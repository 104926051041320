import axios from "axios";
import { baseUrl, RouteNames } from "../config/api";

export const PlaylistService = () => {
    const createPlaylist = async ({name,about,cover,tags,clicks,likes,artist_featured,songs,external_links})=>{
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.playlist}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                name,
                about,
                cover,
                tags,
                clicks,
                likes,
                artist_featured,
                songs,
                external_links
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getPlaylist = async ({id})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.playlist}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                playlistId: id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deletePlaylist = async ({id})=>{
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.playlist}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                playlistId: id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updatePlaylist = async (data)=>{
        console.log("Updating Playlist",data);
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.playlist}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                playlistId: data.playlistId
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getAllPlaylist = async({cursor, limit, offset})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.playlistList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchPlaylist = async({query, cursor, limit, offset})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.playlistSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        createPlaylist,
        getPlaylist,
        deletePlaylist,
        updatePlaylist,
        getAllPlaylist,
        searchPlaylist
    }
}