/* eslint-disable react-hooks/exhaustive-deps */

import  {Card, CardContent, Grid, Accordion, AccordionSummary, Typography, AccordionDetails, TextField, IconButton, Divider } from "@material-ui/core";
import React,{ useEffect, useRef, useState } from "react";
import Row from "../components/Row";
import Column from "../components/Column";
import { sendChatMessage } from "../hooks/useChatMessenger";
import shortUUID from "short-uuid";
import { useSnackbar } from "notistack";
import { useTodoHandler } from "../hooks/useToDoList";
import TaskListTile from "./_todoTile";
import { ReactSortable } from "react-sortablejs";
import { CheckCircle, DragIndicator } from "@material-ui/icons";
import { CustomScrollview } from "./custom_scrollview";

import PropTypes from 'prop-types';


const ToDo = ({listId, myId, viewOnly, autoOpen, project})=>{
    const [open, setOpen] = useState(autoOpen);
    const { addToDo, streamToDos,updateIndexes } = useTodoHandler();
    const [items, setItems] = useState(null);
    const {enqueueSnackbar} = useSnackbar();
    const messageTextRef = useRef(null);
    const [lattestMessage, setLattestMessage] = useState(null);
    useEffect(()=>{
        const unsubscribe = streamToDos(listId, (data)=>{
            const list = data.sort((msg,msg2)=>msg2.index - msg.index);
            const completed = list.filter((msg)=>msg.completed);
            setItems(list);
            if(completed.length > 0)
                setLattestMessage(completed[completed.length - 1]);
            
        });
        return unsubscribe;
    },[viewOnly])
  
    const handleAddNewItem = async ()=>{
        const todo = {
            itemId: shortUUID.generate(),
            content: messageTextRef.current.value,
            timestamp: Date.now(),
            listId: listId,
            completed: false,
            access: "ALL",
            index: items.length

        }
        console.log(todo);
        messageTextRef.current.value = "";
     
            if(await addToDo(todo))
            {
                sendChatMessage({
                    messageId: shortUUID.generate(),
                    content: `New To Do Item Added: ${todo.content?.substring(0,10)}...`,
                    timestamp: Date.now(),
                    senderId: myId,
                    reciepientKey: project?.id,
                    reciepients: ['dtv-admin', project?.agentId === myId ? project?.uid : project?.agentId],
                    seenby:[],

                    
                })
            enqueueSnackbar("Task Added", {variant: 'success'});
        }else{
            enqueueSnackbar("Task failed to add", {variant: 'error'});
        }
    }
    const SortableComponent = ({data})=>{
        return <TaskListTile uid={myId} editMode={
            project?.agentId === myId  ? true :
            !project?.lockEdit} item={{...data}} agentId={project?.agentId === myId ? project?.uid : project?.agentId} access={viewOnly ? 'READ_ONLY' :'ALL' }/>
    }
    SortableComponent.propTypes = {
        data: PropTypes.object,
    }
    const handleSort = () => {
        const sorted = items.map((e,i)=> {
            e.index = i;
            return e;})
        // packet[sortedIds.newIndex].data.id = sortedIds.newIndex;
        // packet[sortedIds.newIndex].id = sortedIds.newIndex;
        // packet[sortedIds.oldIndex].data.id = sortedIds.oldIndex;
        // packet[sortedIds.oldIndex].id = sortedIds.oldIndex;
        console.log(sorted);
        setItems(sorted);
        updateIndexes(listId,sorted);
        
      };
    return items ? <Accordion expanded={open} onChange={
        (e,expanded)=>{
            setOpen(expanded);
        }
    } >
        <AccordionSummary expandIcon>
         <Row style={{width:'100%', alignItems: 'center'}} alignment={"space-between"}>
          <Column>
            
                <Typography>Tasklist</Typography>
                <div style={{width:'1em'}}/>
                {lattestMessage ? <TaskListTile {...lattestMessage} access={viewOnly ? 'READ_ONLY' :'ALL' }/> : 
                    <Typography variant="caption">{"No recently completed task at present"}</Typography>
                }

            
         </Column>
         <Typography variant="caption">{open ? 'Close tasks' :`See All Tasks(${items?.length ?? 0})`}</Typography>
        </Row>
        </AccordionSummary>
        <AccordionDetails>
          
           <Grid container spacing={3}>
           <Card style={{width:'100%'}} variant="outlined"> 
           <CustomScrollview>
            <CardContent style={{height: 400, width:'100%'}}>
            <Column style={{height: '100%', width:"100%", padding:20}} alignment="top">
            <Typography variant="overline">Track List</Typography>
        <div style={{height:'1em'}}/>
        <Divider/>
        <div style={{height:'1em'}}/>
        <Row style={{width:'100%'}}>
        {/* <Select defaultValue={"ALL"} style={{width: '15%', height: 40}}>
                <MenuItem value={"ALL"}>Allow Edit</MenuItem>
                <MenuItem value={"READ_ONLY"}>Read Only</MenuItem>
        </Select>
        <div style={{width:'1em'}}/> */}
            { viewOnly ?<></> : <TextField 
             inputProps={{style:{padding: 0}}}
             multiline
             minRows={1}
        inputRef={messageTextRef} 
        label="Add Item" InputProps={{
            endAdornment: <IconButton onClick={handleAddNewItem}>
            <CheckCircle/>
        </IconButton>}} variant="outlined" fullWidth/>}
        </Row>
        <ReactSortable 
                        style={{listStyleType:"none", padding: 0}}
                        disabled={viewOnly}
                        tag={"ul"}
                        animation="200"
                        easing="ease-out"
                        handle={'.handle'}
                        onSort={handleSort}
                        list={items}
                        setList={setItems}>
                        {items.map(p => (
                            <li key={p.listId} data-id={p.listId} style={{listStyleType: 'none',padding:0, margin:'10px 0'}}>
                              <Row alignment="left" style={{width:"100%",alignItems:'center'}}> {viewOnly ? <></>: 
                              <IconButton className="handle" style={{opacity: 0.5}}><DragIndicator /></IconButton>
                            }
                            <SortableComponent data={p} />   </Row>
                            </li>
                        ))}
                    </ReactSortable>
            </Column>
            </CardContent>
            </CustomScrollview>
           </Card>  
            </Grid>
         
        </AccordionDetails>
    </Accordion>:<></>
}
export default ToDo;

ToDo.propTypes = {
    listId: PropTypes.string,
    myId: PropTypes.string,
    viewOnly: PropTypes.bool,
    autoOpen: PropTypes.bool,
    project: PropTypes.object,
}