/* eslint-disable react-hooks/exhaustive-deps */
import { QontoConnector, QontoStepIcon, ServiceProcess, TimeAgo, calcDaysPast, parseUrlForQueryParams } from "../../../utils";
import { useNavigate } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import useServiceBooking from "../../../contexts/ServiceBookingProvider";
import { useProfile } from "../../../hooks/useProfile";
import { useDatabase } from "../../../hooks/useDatabase";
import { Button, Card, CardContent, CardHeader, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Radio, Step, StepLabel, Stepper, TextField, Typography } from "@material-ui/core";
import Column from "../../../components/Column";
import { useSnackbar } from "notistack";
import { useMediaQuery } from "../../../contexts/MediaQueryProvider";

import PropTypes from 'prop-types';
import Row from "../../../components/Row";
import { AppBarModule } from "../../../components/_app_bar_mod";
import Stack from "../../../components/Stack";
import ServicePakageCard from "../../../components/_service_package_card";
import BuildCommunicationPanel from "../../../components/_communication_panel";
import Logic from "../../../logic/logic";
import FutureBuilder from "../../../components/_future_builder";
import ServiceAgreementPage from "../../serviceAgreementPage";
import ToDo from "../../../components/_todo";
import { ProfileAvatar } from "../../../components/profile/main/_profile_avatar";
import ProjectAction from "../../../components/profile/agent/service_project_actions";
import LoginRequired from "../../../components/_login_required";
import MarkdownTextarea from "../../../components/_markdown_textarea";
import { CustomScrollview } from "../../../components/custom_scrollview";


const BuildProgressTracker = ({ statusDates, status }) => {
  const { isLargeDevice } = useMediaQuery();
  const [filteredProgression, ] = useState(ServiceProcess.filter(p => {
    return (
      (p === 'approved' && statusDates.approved) ||
      (p === 'rejected' && !statusDates.approved && statusDates.rejected) ||
      (p === 'cancelled' && statusDates.cancelled) ||
      (p === 'cancelled-1' && statusDates['cancelled-1']) ||
      (p === 'cancelled-2' && statusDates['cancelled-2']) ||
      (p === 'deposit-paid' && statusDates['deposit-paid']) ||
      (p === 'deposit-failed' && !statusDates['deposit-paid'] && statusDates['deposit-failed']) ||
      (p === 'dpayment-recieved' && statusDates['payment-recieved']) ||
      (p === 'payment-failed' && !statusDates['payment-recieved'] && statusDates['payment-failed']) ||
      (p !== 'approved' && p !== 'rejected' && p !== 'cancelled' && p!== 'cancelled-1' && p!== 'cancelled-2' && p !== 'deposit-paid' && p !== 'deposit-failed' && p !== 'payment-recieved' && p !== 'payment-failed')
    );
  }));
  console.log(filteredProgression);
  return <Column style={{ width: '100%' }}>
    <Stepper style={{ overflowX: isLargeDevice ? 'hidden' : 'auto', padding: isLargeDevice ? null : '2px' }} alternativeLabel activeStep={filteredProgression.indexOf(status)} connector={<QontoConnector />}>
      {
        filteredProgression.map((step) => {
          const Modified = ({ active, completed }) => {
            return <QontoStepIcon name={step} active={active} completed={completed} />
          }
          Modified.propTypes = {
            active: PropTypes.bool,
            completed: PropTypes.bool
          }
          return <Step key={step} active={status === step} completed={filteredProgression.indexOf(status) > filteredProgression.indexOf(step) || status === "published"} >
            <StepLabel StepIconComponent={Modified} style={{ textTransform: 'capitalize' }}>{step === 'cancelled-1' || step === 'cancelled-2' ? 'canceled' : step}</StepLabel>

          </Step>
        })
      }
    </Stepper>
  </Column>
}


const ServiceProjectPage = () => {
  const params = parseUrlForQueryParams(window.location.href);
  console.log(params);
  const history = useNavigate();
  const {  getAgent, updateRequest, streamRequests  } = useServiceBooking();
  const { retrieveServicePackage, getAgreement } = useDatabase();
  const [service, setService] = useState({});
  const [agent, setAgent] = useState({});
  const [servicePackage, setServicePackage] = useState({});
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const { retrieveProfileWithCallback } = useProfile();
  const { isSmallDevice } = useMediaQuery();
  const { enqueueSnackbar } = useSnackbar();
  const [agreement, setAgreement] = useState({});
  const [showPackageTerms, setShowPackageTerms] = useState(false);
  const communicationPanelRef = useRef(null);
  const todoPanelRef = useRef(null);
  const [openComms, setOpenComms] = useState(true);
  const [openToDo, setOpenToDo] = useState(false);
  const descriptionRef = useRef(null);
  const cancelReasonRef = useRef(null);
  const [cancelReason, setCancelReason] = useState('');
  const [showCancellationDialog,setShowCancellationDialog] = useState(false);
  const validateUrl = () => {
    if (params && params.id) {
      return true;
    }
    return false;
  }
  useEffect(() => {
    if (!validateUrl()) {
      history(-1);
    } else {
      setLoading(true);
      const unsubscribe =   streamRequests(params.id,(request) => {
        setService(request);
          retrieveProfileWithCallback((profile) => {
            setProfile(profile);
          getAgent(request.agentId).then((agent) => {
            setAgent(agent);
            retrieveServicePackage(request.packageId).then((packageData) => {
              setServicePackage(packageData);
              getAgreement(packageData.agreementsAttached.length > 0 ? packageData.agreementsAttached[0] : '--').then((agreement) => {
                setAgreement(agreement);
              })
              setLoading(false);
            })
          })
        })
      })
      return unsubscribe;
    }
  }, [profile?.uid]);
  const handleShowAgreement = () => {
    setShowPackageTerms(true);
  }

  const _buildStausDetails = () => {
  
    return service.status ? <Column>
      <Row style={{width:"100%"}} alignment="space-between">
        <Typography variant="h6">Actions</Typography>
        {service.statusDates[service.status] &&
                    <Chip style={
                        {
                            borderColor: calcDaysPast(service.statusDates[service.status]) > 2 ? '#ff6f6f' : '#00ceff',
                            color: calcDaysPast(service.statusDates[service.status]) > 2 ? '#ff6f6f' : '#00ceff',
                        }
                    } variant="outlined" label={<span style={{textTransform:'capitalize'}}>
                        {`Started `}
                        <TimeAgo timestamp={service.statusDates[service.status]}/>
                    </span>} />}
        </Row>
      <div style={{ height: 20 }}></div>
      <ProjectAction
      agent={agent}
      onOpenCommunicationPanel={
        ()=>{communicationPanelRef.current.scrollIntoView({behavior: 'smooth'});setOpenComms(true);}
      }
      onOpenToDoPanel={
        ()=>{
          todoPanelRef.current.scrollIntoView({behavior: 'smooth'});
          setOpenToDo(true);
        }
      }
        onReadDescription={
          ()=>{
            descriptionRef.current.scrollIntoView({behavior: 'smooth'});
          }
        }
       servicePackage={servicePackage} openAgreement={handleShowAgreement} service={service} status={service.status} />
      {/* <Column>
        <Row alignment="right">
          {(canceled||service.status === "rejected") ? <></> : service.status !== "delivered" ? <Chip style={{
            borderColor: getStatusColor(service.status),
            color: getStatusColor(service.status),
          }} variant="outlined" label={
            !((/^(pending)|(deposit-processing)|(in-Progress)|(payment-processing)/g).test(service.status)) ? "Action Required" : "Awaiting Response"
          } /> : <></>}
          <div style={{ width: '1em' }} />{service.completed ? <></>:
          service.status === "rejected" ? <></> : canceled ? <Button onClick={()=>{
            if(service.status === "cancelled"){
              service.status = "pending";
              service.statusDates["pending"] = new Date().getTime();
              delete service.statusDates["cancelled"];
            }else if(service.status === "cancelled-1"){
              if(service.statusDates["deposit-processing"]){
                service.status = "deposit-processing";
              }else if(service.statusDates["deposit-paid"]){
                service.status = "deposit-paid";
              }else if(service.statusDates["deposit-failed"]){
                service.status = "deposit-failed";
              }else{
                service.status = "deposit";
              }
              delete service.statusDates["cancelled-1"];
            }else if(service.status === "cancelled-2"){
              if(service.statusDates["in-progress"]){
                service.status = "in-progress";
              delete service.statusDates["cancelled-2"];
            }else{
              service.status = "review";
            }
            
          }
          service.statusDates[service.status] = new Date().getTime();
          setService(service);
          updateRequest(service.id, service).then((res)=>{
            if(res){
              enqueueSnackbar("Project Re-Opened", {variant: 'success'});
            }else{
              enqueueSnackbar("Error Re-Opening Project", {variant: 'error'});
            }
          })
          }}>
            <Typography variant="caption" color="primary">Re-Open Project</Typography>
          </Button>:<Button disableElevation size="small" style={{ borderColor: 'red', color: 'red' }} variant="outlined" onClick={() => {
           setShowCancellationDialog(true);
          }}>Cancel Request</Button>}
        </Row>
      </Column> */}
    </Column> : <></>
  }
  const _buildToDo = () => {
    return <div ref={todoPanelRef}>
        <ToDo autoOpen = {openToDo} listId={params.id} myId={agent.agentId} project={service}/>
      </div>
  }
  const _buildChat = () => {
    console.log(profile?.uid);
    return (agent?.agentId) && <div ref={communicationPanelRef}>
          <BuildCommunicationPanel isAgent autoOpen={openComms} myId={agent.agentId} chatKey={params.id} />
      </div>
  }
  const BuildPage = () => {
    return service ? <Grid alignContent="center" justifyContent="center" container spacing={30} style={{ width: '100%', padding: 20,maxWidth: 1440, margin: "0 auto", }}>
      <Grid alignContent="center" container >
        <Grid alignItems="center" xs={6} md={3} style={{ margin: 10 }}>
          {!isSmallDevice ? <Card style={{ padding: 10, maxWidth:250 }} variant="outlined">
            <ServicePakageCard mini disable style={{width:'100%',height: 270 }} existingServicePackage={servicePackage} />

          </Card> : <FutureBuilder
            rebuild
            future={Logic.toCountryCurrency(servicePackage.price, 0)} builder={
              (price) => {
                return <Chip color="primary" variant="outlined" label={<Typography>{servicePackage.name} Package | {price}</Typography>} style={{ margin: 10 }} />
              }
            }
            placeholder={
              <Chip color="primary" variant="outlined" label={<Typography>{servicePackage.name} Package | €{servicePackage.price}</Typography>} style={{ margin: 10 }} />
            }
            error={
              () => {

              }
            } />}

        </Grid>
        <Grid xs={12} md={12} style={{ margin: 10 }}>
          <Card ref={descriptionRef} variant="outlined" style={{ padding: 10, textAlign: "left" }}>
            <CardHeader title={<Typography align="left">Request Details</Typography>} subheader={<Typography style={{ textAlign: "left" }} variant="overline">{new Intl.DateTimeFormat().format(service.timestamp)}</Typography>} />
            <CardContent>
              <Row>
                <Typography style={{marginRight: 5}}>Requested by</Typography>
                <ProfileAvatar uid={service.uid} onlyName/>
              </Row>
              <MarkdownTextarea markdownValue={service.description} enableShowMore editmode={false}/>
            </CardContent>
          </Card>
        </Grid>


      </Grid>
      <Grid xs={12}>
        <div style={{ height: 20 }} />
      </Grid>
      <Grid xs={12}>
        <Card style={{ width: "100%", padding: 20 }}>
          <CustomScrollview><BuildProgressTracker statusDates={service.statusDates ?? {}} status={service.status} /></CustomScrollview>
        </Card>
      </Grid>
      <Grid xs={12}>
        <div style={{ height: 20 }} />
      </Grid>
      <Grid container spacing={20}>
        <Grid xs={12} xl={4}>
          <Card variant={"outlined"} style={{ padding: 10, minWidth: "30%", marginRight: 10, textAlign: "left" }}>
            {_buildStausDetails()}
          </Card>
        </Grid>
        {isSmallDevice ? <Grid xs={12}>
          <div style={{ height: 20 }} />
        </Grid> : <></>}
        <Grid xs={12} xl={8}>
          <Card style={{ padding: 10, minWidth: "70%", textAlign: "left" }}>
            <Column>
              {service.status === "in-progress" && service.enableTasklist ? _buildToDo() : <></>}
              {_buildChat()}
            </Column>
          </Card>
        </Grid>
        <Grid xs={12}>
          <div style={{ height: 20 }} />
        </Grid>
      </Grid>
      <Grid xs={12}>
        <div style={{ height: 20 }} />
      </Grid>
    </Grid> : <></>

  }
  return <div>
    <Stack>
      <div style={{ background: "#040404", height: "100%", width: "100%" }}></div>

      {loading ? <Column style={{ width: '100%', height: '100%', alignItems: 'center' }}>
        <CircularProgress />
      </Column> : <div style={{ padding: isSmallDevice ? '60px 10px' : '10%', overflowY: "auto", height: "100vh", width: '100%',alignItems: 'center' }}>
        <BuildPage />
      </div>}
    </Stack>
    {<AppBarModule pageIndex={0} elevation={1} appbarBackground="transparent" />}
    <Dialog fullWidth maxWidth={'lg'} open={showPackageTerms} onClose={() => {
      setShowPackageTerms(false);
    }}>
      <DialogContent>
        {agreement?.policy ? <ServiceAgreementPage viewOnly={true} agreement={agreement} /> : <></>}
      </DialogContent>
    </Dialog>
    <Dialog open={showCancellationDialog} onClose={
      ()=>{
        setShowCancellationDialog(false);
      }
    } >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>
      
        <Typography variant="body2">{"We're sorry to hear that you'd like to cancel your request! Is there any particular reason for this?"}</Typography>
        <Typography color="textSecondary" variant="caption">Be Aware that any deposit paid cannot be refunded once this process is cancelled</Typography>
        <div style={{ height: 10 }} />
        {['I found a better offer', 'I no longer need this service', 'I have a problem with the agent', 'Other'].map((reason, index) => {
          return <Row key={index} style={{ width: '100%', alignItems: 'center' }}>
            <Radio checked={cancelReason === reason} onChange={() => {
              setCancelReason(reason);
            }} />
            <Typography variant="caption">{reason}</Typography>
          </Row>
        })}
        <div style={{ height: 10 }} />
        <TextField multiline fullWidth variant="outlined" label="Other" helperText={"Please describe in detail your reason for cancelling"} inputRef={cancelReasonRef} />
      </DialogContent>
      <DialogActions>
        <Button style={{color: 'red'}} onClick={() => {
          if(!cancelReason && !cancelReasonRef.current?.value){
            enqueueSnackbar("Please select a reason for cancelling", {variant: 'warning'});
            return;
          }
          service.cancelReason = cancelReason ?? cancelReasonRef.current?.value;
          
           if(service.status==="pending"){
            service.status = "cancelled";
          }else if(service.status==="deposit-processing" || service.status==="deposit" || service.status==="deposit-failed" || service.status==="deposit-paid" ){
            service.status = "cancelled-1";
          }else {
            service.status = "cancelled-2";
          }
          service.statusDates[service.status] = new Date().getTime();
          setService(service);
          updateRequest(service.id, service).then((res)=>{
            if(res){
              enqueueSnackbar("Request Cancelled", {variant: 'success'});
             
            }else{
              enqueueSnackbar("Error Cancelling Request", {variant: 'error'});

            }
             setShowCancellationDialog(false);
          })
          
        }}>Continue with Cancellation</Button>
        <Button variant="outlined" onClick={() => {
          setShowCancellationDialog(false);
        }}>{"I've Changed my mind"}</Button>
      </DialogActions>
    </Dialog>
    <LoginRequired/>
  </div>
}
export default ServiceProjectPage;


BuildProgressTracker.propTypes = {
  statusDates: PropTypes.object,
  status: PropTypes.string
}