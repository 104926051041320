import React, { createContext, useState } from 'react';
import { baseUrl, RouteNames,  } from '../config/api';
import PropTypes from 'prop-types';

export const ReleasesContext = createContext();

const ReleasesProvider = ((props)=>{
    const [youtubeVideos,setYoutubeVideos] = useState([]);
    const [maxVideoSize,setMaxSize] = useState(0);
    const [maxQueryVideoSize,setMaxQuerySize] = useState(0);
    const [queryResults,setQueryResults] = useState([]);

    const updateYoutubeVideos = async ()=>{
       
         try{    
            const request = new Request(`${baseUrl}/${RouteNames.video}`,{
                method:"PUT",
                mode:'cors',
                credentials: 'same-origin',
                headers: new Headers({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' }),
            });
    
            return fetch(request);
    
        }catch(e){
            console.log(e)
            return e;
        }
       
    }
  
    
    const getYoutubeVideos = async (min,max)=>{
        //

        console.log(`${baseUrl}/${RouteNames.videoList}?min=${min}&max=${max}`)
        const request = new Request(`${baseUrl}/${RouteNames.videoList}?min=${min}&max=${max}`,
        {
            method:'GET',
            mode:'cors',
            credentials: 'same-origin',
            headers: new Headers({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' }),
        });
        try{
            const response = await fetch(request);
        if(response.status<200 || response.status>300){
            console.error(response.status)
        }else{
            const data = await response.json();
            console.log("--------->")
                console.log(data);
            if(data.success)
            {
                //season->episodes[]
                //to get the max season sort 0-> and get the last enrty in the map
               console.log(data.data);
               setYoutubeVideos(data.data.data);
               setMaxSize(data.data.total);
            }
        } 
        }catch(e){
            return e;
        }
       
       
    } 
    const queryYoutubeVideos = async (query,min,max)=>{
        //

        
        const request = new Request(`${baseUrl}/${RouteNames.videoSearch}?query=${query}&min=${min}&max=${max}`,
        {
            method:'GET',
            mode:'cors',
            credentials: 'same-origin',
            headers: new Headers({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' }),
        });
        try{
            const response = await fetch(request);
        if(response.status<200 || response.status>300){
            console.error(response.status)
        }else{
            const data = await response.json();
            console.log("--------->")
                console.log(data);
            if(data.success)
            {
                //season->episodes[]
                //to get the max season sort 0-> and get the last enrty in the map
               console.log(data.data);
               setQueryResults(data.data.data);
               setMaxQuerySize(data.data.total);
            }
        } 
        }catch(e){
            return e;
        }
       
       
    }

    return <ReleasesContext.Provider value={{
        
        youtubeVideos,
        maxVideoSize,
        maxQueryVideoSize,
        queryResults,
        updateYoutubeVideos,
        getYoutubeVideos,
        queryYoutubeVideos
    }}>
       {props.children}
    </ReleasesContext.Provider>
})
export default ReleasesProvider;


ReleasesProvider.propTypes = {
    children: PropTypes.node
}