/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Dialog, IconButton, CircularProgress, Typography, Card, TextField, Grid, Accordion, AccordionSummary, Chip, AccordionDetails, MenuItem, Divider, InputAdornment, Tooltip, DialogContent } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Stack from '../components/Stack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AccountCircle, ArrowBackIos, ArrowDropDownCircleOutlined, Mail, PinDrop } from '@material-ui/icons';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useSiteData } from '../config/SiteData';
import "../index.css";
import { useNavigate } from 'react-router';
import { AppBarModule } from '../components/_app_bar_mod';
import Column from "../components/Column";
import { TimeAgo, isValidLink, loadFromDevice, parseUrlForQueryParams, useStyles } from "../utils";
import { useMediaQuery } from "../contexts/MediaQueryProvider";
import { AlignHorizontalLeft, Verified } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { UploadProfilePicture } from "../components/UploadDiv";
import { useDatabase } from "../hooks/useDatabase";
import useServiceBooking from "../contexts/ServiceBookingProvider";
import ServiceAgentApplication from "../components/profile/agent/service_agent_application";
import Row from "../components/Row";
import { motion, AnimatePresence } from "framer-motion";
import { useStorage } from "../contexts/storage_handler";
import { MarkdownTextField } from "../components/_new_story_comp";
import ServiceAgreementLink from "../components/_service_agreement_link";
import ServicePakageCard from "../components/_service_package_card";
import { useCustomDialog } from "../contexts/CustomDialogProvider";
import BuildPackageCreator from "../components/_package_creator";
import AgentPortfolio from "../components/agent_portfolio";
import PasswordRequest from "../components/_password_request";
import { updateProfileDB } from "../logic/databaseHandler";
import { useStripePaymentContext } from "../contexts/StripeProvider";
import { UserContext } from "../contexts/UserProvider";
import PropTypes from "prop-types";
const BuildMoreDetails = ({agent})=>{
    const {isSmallDevice} = useMediaQuery();
    return  <Row style={{width:"100%",alignItems:'center', flexWrap:'wrap'}} alignment={isSmallDevice ? "left":"right"}>
     <Chip style={{ margin: isSmallDevice ? 5: 0}} color="primary" label={<ServiceAgreementLink agreementId={agent.serviceAgreement}/>} variant="outlined" />
        <div style={{width:10}}/>
    <Chip icon={<Verified/>} color="primary" label={agent.verified ? "Verified" : "Not Verified"} variant="outlined" style={{
            margin: isSmallDevice ? 5: 0
        }}/>
        <div style={{width:10}}/>
        <Chip label={agent.active ? "Active" : "Deactivated"} variant="outlined" style={{
            backgroundColor: agent.active ? "green" : "grey",
            opacity: 0.8,
            color: 'white',
            margin: isSmallDevice ? 5: 0
        }}/>
        <div style={{width:10}}/>
         <Tooltip title={new Date(agent.joinedDate).toDateString()}>
         <Chip label={<span>Joined <TimeAgo timestamp={agent.joinedDate}/></span>} variant="outlined" style={{
            backgroundColor: agent.active ? "green" : "grey",
            opacity: 0.8,
            color: 'white',
            margin: isSmallDevice ? 5: 0
        }}/>
         </Tooltip>
         <div style={{width:10}}/>
         <Chip label={agent.paid ? "Paid" : "Unpaid"} variant="outlined" style={{
            backgroundColor: agent.active ? "green" : "grey",
            opacity: 0.8,
            color: 'white',
            margin: isSmallDevice ? 5: 0
        }}/>
    </Row>
}
const BuildBankDetails = ({profile,expanded,onOpenAttempt})=>{
    const [expand, setExpand] = useState(expanded);
    const {profile: Profile} = useContext(UserContext)
    const {
        createCustomer
    } = useStripePaymentContext();
    useEffect(()=>{
        if(Profile){
            if(!Profile.stripeCustomerId){
                console.log(Profile);
            createCustomer({
                email:Profile.email,
                name:`${Profile.fname} ${Profile.lname}`,
            }).then((res)=>{
             
                
                updateProfileDB(Profile,{"stripeCustomerId": res.id});
            })
        }
        }
        
        setExpand(expanded)},[expanded]);
    return profile && <>
    
    <Card variant="outlined" style={{marginTop:"20px", padding:"20px", width:'100%',}}>
        <Accordion expanded={expand} onChange={
            ()=>{
                if(expand){
                    setExpand(false);
                    return;
                }
                if(onOpenAttempt)
                    onOpenAttempt(true);
                console.log("expanded");
            }
        } style={{width:"100%"}}>
            <AccordionSummary expandIcon={<ArrowDropDownCircleOutlined/>}>
            <Row style={{width:"100%",alignItems:'center'}} alignment="space-between">
        <Typography variant="h6" style={{ color:"white", textAlign:"left",  fontWeight: "bold"}}>Bank Details</Typography>
        </Row>
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <TextField InputProps={{
            startAdornment:<InputAdornment position="start"><AccountCircle/></InputAdornment>
            }} InputLabelProps={{ shrink: true }} label="Account Name" variant="outlined" defaultValue={profile.bankDetails?.accountName} fullWidth onChange={
                            ()=>{
                            }
                        }/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField InputProps={{
            startAdornment:<InputAdornment position="start"><AccountCircle/></InputAdornment>
            }} InputLabelProps={{ shrink: true }} label="Account Number" variant="outlined" defaultValue={profile.bankDetails?.accountNumber} fullWidth onChange={
                            ()=>{
                            }
                        }/>
                    </Grid>
                    </Grid>
            </AccordionDetails>
        </Accordion>
    </Card></> 
}
const AgentSettingsPage = () => {
    
const history = useNavigate();
const classes = useStyles();
const {SiteData} = useSiteData();
const {addServicePackage,updateServicePackage} = useDatabase();
const [uploading, setUploading] = useState(false);
const {enqueueSnackbar} = useSnackbar();
const [uploadingAccountDetails, setUploadingAccountDetails] = useState(false);
const [openApplication, setOpenApplication] = useState(false);
    const {getServiceTypes,updateAgent,streamAgent} = useServiceBooking();
    const [agent, setAgent] = useState({});
    const [newProfile, setNewProfile] = useState({});
    const [loading, setLoading] = useState(false);
    const [currentPackage] = useState()
    const { uploadMediaOperation } = useStorage();
    const [serviceTypes, setServiceTypes] = useState({});
    const [editBio, setEditBio] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const params = parseUrlForQueryParams(window.location.href);
    useEffect(()=>{
        if(params && params.id){
            setLoading(true);
            if(params.type === "serviceAgent"){
               const unsubscribe = streamAgent(params.id, (res)=>{
                if(res){
                    setAgent(res);
                    setNewProfile(res);
                    getServiceTypes().then((res)=>{
                        setServiceTypes(res);
                        setLoading(false);
                    })
                }
            })
            return unsubscribe;
            }
            
        }else{
            history(-1)
        }
    },[]);
    const uploadProfileImage = async ()=>{
        if(newProfile.picture && !isValidLink(newProfile.picture)){
          return await uploadMediaOperation(newProfile.picture,"agents_profile_picture");   
        }
        return newProfile.picture;
    }
    const updateAgentDetails = async()=>{
        setUploadingAccountDetails(true);
        try{
            const profilePicture = await uploadProfileImage();
            newProfile.picture = profilePicture;
                await updateAgent(newProfile.id,newProfile);
                setUploadingAccountDetails(false);
                enqueueSnackbar("Account details updated successfully", {variant:"success"});
            
        }catch(e){
            console.log(e);
            setUploadingAccountDetails(false);
            enqueueSnackbar("Error updating account details", {variant:"error"});
        }
    }
 
    const BuildPricingPackage = ()=>{
        const {showDialog,closeDialog} = useCustomDialog();
        const packageCreatorRef = React.createRef();
        const handleAddPackage = (packageId)=>{
           
            showDialog({
                title:"Create Package",
                body: <BuildPackageCreator agent={newProfile} packageId = {packageId} existingPackage={currentPackage} ref = {packageCreatorRef}/>,
                yesText:"Save",
                noText:"Cancel",
                onAccept:()=>{
                    const packageData = packageCreatorRef.current.getPackage();
                    console.log(packageData);
                    if(packageData){
                        let temp = newProfile;
                        temp.packages = newProfile.packages?.find((item)=>item === packageId) ? newProfile.packages :
                         [...(newProfile.packages??[]), packageData.id];
                        setNewProfile({...temp});
                        setUploading(true);
                        (packageId ? updateServicePackage(packageId, packageData)  : addServicePackage(packageData)).then(()=>{
                        updateAgent(newProfile.id, newProfile).then((res)=>{
                            if(res){
                                setUploading(false);
                                enqueueSnackbar("Package created successfully", {variant:"success"});
                                closeDialog();
                            }else{
                                enqueueSnackbar("Error creating package", {variant:"error"});
                            }
                        })
                    })
                    }
                }
            })
         }
       
        return newProfile && <Card variant="outlined" style={{marginTop:"20px", padding:"20px", width:'100%',}}>
       
        <Accordion>
            <AccordionSummary expandIcon={<ArrowDropDownCircleOutlined/>}>
                 <Row style={{width:"100%",alignItems:'center'}} alignment="space-between">
                <Typography variant="h6" style={{ color:"white", textAlign:"left",  fontWeight: "bold"}}>Packages</Typography>
                <Button  variant="contained" color="primary" size="small" onClick={()=>{
                    handleAddPackage()
                }}>Add Package</Button>
            </Row>
            </AccordionSummary>
            <AccordionDetails>
                 <Grid container spacing={2}> {
                   
                newProfile?.packages?.map((pkg, index)=>{
                    return <Grid item xs={12} sm={6} md={4} key={index}>
                      <Column>
                        <div style={{height:10}}/>
                      <motion.div  whileHover={{
                        scale:1.01
                       }}> <ServicePakageCard onUpdatePackage={
                        (packageData)=>{
                            let temp = newProfile;
                            temp.packages = newProfile.packages?.find((item)=>item === packageData.id) ? newProfile.packages :
                             [...(newProfile.packages??[]), packageData.id];
                            setNewProfile({...temp});
                        }
                       } agentOwner={newProfile} agentMode disable style={{width:"100%", alignItems: 'stretch', height: 500}} servicePackageId={pkg} /></motion.div>
                      </Column>
                    </Grid>
                })
            }</Grid>
            </AccordionDetails>
           
            </Accordion>
        </Card>
    }
    const BuildPorfolio = ()=>{
        return newProfile && <Card variant="outlined" style={{marginTop:"20px", padding:"20px", width:'100%',}}>
        <Accordion>
            <AccordionSummary expandIcon={<ArrowDropDownCircleOutlined/>}>
            <Row style={{width:"100%",alignItems:'center'}} alignment="space-between">
        <Typography variant="h6" style={{ color:"white", textAlign:"left",  fontWeight: "bold"}}>Portfolio</Typography>
        </Row>
            </AccordionSummary>
            <AccordionDetails>
                <AgentPortfolio agent={agent}/>
            </AccordionDetails>
        </Accordion>

        </Card>
    }
    const BuildAccountDetails = ()=>{
        return  newProfile && <Card variant="outlined" style={{marginTop:"20px", padding:"20px", width:'100%',}}>
       <Row style={{width:"100%",alignItems:'center'}} alignment="space-between">
       <Typography variant="h6" style={{width:'100%', color:"white", textAlign:"left",  fontWeight: "bold"}}>Account Details</Typography>
       {uploadingAccountDetails ? <CircularProgress size={20}/> : <Button  variant="contained" color="primary" size="small" onClick={()=>{
        updateAgentDetails();
       }}>Update</Button>}
       </Row>
        <div style={{height: 20}}/>
        <Grid container spacing={6}>
            <Grid container justifyContent="flex-start" item xs={12} sm={4}>
            <UploadProfilePicture onUpload={
                        (file)=>{
                            loadFromDevice(file,(blob)=>{
                                newProfile.picture = isValidLink(blob) ? blob : {
                                    blob: blob,
                                    file:file
                                }
                            })
                        }
                    } image={newProfile?.picture}/>
            </Grid>
            <Grid item xs={12} sm={8}>
                <BuildMoreDetails agent={newProfile}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField InputProps={{
            startAdornment:<InputAdornment position="start"><AccountCircle/></InputAdornment>
          }}  InputLabelProps={{ shrink: true }} label={"Name"}  placeholder="Name" variant="outlined" defaultValue={newProfile.name} fullWidth onChange={
                    (e)=>{
                        newProfile.name = e.target.value;
                        setNewProfile(newProfile)
                    }
                } />
            </Grid>
            <Grid item xs={12} sm={6}>
               <Column>
                <TextField InputProps={{
            startAdornment:<InputAdornment position="start"><Mail/></InputAdornment>
          }} InputLabelProps={{ shrink: true }}  label="Email" variant="outlined" defaultValue={newProfile.email} fullWidth onChange={
                        (e)=>{
                            newProfile.email = e.target.value;
                            setNewProfile(newProfile)
                        }
                    }/>
                    <div style={{height: 5}}/>
               </Column>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField InputProps={{
            startAdornment:<InputAdornment position="start"><PinDrop/></InputAdornment>
          }} InputLabelProps={{ shrink: true }} label="Location(County, Country)" variant="outlined" defaultValue={newProfile.location} fullWidth onChange={
                    (e)=>{
                        newProfile.location = e.target.value;
                        setNewProfile(newProfile)
                    }
                }/>
            </Grid>
            <Grid item xs={12} sm={6}>
                    <TextField InputProps={
                        {
                            style:{
                                textAlign:"left"
                            }
                        }
                    } select onChange={
                        (e)=>{
                            setNewProfile({...newProfile, serviceType: serviceTypes[e.target.value]});
                        }
                    } value={newProfile.serviceType?.name??newProfile.serviceType} InputLabelProps={{ shrink:true }} label="Service Type" variant="outlined" fullWidth>
                        {
                            Object.keys(serviceTypes).map((key, index)=>{
                                return <MenuItem key={index}  value={key}>{serviceTypes[key].name}</MenuItem>
                            })
                        }
                    </TextField>
                </Grid>
                <Grid item xs={12}>
                <Card style={{padding: 20}}variant="outlined">
            <Column>
          <Row>
              <AlignHorizontalLeft style={{marginRight: 10}}/>
              <Typography variant="h6">Bio</Typography>
          </Row>
          <Divider style={{margin:"20px 0"}}/>
          <Row>
            <Chip label={"Write"} variant={editBio ? "default" : "outlined"} onClick={()=>{
              setEditBio(!editBio);
            }}/>
            <div style={{width:10}}/>
            <Chip label={"Read"} variant={!editBio ? "default" : "outlined"} onClick={()=>{
              setEditBio(!editBio);
            }}/>
          </Row>
          <div style={{height: 10}}/>
          {newProfile ? <MarkdownTextField
          onStyleUpdate={(style)=>{
            setNewProfile({...newProfile,bio:{text:newProfile?.bio?.text,style:style}});
          }}
          style={newProfile?.bio?.style} onChange={
            (e)=>{
              setNewProfile({...newProfile,bio:{text:e,style:newProfile?.bio?.style}});
            }
          } defaultValue={newProfile?.bio?.text} viewMode={!editBio}/>:<></>}
          </Column>
          </Card>
                </Grid>
        </Grid>
    </Card>
    }
    const _buildPage = ()=>{
        return  loading ? <Column style={{marginTop: 60, alignItems:'center', height:'100%', width:'100%' }}>
        <CircularProgress />
        </Column> : agent && 
        <AnimatePresence>
        <motion.div initial={{opacity:0, x:20}} animate={{opacity:1,x:0}} exit={{opacity:0,x:-20}} transition={{duration:0.5}}>
        <Column alignment="start" style={{padding: '60px 30px', width:'100%', margin:'0 auto', maxWidth:1100}}>
        <Row style={{width:"100%", alignItems: 'center'}}><IconButton onClick={
            ()=>{
                history(-1);
            }
        }>
            <ArrowBackIos/>
            </IconButton><Typography variant="h4" style={{width:'100%', color:"white", textAlign:"left", fontWeight: "bold"}}>{'Account Setup'}</Typography></Row>
            <BuildAccountDetails/>
           { newProfile && <BuildBankDetails 
           expanded={expanded}
           onOpenAttempt = {
                ()=>{
                      setShowDialog(true); 
                }
           }
           profile={newProfile}/>}
            <BuildPorfolio/>
            <BuildPricingPackage />
    </Column>
    </motion.div>
    </AnimatePresence>
    }
    return  <div className={classes.root}>
        <CssBaseline />
    <Stack>
        <div style={{height:"100%", background:"black",width:"100%"}}>
            {SiteData.aboutPage.isVideo ? <video playsInline src={SiteData.aboutPage.featuredCover} 
            autoPlay={true}
            loop={true}
            muted={true}
                style={{height:"100%", width:"100%", objectFit: "cover"}} 
                className="react-player" ></video>:<img alt=""src={SiteData.aboutPage.featuredCover} width="100%" height="100%" style={{objectFit:"cover"}}/>}
        </div>
        <div style={{background:"#0000000d", height:"100%", width:"100%"}}></div>
        <div style={{background:"linear-gradient(0deg, black 20%,#0000009c 54%, transparent)",height:"100%", width:"100%"}}></div>
        {_buildPage()}
    </Stack>
    {<AppBarModule showCart pageIndex={1}/>}
    <Dialog open={uploading} onClose={()=>{setUploading(false)}}>
        <DialogContent>
            <Column style={{alignItems:"center"}}>
                <CircularProgress/>
                <div style={{height:10}}/>
                <Typography>Uploading...</Typography>
            </Column>
        </DialogContent>
    </Dialog>
    <ServiceAgentApplication onClose={
        ()=>{
            setOpenApplication(false);
        }
    } profile={agent} open={openApplication} />
    {<PasswordRequest showModal={showDialog} setShowModal={setShowDialog} onValidPassword={
        ()=>{
            setExpanded(true);
            setShowDialog(false);
        }
    
    }/>}
   </div>
}
export default AgentSettingsPage;

BuildMoreDetails.propTypes = {
    agent: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        picture: PropTypes.string,
        location: PropTypes.string,
        serviceType: PropTypes.string,
        bio: PropTypes.shape({
            text: PropTypes.string,
            style: PropTypes.object
        }),
        serviceAgreement: PropTypes.string,
        verified: PropTypes.bool,
        active: PropTypes.bool,
        joinedDate: PropTypes.number,
        paid: PropTypes.bool,
        packages: PropTypes.arrayOf(PropTypes.string),
        bankDetails: PropTypes.shape({
            accountName: PropTypes.string,
            accountNumber: PropTypes.string
        }),
        portfolio: PropTypes.arrayOf(PropTypes.string),
    }),
}

BuildBankDetails.propTypes = {
    profile: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        picture: PropTypes.string,
        location: PropTypes.string,
        serviceType: PropTypes.string,
        bio: PropTypes.shape({
            text: PropTypes.string,
            style: PropTypes.object
        }),
        serviceAgreement: PropTypes.string,
        verified: PropTypes.bool,
        active: PropTypes.bool,
        joinedDate: PropTypes.number,
        paid: PropTypes.bool,
        packages: PropTypes.arrayOf(PropTypes.string),
        bankDetails: PropTypes.shape({
            accountName: PropTypes.string,
            accountNumber: PropTypes.string
        }),
        portfolio: PropTypes.arrayOf(PropTypes.string),
    }),
    showDialog: PropTypes.bool,
    expanded: PropTypes.bool,
    setShowDialog: PropTypes.func,
    onOpenAttempt: PropTypes.func
}