/* eslint-disable react-hooks/exhaustive-deps */
import React,{useState, useEffect, useContext} from "react";
import { makeStyles} from '@material-ui/core/styles';
import { Button, CssBaseline, Container, Input, Select, MenuItem, TextField, Chip } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { useNavigate } from "react-router-dom";
import Row  from "../components/Row";
import Column from "../components/Column";
import {SiteDataContext } from "../config/SiteData";
import { parseUrlForId, parseUrlForQueryParams } from "../utils";
import { MarkdownTextField } from "../components/_new_story_comp";
import { useSnackbar } from "notistack";
import { AppBarModule } from "../components/_app_bar_mod";
import { useDatabase } from "../hooks/useDatabase";
import PropTypes from 'prop-types';

const drawerWidth = 240;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      gridList: {
        width: 500,
        margingBottom:"200px"
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
      toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
     
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        color:theme.palette.text.primary,
        background:"linear-gradient(45deg, black, #d09612bd);",
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: 36,
      },
      menuButtonHidden: {
        display: 'none',
      },
      title: {
        flexGrow: 1,
      },
      drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
     list:{
         height:'100%'
     },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      grow:{},
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.text.primary,
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
      fixedHeight: {
        height: 240,
      },
  }));

export default function  ServiceAgreementPage({viewOnly:VIEW_ONLY, agreement, agreementId, policyTextStyle}){


  const {
    SiteData,
  } = useContext(SiteDataContext);
  const classes = useStyles();
  const history = useNavigate();
  const [agreements, setAgreement] = useState(agreement ?? {});
  const [isPreview, setIsPreview] = useState(VIEW_ONLY);
  const [viewOnly,setViewOnly] = useState(VIEW_ONLY??false);
  const params = parseUrlForQueryParams(window.location.href);
const {getAgreement,addAgreement} = useDatabase();
const {enqueueSnackbar} = useSnackbar();
const handleSave = async ()=>{
    console.log(agreements)
    if(!agreements.agreementType)
        agreements.agreementType = "agent"

        if(agreements.name && agreements.policy?.text && agreements.paymentAmount && agreements.paymentType){
            addAgreement(agreements).then((res)=>{
                if(res){
                    enqueueSnackbar("Agreement Saved successfully",{
                        variant:'success'
                    })
                    history('/service_bookings')
                }else{
                    enqueueSnackbar("Failed to Save agreement",{
                        variant:'error'
                    })
                }

            })
        }else{
            enqueueSnackbar("Please fill all fields",{
                variant:'error'
            })
        }
}

  useEffect(()=>{
   console.log(agreements);
   if(params.view==='true'){
      setViewOnly(true);
      setIsPreview(true);
    }
    if(agreements?.policy){ 
       
        setAgreement(agreements)
    }else{
         const id = agreementId ?? params.id ?? parseUrlForId(history.location.pathname);
 
         getAgreement(id).then((res)=>{
        if(res){
           
            setAgreement(res);
        }else{
            setAgreement({
                id:id,
                dateCreated:Date.now(),
                name:'',
                agreementType:'agent',
                policy:{
                    text:'',
                    style:{}
                },
                paymentAmount:'',
                paymentType:'percentage',
                createdBy:'--'
            })
        }
    })
    }
   
  },[agreements.policy?.text])
const BuildBody = ()=>{
 
  return <Column style={{padding:20}}>
   {viewOnly ? <></> : <Typography variant="title">Create Agreement</Typography>}
    <div style={{height: 10}}/>
   {viewOnly ?<Typography variant="h6">{agreements.name}</Typography> :  
    <Input placeholder="Agreement Title" value={agreements.name} onChange={(e)=>{
        setAgreement({
            ...agreements,
            name:e.target.value
        })
    }}/>}
    <div style={{height: 20}}/>
    {viewOnly ? <></>:<Row>
        <Chip label="Agreement between Agent & Customer" color={agreements.agreementType === 'customer' ? 'primary' : 'default'} onClick={()=>{
            setAgreement({
                ...agreements,
                agreementType:'customer'
            })
        }}/>
        <div style={{width: 10}}/>
        <Chip label={`Agreement between ${SiteData.companyName} & Agent`} color={agreements.agreementType === 'agent' ? 'primary' : 'default'} onClick={()=>{
            setAgreement({
                ...agreements,
                agreementType:'agent'
            })
        }}/>
        </Row>}
    <div style={{height: 20}}/>
    {<>
      {viewOnly?<></>:<Typography variant="caption" color={'textSecondary'}>{`${agreements.agreementType === 'customer'  ? 'Please enter a deposit charge' : 'Please enter on the rate/fee for this service'}`}</Typography>}
    <div style={{height: 20}}/>
    {viewOnly ? <Typography variant="caption">{
        agreements.agreementType === 'customer' ? `A Deposit of ${agreements.paymentType === 'percentage' ? `${agreements.paymentAmount}%` : `€${agreements.paymentAmount}`} Must be paid before the service is rendered` : 
        `DearfxchTv is entitled to ${agreements.paymentType === 'percentage' ? `${agreements.paymentAmount}% of Revenue from this service`:`€${agreements.paymentAmount} from the total Revenue`}`
    }</Typography> :  <Row> <TextField 
            variant="outlined"
            placeholder="Paymeny Amount"
            type="number"
            value={agreements.paymentAmount}
            onChange={(e)=>{
                setAgreement({
                    ...agreements,
                    paymentAmount:e.target.value
                })
            }}
            
         />
        <div style={{width: 10}}/>
        <Select variant="outlined" displayEmpty={false} value={agreements.paymentType??'percentage'} defaultValue={'percentage'} defaultChecked={'percentage'} onChange={(e)=>{
            setAgreement({
                ...agreements,
                paymentType:e.target.value
            })
        }} style={{width: 130}}>
            <MenuItem value={"percentage"}>Percentage</MenuItem>
            <MenuItem value={"fixed"}>EUR</MenuItem>
        </Select>
    </Row>}
    </>}
    {viewOnly ? <></> : <>
    <Typography variant="caption" color={'textSecondary'}>{`Please enter the cost for this agreement`}</Typography>
    <Row>
       <TextField 
            variant="outlined"
            placeholder="Agreement Cost"
            type="number"
            value={agreements.cost??0}
            onChange={(e)=>{
                setAgreement({
                    ...agreements,
                    cost:e.target.value
                })
            }}
            
         />
    </Row></> }
   {viewOnly?<></>:<><div style={{height: 20}}/>
    <Typography variant="caption" color={'textSecondary'}>Please Write a well detailed policy, as this will be legally binding</Typography>
    </>}
    <div style={{height: 20}}/>
    <Divider/>
    <div style={{height: 20}}/>
    {viewOnly?<></>:<Row style={{margin:"20px 0"}}>
        <Chip onClick={
            ()=>{
                setIsPreview(false)
            }
        } label="Write" variant={isPreview ? "outlined":"default"} style={{marginRight: 10}}/>
        <Chip onClick={
            ()=>{
                setIsPreview(true)
            }
        } label="Preview" variant={isPreview ? "default":"outlined"} style={{marginRight: 10}}/>
    </Row>}
    {!agreements?.policy ?<></>:<MarkdownTextField defaultValue={agreements.policy?.text} viewMode={isPreview} onStyleUpdate={
        (style)=>{
            setAgreement({
                ...agreements,
                policy:{
                    ...(agreements.policy??[]),
                    style:style
                }
            })
        }
    }  style={policyTextStyle ?? agreements.policy?.style} placeholder={"Describe Agreement"} onChange={(e)=>{
        setAgreement({
            ...agreements,
            policy:{
                ...(agreements.policy??[]),
                text:e
            }
        })
    }}/>}
    <div style={{height: 20}}/>
    <Divider/>
    <div style={{height: 20}}/>
   {viewOnly ? <></> : <Button variant="contained" color="primary" onClick={handleSave}>Save Agreement</Button>}

  </Column>
}

   return (
   
        viewOnly ? BuildBody() :
        <div className={classes.root}>
        <CssBaseline/>
        <AppBarModule page={-1}/>
    <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
         {BuildBody()}
        </Container>
      </main>
  
    </div>
  );
}

ServiceAgreementPage.propTypes = {
    viewOnly: PropTypes.bool,
    agreement: PropTypes.object,
    agreementId: PropTypes.string,
    policyTextStyle: PropTypes.object
}