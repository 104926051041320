/* eslint-disable react-hooks/exhaustive-deps */

import { alpha, Card, CardActionArea, CardContent, CircularProgress, Dialog, Grid, IconButton, InputAdornment, makeStyles, OutlinedInput, Snackbar, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Close, Search } from '@material-ui/icons';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../../index.css";
import backgroundVideo from "../../assets/backgroundVideo.mp4";
import banner from "../../assets/STORY.png";
import { useNavigate } from 'react-router';
import { AppBarModule } from '../../components/_app_bar_mod';
import Stack from '../../components/Stack';
import Column from '../../components/Column';
import Row from '../../components/Row';
import useServiceBooking from '../../contexts/ServiceBookingProvider';
import AgentCard from '../../components/profile/agent/_agent_card';
import LoginRequired from '../../components/_login_required';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background:"black",
        overflowY:'hidden',
        maxWidth:'100%',
        flexGrow: 1,
        '& .MuiDivider-root':{
            backgroundColor:'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root':{
            color: 'white'
        }
      },
      iconBtn:{
        '&:hover': {
            color:"white"
        },
      },
      sideBarSocials:{
          color:"#ffffff47",
          fontSize:"12px",
        '&:hover': {
            color:"white"
        },
      },
      divider:{
          backgroundColor: "rgba(1,1,1, 1)"
      },
      center:{
        textAlign:"center",
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      gridList: {
        width: 500,
        height: 450,
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
      toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
     
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        padding:"3px 1px",
        position:"fixed",
       // background:"linear-gradient(45deg, black, #d09612bd);",
        background: "transparent",
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      socialIcon:{
        color:"#ffffff47",
        '&:hover': {
            color:"white"
        },
      },
      menuButtonHidden: {
        display: 'none',
      },
      title: {
        flexGrow: 1,
      },
      listItem:{
        backgroundColor: "#2C2C2C",
        color: "white",
        margin:10
      },
      listItemImage:{
        height:"60px",
        width:"60px",
        margin:"10px"
      },
     list:{
        transition: theme.transitions.create(["background", "background-color"], {
            duration: theme.transitions.duration.complex,
          }),
          "&:hover": {
            backgroundColor: "#6363638c",
            color:"black",
          },
     },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      grow:{
          '&:hover':{
            transform: 'scale(1.1)',
            easing:"linear",
            cursor: 'pointer'
          }
      },
      link:{
          color:"grey",
        '&:hover':{
            transform: 'scale(1.1)',
            cursor: 'pointer',
            color:'white'
          }
      },
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
      mobileNav:{
          width:"60%"
      },
      pcNav:{
          width:"30%"
      },
      logo:{
          height:"60px",
          width:"60px"
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.text.primary,
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
      fixedHeight: {
        height: 240,
      },
  }));

///
const BookingServicesPage = ()=>{
  const theme= useTheme()
  const history = useNavigate();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] =useState(false);
  const [notificationMsg, ] =useState('');
  const [filters, updateFilters] = useState([]);
  const [selectedFilter, updateSelectedFilter] = useState("PHOTOGRAPHER");
  const [filteredAgents, updateFilteredAgents] = useState([]);
  const { serviceAgents, getAgents, getServiceTypes, streamAgents } = useServiceBooking();
  const searchRef = useRef(null);
  const [loading, setLoading] = useState(true);


  const filteragents = (filter,agents) => {
   
   
    console.log(serviceAgents,agents);
    let filtered = agents.filter((agent) => {
      return agent.serviceType.name === filter;
    });
    updateFilteredAgents(filtered);
  }
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      getServiceTypes().then((types) => {
        updateFilters(Object.values(types).map((type) => type.name));
        
      
      });
     
     
     };
     fetchData();
     const unsubscribe =  streamAgents((this_agents) => {
          const  agents = Object.values(this_agents ?? serviceAgents ?? {});
          filteragents(selectedFilter, agents.filter(agent=>agent.active && agent.serviceAgreement));
          setLoading(false);
        });
    
      return unsubscribe;
    },[])


  const _buildFilters = () => {
    return <Grid alignContent='center' alignItems='center' container spacing={2} style={{ width: "100%", margin: 0 }}>
      {
        filters.map((filter, index) => {
          return <Grid  key={index} justifyContent='center' item xs={12} sm={12} md={4} lg={4} xl={4}>
            {
              <motion.div whileHover={{ scale: 1.1 }} style={{filter: selectedFilter === filter ? 'none' : 'greyscale'}}>
                <Card style={{ height: 100 }}>
                  {selectedFilter !== filter ? <CardActionArea onClick={() => { updateSelectedFilter(filter);
                    getAgents().then((_agents) => {
                      console.log(_agents);
                      const agents = Object.values(_agents ?? serviceAgents ?? {});
                      filteragents(filter, agents.filter(agent=>agent.active && agent.serviceAgreement))
                    })
                    }} button style={{width:"100%",height:"100%"}}>
                    <Column><Typography>{ filter}</Typography></Column>
                  </CardActionArea> : <CardContent style={ { width:"100%", height:"100%", position:"relative", padding:0 } }>
                      <Stack>
                      <img alt="" src={banner} style={{ objectFit: "cover",filter:"blur(5px)", width:"100%" }} />
                      <div style={ { width:"100%", height:"100%", background: "linear-gradient(-90deg, #ffc500, black)", opacity:0.8 } } />
                        <Column style={{width:"100", height:"100%"}}><Typography style={{color:"white",fontWeight:"bold"}}>{ filter}</Typography></Column>
                      </Stack>
            </CardContent>  
                  }
                
          </Card>
          </motion.div>
      }
          </Grid>
        })
    }  
    </Grid>
  }
  const handleSearch = () => {
    let search = searchRef.current.value;
    let agents = Object.values(serviceAgents);
    let filtered = agents.filter((agent) => {
      return agent.serviceType.name === selectedFilter && agent.name.toLowerCase().includes(search.toLowerCase());
    });
    updateFilteredAgents(filtered);
  }
  //onClick={() => { history(`/booking/${agent.id}`) }} 
  const _buildAgents = () => {
    return <Grid container spacing={10} style={{ width: "100%", margin:0}}>
      {
        filteredAgents.map((agent, index) => {
          return <Grid key={index} item xs={6} sm={6} md={4} lg={4} xl={4}>
            <motion.div whileHover={{scale: 1.02}}  onClick={()=>{
               history(`/booking/${agent.id}`) 
            }}>
             <AgentCard style={{ height: 260 }} agent={agent} />
            </motion.div>
          </Grid>
        })
      }
    </Grid>
  }
  const _buildSearchBar = () => {
    return <div style={{ width: "50vw", marginTop:10 }}>
      <Row alignment="centered">
        <OutlinedInput
          id="outlined-adornment-password"
          type={'text'}
          fullWidth
          placeholder="Search Agents"
          inputRef={searchRef}
          inputProps={{
            style: {
              color: alpha(theme.palette.text.primary, 1),
              paddingTop: "15px",
              borderColor: "white"
            }
          }}
          endAdornment={
            <InputAdornment position="end" >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleSearch}
                edge="end"
                className={classes.iconBtn}
              ><Search />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </Row>
    </div>
  }
  const _buildForeground = () => {
    return loading ? <Column style={{width:'100%', height: '100%', alignItems:'center'}}>
      <CircularProgress/>
    </Column>: <Column alignment={ "start" } style={{ width: "100%", maxWidth:900, margin:"90px auto" }}>
      <Typography style={ { fontSize: "6vh", textTransform:"uppercase" } }>Service Booking</Typography>
      
      <Typography variant="overline">What Would you like to book</Typography>
      <div style={{ height: "20px" }} />
      {_buildFilters()}
      <div style={{ height: "10px" }} />
      {(filteredAgents?.length ?? 0) <= 0 ? <Column>
      <Typography variant="overline">No Agents Found</Typography>
      </Column> : _buildAgents()}
    </Column>
  }
  const _buildBackground = () => {
    return <Stack>
       <div style={{background:"#0000000d", height:"100%", width:"100%"}}></div>
      <video playsInline src={backgroundVideo} style={{ height: "100%", width:"100%", objectFit:"cover" }} />
      <div style={{background:"radial-gradient(circle, rgb(0 0 0 / 96%), black)",height:"100%", width:"100%"}}></div>
           
    </Stack>
  }
  const _buildPage = (()=>{
      //...
     
      return <div style={{width:"100%", height:"100%"}}>
        <Stack style={{width: "100%",height:"100%"}} >
          {_buildBackground()}
          { _buildForeground() }
        </Stack>
      </div>
  });

 return (<div className={classes.root}>
         <CssBaseline />
         <Stack>
           {_buildPage()}
         </Stack>
   {<AppBarModule pageIndex={7} centerModule={_buildSearchBar()} appbarBackground={ "black" } cartClick={true} showCart={true} style={{ width: "100%",paddingRight:20}}/>}
         <Dialog
      maxWidth="lg"
      onClose={()=>{setShowModal(false)}}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
        {preview_body}
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={notificationMsg.timeout}
        onClose={()=>{
          showNotification(false)
        }}
        message={notificationMsg.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{  showNotification(false)}}>
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
      <LoginRequired/>
    </div>
    
    )

   
}
export default BookingServicesPage;