import { CircularProgress, FormControl, IconButton, InputLabel, OutlinedInput } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useContext, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import backgroundVideo from '../../assets/backgroundVideo_short.mp4';
import logo from '../../assets/logo.png';
import Column from '../../components/Column';
import Row from '../../components/Row';
import Stack from '../../components/Stack';
import firebase_app from '../../config/firebase';
import { SiteDataContext } from '../../config/SiteData';
import { useMediaQuery } from '../../contexts/MediaQueryProvider';
import shortUUID from 'short-uuid';
import { useStripePaymentContext } from '../../contexts/StripeProvider';
import { useProfile } from '../../hooks/useProfile';
import { updateProfileDB } from '../../logic/databaseHandler';
function Copyright() {
  const {
    SiteData,
  } = useContext(SiteDataContext)
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        {SiteData.name}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  background: {
    width: "100%",
    height: "100%"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function LoginPage() {
  const theme = useTheme();
  const [errorEmail, ] = useState(false);
  const [error, setErrorMsg] = useState('');
  const [validEmailText, ] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [validPasswordText, setValidPasswordText] = useState(false);
  const [currentImage, ] = useState({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fname, ] = useState('');
  const [lname, ] = useState('');
  const [isSigningUp, ] = useState(false);
  const [passwordToggle, togglePassword] = useState(false);

  const location = useLocation();
  const {device} = useMediaQuery();
  const classes = useStyles();
  const history = useNavigate();

    const {
      createCustomer
    } = useStripePaymentContext();
    const {retrieveProfile} = useProfile();

    const emailRef = useRef(null);
    const passwordRef = useRef(null);

  const _buildVersion1 = () => {
    return <Stack style={{ width: '100%', height: "100%" }}>
      <div style={{ width: "100%", height: "100%", filter: "blur(30px)" }}>
        <video playsInline src={backgroundVideo} width="100%" height="100%" loop={true} style={{ objectFit: "cover" }} autoPlay={true} muted={true}></video>
      </div>
      <div className="centered" style={{ filter: "blur(17px)", width: "100%", height: "70%" }}>
        <video playsInline src={backgroundVideo} width="100%" height="100%" loop={true} style={{ objectFit: "cover" }} autoPlay={true} muted={true}></video>
      </div>
      <div className="centered" style={{ width: device !== 'pc' ? "100%" : "50%", background: "#ffffff17", borderRadius: "10px", height: "80%"}}></div>
      {device !== 'pc' ? <div style={{ width: "100%", height: "100%", background: alpha(theme.palette.background.paper, 0.67) }}></div> : <></>}
      <div className="centered" style={{ padding: "2", width: device !== 'pc' ? "100%" : "", maxWidth: 400, overflow: 'hidden' }}>
        {isSigningUp ? _buildRegistering() : _buildLogin()}
      </div>

    </Stack>
  }

  const handleSubmit = async () => {

    try {
      let user = await firebase_app.auth().signInWithEmailAndPassword(emailRef.current.value, passwordRef.current.value);
      retrieveProfile(user.user.uid).then((profile) => {
        if(!profile.stripeCustomerId){
          createCustomer({
              email:profile.email,
              name:`${profile.fname} ${profile.lname}`,
          }).then((res)=>{
          
              
              updateProfileDB(profile,{"stripeCustomerId": res.id});
          })
      }
      })
      
      if(location.state && location.state?.from) {
        if(location.state.from === 'VideoSubmission') {
          history("/" + user.user.uid + "/Video", {replace: true})
        }else if(location.state.from === 'StorySubmission') {
          history(`/${user.user.uid}/Story?draft=true&id=${shortUUID().generate()}`)
        }
      }
      else{
      history("/", {replace: true});}
    } catch (e) {
      console.log(e);
      setErrorMsg(e.message);
    }

  }
  const verifyPassword = () => {
    var passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g
    if (passReg.exec(password)) {
      setErrorPassword(false);
      setValidPasswordText("Strong password");
    }
    else {
      setErrorPassword(true);
      setValidPasswordText("Weak password");
    }
  }
  const _buildLogin = () => {
    return <div className={classes.paper}>
      <Avatar src={logo} className={classes.avatar} style={{ margin: "20px 0", height: "40px", width: "40px" }} />
      <Typography style={{ margin: "0 0 0px 0", color: theme.palette.text.secondary, fontSize: "14px" }} variant="overline">WELCOME BACK</Typography>
      {device !== 'pc' ? <></> : <Typography component="h1" variant="h5">Login</Typography>}

      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          inputRef={emailRef}
          fullWidth
          id="email"
          error={errorEmail}
          helperText={errorEmail ? validEmailText : ""}
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onKeyUp={(evt) => {
            let val = evt.target.value;
            setEmail(val);
          }}
          onChange={
            (evt) => {
              let val = evt.target.value;
              setEmail(val);
            }
          }
        />
        <FormControl fullWidth className={`${classes.margin} ${classes.textField}`} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>

          <OutlinedInput
            variant="outlined"
            margin="normal"
            name="password"
            label="Password"
            inputRef={passwordRef}
            endAdornment={<IconButton onClick={() => { togglePassword(!passwordToggle) }}>
              {passwordToggle ? <Visibility /> : <VisibilityOff />}
            </IconButton>}
            error={errorPassword}
            helperText={errorPassword ? validPasswordText : ""}
            type={passwordToggle ? "text" : "password"}
            id="password"
            required
            autoComplete="current-password"
            onKeyUp={(evt) => {
              let val = evt.target.value;
              setPassword(val);
              verifyPassword();
            }}
          />
        </FormControl>

        <Typography variant="caption" style={{ color: "red" }}>{error}</Typography>

        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={errorEmail || errorPassword}
        >
          Login
        </Button>
        <Grid container>
          <Grid item xs={12}>
            <Link href="/forgot_password" variant="body2">
              Forgot password?
            </Link>
          </Grid>

          <Grid item xs={12}>
            <div style={{ height: "10px" }}></div>
            <Link href="/register" variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </div>
  }
  const _buildRegistering = () => {
    return <Row alignment="centered" style={{ width: "100%" }}>
      <Column alignment="centered">
        <Row alignment="centered">
          <Avatar style={{ width: 70, height: 70, margin: 10 }} src={currentImage?.blob} />
        </Row>

        <Typography align="center" variant={"overline"}>{email}</Typography>
        <Typography align="center" variant={"overline"}>{`${fname} ${lname}`}</Typography>
        <div style={{ height: "20px" }}></div>
        <Column>
          <Row alignment="centered" style={{ margingTop: "20px", width: "100%" }}>
            <CircularProgress />
          </Row>
          <div style={{ height: "20px" }}></div>
          <Typography align="center" variant={"caption"}>{"Signing you up..."}</Typography>
        </Column>



      </Column>
    </Row>
  }

  const _buildVersion2 = () => {
    return <Row className={classes.root} style={{ width: "100%", height: "100%" }}>

      <div style={{ width: "60%", height: "100%" }}>
        <video playsInline src={backgroundVideo} width="100%" height="100%" loop={true} style={{ objectFit: "cover" }} autoPlay={true} muted={true}></video>
      </div>

      {isSigningUp ? _buildRegistering() : <Row style={{ width: "40%", padding: "70px" }} alignment="centered" className={classes.paper}>
        {_buildLogin()}
      </Row>}
    </Row>

  }
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CssBaseline />
      {device === 'xs' ? _buildVersion1() : _buildVersion2()}

    </div>
  );
}