/* eslint-disable no-extend-native */
/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
/*eslint no-extend-native: ["error", { "exceptions": ["Object"] }]*/

import { alpha, Paper, ThemeProvider } from '@material-ui/core';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import React, { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './App.css';
import EventProvider from './contexts/EventProvider';
import PlaylistProvider from './contexts/PlaylistProvider';
import ReleasesProvider from './contexts/ReleasesProvider';
import ShowProvider from './contexts/ShowProvider';
import StoryProvider from './contexts/StoryProvider';
import { Colors, ThemeChangerContext } from './contexts/ThemeChangerProvider';
import UserProvider from './contexts/UserProvider';
import WalletProvider from './contexts/WalletProvider';
import AboutUsPage from './pages/aboutuspage';
import ForgotPassword from './pages/auth/auth_forgot_password';
import LoginPage from './pages/auth/auth_login_page';
import RegisterPage from './pages/auth/auth_register_page';
import BookingPage from './pages/booking_page';
import ContactPage from './pages/contact_page';
import EventsPage from './pages/events_page';
import HomePage from './pages/homepage';
import PlaylistsPage from './pages/playlists_page';
import ProfilePage from './pages/profile_page';
import ReleasesPage from './pages/releases_page';
import ShowsPage from './pages/shows_page';
import ShowPage from './pages/show_page';
import StoryPage from './pages/stories_page';
import StoryAuthorPage from './pages/story_author';
import ViewStoryPage from './pages/story_page';
import StoryPagePreview from './pages/story_page_preview';
import StorySubmissionPage from './pages/story_submission_page';
import VideoSubmissionPage from './pages/video_submission_page';
import WatchShowPage from './pages/watch_show_page';
import './utils.css';
//import BookingCardPage from './pages/BookingCardPage';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { SnackbarProvider } from 'notistack';
import 'semantic-ui-css/semantic.min.css';
import MediaQueryProvider from './contexts/MediaQueryProvider';
import { StorageHandlerProvider } from './contexts/storage_handler';
import { StripeProvider } from './contexts/StripeProvider';
import TicketPage from './pages/ticketpage';
import AdSense from 'react-adsense';
import BookingServicesPage from './pages/booking/booking_services';
import ServiceAgentPage from './pages/booking/service_agent_page';
import PageNotFound from './pages/page_not_found';
import firebase_app from './config/firebase';
import PackageSelection from './pages/package_selection';
import PaymentPage from './pages/checkout/payment_page';
import BookAgentRoom from './pages/booking/book_agent_room';
import ServiceBookingPage from './pages/profile/service/service_booking_page';
import SingleVideoSubmissionPage from './pages/SingleVideoSubmissionPage';
import SingleStorySubmissionPage from './pages/SingleStorySubmissionPage';
import SiteDataProvider from './contexts/SiteDataProvider';
import SettingPage from './pages/setting_page';
import { CustomDialogProvider } from './contexts/CustomDialogProvider';
import AgentSettingsPage from './pages/agent_settings_page';
import ServiceAgreementPage from './pages/serviceAgreementPage';
import ServiceProjectPage from './pages/profile/service/service_project_page';
import { NotificationProvider, NotificationSnackbar } from './contexts/NotificationProvider';
import { useSelector } from 'react-redux';
import { ModalDialogProvider } from './contexts/ModalDialogProvider';
import Marquee from "react-fast-marquee";

import dotenv from 'dotenv';
dotenv.config();


Date.prototype.addDays = function (days) {
  this.setDate(this.getDate() + parseInt(days));
  return this;
};
Date.prototype.removeDays = function (days) {
  this.setDate(this.getDate() - parseInt(days));
  return this;
};
String.prototype.replaceByIndex = function (startIndex, endIndex, replacement) {
  return this.substring(0, startIndex) + replacement + this.substring(endIndex);
};
Date.prototype.addHours = function(hour) {
  this.setHours(this.getHours() + parseInt(hour));
  return this;
};

function App() {
  const SiteData = useSelector(state => state.siteData.value);
  const location = useLocation();
  const [stripePromise, setStripePromise] = useState(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY));

  const {
    currentTheme,
  } = useContext(ThemeChangerContext);


  TimeAgo.addLocale(en)

  useEffect(() => {
    setStripePromise(() => loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY))
  },[process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY])

  useEffect(() => {
    firebase_app.analytics().logEvent('page_view', {
      page_title: location.pathname,
    });
  },[location])
    console.log(SiteData, '++'); 
  const routes = useRoutes([
    {
      path: '/',
      element: <HomePage />,
    },
    {
      path: '/package',
      element: <Navigate to="/package/video" />,
      
    },
        {
          path: '/package/:id',
          element: <PackageSelection />,
        },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'register',
      element: <RegisterPage />,
    },
    {
      path: '/:id',
      element: <ProfilePage />,
    },
    {
      path: 'forgot_password',
      element: <ForgotPassword />,
    },
    {
      path: 'about',
      element:  !(SiteData?.liveSections?.about) ?  <PageNotFound/> : <AboutUsPage />,
    },
    {
      path: 'contact',
      element:  !(SiteData?.liveSections?.contact) ?  <PageNotFound/> : <ContactPage />,
    },
    {
      path: 'noir-studios',
      element:  !(SiteData?.liveSections?.noir_studios) ?  <PageNotFound/> : <BookingPage />,
    },
    {
      path: 'bookings',
      element: SiteData?.liveSections?.bookings ? <BookingServicesPage /> : <PageNotFound/>,
    },
    {
      path: 'booking/:id',
      element:  !(SiteData?.liveSections?.bookings) ?  <PageNotFound/> : <ServiceAgentPage />,
    },
    {
      path: 'booking/book:id/',
      element:  !(SiteData?.liveSections?.bookings) ?  <PageNotFound/> : <BookAgentRoom />,
    },
    {
      path: 'payment',
      element:  !(SiteData?.liveSections?.bookings) && (!(SiteData?.liveSections?.submissions) || !(SiteData?.liveSections?.story_submissions)) ?  <PageNotFound/> : <PaymentPage />,
    },
    {
      path: 'checkout',
      element:  !(SiteData?.liveSections?.bookings) && (!(SiteData?.liveSections?.submissions) || !(SiteData?.liveSections?.story_submissions)) ?  <PageNotFound/> : <PaymentPage />,
    },
    {
      path: 'events',
      element:  !(SiteData?.liveSections?.events) ?  <PageNotFound/> : <EventsPage />,
    },
    {
      path: 'playlists',
      element: !(SiteData?.liveSections?.playlist) ?  <PageNotFound/> :  <PlaylistsPage />,
    },
    {
      path: '/:id/settings',
      element: <SettingPage />,
    },
    {
      path: '/:id/settings/agent',
      element: <AgentSettingsPage />,
    },
    {
      path: 'show',
      element: !(SiteData?.liveSections?.shows) ?  <PageNotFound/> :  <ShowsPage />,
    },
    {
      path: 'show/:id',
      element:  !(SiteData?.liveSections?.shows) ?  <PageNotFound/> : <ShowPage />,
    },
    {
      path: '/:id/Story',
      element:  !(SiteData?.liveSections?.story_submissions) ?  <PageNotFound/> : <StorySubmissionPage />
    },
        {
          path: '/:id/Video',
          element:  !(SiteData?.liveSections?.submissions) ?  <PageNotFound/> : <VideoSubmissionPage />
        },
        {
          path: '/:id/ServiceBooking',
          element:  !(SiteData?.liveSections?.bookings) ?  <PageNotFound/> : <ServiceBookingPage />
        },
        {
          path: '/:id/ServiceProject',
          element:  !(SiteData?.liveSections?.bookings) ?  <PageNotFound/> : <ServiceProjectPage />
        },
        {
          path: '/:id/VideoSubmission',
          element:  !(SiteData?.liveSections?.submissions) ?  <PageNotFound/> : <SingleVideoSubmissionPage />
        },
        {
          path: '/:id/StorySubmission',
          element:  !(SiteData?.liveSections?.story_submissions) ?  <PageNotFound/> : <SingleStorySubmissionPage />
        },
        {
          path: '/service_agreement_page',
          element:  !(SiteData?.liveSections?.bookings) ?  <PageNotFound/> : <ServiceAgreementPage />
        },
    
    {
      path: 'ticket/:id',
      element: <TicketPage />,
    },
    {
      path: 'verifyticket/:id',
      element: <TicketPage />,
    },
    {
      path: 'episode/:id',
      element:  !(SiteData?.liveSections?.shows) ?  <PageNotFound/> : <WatchShowPage />,
    },

    {
      path: 'releases',
      element:  !(SiteData?.liveSections?.releases) ?  <PageNotFound/> : <ReleasesPage />,
    },
    {
      path: 'releases/:id',
      element:  !(SiteData?.liveSections?.releases) ?  <PageNotFound/> : <div><ReleasesPage /></div>
    },
  

    {
      path: 'stories',
      element:  !(SiteData?.liveSections?.stories) ?  <PageNotFound/> : <StoryPage />,
    },
        {
          path: 'stories/search',
          element:  !(SiteData?.liveSections?.stories) ?  <PageNotFound/> : <div><StoryPage /></div>
        },
        {
          path: 'stories/:id',
          element:  !(SiteData?.liveSections?.stories) ?  <PageNotFound/> : <ViewStoryPage />
        },
        {
          path: 'author/:id',
          element:  !(SiteData?.liveSections?.stories) ?  <PageNotFound/> : <StoryAuthorPage />
        },
        {
          path: 'stories/Preview',
          element:  !(SiteData?.liveSections?.stories) ?  <PageNotFound/> : <StoryPagePreview />
        },
      
     
  ]);
  return (
    <>
    
      <AdSense.Google
        client='ca-pub-4749149982351318'
        slot='7806394673'
        style={{ width: "10vw", height: 40, right: 0, bottom: 0, position: "fixed", zIndex: "10000" }}
        format=''
      />
      <SnackbarProvider maxSnack={3}  Components={{
        notification: NotificationSnackbar,
      }}>
        
        <ThemeProvider theme={currentTheme}>
          <CustomDialogProvider>
            
          <ModalDialogProvider>
          <MediaQueryProvider>
            <SiteDataProvider>
            <Elements stripe={stripePromise}>
              <StripeProvider>
                <Paper className={"App"}>

                  <StorageHandlerProvider>
                    <UserProvider>
                      <NotificationProvider>
                      <WalletProvider>
                        <ReleasesProvider>
                          <ShowProvider>
                            <StoryProvider>
                              <EventProvider>
                                <PlaylistProvider>
                                  {
                                    routes
                                  }
                                  {process.env.REACT_APP_ENV !== 'Production' && <Marquee style={{zIndex: 5000, backgroundColor: alpha(Colors.primary, 0.7), pointerEvents:'none', position: "absolute", top: 0}}>
                                  {`Note: This is the ${process.env.REACT_APP_ENV} enviroment, any information here is not reflective of the live site.`}
                                </Marquee>}
                                </PlaylistProvider>
                              </EventProvider>
                            </StoryProvider>
                          </ShowProvider>
                        </ReleasesProvider>
                      </WalletProvider>
                      </NotificationProvider>
                    </UserProvider>
                  </StorageHandlerProvider>
                </Paper> </StripeProvider>
            </Elements>  </SiteDataProvider>
          </MediaQueryProvider> 
          </ModalDialogProvider>
          </CustomDialogProvider>
        </ThemeProvider>
    
      </SnackbarProvider>
    
    </>
  );
}

export default App;
