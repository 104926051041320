/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Chip, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { Close, LibraryBooks, VideoLibraryRounded } from '@material-ui/icons';
import { useSnackbar } from "notistack";
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { addAStudioBookingToCart, removeItemFromCart, removeStudioBookingFromCart } from '../logic/databaseHandler';
import Row from './Row';
import { StudioRoomImage } from './_studio_room_icon';
import { StudioRoomName } from './_studio_room_name';
import FutureBuilder from './_future_builder';
import Logic from '../logic/logic';
import { useProfile } from '../hooks/useProfile';

const CartItem = (props)=>{
    const [item, setItem] = useState(props.item);
    const [isExpired, setExpired] = useState(false);
    const today = new Date().valueOf();
    const {retrieveProfileWithCallback} = useProfile();
    const [profile, setProfile] = useState();
    const [enableDTVCoins, setEnableDTVCoins] = useState(props.enableDTVCoins??false);
    //firebase_app.currentUse        r.uid
        const { enqueueSnackbar,closeSnackbar } = useSnackbar();

    const deleteBooking = async(booking)=>{
        console.log(booking)
         await removeStudioBookingFromCart(profile.uid,booking,props?.onDelete)
        const action = () => (
            <Fragment>
                <Button onClick={() => { 
                    addAStudioBookingToCart(profile.uid,booking).then(()=>{
                        enqueueSnackbar("Booking Added to Cart",{ variant: 'success', anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'center',
                        },})
                    })
                    closeSnackbar(undoKey)
                }}>
                    Undo
                </Button>
            </Fragment>
        );
        
        const undoKey = enqueueSnackbar("Booking removed",{ variant: 'warning', anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        },
        action,
    })
    }
    const handleDeleteCartItem = async()=>{
        console.log(item);
        props.onDelete(item);
        if(item.type === 'shop'){
            //remove shop item
        }else if(item.type === 'submission'){
            //remove submission
            
        }
        else if(item.type === 'booking'){
            //remove booking
            await deleteBooking(item);
            enqueueSnackbar("Booking Removed",{ variant: 'success', anchorOrigin: {horizontal:'center',vertical:'bottom'} });
            return;
        }
        await removeItemFromCart(profile.uid, props.item.cartId);
        enqueueSnackbar("Item Removed",{ variant: 'success', anchorOrigin: {horizontal:'center',vertical:'bottom'} });
        return;
        
    }
    useEffect(()=>{
        retrieveProfileWithCallback((p)=>{
            setProfile(p);
        });
        if(item.type === 'submission'){
            console.log(item);
            setEnableDTVCoins(true);
            setItem({...item.submission})
        }
        setExpired((today > item.startTime) || (today > item.endTime));
    },[])
    return  <ListItem button  disabled = {isExpired}>
                 {item.type==="shop"?<></>:<> 
                 <ListItemAvatar style={{fontSize:14}}>
                    { 
                    item.type === 'video_submission' ? <VideoLibraryRounded/> : 
                    item.type === 'saa' ? <LibraryBooks/> : 
                     <StudioRoomImage id={item.room}/>}
                  </ListItemAvatar> 
                  <ListItemText secondaryTypographyProps={{style:{fontSize:10,textDecoration:!props.viewOnly && isExpired ? "line-through" : "none"}}} primaryTypographyProps={{style:{fontSize:14}}} primary={
                  
                  <Row style={{fontSize:14, alignItems: 'center'}}>
                      {
                      item.type === 'video_submission' ? <Typography style={{maxWidth:"80%"}}>{`Video Submission | ${item.title}`}</Typography>:
                      item.type === 'saa' ? <Typography style={{maxWidth:"80%"}}>{`${item.title}`}</Typography>:
                       <StudioRoomName style={{fontSize:14}} id={item.room}/>}
                      <div style={{width:10}}/>
                      
                     </Row>} 
                          secondary={ 
                            item.type==='video_submission' || item.type==='saa' ? item.summary : 
                          
                          (`${new Intl.DateTimeFormat("en-US", {year:"numeric",month:"long",day:"numeric", hour: "numeric",minute:"numeric",hour12:false}).format(item.startTime)} - ${new Intl.DateTimeFormat("en-US", { hour: "numeric",minute:"numeric",hour12:false}).format(item.endTime)}`)} />
                          </>}     
                           <ListItemSecondaryAction>
                               <Row> {props.hidePrice ? <></>:<FutureBuilder
                      rebuild
                      futureFunc = {()=>Logic.toCountryCurrency((enableDTVCoins ? ((item.price ?? 0) / 10): 
                      item.type?.includes("submission") ? ((item.price??0) / 10) : (item.price??0)).toFixed(2) )}
                      builder = {(cost)=><Typography style={{fontSize:14, textDecoration:!props.viewOnly && isExpired ? "line-through" : "none"}}>
                      {cost}
                      </Typography>}
                      placeholder = {<Typography style={{fontSize:14}}>{`€${((enableDTVCoins ? ((item.price ?? 0) / 10) : 
                      item.type?.includes("submission") ? ((item.price??0) / 10) :
                      (item.price??0))).toFixed(2)}`}</Typography>}
                        errorChild = {<Typography style={{fontSize:14}}>xx</Typography>}
                      />}
                                   {!props.viewOnly && isExpired ? <Chip variant="outlined" size="small" style={{borderColor:"#e63946",color:"#e63946",fontSize:"10px"}} label = "expired" /> :<></>}
                                   <div style={{width:"8px"}}/>
                                   {props.viewOnly ? <></> : <IconButton style={{fontSize:8}} edge="end" aria-label="delete" onClick={handleDeleteCartItem}>
                      {<Close style={{fontSize:16}}/>}
                    </IconButton>}
                               </Row>
                
                </ListItemSecondaryAction>
                </ListItem>
}
CartItem.propTypes = {
    item:PropTypes.object,
    viewOnly:PropTypes.bool,
    hidePrice: PropTypes.bool,
    onDelete:PropTypes.func,
    enableDTVCoins:PropTypes.bool

}
export default CartItem;