/* eslint-disable react-hooks/exhaustive-deps */

import { Button, CircularProgress, Dialog, Divider, alpha, Grid, IconButton, InputAdornment, makeStyles, OutlinedInput, Typography, useTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ChevronLeft, ChevronRight, Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Center from '../components/Center';
import Column from '../components/Column';
import DropdownModule from '../components/Dropdown';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { AppBarModule } from '../components/_app_bar_mod';
import { StoryCard } from '../components/_story_mod';
import { useStoryProvider } from '../contexts/StoryProvider';
import "../index.css";
import { getUserProfile } from '../logic/databaseHandler';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    },
    '& label.Mui-focused': {
      color: '#e49726',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#e49726',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: '#e49726',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#b77514',
      },
      '&:hover fieldset': {
        borderColor: '#b77514',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#e49726',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.24)',
      color: '#e49726',
      borderColor: "#e49726"
    },
    //  '& .MuiPaginationItem-root':{
    //    border:"1px solid rgba(255, 255, 255, 0.23)",
    //    color:"white",
    //    '&:hover':{
    //     backgroundColor: 'rgba(255, 255, 255, 0.14)',
    //     border:"1px solid rgba(255, 255, 255, 0.53)",
    //     cursor:"pointer"
    //    }
    //  },
    '& .MuiCircularProgress-colorPrimary': {
      color: "white"
    }

  },
  iconBtn: {
    color: alpha(theme.palette.text.primary, 0.5),
    '&:hover': {
      color: alpha(theme.palette.text.primary, 1)
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer',
      color: "white"
    }
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  textField: {
    '&focus': {
      borderBottomColor: '#e49726'
    }
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  pagination: {
    color: "white"
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const StoryPage = () => {
  const SiteData = useSelector(state => state.siteData.value);
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [device, setDevice] = useState('pc');
  const [, setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [search, setSearch] = useState('');
  const [searchResults, showSearchResults] = useState(false);
  const [currentSlide, setSlide] = useState(0);
  const [loadResults, setLoadResults] = useState(false);
  const [maxPageSize, ] = useState(SiteData.storyPage.maxPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, setFilter] = useState("all")
  const [sort, setSort] = useState("none");
  const [featuredStory, setFeaturedStory] = useState(null);
  const [q_maxPageSize, ] = useState(8);
  const [q_currentPage, setQCurrentPage] = useState(1);
  const [loadingStories, setLoadingStories] = useState(false);

  const searchRef = useRef(null);
  const {
    allStories,
    maxStorySize,
    queryResults,
    maxQueryResultsSize,
    getAllStories,
    getAllStoriesByPublished,
    getAllStoriesByPopular,
    getAllStoriesByTrending,
    getDTVStories,
    getStoriesBetween,
    queryStories,
    lastStoryCursor
  } = useStoryProvider();

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/stories/search') {
      showSearchResults(location.state.showSearch);
      setSearch(location.state.search);
      showQueryPage(1, location.state.search)
    }
    else {
      console.log(SiteData?.storyPage?.featured)
       if (SiteData?.storyPage?.featured)
      setFeaturedStory(SiteData.storyPage.featured.filter((x) => x!==null && x.storyId));
      showPage();
    }
    updateDevice();
   window.addEventListener("resize", updateDevice);
  }, [])

  const handlePageChange = async (e, page) => {
    setCurrentPage(page);
    await showPage(lastStoryCursor,maxPageSize * (page - 1),filter);
  }
  const q_handlePageChange = async (e, page) => {
    setQCurrentPage(page);
    await showQueryPage(search, lastStoryCursor,maxPageSize * (page - 1),filter);
  }
  const showPage = async (cursor,offset,type, s) => {
    setLoadingStories(true);
    switch (type) {
      case 'all':
        
          switch (s) {
          case 'rel': getAllStoriesByPublished(cursor, maxPageSize,offset,'desc' ); break;
          case 'pop': getAllStoriesByPopular(cursor,maxPageSize,offset,'desc'); break;
          default: getAllStories(cursor, maxPageSize,offset);
        }
      
      break;
      case 'trending': getAllStoriesByTrending(cursor, maxPageSize,offset); break;
      case 'dtv': getDTVStories(cursor, maxPageSize,offset); break;
      case 'today': getStoriesBetween(new Date().removeDays(1).valueOf(), new Date().valueOf(), cursor,maxPageSize,offset); break;
      case 'this_week': getStoriesBetween(new Date().removeDays(7).valueOf(), new Date().valueOf(), cursor, maxPageSize,offset); break;
      case 'this_month': getStoriesBetween(new Date().removeDays(31).valueOf(), new Date().valueOf(), cursor, maxPageSize,offset); break;
      case 'this_year': getStoriesBetween(new Date().removeDays(365).valueOf(), new Date().valueOf(), cursor, maxPageSize,offset); break;
      default: 
        await getAllStories(cursor, maxPageSize,offset);
       break;
    }
    setLoadingStories(false);
  }
  const showQueryPage = async (s,cursor,offset) => {
    await queryStories(s,cursor, maxPageSize,offset);
    setLoadResults(false)
  }
  const updateDevice = () => {
    if (window.innerWidth < 550) {
      setDevice('mobile');
      setDrawerWidth(300)
    }
    else {
      setDevice('pc')
      setDrawerWidth(280)
    }
  }
  const _buildPageBackGround = (() => {
    //animate = {{opacity:1-scrollPos}}style={{opacity:1-scrollPos}}
    return !featuredStory ? <></> : <motion.div style={{ width: '100%' }}>
      <Stack>
        <Slider
          dots={false}

          slidesToScroll={featuredStory.length}
          slidesToShow={1}
          autoplay={true}
          infinite={true}
          afterChange={(index) => { setSlide(index) }}
          cssEase="linear"
          autoplaySpeed={10000}
        >
          <div style={{ width: '100%', height: '100%' }}>
            {
              featuredStory.map((data, i) =>
                <div key={i} className="video-wrapper" style={{ width: '100%', height: '100%' }} >
                  <img alt={`Featured ${i}`} style={{ width: "100%", height: "100%", objectFit: "cover" }} src={data.photo} />
                </div>)
            }
          </div>

        </Slider>
        <Column alignment="space-between" style={{ height: "90%", width: "100%", }}>
          <div style={{ background: `linear-gradient(180deg, ${alpha(theme.palette.background.default, 1)} 20%,${alpha(theme.palette.background.default, 0.7)} 54%, transparent)`, height: "50%", width: "100%" }}></div>
          <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 20%,${alpha(theme.palette.background.default, 0.5)} 54%, transparent)`, height: "70%", width: "100%" }}></div>
        </Column>
        <Column alignment="space-between" style={{ height: "12vw", width: "100%", padding: device === 'pc' ? "7% 5% 15% 2%" : "20% 5% 15% 2%", position: "absolute", zIndex: "1000" }}>
          <Row alignment="space-between">
            <Typography align="left" variant="overline" style={{ fontSize: "20px" }}>Top Story</Typography>
            <Column alignment="centered">
              <Button onClick={() => {
                history(`/stories/${featuredStory[currentSlide].storyId}`, { state: { story: featuredStory[currentSlide] } })
              }}>
                Full Story
              </Button>
            </Column>
          </Row>

          <Row alignment="space-between" style={{ pointerEvent: "none" }} >
            <Column>
              <Typography align="left" style={{ fontSize: "3.5vw", width: "85%", fontFamily: "raleway", textTransform: "capitalize", fontWeight: "700" }}>{featuredStory[currentSlide].headline}</Typography>
              <Typography align="left" style={{ fontSize: "20px", color: alpha(theme.palette.primary.dark, 0.9), width: "85%", fontFamily: "raleway", textTransform: "capitalize", fontWeight: "500" }}>By {featuredStory[currentSlide].author}</Typography>
            </Column>

          </Row>
        </Column>
      </Stack>
    </motion.div>
  });
  const _buildPage = (() => {
    //...
    return <Column alignment="start" style={{ height: "100%" }}>

      <div style={{ width: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 90%,transparent)` }}>

        <Grid container spacing={6} style={{ width: "100%", margin: device === 'pc' ? "5% 0 0 10px" : "45% 0 10px 0px", padding: device === 'pc' ? "6%" : "0" }}>
          <Grid xs={12} style={{ padding: device === 'pc' ? "5% 20px 20px 0" : "10px 30px 10px 0px" }}>
            <Row alignment="space-between" style={{ width: "100%" }}>

              {device !== 'pc' ? <></> : <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 1), fontSize: "16px" }}>{SiteData.name} | <span style={{ color: alpha(theme.palette.text.primary, 0.7) }}>Stories</span></Typography>}
              {device !== 'pc' ? <></> : <Column alignment="centered">
                <Row alignment={device === "pc" ? "left" : "centered"}>
                  <IconButton className={classes.iconBtn} onClick={(e) => {
                    if (currentPage > 1)
                      handlePageChange(e, currentPage - 1)
                  }}><ChevronLeft /></IconButton>
                  <Column alignment="centered">
                    <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 1), fontSize: "12px" }}>Showing {(currentPage * maxPageSize) - maxPageSize} - {(currentPage * maxPageSize)} Stories</Typography>
                  </Column>
                  <IconButton className={classes.iconBtn} onClick={(e) => {
                    if (currentPage < Math.max(Math.floor(maxStorySize / maxPageSize), 1))
                      handlePageChange(e, currentPage + 1)
                  }}><ChevronRight /></IconButton>
                </Row>
              </Column>}

            </Row>

          </Grid>

          <Grid xs={12} style={{ padding: "12px" }}>
            <Row alignment="space-between" style={{ width: "100%" }}>
              <DropdownModule style={{ width: "" }}
                label="Filter by"
                onSelected={async (selected) => {
                  setFilter(selected)
                  await showPage(null,0, selected)

                }
                }
                defaultValue={filter}

                items={
                  [{ label: "All Stories", value: "all" },
                  { label: "DearfxchTV", value: "dtv" },
                  { label: "Trending", value: "trending" },
                  { label: "Today", value: "today" },
                  { label: "This Week", value: "this_week" },
                  { label: "This Month", value: "this_month" },
                  { label: "This Year", value: "this_year" },]} />
              {<DropdownModule disabled={filter !== 'all'} style={{ width: "" }}
                label="Sort by"
                onSelected={async (selected) => {
                  setSort(selected)
                  await showPage(null,0, filter, selected)

                }
                }
                defaultValue={sort}

                items={
                  [
                    { label: "None", value: "none" },
                    { label: "Released", value: "rel" },
                    { label: "Popular", value: "pop" },
                  ]} />}
            </Row>
          </Grid>
          {device !== 'pc' ? <Grid xs={12}>
            <Column alignment="centered">
              <Row alignment={device === "pc" ? "left" : "centered"}>
                <IconButton className={classes.iconBtn} onClick={(e) => {
                  if (currentPage > 1)
                    handlePageChange(e, currentPage - 1)
                }}><ChevronLeft /></IconButton>
                <Column alignment="centered">
                  <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 1), fontSize: "12px" }}>Showing {(currentPage * maxPageSize) - maxPageSize} - {(currentPage * maxPageSize)} Videos</Typography>
                </Column>
                <IconButton className={classes.iconBtn} onClick={(e) => {
                  if (currentPage < Math.max(Math.floor(maxStorySize / maxPageSize), 1))
                    handlePageChange(e, currentPage + 1)
                }}><ChevronRight /></IconButton>
              </Row>
            </Column>
          </Grid> : <></>}
          <Divider />
          <Grid container spacing={6} style={{ width: "100%", margin: device === "pc" ? "60px 10px" : "10px 0" }}>
            {_buildAllStories()}
            {device !== "pc" ? <></> : <Grid xs={12}>
              <Row alignment="centered" style={{ width: "100%", margin: "30px 0" }} >
                <Pagination onChange={handlePageChange} count={Math.max(Math.floor(maxStorySize / maxPageSize), 1)} page={currentPage} variant="outlined" />
              </Row>
            </Grid>}
          </Grid>
        </Grid>

      </div>

    </Column>
  });


  let week1, week2;
  const setWeek = (w, w2) => {
    week1 = w;
    week2 = w2;
    console.log(week1);
    console.log(new Date(week1).toLocaleDateString());
    console.log(new Date(week2).toLocaleDateString());
  }
  const _buildAllStories = () => {
    return <div style={{ width: "100%", height: "100%", margin: "3vh 0", padding: "3vh" }}>
      <Grid container spacing={5}>


        {allStories ? allStories.length > 0 ? allStories.map((story, index) => {
          console.log("###", story);
          if (story.approvedBy) {
            if (sort === 'rel') {

              if (index === 0) {
                setWeek(new Date(story.publishedAt).removeDays(7).valueOf(), story.publishedAt)
                console.log("-----------------------");
                let elem = (<><Grid xs={12}> <Typography align="left" style={{ fontSize: "26px", margin: "20px" }}>
                  {new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(week1)}  -  {new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(week2)}
                </Typography></Grid>
                  <Grid lg={4} xl={2} xs={12} className={classes.grow} style={{ margin: "10px" }}>

                    <StoryCard
                      onClick={() => {
                        history(`/stories/${story.storyId}`, { state: { story: story } })
                      }}
                      showLabel={true} userFuture={()=>getUserProfile(story.userId)} views={story.views} story={story} />
                  </Grid></>
                )

                return elem;
              } else {
                console.log(story.publishedAt, week2);
                if (story.publishedAt <= week2 && story.publishedAt >= week1) {

                  return <Grid key={story?.storyId ?? index} lg={4} xl={2} xs={12} className={classes.grow} style={{ margin: "10px" }}> <StoryCard
                    onClick={() => {
                      history(`/stories/${story.storyId}`, { state: { story: story } })
                    }}
                    showLabel={true} userFuture={()=>getUserProfile(story.userId)} views={story.views} story={story} />
                  </Grid>
                } else {
                  setWeek(new Date(story.publishedAt).removeDays(7).valueOf(), story.publishedAt)
                  let elem = (<><Grid xs={12}> <Typography align="left" style={{ fontSize: "26px", margin: "20px" }}>
                    {new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(week1)}  -  {new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(week2)}
                  </Typography></Grid>
                    <Grid lg={4} xl={2} xs={12} style={{ margin: "10px" }}>

                      <StoryCard
                        onClick={() => {
                          history(`/stories/${story.storyId}`, { state: { story: story } })
                        }}
                        showLabel={true} userFuture={()=>getUserProfile(story.userId)} views={story.views} story={story} />
                    </Grid></>
                  )

                  return elem;
                }
              }
            }

            else {

              return <Grid key={story.storyId} xs={device === "pc" ? 3 : 12} className={classes.grow} style={{ margin: "10px" }}> <StoryCard
                onClick={() => {
                  history(`/stories/${story.storyId}`, { state: { story: story } })
                }}
                showLabel={true} userFuture={()=>getUserProfile(story.userId)} views={story.views} story={story} />
              </Grid>
            }
          } else return <Typography key={story?.storyId ?? index} variant="overline" style={{ color: alpha(theme.palette.text.primary, 0.7) }}>No Stories to Display</Typography>
        }) : <Center>

          {loadingStories ? <CircularProgress /> :
            <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 0.7) }}>No Stories to Display</Typography>
          }  </Center> : <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 0.7) }}>No Stories to Display</Typography>}
        <Grid xs={12}>
          {device === "pc" ? <></> : <Row alignment="centered" style={{ width: "100%", margin: "1px 0" }} >
            <Pagination onChange={handlePageChange} count={Math.max(Math.floor(maxStorySize / maxPageSize), 1)} page={currentPage} variant="outlined" />
          </Row>}
        </Grid>
        {/* <FooterModule/> */}
      </Grid>
    </div>
  }
  const _buildSearchResults = () => {
    console.log("BUILDING");
    console.log(queryResults);
    return <>
      <Column alignment={queryResults.length < 1 ? "centered" : "start"} style={{ padding: device === 'pc' ? "5%" : "", height: "100%" }}>
        <motion.label initial={{ x: -30, opacity: 0.3 }} animate={{ x: 0, opacity: 0.8 }} style={{ textAlign: "center", fontSize: "30px", color: alpha(theme.palette.text.primary, 0.65) }}>{`Search for "${search}" `}
        </motion.label>
        <motion.div >
          {loadResults ? <><Center><Row><CircularProgress /></Row></Center></> : <></>}
          {queryResults.length < 1 ? <>
            <Column alignment="centered" style={{ height: "100%" }}>
              <motion.label initial={{ y: -30, opacity: 0.0 }} animate={{ y: 0, opacity: 0.3 }} style={{ textAlign: "center", fontSize: "30px", color: alpha(theme.palette.text.primary, 0.5) }}>{`No Results Found`}
              </motion.label>
            </Column>
          </> : <Grid container spacing={6} style={{ width: "100%", height: "100%", margin: device === 'pc' ? "5% 0 0 10px" : "6% 0 10px 0px", padding: device === 'pc' ? "0 0 5% 0" : "0" }}>
            {

              queryResults.map((story, index) => {
                console.log(story);
                return <Grid xs={device === 'pc' ? 4 : 12} key={"story_q_" + index}>
                  <motion.div initial={{ y: -30, opacity: 0.1 }} animate={{ y: 0, opacity: 1 }} transition={{ type: "tween", easings: "linear", duration: 0.1, delay: index / 10 }}><div style={{ width: device === 'pc' ? "100%" : "100%", height: device === 'pc' ? "350px" : "350px", padding: device === 'pc' ? "10px" : "10px 0" }}>
                    <StoryCard
                      onClick={() => {
                        history(`/stories/${story.storyId}`, { state: { story: story } })
                      }}
                      showLabel={true} userFuture={()=>getUserProfile(story.userId)} views={story.views} story={story} />
                  </div></motion.div>

                </Grid>
              })
            }
            <Grid xs={12}>
              <Row alignment="centered" style={{ width: "100%", margin: "30px 0" }} >
                <Pagination onChange={q_handlePageChange} count={Math.max(Math.floor(maxQueryResultsSize / q_maxPageSize), 1) + ((maxQueryResultsSize % q_maxPageSize) === 0 || ((maxQueryResultsSize % q_maxPageSize)) === maxQueryResultsSize ? 0 : 1)} page={q_currentPage} variant="outlined" />
              </Row>
            </Grid>
          </Grid>}
        </motion.div>
      </Column>
    </>
  }
  const handleSearch = async () => {
    setSearch(searchRef.current.value)
    if (searchRef.current.value === '')
      history(-1);
    else if (!SiteData.storyPage.searchOption.liveResults && location.pathname === '/stories') {
      history(`/stories/search`, { state: { showSearch: true, search: searchRef.current.value } })
    } else {
      setLoadResults(true);
      setQCurrentPage(1);
      await showQueryPage(1, searchRef.current.value)
      if (searchRef.current.value === '') {
        showSearchResults(false)
      }
      else {
        showSearchResults(true)
      }
    }

  }
  const handleSearchSuggestions = async (evt) => {
    if (SiteData.storyPage.searchOption.liveResults || evt.key === 'Enter')
      await handleSearch(evt.target.value);
  }
  const _buildSearchBar = () => {
    return <div style={{ width: "80%" }}>
      <Center>
        <OutlinedInput
          id="outlined-adornment-password"
          type={'text'}
          className={classes.textField}
          defaultValue={search}
          fullWidth
          placeholder="Search"
          inputRef={searchRef}
          inputProps={{
            style: {
              color: alpha(theme.palette.text.primary, 1),
              paddingTop: "15px",
              borderColor: "white"
            }
          }}
          
          onKeyUp={handleSearchSuggestions}
          endAdornment={
            <InputAdornment position="end" >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleSearch}
                edge="end"
                className={classes.iconBtn}
              ><Search />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </Center>
    </div>
  }
  return (<div className={classes.root}>
    <CssBaseline />
    <Stack style={{ maxHeight: "100%", height: "100%", width: "100%", overflow: "hidden" }}>
      <div style={{ background: theme.palette.type === "dark" ? "#0C0C0Cf1" : "#ffffff", height: "100%", width: "100%" }}></div>
      <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 20%,${alpha(theme.palette.background.default, 0.5)} 54%, transparent)`, height: "100%", width: "100%" }}></div>
      {/* <FooterModule device={device} hideBranding={false}/> */}
      {!searchResults ? _buildPageBackGround() : <></>}

    </Stack>
    <Stack startLayer="200" style={{ paddingTop: device === 'pc' ? searchResults ? "10vh" : '55vh' : "45%" }}>
      <div style={{ width: "100%", overflow: "hidden" }}>{
        searchResults ? _buildSearchResults() : _buildPage()
      }</div>
    </Stack>
    {<AppBarModule pageIndex={6} appbarBackground={ theme.palette.background.default } centerModule={_buildSearchBar()} />}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
  </div>)


}
export default StoryPage;