import axios from "axios";
import {baseUrl, RouteNames} from "../config/api";

export const SubmissionService = () => {

    /** Story Submissions */
    const createStorySubmission = async ({
        sId, userId, storyId, timestamp, published,seen,paid,bundleId
    }) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionStory}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                sId,
                userId,
                storyId,
                timestamp,
                published,
                seen,
                paid,
                bundleId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getStorySubmissionsByUser = async ({userId, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStoryFromUser}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getStorySubmission = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStory}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'story'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteStorySubmission = async ({id}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.submissionStory}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'story'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateStorySubmission = async (data) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.submissionStory}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                id: data.sId,
                type: 'story',
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getStorySubmissions = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStory}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                type: 'story',
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchStorySubmissions = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStorySearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset,
                type: 'story'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getNewStorySubmissions = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStoryListNew}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                type: 'story',
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getOldStorySubmissions = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStoryListOld}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                type: 'story',
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchNewStorySubmissions = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStorySearchNew}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset,
                type: 'story'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchOldStorySubmissions = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStorySearchOld}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset,
                type: 'story'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const markStoryAsSeen = async ({id}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionStorySeen}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'story'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const markAsPublished = async ({id}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionStoryPublished}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'story'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const approveStory = async ({id}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionApproveStory}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'story'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    /** Video Submission */

    const createVideoSubmission = async (data) => {
        console.log("Creating Video Submission...")
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionVideo}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data);
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getVideoSubmission = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideo}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'video'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteVideoSubmission = async ({id}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.submissionVideo}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'video'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateVideoSubmission = async (data) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.submissionVideo}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                id: data.sId,
                type: 'video',
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getVideoSubmissions = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideo}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                type: 'video',
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchVideoSubmissions = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset,
                type: 'video'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getNewVideoSubmissions = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoListNew}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                type: 'video',
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getOldVideoSubmissions = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoListOld}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                type: 'video',
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchNewVideoSubmissions = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoSearchNew}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset,
                type: 'video'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getVideoSubmissionsByUser = async ({userId, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoFromUser}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchVideoSubmissionsByUser = async ({userId, query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionSearchVideoFromUser}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                query,
                cursor,
                limit,
                offset,
                type: 'video'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchStorySubmissionsByUser = async ({userId, query, cursor, limit, offset}) => {
        console.log("Searching Story Submissions by User...", query)
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionSearchStoryFromUser}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                query,
                cursor,
                limit,
                offset,
                type: 'story'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchOldVideoSubmissions = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoSearchOld}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset,
                type: 'video'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const markVideoAsSeen = async ({id}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionVideoSeen}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'video'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const markVideoAsPublished = async ({id}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionVideoPublish}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'video'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const approveVideo = async ({id}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionApproveVideo}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                type: 'video'
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    /** STORY PACKAGES */
    const getStoryPackages = async ({cursor, limit, offset, order}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStoryPackageList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset,
                order
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getStoryPackageByUser = async ({userId, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStoryPackageFromUser}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
        }
    }
    const searchStoryPackages = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStoryPackageSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
            }
    }
    const getStoryPackage = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionStoryPackage}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const createStoryPackage = async (data) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionStoryPackage}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
            }
    }
    const updateStoryPackage = async (data) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.submissionStoryPackage}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                id: data.id
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
            }
    }
    const deleteStoryPackage = async ({id}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.submissionStoryPackage}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
            }
    }

    /** VIDEO PACKAGES */
    const getVideoPackages = async ({cursor, limit, offset, order}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoPackageList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset,
                order
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
        }
    }
    const getVideoPackageByUser = async ({userId, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoPackageFromUser}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
            }
    }
    const searchVideoPackages = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoPackageSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
            }
    }
    const getVideoPackage = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.submissionVideoPackage}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const createVideoPackage = async (data) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.submissionVideoPackage}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
            }
    }
    const updateVideoPackage = async (data) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.submissionVideoPackage}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                id: data.id
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
            }
    }
    const deleteVideoPackage = async ({id}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.submissionVideoPackage}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
            } catch (e) {
                console.log(e);
                throw new Error(e);
            }
    }

    return {
        createStorySubmission,
        getStorySubmission,
        deleteStorySubmission,
        updateStorySubmission,
        getStorySubmissions,
        searchStorySubmissions,
        getNewStorySubmissions,
        getOldStorySubmissions,
        searchNewStorySubmissions,
        searchOldStorySubmissions,
        markStoryAsSeen,
        markAsPublished,
        approveStory,
        createVideoSubmission,
        getVideoSubmission,
        deleteVideoSubmission,
        updateVideoSubmission,
        getVideoSubmissions,
        searchVideoSubmissions,
        getNewVideoSubmissions,
        getOldVideoSubmissions,
        searchNewVideoSubmissions,
        searchOldVideoSubmissions,
        markVideoAsSeen,
        markVideoAsPublished,
        approveVideo,
        getStorySubmissionsByUser,
        getVideoSubmissionsByUser,
        searchVideoSubmissionsByUser,
        searchStorySubmissionsByUser,
        getStoryPackages,
        getStoryPackageByUser,
        searchStoryPackages,
        getStoryPackage,
        createStoryPackage,
        updateStoryPackage,
        deleteStoryPackage,
        getVideoPackages,
        getVideoPackageByUser,
        searchVideoPackages,
        getVideoPackage,
        createVideoPackage,
        updateVideoPackage,
        deleteVideoPackage
    }
}