/* eslint-disable react-hooks/exhaustive-deps */

import { Dialog, alpha, Grid, IconButton, makeStyles, Snackbar, Typography, useTheme } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import Stack from '../components/Stack';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Close } from '@material-ui/icons';
import { endOfMonth, startOfMonth } from 'date-fns';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Center from '../components/Center';
import Column from '../components/Column';
import "../index.css";

import { Calendar } from '../components/Calendar';
import { AppBarModule } from '../components/_app_bar_mod';
import { EventCard } from '../components/_event_mod';
import { FooterModule } from '../components/_footer_mod';
import { EventContext } from '../contexts/EventProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    }
  },
  iconBtn: {
    '&:hover': {
      color: "white"
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      color: "white"
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

//CUSTOM MUI

const EventsPage = () => {

  const theme = useTheme()
  const classes = useStyles();
  const {
    events,
    getEvents,
  } = useContext(EventContext)

  const [device, setDevice] = useState('pc');
  const [, setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [notificationMsg, ] = useState('');
  const [dateFrom, setDateFrom] = useState(Date.now());
  const [dateTo, setDateTo] = useState(Date.now());
  useEffect(() => {
    updateDevice();
    window.addEventListener("resize", updateDevice);
    const monthStart = startOfMonth(new Date());
    const monthEnd = endOfMonth(monthStart);
    populateEvents(new Date(monthStart).valueOf(), new Date(monthEnd).valueOf())
  }, [])

  const populateEvents = useCallback(async (from, to) => {
    await getEvents(from, to)
  }, [getEvents])
  
  const updateDevice = () => {
    if (window.innerWidth < 550) {
      setDevice('mobile');
      setDrawerWidth(300)
    }
    else {
      setDevice('pc')
      setDrawerWidth(280)
    }
  }
  const _buildCalendar = (() => {
    return <Calendar events={events} onMonthChange={async (month) => {
      const monthStart = startOfMonth(month);
      const monthEnd = endOfMonth(monthStart);
      setDateFrom(new Date(monthStart))
      setDateTo(new Date(monthEnd))
      await populateEvents(new Date(monthStart).valueOf(), new Date(monthEnd).valueOf())
    }} />
  })
  const _buildPage = (() => {
    //...
    return <Column>
      {_buildCalendar()}
      <div style={{ minHeight:100, width: "100%", height: "100%", margin: "3vh 0", padding: "3vh" }}>
        <Grid container spacing={5}>

          {events?.map((key, index) => {
            return <Grid key={index} xs={12}><EventCard event={key} /></Grid>
          })}

        </Grid>
        {(events?.length ?? 0) <= 0 ? <Center><Typography align="center" style={{ color: alpha(theme.palette.text.primary, 0.6), margin: 20 }} variant="overline">{`No Events Between ${new Date(dateFrom).toLocaleDateString()} and  ${new Date(dateTo).toLocaleDateString()}`}</Typography></Center> : <></>}
      </div>
    </Column>
  });

  return (<div className={classes.root}>
    <CssBaseline />
    <Stack>
      <div style={{ background: "#0000000d", height: "100%", width: "100%" }}></div>
      <div style={{ background: "linear-gradient(0deg, black 20%,#0000009c 54%, transparent)", height: "100%", width: "100%" }}></div>
      <FooterModule device={device} hideBranding={false} />
      {_buildPage()}
    </Stack>
    {<AppBarModule showCart pageIndex={8} />}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification}
      autoHideDuration={notificationMsg.timeout}
      onClose={() => {
        showNotification(false)
      }}
      message={notificationMsg.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  </div>

  )


}
export default EventsPage;