/* eslint-disable react-hooks/exhaustive-deps */

import { Button, IconButton, CircularProgress, Typography, TextField, InputAdornment } from '@material-ui/core';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import { Close } from '@material-ui/icons';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import "../index.css";
import Column from "./Column";
import { useDatabase } from "../hooks/useDatabase";
import Row from "./Row";
import shortUUID from 'short-uuid';
import PropTypes from 'prop-types';


const BuildPackageCreator = React.forwardRef(function BPC(props, ref){
    const {existingPackage,agent,packageId} = props;
    const {retrieveServicePackage} = useDatabase();
    const [loading, setLoading] = useState(false);
    const [servicePackage, setPackage] = useState(existingPackage ?? {});
          const [included, setIncluded] = useState(existingPackage?.included??[]);
           useEffect(()=>{
            console.log("packageId", packageId,agent);
            if(packageId){
                console.log("packageId", packageId);
                setLoading(true);
                retrieveServicePackage(packageId).then((res)=>{
                    if(res){
                        setPackage(res);
                        setIncluded(res?.included??[]);
                        setLoading(false);
                    }
                })
            }
           },[])
          useImperativeHandle(ref, () => ({
            getPackage: () => {
                if(!packageId){
                    servicePackage.dateCreated = Date.now();
                      servicePackage.approved = false;
                      
                      servicePackage.agreementsAttached = [];
                      servicePackage.createdBy = agent.id;
                      servicePackage.active = false;
                      servicePackage.id = shortUUID.generate();
                      servicePackage.boughtBy=[];
                      servicePackage.likes=[];
                      servicePackage.price = Number(servicePackage.price??0);
                      servicePackage.discount = Number(servicePackage.discount??0);
              }
                servicePackage.included = included;
                return servicePackage;
            }
          }))
          const _buildPackageIncludes = ()=>{
              
                return <Column>
                    <Button onClick={
                        ()=>{
                            let temp = included;
                            temp.push("");
                            setIncluded([...temp]);
                        }
                    } variant="contained" color="primary">Add Included Item</Button>
                    {included.map((item, index)=>{
                        return <TextField key={index}
                        defaultValue={item}
                        onChange={
                            (e)=>{
                                let temp = included;
                                temp[index] = e.target.value;
                                setIncluded([...temp]);
                            }
                        }
                        InputProps={{
                            startAdornment: <InputAdornment><IconButton
                            onClick = {()=>{
                                let temp = included;
                                temp.splice(index, 1);
                                setIncluded([...temp]);
                                
                            }}
                            ><Close/></IconButton></InputAdornment>
                        }} label="Item" style={{margin:"10px 0"}} variant="outlined" />
                        
                    })}
                </Column>
            }
            return loading ? <Column style={{height:"100%", width:"100%"}}>
                <CircularProgress/>
            </Column> : <Column>
                <TextField defaultValue={servicePackage.name} InputProps={{}} InputLabelProps={{ shrink: true }} label={"Package Name"}  placeholder="Name" variant="outlined" fullWidth onChange={(e)=>{
                    let temp = servicePackage;
                    temp.name = e.target.value;
                    setPackage({...temp});
                }}/>
                <div style={{height: 20}}/>
                <TextField defaultValue={servicePackage.description} InputProps={{}} multiline maxRows={4} InputLabelProps={{ shrink: true }} label={"Package Description"}  placeholder="Description" variant="outlined" fullWidth onChange={(e)=>{
                    let temp = servicePackage;
                    temp.description = e.target.value;
                    setPackage({...temp});
                }}/>
                <div style={{height: 20}}/>
                {_buildPackageIncludes()}
                <div style={{height: 20}}/>
        <Row>
        <div style={{height: 20}}/>
            <TextField label="€ Package Price" 
            value={servicePackage?.price??0}
            type="number"
             InputProps={{
            startAdornment: <InputAdornment><Typography>€</Typography></InputAdornment>
        }}
            onChange={
                (e)=>{
                    let temp = servicePackage;
                    temp.price = Number(e.target.value);
                    setPackage({...temp});
                }
            }
                variant="outlined" />
                <div style={{width: 20}}/>
                {/* <TextField type="number"
                value={servicePackage?.deposit??0}
                InputProps={{ inputProps: { min: 0, max: 100 },startAdornment: <InputAdornment><Typography>%</Typography></InputAdornment> }}
                onChange={
                    (e)=>{
                        let temp = servicePackage;
                        temp.deposit = Number(e.target.value);
                        setPackage({...temp});
                    }
                }label="% Deposit " variant="outlined" />
                <div style={{width: 20}}/> */}
                <TextField type="number"
                value={servicePackage?.discount??0}
                InputProps={{ inputProps: { min: 0, max: 100 },startAdornment: <InputAdornment><Typography>%</Typography></InputAdornment> }}
                onChange={
                    (e)=>{
                        let temp = servicePackage;
                        temp.discount = Number(e.target.value);
                        setPackage({...temp});
                    }
                }label="% Discount" variant="outlined" />
            </Row>
            </Column>
        });

        export default BuildPackageCreator;

        
BuildPackageCreator.propTypes = {
    existingPackage: PropTypes.object,
    agent: PropTypes.object,
    packageId: PropTypes.string
}