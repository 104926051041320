import firebase_app, { firestore } from "../config/firebase"
import Logic from "./logic";
import { NotificationTypes, sendNotification } from "./notificationHandler";
import shortUUID from "short-uuid";
import { sendChatMessage } from "../hooks/useChatMessenger";
import { AuthService } from "../services/authService";
import { SubmissionService } from "../services/submissionService";
import { CheckoutService } from "../services/checkoutService";
export const updateProfileDB = async (profile,update) => {
    console.log(profile, profile.uid)
    await firebase_app.database().ref().child("Users").child(profile.uid).update(update??profile);
}

export const getUserProfile = async (uid) => {
    const {getUserProfile: getProfile} = AuthService();
    var {data: profile} = await getProfile({uid: uid});
    console.log(profile);
    return profile.data;
  }
export const getCheckoutItems = async (uid, callback) => {
    console.log("-------->", uid);
    firestore.collection("Users").doc(uid).collection("Cart").onSnapshot((qs) => {
        console.log("-->", qs.docs);
        callback(qs.docs);
    })
}
export const getRoom = async (roomId) => {
    let data = firestore.collection("StudioRooms").doc(roomId);
    return (await data).data();
}
export const isInCart = async (uid, id, type) => {
    if(type === "submission"){
        let data = await firestore.collection("Users").doc(uid).collection("Cart").doc(id).get();
        return data.exists;
    }
    let data = await firestore.collection("Users").doc(uid).collection("Cart").where("bookings", "array-contains", { bid: id }).get();
    return data.docs.length > 0;
}
export const getCartItems = async (uid, callback) => {
    let itemsObject = { bookings: [] }

    firestore.collection("Users").doc(uid).collection("Cart").onSnapshot((qs) => {
        let num = 0;
        itemsObject = { bookings: [] }
        qs.docs.forEach((d) => {

            let data = d.data();
            
            if (data.type === "booking") {
                num += data.bookings.length;
                // [{bid...}]
                // [{bid,type,....}] 
                let nArr = data.bookings.map((v) => {
                    let o = Object.assign({}, v);
                    o.type = "booking";
                    o.cartId = d.id;
                    return o;
                })
                itemsObject.bookings.push.apply(itemsObject.bookings, nArr)
            }else if(data.type==="submission"){
                num+=1;
                itemsObject.submissions = [...(itemsObject.submissions??[]),data];

            }
        });
        console.log(itemsObject);
        callback(itemsObject, num);
    });
    return itemsObject;

}
export const getUserBookings = async(uid,callback)=>{
    if(!uid)
        {
            callback([]);
            return;
        }
    console.log(uid)
     firestore.collection("Users").doc(uid??firebase_app.auth().currentUser.uid).collection("Bookings").orderBy("date").onSnapshot((qs)=>{
        callback(qs.docs);
    })
}
export const getUserBooking = async(uid,bid)=>{
    console.log(uid)
     let booking = await firestore.collection("Users").doc(uid??firebase_app.auth().currentUser.uid).collection("Bookings").doc(bid).get();
     return booking.data();
}
export const updateVideoSubmission = async (data)=>{
    const {updateVideoSubmission} = SubmissionService();

    const {status} = await updateVideoSubmission(data);
    return status === 200;
}
export const retrieveSubmission = async(sid)=>{
    const {getVideoSubmission} = SubmissionService();
    let {data:submission} = await getVideoSubmission({id:sid});
    return submission?.data
      
}
export const retrieveStorySubmission = async(sid)=>{
    const {getStorySubmission} = SubmissionService();
      try {
        const {data: submission} = await getStorySubmission({id:sid});
          return submission?.data;
  
      } catch (e) {
        console.log(e);
        return null;
      }
      
}
export const retrieveUserStorySubmissions = async(uid)=>{
    const {getStorySubmissionsByUser} = SubmissionService();
      try {
        const {data: submission} = await getStorySubmissionsByUser({userId:uid});
          return submission;
  
      } catch (e) {
        console.log(e);
        return null;
      }
      
}
export const submitStory = async (data)=>{
    const {createStorySubmission} = SubmissionService();
      try {
        console.log(data);
        const {status} = await createStorySubmission(data);
          return status === 200;
  
      } catch (e) {
        return false;
      }
}
const getRequest = async (id) => {
    try{
        const request = await firebase_app.database().ref('serviceRequests').child(id).once('value');
        return request.val();
    }catch(e){
        console.log(e);
    }
}
const getApplication = async (id) => {
    try{
        const request = await firebase_app.firestore().collection('ServiceAgentForms').doc(id).get();
        return request.data()
    }catch(e){
        console.log(e);
    }
}
const updateApplication = async (id, update) => {
    return await firebase_app.firestore().collection('ServiceAgentForms').doc(id).update(update);
}
const updateRequest = async (id, update) => {
    return await firebase_app.database().ref('serviceRequests').child(id).update(update);
 }
export const purchaseItems = async (uid, items = {},onSuccess = ()=>{}) => {

    try {
        console.log(items);
        let success = true;
        for(const item of items)
        {
            switch (item.type) {
                case "video_submission":
                    {
                        const submission = await retrieveSubmission(item.cartId);
                    if(submission.status === 'payment'){
                        submission.status = 'validating';
                        submission.paid = true;
                        submission.statusDates = {
                            ...(submission.statusDates??{}),
                            validating: new Date().valueOf(),
                        }
                        if(!await updateVideoSubmission(submission)){
                            success = false;
                        }
                        sendNotification({
                            id: shortUUID.generate(),
                            sendTo: 'dtv-admin',
                            sentFrom: uid,
                            type: NotificationTypes.VIDEO_SUBMISSION,
                            title: 'Video Submission',
                            message: `${submission.name} submitted a video`,
                            timestamp: new Date().valueOf(),
                            seen: false,
                            url: `/Submissions/Video?id=${item.cartId}`
                        })

                    }
                }
                break;
                case "service_deposit":
                    getRequest(item.cartId).then((request)=>{
                        updateRequest(item.cartId, {
                            status: 'deposit-processing',
                            depositPaid: item.price,
                            statusDates:{
                                ...request.statusDates,
                                deposit: new Date().valueOf(),
                                'deposit-processing': new Date().valueOf()
                            }
                        });
                        sendChatMessage({
                            messageId: shortUUID.generate(),
                            senderId: uid,
                            reciepientKey: request.id,
                            reciepients: [request.agentIdm, 'dtv-admin'],
                            content: `THIS IS AN AUTOMATED MESSAGE: DEPOSIT OF ${item.price} PAID`,
                            timestamp: new Date().valueOf(),
                            seenby: [uid]
                        })
                        sendNotification({
                            id: shortUUID.generate(),
                            sendTo: 'dtv-admin',
                            sentFrom: uid,
                            type: NotificationTypes.AGENT_SERVICE,
                            title: 'Deposit Paid',
                            message: `${request.firstName} ${request.lastName} paid Depost`,
                            timestamp: new Date().valueOf(),
                            seen: false,
                            url: `/service_tracker/${item.cartId}`
                        })
                    });
                    
                break;
                case "service_payment":
                    getRequest(item.cartId).then((request)=>{
                        updateRequest(item.cartId, {
                            status: 'payment-processing',
                            amountPaid: item.price,
                            statusDates:{
                                ...request.statusDates,
                                'payment-processing': new Date().valueOf()
                            }
                        });
                        sendChatMessage({
                            messageId: shortUUID.generate(),
                            senderId: uid,
                            reciepientKey: request.id,
                            reciepients: [request.agentIdm, 'dtv-admin'],
                            content: `THIS IS AN AUTOMATED MESSAGE: PAYMENT OF ${item.price} PAID`,
                            timestamp: new Date().valueOf(),
                            seenby: [uid]
                        })
                        sendNotification({
                            id: shortUUID.generate(),
                            sendTo: 'dtv-admin',
                            sentFrom: uid,
                            type: NotificationTypes.AGENT_SERVICE,
                            title: 'Payment Made',
                            message: `${request.firstName} ${request.lastName} paid ${item.price}`,
                            timestamp: new Date().valueOf(),
                            seen: false,
                            url: `/service_tracker/${item.cartId}`
                        })
                    });
                break;
                case "saa":
                    getApplication(item.cartId).then((request)=>{
                        updateApplication(item.cartId, {
                            status: 'payment-processing',
                            amountPaid: item.price,
                            statusDates:{
                                ...request.statusDates,
                                'payment-processing': new Date().valueOf()
                            }
                        });
                        sendNotification({
                            id: shortUUID.generate(),
                            sendTo: 'dtv-admin',
                            sentFrom: uid,
                            type: NotificationTypes.APPLICATION,
                            title: 'Payment Made',
                            message: `${request.name} paid ${item.price}`,
                            timestamp: new Date().valueOf(),
                            seen: false,
                            url: `/service_tracker/${item.cartId}`
                        
                        })
                    });
                break;
                default:
                       {
                        await firestore.collection("Users").doc(uid).collection("Bookings").doc(item["bid"]).set(item);
                        let bookedHours = Logic.calcBookedHrs({ start: new Date(item["startTime"]), end: new Date(item["endTime"]) });
                        console.log(bookedHours);
                      let bkdHours =  (await firestore.collection("StudioRooms").doc(item["room"]).get()).data()["bookedHours"];
                       await firestore.collection("StudioRooms").doc(item["room"]).update({
                            bookedHours:[...bkdHours,...bookedHours]
                        });
                    }
                    break;
            }

        }
        if(success){
            onSuccess();
        }   
    } catch (e) {
        console.log(e);
    }
}
export const clearCart = async ({ uid }) => {
    const {clearCheckoutItems} = CheckoutService();
    const {status} = await clearCheckoutItems({uid: uid});
    return status === 200;
}
export const getCartItem = async (uid, itemId) => {
    if (itemId === "") return undefined;
    const {getCheckoutItemById} = CheckoutService();
    const {data: checkout} = await getCheckoutItemById({uid: uid, itemId: itemId});
    
    return checkout.data;

}

export const addStudioBookingsToCart = async (bookingCart, uid, bid,price) => {
    const {addCheckoutItem} = CheckoutService();
    try {
       const {status} =  await addCheckoutItem({
            uid: uid,
            item: {
            type: "booking",
            cartId: bid,
            price: price ?? 0,
            bookings: bookingCart
        }})
        return status === 200;
    } catch (e) {
        console.log(e);
    }

}

export const addSubmissionToCart = async ({uid,sId,bundleId,title,summary,price,link,type }) => {
    
    const {addCheckoutItem} = CheckoutService();
    try {
        await addCheckoutItem({
            uid: uid,
            item: {
            type: "submission",
            cartId:sId,
            name: title,
            price: price,
            submission: {
                bundleId,
                title,
                summary,
                price,
                link,
                type
            }
        }})
    } catch (e) {
        console.log(e);
        
    }

}
export const addNewTransaction = async ({ data, uid }) => {
    console.log(uid);
    const {addTransaction} = CheckoutService();
    const {status} = await addTransaction({
       uid: uid,
       transaction: data
    });
    console.log(data);

    return status === 200;
}
export const verifyVoucher = async ({ vid, uid }) => {
    var data = await firestore.collection("Vouchers").doc(vid).get();
    let today = Date.now();
    console.log(data.data());
    if (data.exists) {
        if (data.data().code === vid) {
            if (data.data().usedDate > today) {
                let temp = { code: data.data().code, genDate: data.data().genDate, usedBy: [uid, ...data.data().usedBy], value: 5 };
                await firestore.collection("Vouchers").doc(vid).set(temp)
                return temp;
            }
            else {
                throw new Error("Voucher Expired");
            }
        }
        else throw new Error("Invalid Voucher");
    }
    else throw new Error("Invalid Voucher");
}

export const addAStudioBookingToCart = async (uid, booking) => {
    await firestore.runTransaction((transaction) => {
        const ref = firestore.collection("Users").doc(uid).collection("Cart").doc(booking.id);
        return transaction.get(ref).then(doc => {
            if (!doc.data().bookings) {
                transaction.set({
                    bookings: [booking]
                });
            } else {
                const bookings = doc.data().bookings;
                bookings.push(booking);
                transaction.update(ref, { bookings: bookings });
            }
        });
    })

}
export const  removeItemFromCart = async (uid, itemId) => {
    const {removeCheckoutItem} = CheckoutService();
    console.log(uid, itemId);
    const {data: cart} = await removeCheckoutItem({uid: uid, itemId: itemId});
    return cart.staus === 200;
}
export const removeStudioBookingFromCart = async (uid, booking, onComplete = () => { }) => {
    await firestore.runTransaction((transaction) => {
        const ref = firestore.collection("Users").doc(uid).collection("Cart").doc(booking.cartId);
        return transaction.get(ref).then(doc => {

            if ((doc?.data()?.bookings ?? []).length <= 0) {
                if (transaction)
                    transaction.delete()
            } else {
                const bid = `${booking.startTime}-${booking.endTime}`;
                const bookings = doc.data().bookings.filter((v) => v.bid !== bid);
                transaction.update(ref, { bookings: bookings });
                if (onComplete) {
                    onComplete();
                }
            }
        });
    })

}