import {
  FormControl,
  useTheme,
  Typography,
  OutlinedInput,
  TextField,
  makeStyles,
} from "@material-ui/core";
import React, { useState, forwardRef } from "react";
import Row from "./Row";
import 'react-phone-number-input/style.css'; // Import CSS for react-phone-number-input


import PropTypes from 'prop-types';

const useStyles = makeStyles({
  root: {
    '& .MuiInputBase-root': {
      borderRadius: "12px"
    }
  },
  textfield: {
    borderRadius: "12px"
  }
})
export const AdortmentTextField = forwardRef(
  function TF(props = {
    required: true,
    value: "",
    defaultValue: "",
    label: "",
    hint: "",
    disabled: false,
    transform: "capitalize",
    style: {},
    labelStyle: {},
    fullWidth: true,
    helperTextLeft: "",
    helperTextRight: "",
    maxChar: 1000,
    error: false,
    readOnly: false,
    type: "text",
    endAdortment: <></>,
    startAdortment: <></>,
    validate: () => false,
    onKeyUp: () => { },
    onChange: () => { },
  }, ref) {

    const classes = useStyles();
    const theme = useTheme();
    const [error, setError] = useState(props.error);
    return (
      <FormControl
        fullWidth
        style={{
          ...props.style,
          width: props.style?.width ?? "100%",
          marginTop: "21px",
        }}
      >
        {/* <TextField required fullWidth label="Username" placeholder="Enter Username or Email address" variant="outlined"></TextField>
       */}
        <Row style={{ marginBottom: "5px" }} alignment="space-between">
          <Typography
            align="left"
            style={{
              ...props.labelStyle,
              fontWeight: props.labelStyle?.fontWeight ?? "bold",
              fontSize: props.labelStyle?.fontSize ?? "12px",
              color: props.labelStyle?.color ?? theme.palette.text.primary,
            }}
          >
            {props.label}
          </Typography>
          {props.required ? (
            <Typography
              align="right"
              style={{
                ...props.labelStyle,
                fontWeight: "bold",
                fontSize: "12px",
                color: theme.palette.text.primary,
              }}
            >
              {`${props.disabled ? "" : props.required ? "*required" : ""}`}
            </Typography>
          ) : (
            <></>
          )}
        </Row>
        <OutlinedInput

          disabled={props.disabled}
          className={classes.textfield}
          defaultValue={props.defaultValue}
          inputProps={{ maxLength: props.maxChar ?? "none" }}
          type={props.type}
          style={{
            borderRadius: "12px"
          }}
          readOnly={props.readOnly}
          value={props.value}
          required={props.required}
          endAdornment={props.endAdortment}
          startAdornment={props.startAdortment}
          inputRef={ref}
          placeholder={props.hint}
          fullWidth
          variant="outlined"
          onChange={(e) => {
            //console.log(e)
            if (props.onKeyUp)
              props.onKeyUp(e.target.value);
          }}
          onKeyUp={(e) => {
            //console.log(e)

            if (props.validate) {
              setError(!props.validate());
            }
            props.onKeyUp(e.target.value);
          }}
        />
        <Row
          style={{ width: "100%", marginTop: "5px" }}
          alignment="space-between"
        >
          <Typography
            align="left"
            variant="caption"
            style={{ fontSize: "10px", maxWidth: "80%", color: theme.palette.disabled }}
          >
            {props.helperTextLeft}
          </Typography>
          <Typography
            align="right"
            variant="caption"
            style={{ fontSize: "10px", maxWidth: "30%", color: error ? "red" : "#009D35" }}
          >
            {props.helperTextRight}
          </Typography>
        </Row>
      </FormControl>
    );
  })

export const DefaultTextField = forwardRef(

  function TF(props = {
    value: "",
    defaultValue: "",
    label: "",
    required: true,
    type: "text",
    hint: "",
    disabled: false,
    style: {},
    ref: "",
    multiline: false,
    rows: 1,
    transform: "capitalize",
    fullWidth: true,
    helperTextLeft: "",
    readOnly: false,
    helperTextRight: "",
    error: false,
    validate: () => false,
    onKeyUp: () => { },
    onChange: () => { },
  }, ref) {

    const classes = useStyles();
    const theme = useTheme();
    const [error, setError] = useState(props.error);
    return (
      <FormControl
        fullWidth
        style={{
          ...props.style,
          width: props.style?.width ?? "100%",
          marginTop: "21px",
        }}
      >
        {/* <TextField required fullWidth label="Username" placeholder="Enter Username or Email address" variant="outlined"></TextField>
       */}
        <Row
          style={{ width: "100%", marginBottom: "5px" }}
          alignment="space-between"
        >
          <Typography
            align="left"
            style={{
              ...props.labelStyle,
              fontWeight: props.labelStyle?.fontWeight ?? "bold",
              fontSize: props.labelStyle?.fontSize ?? "12px",
              color: props.labelStyle?.color ?? theme.palette.text.primary,
            }}
          >
            {props.label}
          </Typography>
          {
            <Typography
              align="right"
              style={{
                fontWeight: "bold",
                fontSize: "12px",
                color: props.error ?? error ? "red" : "#009D35",
              }}
            >{`${props.disabled ? "" : props.required ? "*required" : ""}`}</Typography>
          }
        </Row>
        <OutlinedInput
          rows={props.rows}
          multiline={props.multiline}
          value={props.value}
          disabled={props.disabled}
          className={classes.textfield}
          inputRef={ref}
          defaultValue={props.defaultValue}
          type={props.type}
          required={props.required}
          readOnly={props.readOnly}
          placeholder={props.hint}
          fullWidth
          inputProps={{
            maxLength: props.maxChar ?? "none",
            style: {
              textTransform: props.transform ?? "capitalize"
            }
          }}
          variant="outlined"
          onChange={(e) => {
            //console.log(e)
            if (props.onKeyUp)
              props.onKeyUp(e.target.value);
          }}
          onKeyUp={(e) => {
            //console.log(e)
            if (props.validate) {

              setError(!props.validate());
            }
            if (props.onKeyUp)
              props.onKeyUp(e.target.value);
          }}
        />
        <Row
          style={{ width: "100%", marginTop: "5px" }}
          alignment="space-between"
        >
          <Typography
            align="left"
            variant="caption"
            style={{ fontSize: "10px", maxWidth: "80%", color: theme.palette.disabled }}
          >
            {props.helperTextLeft}
          </Typography>
          <Typography
            align="right"
            variant="caption"
            style={{ fontSize: "10px", maxWidth: "30%", color: props.error ?? error ? "red" : "#009D35" }}
          >
            {props.helperTextRight}
          </Typography>
        </Row>
      </FormControl>
    );
  })

export const CustomTextField = forwardRef(

  function TF(props = {
    value: "",

    defaultValue: "",
    label: "",
    required: true,
    type: "text",
    hint: "",
    disabled: false,
    style: {},
    ref: "",
    multiline: false,
    rows: 1,
    //transform: "capitalize",
    fullWidth: true,
    helperTextLeft: "",
    readOnly: false,
    helperTextRight: "",
    error: false,
    validate: () => false,
    onKeyUp: () => { },
    onChange: () => { },
  }, ref) {

    const classes = useStyles();
    return (
    
        <TextField
         InputProps={{style:{borderRadius:12}}}
          variant="outlined"
          value={props.value}
          label={props.label}
          disabled={props.disabled}
          className={classes.textfield}
          inputRef={ref}
          defaultValue={props.defaultValue}
          type={props.type}
          required={props.required}
          readOnly={props.readOnly}
          placeholder={props.hint}
          fullWidth
          inputProps={{
            maxLength: props.maxChar ?? "none",
            style: {
              textTransform: props.transform ?? "capitalize"
            }
          }}
          onChange={(e) => {
            //console.log(e)
            if (props.onChange)
              props.onChange(e.target.value);
          }}
        />
    );
  })


  CustomTextField.propTypes = {
    required: PropTypes.bool,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
    disabled: PropTypes.bool,
    transform: PropTypes.string,
    style: PropTypes.object,
    labelStyle: PropTypes.object,
    fullWidth: PropTypes.bool,
    helperTextLeft: PropTypes.string,
    helperTextRight: PropTypes.string,
    maxChar: PropTypes.number,
    error: PropTypes.bool,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    endAdortment: PropTypes.node,
    startAdortment: PropTypes.node,
    validate: PropTypes.func,
    onKeyUp: PropTypes.func,
    onChange: PropTypes.func,
  }




  export const PhoneTextField = forwardRef(
    function TF(
    props = {
      style: {},
      onError: () => { },
      display: "",
      label: "",
      hint: "",
      required: true,
      ref: "",
      disabled: false,
      fullWidth: true,
      helperTextLeft: "",
      helperTextRight: "",
      error: false,
      onCountryChange: () => { },
      onChange: () => { },
    },ref
  ) {
    const { component: Component, ...other } = props;

      // implement `InputElement` interface
      React.useImperativeHandle(ref, () => ({
        focus: () => {
          // logic to focus the rendered component from 3rd party belongs here
        },
        // hiding the value e.g. react-stripe-elements
      }));

    return (
        <Component
          // className={
          //   // "MuiInputBase-input WithStyles(ForwardRef(InputBase))-input-144"
          // }
          value={props.value}
          style={{
            border: 'none',
            width:'100%',
            padding: "16px"
          }}
          country={props.country}
          disabled={props.disabled}
          onError={(value) => {
            props.onError(value);
          }}
          onCountryChange={(countryCode) => {
            console.log(countryCode)
          }}
          {...other}
        />
    );
  })

  PhoneTextField.propTypes = {
    required: PropTypes.bool,
    value: PropTypes.string,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    hint: PropTypes.string,
    disabled: PropTypes.bool,
    transform: PropTypes.string,
    style: PropTypes.object,
    labelStyle: PropTypes.object,
    fullWidth: PropTypes.bool,
    helperTextLeft: PropTypes.string,
    helperTextRight: PropTypes.string,
    maxChar: PropTypes.number,
    error: PropTypes.bool,
    readOnly: PropTypes.bool,
    type: PropTypes.string,
    endAdortment: PropTypes.node,
    startAdortment: PropTypes.node,
    validate: PropTypes.func,
    onKeyUp: PropTypes.func,
    onChange: PropTypes.func,
    onCountryChange: PropTypes.func,
    component: PropTypes.node,
    country: PropTypes.string,
    onError: PropTypes.func,
  }
AdortmentTextField.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  transform: PropTypes.string,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperTextLeft: PropTypes.string,
  helperTextRight: PropTypes.string,
  maxChar: PropTypes.number,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  endAdortment: PropTypes.node,
  startAdortment: PropTypes.node,
  validate: PropTypes.func,
  onKeyUp: PropTypes.func,
  onChange: PropTypes.func,
}


DefaultTextField.propTypes = {
  required: PropTypes.bool,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  hint: PropTypes.string,
  disabled: PropTypes.bool,
  transform: PropTypes.string,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  fullWidth: PropTypes.bool,
  helperTextLeft: PropTypes.string,
  helperTextRight: PropTypes.string,
  maxChar: PropTypes.number,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  type: PropTypes.string,
  endAdortment: PropTypes.node,
  startAdortment: PropTypes.node,
  validate: PropTypes.func,
  onKeyUp: PropTypes.func,
  onChange: PropTypes.func,
  rows: PropTypes.number,
  multiline: PropTypes.bool,
}