/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Dialog, alpha, IconButton, makeStyles, Snackbar, Typography, useTheme, CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import Stack from '../components/Stack';
import logo from '../assets/logo.png';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Close } from '@material-ui/icons';
import { motion } from 'framer-motion';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Column from '../components/Column';
import Row from '../components/Row';
import { AppBarModule } from '../components/_app_bar_mod';
import FutureBuilder from '../components/_future_builder';
import { EpisodeCard } from '../components/_show_card_mod';
import { ShowContext } from '../contexts/ShowProvider';
import { UserContext } from '../contexts/UserProvider';
import "../index.css";
import { plurify } from '../logic/plural';
import { useMediaQuery } from '../contexts/MediaQueryProvider';
import { parseUrlForId } from '../utils';
import { useProfile } from '../hooks/useProfile';
import SecureServerConnection from '../components/_secure_server_connection';
import { Skeleton } from '@mui/material';
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    }
  },
  iconBtn: {
    '&:hover': {
      color: "white"
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      color: "white"
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


const ShowPage = () => {

  const theme = useTheme()
  const classes = useStyles();
  const {isSmallDevice, device,} = useMediaQuery();
  const [showModal, setShowModal] = useState(false);
  const [preview_body, updatePreviewBody] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [notificationMsg,] = useState('');
  const [show, setShow] = useState({});
  const [loading, setLoading] = useState(false);
  const [notExists, setNotExists] = useState(false);
  const {sessionId, } = useContext(UserContext);
  const [profile,] = useState(null);
  const { retrieveProfileWithCallback } = useProfile();
  const {
    getShow,
    getEpisodes,
    getWatchedData,

  } = useContext(ShowContext)
const showId = parseUrlForId(window.location.href);
const initShow = async()=>{
  if(showId){
    getShow(showId).then((s)=>{
    const seasons = {};
    s.seasons && Object.keys(s.seasons).forEach((key)=>{
      if(s.seasonReleaseDates){
        if(s.seasonReleaseDates[key]){
          if(new Date(s.seasonReleaseDates[key])<=new Date()){
            seasons[`${key}`] = s.seasons[key];
            return;
          }
        }
      }
    });
    s.seasons = seasons;
    setShow(s);
    getEpisodes(showId).then(e=>{
      console.log(e, e.length)
      if(!e.length){
        setNotExists(true);
      }
      setLoading(false)
    });
  });
  
}
}
  useEffect(() => {
     setLoading(true);
     retrieveProfileWithCallback((p)=>{
      initShow(p);
    },()=>{
      console.log('User is not logged in');
      initShow(null);
    })
   }, [])

  const _buildPage = (() => {
    //...
    return <>
      <Column>
        {_buildSeasons()}
      </Column>
    </>
  });
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,//Math.floor(window.innerWidth/350),
    slidesToScroll: 1,
    variableWidth: true,
    arrows: false,

  };

  const _buildSeasons = () => {
    const orderedSeasons = Object.keys(show?.seasons ?? {}).sort((a, b) => {
      return b-a;
    })
    return <Column style={{ marginTop: !isSmallDevice ? "2vh" : "3vh", padding: "30px" }}>
      {orderedSeasons.map((key, index) => {
        console.log(key);
        return <Column key={index} style={{marginBottom: !isSmallDevice ? "2vh" : "3vh",}}>
          <Row alignment="left" style={{alignItems: "end"}}>
            <Typography variant="" align="left" style={{ fontWeight: "bold", fontFamily: "pridi", fontSize: !isSmallDevice ? "1.5vw" : "3vw"  }}>Season {key}</Typography>
            <Typography variant="" align="left" style={{opacity:0.5, fontFamily: "pridi", fontSize: !isSmallDevice ? "1.5vw" : "3vw" , margin:"0 10px" }}>|</Typography>
            <Typography variant="" align="left" style={{opacity:0.5, fontWeight: "bold", fontFamily: "pridi", fontSize: !isSmallDevice ? "1.5vw" : "3vw" }}>{`${show.seasons[key].length} ${plurify("Episode", show.seasons[key].length)}`}</Typography>
          </Row>
          <div style={{ height: '40px' }}></div>
          <Slider {...settings} >
            {

              show.seasons[key].map((data, i) => {
                return <FutureBuilder
                  key={i}
                  rebuild
                  futureFunc={()=>getWatchedData(data.episodeId, profile?.uid ?? sessionId)}
                  placeholder={
                    Array.from({ length: 1 }).map((_, i) => {
                      return <Skeleton key={`watched_${i}`} sx={{ bgcolor: 'grey.900' }} variant="rectangular" width={isSmallDevice ? "100%" : "300px"} height={isSmallDevice ? "200px" : "200px"} style={{ margin: "0 10px" }} />
                    })
                  }
                  builder={

                    (watched) => {
                      if(data.release && new Date(data.release)>new Date())
                        return <></>
                      console.log('watched',watched)
                      if ((watched?.length ?? 0) > 0) {
                        return <EpisodeCard style={{margin:"0 10px"}} episodeId={data} epNo={(i + 1)} mobile={device !== "pc"} seasonNo={key} hoverPlay={true} show={show} timestamp={watched[0].timestamp} />
                      } else {
                        return <EpisodeCard style={{margin:"0 10px"}}  episodeId={data} mobile={device !== "pc"} epNo={(i + 1)} seasonNo={key} hoverPlay={true} show={show} timestamp={0} />
                      }
                    }
                  }
                />
              }
              )
            }

          </Slider>
        </Column>
      })}

    </Column>
  }
  const _buildAboutShow = () => {
    return show && <>
      <Column alignment={!isSmallDevice ? "end" : "centered"} style={{ padding: !isSmallDevice ? "2vh 0 10px 20px" : "10px", width: "100%", height: "100%" }}>

        <motion.div initial={{ scale: 0.3 }} animate={{ scale: 1 }}>
          <Row alignment={!isSmallDevice ? "left" : "centered"} style={{ width: "100%",marginBottom:10 }}>
            <div style={{ width: !isSmallDevice ? "200px" : "120px", }}>
              {show?.logo ? <img alt="logo" src={show?.logo} style={{ width: "100%", }} /> : <Typography>{show?.showTitle}</Typography>}
            </div>
          </Row>
          <Row alignment="start" style={{ paddingLeft: "10px" }}>
            <Column>
              <Typography varaint={'caption'} align={!isSmallDevice ? "left" : "center"} style={{ fontWeight: !isSmallDevice ? 'bold' : "normal", width: !isSmallDevice ? "50%" : "100%", padding: !isSmallDevice ? "" : "20px", fontSize: !isSmallDevice ? "" : "3vw" }}>{show?.showDesc}</Typography>
            </Column>
          </Row>
        </motion.div>

        <div style={{ height: "20px" }}></div>
        <Row alignment={!isSmallDevice ? "left" : "centered"}>
          <Button variant="outlined" onClick={() => {
            updatePreviewBody(_buildTrailerBody())
            setShowModal(true);
          }}>Watch Trailer</Button>
        </Row>

      </Column>

    </>
  }
  const _buildTrailerBody = () => {
    return <div style={{ width: "100%", height: !isSmallDevice ? "800px" : "400px" }}>
      <ReactPlayer playsinline url={show?.trailer} width="100%" height="100%" style={{ width: "100%", height: "100%" }} />
    </div>
  }
  const _buildTrailer = () => {
    return <Column style={{ width: "100%", height: "60%" }}>
      <div style={{ height: "100%", background: "black", width: "100%" }}>
        {<img alt="cover" src={show?.showCover} width="100%" height="100%" style={{ objectFit: "cover" }} />}
      </div>
    </Column>
  }
  return (<div className={classes.root} style={{width:"100%",height:"100%"}}>
    <div style={{width:"100%", height: "100%", margin: "0 auto"}}>
    <CssBaseline />
   {loading ? <Column style={{width: "100%", height: "100%", alignItems:"center"}}>
      <CircularProgress style={{color:"white"}}/>
   </Column>: notExists ? <Column style={{width:'100%', height:'100%'}}>
    <img alt="logo" src={logo} style={{width:"200px", height:"200px", objectFit:"cover"}}/>
      <Typography variant="h5" style={{color:"white"}}>Sorry this show no longer exists</Typography>
      <Typography variant="caption" color={'textSecondary'} style={{color:"white"}}>If you think this is a mistake please contact us</Typography>
   </Column> : <><Stack>

      <div style={{ background: "#0000000d", height: "100%", width: "100%" }}></div>
      <div style={{ background: "linear-gradient(0deg, black 20%,#0000009c 54%, transparent)", height: "100%", width: "100%" }}></div>
      {_buildTrailer()}



    </Stack>
    <Stack startLayer={106} style={{ marginTop: "40vh" }}>
      <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} ${!isSmallDevice ? '80%' : "88%"}, transparent)`, height: "100%", width: "100%", transform: ' translate(0, -21vh)' }}></div>
      {_buildPage()}
    </Stack>
    <Stack startLayer={109} style={{ height: !isSmallDevice ? "40vh" : "65vh" }}>
      {_buildAboutShow()}
    </Stack></> }
    {<AppBarModule pageIndex={3} customRightModule={
      <Row alignment={"right"}
        style={{ width: "50%", padding: "0 20px" }}>
        <Column>
          <Typography align="right" variant="" style={{ fontWeight: 'bold', fontSize: "1.1vw", color: "white", fontFamily: "pridi", textTransform: "uppercase" }}>Dearfxch <span style={{ fontWeight: "200" }}>Originals</span></Typography>
        </Column>
      </Row>}
    />}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
    <SecureServerConnection />
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification}
      autoHideDuration={notificationMsg.timeout}
      onClose={() => {
        showNotification(false)
      }}
      message={notificationMsg.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  </div>
  </div>
  )


}
export default ShowPage;