/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Button, Card, CardActionArea, CardContent, Divider, StepConnector, Typography, makeStyles, withStyles } from "@material-ui/core";
import Row from "./components/Row";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { CancelOutlined, Check, CheckCircleOutlineRounded, NotInterestedRounded, Payment, PresentToAllRounded, RedeemRounded, Settings, Visibility } from "@material-ui/icons";
import { AssuredWorkloadRounded, ChangeCircleOutlined, Pending, VerifiedRounded } from "@mui/icons-material";
import { DTVApi } from "./config/api";

export const youtubeConfig = {
    youtube: {
      playerVars: {
        controls: 0, // Set to 0 to hide YouTube controls
        showInfo: 1,
        start: 10
      },
    },
  };
export const formatDuration = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const remainingSeconds = seconds % 3600;
    const minutes = Math.floor(remainingSeconds / 60);
    const remainingSecondsAfterMinutes = remainingSeconds % 60;
  
    let formattedTime = '';
  
    if (hours > 0) {
      formattedTime += `${String(hours.toFixed(0)).padStart(2, '0')}:`;
    }
  
    if (minutes > 0 || hours > 0) {
      formattedTime += `${String(minutes.toFixed(0)).padStart(2, '0')}:`;
    }else{
        formattedTime += `00:`;
    }
  
    formattedTime += String(remainingSecondsAfterMinutes.toFixed(0)).padStart(2, '0');
  
    return formattedTime;

  }
  
  
export const TimeAgo = ({timestamp,short}) => {
    const [timeLabel, setTimeLabel] = useState("just now");
    useEffect(()=>{
        setTimeLabel(lastSeen(timestamp,short));
        const interval = setInterval(()=>{
            setTimeLabel(lastSeen(timestamp,short));
        },1000);
        return ()=> clearInterval(interval);
    },[timestamp]);
    return <span>{timeLabel}</span>
}
TimeAgo.propTypes = {
    timestamp: PropTypes.number,
    short: PropTypes.bool
}
export const lastSeen = (timestamp,short)=>{
    //create a function to calculate the time diffrence from now to the timestamp reurn just now, x min ago, x hr ago, x day ago, x week ago, x month ago, x year ago
    const diff = new Date().getTime() - timestamp;
    const seconds = diff / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days =  hours / 24;
    const weeks = days / 7;
    const months = weeks / 4;
    const years = months / 12;
    if(seconds < 60){
        return short ? "now":"Just Now";
    }else if(minutes < 60){
        return `${Math.floor(minutes)} ${short ? 'min ago' : 'minutes ago'}`;
    }else if(hours < 24){
        return `${Math.floor(hours)} ${short ? 'hr ago' : 'hours ago'}`;
    }else if(days < 7){
        return `${Math.floor(days)} ${short ? 'd ago' : 'days ago'}`;
    }else if(weeks < 4){
        return `${Math.floor(weeks)} ${short ? 'wks ago' : 'weeks ago'}`;
    }else if(months < 12){
        return `${Math.floor(months)} ${short ? 'mths ago' : 'months ago'}`;
    }else{
        return `${Math.floor(years)} ${short ? 'yrs ago' : 'year ago'}`;
    }
}

export const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
    })(StepConnector);
    
    const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#784af4',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    });
    
    export const QontoStepIcon = (props)=> {
    const classes = useQontoStepIconStyles();
    const { active, completed,name} = props;
    
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <Check className={classes.completed} /> : getStatusIcon(name,active ? 'white':'grey',16)}
      </div>
    );
    }
    
    QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    
    icon: PropTypes.number,
    
    name: PropTypes.string,
    };
export const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        '& .MuiDivider-root':{
            backgroundColor:'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root':{
            color: 'white'
        }
      },
      packageCard:{
        minHeight: 400,
        minWidth: 250,
        padding: 20,
        borderRadius: 12,
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        background: "transparent",
        border: "1px solid #ffffff2f",
        backdropFilter: "blur(20px)",
        '-webkit-box-reflect': 'below 20px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.13))'
      }
}));
export const calcDaysPast = (date)=>{
    const now = new Date();
    const d = new Date(date);
    const diff = now.getTime() - d.getTime();
    return Math.floor(diff / (1000 * 60 * 60 * 24));

}

  export const loadFromDevice = (file, callback)=>{
    var fileReader = new FileReader();
    if(typeof file?.file !== "string"){
      console.log("file is no string", file);
        fileReader.onload = function(){
        var dataUri = fileReader.result;
          callback(dataUri); 
      }
      if(file)
      fileReader.readAsDataURL(file)
    }else{
      console.log("file is a string", file);
      callback(file.file);
    }
  }
  export const parseUrlForId = (url,shiftBy = 1) => {
    const urlSplit = url.split('/');
    const mainPath = urlSplit[urlSplit.length - shiftBy];
    const idSplit = mainPath.split(':');
    const id = idSplit[idSplit.length - 1];
    return id;
}
export const handleDownload = async(url)=>{
    const proxyImageUrl = `${DTVApi.url}download_media?url='${url}'&filename='dtv_download'`;
    window.open(proxyImageUrl, '_blank');
}
export const _buildConfirmation = ({title="", okText="", onOkClicked = ()=>{}, onCancelClicked = () => {}}) => {
    return <>
        <Card>
            <CardContent><Typography align="center" variant="overline">{title}</Typography></CardContent>
            <Divider />
            <CardActionArea>
                <Row alignment="space-between" style={{ width: "100%" }}>
                    <Button onClick={
                       ()=> onCancelClicked()
                    }>Cancel</Button>
                    <Button color="primary" onClick={() => {
                            onOkClicked();
                    }} >{okText}</Button>

                </Row>
            </CardActionArea>
        </Card>
    </>
}
export const camelCaseToSentenceCase = (str) => {
    return str.replace(/([A-Z])/g, ' $1')
      .replace(/^./, function(str){ return str.toUpperCase(); })
  
}
export  const makeUnique = (arr) => {
    return !arr ? arr : Object.values(
        arr.reduce((accumulator, obj) => {
          accumulator[obj.id] = obj;
          return accumulator;
        }, {})
      );
}
export const parseUrlForQueryParams = (url)=>{
    const urlSplit = url.split('/');
   const mainPath = urlSplit[urlSplit.length - 1];
   const idSplit = mainPath.split('?');
   const query = idSplit[idSplit.length - 1];
   const params = query.split('&');
   const result = {}
   for(const p of params){
       const temp = p.split('=');
       result[`${temp[0]}`] = temp[1];
   }
   return result;
}
export const FieldKeys = {
    topUp: "TopUp"
}
export const getStatusColor = (status) => {
    switch(status){
        case "submitted":
            return "#7a7a7a";
        case "approved":
            return "#49a45f";
        case "rejected":
            return "#a44949";
        case "pending":
            return "#bb8be4";
        case "cancelled":
        case "cancelled-1":
        case "cancelled-2":
            return "#a44949";
        case "payment":
            return "#49a2a4";
        case "validating" : case "deposit-processing":case "payment-processing":
            return "#bb8be4";
        case "published":case "completed":
            return "#49a45f";
        default:
            return "#7a7a7a";
    }
}
export const getStatusIcon = (status,color,size) => {
    switch(status){
        case "submitted":
            return <PresentToAllRounded style={{color: color??getStatusColor(status), fontSize:size ?? 14}}/>
        case "approved": case "deposit-paid": case "payment-recieved": case 'completed':case "reviewed":
            return <CheckCircleOutlineRounded style={{color:color?? getStatusColor(status), fontSize:size ??14}}/>
        case "rejected": case "deposit-failed": case "payment-failed":
            return <NotInterestedRounded style={{color:color?? getStatusColor(status), fontSize:size ??14}}/>
        case "pending":case "payment-processing":
            return <Pending style={{color:color?? getStatusColor(status), fontSize:size ??14}}/>;
        case "cancelled": case "cancelled-1": case "cancelled-2":
            return <CancelOutlined style={{color: color??getStatusColor(status), fontSize:size ??14}}/>;
        case "payment": case 'deposit':
            return <Payment style={{color: color??getStatusColor(status), fontSize:size ??14}}/>;
        case "validating":
            return <AssuredWorkloadRounded style={{color: color??getStatusColor(status), fontSize:size ??14}}/>;
        case "published":
            return <VerifiedRounded style={{color: color??getStatusColor(status), fontSize:size ??14}}/>;
        case "deposit-processing":
            return <Pending style={{color: color??getStatusColor(status), fontSize:size ??14}}/>;
         case "in-progress":
            return <Settings style={{color: color??getStatusColor(status), fontSize:size ??14}}/>;
        case "review":
            return <Visibility style={{color: color??getStatusColor(status), fontSize:size ??14}}/>;
        case "delivered":
            return <RedeemRounded style={{color: color??getStatusColor(status), fontSize:size ??14}}/>;
        case "change-requested":
            return <ChangeCircleOutlined style={{color: color??getStatusColor(status), fontSize:size ??14}}/>;
        default:
            return <></>;
    }
}

export const toPascalCaseWithSpaces = (str) => {
    return str.replace(/([a-z])([A-Z])/g, '$1 $2');
}
export const isValidLink = (link) => {
    if(!link) return false;
    const regex = new RegExp('^(http|https)://', 'i');
    return regex.test(link);
}
export const ApplicationProcess = [
    "submitted",
    "pending",
    "reviewed",
    "change-requested",
    "rejected",
    "cancelled",
    "payment",
    "payment-processing",
    "payment-failed",
    "completed"
  ]
export const VideoSubmissionProgress = ["submitted","pending", "approved", "rejected",  "cancelled", "payment", "validating", "published"];
export const ServiceProcess = ["submitted",
"pending", 
"approved",
 "rejected", 
  "cancelled",
  "deposit",
  "deposit-processing",
  "deposit-paid",
  "deposit-failed",
  "cancelled-1",
  "in-progress",
  "review",
  "cancelled-2",
  "payment","payment-processing","payment-recieved", "payment-failed", "delivered"];


 export const PrimeReactPassThrough = {
        item: (props)=>{
            return {
                ...props,
                style: {
                    flex: "none"
                }
            }
        }
};