import axios from "axios";
import { RouteNames, baseUrl } from "../config/api";

export const EventService = () => {
    const createEvent = async ({title, poster,dateFrom, dateTo, headlineArtist, artists,website, location,tickets,logo,hosts,sponsors, socials}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.event}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                title,
                poster,
                dateFrom,
                dateTo,
                website,
                headlineArtist,
                artists,
                location,
                tickets,
                logo,
                hosts,
                sponsors,
                socials
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getEvent = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.event}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                eventId: id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteEvent = async ({id}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.event}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                eventId: id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateEvent = async (event) => {
        try {
            console.log(event);
            const request = {
                method: 'put',
                url: `${baseUrl}/${RouteNames.event}`,
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                },
                params:{
                    eventId: event.eventId
                },
                data: event
            }
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        }catch(e){
            console.log(e);
        }
    }
    const getAllEvents = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.eventList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchEventsByDate = async ({cursor, limit, offset, dateFrom, dateTo}) => {

        console.log(dateFrom, dateTo);
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.eventSearchDate}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset,
                from: dateFrom,
                to: dateTo
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchEvent = async ({cursor, limit, offset, query}) => {
        console.log(query);
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.eventSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset,
                query
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        createEvent,
        getEvent,
        deleteEvent,
        updateEvent,
        getAllEvents,
        searchEventsByDate,
        searchEvent
    }
};