import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import firebase_app from "../config/firebase";

const LoginRequired = ()=>{
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)
    useEffect(()=>{
        setTimeout(()=>{
            firebase_app.auth().onAuthStateChanged(()=>{
                if(firebase_app.auth().currentUser === null)
                setShowModal(true);
            })
        }, 2000)
    },[]);
   
    const handleLeave = ()=>{
        navigate(-1);
    }
    const handleLogin = ()=>{
        navigate("/login");
    }
    return (
        <div>
            <Dialog open = {showModal} >
                <DialogTitle>Login Required</DialogTitle>
                <DialogContent>We have detected that you are not currently signed in.</DialogContent>
                <DialogActions>
                    <Button onClick={handleLeave}>Leave</Button>
                    <Button variant="contained" color = "primary" onClick={handleLogin}>Login</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default LoginRequired;