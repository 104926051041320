import { createPDF, PDFJSONParser } from "ruki-react-pdf-creator";
import template from "../assets/templates/invoice-01";
export async function createInvoice() {
  const { components, pageSettings } = await PDFJSONParser(template);
  const { pdfUrl, pdfBlob, } = await createPDF({
    components,
    pageSetting: pageSettings,
    logo: ""
  });

  const href = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = href;
  console.log(href);
  link.download = `pdf-components(${new Date().toLocaleString()}).pdf`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  console.log(pdfUrl);
}
