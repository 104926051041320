/* eslint-disable react-hooks/exhaustive-deps */

import { Avatar, makeStyles, Typography } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ChromeReaderMode, Headset, TvRounded, VideoLibraryRounded } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import backgroundVideo from '../assets/backgroundVideo.mp4';
import banner from '../assets/banner.png';
import image1 from '../assets/homepageImage.png';
import logo from '../assets/logo.png';
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { SiteDataContext } from '../config/SiteData';
import "../index.css";

import { motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import Slider from "react-slick";
import { AppBarModule, sitemap } from '../components/_app_bar_mod';
import { NavBubble } from '../components/_nav_bubbles';
import firebase_app from '../config/firebase';
import { UserContext } from '../contexts/UserProvider';
import { Grid } from 'semantic-ui-react';

export const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        '& .MuiDivider-root':{
            backgroundColor:'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root':{
            color: 'white'
        }
      },
      iconBtn:{
        color:"#ffffff47",
        '&:hover': {
            color:"white"
        },
      },
      sideBarSocials:{
          color:"#ffffff47",
          fontSize:"12px",
        '&:hover': {
            color:"white"
        },
      },
      divider:{
          background: "rgba(1,1,1, 1)"
      },
      center:{
        textAlign:"center",
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      gridList: {
        width: 500,
        height: 450,
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
      toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
     
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        padding:"3px 10px",
       // background:"linear-gradient(45deg, black, #d09612bd);",
        background:"transparent",
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      menuButton: {
        marginLeft: 5,
        color:"white"
      },
      menuButtonHidden: {
        display: 'none',
      },
      title: {
        flexGrow: 1,
      },
     list:{
        transition: theme.transitions.create(["background", "background-color"], {
            duration: theme.transitions.duration.complex,
          }),
          "&:hover": {
            backgroundColor: "#6363638c",
            color:"black",
          },
     },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      grow:{},
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
      mobileNav:{
          width:"60%"
      },
      pcNav:{
          width:"30%"
      },
      logo:{
          height:"60px",
          width:"60px"
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.text.primary,
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
      fixedHeight: {
        height: 240,
      },
  }));

const HomePage = ()=>{
  const history = useNavigate();
const {
  SiteData,
} = useContext(SiteDataContext)
  const classes = useStyles();
  const [device,setDevice] = useState('pc');
  const [,setDrawerWidth] = useState("30%");
  const {
    profile,
    updateProfile } = useContext(UserContext);
    useEffect(()=>{
        updateDevice();
        const fetchUser = async()=>{
           if(firebase_app.auth().currentUser){
            var p =  await firebase_app.database().ref("Users").child(firebase_app.auth().currentUser.uid).once('value');
             updateProfile({...p.val(), uid:firebase_app.auth().currentUser.uid})
              console.log(profile);
          
           } 
        
        }
       fetchUser();
        window.addEventListener("resize", updateDevice);
    },[])
  const updateDevice = ()=>{
      if(window.innerWidth<550)
      {
          setDevice('mobile');
          setDrawerWidth(300)
      }
      else {
          setDevice('pc')
          setDrawerWidth(280)
        }
  }
 
    
    const navLinkdata = [
          SiteData.liveSections?.shows? {
                id:3,
                name: SiteData.showPage?.pageName ?? "Show",
                icon:<TvRounded style={{fontSize: device==='pc'? "32px" : "18px"}}/>,
                video:`${SiteData.showPage.featured?.filter((e)=>e)?.[0]?.trailer}`
            }:"",
            SiteData.liveSections?.playlist?{
                id:4,
                name: SiteData.playlistPage?.pageName ?? "Playlists",
                icon:<Headset style={{fontSize:device==='pc'? "32px" : "18px"}}/>,
                video:backgroundVideo
            }:"",
            SiteData.liveSections?.releases?{
                id:5,
                name: SiteData.releasesPage?.pageName ?? "Releases",
                icon:<VideoLibraryRounded style={{fontSize:device==='pc'? "32px" : "18px"}}/>,
                video:`https://www.youtube.com/watch?v=${SiteData.releasesPage.featured[0].id}`
            }:"",
            SiteData.liveSections?.stories?{
                id:6,
                name: SiteData.storyPage?.pageName ?? "Stories",
                icon:<ChromeReaderMode style={{fontSize:device==='pc'? "32px" : "18px"}}/>,
                title: SiteData.storyPage.featured ? SiteData.storyPage.featured[SiteData.storyPage.featured.length-1].headline : "",
                video:SiteData.storyPage.featured ? SiteData.storyPage.featured[SiteData.storyPage.featured.length-1].photo : ""
            }:"",
        ]
    const _buildPage=()=>{

       
        var temp=navLinkdata.filter((e)=>e!=="");
        return (
          <Row aligment="centered" style={{width:"100%"}}>
            <div style={{width:"100%"}}>
              <Slider 
              style={{maxWidth:"1500px"}}
                dots={false}
                slidesToShow={Math.floor(window.innerWidth/300)}
                slidesToScroll={temp.length}
                autoplay={false}
                infinite={false}
                arrows={false}
                autoplaySpeed={3000}
                responsive= {[
                  {
                    breakpoint: 1024,
                    settings: {
                      slidesToShow: 3,
                      slidesToScroll: 3,
                      infinite: true,
                      dots: true
                    }
                  },
                  {
                    breakpoint: 600,
                    settings: {
                      slidesToShow: 2,
                      slidesToScroll: 2,
                      initialSlide: 2
                    }
                  },
                  {
                    breakpoint: 480,
                    settings: {
                      slidesToShow: 1,
                      slidesToScroll: 1
                    }
                  }
                ]}
            >
                {
                temp.map((data,i)=>data!==""?<NavBubble height="300px" key = {i} onClick={()=>{
                 console.log(data.name.toLowerCase())
                  history(sitemap[data.id].toLowerCase())
                }}
                backgroundVideo={data.video} title={data.title} name={data.name} icon={data.icon}  width="300px"/>:<></>)
                }
            </Slider> </div>  </Row>
        )
    }
    
    const _buildPageMobile=()=>{
      var temp=navLinkdata.filter((e)=>e!=="");
      return <Column style={{width:"100%", height:"100%", marginTop: 30}}>
        <Grid container spacing={6} >
          {
            temp.map((data,i)=>data!==""?<Grid item xs={12} md={12} key={i} style={{margin: "10px 0"}}>
              <NavBubble height="100px" onClick={()=>{
                console.log(data.name.toLowerCase())
                  history(data.name.toLowerCase())
                }}
                backgroundVideo={data.video} title={data.title} name={data.name} icon={data.icon} mobile />
            </Grid>:<></>)
          }
        </Grid>
      </Column>
    }
    return (<div className={classes.root}>
         <CssBaseline />
        <Stack>
            <div style={{height:"100%", width:"100%",}}>
               {SiteData.isVideo? <ReactPlayer playsinline url={SiteData.featuredCover??backgroundVideo} 
                playing={true}
                height="100%"
                width="100%"
                loop={true}
                muted={true}
                    style={{objectFit: "cover"}} 
                    />:<img alt="featured video" style={{
                      height:"100%",
                      width:"100%",
                      objectFit:"cover"
                    }} src={SiteData.featuredCover??banner}/>}
            </div>
            <div style={{background:"#0000000d", height:"100%", width:"100%"}}/>
            <div style={{background:"linear-gradient(0deg, black 14%, transparent)",height:"100%", width:"100%"}}/>
            {SiteData.showCutout?(device==="pc"?<Column style={{height:"100%",width:"100%"}} alignment="end"><motion.div style={{}}  animate={{y:-120}}>
              <img alt="" src={image1} style={{objectFit:"contain",maxWidth:"1200px",maxHeight:"900px"}} width="100%" height="100%"/>
              </motion.div></Column>:<Column style={{height:"100%",overflow:"hidden",width:"100%"}} alignment="center">
                <motion.div initial={{y:-120}} className={"centered-row"} animate={{y:-40}}>
              <img alt="" src={image1} style={{objectFit:"contain",maxWidth:"1200px",}} width="200%" height="100%"/>
              </motion.div></Column>):<></>}
              <div style={{background:"linear-gradient(0deg, black 5%, transparent)",height:"100%", width:"100%"}}></div>
             {device==="pc"? <Column style={{height:"100%",padding:"10vh",width:"100%"}} alignment="end"><motion.div animate={{y:-120}}>
              <Typography variant={"overline"} style={{fontSize:"1.5vw",fontWeight:"lighter",fontFamily:""}}>{SiteData.live?"Welcome To":"Coming Soon"}</Typography>
             <Row alignment="centered">
               <Column>
                <Avatar style={{width:"80px",height:"80px"}} src={logo}/>
               </Column>
              
               <div style={{width:"10px"}}></div>
             <Typography style={{fontSize:"4vw",textTransform:"uppercase",fontFamily:""}}>{SiteData.name}</Typography>

             </Row>
             <Typography variant={"caption"} style={{fontWeight:"lighter",fontFamily:""}}>{SiteData.slogan}</Typography>
              </motion.div></Column>:
              <Column style={{height:"100%",padding:"10vh",width:"100%"}} alignment="end"><motion.div animate={{y:10}}>
              <Typography variant={"overline"} style={{fontSize:"2.5vw",fontWeight:"lighter",fontFamily:""}}>{SiteData.live?"Welcome To":"Coming Soon"}</Typography>
             <Row alignment="centered">
               <Column>
                <Avatar style={{width:"40px",height:"40px"}} src={logo}/>
               </Column>
              
               <div style={{width:"10px"}}></div>
               <Column>
                <Typography style={{fontSize:"6vw",textTransform:"uppercase",fontFamily:""}}>{SiteData.name}</Typography>
            
               </Column>
            
             </Row>
             <Typography variant={"caption"} style={{fontSize:"2.5vw",fontWeight:"lighter",fontFamily:""}}>{SiteData.slogan}</Typography>
              </motion.div></Column>}
           {SiteData.live? <Column alignment="end" style={{height:"100%", width:'100%', overflowX:"hidden"}}>{device==='pc'? _buildPage():_buildPageMobile()}
                </Column>:<></> }
        </Stack>
        {SiteData.live?<AppBarModule showCart pageIndex={0} iconClass={classes.iconBtn} menuButton={classes.menuButton} elevation={1} appbarBackground="transparent"/>:<></>}
    </div>)

   
}
export default HomePage;