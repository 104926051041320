/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Avatar, Chip, Typography } from "@material-ui/core";
import Column from "../../Column";
import Stack from "../../Stack";
import Ratings from "../../ratings";
import useServiceBooking from "../../../contexts/ServiceBookingProvider";
import { Skeleton } from "@mui/material";
import PropTypes from 'prop-types'

const AgentCard = (({ agent:Agent, agentId, style}) => {
    const cardStyle = {
        borderRadius: "10px",
        border: "1px solid rgb(255 255 255 / 32%)",
      
    }
    const [agent, setAgent] = useState(Agent);
    const {getAgent} = useServiceBooking();
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        console.log(agentId)

        if(agentId){
            setLoading(true);
            getAgent(agentId).then((agent) => {
                console.log(agent);
                setAgent(agent);
                setLoading(false);
            })
        }
    }, []);
    return loading ? <Column>
        <Skeleton variant="rectangular" width={200} height={100} sx={{bgcolor: 'grey.900'}} />
    </Column> : !agent ?<></> : <Column style={{...cardStyle, ...style, position:'relative',overflow:'clip'}}>
        <Stack>
            <img alt="" src={agent.picture} style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'blur(5px)'}}/>
            <div style={{
                height: '100%',
                width: '100%',
                background: "linear-gradient(180deg, rgba(0,0,0,0) -95%, rgba(0,0,0,1) 100%)",
            }}/>
            <Column style={{width: '100%', padding: 20}}>
                <Avatar src={agent.picture} style={{width: 80, height: 80, border: '2px solid white'}}/>
                <Typography variant="h5" style={{textAlign: "left", fontWeight: "bold", marginTop: "1rem"}}>{agent.name}</Typography>
                <Ratings ratings={Object.values(agent.ratings??{})}/>
                <Typography color="textSecondary" variant="caption" style={{textAlign: "left", marginTop: "0.2rem"}}>{agent.email}</Typography>
                <Typography color="textSecondary" variant="caption" style={{textAlign: "left", marginTop: "0.2rem"}}>{agent.location}</Typography>
                <Chip variant="outlined" style={{margin: "0", marginTop: "0.3rem"}} label={agent.serviceType?.name??agent.serviceType}/>
                
            </Column>
        </Stack>
    </Column>
});
export default AgentCard;


AgentCard.propTypes = {
    agent: PropTypes.object,
    agentId: PropTypes.string,
    style: PropTypes.object
}