import { DTVApi } from "../config/api";
import { Profile, ProfileTranslator } from "../models/profile";

export const useAdmin = ()=>{
    const retrieveAdmin = async (uid: string): Promise<Profile | undefined> =>{
        const request = new Request(`${DTVApi.url}api/dearfach/auth_get_user?id=${uid}`,
        {
            method:'GET',
            mode:'cors',
            credentials: 'same-origin',
            headers: new Headers({ 'Content-Type': 'application/json','Access-Control-Allow-Origin': '*' }),
        });
        
        try{
            const response = await fetch(request);
                if(response.status<200 || response.status>300){
                    console.error(response.status)
                    return undefined;
                }else{
                    const data = await response.json();
                    console.log(data);
                  return ProfileTranslator.fromJson(data.data);
                }
                  
            }catch(e){
                console.error()
                return undefined;
            }
    }
    return {
        retrieveAdmin
    }
}