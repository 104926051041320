/* eslint-disable react-hooks/exhaustive-deps */

import { Chip } from "@material-ui/core";
import { motion } from "framer-motion";
import React,{ useEffect, useState } from "react";
import Column from "../components/Column";
import BookingCard from "../components/_booking_card";
import { useMediaQuery } from "../contexts/MediaQueryProvider";
import { getUserBooking } from "../logic/databaseHandler";
import PropTypes from "prop-types";
const TicketPage = ({location})=>{
    const [booking, updateBooking] = useState();
    const [uid, setUid] = useState({});
    const { device } = useMediaQuery()
    const _getBooking = async()=>{
        let url = location.pathname;
        let path = url.split(":")[url.split(":").length-1];
        let id = path.split("_")[0];
        let bid = path.split("_")[1];
        console.log(id,bid)
        setUid(id);
        let b = await getUserBooking(id,bid);
        updateBooking(b);
    }
    useEffect(() =>{
        _getBooking()
    },[])
    return booking ? <Column className="centered">
        <motion.div animate={{y:-150}}>
        <Chip style={{color:Date.now() > booking.startTime ? "red":"green"}} variant="default" label={Date.now() > booking.startTime ? "EXPIRED" :"VALID"}/></motion.div>
           <motion.div initial = {{scale:1}} animate={{scale:device==="xs" ? 1.2 : 2}}>
               <BookingCard booking = {booking} uid = {uid}/>
               </motion.div>
        </Column>:<></>
}
export default TicketPage;

TicketPage.propTypes = {
    location: PropTypes.object
}