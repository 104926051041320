/* eslint-disable react-hooks/exhaustive-deps */

import React,{ useEffect, useState } from "react";
import useServiceBooking from "../../../contexts/ServiceBookingProvider";
import { Skeleton } from "@mui/material";
import {Card, Accordion, CardContent, AccordionSummary, Typography, Chip, Avatar, IconButton, InputAdornment, OutlinedInput, Select, FormControl, MenuItem, AccordionDetails, Button } from "@material-ui/core";
import Row from "../../Row";
import Column from "../../Column";
import FutureBuilder from "../../_future_builder";
import { ServiceProcess, getStatusColor, getStatusIcon } from "../../../utils";
import { useDatabase } from "../../../hooks/useDatabase";
import { useNavigate } from "react-router";
import { OpenInBrowser, Search } from "@material-ui/icons";
import { useMediaQuery } from "../../../contexts/MediaQueryProvider";
import { getNormalTextFromMarkdown } from "../../_markdown_textarea";
import PropTypes from 'prop-types'

const ServiceTracker = ({profile}) => {
    const {loading, getRequestsByUser, getAgent, getServiceTypes } = useServiceBooking();
    const {retrieveServicePackage} = useDatabase();
    const history= useNavigate();
    const [serviceRequests, setServiceRequests] = useState({});
    const [filteredRequests, setFilter] = useState({});
    const [serviceTypes, setServiceTypes] = useState([]);
    const [currentTypeFilter, setCurrentTypeFilter] = useState('all');
    const [currentProgressFilter, setCurrentProgressFilter] = useState('all');
    const searchRef = React.useRef(null);
    const {isLargeDevice, device} = useMediaQuery();
    const [serviceProgress, ] = useState(ServiceProcess.filter((process)=>
    (process !== 'deposit-processing' && process !== 'deposit-paid' && process !== 'deposit-failed' && 
    process !== 'cancelled-1' && process !== 'cancelled-2' && process !== 'payment-failed' && 
    process !== 'payment-recieved' && process !== 'payment-processing') 
    ));
    useEffect(() => {
        getRequestsByUser(profile.uid).then((requests) => {
            setServiceRequests(requests??{});
            setFilter(requests??{});
            getServiceTypes().then((types)=>{
                setServiceTypes(types??[]);
            })
        });
    },[JSON.stringify(profile)]);
    const handleSearch = ({ typeFilter, progressFilter}) => {
        const applyTypeFilter = Object.values(serviceRequests).filter((request) => {
            return (typeFilter === 'all' ) ? true : request.serviceType.name === (typeFilter );
        });
        const applyProgressFilter = Object.values(serviceRequests).filter((request) => {
            return (progressFilter === 'all') ? true : request.status === (progressFilter );
        })
        const searchFilter = Object.values(serviceRequests).filter((request) => {
            return request.description.toLowerCase().includes(searchRef.current?.value?.toLowerCase());
        });
        const filtered = [applyTypeFilter, applyProgressFilter, searchFilter].reduce((a, b) => a.filter(c => {
            return b.includes(c);
        }));
        console.log(applyTypeFilter, applyProgressFilter, searchFilter);
        setFilter(
            filtered
        );

    }
    const _buildFilter = () => {
        const innerBody = <>
      
            <FormControl variant="outlined" size="small">
      <Select
        value={currentProgressFilter}
        onChange={(event)=>{setCurrentProgressFilter(event.target.value);
            handleSearch({
                search: searchRef.current?.value,
                typeFilter: currentTypeFilter,
                progressFilter: event.target.value
            })
        }}
        style={{
          borderRadius: 10,
          margin:'0 10px'
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Font Size' }}
      >
             <MenuItem  value={'all'}>
                            <Typography variant="caption">{'All Status'}</Typography>
                        </MenuItem>
                    {serviceProgress.map((process, index) => {
                        return <MenuItem key={index} value={process}>
                            <Typography variant="caption">{process.replace('-',' ')}</Typography>
                        </MenuItem>
                    })}
                </Select>
                </FormControl>
                {device === 'xs' ? <div style={{height: 10}}/> : <></>}
                <FormControl variant="outlined" size="small">
        <Select 
        value={currentTypeFilter}
        onChange={(event)=>{setCurrentTypeFilter(event.target.value);
            handleSearch({
                search: searchRef.current?.value,
                typeFilter: event.target.value,
                progressFilter: currentProgressFilter
            })
        }}
        style={{
            borderRadius: 10,
            margin:'0 10px'
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Font Size' }}
            >
            <MenuItem  value={'all'}>
                            <Typography variant="caption">{'All Types'}</Typography>
                        </MenuItem>
                    {Object.values(serviceTypes).map((serviceType, index) => {
                        return <MenuItem key={index} value={serviceType.name}>
                            <Typography variant="caption">{serviceType.name}</Typography>
                        </MenuItem>
                    })}
                </Select>
                </FormControl>
                {device === 'xs' ? <div style={{height: 20}}/> : <></>}

            <OutlinedInput
                placeholder="Search"
                onChange={
                    ()=>{
                        handleSearch({
                            search: searchRef.current?.value,
                            typeFilter: currentTypeFilter,
                            progressFilter: currentProgressFilter
                        })
                    }
                }
                inputRef={searchRef}
                endAdornment={<InputAdornment position="end"><Search /></InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                    'aria-label': 'weight',
                    style:{padding: '10px 15px', borderRadius: 15}
                }}

            />
        </>
        const body = <>
         <Typography style={{ fontWeight: "bold", fontSize: "22px", textAlign: "left", marginBottom: 10 }}>Service Bookings</Typography>
          {device === 'xs' ? <Column>{innerBody}</Column> : <Row>{innerBody}</Row>}
            </>
        return isLargeDevice ? <Row alignment="space-between">{body}</Row> : <Column>
            {body}
        </Column>
    }
    return loading && !profile ? <Skeleton  sx={{ bgcolor: 'grey.900' }} variant="rectangular" width="100%" height={200} style={{margin: 20}} /> : (
        <Card variant='outlined'>
            <CardContent>
                {_buildFilter()}
                <div style={{ height: 20 }} />
                {filteredRequests.length<=0 ? 
                <Typography variant="caption">No Service Bookings Found</Typography>
                :Object.values(filteredRequests).map((request) => {
                    const description = request.description?.text ? getNormalTextFromMarkdown(request.description?.text) : request.description ;
                    
                return <Accordion 
                    style={{
                        margin: "10px 0",
                    }}
                    onClick={
                        ()=>{
                            console.log(profile.uid)
                            history(`ServiceBooking?id=${request.id}`)
                        }
                    }
                variant="outlined" expanded={device==='xs'} key={request.id}>
                    <AccordionSummary>
                        <Row style={{width: "100%"}} alignment="space-between">
                            <Column style={{width: "80%",alignItems: 'start'}}>
                                <Typography variant="caption" style={{
                                    textAlign: "left",
                                }}>{`${new Intl.DateTimeFormat("en-US").format(new Date(request.timestamp))} | ${description?.substring(0, Math.min(description.length, 50))}...`}</Typography>
                                <div style={{height: 5}}/>
                                <FutureBuilder future={getAgent(request.agentId)} builder = {
                                    (agent)=>{
                                      return <Chip variant="outlined" style={{margin:"0"}} avatar={ <Avatar src={agent.picture} />} label={agent.name}/>  
                                    }
                                } />
                            </Column>
                            {device==='xs' ? <></> : <Row style={{alignItems:"center"}}>
                                <Chip style={{margin:"0 5px",borderColor: getStatusColor(request.status), textTransform:'capitalize'}} icon={ getStatusIcon(request.status)} label={request.status?.replace('-',' ')} size="medium" variant="outlined"/>
                                <FutureBuilder futureFunc={()=>retrieveServicePackage(request.packageId)} builder = {
                                    (servicePackage)=>{
                                        return <Chip color="primary" style={{margin:"0 5px", textTransform:'capitalize'}} label={`${servicePackage.name} Package`} size="medium" variant="outlined"/>  
                                        }
                                    } />
                                <Chip style={{margin:"0 5px", textTransform:'capitalize'}} label={request.serviceType.name} size="medium" variant="outlined"/>
                                <IconButton  onClick={
                                    ()=>{
                                        console.log(profile.uid)
                                        history(`ServiceBooking?id=${request.id}`)
                                    }
                                }>
                                    <OpenInBrowser/>
                                </IconButton>
                            </Row>}
                        </Row>
                    </AccordionSummary>
                    {device==='xs' ? <AccordionDetails>
                    <Row style={{alignItems:"center", flexWrap:'wrap'}}>
                                <Chip style={{margin:"5px 5px",borderColor: getStatusColor(request.status), textTransform:'capitalize'}} icon={ getStatusIcon(request.status)} label={request.status?.replace('-',' ')} size="medium" variant="outlined"/>
                                <FutureBuilder futureFunc={()=>retrieveServicePackage(request.packageId)} builder = {
                                    (servicePackage)=>{
                                        return <Chip color="primary" style={{margin:"5px 5px", textTransform:'capitalize'}} label={`${servicePackage.name} Package`} size="medium" variant="outlined"/>  
                                        }
                                    } />
                                <Chip style={{margin:"5px 5px", textTransform:'capitalize'}} label={request.serviceType.name} size="medium" variant="outlined"/>
                                <Button style={{margin:"5px 5px",}} color={'primary'} disableElevation variant="contained" icon={<OpenInBrowser/>} onClick={
                                    ()=>{
                                        console.log(profile.uid)
                                        history(`ServiceBooking?id=${request.id}`)
                                    }
                                }>
                                    <Typography variant="caption">Open Tracker</Typography>
                                </Button>
                            </Row>
                    </AccordionDetails>:<></>}
                </Accordion>})}
            </CardContent>
        </Card>
    )
};
export default ServiceTracker;

ServiceTracker.propTypes = {
    profile: PropTypes.object
}