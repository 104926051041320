/* eslint-disable react-hooks/exhaustive-deps */

import { useProfile } from "../hooks/useProfile";
import { Button, IconButton, CircularProgress, Typography, Card, TextField, Grid, Accordion, AccordionSummary, Chip, AccordionDetails } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Stack from '../components/Stack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ArrowBackIos, ArrowDropDownCircleOutlined, CheckOutlined } from '@material-ui/icons';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { useSiteData } from '../config/SiteData';
import "../index.css";
import { useNavigate } from 'react-router';
import { AppBarModule } from '../components/_app_bar_mod';
import Column from "../components/Column";
import { camelCaseToSentenceCase, parseUrlForId, useStyles } from "../utils";
import firebase_app from "../config/firebase";
import { CloseOutlined, } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { UploadProfilePicture } from "../components/UploadDiv";
import useServiceBooking from "../contexts/ServiceBookingProvider";
import ServiceAgentApplication from "../components/profile/agent/service_agent_application";
import Row from "../components/Row";
import AgentCard from "../components/profile/agent/_agent_card";
import { motion, AnimatePresence } from "framer-motion";
const SettingPage = ()=>{
const [profile, setProfile] = useState({});
const history = useNavigate();
const [newProfile, setNewProfile] = useState({});
const [loading, setLoading] = useState(true);
const [serviceTypes, setserviceTypes] = useState([]);
const classes = useStyles();
const {SiteData} = useSiteData();
const {retrieveProfile} = useProfile();
const { getServiceTypes } = useServiceBooking();
const {enqueueSnackbar} = useSnackbar();
const [openApplication, setOpenApplication] = useState(false);
const uid = parseUrlForId(window.location.href,2);

    useEffect(()=>{
        retrieveProfile(uid)
        .then((res)=>{
            console.log(res)
            setProfile(res);
            setNewProfile(res);
            getServiceTypes().then((res)=>{
                setserviceTypes(Object.values(res).map((item)=>item.name));
                console.log(Object.values(res).map((item)=>item.name));
                setLoading(false);
            })

        });
    },[])
    const BuildAccountDetails = ()=>{
        return  <Card variant="outlined" style={{marginTop:"20px", padding:"20px", width:'100%',}}>
        <Typography variant="h6" style={{width:'100%', color:"white", textAlign:"left", marginTop:"20px", fontWeight: "bold"}}>Account Details</Typography>
        <div style={{height: 20}}/>
        <Grid container spacing={6}>
            <Grid container justifyContent="flex-start" item xs={12} sm={12}>
                <UploadProfilePicture image={profile.picture}/>
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField InputLabelProps={{ shrink: true }} label={"First name"}  placeholder="First Name" variant="outlined" value={newProfile.fname} fullWidth onChange={
                    (e)=>{
                        setNewProfile({...newProfile, fname: e.target.value})
                    }
                } />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField InputLabelProps={{ shrink: true }}  label="Last Name" variant="outlined" value={newProfile.lname} fullWidth onChange={
                    (e)=>{
                        setNewProfile({...newProfile, lname: e.target.value})
                    }
                }/>
            </Grid>
            <Grid item xs={12} sm={6}>
               <Column>
                <TextField InputLabelProps={{ shrink: true }}  label="Email" variant="outlined" value={newProfile.email} fullWidth onChange={
                        (e)=>{
                            setNewProfile({...newProfile, email: e.target.value})
                        }
                    }/>
                    <div style={{height: 5}}/>
                    <Button onClick={
                        ()=>{
                            firebase_app.auth().currentUser.sendEmailVerification().then(()=>{
                                enqueueSnackbar('Verification email sent', {variant:'success'});
                            })
                        }
                    } style={{alignItems:'left',textAlign:'left'}} disabled={
                        firebase_app.auth().currentUser.emailVerified
                    } startIcon={
                        firebase_app.auth().currentUser.emailVerified ? <CheckOutlined style={{color:'green'}}/> : <CloseOutlined style={{color:'red'}}/>
                    } size="small">
                        <Typography variant="caption" style={{textTransform:'initial',width:'100%', textAlign:'left'}}>{`${firebase_app.auth().currentUser.emailVerified ? 'Email Verified': 'Please verify your email (click to re-send)'}`}</Typography>
                    </Button>
               </Column>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField InputLabelProps={{ shrink: true }}  label="Phone Number(+353xxxxxxxxx)" variant="outlined" value={newProfile.phone} fullWidth onChange={
                    (e)=>{
                        setNewProfile({...newProfile, phone: e.target.value})
                    }
                }/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField InputLabelProps={{ shrink: true }} helperText={""} label="Main Address" variant="outlined" value={newProfile.address?.address1} fullWidth onChange={
                    (e)=>{
                        setNewProfile({...newProfile, address: {...newProfile.address, address1: e.target.value}})
                    }
                }/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField InputLabelProps={{ shrink: true }}  label="Postal Code" variant="outlined" value={newProfile?.address?.postal_code ?? ""} fullWidth onChange={
                    (e)=>{
                        setNewProfile({...newProfile, address: {...newProfile.address, postal_code: e.target.value}})
                    }
                }/>
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField InputLabelProps={{ shrink: true }}  label="Country" variant="outlined" value={newProfile?.address?.country ?? ""} fullWidth onChange={
                    (e)=>{
                        setNewProfile({...newProfile, address: {...newProfile.address, country: e.target.value}})
                    }
                }/>
            </Grid>
        </Grid>
    </Card>
    }
    const BuildAdditionalAccounts = ()=>{
        return <Card variant="outlined" style={{marginTop:"20px", padding:"20px", width:'100%'}}>
        <Typography variant="h6" style={{width:'100%', color:"white", textAlign:"left", marginTop:"20px", fontWeight: "bold"}}>Additional Accounts</Typography>
        <div style={{height: 20}}/>
        <Grid container spacing={6}>
                <Grid item xs={12}>
                <Column>
                        {
                            Object.keys(profile.accounts??{}).map((key, index)=>{
                                return <Accordion key={index} variant="outlined">
                                    <AccordionSummary expandIcon={<ArrowDropDownCircleOutlined/>}>
                                        <Row alignment="space-between" style={{width:"100%"}}>
                                            <Typography variant="body1" style={{textTransform:'initial',width:'100%', textAlign:'left'}}>{camelCaseToSentenceCase(key)}</Typography>
                                            <Chip label={`${profile.accounts[key].length} Account(s)`} color="primary"/>
                                        </Row>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <Grid container spacing={3}>
                                            {
                                                profile.accounts[key].map((item, index)=>{
                                                    return <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={3}>
                                                        <motion.div onClick={
                                                            ()=>{
                                                                history(`agent?id=${item}&type=${key}`)
                                                            }
                                                        } style={{height:300}} whileHover={{scale:1.03,cursor:'pointer'}} whileTap={{scale:0.9,cursor:'pointer'}}>
                                                            <AgentCard style={{width:"100%", height:"100%"}} agentId={item} />
                                                        </motion.div>
                                                    </Grid>
                                                })
                                            }
                                        </Grid>
                                    </AccordionDetails>
                                    <AccordionDetails>
                                        <Column>
                                        <Typography variant="body1" style={{textTransform:'initial',width:'100%',fontWeight:"bold", textAlign:'left'}}>Create New Account</Typography>
                                            <Typography color="textSecondary" variant="caption" style={{textTransform:'initial',width:'100%', textAlign:'left'}}>
                                                {`A Service Agent is able to provide a service to a customer. A Service agent can be a ${serviceTypes?.join(', ')} 
                                                and more. As a Service Agent you able to manage the bookings and recieve payment all in one locations.`}
                                            </Typography>
                                            <div style={{height: 10}}/>
                                            <Button variant="outlined" color="primary" onClick={
                                                ()=>{
                                                    setOpenApplication(true);
                                                }} style={{alignItems:'left',textAlign:'left', width:90}}>
                                                <Typography variant="caption" style={{textTransform:'initial',width:'100%', textAlign:'left'}}>Apply Now</Typography>
                                                </Button>
                                        </Column>
                                    </AccordionDetails>
                                </Accordion>
                            })
                        }
                      {profile.role !== 'serviceAgent' ? <>
                      <Typography variant="body1" style={{textTransform:'initial',width:'100%',fontWeight:"bold", textAlign:'left'}}>Become A Service Agent</Typography>
                        <Typography color="textSecondary" variant="caption" style={{textTransform:'initial',width:'100%', textAlign:'left'}}>
                            {`A Service Agent is able to provide a service to a customer. A Service agent can be a ${serviceTypes?.join(', ')} 
                            and more. As a Service Agent you able to manage the bookings and recieve payment all in one locations.`}
                        </Typography>
                        <div style={{height: 10}}/>
                        <Button variant="outlined" color="primary" onClick={
                            ()=>{
                                setOpenApplication(true);
                            }} style={{alignItems:'left',textAlign:'left', width:90}}>
                            <Typography variant="caption" style={{textTransform:'initial',width:'100%', textAlign:'left'}}>Apply Now</Typography>
                            </Button>
                      </> : <></>}
                </Column>
            
            </Grid>
        </Grid>
        </Card>
    }
    const _buildPage = ()=>{
        return   loading ? <Column style={{marginTop: 60, alignItems:'center', height:'100%', width:'100%' }}>
            <CircularProgress />
        </Column> : profile && <AnimatePresence>
            <motion.div initial={{opacity:0, x:20}} animate={{opacity:1,x:0}} exit={{opacity:0,x:-20}} transition={{duration:0.5}}>
            <Column alignment="start" style={{padding: '60px 30px', width:'100%', margin:'0 auto', maxWidth:1100}}>
                <Row style={{width:"100%", alignItems: 'center'}}><IconButton onClick={
                    ()=>{
                        history(-1);
                    }
                }>
                    <ArrowBackIos/>
                    </IconButton><Typography variant="h4" style={{width:'100%', color:"white", textAlign:"left", fontWeight: "bold"}}>Settings</Typography></Row>
            <BuildAccountDetails/>
            <BuildAdditionalAccounts/>
                </Column>
            </motion.div>
        </AnimatePresence>
    }
    return (
        <div className={classes.root}>
         <CssBaseline />
        <Stack>
            <div style={{height:"100%", background:"black",width:"100%"}}>
               {SiteData.aboutPage.isVideo ? <video playsInline src={SiteData.aboutPage.featuredCover} 
                autoPlay={true}
                loop={true}
                muted={true}
                    style={{height:"100%", width:"100%", objectFit: "cover"}} 
                    className="react-player" ></video>:<img alt="" src={SiteData.aboutPage.featuredCover} width="100%" height="100%" style={{objectFit:"cover"}}/>}
            </div>
            <div style={{background:"#0000000d", height:"100%", width:"100%"}}></div>
            <div style={{background:"linear-gradient(0deg, black 20%,#0000009c 54%, transparent)",height:"100%", width:"100%"}}></div>
           {_buildPage()}
        </Stack>
        {<AppBarModule showCart pageIndex={1}/>}
        <ServiceAgentApplication onClose={
            ()=>{
                setOpenApplication(false);
            }
        } profile={profile} open={openApplication} />
        </div>
    )
}
export default SettingPage;