/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import ServicePakageCard from "../../components/_service_package_card";
import { useDatabase } from "../../hooks/useDatabase";
import { Avatar, Button, Checkbox, Chip,alpha, CircularProgress, DialogContent, FormControlLabel, TextField, Typography, useTheme } from "@material-ui/core";

import { motion } from 'framer-motion';
import Row from "../../components/Row";
import Stack from "../../components/Stack";
import { AppBarModule } from "../../components/_app_bar_mod";
import { parseUrlForQueryParams } from "../../utils";
import useServiceBooking from "../../contexts/ServiceBookingProvider";
import shortUUID from "short-uuid";
import { emailValidation } from "../../logic/validation";
import { useSiteData } from "../../config/SiteData";
import { useSnackbar } from "notistack";
import { useEmail } from "../../hooks/emails";
import { sendFeedback } from "../../config/emailer";
import Column from "../../components/Column";
import { Skeleton } from "@mui/material";
import SlidingCarousel from "../../components/_sliding_carousel";
import { useNavigate } from "react-router";
import ServiceAgreementPage from "../serviceAgreementPage";
import SubmissionModal from "../../components/_build_submission_modal";
import MarkdownTextarea, { getHTMLFromMarkdown } from "../../components/_markdown_textarea";
import { NotificationTypes, sendNotification } from "../../hooks/notificationHandler";
import { useProfile } from "../../hooks/useProfile";
import PropTypes from 'prop-types';

const BookAgentRoom = () => {
    const [servicePackage, setServicePackage] = useState(null);
    
  const [profile, ] = useState({});
  const { retrieveProfileWithCallback } = useProfile();
    const {SiteData} = useSiteData();

    const {enqueueSnackbar} = useSnackbar();
    const [agent, setAgent] = useState(null);
    const {getAgent, addRequest}  = useServiceBooking();
    const { retrieveServicePackage, loading } = useDatabase();
    const [currentPage, setCurrentPage] = useState(0);
    const [pages, setPages] = useState([]);
    const [termsAgreed, setTermsAgreed] = useState(false);
    let description = {text: "", style:{}};
    const { 
        serviceBookingEmail
    } = useEmail();
    const params = parseUrlForQueryParams(window.location.href);
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const modalRef = useRef(null);
    const history = useNavigate();
    const theme = useTheme();

  const _sendEmailToCustomer = async (request) => {
    sendFeedback(
        { 
          to:`${emailRef.current.value}`,
          from:SiteData.email,
          subject:`DTV Service Booking for ${agent.serviceType.name}, ${agent.name}`,
            body: serviceBookingEmail({
                name: `${firstNameRef.current.value} ${lastNameRef.current.value}`,
                companyName: SiteData.companyName,
                serviceType: agent.serviceType.name,
                agentName: agent.name,
                agentEmail: agent.email,
                description: getHTMLFromMarkdown(description.text)

            })
        },
        ()=>{
            console.log("email sent");
            sendNotification({
                id: shortUUID.generate(),
                sendTo: profile.uid,
                sentFrom: 'dtv-admin',
                type: NotificationTypes.SERVICE,
                title: `Request to ${agent.name}`,
                message: "Your request has been sent",
                timestamp: new Date().getTime(),
                seen: false,
                url: `/${profile.uid}/ServiceProject?id=${request.id}`
            })
            enqueueSnackbar("Request Sent", {variant:"success"});
        },
        () => {
            enqueueSnackbar("something went wrong, Try again later", {variant:"error"});
        }
      )
}
const _sendEmailToAgent = async (request) => {
    sendFeedback(
        { 
          to:`${agent.email}`,
          from:SiteData.email,
          subject:`DTV Service Booking for ${agent.serviceType.name}, ${agent.name}`,
            body: serviceBookingEmail({
                customerEmail:emailRef.current?.value??"",
                name: `${firstNameRef.current.value} ${lastNameRef.current.value}`,
                companyName: SiteData.companyName,
                serviceType: agent.serviceType.name,
                agentName: agent.name,
                agentEmail: agent.email,
                description: getHTMLFromMarkdown(description.text)

            }, true)
        },
        ()=>{
            console.log("email sent");
            sendNotification({
                id: shortUUID.generate(),
                sendTo: agent.id,
                sentFrom: 'dtv-admin',
                type: NotificationTypes.AGENT_SERVICE,
                title: `New Request for ${agent.name}`,
                message: "A brand new request has been sent to you",
                timestamp: new Date().getTime(),
                seen: false,
                url: `/${agent.id}/ServiceProject?id=${request.id}`
            })
        },
        () => {
           
        }
      )
}

  const handleSendRequest = async() => {
    const request = {
        id: shortUUID.generate(),
        agentId: agent.id,
        termsAgreed: false,
        serviceType: agent.serviceType,
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        email: emailRef.current.value,
        description: description,
        uid: profile?.uid,
        statusDates:{
            pending: Date.now()
        },
        status: "pending",
        packageId: params.package,
        timestamp: Date.now(),
        completed: false
    }
    console.log("SENDING REQUEST", request)
    try {
        let mail = emailRef.current?.value ?? "";
        if (emailValidation(mail)) {
            if(description.text?.length > 0){
                if(termsAgreed){
                      await addRequest(request);
                    _sendEmailToCustomer(request);
                    _sendEmailToAgent(request);
                     modalRef.current.open(true)
                   
                }else{
                    enqueueSnackbar("You must agree to the terms and conditions", {variant:"error"});
                }
              
            }else{
                enqueueSnackbar("Invalid Description", {variant:"error"});
            }
            
        } else {
            enqueueSnackbar("Invalid Email", {variant:"error"});
        }
    } catch (err) {
        console.log(err);
    }
 }

    useEffect(() => {
        retrieveProfileWithCallback(()=>{
        if(params.package)
       {
        retrieveServicePackage(params.package).then((res)=>{
            setServicePackage(res);
        });
        getAgent(params.agent).then((agentData)=>{
            setAgent(agentData);
        })}
    })
    
    }, [profile?.uid])
    const BuildPolicy = ({agreementId}) => {
        return <Column style={{height:"100%", width:"100%"}}>
            <Typography variant="overline">Service Agreenment</Typography>
            <ServiceAgreementPage policyTextStyle={
                {
                    fontSize: 12,
                }
            } viewOnly={true} agreementId={agreementId}/>
        <FormControlLabel control={<Checkbox checked={termsAgreed} onChange={()=>{
            console.log(termsAgreed);
            setTermsAgreed(!termsAgreed);
            if(termsAgreed === false)
            setCurrentPage(servicePackage?.agreementsAttached?.length??0);
        }} />} label="I agree to the terms and conditions of this agreement" />
        </Column>
    }
    BuildPolicy.propTypes = {
        agreementId: PropTypes.string.isRequired
    }
    const BuildBookNow = () => {
        return agent? <>
           <Row>
            <Chip variant="outlined" style={{margin:"0 5px"}} avatar={ <Avatar src={agent.picture} />} label={agent.name}/>
            <Chip variant="outlined" style={{margin:"0 5px"}} label={agent.serviceType.name}/>
           </Row>
           <div style={{ height: 20 }} />
             <Typography variant="overline">Service Request</Typography>
            <DialogContent>
                <Typography variant="overline">Your Details</Typography>
                <div style={{ height: 20 }} />
                <Row>
                    <TextField variant="outlined" inputRef={firstNameRef} defaultValue={profile?.fname??""} label="First Name" />
                    <div style={{width:10}} />
                    <TextField variant="outlined" inputRef={lastNameRef} defaultValue={profile?.lname??""}  label="Last Name" />
                    
                    
                </Row>
                <div style={{ height: 40 }} />
                <TextField variant="outlined" fullWidth inputRef={emailRef} defaultValue={profile?.email??""}  label="Email" />
                <div style={{ height: 40 }} />
                <Typography variant="overline"> Full Details of Request</Typography>
                <div style={{ height: 40 }} />
                <MarkdownTextarea markdownValue={description} 
                onChange={(e)=>{
                    description = e;
                }}
                    variant="outlined" editmode label="Description"/>
                <div style={{ height: 40 }} />
                <Button variant="contained" color="primary" onClick={ handleSendRequest }>Submit</Button>
            </DialogContent>
        </>:<Skeleton variant="rectangular" width="100%" height={400} />
    }
    
      useEffect(()=>{
        const slides = servicePackage?.agreementsAttached  ? [
        [...servicePackage.agreementsAttached.map((id,index)=><BuildPolicy agreementId = {id} key={index}/>)],<BuildBookNow key={`randBookNow`}/>]:[
            <BuildBookNow key={`randBookNow2`}/>
        ];
        setPages(slides);
      },[JSON.stringify(agent), termsAgreed])
      
const BuildSteps = ()=>{
  return (
   <div style={{height: "70vh", width:"60%"}}>
    <SlidingCarousel disableNext={!termsAgreed} currentPage={currentPage} pages={pages}/>
   </div>
  );
};

    const BuildPage = () => {
        return <Row style={{height:"100%", width: "100%", alignItems:"center"}} alignment = "centered">
           
             {loading ? <CircularProgress/> :  <motion.div
        className="rotating-card"
        animate={{ rotateY: [0, 30, -30, 0] }} // Continuously rotate by 360 degrees
        transition={{ duration: 10, repeat: Infinity, ease: 'linear' }} // Infinite rotation with a 5-second duration
      >  
      <div 
          style={{
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            borderRadius: '8px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#f0f0f0',
            position: 'relative',
            backdropFilter: "blur(20px)",
        '-webkit-box-reflect': 'below 20px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.13))'
          }}
        >
      <ServicePakageCard disable={true} servicePackageId={params.package}/>
      <motion.div
            style={{
              content: '""',
              position: 'absolute',
              top: '-30%',
              left: '-30%',
              width: '60%',
              height: '60%',
              background: 'linear-gradient(45deg, rgba(255, 255, 255, 0.8) 50%, transparent 100%)',
              transform: 'rotate(45deg)',
              opacity: 0.6,
              pointerEvents: 'none',
            }}
            initial={{ rotate: -45, opacity: 0 }}
            animate={{ rotate: [45, -45, 45], opacity: [0.6, 0.2, 0.6], transition: { duration: 2, repeat: Infinity } }}
          />
          </div>
      </motion.div>
      }
      <div style={{width:100}}/>
      <BuildSteps/>
        </Row>
    }
    return <>
      <Stack>
            <div style={{ background: "#0000000d", height: "100%", width: "100%", maxWidth: 1140, margin:"0 auto"
         }}></div>
            <div style={{ background: "linear-gradient(0deg, black 20%,#000000ff 54%, transparent)", height: "100%", width: "100%" }}></div>
                {BuildPage()} 
              
                
            </Stack>
            <AppBarModule pageIndex={ 7 } />
            <SubmissionModal ref={modalRef} successText={
                <Column>
                 <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">Your Submission has been sent</Typography>
                <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">{"We'll back back to you within"} <span style={{ color: alpha(theme.palette.text.primary, 1) }}>3-5 workin days</span></Typography>
                <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">for any enquiry visit out <a href={"/contact"} style={{ textTransform: "underline", color: alpha(theme.palette.text.primary, 1) }}>contact page</a></Typography>
                
                </Column>
            } onCompleted={
                ()=>{
                    modalRef.current.open(false);
                    history(-1);
                }
            } open={false}/>
    </>
}
export default BookAgentRoom;