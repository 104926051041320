/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";


const FutureBuilder = (props=>{
    
    const [placeholder, ] = useState(props.placeholder);
     const [errorChild, ] = useState(props.errorChild);
    const [, setLoaded] = useState(false);
    const [, setError] = useState(false);
    const [build, setBuild] = useState(<></>);
   
    useEffect(()=>{
        startBuild();
    },props.rebuild ? [props.future] : props.dependencies ?? [])
    const startBuild = async()=>{
        setLoaded(false)
        setBuild(placeholder)
        try{
            let data = await (props.futureFunc ? props.futureFunc() : props.future)
            //console.log("RETRIEVED--->>")
            //console.log(data);
            let elem = props.builder(data)
            //console.log(elem);
            setBuild(elem);
        }catch(e){
            console.error(e);
            console.log(e);
            setError(true)
            setLoaded(true);
            if(props.onError)
            props.onError(e);
            setBuild(errorChild);
        }
       
    }
    return <div style={props.style} className={props.className}>{build}</div>;
})
export default FutureBuilder;

FutureBuilder.propTypes = {
    future: PropTypes.func,
    futureFunc: PropTypes.func,
    builder: PropTypes.func,
    placeholder: PropTypes.element,
    errorChild: PropTypes.element,
    onError: PropTypes.func,
    rebuild: PropTypes.bool,
    dependencies: PropTypes.array,
    className: PropTypes.string,
    style: PropTypes.object
}