/* eslint-disable react-hooks/exhaustive-deps */

import { Grid, TextField, Typography, useTheme } from '@material-ui/core';
import { motion } from 'framer-motion';
import { useSnackbar } from 'notistack';
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from 'react';
import { firestore } from '../config/firebase';
import { useMediaQuery } from '../contexts/MediaQueryProvider';
import { DefaultStyle } from '../contexts/styles';
import { addStudioBookingsToCart } from '../logic/databaseHandler';
import Column from './Column';
import Row from './Row';
import BookingHoursBubble from './_booking_hours_bubble';
import ExtendedButton from './_extended_button';
import StudioSeatItem from './_studio_seat';
import shortid from 'shortid';

function BookRoomCard(props){

    /**
     * Cart - {id} - booking:Array(1...)
     * 2 cases to consider when it comes to the sadd to cart functionality
     * 1. The user can only book for 1 session per day
     * 2. The user can book multiple sessions per day
     * 
     * in case 1. we retrieve the booking for the current day in the user cart,
     * if non exists initialize with default data, otherwise we retrieve the booking for the current day selected
     * 
     * in case 2. We retrieve the bookings for the current day
     */
    const dateRef = useRef(null);
    const theme = useTheme()
    const classes = DefaultStyle();

  
    const { device,  } = useMediaQuery();
    const [date, setDate] = useState(new Date())
    const [hours,updateHours] = useState([])
    const [seats,updateSeats] = useState([])
    const [bookedHours, updateBookedHours] = useState([])
    const [availableHours, updateAvailableHours] = useState([])
    const [bookingCart, updateBookingCart] = useState([])     
    const {enqueueSnackbar} = useSnackbar()                   
    const [booking, updateBooking] = useState({
      id: "",
      room: "",
      code: "",
      date: "",
      startTime: 0,
      endTime: 0,
      info: "",
      guest: 1,
      location: "",
      price: 0,
      studio_type: ""
    })

    const retrieveBookingCart = async (dt)=>{
      console.log("???",props.profile)
        var tempId = props.profile?.uid //firebase_app.currentUser.uid
        firestore.collection("Users").doc(tempId??"none").collection("Cart").doc("SB-"+dt.valueOf()).onSnapshot((qs)=>{
            let tempBC = [];
            if(qs.data())
            tempBC = qs.data().bookings;//list

            updateBookingCart(tempBC)
        })
       
    }
    const setUpSeats = ()=>{
        let temp = [];
        for(var i = 0; i<props.room.capacity;i++){
          temp.push(i+1);
        }
        updateSeats(temp)
      }
      const setupHours  = (date)=>{
       const today = new Date(date.getFullYear(),date.getMonth(),date.getDate())
        retrieveBookingCart(today).then(()=>{
 
             
        })
     
        
         booking.id = "SB-"+today.valueOf();
        let hrs=[];
        for(let i = 0; i<24; i++){
          let tempD = new Date(date.getFullYear(),date.getMonth(),date.getDate()).addHours(i)
      //  console.log(tempD);
          hrs.push(tempD)
         
        }
        // console.log(today);
        //  console.log('====================================');
        // console.log(hrs);
        // console.log('====================================');
        updateHours(hrs);
        
      }
      const msToTime = (duration) => {
        var hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
    
        return hours 
    }
      const getHours = (date1, date2) => {
    
        return msToTime(date2 - date1);
    }

    const calculatePrice = ()=>{
        let val = 0;
        val+=(props.room.priceMap?.perHour??0)*Math.abs(getHours(new Date(booking.startTime),new Date(booking.endTime)))
        console.log(getHours(new Date(booking.endTime),new Date(booking.startTime)))
        val+=(props.room.priceMap?.perSeat??0)*booking.guest

        booking.price = val;
        return booking.price
      }


    useEffect(() => {

    updateBookedHours(props.room.bookedHours)
    let sortedHours = props.room?.availableHours??[];
    sortedHours.sort((a,b)=>a<b);
    updateAvailableHours(sortedHours.filter((v)=> v > new Date().getHours()));
      setupHours(date);
      setUpSeats();
      dateRef.current.value=`${date.getFullYear()}-${date.getMonth()<10 ? '0'+date.getMonth()+1:date.getMonth()+1}-${date.getDate()}`
  
    }, [])
  const checkIsInCart = (h)=>{
      var res = false;
      bookingCart.forEach((b)=>{
          //console.log(b.startTime,h.valueOf(),b.startTime <= h.valueOf())
          if(b.startTime <= h.valueOf() && b.endTime>= h.valueOf()){
              console.log(true)
              res = true ;
              return;
          }
      })
      return res;
  }
  const generateRoomCode = ()=>{
    return shortid.generate()
  }
  const addBookingToCart = async()=>{
      // If case 1. we erase booking cart and only store a single booking
      // if case 2. we store all booking cart content as is
      var tempId = props?.profile?.uid
      let tempD = new Date(date.getFullYear(),date.getMonth(),date.getDate());
      booking.id = "SB-"+tempD.valueOf();
      booking.bid=`${booking.startTime}-${booking.endTime}`;
      booking.room = props.room.roomId;
      booking.code = generateRoomCode();
      booking.info = tempId;
      booking.studio_type = props.room.type;
      booking.date = Date.now();
      
      console.log(booking)
      bookingCart.push(booking)
    

      addStudioBookingsToCart(bookingCart,tempId,booking.id).then(()=>{
        enqueueSnackbar("Booking Added to Cart",{ variant: 'success', anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        },})
      })
      updateBooking({
        id: "",
        room: "",
        code: "",
        date: Date.now(),
        startTime: 0,
        endTime: 0,
        info: "",
        guest: 1,
        location: "",
        price: 0,
        studio_type: ""
      })

  }
    const _buildCard = () => {
   
        return <Column alignment="space-between"style={{ width: "100%",height:"100%", color: "black", overflow:"hidden"}}>
        <Column style={{padding: "30px", height:"85%"}}>
            <Typography align="left" variant="caption" style={{ color: "black", textAlign: "center", fontWeight: "bold", fontSize: "20px" }}>{`Book ${props.room.name}`}</Typography>
            <div style={{height:"20px"}}/>
            <Row alignment="space-between">
              <Column alignment="centered" style={{ }}>
                <Typography style={{ fontWeight: "bold", fontSize: "14px", textAlign: "left", }}>Select Date » </Typography>
              </Column>
              <Column alignment="centered" style={{width:"50%" }}>
                <TextField
                  id="datetime-local"
                  
                  type="date"
                  inputRef = {dateRef}
                  inputProps={
                    {
                      defaultValue:`${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`,
                      min:new Date().toISOString().split("T")[0],
                      style:{
                        color:"black"
                      }
                    }
                  }
                  
                  className={classes.textField}
                  onChange={(e) => {
                   
                    console.log( props.room?.availableHours)
                    let dte = new Date(e.target.value)
                    setDate(dte)
                    booking.startTime = 0;
                    booking.endTime = 0;
                    setupHours(dte);
                    let sortedHours = props.room?.availableHours??[];

                    const d = new Date();
                    const today = new Date(d.getFullYear(),d.getMonth(),d.getDate());
                    const cd = new Date(dte.getFullYear(),dte.getMonth(),dte.getDate());
                    
                    const hrs = cd.valueOf() === today.valueOf() ? new Date().getHours() : 0;

                    sortedHours.sort((a,b)=>a<b);
                    updateAvailableHours(sortedHours.filter((v)=> v > hrs))
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Column>
              
            </Row>
            <div style={{height:"30px"}}/>
            <Typography style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center",}}>{`Selected Hours: ${booking.startTime>0 ? `${new Intl.DateTimeFormat("en-US", { hour: "numeric",minute:"numeric", hour12:false}).format(new Date(booking.startTime))} - ${new Intl.DateTimeFormat("en-US", { hour: "numeric",minute:"numeric", hour12:false}).format(new Date(booking.endTime))}`: "No hours selected"}`}</Typography>
            <div style={{height:"30px"}}/>
            <Row alignment="centered" style={{width:"100%"}}>
              <Grid alignContent='center' 
  direction="row"
  justify="center" container  columns = {24} style={{justifyContent:"center"}}>
              {
                hours.map((h, index)=>{
                // console.log(h.valueOf(),bookedHours.includes(h.valueOf()))
                  //console.log(h,new Date(booking.startTime),h.valueOf() >= booking.startTime,h.valueOf() <= booking.endTime,(booking.startTime != 0 ) && (h.valueOf() >= booking.startTime && h.valueOf() <= booking.endTime));
                  return <div key={h.valueOf()+index} style={{marginRight:"15px",marginLeft:"0px",marginBottom:"10px"}}>
                    <BookingHoursBubble inCart={checkIsInCart(h)} isOpen = {availableHours.includes(h.getHours())} available={!bookedHours.includes(h.valueOf())} isBooked={(booking.startTime !== 0 ) && (h.valueOf() >= booking.startTime && h.valueOf() <= booking.endTime)} value={h} onClick = {
                    (open,val)=>{
                      console.log('====================================');
                      console.log(val.getHours());
                      console.log('====================================');
                      let tempD = new Date(date.getFullYear(),date.getMonth(),date.getDate()).addHours(val.getHours())
                      console.log("AM I BOOKED:" + open,booking.startTime)
                      // 0 1 2 3 4 -> 0 | 0 | 1
                      /**
                        * HOW THIS WORKS
                        * Gived the array [ 0 1 2 3 4]
                        * and two intergers to represent the start, s and  the end, e
                        * we want to select all nodes from s -> e
                        * e >= s >= 0
                        * when s == 0 the node is un visited
                        * if s >= e: e has not been selected, which means e must be s
                        * other wise e is the current node;
                        * e.g: 
                        * d = [0 1 2 3 4]
                        * s = 0
                        * e = 0
                        * ----
                        * c = 1 //the current node
                        * since s == 0, we initialize s as the current node s, also e = s
                        * --
                        * c = 3
                        * since s != 0 that means we are looking for a closing node,
                        * s = 1
                        * e = c = 3
                        * highlight 1 --> 3
                        * 
                        */
                      if(booking.startTime === 0 ){
                          if(!open){
                            booking.startTime = tempD.valueOf()
                            let d = new Date(date.getFullYear(),date.getMonth(),date.getDate()).addHours(val.getHours()+1)
                            console.log(availableHours[availableHours.length-1],h.getHours(),availableHours[availableHours.length-1] > h.getHours())
                            if(availableHours[availableHours.length-1] > h.getHours()){
                              booking.endTime = booking.startTime
                            booking.endTime = d.valueOf()
                          }else{
                            booking.startTime = 0 
                          booking.endTime = booking.startTime
                          }
                        }
                      }
                      else if(open && booking.startTime === booking.endTime){
                        booking.startTime = 0 
                      }
                      else if(booking.startTime <= booking.endTime){
                        booking.endTime =tempD.valueOf();
                        if(booking.endTime <= booking.startTime){
                          // booking.startTime = tempD.valueOf()
                          // let d = new Date(date.getFullYear(),date.getMonth(),date.getDate()).addHours(val.getHours()+1)
                          // booking.endTime = d.valueOf()
                          booking.startTime = 0 
                          booking.endTime = booking.startTime
                        }
                      }
                      
                      else{
                        booking.startTime = tempD.valueOf()
                        // let d = new Date(date.getFullYear(),date.getMonth(),date.getDate()).addHours(val.getHours()+1)
                        //     booking.endTime = d.valueOf()
                        booking.endTime = booking.startTime
                        booking.startTime = 0 
                        
                      }
                    
                      updateBooking({...booking})
                      return (booking.startTime !== 0 ) && (h.valueOf() >= booking.startTime && h.valueOf() <= booking.endTime)
                    }
                  }/></div>
                })
              }
            </Grid>
            </Row>
            <div style={{height:"30px"}}/>
            <Typography style={{ fontWeight: "bold", fontSize: "14px", textAlign: "center",}}>{`Studio Seats: ${booking.guest} selected`}</Typography>
            <div style={{height:"30px"}}/>
            <Grid container  columns = {24} style={{justifyContent:"center"}}>
              {
                seats.map((g, index) => {
                  return <div key={index+g} style={{marginRight:"15px",marginLeft:"0px",marginBottom:"10px"}}>
                    <StudioSeatItem value={g} isOpen={g>0&&g<=booking.guest} onClick={
                      (e,v)=>{
                        booking.guest = v;
                        updateBooking({...booking})
                      }
                    }/>
                    </div>
                })
              }
              </Grid>
          </Column>
          <motion.div
          style={{
            height:"25%",
          }}
            initial={{
              y:-100,
              opacity:0
            }}
            animate = {
              {
                y:booking.startTime <= 0 ? 100 : 0,
                opacity:booking.startTime <= 0  ? 0.0 :1
              }
            }
            transition={{type: "spring", ease: "easeIn", duration: 0.8 }}
          >
     <ExtendedButton value="Add to Cart" onClick={()=>{
                      addBookingToCart()
                  }}/>
                   <div style={{height:5}}/>
          <Column style={{padding:"10px 20px",maxHeight:"250px", backgroundColor:`${theme.palette.primary.main}`,borderRadius:"8px",boxShadow:"0px -3px 4px rgba(0, 0, 0, 0.25)"}}>
              <Row alignment="space-between" style={{height:"100%"}}>
                <Column>
                  <Typography variant="caption"align="left" style={{fontWeight:"bold",fontSize:"15px"}}>{`${props.room.name} | ${booking.guest} studio seat(s)`}</Typography>
                  <div style={{height:1}}/>
                  <Typography variant="caption" align="left" style={{fontWeight:"bold",opacity:0.7,fontSize:"13px"}}>{`${booking.startTime <= 0 ? "-":new Intl.DateTimeFormat("en-US", {year:"numeric",month:"long",day:"numeric", hour: "numeric",minute:"numeric",hour12:false}).format(booking.startTime)} - ${booking.startTime <= 0 ? "":new Intl.DateTimeFormat("en-US", { hour: "numeric",minute:"numeric",hour12:false}).format(booking.endTime)}`}</Typography>
                  <div style={{height:5}}/>
                </Column>
                <Column alignment={device === "xs" ? "centered":"space-between"} style={{height:"100%"}}>
                  <Column alignment={"centered"} style={{borderRadius:"20px",padding:"2px 12px",height:"36px",backgroundColor:"rgb(30, 30, 30)",color:"white"}}>
                  <Typography align="center" style={{fontWeight:"bold",fontSize:"12px"}}>{`€${calculatePrice()}`}</Typography>
                  </Column>
                 
                </Column>
                
              </Row>
          </Column>
          </motion.div>
        </Column>
      }
    return (
        _buildCard()
    )
}
BookRoomCard.propTypes = {
    room: PropTypes.object,
  };
export default BookRoomCard;
