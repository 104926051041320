/* eslint-disable react-hooks/exhaustive-deps */

import { AppBar, Avatar, Button, Dialog, DialogTitle, Divider, Drawer, alpha, IconButton, List, ListItem, ListItemAvatar, ListItemIcon, ListItemSecondaryAction, ListItemText, makeStyles, Snackbar, Typography, useTheme, withStyles } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import { Book, BubbleChart, ChromeReaderMode, Close, ContactPhoneRounded, Event as EventIcon, Headset, Home as HomeIcon, Menu as MenuIcon, Tv, TvRounded, VerticalSplit, VideoLibraryRounded } from '@material-ui/icons';
import { motion } from 'framer-motion';
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router';
import uuid from 'short-uuid';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import logo from '../assets/logo.png';
import logoBanner from '../assets/STORY.png';
import Center from '../components/Center';
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { SocailsList } from '../components/_socail_mod';
import firebase_app from '../config/firebase';
import { useSiteData } from '../config/SiteData';
import { ThemeChangerContext } from '../contexts/ThemeChangerProvider';
import { UserContext } from '../contexts/UserProvider';
import "../index.css";
import Cart from './_cart';
import { useMediaQuery } from '../contexts/MediaQueryProvider';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    },
    
  },
  iconBtn: {
    color: alpha(theme.palette.text.primary, 0.5),
    '&:hover': {
      color: alpha(theme.palette.text.primary, 1)
    },
  },
  sideBarSocials: {
    color: alpha(theme.palette.text.primary, 0.6),
    fontSize: "12px",
    '&:hover': {
      color: alpha(theme.palette.text.primary, 1)
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: 5,
    color: alpha(theme.palette.text.primary, 0.5),
    '&:hover': {
      color: alpha(theme.palette.text.primary, 1)
    },
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: alpha(theme.palette.background.paper, 0.3),
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: alpha(theme.palette.text.primary, 0.2),
    },
    '&:focus': {
      color: alpha(theme.palette.text.primary, 1),
    }
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  logoutButton: {
    marginLeft: "10px",
    borderColor: 'white',
    '&:hover': {
      color: 'red',
      borderColor: 'red',
    },
    label: {
      color: 'red',
      '&:hover': {
        color: '#ffb03a',
      },
    }
  },
  fixedHeight: {
    height: 240,
  },
}));

const ColorButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(purple[500]),
    backgroundColor: '#de8500',
    '&label:hover': {
      backgroundColor: '#ffb03a',
    },
  },
}))(Button);
const BorderColorButton = withStyles((theme) => ({
  root: {
    color: alpha(theme.palette.text.primary, 1),
    borderColor: '#de8500',
    '&:hover': {
      color: '#ffb03a',
    },
  },
  label: {
    color: alpha(theme.palette.text.primary, 1),
    '&:hover': {
      color: '#ffb03a',
    },
  }
}))(Button);
///

export const sitemap = {
  0: "Home",
  1: "About",
  2: "Contact",
  3: "Show",
  4: "Playlists",
  5: "Releases",
  6: "Stories",
  7: "Bookings",
  8: "Events",
}

export function AppBarModule(props) {

  const {
    SiteData,
  } = useSiteData();
  const {
    updateTheme
  } = useContext(ThemeChangerContext);
  const {
    drawerWidth,
    isPC
  } = useMediaQuery();
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [openSidebar, showSidebar] = useState(false)
  const [notification, showNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const { currentUser, sessionId, profile, updateSessionId,  } = useContext(UserContext);
  const [open, showMenu] = React.useState(false);
  const handleClick = () => {
    showMenu(true)
  };

  const handleClose = () => {
    showMenu(false)
  };

  useEffect(() => {
    if (sessionStorage.getItem("sessionId")) {
      updateSessionId(localStorage.getItem("sessionId"))
    }
    else {
      updateSessionId(uuid.uuid())
      sessionStorage.setItem("sessionId", sessionId);
    }
  }, [])
 

  const _buildAppBar = () => {
    return <>
      <AppBar position="static" elevation={props.elevation ?? "0"} className={`${classes.appBar}`} style={{ ...props.style, background: props.appbarBackground ?? "transparent" }}>
        <Row alignment="space-between" style={{ width: "100%" }}>
          <Row alignment={isPC ? "left" : "space-between"} style={{ width: props.centerModule && !isPC ? "30%" : "100%" }} >
            <IconButton className={`grow ${classes.menuButton} ${props.menuButton}`} color="inherit" aria-label="menu"
              onClick={() => {
                showSidebar(true)
              }}
            >

              <MenuIcon style={{ fontSize: "30px" }} />

            </IconButton>
            <Column alignment="centered" style={{ width: isPC ? "100%" : "100%" }} >
              {props.centerModule || !isPC ? <></> : <Typography variant="overline" align={isPC ? "left" : "right"} style={{ color: alpha(theme.palette.text.primary, 0.5), padding: "0 15px", letterSpacing: "7px" }}>{props.pageTitle ?? SiteData.name.toUpperCase()}</Typography>
              }
            </Column>
          </Row>

          {props.centerModule ?? <></>}
          {isPC && !props.hideSocials ? props.customRightModule ?? <Center align="row">
            <Row alignment="right" style={{ width: "100%" }}>
              {isPC ? <SocailsList
                className={`${props.iconClass} ${classes.iconBtn}`}
                youtube={SiteData.socials.youtube}
                facebook={SiteData.socials.facebook}
                twitter={SiteData.socials.twitter}
                alignment="right"
                instagram={SiteData.socials.instagram} /> : <></>}
              {/* <Column alignment="centered">
                <Avatar src={logo} className={classes.logo} />
              </Column> */}
            </Row>


          </Center> : <></>}
          {props.showCart ? <Column alignment="centered" style={{padding:'0 13px'}}><Cart hidePrice={props.hideCartPrice} enableCheckoutPage={props.checkoutPage ?? true} enableHover={props.cartHover ?? false} enableClick={props.cartClick ?? true} /></Column> : <></>}
        </Row>

      </AppBar>
    </>
  }
  const _buildDrawerBody = () => {
    return <div className={classes.root} role="presentation" style={{ width: drawerWidth, height: "100%"}}
      onClick={() => { showSidebar(false) }}>
      <Stack  style = {{overflow: 'hidden'}} >
        <div style={{ background: "black", opacity: "0.7", width: "100%", height: "100%" }}></div>
        <div style={{ width: "100%", height: "100%" }}><img alt="" src={logoBanner} width="100%" height="100%" style={{ objectFit: "cover" }} /></div>
        <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 5%, ${alpha(theme.palette.background.paper, 0.8)} 60%, ${alpha(theme.palette.background.default, 1)})`, height: "100%", width: "100%" }}></div>
        <Column alignment="space-between" style={{ height: "100%", overflowX: "hidden" }}>
          <Column>
            <List >
              <ListItem>
                <ListItemAvatar>
                  {
                    profile ?
                      <motion.div whileHover={{ scale: 1.2 }} style={{ cursor: "pointer" }}>
                        <Avatar src={profile?.picture ?? logo} style={{ border: `${theme.palette.primary.main} solid 2px` }} onClick={() => { history({ pathname: `/${currentUser.uid}` }) }} />
                      </motion.div> : <Avatar src={profile?.picture ?? logo} style={{ border: `${theme.palette.primary.main} solid 2px` }} />}
                </ListItemAvatar>
                <ListItemSecondaryAction>
                  {
                    <Row>

                      {
                        (SiteData?.liveSections?.submissions || SiteData?.liveSections?.story_submissions)?
                        <ColorButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} style = {{marginRight: 10}}> Submit </ColorButton>:<></>
}
                      {currentUser ? <Button variant="outlined" className={classes.logoutButton} onClick={async () => {
                        await firebase_app.auth().signOut();
                        window.location.reload();
                        setNotificationMsg({ message: "You've been signed Out!", timeout: 2000 })
                        showNotification(true);

                      }} >Sign Out</Button>:
                      <BorderColorButton variant={"outlined"} className={"grow " + classes.bbtn} onClick={() => {
                        history("/login")
                      }}>Login</BorderColorButton>}
                      {/* <IconButton about="Logout" className={classes.iconBtn}><PowerSettingsNew/></IconButton> */}
                    </Row> 
                  }
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            <Divider />
            <List >
              {[{id:0, title: "Home", icon: <HomeIcon /> },
              {id:1, title: "About", icon: <BubbleChart /> },
              {id:2, title: "Contact", icon: <ContactPhoneRounded /> }].map((data, index) =>
                <ListItem className={`${classes.list} grow`} button selected={index === (props.pageIndex ?? 0)} key={`${data.title}_${index}`} onClick={() => { history(index === 0 ? '/' : "/"+data.title.toLowerCase()) }}>
                  <ListItemIcon color="primary" style={{ color: theme.palette.text.primary }}>{data.icon}</ListItemIcon>
                  <ListItemText primary={data.title} />
                </ListItem>
              )}
            </List>
            <div key={"wtf_key1"} style={{ padding: "10px", margin: "2px 0" }}>
              <div  key={"wtf_key2"} style={{ height: "1px", width: "100%", backgroundColor: "rgb(99 99 99 / 13%)" }}/>
            </div>
            <List style={{ width: drawerWidth }}>
              {[
                SiteData.liveSections?.shows ? { title: SiteData.showPage?.pageName ?? "Show", icon: <TvRounded />, id: 3 } : "",
                SiteData.liveSections?.playlist ? { title: SiteData.playlistPage?.pageName??"Playlists", icon: <Headset />, id: 4 } : "",
                SiteData.liveSections?.releases ? {title: SiteData.releasesPage?.pageName??"Releases", icon: <VideoLibraryRounded />, id: 5 } : "",
                SiteData.liveSections?.stories ? { title: SiteData.storyPage?.pageName ?? "Stories", icon: <ChromeReaderMode />, id: 6 } : "",
                SiteData.liveSections?.bookings ? {title: "Bookings", icon: <Book />, id: 7 } : "",
                SiteData.liveSections?.events ? { title: SiteData.eventPage?.pageName ?? "Events", icon: <EventIcon />, id: 8 } : "",
              ].map((data, index) =>
                data !== "" ? <ListItem className={`${classes.list} grow`} button key={`${data.id}_${index}`} onClick={() => { history("/"+sitemap[data?.id??0].toLowerCase()) }}>
                  <ListItemIcon className={"listIcon"} selected={index + 3 === (props.pageIndex ?? 0)} style={{ color: index + 3 === (props.pageIndex ?? 0) ? theme.palette.primary.main : theme.palette.text.secondary }}>{data.icon}</ListItemIcon>
                  <ListItemText primary={data.title} selected={index + 3 === (props.pageIndex ?? 0)} style={{ color: index + 3 === (props.pageIndex ?? 0) ? theme.palette.primary.main : theme.palette.text.secondary }} />
                </ListItem> : <></>
              )}
            </List>
          </Column>
          <Column>
            <Center align="column">
              {isPC ? <Row alignment="centered" style={{ margin: "0 10px" }}>
                <IconButton onClick={() => { updateTheme(theme.palette.type === "dark" ? "light" : "dark") }}>
                  <Avatar src={logo} className={classes.logo} />
                </IconButton>
              </Row> : <></>}
              {props.centerModule ? <></> : <Typography align={isPC ? "center" : "left"} variant="overline" style={{ color: alpha(theme.palette.text.primary, 0.5), padding: "0 15px", letterSpacing: "7px" }}>{SiteData.name.toUpperCase().replace(/\s/g, '')}</Typography>
              }

              {<SocailsList youtube={SiteData.socials.youtube}
                facebook={SiteData.socials.facebook}
                twitter={SiteData.socials.twitter}
                instagram={SiteData.socials.instagram}
                alignment={isPC ? "centered" : "left"}
                className={classes.sideBarSocials}
                fontSize={"20px"} />
              }
            </Center>

            <div style={{ padding: "10px", }}>
              <Typography variant="caption" align="center" style={{ color: alpha(theme.palette.text.primary, 0.5), fontSize: `${isPC ? '1.1vh' : '1.2vh'}` }}>
                {SiteData.copyright}
              </Typography>
            </div>
          </Column>



        </Column>
      </Stack>


    </div>
  }
  const _buildDrawer = () => {
    return <Drawer anchor="left" style = {{overflow: 'hidden'}} open={openSidebar} onClose={() => { showSidebar(false) }}>
      {_buildDrawerBody()}


    </Drawer>
  }
  return (
    <>
      <Column alignment="start" style={{ height: "30%", width: "100%" }}>
        {_buildAppBar()}
      </Column>
      {_buildDrawer()}
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
        <DialogTitle id="simple-dialog-title">Choose a Submission Type</DialogTitle>
        <List>

          {
            SiteData?.liveSections?.submissions ?
              <ListItem button onClick={() => {
                if(!currentUser){
                  history("/login", {state:{from: "VideoSubmission"}})
                }else
                 history("/" + currentUser.uid + "/Video")
                 }}>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>
                <Tv />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Submit Video"} secondary={"Upload a music video to our YouTube Channel"} />
          </ListItem>:<></>}
          {
            SiteData?.liveSections?.story_submissions ?
              <ListItem button onClick={() => {
                if(!currentUser){
                  history("/login", {state:{from: "StorySubmission"}})
                }else
                 history(`/${currentUser.uid}/Story?draft=true&id=${uuid().generate()}`) 
                 }}>
            <ListItemAvatar>
              <Avatar variant={"outline"} className={classes.avatar}>
                <VerticalSplit />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"Submit Story"} secondary={"Create a Blog Post"} />
          </ListItem>:<></>}
        </List>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={notificationMsg.timeout}
        onClose={() => {
          showNotification(false)
        }}
        message={notificationMsg.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
        <Outlet />
    </>
  )
}
AppBarModule.propTypes = {
  pageIndex: PropTypes.number,
  elevation: PropTypes.number,
  appbarBackground: PropTypes.string,
  centerModule: PropTypes.element,
  menuButton: PropTypes.string,
  customRightModule: PropTypes.element,
  iconClass: PropTypes.string,
  showCart: PropTypes.bool,
  style: PropTypes.object,
  pageTitle: PropTypes.string,
  cartHover: PropTypes.bool,
  cartClick: PropTypes.bool,
  checkoutPage: PropTypes.bool,
  hideSocials: PropTypes.bool,
  hideCartPrice: PropTypes.bool,
};

