export const useEmail = () => {
    const serviceBookingEmail = ({ name,customerEmail, companyName, serviceType,agentName,agentEmail,description },toAgent) => {
        let email = toAgent ? `
        Dear ${agentName},
        <br/><br/>You have a new booking request.<br/>
        <strong>Booking Details:</strong>
        <br/><br/></strong>Service Type: <strong>${serviceType}</strong>
        <br/><br/>Customer Name: <strong>${name}</strong>
        <br/><br/>Customer Email: <strong>${customerEmail}</strong>
        <br/><br/>Description: <br/>${description}
        <br/><br/>Regards,
        <br/><br/>${companyName}` :
            `Dear ${name},
        <br/><br/>Thank you for booking with us.<br/>
        <strong>Your Booking Details:</strong>
        <br/><br/>Service Type: <strong>${serviceType}</strong>
        <br/><br/>Agent Name: <strong>${agentName}</strong>
        <br/><br/>Agent Email: <strong>${agentEmail}</strong>
        <br/><br/>Description: <br/><br/>${description}
         We will be in touch shortly.
         <br/><br/>Regards,
         <br/><br/>${companyName}`;
        return email
    }
    return {
        serviceBookingEmail
    }
}