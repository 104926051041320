import { Button, Card, Chip, Dialog, Typography, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Row from './Row';

import { Stars } from '@material-ui/icons';
import '../bubble.css';
import Column from './Column';
import Stack from './Stack';
import { SocailsList } from './_socail_mod';
import {motion} from 'framer-motion';
import { Grid } from 'semantic-ui-react';
import FutureBuilder from './_future_builder';
import Logic from '../logic/logic';
import { toPascalCaseWithSpaces } from '../utils';
import PropTypes from 'prop-types'

function openInNewTab(url) {
    if (url !== "/") {
        var win = window.open(url, '_blank');
        win.focus();
    }
}



export const EventCard = (props) => {
    const [showModal, setShowModal] = useState(false);
    const [showTicketModal, setShowTicketModal] = useState(false);
    useEffect(() => {
        getViews();
    }, [])

    const getViews = async () => {
        // const request = new Request(`https://youtube.googleapis.com/youtube/v3/videos?part=id%2C%20%20statistics&id=${props.youtubeData.id}&key=${GoogleApi.key}`,{method:"GET"})
        // const response = await fetch(request)
        // try{
        //     const result=  await response.json();
        //     console.log(result);
        //     setLikes(result.items[0].statistics.viewCount)
        // }catch(e){
        //     console.log(e)

        // }
    }
    return (
        <div style={{padding: 20}}>
            <Card variant="outlined" style={{ width: props.style?.width ?? "100%", minHeight: props.style?.height ?? "500px" }}>
                <Row style={{ position: "relative", height: "100%", width: "100%" }}>
                    <div style={{ position: "relative", width: "40%" }}>
                        <Stack style={{ width: '100%' }}>
                            <div onClick={() => {
                                setShowModal(true);
                            }} style={{ height: "100%", width: "100%" }}><img alt="" width="100%" height="100%" style={{ objectFit: "cover" }} src={`${props.event.poster}`} /></div>
                        </Stack>
                    </div>
                    <Column alignment="space-between" style={{ padding: "20px", width: "90%" }}>
                        <Column>
                            <Row alignment="space-between" style={{ width: "100%" }}>
                                <Typography style={{ fontWeight: "bold", fontFamily: "pirdi", fontSize: "30px" }}>{props.event.title}</Typography>
                                <Button variant="outlined" color="" onClick={() => {
                                  setShowTicketModal(true)
                                  // openInNewTab(props.event?.bookingSite);
                                }}>Book Now</Button>
                            </Row>
                            <Typography align="left" style={{ fontWeight: "200", fontFamily: "pirdi", fontSize: "18px" }}>{
                            `${new Intl.DateTimeFormat("en-US", {month: "long", day: "numeric"}).format(props.event.dateFrom)} - ${new Intl.DateTimeFormat("en-US", { month: "long", day: "numeric", year: "numeric"}).format(props.event.dateTo)}`
                            }</Typography>
                            <div style={{ height: "20px" }}></div>
                            <Typography style={{ fontWeight: "200", fontFamily: "pirdi", fontSize: "18px" }}>{`Featuring`}</Typography>
                            <div style={{ height: "10px" }}></div>
                            <Row style={{ width: '100%', flexWrap: "wrap", justifyContent: "center" }}>
                                {props.event.headlineArtist?.map((host, index) => <Chip variant="outlined" icon={<Stars />} color="primary" style={{ margin: "6px 10px" }} key={"host_" + index} label={host} />)}
                                {props.event.artists?.map((host, index) => <Chip variant="outlined" color="default" style={{ margin: "6px 10px" }} key={"host_" + index} label={host} />)}
                            </Row>
                            <div style={{ height: "30px" }}></div>
                            <Typography align="center" style={{ fontWeight: "200", fontFamily: "pirdi", fontSize: "14px" }}>{`Hosted by `}</Typography>
                            <div style={{ height: "10px" }}></div>
                            <Row alignment="center">
                                {props.event.hosts?.map((host, index) => <Chip style={{ marginRight: "10px" }} key={"host_" + index} label={host} />)}
                            </Row>
                            
                   <div style={{height:"30px"}}></div>
                   <Typography style={{fontWeight:"200",fontFamily:"pirdi",fontSize:"14px"}}>{`Sponsored by `}</Typography>
                   <div style={{height:"10px"}}></div>
                   
                   <Row alignment="center">
                        {Object.keys(props.event.sponsors)?.map((sponsor, index)=>
                            (<Tooltip title={`${props.event.sponsors[sponsor]?.name}`} key={"sponsor_"+index}>
                                <motion.img  whileHover={{scale:1.05}} onClick={()=>{
                                    openInNewTab(props.event.sponsors[sponsor]?.url);
                                }} style={{width:"50px",height:"50px",
                                objectFit:"contain",
                                borderRadius: 10,
                                border:"1px solid #0000000d",
                                //mixBlendMode:"multiply",filter:"grayscale(100%)",
                                backgroundColor:"white",
                                margin:"10px"}} alt={`${props.event.sponsors[sponsor]?.name}'s Logo`} src={props.event.sponsors[sponsor]?.logo} />
                            </Tooltip>)
                        )}
                   </Row>
                        </Column>
                        <Row alignment="space-between" style={{ width: "100%" }}>
                            <SocailsList style={{ width: "auto" }} instagram={props.event.website?.split(",")[0]} twitter={props.event.website?.split(",")[1]} facebook={props.event.website?.split(",")[1]} />
                        </Row>
                    </Column>

                </Row>
            </Card>
            <Dialog maxWidth="lg"
                onClose={() => { setShowModal(false) }}
                fullWidth
                aria-labelledby="confirmation-dialog-title"
                open={showModal}>
                <div style={{ width: "100%", height: "100%" }}><img alt="" width="100%" height="100%" src={props.event?.poster} /></div>
            </Dialog>
            <Dialog maxWidth="md"
                onClose={() => { setShowTicketModal(false) }}
                fullWidth
               
                aria-labelledby="confirmation-dialog-title"
                open={showTicketModal}>
               <Grid style={{height: 400, justifyContent: 'center', alignItems: 'center'}} container spacing={5}>
                {
                    Object.keys(props.event.tickets).map((ticket, index)=>(
                        <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                            <Card variant='outlined' style={{padding:20,backgroundImage:`url(${
                                props.event.poster
                            })`, maxHeight: 300, width: 300,margin:"10px 20px"}}>
                             
                                <Column alignment="centered" style={{alignItems: "center",backgroundColor:"#0d0d0d63",padding:20, height: "100%", width:"100%"}}>
                                <Typography align={"center"} style={{fontWeight:"bold",fontFamily:"pirdi",fontSize:"24px"}}>{toPascalCaseWithSpaces(props.event.tickets[ticket]?.name??"")}</Typography>
                                <Typography align={"center"} style={{fontWeight:"200",fontFamily:"pirdi",fontSize:"18px", textTransform: 'capitalize'}}>{toPascalCaseWithSpaces(props.event.tickets[ticket]?.type)}</Typography>
                                <FutureBuilder future={
                                    Logic.toCountryCurrency(props.event.tickets[ticket]?.price, 0)
                                }
                                builder = {(price)=>{
                                    return <Typography align={"center"} style={{fontWeight:"200",fontFamily:"pirdi",fontSize:"24px"}}>{price}</Typography>
                                }}
                                placeholder={<Typography align={"center"} style={{fontWeight:"200",fontFamily:"pirdi",fontSize:"24px"}}>{
                                    props.event.tickets[ticket]?.price
                                }</Typography>}
                                />
                                {
                                    
                                    <Button variant="contained" disabled={
                                        props.event.tickets[ticket]?.status?.toLowerCase() !== "active" || 
                                        props.event.tickets[ticket]?.onSaleDate > new Date().getTime() ||
                                        props.event.tickets[ticket]?.offSaleDate < new Date().getTime()
                                    } color="primary" onClick={()=>{
                                        setShowTicketModal(false);
                                    openInNewTab(props.event.tickets[ticket]?.url);
                                }}>{
                                    props.event.tickets[ticket]?.status?.toLowerCase() !== "active" ? props.event.tickets[ticket]?.status?.toUpperCase() : 
                                    props.event.tickets[ticket]?.onSaleDate > new Date().getTime() ? "Not On Sale" : 
                                    props.event.tickets[ticket]?.offSaleDate < new Date().getTime() ? "Off Sale" : "Buy Now"
                                
                                }</Button>}
                                </Column>
                            </Card>
                            </Grid>
                    ))
                }
               </Grid>
            </Dialog>
            
        </div>
    )
};


EventCard.propTypes = {
    event: PropTypes.object,
    style: PropTypes.object
}