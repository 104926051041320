/* eslint-disable no-undef */

export const usePayment =  ()=>{
    const paypalOptions = {
        // Set your PayPal client ID
        currency:'EUR',
        disableFunding: 'card',
        clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
    }
    const createOrder =  (data, actions, amount) => {
        console.log(data,process.env.REACT_APP_PAYPAL_CLIENT_ID);
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: amount,
                    },
                },
            ],
        });
    };
    return {
        payWithPaypal,
        createOrder,
        paypalOptions
    }
}