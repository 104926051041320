

import { Button, Card, Dialog, IconButton, Snackbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import Stack from '../../components/Stack';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Close } from '@material-ui/icons';
import PropTypes from "prop-types";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Column from '../../components/Column';
import Row from '../../components/Row';
import "../../index.css";

import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import BookRoomCard from '../../components/_book_room_card';
import { useMediaQuery } from '../../contexts/MediaQueryProvider';
import { DefaultStyle } from '../../contexts/styles';
import { useProfile } from '../../contexts/UserProvider';




const BookingRoom = (props) => {
  const history = useNavigate();
  const classes = DefaultStyle()
  const profile = useProfile();
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [notificationMsg,] = useState('');
  const [openFullDesc, setOpenFullDesc] = useState(false)
  const [openSlider, setSliderOpen] = useState(false);
  const { device, deviceSize } = useMediaQuery();



  const toggleOpen = () => setOpenFullDesc(!openFullDesc);

  const _buildBoookingCard = () => {
    return profile?.profile ? <BookRoomCard profile={profile?.profile} room={props.room} /> : <Card style={{height:400,width:300, padding: 20 }}>
      <Column alignment="centered" style={{ height:"100%", width:"100%"}}>
        <Typography>Please Login to a Book Room</Typography>
        <div style={{ height: 10 }} />
        <Button color="primary" variant='outlined' onClick={() => { history("/login") }}> Login </Button>
      </Column>
    </Card>
  }
  const _buildAboutRoom = () => {
    return <motion.div initial={{ opacity: 0.0, x: -20 }} animate={{ opacity: 1.0, x: 0 }} transition={{ delay: 0.2, type: "tween", ease: "easeIn", duration: 0.3 }} style={{ width: "100%",zIndex:300,height: "100%", fontFamily: "Raleway" }}>
      <Column alignment="end" style={{ width: "50%", height: "100%", padding: device === "xs" ? "60px 20px" : "60px", paddingBottom:  device === "xs" ?"30px":"100px" }}>
        <Typography align="left" style={{ color: "white", fontWeight: "bold", fontSize: "50px" }}>{props.room.name}</Typography>
        <div style={{ height: openSlider? 0: 10 }} />
       {openSlider ? <></>:<Typography align="left" style={{ color: "white", fontWeight: "normal", fontSize: "16px", width: device === "xs" ? "80%" : "30%" }}>{props.room.desc}</Typography>}
        <Row onClick={toggleOpen} style={{}} >
          <Typography align="left" variant="overline" style={{ color: "white", fontWeight: "normal", fontSize: "20px", }}>{"Equipments"}</Typography>
          <div style={{ width: 20 }} />
          <Column alignment="centered">
            <IconButton style={{ width: 20, height: 20 }} onClick={toggleOpen}>
              {!openFullDesc ? <img alt=""style={{ width: 20, height: 20 }} src="https://img.icons8.com/ios-glyphs/30/ffffff/circled-chevron-down.png" /> :
                <img alt=""style={{ width: 20, height: 20 }} src="https://img.icons8.com/ios-glyphs/30/ffffff/circled-chevron-up.png" />
              }
            </IconButton>
          </Column>
        </Row>
        <AnimatePresence>{openFullDesc && <Content room={props.room} />}</AnimatePresence>
        {device!=="xs" ? <></> : <Button variant="contained" color={openSlider ? 'default':'primary'} onClick={()=>{setSliderOpen(!openSlider)}} >{`${openSlider ? 'Close':'Book Room'}`}</Button>}
      </Column>
    </motion.div>
  }
  const _buildPage = (() => {
    //...
    return <>
     { device === "xs" ? <></> : <Stack style={{ margin: "0 auto", width: "100%" }}>
        {_buildAboutRoom()}
        <Row alignment="space-between" style={{ width: "50%", sheight: "50%", height: "100%", padding: "0 50px", }}>
          <Column alignment="end">
            <motion.div whileHover={{ scale: 1.2 }} transition={{ type: "tween", ease: "easeIn", duration: 0.3 }}>
              <IconButton style={{ width: 20, height: 20 }} onClick={() => props.onPrev()}>
                <img alt=""style={{ width: 20, height: 20 }} src="https://img.icons8.com/cotton/64/000000/circled-left-2.png" />
              </IconButton>
            </motion.div>
          </Column>

          <Column alignment="end">
            <motion.div whileHover={{ scale: 1.3 }} transition={{ type: "tween", ease: "easeIn", duration: 0.3 }}>
              <IconButton style={{ width: 20, height: 20 }} onClick={() => props.onNext()}>
                <img alt=""style={{ width: 20, height: 20 }} src="https://img.icons8.com/cotton/64/000000/circled-right--v1.png" />
              </IconButton>
            </motion.div>
          </Column>
        </Row>
      </Stack>}
      {
        device === "xs" ? <>
          <Column alignment="end">
            {_buildAboutRoom()}
            <AnimatePresence>{openSlider && <motion.div
            style={{ width: window.innerWidth, height: window.innerHeight*0.7, background: "white", position: "relative", borderRadius: 8, boxShadow: " 0px 0px 25px 3px rgba(0, 0, 0, 0.48)" }}
      layout
      initial={{ opacity: 0, y: window.innerHeight*0.7 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: window.innerHeight*0.7 }}
    >
      {_buildBoookingCard()}
      </motion.div>}</AnimatePresence>
            {/* <SlidingPanel
        type={"bottom"}
        isOpen={openSlider}
        closeFunc={() => setSliderOpen(false)}
      > </SlidingPanel> */}
            {/* <motion.div initial={{ opacity: 0.0, x: -20 }} animate={{ opacity: 1.0, x: 0 }} transition={{ delay: 0.2, type: "tween", ease: "easeIn", duration: 0.3 }} >
         
            {_buildBoookingCard()}
            </motion.div> */}
          </Column>
        </> : <div style={{ position: "absolute", zIndex: 2000, top: "120px", right: "55%" }}>
          <motion.div initial={{ opacity: 0.0, x: -20 }} animate={{ opacity: 1.0, x: 0 }} transition={{ delay: 0.2, type: "tween", ease: "easeIn", duration: 0.3 }} style={{ width: deviceSize.w * 0.28, maxWidth: "400px", height: deviceSize.h * 0.7, background: "white", position: "relative", borderRadius: 8, boxShadow: " 0px 0px 25px 3px rgba(0, 0, 0, 0.48)" }}>
            {_buildBoookingCard()}
          </motion.div>
        </div>
      }

    </>
  });

  return (<div className={classes().root} style={{ width: deviceSize.w, height: deviceSize.h }}>
    <CssBaseline />
    <Stack>
      <div style={{ height: "100%", background: "black", width: "100%", maxWidth: deviceSize.w, maxHeight: deviceSize.h }}>
        <motion.div initial={{ opacity: 0.0 }} animate={{ opacity: 1.0 }} transition={{ delay: 1.0, ease: "easeIn", duration: 1.2 }} style={{ height: "100%", width: "100%" }} >
          <img alt=""src={props.room.image} width="100%" height="100%" style={{ objectFit: "cover" }} />
        </motion.div>
      </div>
      <div style={{ background: "#0000000d", height: "100%", width: "100%" }}></div>
      <div style={{ background: "linear-gradient(0deg, black 20%,#0000009c 54%, transparent)", height: "100%", width: "100%" }}></div>
      <motion.div whileHover={{ opacity: 1.0 }} style={{ width: "100%", height: "100%", filter: "blur(2px)" }}>

      </motion.div>

      {_buildPage()}
    </Stack>

    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification}
      autoHideDuration={notificationMsg.timeout}
      onClose={() => {
        showNotification(false)
      }}
      message={notificationMsg.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  </div>

  )


}
BookingRoom.propTypes = {
  room: PropTypes.object,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
};



function Content(props) {
  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 100 }}
    >
      {
        props.room.Equipment.map((eq, index) => {
          return <Column key={`${eq}_${index}`} style={{ width: "50%" }}>
            <Typography align="left" style={{ color: "white", fontWeight: "bold", fontSize: "20px" }}>{eq.title}</Typography>
            <div style={{ height: 10 }} />
            <Typography align="left" variant="caption" style={{ color: "white", fontWeight: "normal", fontSize: "12px" }}>{eq.desc}</Typography>
            <div style={{ height: 40 }} />
          </Column>
        })
      }

    </motion.div>
  );
}

Content.propTypes = {
  room: PropTypes.object
};

export default BookingRoom;

