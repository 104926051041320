import {  init, send} from "emailjs-com"
import nodemailer from 'nodemailer';

const config={
    apiKey:"821f2a5de2866204a6b733f1373a492d",
    userId:"user_uzQFG8YSYQwlvsLzIsanh",
    templateId:"template_tzlj1hb"
}
export const configEmailJs = ()=>{
    init(config.userId);
}
export const sendFeedback =  (payload,success_callback, error_callback)=>{
    configEmailJs();
    console.log(payload)
    send(
        'service_ocr4tex',config.templateId,
        {message: payload.body, subject:payload.subject, to_name:payload.to,from_name: payload.from, reply_to: payload.to}
    ).then(res=>success_callback(res)).catch(res=>{
        console.log(res);
        error_callback(res);})
}



export const sendWithNodeMailer = async (payload)=>{
    let transporter = nodemailer.createTransport({
    service: 'gmail',
    auth: {
        user: 'olamidepeters@gmail.com',
        pass: 'Sonyerics24'
    }
});
    let mailOptions = {
        from: payload.from, // Something like: Jane Doe <janedoe@gmail.com>
        to: payload.to,
        subject: payload.subject, // email subject
        html:payload.html // email content in HTML
    };
    console.log(mailOptions);
    let request=null;

    try{
 request = await transporter.sendMail(mailOptions);
 console.log("frond");
    }catch(e){
        console.log(e);
    }
  return request;
   
}