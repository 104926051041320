import { Avatar, Button, withStyles } from '@material-ui/core';
import { purple } from '@material-ui/core/colors';
import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

export const UploadButton = (props) =>{
    const ColorButton = withStyles((theme) => ({
        root: {
          color: theme.palette.getContrastText(purple[500]),
          backgroundColor: props.buttonColor??'#de8500',
          '&:hover': {
            backgroundColor: props.buttonHoverColor??'#ffb03a',
          },
        },
      }))(Button);


      const fileUploadRef = useRef(null);

      const handleClick =()=>{
          fileUploadRef.current.click();
      }
      const handleChange = (e)=>{
          const fileUploaded = e.target.files[0];
          if(props.onUpload!==null)
          props.onUpload(fileUploaded);
      }
    return(
        <>
            <ColorButton style={props.style} onClick={handleClick}>
                {props.children}
            </ColorButton>
            <input type="file" ref={fileUploadRef} onChange={handleChange} style={{display:'none'}}></input>
        </>
    )
}


export const UploadProfile = ((props)=>{
    const [image, setImage] = useState(null);

    const fileUploadRef = useRef(null);

    const handleClick =()=>{
        fileUploadRef.current.click();
    }
    const handleChange = (e)=>{
        const fileUploaded = e.target.files[0];
        var fileReader = new FileReader();
        fileReader.onload = function(){
             var dataUri = fileReader.result;
               setImage(dataUri)
        }
        fileReader.readAsDataURL(fileUploaded)
      
        if(props.onUpload!==null)
        props.onUpload(fileUploaded);
    }
    return (
        <div onClick={handleClick}>
            <Avatar src={image} alt={props.alt} style={{...props.style}}/>
            <input type="file" ref={fileUploadRef} onChange={handleChange} style={{display:'none'}}></input>
        </div>
    )
})

UploadProfile.propTypes = {
    alt: PropTypes.string,
    onUpload: PropTypes.func,
    style: PropTypes.object
}

UploadButton.propTypes = {
    buttonColor: PropTypes.string,
    buttonHoverColor: PropTypes.string,
    onUpload: PropTypes.func,
    style: PropTypes.object,
    children: PropTypes.node
}


