/* eslint-disable react-hooks/exhaustive-deps */

import { Avatar, Card, CardActions, CardContent, CardHeader, CardMedia, Chip, alpha, makeStyles, Typography, useTheme } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import Row from './Row';

import NumericLabel from 'react-pretty-numbers';
import banner from '../assets/banner.png';
import logo from '../assets/logo.png';
import '../bubble.css';
import Center from './Center';
import Column from './Column';
import Stack from './Stack';
import FutureBuilder from './_future_builder';
import { MarkdownTextField } from './_new_story_comp';
import { FavoriteBorderRounded } from '@mui/icons-material';
import { FavoriteRounded } from '@material-ui/icons';
import { useProfile } from '../hooks/useProfile';
import PropTypes from 'prop-types';
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        '& .MuiDivider-root': {
            backgroundColor: 'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root': {
            color: 'white'
        },
        '& .MuiCardHeader-title': {
            fontFamily: "raleway",
            fontSize: "15px",
            textAlign: "left"
        },
        '& .MuiCardHeader-subheader': {
            fontFamily: "raleway",
            fontSize: "13px",
            textAlign: "left"
        }
    }
}));

export const StoryCard = ({ story, varaint, onClick, showLabel, label, headlineStyle, userFuture, profile }) => {

    const [views, ] = useState(0);
    const [user, setUser] = useState(false);
    const {retrieveProfileWithCallback} = useProfile();
    const theme = useTheme();
    useEffect(() => {
        getViews();
        retrieveProfileWithCallback((profile)=>{
            setUser(profile)
        })
    }, [])

    const classes = useStyles();
    const getViews = async () => {
        // const request = new Request(`https://youtube.googleapis.com/youtube/v3/videos?part=id%2C%20%20statistics&id=${youtubeData.id}&key=${GoogleApi.key}`,{method:"GET"})
        // const response = await fetch(request)
        // try{
        //     const result=  await response.json();
        //     console.log(result);
        //     setLikes(result.items[0].statistics.viewCount)
        // }catch(e){
        //     console.log(e)

        // }
    }
    const calcDays = () => {
        const date1 = new Date(story.publishedAt);
        const date2 = new Date();
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        return diffDays;
    }
    return (
        <motion.div whileHover={{ scale: 1.02 }}><Card variant={varaint ?? 'outlined'} style={{ height: "auto", paddingBottom: "10px" }} onClick={() => {
            if (onClick)
                onClick();
        }}>
            <CardHeader
                className={classes.root}
                action={
                    <Column alignment="centered" style={{ marginTop: "8px" }}>
                        {showLabel ? (label ? <Chip label={label} /> : calcDays() <= 6 ? <Chip variant="outlined" color={"primary"} label={"NEW"} /> : <></>) : <></>}
                    </Column>
                }
                title={`${story.headline}`}
                subheader={new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(story.publishedAt)}
            />
            <CardMedia style={{ height: 150 }}>
                <div style={{ height: "100%" }}>
                    <img alt="" height="100%" width="100%" style={{ objectFit: "cover" }} src={story?.photo ?? { banner }} />
                </div>
            </CardMedia>
            <CardContent style={{ height: "70px", width: "100%", position: "relative", overflow: "hidden" }}>
                <Stack>
                    <MarkdownTextField style={{ ...headlineStyle, fontFamily: headlineStyle?.fontFamily ?? "raleway", margin: headlineStyle?.margin ?? "8px 5px" }} align="left" viewMode={true} defaultValue={`${JSON.parse(story?.story ?? '{}')?.paragraphs?.find(paragraph => paragraph?.data?.ref !== '')?.data?.ref ?? '...'}`} />
                    <motion.div initial={{ y: 10 }} style={{ height: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.paper, 0.8)} 70%,transparent )` }}></motion.div>

                </Stack>
            </CardContent>
            {userFuture ? <FutureBuilder
                getError={(e) => { console.log(e); }}
                futureFunc={userFuture}
                builder={(user) => {
                    console.log(user);
                    return <Row alignment="space-between" style={{ flexWrap: "wrap", padding: 5 }}>

                        {
                            story.isDTV ?
                                <Chip label={`${user.fname} ${user.lname}`} avatar={<Avatar src={user?.photo ?? logo} />} /> :
                                <Chip label={`${user.fname} ${user.lname}`} avatar={<Avatar src={user?.photo} />} />}
                      
                    </Row>
                }}
                placeholder={
                   <Chip avatar={<Avatar/>}/>
                }
                errorChild={

                    <Center><Typography>Error..</Typography></Center>

                }
            /> : profile ? <Row alignment="space-between" style={{ flexWrap: "wrap", padding: 5 }}><Chip label={`${profile.fname} ${profile.lname}`} avatar={<Avatar src={profile?.photo} />} />
                <Typography align="left" variant="overline" style={{ fontSize: "16px", marginRight: "10px", color: alpha(theme.palette.text.primary, 0.7) }}><NumericLabel>{views ? views?.split(",")?.length - 1 : 0}</NumericLabel> <span style={{ fontSize: "10px" }} >Views</span></Typography>
            </Row> : <></>}
            <CardActions>
            <Row alignment={"space-between"} style={{ width: "100%", }}>
                                <Typography align="left" variant="overline" style={{ fontSize: "16px", margin: "0 10px", color: alpha(theme.palette.text.primary, 0.7) }}><NumericLabel>{isNaN(story.viewCount) ? 0 : story.viewCount}</NumericLabel> <span style={{ fontSize: "10px" }} >Reads</span></Typography>
                            <Row alignment="centered" style={{alignItems:"center",marginRight: "10px",}}>
                               {story?.likes?.includes(user?.uid) ?
                                <FavoriteRounded style={{ fontSize: "18px", color: theme.palette.primary.main }} /> :
                               <FavoriteBorderRounded style={{ fontSize: "18px", color: alpha(theme.palette.text.primary, 0.7) }} />}
                                <Typography align="left" variant="overline" style={{ fontSize: "16px", marginLeft: "6px", color: alpha(theme.palette.text.primary, 0.7) }}><NumericLabel>{isNaN(story.likeCount) ? 0 : story.likeCount}</NumericLabel></Typography>
                            </Row>
                        </Row>
            </CardActions>
        </Card></motion.div>
    )
};

StoryCard.propTypes = {
    story: PropTypes.object,
    varaint: PropTypes.string,
    onClick: PropTypes.func,
    showLabel: PropTypes.bool,
    label: PropTypes.string,
    headlineStyle: PropTypes.object,
    userFuture: PropTypes.func,
    profile: PropTypes.object
}