import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
 import PropTypes from 'prop-types';


export const  CustomScrollview = ({
    children,
    handleScroll,
    handleScrollFrame,
    handleScrollStart,
    handleScrollStop,
    handleUpdate,
    renderView,
    renderTrackHorizontal,
    renderTrackVertical,
    renderThumbHorizontal,
    renderThumbVertical,
    ...props
}) => {
  
    return (
      <Scrollbars
        onScroll={handleScroll}
        onScrollFrame={handleScrollFrame}
        onScrollStart={handleScrollStart}
        onScrollStop={handleScrollStop}
        onUpdate={handleUpdate}
        renderView={renderView}
        renderTrackHorizontal={renderTrackHorizontal}
        renderTrackVertical={renderTrackVertical}
        renderThumbHorizontal={renderThumbHorizontal}
        renderThumbVertical={renderThumbVertical}
        autoHide
        autoHideTimeout={1000}
        autoHideDuration={200}
        autoHeight
        autoHeightMin={0}
        autoHeightMax={"100%"}
        thumbMinSize={30}
        universal={true}
        {...props}>
            {children}
        </Scrollbars>
    );
  
}

CustomScrollview.propTypes = {
    children: PropTypes.node,
    handleScroll: PropTypes.func,
    handleScrollFrame: PropTypes.func,
    handleScrollStart: PropTypes.func,
    handleScrollStop: PropTypes.func,
    handleUpdate: PropTypes.func,
    renderView: PropTypes.func,
    renderTrackHorizontal: PropTypes.func,
    renderTrackVertical: PropTypes.func,
    renderThumbHorizontal: PropTypes.func,
    renderThumbVertical: PropTypes.func,
}