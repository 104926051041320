import React, { useState } from 'react';
import { OutlinedInput, InputAdornment, Typography, IconButton } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import PropTypes from 'prop-types'

export const MyVideosFilters = ({ onSearch }) => {
    const [search, setSearch] = useState('');

    return <>
    <Typography style={{ fontWeight: "bold", fontSize: "22px", textAlign: "left", marginBottom: 10 }}>My Videos</Typography>
    <OutlinedInput
        placeholder="Search Videos"
        value={search}
        onChange={(e) => {
            setSearch(e.target.value);
        }}
        endAdornment={<InputAdornment position="end"><IconButton onClick={()=>{
            onSearch(search)
        }}>
            <Search />
            </IconButton></InputAdornment>}
        aria-describedby="outlined-weight-helper-text"
        inputProps={{
            'aria-label': 'weight',
        }}

    />
</> 
}
MyVideosFilters.propTypes = {
    onSearch: PropTypes.func
}