import React, { createContext,useState } from 'react';
import { ShowsService } from '../services/showsService';
import PropTypes from 'prop-types';

export const ShowContext = createContext();


const ShowProvider = ((props)=>{
    const [dearfach_shows,setDearfachShows] = useState([]);
    const [coming_shows,setComingShows] = useState([]);
    const [dearfach_shows_size,setDearfachShowsSize] = useState(0);
    const [dearfach_show,setDearfachShow] = useState({});
    const [shows_result,setSearchResult] = useState([]);
    const [shows_result_size,setSearchResultSize] = useState([]);
    const [show_episodes,setEpisodes] = useState({});
    const [showEpisodesTotal,setShowEpisodesTotal] = useState(0);
    const {
        createShow,
        getShow: getShowService,
        getShowList,
        searchShow,
        updateShow: updateShowService,
        deleteShow,
        getEpisodesForShow,
        getEpisodesForSeason,
        getEpisode: getEpisodeService,
        createEpisode,
        updateEpisode: updateEpisodeService,
        deleteEpisode: deleteEpisodeService,
        addToWatchlist,
        addToContinueWatching,
        getWatchList,
        getContinueWatching,
        getContinueWatchingList,
        comingSoon,
        

    } = ShowsService();

    const getShows = async(cursor,limit,offset)=>{
      const {data, total} = await getShowList({cursor:cursor,limit:limit,offset:offset});
      if(data){
          setDearfachShows(data);
            setDearfachShowsSize(total);
        }
        console.log("SHOWS: ",data);
        return data;
      
    }
    const getComingSoonShows = async(cursor,limit,offset)=>{
        const {data, } = await comingSoon({cursor:cursor,limit:limit,offset:offset});
        if(data){
            setComingShows(data);
        }
        return data;
    }
    const getEpisodesForThisSeason = async(showId,season_no, cursor, limit, offset)=>{
        const {data, total} = await getEpisodesForSeason({id:showId,season_no:season_no,cursor:cursor,limit:limit,offset:offset});
        if(data){
            setEpisodes(data);
            setShowEpisodesTotal(total);
        }
        return data;
    }
    const getShow = async(showId)=>{
        console.log("SHOWID: ",showId);
        const {data} = await getShowService({id: showId});
        if(data?.data){
            setDearfachShow(data?.data);
        }
        return data?.data;

    } 
    const getEpisode = async(episodeId)=>{
        console.log("EPISODE ID: ",episodeId);
        const {data} = await getEpisodeService({id: episodeId});
        return data?.data;
    }
    
    const getEpisodes = async(showId, cursor, limit, offset)=>{
        const {data, total} = await getEpisodesForShow({id:showId,cursor:cursor,limit:limit,offset:offset});
        if(data){
            setEpisodes(data);
            setShowEpisodesTotal(total);
        }
        return data;
    }
    //Takes in show
    const addShow = async ({showTitle, showDesc, showRelease, showCover, views,comments,likes,trailerLink, seasons})=>{
     
        const {status} = await createShow({showTitle, showDesc, showRelease, showCover, views,comments,likes,trailerLink, seasons});
        if(status === 200){
            getShows();
        } 
    }
    const addEpisode = async ({showId, title,description,season_no,episode_no,next_episode,views,link,thumbnail,release,duration,isYoutube})=>{
        
        const response = await createEpisode({showId, title,description,season_no,episode_no,next_episode,views,link,thumbnail,release,duration,isYoutube});
        if(response.status<200 || response.status>300){
            console.error(response.status)
        }else{
           getEpisodes(showId);
        }
        return await response.json()
    }
    const findShows = async (name, cursor, limit, offset) => {
                const {data,total} = await searchShow({query: name, cursor: cursor, limit: limit, offset: offset});
                    if(data){
                    setSearchResult(data.data);
                    setSearchResultSize(total);
                }
                return data;
        
    }
    const removeShow = async(showId)=>{
        const {status} =  await deleteShow({id: showId});
         getShows();
         return status === 200;
    }
    const deleteEpisode = async(showId,episodeId)=>{
                const {status} = await deleteEpisodeService({id: episodeId});
                getEpisodes(showId);
                return status === 200;
    }
    const updateEpisode = async(episode)=>{
      
        await updateEpisodeService(episode);
        getShows();
        getEpisodes(episode.showId);
    }
    const getWatchedData = async(episodeId,showId, userId)=>{
        const {data} = await getContinueWatching({episodeId,showId, userId});
        return data?.data;

      }
      const getContinueWatchingListData = async(userId,cursor,limit,offset)=>{
        if(!userId){
            return [];
        }
        const {data} = await getContinueWatchingList({userId,cursor,limit,offset});
        return data?.data ?? [];
      }
    const addWatchedData = async({showId, episodeId,userId,progress})=>{
        const {status} = await addToContinueWatching({showId, episodeId,userId,progress});
        return status === 200;
    }
    const getWatchListData = async(userId)=>{
        const {data} = await getWatchList({userId});
        return data?.data;
    }
    const addWatchListData = async({showId, userId,date_finished,date_started,timestamp,episodeId})=>{
        const {status} = await addToWatchlist({showId, userId,date_finished,date_started,timestamp,episodeId});
        return status === 200;
    }
    const updateShow = async(show)=>{
        const {status} = await updateShowService(show);
           getShows();
        return status === 200;
    }
    return (
        <ShowContext.Provider value={{
        dearfach_shows,
        dearfach_show,
        shows_result,
        show_episodes,
        showEpisodesTotal,
        dearfach_shows_size,
        shows_result_size,
        coming_shows,
        getShows,
        getShow,
        addShow,
        findShows,
        removeShow,
        updateShow,
        getEpisodes,
        addEpisode,
        deleteEpisode,
        updateEpisode,
        getEpisode,
        getEpisodesForThisSeason,
        getWatchedData,
        addWatchedData,
        getWatchListData,
        addWatchListData,
        getContinueWatchingListData,
        getComingSoonShows,
        
        }}>
            {props.children}
        </ShowContext.Provider>
    );
})

export default ShowProvider;


ShowProvider.propTypes = {
    children: PropTypes.node
}