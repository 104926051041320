import {
    makeStyles
} from '@material-ui/core';


export const  DefaultStyle = ()=>{

    const useStyles = makeStyles((theme) => ({
        root: {
            display: 'flex',
            background: "black",
            overflowY: 'hidden',
            maxWidth: '100%',
            flexGrow: 1,
            '& .MuiDivider-root': {
                backgroundColor: 'white'
            },
            '& .MuiList-root .MuiListItem-root .MuiListText-root': {
                color: 'white'
            },
            '& .MuiIconButton-root': {
                padding: 0
            }
        },
        iconBtn: {
            '&:hover': {
                color: "white"
            },
        },
        sideBarSocials: {
            color: "#ffffff47",
            fontSize: "12px",
            '&:hover': {
                color: "white"
            },
        },
        divider: {
            backgroundColor: "rgba(1,1,1, 1)"
        },
        center: {
            textAlign: "center",
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        toolbar: {
            paddingRight: 24, // keep right padding when drawer closed
        },
        gridList: {
            width: 500,
            height: 450,
        },
        icon: {
            color: 'rgba(255, 255, 255, 0.54)',
        },
        toolbarIcon: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '0 8px',
            ...theme.mixins.toolbar,
        },

        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            padding: "3px 1px",
            position: "fixed",
            // background:"linear-gradient(45deg, black, #d09612bd);",
            background: "transparent",
            transition: theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        socialIcon: {
            color: "#ffffff47",
            '&:hover': {
                color: "white"
            },
        },
        menuButtonHidden: {
            display: 'none',
        },
        title: {
            flexGrow: 1,
        },
        listItem: {
            backgroundColor: "#2C2C2C",
            color: "white",
            margin: 10
        },
        listItemImage: {
            height: "60px",
            width: "60px",
            margin: "10px"
        },
        list: {
            transition: theme.transitions.create(["background", "background-color"], {
                duration: theme.transitions.duration.complex,
            }),
            "&:hover": {
                backgroundColor: "#6363638c",
                color: "black",
            },
        },
        drawerPaperClose: {
            overflowX: 'hidden',
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up('sm')]: {
                width: theme.spacing(9),
            },
        },
        grow: {
            '&:hover': {
                transform: 'scale(1.1)',
                easing: "linear",
                cursor: 'pointer'
            }
        },
        link: {
            color: "grey",
            '&:hover': {
                transform: 'scale(1.1)',
                cursor: 'pointer',
                color: 'white'
            }
        },
        appBarSpacer: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
        },
        mobileNav: {
            width: "60%"
        },
        pcNav: {
            width: "30%"
        },
        logo: {
            height: "60px",
            width: "60px"
        },
        avatar: {
            margin: theme.spacing(1),
            backgroundColor: theme.palette.text.primary,
        },
        container: {
            paddingTop: theme.spacing(4),
            paddingBottom: theme.spacing(4),
        },
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            overflow: 'auto',
            flexDirection: 'column',
        },
        fixedHeight: {
            height: 240,
        },
    }));

    return useStyles;
}
