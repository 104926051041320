/* eslint-disable react-hooks/exhaustive-deps */

import { Breadcrumbs, Button, Card, Chip, CircularProgress, Dialog, Divider, FormControlLabel, FormGroup, IconButton, ListItem, ListItemText, Radio, Tooltip, Typography, alpha, useTheme } from '@material-ui/core'
import { ArrowBackIosRounded, CheckCircleOutline, LocalShipping, PaymentRounded, Person } from '@material-ui/icons'
import { motion } from 'framer-motion'
import { useSnackbar } from "notistack"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Column from '../../components/Column'
import Row from '../../components/Row'
import { CustomTextField } from '../../components/textfields'
import { UsernameText } from '../../components/_user_name_mod'
import firebase_app from "../../config/firebase"
import { SiteDataContext } from '../../config/SiteData'
import { useMediaQuery } from '../../contexts/MediaQueryProvider'
import { useStripePaymentContext } from '../../contexts/StripeProvider'
import { useWallet } from '../../contexts/WalletProvider'
import { clearCart, purchaseItems } from '../../logic/databaseHandler'
import Logic from '../../logic/logic'
import { sendInvoice } from '../../logic/paymentHandler'
import SuccessPage, { SuccessMessage } from '../booking/success_page'
import Wallet from './wallet_top_up'
import { usePayment } from '../../contexts/usePayment'

import { PayPalButton } from 'react-paypal-button-v2';

const PaymentComp = ({profile: Profile,price,items,cost:Cost,actualCost:ACost,style, enableDTVCoins, onPaymentProcessCompleted}) => {
    const [tempId,] = useState(firebase_app.auth()?.currentUser?.uid);
    const { enqueueSnackbar } = useSnackbar();
    const [showWallet, setShowWallet] = useState(false)
    const history = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [preview_body, setPreview_body] = useState(<></>);
    const {
        SiteData,
    } = useContext(SiteDataContext);
    const { runPaymentTransaction,
        transactionStatus,
        loadingTransaction,
         } = useWallet();
    const today = new Date().valueOf();
    const [profile, updateProfile] = useState(Profile);
    const [cost, setCost] = useState(Cost);
    const [actualCost, setActualCost] = useState(ACost);
    
    const [tax, ] = useState(0);
  const [taxString, setTaxString] = useState(0);
    const [eCounter, ] = useState(0);
    const theme = useTheme();
    const [currentPage, setCurrentPage] = useState(0);
    const [useShipping, ] = useState(false);
    const [shipping, ] = useState({ standard: 7.00, express: 14.00 })
    const [chossenShipping, setChossenShipping] = useState("standard");
    const [completed, setCompleted] = useState(false);
    const [completed_message, ] = useState("Congratulations! An invoice of your purchase has been sent to your email");
    const [successMessage, setSuccessMessage] = useState(new SuccessMessage(undefined, undefined, `${completed_message}`));
    const { runTransaction, stripePaymentLoading,  StripeElement } = useStripePaymentContext();
    const {device} = useMediaQuery();
    const { paypalOptions, createOrder} = usePayment()
    useEffect(() => {
        getCost();
        return ()=>{
            window.removeEventListener('focus', handleFocus);
        }
    },[price]);
    const handleFocus = () => {
        // Run your API query here
        console.log('User returned to the original tab. Run API query.');
  
        // Remember to remove the event listener
        window.removeEventListener('focus', handleFocus);
      };
    const handlePayWithPaypal = async ()=>{
        await Logic.saveTransaction({
            description: `Purchased ${items.length} Items`,
                totalPaid: `¢${price}`,
                totalCharge: cost,
                paymentType: "PP",
                uid: tempId,
                cat: "all"
        });
        await finalizeTransaction()
    }
    const getCost = async ()=>{
       
        setCost(`€${(price/(enableDTVCoins ? 10 : 1)).toFixed(2)}`);
        let c1 = await Logic.toCountryCurrency(Number(price)/(enableDTVCoins ? 10 : 1));
        Logic.toCountryCurrency(tax).then((tx) => {
            setTaxString(tx)
        })
         console.log(c1);
        setCost(c1);
        setActualCost(c1);
    }
    function handleClick(page) {
        setCurrentPage(page)
    }
    const handlePayment = async (ev) => {
        ev.preventDefault();
        try{
                let res = await runTransaction(price/(enableDTVCoins ? 10 : 1),{
                    customerId: profile?.stripeCustomerId,
                    email: profile?.email,
                    shipping: {
                        name: `${profile.fname} ${profile.lname}`,
                        address: profile?.address?.full ?? "",
                        carrier: "UPS",
                        tracking_number: "",
                        phone: profile?.phone
                    }
                });

            if (res) {

                await Logic.saveTransaction({
                    description: `Purchased ${items.length} Items`,
                    totalPaid: `¢${price}`,
                    totalCharge: cost,
                    paymentType: "DC",
                    uid: tempId,
                    cat: "all"
                });
                await finalizeTransaction()
            }
        }catch(e){
            console.log(e);
            enqueueSnackbar(`Payment Failed!`, {
                variant: 'error', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        }
        
    }
    const finalizeTransaction = async () => {
        const billing = {
            name: `${profile.fname} ${profile.lname}`,
            email: `${profile.email}`,
            address: {
                city: profile?.address?.address2 ?? "",
                line1: profile?.address?.full ?? "",
                country: profile?.address?.country ?? "",
                postal_code: profile?.address?.postal_code
            },
            dateAdded: today,
            items: items,
            uid: profile.uid,
            subtotal: actualCost,
            tax: taxString,
            total: cost,
            profileLink: `${SiteData.siteUrl}/profile/${profile.uid}`,
            orderID: `DTV-${Date.now()}`,

        }
        console.log("PAYMENT DONE-->", billing);
        await purchaseItems(tempId, items, async()=>{
            await clearCart({ uid: tempId });
            sendInvoice(billing, SiteData);
            setSuccessMessage(new SuccessMessage(undefined, undefined, `Congratulations! An invoice of your purchase has been sent to your email`, () => { history(-1) }))
            setCompleted(true);
            setPreview_body(<>
                <Column style={{padding: 40}}>
            <Row alignment="centered">
              <motion.div animate={{
                scale: [1, 2, 2, 1, 1],
                //rotate: [0, 0, 270, 270, 0],
                style: {
                  color: 'green',
                },
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
              }}
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5, 0.8, 1],
                  loop: Infinity,
                  repeatDelay: 1
                }}>
                <IconButton style={{ color: theme.palette.primary.main }}>
                  <CheckCircleOutline />
                </IconButton>
              </motion.div>
                </Row>
                <Typography variant="overline" align="center">Success!</Typography>
                <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">Your Submission has been sent</Typography>
                <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">{"We'll back back to you within "}<span style={{ color: alpha(theme.palette.text.primary, 1) }}>3-5 workin days</span></Typography>
                <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">for any enquiry visit out <a href={"/contact"} style={{ textTransform: "underline", color: alpha(theme.palette.text.primary, 1) }}>contact page</a></Typography>
                <Row alignment="centered">
                <div style={ 
                    {
                    marginTop:30,
                    width:80
                    }
                }>
                <Button variant={"contained"} color="primary" onClick={() => {
                    if(onPaymentProcessCompleted){
                        onPaymentProcessCompleted();
                    }else{
                        history('/'+profile.uid, {replace:true});
                    }
                
                } }>Done</Button>
                </div>
            </Row>
            </Column>
            </>);
            setShowModal(true);
           
        });
       

    }
    const handleWalletPayment = async () => {
        try {

            setCost(`¢${price}`);
            setActualCost(`¢${price}`);
            if(await runPaymentTransaction(Number(price))){
                  enqueueSnackbar(`Purchase Success!`, {
                variant: 'success', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
            await Logic.saveTransaction({
                description: `Purchased ${items.length} Items`,
                totalPaid: `¢${price}`,
                totalCharge: cost,
                paymentType: "W",
                uid: tempId,
                cat: "all"
            });
            await finalizeTransaction();
            }
          

        } catch (e) {
            console.log(e);
        }


    }




    const nextStep = () => {

        if (currentPage < breadcrumbs.length)
            setCurrentPage(currentPage + (useShipping ? 1 : 2));
    }
    const prevStep = () => {
        if (currentPage > 0)
            setCurrentPage((breadcrumbs.length - 1) - 1);
    }

    const handleTopUpWallet = async () => {
        setShowWallet(true);
    }

    const  breadcrumbs =[
        <motion.div key={"currPage0"} style={{ cursor: 'pointer' }} whileHover={{ color: theme.palette.text.primary, scale: 1.1 }}>
            <Typography underline="hover" key="1" style={{ color: currentPage === 0 ? theme.palette.primary.main : theme.palette.text.disabled }} color="inherit" href="/" onClick={() => { handleClick(0) }}>
                Information
            </Typography>
        </motion.div>,

        <motion.div key={"currPage1"} style={{ cursor: 'pointer' }} whileHover={{ color: theme.palette.text.primary, scale: 1.1 }}>
            <Typography
                underline="hover"
                key="2"
                style={{ color: currentPage === 1 ? theme.palette.primary.main : theme.palette.text.disabled }}
                onClick={() => { handleClick(1) }}
            >
                Shipping
            </Typography>
        </motion.div>,
        <motion.div key={"currPage2"} style={{ cursor: 'pointer' }} whileHover={{ color: theme.palette.text.primary, scale: 1.1 }}>
            <Typography key="3"
                style={{ color: currentPage === (2) ? theme.palette.primary.main : theme.palette.text.disabled }}
                color="text.primary"
                onClick={() => { handleClick(2) }}
            >
                Payment
            </Typography>
        </motion.div>


    ];
if (!useShipping) {
            breadcrumbs.splice(1, 1);
        }
    const _buildAddress = () => {
        return <FormGroup>
            <Column>

                <CustomTextField type="address" defaultValue={profile?.address?.address1 ?? ""}
                    onKeyUp={(v) => {
                        if (profile?.address) {
                            profile.address.address1 = v;
                        } else {
                            profile.address = { address1: v }
                        }
                        profile.address.full = `${profile.address.address1}`
                        updateProfile({ ...profile })
                    }}
                    label="Address 1" />
                <div style={{ height: 20 }} />
                <CustomTextField type="address" onKeyUp={(v) => {
                    if (profile?.address) {
                        profile.address.address2 = v;
                    } else {
                        profile.address = { address2: v }
                    }
                    profile.address.full = `${profile.address.address1}, ${profile.address.address2}`
                    updateProfile({ ...profile })
                }} defaultValue={profile?.address?.address2 ?? ""} label="Address 2" />
                <div style={{ height: 20 }} />
                <CustomTextField type="address"
                    onChange={(v) => {
                        if (profile?.address) {
                            profile.address.address3 = v;
                        } else {
                            profile.address = { address3: v }
                        }
                        updateProfile({ ...profile })
                    }}
                    value={profile?.address?.address3 ?? ""} label="Address 3" />
                <div style={{ height: 20 }} />
                <Row>
                    <CustomTextField type="address"
                        onChange={(v) => {
                            if (profile?.address) {
                                profile.address.country = v;
                            } else {
                                profile.address = { country: v }
                            }
                            profile.address.full = `${profile.address.address1}, ${profile.address.address2}, ${profile.address.country}`
                            updateProfile({ ...profile })
                        }}

                        value={profile?.address?.country ?? ""} label="Country" />
                    <div style={{ width: 20 }} />
                    <CustomTextField type="address"
                        onChange={(v) => {
                            if (profile?.address) {
                                profile.address.postal_code = v;
                            } else {
                                profile.address = { postal_code: v }
                            }
                            profile.address.full = `${profile.address.address1}, ${profile.address.address2}, ${profile.address.country}, ${profile.address.postal_code}`

                            updateProfile({ ...profile })
                        }}
                        value={profile?.address?.postal_code ?? ""} label="Postal Code" />

                </Row>
                <div style={{ height: 20 }} />

            </Column>
        </FormGroup>
    }
    const _buildNameFields = ()=>{
        return <>
         <CustomTextField style={{ width: "50%" }} label="First name" value={profile?.fname ?? ""} onChange={(v) => {
                    profile.fname = v;
                    updateProfile({ ...profile })
                }} />
                <div style={{ width: device==="xs" ? 0 : 10, height: device==="xs" ? 20 : 0 }} />
                <CustomTextField value={profile?.lname ?? ""} onChange={(v) => {
                    profile.lname = v;
                    updateProfile({ ...profile })
                }} style={{ width: "50%" }} label="Last name" />
                </>
    }
    const _buildPersonalInfo = () => {
        return <Column>
            {device === "xs" ? <Column> {_buildNameFields()} </Column> :<Row>
               {_buildNameFields()}
            </Row>}
            <div style={{ height: 20 }} />
            <CustomTextField value={profile?.email ?? ""} label="Email" type="email" onChange={(v) => {
                profile.email = v;
                updateProfile({ ...profile })
            }} />
            <div style={{ height: 20 }} />
            <CustomTextField label="Mobile" value={profile?.phone ?? ""} onChange={(v) => {
                profile.phone = v;
                updateProfile({ ...profile })
            }} />
        </Column>
    }
    const _buildWallet = () => {
        return <Column>
            <Card variant="outlined" style={{ padding: 12 }}>
                {device ==="xs" ? <Column>
                <Typography style={{ color: theme.palette.text.disabled }}>Contact</Typography>
                    <Typography >{profile.phone ? (profile?.phone + " | ") : "" + profile?.email ?? "--"}</Typography>
                </Column> :<Row alignment="space-between">
                    <Typography style={{ color: theme.palette.text.disabled }}>Contact</Typography>
                    <Typography >{profile.phone ? (profile?.phone + " | ") : "" + profile?.email ?? "--"}</Typography>
                </Row>}
                {
                    useShipping ? <><div style={{ height: 20 }} />

                        <Divider style={{ backgroundColor: theme.palette.text.disabled }} />
                        <div style={{ height: 20 }} />
                        <Row alignment="space-between">
                            <Typography style={{ color: theme.palette.text.disabled }}>Ship to</Typography>
                            <Typography>{profile?.address?.full ?? "--"}</Typography>
                        </Row>
                        <div style={{ height: 20 }} />

                        <Divider style={{ backgroundColor: theme.palette.text.disabled }} />
                        <div style={{ height: 20 }} />
                        <Row alignment="space-between">
                            <Typography style={{ color: theme.palette.text.disabled }}>Shipping</Typography>
                            <Row>
                                <FormGroup row={true}>
                                    {
                                        Object.keys(shipping).map(k => <FormControlLabel key={k} control={<Radio style={{ marginLeft: 5 }} onChange={() => {
                                            setChossenShipping(k);
                                        }} value={k} checked={chossenShipping === k} />} label={k} />)
                                    }

                                </FormGroup>
                            </Row>
                        </Row></> : <></>}
            </Card>
            <div style={{ height: 20 }} />
           {enableDTVCoins && <>
            <Typography align="left" style={{ fontSize: 13 }}>Account Wallet</Typography>
            <div style={{ height: 10 }} />
            <Card variant={"outlined"} style={{ padding: 12 }}>
                <Column>
                <ListItem>
                    <ListItemText primary={<UsernameText uid={tempId} />} />

                    <Column>
                       { device === "xs" ?<></>  : <Typography align="right" style={{ fontSize: 12 }}> DTV Wallet</Typography>}
                        <Typography color="primary" align="right" style={{ ...style, }}>{`¢${profile?.bank ?? 0}`}</Typography>
                       
                    </Column>
                </ListItem>
                {!transactionStatus.error ? <></> : <Typography align="right" color="textSecondary" style={{ fontSize: 12 }}>{transactionStatus.message}</Typography>}
                </Column>
                
            </Card>
            <div style={{ height: 20 }} />
            <Row alignment="right">
                <Button onClick={handleTopUpWallet}>Add Funds</Button>
                <div style={{ width: 10 }} />
                {loadingTransaction ? <div style={{ height: 20, width: 20 }}><CircularProgress /> </div> : <Button variant='contained' color="primary" 
                disabled={((price ?? 0) < 1) || (profile?.bank ?? 0) < (price ?? 0) } 
                onClick={() => { handleWalletPayment() }}>Pay With Wallet</Button>}


            </Row>
            <div style={{ height: 30 }} />
            <div className="ui horizontal divider">
                <Typography style={{ color: theme.palette.text.primary }}>OR</Typography>
            </div>
            </>}
            <div style={{ height: 20 }} />
            <Typography align="left" style={{ fontSize: 13 }}>Pay with Card</Typography>
            <div style={{ height: 10 }} />

            <Card variant={"outlined"} style={{ padding: 12 }}>
                {showWallet ? <></> : StripeElement()}
            </Card>
            <div style={{ height: 20 }} />
            <Row alignment={device==="xs" ?"center":"right"}>
                {stripePaymentLoading ? <CircularProgress /> : <Button variant='contained' color="primary" onClick={handlePayment}>Pay With Card</Button>}
            </Row>
            <div style={{ height: 30 }} />
            <div className="ui horizontal divider">
                <Typography style={{ color: theme.palette.text.primary }}>OR</Typography>
            </div>
            <Row style={{width:'100%'}} alignment={"centered"}>
            

            <PayPalButton 
            
            createOrder={(data, actions) => createOrder(data, actions, (price/(enableDTVCoins ? 10 : 1)))}
            style={{marginRight: 10}}
        options={paypalOptions}
      onSuccess={handlePayWithPaypal}
    />
    {/* <div style={{width:10}}/>
            <Button variant='outlined' style={{marginRight: 10}}>
                {
                    <img src={applePay} style={{ height: 25,padding:5}} />
                }
            </Button>
            <Button variant='outlined' style={{marginRight: 10}}>
                {
                    <img src={googlePay} style={{ height: 25,padding:5}} />
                }
            </Button> */}
            </Row>
        </Column>
    }
    const _buildOrderTotal= ()=>{
        console.log(cost,cost,price,price);
        return  <Row alignment='space-between' style={{width:'100%'}}>
            <Row style={{alignItems: 'center'}}>
                 <motion.div style={{ marginRight: 10 }} whileHover={{ scale: 1.1 }}>
                    <IconButton style={{padding: 5}} onClick={() => { history(-1) }}>
                       <ArrowBackIosRounded style={{fontSize: 16}} />
                    </IconButton>
                </motion.div>
            <Typography align={device==="xs"?"center":"left"} style={{ fontSize:device === "xs" ?16:22, fontWeight: "bold" }}>
        Order Total:
    </Typography>
            </Row>
           
    <Row alignment={device==="xs"?"center":"right"}>
        <Tooltip title={cost}>
            <Typography style={{ fontWeight: "bold", fontSize: 22, }}>
                {`${(cost)}` }  {enableDTVCoins && <span style={{ color: theme.palette.primary.main }}>{` » ¢${(price ?? 0)}`}</span>}
            </Typography>
        </Tooltip>
        <div style={{ width: "10px" }} />
        <Column>
            {
                eCounter > 0 ? <Chip variant="outlined" size="small" style={{ borderColor: "#e63946", color: "#e63946", fontSize: "10px" }} label={`You have ${eCounter} items expired`} /> : <></>
            }

        </Column>

    </Row></Row> 
    }
    return (<>
        <Column alignment="right" style={{ height: "100%", width: "100%", padding: "20px" }}>
            {device==="xs" ? <Column alignment="center"> {_buildOrderTotal()} </Column> : <Row alignment="space-between">
                {_buildOrderTotal()}
            </Row>}
            <div style={{ height: 10 }} />
            <Divider />
            <div style={{ height: 10 }} />
            <Breadcrumbs separator="›" aria-label="breadcrumb">
                {breadcrumbs}
            </Breadcrumbs>
            <div style={{ height: 40 }} />

            <Row>
                <Column>
                    {currentPage === 0 ? <Person /> : currentPage === 1 ? <LocalShipping /> : <PaymentRounded />}
                </Column>
                <div style={{ width: 10 }} />
                <Typography align="left" style={{ fontSize: device==="xs"?16:22 }}>{currentPage === 0 ? 'Personal Information' : currentPage === 1 ? 'Shipping Address' : 'Payment'}</Typography>
            </Row>
            <div style={{ height: 15 }} />
            {
                currentPage === 0 ? _buildPersonalInfo() : currentPage === 1 ? _buildAddress() : _buildWallet()

            }
            <div style={{ height: 40 }} />
            <Row alignment="space-between">
                {currentPage > 0 ? <Button onClick={prevStep}>‹ Previous</Button> : <div />}
                {(currentPage < breadcrumbs.length) ? <Button variant="contained" color="primary" onClick={nextStep}>Next</Button> : <div />}
            </Row>
            <div style={{ height: 40 }} />
            
        </Column>
        <Dialog
        maxWidth='md'
        fullWidth={true}
      onClose={() => { setShowModal(false) }}

      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
        <Dialog  maxWidth={device==="xs" ? "lg":"xs"} fullWidth open={showWallet} onClose={() => {
            setShowWallet(false)
        }}>
            <Wallet stripeForm={!showWallet ? <></> : StripeElement()} />
        </Dialog>
        {!completed ? <></> : <SuccessPage open={true} info={successMessage} disableBackdropClick />}
    </>)
}
PaymentComp.propTypes = {
    totalCost: PropTypes.number,
    cost: PropTypes.number,
    actualCost: PropTypes.number,
    profile: PropTypes.object,
    items: PropTypes.array,
    enableDTVCoins: PropTypes.bool,
    onPaymentProcessCompleted: PropTypes.func,
    style: PropTypes.object,
    price: PropTypes.number
}
export default PaymentComp;