import { Grid, IconButton, makeStyles } from '@material-ui/core';
import { Facebook, Instagram, Twitter, YouTube } from '@material-ui/icons';
import React from 'react';
import '../bubble.css';
import "../index.css";
import Column from './Column';
import Row from './Row';

function openInNewTab(url) {
    if(url!=="/")
   {
        var win = window.open(url, '_blank');
        win.focus();
    }
  }
const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        '& .MuiDivider-root':{
            backgroundColor:'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root':{
            color: 'white'
        }
      },
          iconBtn:{
              color:"white",
            '&:hover': {
                color:"white",
                transform: 'scale(1.1)',
                cursor: 'pointer'
            },
          },
    }));
export const SocailsList = (props,)=>{


    const classes = useStyles();
    const _buildSocials = (
    option={alignment:"right",iconClass:classes.iconBtn, style:{}, fontSize:"28px"},
    links={youtube:undefined,instagram:undefined,facebook:undefined,twitter:undefined}
    )=>{
    
        return <Row alignment="right" style={{height:"100%", width:props.style?.width??"100%"}}>
            <Column alignment="centered" style={{height:"100%", width:'100%'}}>
            <Row alignment={option.alignment}>
            {!links.youtube?<></>:<IconButton className={`grow  ${option.iconClass}`} style={option.style} onClick={()=>{openInNewTab(links.youtube)}}>
                <YouTube style={{fontSize:option.fontSize}} />
            </IconButton>
            }
             {!links.instagram?<></>:<IconButton className={`grow ${option.iconClass}`} style={option.style} onClick={()=>{openInNewTab(links.instagram)}}> 
                <Instagram style={{fontSize:option.fontSize}}/>
            </IconButton>}
            {!links.twitter?<></>:<IconButton className={`grow ${option.iconClass}`} style={option.style} onClick={()=>{openInNewTab(links.twitter)}}>
                <Twitter style={{fontSize:option.fontSize}}/>
            </IconButton>}
            {!links.facebook?<></>:<IconButton className={`grow ${option.iconClass}`} style={option.style} onClick={()=>{openInNewTab(links.facebook)}}>
                <Facebook style={{fontSize:option.fontSize}}/>
            </IconButton>}
            </Row>
          </Column>
        </Row>
    }
    return (
        _buildSocials({
            alignment:props.alignment??3,
            iconClass:props.className??classes.iconBtn,
            fontSize:props.fontSize??"28px",
            style:props.style
            },
            {
                youtube:props.youtube,
                instagram:props.instagram,
                facebook:props.facebook,
                twitter:props.twitter,
            }
            )
    )}
export const SocialsGrid =((props)=>{
    const classes = useStyles();

    const _buildSocialsGrid = (option={spacing:3,xs:6, iconClass:classes.iconBtn, style:{}, fontSize:"28px"},
    links={youtube:undefined,instagram:undefined,facebook:undefined,twitter:undefined})=>{
      return <Grid container spacing={option.spacing}>
          {!links.youtube?<></>:<Grid xs={option.xs}>  
          <IconButton className={`grow  ${option.iconClass}`} style={option.style} onClick={()=>{openInNewTab(links.youtube)}}>
              <YouTube style={{fontSize:option.fontSize}} />
          </IconButton>
          </Grid>}
          {!links.instagram?<></>:<Grid xs={option.xs}>  
          <IconButton className={`grow  ${option.iconClass}`}  style={option.style} onClick={()=>{openInNewTab(links.instagram)}}>
          <Instagram style={{fontSize:option.fontSize}}/>
          </IconButton>
          </Grid>}
          {!links.twitter?<></>:<Grid xs={option.xs}>  
          <IconButton className={`grow  ${option.iconClass}`}  style={option.style} onClick={()=>{openInNewTab(links.twitter)}}>
          <Twitter style={{fontSize:option.fontSize}}/>
          </IconButton>
          </Grid>}
          {!links.facebook?<></>:<Grid xs={option.xs}>  
          <IconButton className={`grow  ${option.iconClass}`}  style={option.style} onClick={()=>{openInNewTab(links.facebook)}}>
          <Facebook style={{fontSize:option.fontSize}}/>
          </IconButton>
          </Grid>}
      </Grid>
  }
    return (
        _buildSocialsGrid({
            spacing:props.spacing??3,
            xs:props.xs??6,
            iconClass:props.className??classes.iconBtn,
            fontSize:props.fontSize??"28px",
            style:props.style
            },
            {
                youtube:props.youtube,
                instagram:props.instagram,
                facebook:props.facebook,
                twitter:props.twitter,
            }
            )
        
    )
})