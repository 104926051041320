import { Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { firestore } from "../config/firebase";
import FutureBuilder from "./_future_builder";
import React from "react";
export const StudioRoomName = (props)=>{
    return <>
        <FutureBuilder 
            future = {firestore.collection("StudioRooms").doc(props.id).get()}
            builder={(snap)=>{
             
                return <Typography {...props}>{`${snap.data().name}`}</Typography>}}
            errorChild={<p>..</p>}
            placeholder={<Typography>--</Typography>}
        />
    </>
}
StudioRoomName.propTypes = {
    id:PropTypes.string,
    style:PropTypes.object
}