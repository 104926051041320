import { createSlice } from '@reduxjs/toolkit'

export const sideDataSlice = createSlice({
    name: 'siteData',
    initialState: {
        value: {
            name: "Dearfxch TV",
            slogan: "Slogan here",
            email: "officiallyRuki@gmail.com",
            featuredCover: null,
            isVideo: true,
            cutout: null,
            showCutout: true,
            socials: {
                youtube: "/",
                facebook: "/",
                instagram: "/",
                twitter: "/",
            },
            live: false,

            liveResults: {
                "home": true, "about": true, "contact": true
            },
            showPage: {
                pageName: "Shows",
                enableCommingSoon: true,
                bigCards: true,
                featured: [
                    {
                        trailer: "https://s3.eu-west-3.amazonaws.com/dearfach.tv/media/1k3-ft-ksav-x-dbo-x-nikz-a92-official-music-video-dearfach-tv.mp4",
                        showTitle: "Shop Talk",
                        logo: "",
                        showDesc: ""
                    }
                ]
            },
            adminSiteLink: "localhost:3000 ",
            youtubeChannelName: "DEARFXCH TV",
            channelId: "UCVH1nmGSqkoSGMUsig1SIkw",
            uploadId: "UUVH1nmGSqkoSGMUsig1SIkw",
            copyright: "© 2020 DEARFXCH TV, ALL RIGHTS RESERVED",
            aboutPage: {
                title: "About",
                featuredCover: "https://s3.eu-west-3.amazonaws.com/dearfach.tv/media/1k3-ft-ksav-x-dbo-x-nikz-a92-official-music-video-dearfach-tv.mp4",
                isVideo: true,
                subtitle: "DEARFXCHTV",
                accolades:
                {
                    youtube: {
                        subscribers: 41100,
                        views: 11907048,
                        videos: 1200,
                        artists: 112

                    }
                },
                bio: "Welcome to Dearfach TV, the Home of Irish Urban Culture.\n\nOur Aim is to produce the best urban content, spanning Shows, Interviews, News, Music Videos and many other top quality programming.",
                showcase: [
                    {
                        image: "https://cdn2.thelineofbestfit.com/images/made/images/remote/https_cdn2.thelineofbestfit.com/media/2014/headie-one-drake-only-you-freestyle_1290_1011.jpg",
                        video: "https://www.youtube.com/watch?v=GbUQG-qgdtg&ab_channel=HeadieOne",
                        body: "",
                        caption: "Headie One"
                    },
                    {
                        image: "https://lh3.googleusercontent.com/proxy/HV_aYO2W9dvSvNeslIudtjFTy6D_UXjB9t0gYacihxSsFLRD04ekyTafolti344XeEf761jmnEO9dZWhO9quKAc7L7yWqxXM1VCPeMGjai_w8iFX4k27YOw4mdGPyA",
                        video: "",
                        body: "",
                        caption: "Dave"
                    },
                    {
                        image: "https://inews-prd-a-images.s3.eu-west-2.amazonaws.com/content/uploads/2019/12/Stormzy.jpg",
                        video: "",
                        body: "Michael Ebenezer Kwadjo Omari Owuo Jr. (born 26 July 1993), known professionally as Stormzy, is a British rapper, singer and songwriter. In 2014, he gained attention on the UK underground music scene through his Wicked Skengman series of freestyles over classic grime beats.[5][6]\n\nStormzy's \"Shut Up\", which was initially released as a freestyle on YouTube, became popular and peaked at number eight on the UK Singles Chart after he launched a campaign to reach Christmas number one.[7] Stormzy won Best Grime Act at the 2014 and 2015 MOBO Awards and was named as an artist to look out for in the BBC's Sound of 2015 list.[8][5] His debut album, Gang Signs & Prayer (2017), was the first grime album to reach number one on the UK Albums Chart and won British Album of the Year at the 2018 Brit Awards.[9] In 2019",
                        caption: "Stormzy"
                    },
                    {
                        image: "https://blog.ticketmaster.ie/wp-content/uploads/2019/07/jay1-blog.png",
                        video: "",
                        body: "",
                        caption: "Jay1"
                    },
                    {
                        image: "https://townsquare.media/site/812/files/2019/11/MS-BANKS-LEAD-SHOT1.jpg?w=980&q=75",
                        video: "",
                        body: "",
                        caption: "Ms Banks"
                    }
                ],
                team: [
                    {
                        name: "Junior Echeurewe",
                        image: "https://www.nme.com/wp-content/uploads/2019/02/davepress.jpg",
                        desc: "Co. Founder & CEO",
                        facebook: "/",
                        instagram: "/",
                        twitter: "/"
                    },
                    {
                        name: "Junior Echeurewe",
                        image: "https://crackmagazine.net/wp-content/uploads/2017/07/Dave-Parallax-2.jpg",
                        desc: "Co. Founder & CEO",
                        facebook: "/",
                        instagram: "/",
                        twitter: "/"
                    },
                    {
                        name: "Junior Echeurewe",
                        image: "https://i.guim.co.uk/img/media/0511ab9ac0ee1dc4f4ad76ae4c2a393d9208c170/216_164_2649_1590/master/2649.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=058cea43f3036b8fb547a4a609cc126e",
                        desc: "Co. Founder & CEO",
                        facebook: "/",
                        instagram: "/",
                        twitter: "/"
                    },
                    {
                        name: "Junior Echeurewe",
                        image: "",
                        desc: "Co. Founder & CEO",
                        facebook: "/",
                        instagram: "/",
                        twitter: "/"
                    }
                ]
            },
            contactPage: {
                title: "Contact Us",
                featuredCover: "",
                isVideo: false,
                mainEmail: "akintundegabriele@gmail.com",
                numbers: [
                    "0899603367",
                    "0899603367",
                    "0899603367",
                    "0899603367",
                    "0899603367",
                ],
                emails: [
                    "HollywoodJunz@gmail.com",
                    "HollywoodJunz@gmail.com",
                    "HollywoodJunz@gmail.com",
                    "HollywoodJunz@gmail.com",
                ],
                locations: [
                    "Dublin 321",
                    "Dublin 321"
                ],
                links: [
                    "csd.com"
                ]
            },
            releasesPage: {
                featured: [{
                    id: "GbUQG-qgdtg",
                    thumbnail: "GbUQG-qgdtg",
                    title: "",
                    description: "",
                }],
                searchOption: {
                    liveResults: false
                },
                maxVideoPerPage: 120,
            },


            storyPage: {
                imageLimit: 5,
                videoLimit: 1,
                maxPerPage: 30,
                sizeLimit: 1860940745,
                searchOption: {
                    liveResults: false
                },
                featured: [{
                    "storyId": "99087534742568960",
                    "userId": "24f6aa98-51c7-11eb-9964-a683e7936a4d",
                    "views": null,
                    "headline": "NEW LG ",
                    "publishedAt": 1611144373283,
                    "approved": null,
                    "tags": "assporn,tits,big,bbc,bbw,",
                    "likes": null,
                    "story": "{\"photo\":\"https://s3.eu-west-3.amazonaws.com/dearfach.tv/Story/NEW_LG_/images//29eec5b1a06f6f678245957907cb16af2db260d882734083105cc1a85d4085347.png\",\"headline\":\"NEW LG \",\"tags\":\"assporn,tits,big,bbc,bbw,\",\"titles\":[{\"id\":0,\"ref\":\"New face\"}],\"images\":[{\"id\":1,\"ref\":\"\",\"blob\":\"https://s3.eu-west-3.amazonaws.com/dearfach.tv/Story/NEW_LG_/images//ms-banks-snack-video-coldest-winter-ever-2.jpg\",\"file\":\"https://s3.eu-west-3.amazonaws.com/dearfach.tv/Story/NEW_LG_/images//ms-banks-snack-video-coldest-winter-ever-2.jpg\"}],\"paragraphs\":[{\"id\":2,\"ref\":\"plowpel\"}]}",
                    "approvedBy": null,
                    "photo": "https://s3.eu-west-3.amazonaws.com/dearfach.tv/Story/NEW_LG_/images//29eec5b1a06f6f678245957907cb16af2db260d882734083105cc1a85d4085347.png",
                    "isDTV": 1,
                    "author": "Ollie Peters"
                }]
            },
            forms: {
                sumbission: ""
            }
        },
    },
    reducers: {
        refreshSiteData: (state) => {
            // Redux Toolkit allows us to write "mutating" logic in reducers. It
            // doesn't actually mutate the state because it uses the Immer library,
            // which detects changes to a "draft state" and produces a brand new
            // immutable state based off those changes
            const serializedState = localStorage.getItem('dtv_state');
            console.log("refreshing");
            if (serializedState !== null) {
                state.value = JSON.parse(serializedState);
            }
            console.log(state.value)
        },
        updateSiteData: (state, action) => {
            state.value = action.payload
            //firebase_app.database().ref().child("SiteData").set(action.payload);
        },
    },
})

// Action creators are generated for each case reducer function
export const { refreshSiteData, updateSiteData } = sideDataSlice.actions

export default sideDataSlice.reducer