/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
import { alpha, GridList, GridListTile, IconButton, makeStyles, Typography, useTheme } from '@material-ui/core';
import { addDays, addMonths, endOfMonth, endOfWeek, format, isAfter, isSameDay, startOfMonth, startOfWeek, subMonths } from 'date-fns';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import '../bubble.css';
import '../calendar.css';
import Center from './Center';
import Column from './Column';
import Row from './Row';
import { useMediaQuery } from '../contexts/MediaQueryProvider';
import { ChevronLeftRounded, ChevronRightRounded } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
    },
    gridList: {
        width: "100%",
        height: 'auto',
        justifyContent:'center',
        alignItems:'right',
    },
}));


export const Calendar = (props) => {

    const theme = useTheme();
    const slideRef = useRef(null)
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(null);
    const [calendarMonths, updateCalendarMonths] = useState({});
    const { device, isLargeDevice } = useMediaQuery();
    const [months] = useState([
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ])
    const settings = {
        className: "slider variable-width",
        dots: false,
        infinite: true,
        arrows: isLargeDevice,
        centerMode: false,
        slidesToShow: 1,//Math.floor(window.innerWidth/350),
        slidesToScroll: 1,
        initialSlide: new Date().getMonth()
    };
    useEffect(() => {
        console.log(props.show)
        renderMonths();
       
    }, [selectedDate,currentMonth,isLargeDevice])

    const renderMonths= () => {
         let calendar = {};
        months.forEach((month) => {
            
            
           calendar[month] = _buildMonth(new Date(`${getMonthNum(month)+1} , 1 , ${new Date().getFullYear()}`));

        });
        updateCalendarMonths(calendar);

    }
    const _buildMonth = (month) => {
        
        const dateFormat = "MMMM yyyy";
        return <Center >
            <Row alignment="space-between" style={{ width: "100%", height: props.style?.height ?? "500px" }}>
                <Column style={{ width: "100%" }}>
                    { !isLargeDevice ? 
                    <Row style={{alignItems: "center"}} alignment="centered">
                        <IconButton onClick={() => slideRef.current.slickPrev()} style={{ color: theme.palette.text.primary }}><ChevronLeftRounded/></IconButton>
                        <motion.label style={{ fontSize: "5vw", fontWeight: "bold" }}>{format(month, dateFormat)}</motion.label>
                        <IconButton onClick={() => slideRef.current.slickNext()} style={{ color: theme.palette.text.primary }}><ChevronRightRounded/></IconButton>

                    </Row>
                    : <motion.label style={{ fontSize: "2vw", fontWeight: "bold" }}>{format(month, dateFormat)}</motion.label>}
                    <div style={{ height: "10px" }}></div>
                    <div className={classes.root}>
                        <>
                            {_buildDays(month)}</>
                    </div>
                    {_buildCells(month)}
                </Column>
                <div></div>
            </Row>
        </Center>
    }
    const _buildDays = (month) => {
        const dateFormat = "eee";

        let startDate = startOfWeek(month);

        return <GridList className={classes.gridList} cellHeight={'auto'} cols={7}>{[0, 1, 2, 3, 4, 5, 6,].map((i, key) => <GridListTile key={key}>
            <Typography style={{ margin: "8px 21px", textAlign: "right" }}>{format(addDays(startDate, i), dateFormat)}</Typography>
        </GridListTile>)}</GridList>
    }
    const isToday = (date) => {
        return isSameDay(date, new Date());
    }
    const _buildCells = (month) => {
        const monthStart = startOfMonth(month);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);
        const dateFormat = "d";
        const rows = [];
        let day = startDate;
        let formattedDate = "";

        while (day <= endDate) {
            rows.push(
                <GridList  className={classes.gridList} cellHeight={'auto'} cols={7}>{[0, 1, 2, 3, 4, 5, 6].map((i, key) => {
                    formattedDate = format(day, dateFormat);
                    const cloneDay = day;
                    day = addDays(day, 1);
                    return <GridListTile  key={key}>
                       <Column style={{
                            alignItems:'flex-end',
                            }}>
                       <motion.div  className='centered-col' whileTap={{ scale: "1.1" }} whileHover={{ scale: 1.1, color: theme.palette.primary.main, cursor:"pointer" }} onClick={() => {
                          
                            if (new Date(cloneDay).valueOf() > Date.now().valueOf())
                                onDateClick(new Date(cloneDay));

                        }} style={{
                            alignItems:'flex-end',
                            height: isLargeDevice ? 50 : 30,
                            width: isLargeDevice ? 50: 30,
                            margin:3,
                            background: (isToday(cloneDay) ? theme.palette.primary.main : ""),
                            // '& :hover': { background: alpha(theme.palette.background.paper, 0.4) },
                            border: isSameDay(selectedDate,cloneDay) ?
                             `1px ${props.style?.currentMonthColor ?? theme.palette.primary.main} dashed` :
                              (isToday(cloneDay) ?
                               `1px ${props.style?.currentMonthColor ?? theme.palette.secondary.main} solid` : ""),
                            borderRadius: "100%",

                        }}>
                            <Row style={{width: '100%', height:'100%', alignItems:'center'}} alignment="centered">
                                {props.events?.map((e, i) =>
                                    isSameDay(e.fromDate, cloneDay) ?
                                        <Column key={i} alignment="centered"><div style={{ height: "10px", width: "10px", borderRadius: "10em", background: "red" }}></div></Column> : <></>)}
                                <Typography align="center" style={{ margin: "8px 20px", color: alpha( isToday(cloneDay) ? "#000" : theme.palette.text.primary, 
                                     isToday(new Date(cloneDay)) || (isAfter(cloneDay, new Date())) ? 1 : 0.25) }}>{formattedDate}</Typography>
                            </Row>
                        </motion.div>
                       </Column> 

                    </GridListTile>
                })}</GridList>
            );
        }
        return <>{rows}</>;
    }

    const onDateClick = (day) => {
        
        
        
        setSelectedDate(new Date(day))

    }
    const nextMonth = () => {
        let month = addMonths(currentMonth, 1)
        if(currentMonth.getMonth() === 11){
            // month = new Date(currentMonth.getFullYear()+1,0,1)
            // setCurrentMonth(month)
            return;
        }
        
        
        slideRef.current.slickGoTo(new Date(month).getMonth())
        // setCurrentMonth(month)
        if (props.onMonthChange) {
            props.onMonthChange(month)
        }
    }
    const prevMonth = () => {
        let month = subMonths(currentMonth, 1);
        slideRef.current.slickGoTo(new Date(month).getMonth())
        //setCurrentMonth(month)
        if (props.onMonthChange) {
            props.onMonthChange(month)
        }
    }
    const onMonthClick = (month) => {
        
        let temp = new Date(currentMonth);
        temp.setMonth(month, new Date(currentMonth).getDate());
        setCurrentMonth(temp)
        slideRef.current.slickGoTo(temp.getMonth())
        if (props.onMonthChange) {
            props.onMonthChange(temp)
        }
    }
    const classes = useStyles();

    const getMonthNum = (month) => {
        switch (month) {
            case "January": return 0;
            case "February": return 1;
            case "March": return 2
            case "April": return 3
            case "May": return 4
            case "June": return 5
            case "July": return 6
            case "August": return 7
            case "September": return 8
            case "October": return 9
            case "November": return 10
            case "December": return 11
            default: return 0;
        }
    }
   
    console.log(months[currentMonth.getMonth()])
    return (
    
        <div style={{
            ...props.style ?? {
                height: props.style?.height ?? "600px",
                backgroundColor: theme.palette.background.paper,
            }
        }}>
            <Column style={{ width: "100%", height: "100%" }} alignment="space-between">
            <Row alignment={ isLargeDevice ? "space-between" : "center"} style={{ padding: isLargeDevice ? "20px 10vw":"20px 0px", width: "100%", height: props.style?.height ?? "500px" }}>
                { isLargeDevice ? <Column>
                    <motion.label style={{ fontSize: "5vw", fontWeight: "bold" }}>{new Date(currentMonth).getFullYear()}</motion.label>
                    <motion.label style={{ fontSize: "3vw", fontWeight: "200" }}>{"Events"}</motion.label>
                </Column>: <div></div>}
                <div style = {{width: isLargeDevice ? '70%':'100%'}}>
                    <Slider ref={slideRef} {...settings}  beforeChange={(cS,nS)=>{
                            
                            if(cS < nS)
                                onMonthClick(nS);
                            else 
                                onMonthClick(cS-1);
                      
                    }} onSwipe={(slide) => {
                    
                    if (slide === "left") {
                        nextMonth()
                    }
                    else {
                        prevMonth()
                    }
                }}>
                    {Object.keys(calendarMonths).map((month, i) => <div style={{ height: "100%" }} key={i}>
                        {calendarMonths[month]}
                    </div>

                    )}
                </Slider>
                </div>
                </Row>
                
               { device ==="xs" ? <></> : <Row style={{ padding: "0 20px", overflowY: 'auto' }} alignment="space-between">
                    {months.map((month, i) => {
                        return <Column key={i} style={{ width: "auto" }} >
                            <motion.label onClick={() => {
                                onMonthClick(getMonthNum(month))
                            }} style={{
                                margin: "10px",
                                fontFamily: props.style?.fontFamily ?? "Raleway",
                                fontSize: props.style?.monthFontSize ?? "16px",

                            }}>{months[new Date().getMonth()] === month ? <span style={{ color: props.style?.selectedMonthColor ?? theme.palette.primary.main, margin: "0 10px" }}>•</span> : <></>}{month}</motion.label>
                            <motion.div initial={{ width: "0" }} style={{
                                height: "3px",
                                borderRadius: "10px",
                                background: props.style?.selectedMonthColor ?? theme.palette.primary.main
                            }} animate={{
                                width:months[currentMonth.getMonth()] === month ? "100%" : "0"
                            }}></motion.div>
                        </Column>
                    })}
                </Row>}
            </Column>

        </div>
    )
};

Calendar.propTypes = {
    style: PropTypes.any,
    show: PropTypes.bool,
    events: PropTypes.array,
    onMonthChange: PropTypes.func,
    
}