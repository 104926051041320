/* eslint-disable react-hooks/exhaustive-deps */

import { Card, CardContent, CardMedia, Typography, IconButton, CardActions, useTheme, alpha, LinearProgress, withStyles, Tooltip } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { useEffect, useContext, useState } from 'react';
import ReactPlayer from 'react-player';
import Row from './Row';
import PropTypes from 'prop-types';
import Stack from './Stack';
import Center from './Center';

import { useNavigate } from 'react-router';
import '../bubble.css'
import { DynamicFeed, PlayArrow, VolumeMute, VolumeUp } from '@material-ui/icons';
import Column from './Column';
import { ShowContext } from '../contexts/ShowProvider';


const CustomLinearProgress = withStyles((theme) => ({
    root: {
        height: 3,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.light,
    },
}))(LinearProgress);


export const EpisodeCard = ({
    episodeId,
    timestamp,
    onClick,
    show,
    mobile,
    hoverPlay,
    style,
    width
}) => {

    const history = useNavigate();
    const [isHovered, setHovered] = useState(false);
    const theme = useTheme();
    const [play, setPlay] = useState(false);
    const [mute,] = useState(false);
    const [, setProgress] = useState(timestamp);
    const [episode, setEpisode] = useState(null);
    const {getEpisode} = useContext(ShowContext)
    const [cardDetails,] = useState({ width: width ?? (mobile ? "250px" : "300px"), height: mobile ? 200 : 150, mediaHeight: "100%", showVideo: false })
    useEffect(() => {
        getEpisode(episodeId).then((data) => {
            setEpisode(data);
        })

    }, [])

    let t;
    const startPlayCounter = () => {
        t = setTimeout(function () {
            if (isHovered) { setPlay(true) }
        }, 1000)
    }
    const stopPlaycounter = () => {
        setPlay(false);
        clearTimeout(t)
    }
    if(!episode)
        return <></>
    return (
        <Column style={style}>
            <motion.div onHoverStart=
                {() => {
                    console.log("enter");
                    setHovered(true);
                    startPlayCounter();
                    console.log(isHovered);
                    //   updateCardDetails({...cardDetails, height:380,mediaHeight:"70%",width:"350px"})
                }}
                onHoverEnd={() => {
                    stopPlaycounter(); setHovered(false);
                    //updateCardDetails({...cardDetails, height:200, mediaHeight:"90%",width:"300px"})
                }}>
                <Card style={{ height: cardDetails.height, width: cardDetails.width, paddingBottom: "10px" }}

                    onClick={() => {
                        if (onClick)
                            onClick();
                        else history(`/episode/w?id=${episode.episodeId}&time=${timestamp}`)
                    }}>
                      {<motion.div whileHover={{ scale: 1.06 }} style={{width:"100%", height:"100%"}}>
                       <CardMedia style={{ height: cardDetails.mediaHeight, position: "relative" }}>
                        {isHovered ? <Stack><div style={{ width: "100%", height: "100%" }}>
                            {
                                play && hoverPlay ?
                                    <ReactPlayer playsinline url={`${episode.link}`}
                                        width="100%"
                                        height="100%"
                                        canPlay="true"
                                        muted={mute}
                                        onProgress={(p) => { setProgress(p.playedSeconds) }}
                                        onReady={(p) => { p.seekTo(timestamp); setProgress(timestamp) }}
                                        playing={isHovered} /> : <img alt=""src={`${episode.thumbnail ? episode.thumbnail : show.showCover}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
                            }

                        </div>
                            <div style={{ width: "100%", height: "100%", background: "linear-gradient(0deg, black,transparent)" }}></div>
                            <motion.div whileHover={{ scale: 1.1 }} onClick={() => {
                               window.location.replace(`/episode/w?id=${episodeId}&time=${timestamp??0}`)
                            }} style={{ width: "100%", height: "100%" }} >
                                <Column alignment="centered" style={{ width: "100%", height: "100%" }}>
                                    <Row alignment="centered" style={{ width: "100%", height: "100%" }}>
                                        <Column alignment="centered" style={{ height: "100%", width: "auto" }}>
                                            <IconButton onClick={() => {
                                                window.location.replace(`/episode/w?id=${episodeId}&time=${timestamp??0}`)
                                            }}><div style={{ border: " 1px white solid", height: "40px", width: "40px", borderRadius: "100%" }}>
                                                    <Center>
                                                        <Row alignment="centered">
                                                            {<PlayArrow />}
                                                        </Row>
                                                    </Center>

                                                </div> </IconButton>
                                        </Column>

                                    </Row>
                                </Column>
                            </motion.div>
                        </Stack>

                            : <Stack>
                                <div style={{ width: "100%", height: "100%" }}>
                                    <img alt=""src={`${episode.thumbnail ? episode.thumbnail : show?.showCover??""}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
                                </div>
                                <Column alignment="end" style={{ width: "100%", height: "100%" }}>
                                    <div style={{ width: "100%" }}>
                                        <CustomLinearProgress variant={"determinate"} value={((timestamp) / episode.duration) * 100} />
                                    </div>
                                </Column>

                            </Stack>
                        }
                    </CardMedia> </motion.div>}
                </Card></motion.div>
            <Column style={{ padding: "10px 20px" }} alignment="start">

                <Typography variant="" align="left" style={{ fontWeight: "bold", fontSize: mobile ? "16px" : "18px" }} >{`${episode.title}`}</Typography>
                <Typography align="left" style={{ color: alpha(theme.palette.text.primary, 0.6) }} variant="caption" >{`Season ${episode.season_no}  •  Episode ${episode.episode_no}`}</Typography>
            </Column>
        </Column>
    )
};


export const LargeShowCard = (props) => {

    const history = useNavigate();
    const [isHovered, setHovered] = useState(false);
    const [play, setPlay] = useState(false);
    const [mute, setMute] = useState(false);
    const [cardDetails] = useState({ width: props.mobile ? "140px" : "300px", height: props.mobile ? 250 : 400, mediaHeight: "100%", showVideo: false })
    useEffect(() => {
        console.log(props.show)
    }, [])
    let t;
    const startPlayCounter = () => {
        t = setTimeout(function () {
             setPlay(true) 
        }, 1000)
    }
    const stopPlaycounter = () => {
        setPlay(false);
        clearTimeout(t)
    }
    return props.mobile ? (
        <motion.div  onHoverStart=
            {() => {
                console.log("enter");
                setHovered(true);
                //startPlayCounter();
                console.log(isHovered);

            }}
            onHoverEnd={() => {
                stopPlaycounter();
                setHovered(false);
                //updateCardDetails({...cardDetails, height:200, mediaHeight:"90%",width:"300px"})
            }} style={{ margin: "0 5px" }}>
            <Card variant='outlined' style={{ height: props.height ?? cardDetails.height, width:  props.width ?? cardDetails.width, paddingBottom: "10px",borderRadius:8 }}

                onClick={() => {
                    if (props.onClick)
                        props.onClick();
                    else history("/show/" + props.show.showId, { show: props.show })
                    // else{
                    //      history("/episode:"+props.episode.episodeId,{episode:props.episode,progress:props.timestamp??0})

                    // }
                }}>
                {<motion.div whileHover={{ scale: 1.06 }} style={{width:"100%", height:"100%"}}>
                    <CardMedia style={{ height: cardDetails.mediaHeight, position: "relative", borderRadius:12 }}>

                    {
                        <Stack>
                            <div style={{ width: "100%", height: "100%" }}>
                                <img alt=""src={`${props.show.showCover}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
                            </div>
                            {/* <div style={{ width: "100%", height: "100%" }}>
                                <img alt=""src={`${banner}`} width="110%" height="150%" style={{ objectFit: "cover", transform: "translateY(-120px)" }} />
                            </div> */}
                            <div style={{ width: "100%", height: "100%", background: "linear-gradient(0deg, black,transparent)" }}></div>
                            <Column alignment={"end"} style={{ width: "100%", height: "100%", padding: " 0 0 3px 0" }}>

                                <Row alignment={"centered"}>
                                    {props.show.logo ? <div style={{ width: "80px", height: "80px" }}>
                                        <img alt=""src={`${props.show.logo}`} width="100%" height="100%" style={{ objectFit: "contain" }} />
                                    </div> : <></>}
                                </Row>
                            </Column>

                        </Stack>
                    }
                </CardMedia>
                    </motion.div>}


                <CardContent style={{ width: "100%", position: "relative", overflow: "hidden" }}>
                    <Column>
                        {/* <Typography variant="h6" align="left">{episode.ep_title}</Typography> */}
                    </Column>
                </CardContent>
                <CardActions>
                </CardActions>
            </Card></motion.div>
    ) : (
        <motion.div onHoverStart=
            {() => {
                console.log("enter");
                setHovered(true);
                startPlayCounter();
                console.log(isHovered);

            }}
            onHoverEnd={() => {
                stopPlaycounter();
                setHovered(false);
                //updateCardDetails({...cardDetails, height:200, mediaHeight:"90%",width:"300px"})
            }} style={{ margin: "0 20px" }}>

            <Card  variant='outlined' style={{ height: props.height ?? cardDetails.height, width: cardDetails.width, paddingBottom: "10px",borderRadius:8 }}

                onClick={() => {
                    if (props.onClick)
                        props.onClick();
                    else history("/show/" + props.show.showId, { show: props.show })
                    // else{
                    //      history("/episode:"+props.episode.episodeId,{episode:props.episode,progress:props.timestamp??0})

                    // }
                }}>
                {<motion.div whileHover={{ scale: 1.06 }} style={{width:"100%", height:"100%"}}>
                    <CardMedia style={{ height: cardDetails.mediaHeight, position: "relative" }}>

                    {isHovered ? <Stack><div style={{ width: "100%", height: "100%" }}>
                        {
                            play && props.hoverPlay ?
                                <ReactPlayer playsinline url={`${props.show.trailer}`}
                                    width="100%"
                                    height="100%"
                                    canPlay="true"
                                    muted={mute}
                                    playing={isHovered} style={{ overflow:"hidden", objectFit: "cover", transform: `scale(3.5)` }}/> : <img alt=""src={`${props.show.showCover}`} width="100%" height="100%" style={{ overflow:"hidden", objectFit: "cover"}} />
                        }

                    </div>

                        <div style={{ width: "100%", height: "100%", background: "linear-gradient(0deg, black,transparent)" }}></div>

                        <motion.div initial={{ opacity: 0.1 }} animate={{ opacity: 1 }} transition={{ easings: "linear", duration: 0.2, type: "tween" }} style={{ width: "100%", height: "100%" }}>

                            <Column alignment={"end"} style={{ width: "100%", height: "100%", padding: " 0 0 20px 0" }}>

                                {/* <Row alignment={"centered"}>
                                    {props.show.logo ? <div style={{ width: "80px", height: "80px" }}>
                                        <img alt=""src={`${props.show.logo}`} width="100%" height="100%" style={{ objectFit: "contain" }} />
                                    </div> : <></>}
                                </Row> */}
                                <Row alignment="centered" style={{}}>
                                    {props.show.logo ? <div style={{ width: "100px", height: "100px" }}>
                                        <img alt=""src={`${props.show.logo}`} width="100%" height="100%" style={{ objectFit: "contain" }} />
                                    </div> : <></>}

                                    <Tooltip arrow style={{ height: "auto" }} title={`${mute ? 'unmute' : 'mute'}`}>
                                        <IconButton onClick={() => { setMute(!mute) }}><div style={{ border: " 1px white solid", height: "40px", width: "40px", borderRadius: "100%" }}>
                                            <Center>
                                                <Row alignment="centered">
                                                    {mute ? <VolumeMute /> : <VolumeUp />}
                                                </Row>
                                            </Center>

                                        </div> </IconButton>
                                    </Tooltip>
                                    {/* <Tooltip arrow title={`Watch`}>
                                                         <IconButton onClick={()=>{
                                                            history("/episode:"+props.episode.episodeId,{episode:props.episode,progress:props.timestamp??0})
                                                     }}><div style={{border:" 1px white solid", height:"40px", width:"40px", borderRadius:"100%"}}>
                                                    <Center>
                                                    <Row alignment="centered">
                                                    {<PlayArrow/>}
                                                    </Row> 
                                                    </Center>
                                                    
                                                    </div> </IconButton>
                                                   </Tooltip> */}
                                    <Tooltip arrow title={`More Episodes`}>
                                        <IconButton onClick={() => {
                                                history("/show/" + props.show.showId, { show: props.show })
                                        }}><div style={{ border: " 1px white solid", height: "40px", width: "40px", borderRadius: "100%" }}>
                                                <Center>
                                                    <Row alignment="centered">
                                                        {<DynamicFeed />}
                                                    </Row>
                                                </Center>

                                            </div> </IconButton>
                                    </Tooltip>

                                </Row>
                            </Column>

                        </motion.div>

                    </Stack> :
                        <Stack>
                            <div style={{ width: "100%", height: "100%" }}>
                                <img alt=""src={`${props.show.showCover}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
                            </div>
                            {/* <div style={{ width: "100%", height: "100%" }}>
                                <img alt=""src={`${banner}`} width="110%" height="160%" style={{ objectFit: "cover", transform: "translateY(-120px)" }} />
                            </div> */}
                            <div style={{ width: "100%", height: "100%", background: "linear-gradient(0deg, black,transparent)" }}></div>
                            <Column alignment={"end"} style={{ width: "100%", height: "100%", padding: " 0 0 3px 0" }}>

                                <Row alignment={"centered"}>
                                    {props.show.logo ? <div style={{ width: "120px", height: "120px" }}>
                                        <img alt=""src={`${props.show.logo}`} width="100%" height="100%" style={{ objectFit: "contain" }} />
                                    </div> : <></>}
                                </Row>
                            </Column>



                        </Stack>
                    }
                </CardMedia></motion.div>}
            </Card></motion.div>
    )
};
export default LargeShowCard;


export const ShowCard = (props) => {

    const history = useNavigate();
    const [isHovered, setHovered] = useState(false);
    const [play, setPlay] = useState(false);
    const [mute, setMute] = useState(false);
    const [cardDetails,] = useState({ width:  props.width ?? (props.mobile ? "140px" : "300px"), height: props.height ?? (props.mobile ? 250 : 400), mediaHeight: "100%", showVideo: false })
    useEffect(() => {
        console.log(props.show)
    }, [])

    let t;
    const startPlayCounter = () => {
        t = setTimeout(function () {
            if (isHovered) { setPlay(true) }
        }, 1000)
    }
    const stopPlaycounter = () => {
        setPlay(false);
        clearTimeout(t)
    }

    return (
        <motion.div onHoverStart=
            {() => {
                console.log("enter");
                setHovered(true);
                startPlayCounter();
                console.log(isHovered);

            }}
            onHoverEnd={() => {
                stopPlaycounter();
                setHovered(false);
                //updateCardDetails({...cardDetails, height:200, mediaHeight:"90%",width:"300px"})
            }} style={{ margin: "0 20px" }}>
            <Card variant='outlined' style={{ height: cardDetails.height, width: cardDetails.width, paddingBottom: "10px" }}

                onClick={() => {
                    if (props.onClick)
                        props.onClick();
                    else history("/show/" + props.show.showId, { show: props.show })
                    // else{
                    //      history("/episode:"+props.episode.episodeId,{episode:props.episode,progress:props.timestamp??0})

                    // }
                }}>
                 {<motion.div whileHover={{ scale: 1.06 }} style={{width:"100%", height:"100%"}}>
                 <CardMedia style={{ height: cardDetails.height, width: cardDetails.width, position: "relative" }}>

                    {isHovered ? <Stack><div style={{ width: "100%", height: "100%" }}>
                        {
                            play && props.hoverPlay ?
                                <ReactPlayer playsinline url={`${props.show.trailer}`}
                                    width="100%"
                                    height="100%"
                                    canPlay="true"
                                    muted={mute}
                                    playing={isHovered} style={{ transform: `scale(${'1.5'})`, overflow:'hidden' }} /> : <img alt=""src={`${props.show.showCover}`} width="100%" height="100%" style={{overflow:"hidden", objectFit: "cover",}} />
                        }

                    </div>

                        <div style={{ width: "100%", height: "100%", background: "linear-gradient(0deg, black,transparent)" }}></div>

                        <motion.div initial={{ opacity: 0.1 }} animate={{ opacity: 1 }} transition={{ easings: "linear", duration: 0.2, type: "tween" }} style={{ width: "100%", height: "100%" }}>

                            <Column alignment={"end"} style={{ width: "100%", height: "100%", padding: "0 30px" }}>

                                <Row alignment="space-between" style={{}}>
                                    {props.show.logo ? <div style={{ width: "150px", height: "150px" }}>
                                        <img alt=""src={`${props.show.logo}`} width="100%" height="100%" style={{ objectFit: "contain" }} />
                                    </div> : <></>}
                                    <Column alignment="centered"><Tooltip arrow style={{ height: "auto" }} title={`${mute ? 'unmute' : 'mute'}`}>
                                        <IconButton style={{ height: "40px", width: "40px" }} onClick={() => { setMute(!mute) }}><div style={{ border: " 1px white solid", padding: "10px", borderRadius: "100%" }}>
                                            <Center>
                                                <Row alignment="centered">
                                                    {mute ? <VolumeMute /> : <VolumeUp />}
                                                </Row>
                                            </Center>

                                        </div> </IconButton>
                                    </Tooltip>
                                    </Column>


                                </Row>
                            </Column>

                        </motion.div>

                    </Stack> :
                        <Stack>
                            <div style={{ width: "100%", height: "100%" }}>
                                <img alt=""src={`${props.show.showCover}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
                            </div>
                            <div style={{ width: "100%", height: "100%", background: "linear-gradient(0deg, black,transparent)" }}></div>
                            <Column alignment={"end"} style={{ width: "100%", height: "100%", padding: " 0 0 3px 0" }}>

                                <Row alignment={"centered"}>
                                    {props.show.logo ? <div style={{ width: props.mobile ? "50px" : "100px", height: props.mobile ? "50px" : "100px" }}>
                                        <img alt=""src={`${props.show.logo}`} width="100%" height="100%" style={{ objectFit: "contain" }} />
                                    </div> : <></>}
                                </Row>
                            </Column>
                            <Column alignment={"start"} style={{ width: "100%", height: "100%", padding: "10px 2px" }}>
                                <div>{props.customOverlay}
                                </div>
                            </Column>
                        </Stack>
                    }
                </CardMedia>   </motion.div>}
            </Card></motion.div>
    )
};

EpisodeCard.propTypes = {
    episodeId: PropTypes.string,
    seasonNo: PropTypes.number,
    epNo: PropTypes.number,
    timestamp: PropTypes.number,
    onClick: PropTypes.func,
    show: PropTypes.object,
    mobile: PropTypes.bool,
    hoverPlay: PropTypes.bool,
    style: PropTypes.object,
    width: PropTypes.string
}

LargeShowCard.propTypes = {
    show: PropTypes.func,
    episode: PropTypes.func,
    hoverPlay: PropTypes.bool,
    episodeId: PropTypes.string,
    currentWatching: PropTypes.any,
    mobile: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    onClick: PropTypes.func,
}
ShowCard.propTypes = {
    show: PropTypes.func,
    episode: PropTypes.func,
    hoverPlay: PropTypes.bool,
    episodeId: PropTypes.string,
    currentWatching: PropTypes.any,
    mobile: PropTypes.bool,
    height: PropTypes.string,
    width: PropTypes.string,
    onClick: PropTypes.func,
    customOverlay: PropTypes.any,
    story: PropTypes.object
}