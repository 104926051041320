import { Step, StepLabel, Stepper, makeStyles, withStyles } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Check } from "@material-ui/icons";
import Column from "./Column";
import {  getStatusIcon } from "../utils";
import StepConnector from '@material-ui/core/StepConnector';

const QontoConnector = withStyles({
alternativeLabel: {
  top: 10,
  left: 'calc(-50% + 16px)',
  right: 'calc(50% + 16px)',
},
active: {
  '& $line': {
    borderColor: '#784af4',
  },
},
completed: {
  '& $line': {
    borderColor: '#784af4',
  },
},
line: {
  borderColor: '#eaeaf0',
  borderTopWidth: 3,
  borderRadius: 1,
},
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
root: {
  color: '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
},
active: {
  color: '#784af4',
},
circle: {
  width: 8,
  height: 8,
  borderRadius: '50%',
  backgroundColor: 'currentColor',
},
completed: {
  color: '#784af4',
  zIndex: 1,
  fontSize: 18,
},
});

function QontoStepIcon(props) {
const classes = useQontoStepIconStyles();
const { active, completed,name} = props;

return (
  <div
    className={clsx(classes.root, {
      [classes.active]: active,
    })}
  >
    {completed ? <Check className={classes.completed} /> : getStatusIcon(name,active ? 'white':'grey',16)}
  </div>
);
}

QontoStepIcon.propTypes = {
/**
 * Whether this step is active.
 */
active: PropTypes.bool,
/**
 * Mark the step as completed. Is passed to child components.
 */
completed: PropTypes.bool,

icon: PropTypes.number,

name: PropTypes.string,
};


const Tracker = ({status,  processFilters} ) => {
    const [filteredProgression, setFilteredProgression] = useState(processFilters);
    useEffect(() => {
      setFilteredProgression(processFilters);

    }, [processFilters,status]);
    return   <Column>
    <Stepper alternativeLabel activeStep={filteredProgression.indexOf(status)} connector={<QontoConnector />}>
   {
        filteredProgression.map((step) => {
          
           const Modified = ({active, completed})=>{
               return <QontoStepIcon name={step} active={active} completed={completed}/>
           }
           Modified.propTypes = {
            active: PropTypes.bool,
            completed: PropTypes.bool,
          };
           return <Step key={step} active={status === step} completed={filteredProgression.indexOf(status) > filteredProgression.indexOf(step) || status === "published"} >
               <StepLabel StepIconComponent={Modified} style={{textTransform:'capitalize'}}>{step}</StepLabel>
           
           </Step>
        })
   }
  </Stepper>
   </Column>

}
export default Tracker;

Tracker.propTypes = {
    status: PropTypes.string,
    processFilters: PropTypes.array,
}