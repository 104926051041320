/* eslint-disable react-hooks/exhaustive-deps */

import React,{ createContext, useContext, useEffect, useState } from "react";
import { useDatabase } from "../hooks/useDatabase";
import { useProfile } from "../hooks/useProfile";
import { SnackbarContent, useSnackbar } from "notistack";
import { Card, CardActions, IconButton, ThemeProvider, Typography } from "@material-ui/core";
import { Colors, ThemeChangerContext } from "./ThemeChangerProvider";
import Row from "../components/Row";
import { ProfileAvatar } from "../components/profile/main/_profile_avatar";
import { TimeAgo } from "../utils";
import Column from "../components/Column";
import { CloseRounded } from "@material-ui/icons";
import PropTypes from 'prop-types';


export const NotificationSnackbar = React.forwardRef(function NS(props, ref){
    const {
        // You have access to notistack props, options 👇🏼
        message,
        title,
        sender,
        url,
        action,
        timestamp,
        maxWidth,
        key
    } = props;
    
  const {
    currentTheme,
  } = useContext(ThemeChangerContext);

    return <ThemeProvider theme={currentTheme}> <SnackbarContent ref={ref}>
      <Card variant="outlined" style={{ backgroundColor: Colors.primary, maxWidth:maxWidth??500, width:"100%",color: Colors.primaryText, padding: 10,}}>
        <CardActions onClick={()=>{
            window.open(url,"_blank");
        }}>
          <Row style={{width:"100%", alignItems: 'center'}} alignment="space-between">
            <Row style={{alignItems: 'center'}}>
                <ProfileAvatar avatarStyle={{
                    backgroundColor: "black"
                }} uid={sender} size={15} />
                <div style={{ width: 5 }} />
                <Column>
                    {timestamp&&<span style={{fontSize:10, marginBottom:2}}><TimeAgo short timestamp={timestamp}/></span>}
                    {title&&<Typography variant="body2" style={{ fontWeight: 'bold' }}>{title}</Typography>}
                    <Typography variant="caption">{message}</Typography>
                </Column>
            </Row>
            {<div style={{ width: 5 }} />}
            <Row style={{alignItems: 'center'}}>
               
                
                {action(key)}
            </Row>
          </Row>
        </CardActions>  
      </Card>
    </SnackbarContent>
    </ThemeProvider>
  })

  NotificationSnackbar.propTypes = {
    variant: PropTypes.string,
    message: PropTypes.string,
    title: PropTypes.string,
    sender: PropTypes.string,
    url: PropTypes.string,
    action: PropTypes.func,
    timestamp: PropTypes.number,
    maxWidth: PropTypes.number,
    key: PropTypes.string
}
export const NotificationContext = createContext(); 

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([]);
    const [profile, setProfile] = useState(null);
    const {enqueueSnackbar,closeSnackbar} = useSnackbar();
    const { retrieveProfileWithCallback } = useProfile();

    const {
        streamNotifications,
        openNotification
    } = useDatabase();
    useEffect(() => {
        let unsubscribe = null;
      
        setTimeout(() => {
            retrieveProfileWithCallback((profile) => {
                setProfile(profile);
                console.log("notifactions: subsribed")
                if(!profile?.uid)return;
                unsubscribe = streamNotifications(profile.uid, 
                     (notifications) => {
                        console.log(notifications)
                        setNotifications(notifications);
                        notifications.forEach((notification) => {
                            const options = {
                                variant: "notification",
                                hideIconVariant: true,
                                title:notification.title??`Notification | ${notification.type.toLowerCase()}`,
                                sender:notification.sentFrom,
                                timestamp:notification.timestamp,
                                url:notification.url,
                                style:{
                                    backgroundColor:Colors.primary,
                                    color:Colors.primaryText,
                                    fontWeight: 'bold'
                                },
                                preventDuplicate: true,
                                anchorOrigin: {
                                    vertical: "top",
                                    horizontal: "center",
                                },
                                onExited: () => {
                                    openNotification(profile.uid,notification.id,notification.type);
                                },
                                maxSnack: 3,
                                autoHideDuration:10000,
                                action: (key) => (
                                    <IconButton
                                        onClick={() => {
                                            openNotification(profile.uid,notification.id,notification.type);
                                            closeSnackbar(key);
                                        }}
                                    >
                                        <CloseRounded style={{color:Colors.primaryText}}/>
                                    </IconButton>
                                ),
                            };
                            enqueueSnackbar(notification.message, options);
                        });
                    
                });
            });
        },20000);
        return () => {
            console.log("unsubscribing");
            if (typeof unsubscribe === "function") {
                unsubscribe();
            }
            notifications.forEach((notification) => {
                    openNotification(profile.uid,notification.id,notification.type);
            });
        };
    },[])
    return <NotificationContext.Provider value={{}}>{children}</NotificationContext.Provider>
}

NotificationProvider.propTypes = {
    children: PropTypes.node
}