/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { parseUrlForQueryParams } from "../utils";
import { useNavigate } from "react-router";
import { useProfile } from "../hooks/useProfile";
import { retrieveSubmission } from "../logic/databaseHandler";
import Stack from "../components/Stack";
import { CircularProgress } from "@material-ui/core";
import Column from "../components/Column";
import { AppBarModule } from "../components/_app_bar_mod";
import VideoSubmissionCard from "../components/video_sub_card";
import { useMediaQuery } from "../contexts/MediaQueryProvider";

const SingleVideoSubmissionPage = () => {
    const params = parseUrlForQueryParams(window.location.href);
    const history = useNavigate();
    const [profile, setProfile] = useState({});
    const [loading, setLoading] = useState(false);
    const { retrieveProfileWithCallback } = useProfile();
    const [submission, setSubmission] = useState(null);
    const {isSmallDevice} = useMediaQuery();
    const validateUrl = () => {
        console.log(params)
        if (params && params.id) {
          return true;
        }
        return false;
      }
      useEffect(() => {
        if (!validateUrl()) {
            console.log("invalid url")
          history(-1);
        } else {
            console.log("valid url")
          setLoading(true);
          retrieveProfileWithCallback((profile) => {
            setProfile(profile);
            retrieveSubmission(params.id).then((submission) => {
                console.log(submission)
              setSubmission(submission);
              setLoading(false);
            })
        },(e)=>{
            console.log(e)
            history(-1);
        })
        }
      }, [profile.uid]);
    const BuildPage = ()=>{
        console.log(submission)
        return <>
            {submission && <VideoSubmissionCard data = {submission} />}
        </>
    }
      return <div>
      <Stack>
        <div style={{ background: "#040404", height: "100%", width: "100%" }}></div>
  
        {loading ? <Column style={{ width: '100%', height: '100%', alignItems: 'center' }}>
          <CircularProgress />
        </Column> : <div style={{ padding: isSmallDevice ? '60px 10px' : '10%', overflowY: "auto", height: "100vh", width: '100%', alignItems: 'center' }}>
          <BuildPage />
        </div>}
      </Stack>
      {<AppBarModule pageIndex={-1} elevation={1} showCart appbarBackground="transparent" />}
      </div>
      
}
export default SingleVideoSubmissionPage;