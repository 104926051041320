import React, { createContext, useEffect,useState, useContext } from "react";
import PropTypes from "prop-types";
export const MediaQueryContext = createContext();
export const useMediaQuery = ()=>
    useContext(MediaQueryContext);



export default function MediaQueryProvider(props) {

    
    const [device,setDevice] = useState("pc");

    const [deviceSize,] = useState({ "w": "100%", "h": "100%" })
    const [breakpoints,setBreakpoints] = useState({
        "xs":600,
        "md":1200,
        "pc":1440,
        "lg":1620,
        "xl":2080
    });
    const drawerWidth = device === "xs" ? "80vw" : 280;
    const isLargeDevice = device === "pc" || device === "lg" || device === "xl";
    const isSmallDevice = device === "xs";
    useEffect(()=>{
        updateDevice();
        //updateSize({ "w": window.innerWidth, "h": window.innerHeight - 6 })
        window.addEventListener("resize",updateDevice);
    })
    const updateDevice = ()=>{
       
        if(window.innerWidth<=breakpoints.xs)
        {
            setDevice('xs');
        }else if(window.innerWidth<=breakpoints.md&&window.innerWidth>breakpoints.xs){
            setDevice('md');
        }
        else if(window.innerWidth<=breakpoints.pc&&window.innerWidth>breakpoints.md){
            setDevice('pc');
        }
        else if(window.innerWidth<=breakpoints.lg&&window.innerWidth>breakpoints.pc){
            setDevice('lg');
        }
        else {
            setDevice('xl')
          }
       //   console.log(device)
    }
    return <MediaQueryContext.Provider value={{
        device,
        deviceSize,
        setBreakpoints,
        updateDevice,
        isLargeDevice,
        isSmallDevice,
        drawerWidth,
        isPC: device === "pc",
    }}>
        {props.children}
    </MediaQueryContext.Provider>
}

MediaQueryProvider.propTypes = {
    children: PropTypes.node
}