/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { refreshSiteData ,updateSiteData as update} from '../reducer/siteDataReducer';
import firebase_app from './firebase';
import PropTypes from 'prop-types'

export const useSiteData = ()=>useContext(SiteDataContext);
export const SiteDataContext =  createContext();

const SiteDataProvider = ((props)=>{
   
    useEffect(()=>{
        console.log("fetching data");
      
        const unsubscribe = streamSiteData((data)=>{
            console.log(data);
            dispatch(update(data))
        })
        return unsubscribe;
    }, [])
    const SiteData = useSelector(state => state.siteData.value);
    const dispatch = useDispatch();
    const streamSiteData = (callback)=>{
        firebase_app.database().ref().child("SiteData").on('value', (snapshot)=>{
            const data = snapshot.val();
            callback(data);
        })
    }
    const reloadsiteData = async () => {
        if (localStorage.getItem('dtv_state') === null) {
            var data = await firebase_app.database().ref().child("SiteData").once('value');
            const serializedState = JSON.stringify(data.val());
            localStorage.setItem('dtv_state', serializedState);
       
        }
        dispatch(refreshSiteData());
    }
const updateSiteData = async(data)=>{
    dispatch(updateSiteData(data))
}
    return <SiteDataContext.Provider value={
        {
            SiteData,
            reloadsiteData,
        updateSiteData}
    }>
        {props.children}
    </SiteDataContext.Provider>
})


export default SiteDataProvider;


SiteDataProvider.propTypes = {
    children: PropTypes.node
}