import axios from "axios";
import { RouteNames, baseUrl } from "../config/api";

export const OrganisationService = () => {
    const createOrganisation = async ({userId, name, alias, domain, website, social, org_type, members, locations, about}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.organisation}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                userId
            },
            data: {
                name,
                alias,
                domain,
                website,
                social,
                org_type,
                members,
                locations,
                about
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data);
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getOrganisation = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.organisation}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId: id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteOrganisation = async ({id}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.organisation}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId: id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateOrganisation = async(orgId,data) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.organisation}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId
            },
            data
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getOrganisations = async ({cursor, limit, offset}) => {
        console.log("GETTING ORGANISATIONS");
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.organisationList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                offset,
                limit
            }
        }
        try {
            const response = await axios(request);
            console.log(response.status);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data);
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchOrganisations = async ({query, cursor, offset, limit}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.organisationSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                offset,
                limit,
                query
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getOrganisationMembers = async ({orgId, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.organisationMembers}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const createOrganisationMember = async ({orgId, userId, roleId}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.organisationMembers}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                orgId,
                userId,
                roleId
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const inviteOrganisationMember = async ({orgId, email, roleId}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.organisationMembersInvite}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                orgId,
                email,
                roleId
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
    }
}
    const removeOrganisationMember = async ({orgId, userId}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.organisationMembers}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId,
                userId
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchOrganisationMembers = async ({orgId, query, cursor, offset, limit}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.organisationMembersSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId,
                query,
                cursor,
                offset,
                limit
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const changeOrganisationMemberRole = async ({orgId, userId, roleId}) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.organisationMemberRoleChange}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId,
                userId,
                roleId
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getOrganisationRoles = async ({orgId}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.organisationRoles}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getOrganisationRole = async ({orgId, roleId}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.organisationRole}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId,
                roleId
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getUserOrganisations = async ({userId}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.organisationUser}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data?.data?.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const createOrganisationRole = async ({orgId, title, roleId, description,permissions,maxMembers}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.organisationRoles}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {orgId},
            data: {
                title,
                roleId,
                description,
                permissions,
                maxMembers,
                membersCount: 0,
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data);
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteOrganisationRole = async ({orgId, roleId}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.organisationRoles}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId,
                roleId
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchOrganisationRoles = async ({orgId, query, cursor, offset, limit}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.organisationRolesSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId,
                query,
                cursor,
                offset,
                limit
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        createOrganisation,
        getOrganisation,
        deleteOrganisation,
        updateOrganisation,
        getOrganisations,
        searchOrganisations,
        getOrganisationMembers,
        inviteOrganisationMember,
        createOrganisationMember,
        removeOrganisationMember,
        searchOrganisationMembers,
        changeOrganisationMemberRole,
        getOrganisationRole,
        getOrganisationRoles,
        createOrganisationRole,
        deleteOrganisationRole,
        searchOrganisationRoles,
        getUserOrganisations
    }
}