import { Card,Typography, IconButton, useTheme, fade, Dialog, Tooltip } from '@material-ui/core';
import { motion } from 'framer-motion/dist/framer-motion';
import React, { useEffect,useState } from 'react';
import Row from './Row';
import '@fortawesome/fontawesome-svg-core/styles.css'
import Stack from './Stack';
import '../bubble.css'
import Column from './Column';
import PropTypes from 'prop-types';



export const PlaylistCard = (props)=>{
    const [showModal, setShowModal] = useState(false);
    const theme = useTheme();
    useEffect(()=>{
        getViews();
    },[])

    const getViews = async()=>{
        // const request = new Request(`https://youtube.googleapis.com/youtube/v3/videos?part=id%2C%20%20statistics&id=${props.youtubeData.id}&key=${GoogleApi.key}`,{method:"GET"})
        // const response = await fetch(request)
        // try{
        //     const result=  await response.json();
        //     console.log(result);
        //     setLikes(result.items[0].statistics.viewCount)
        // }catch(e){
        //     console.log(e)
            
        // }
    }
    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }
    return (
        <>
       <Card style={{width:props.style?.width??"100%",height:props.style?.height??"400px"}} onClick = {()=>{
        setShowModal(true);
        if(props.onClick)
         props.onClick()
       }}>
           <Row style={{position:"relative", height:"100%", width:"100%"}}>
               <div style={{position:"relative", height:"100%", width:"100%"}}>
                  <Stack style={{width:'100%'}}>
                   <div onClick={()=>{
                       setShowModal(true);
                   }} style={{height:"100%",width:"100%"}}><img alt="" width="100%" height="100%" style={{objectFit:"cover"}} src={`${props.playlist?.cover}`}/></div>
                   <div style={{height:"100%",width:"100%",background:`linear-gradient(0deg, ${fade(theme.palette.background.default,1)},transparent)`}}></div>
                   <Column style={{width:"100%",padding:"20px", height:"100%"}} alignment="end">
                       <Typography align="center" style={{fontWeight:"bold",fontSize:"20px"}}>{props.playlist?.name}</Typography>
                       <Row alignment="centered">
                           {Object.keys(props.playlist?.external_links)?.map((link,i)=>{
                              
                                   return link==="" ? <></>: <motion.div key={"link__"+i} whileHover={{scale:1.2,color:"green"}}>
                                      <Tooltip title={`${props.playlist?.external_links[link]}`}>
                                      <IconButton onClick={()=>{
                                          openInNewTab(props.playlist?.external_links[link])
                                      }}>
                                       <i style={{fontSize:"1.5rem"}} className={ link.includes("spotify") ?
                                       "fa fa-spotify": link.includes("soundcloud") ? 
                                       "fa fa-soundcloud" : link.includes("apple") ?
                                        "fa fa-apple" : link.includes("deezer") ? "fa fa-music" : "fa fa-youtube"}></i>
                                   </IconButton>
                                      </Tooltip>
                                       </motion.div>
                           })}
                       </Row>
                   </Column>
               </Stack> 
               </div>

           </Row>
       </Card>
       <Dialog maxWidth="xs"
      onClose={()=>{setShowModal(false)}}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
          <Column style={{width:"100%",height:"100%", padding:20, flexWrap: "wrap",
    alignContent: "center"}}>
            <div style={{height: 200, width:200}}>
                <img alt="" width="100%" height="100%" style={{borderRadius: "10px"}} src={props.playlist?.cover}/></div>
                <div style={{height: 10}}/>
                <Row alignment="centered">

              
            {props?.playlist?.links?.split(",").map((link,i)=>{
                return link==="" ? <></>:<motion.div key={"link__"+i} whileHover={{scale:1.2,color:"green"}}>
                   <IconButton onClick={()=>{
                       openInNewTab(link)
                   }}>
                    {<i style={{fontSize:"1.5rem"}} className={ link.includes("spotify") ?"fa fa-spotify": link.includes("soundcloud") ? "fa fa-soundcloud" : "fa fa-youtube"}></i>}
                </IconButton> </motion.div>
            })}  </Row>
            </Column>
       </Dialog>
     </>  
    )
};


PlaylistCard.propTypes = {
    playlist: PropTypes.object,
    story: PropTypes.object,
    style: PropTypes.object,
    onClick: PropTypes.func
}