/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Card, CardContent, CardMedia, Dialog, Divider, alpha, IconButton, LinearProgress, makeStyles, OutlinedInput, Snackbar, TextField, Typography, useTheme, withStyles, DialogActions, Icon, CircularProgress, Chip, Select, FormControl } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { CheckCircleOutline, Close, CloudUpload, Error } from '@material-ui/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Center from '../components/Center';
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { SiteDataContext } from '../config/SiteData';
import "../index.css";

import { motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import { UploadDiv } from '../components/UploadDiv';
import { AppBarModule } from '../components/_app_bar_mod';
import { sendFeedback } from '../config/emailer';
import { useStorage } from '../contexts/storage_handler';
import LoginRequired from '../components/_login_required';
import ValidUserRequired from '../components/_valid_user_required';
import { useProfile } from '../hooks/useProfile';
import { useDatabase } from '../hooks/useDatabase';
import shortUUID from 'short-uuid';
import { loadFromDevice, parseUrlForQueryParams } from '../utils';
import { MenuItem } from 'semantic-ui-react';
import { useMediaQuery } from '../contexts/MediaQueryProvider';
import { NotificationTypes, sendNotification } from '../logic/notificationHandler';
import { SubmissionService } from '../services/submissionService';
import { updateVideoSubmission } from '../logic/databaseHandler';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    }
  },

  gridList: {
    width: 500,
    height: 450,
  },
  iconBtn: {
    '&:hover': {
      color: "white"
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      color: "white"
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));
export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    width: '100%',
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#ffb03a',
  },
}))(LinearProgress);
///
const VideoSubmissionPage = () => {
  const {
    SiteData,
  } = useContext(SiteDataContext)
  const {retrieveProfileWithCallback} = useProfile();
  const theme = useTheme()
  const history = useNavigate();
  const classes = useStyles();
  const [uploadingVideo, showUploadingVideo] = useState(false);
  const [, setErrorProgress] = useState(null);
  const {device} = useMediaQuery();
  const [showModal, setShowModal] = useState(true);
  const [preview_body, updatePreviewBody] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [, setExitClick] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [profile, setProfile] = useState(null);
  const { uploading,
    progress,
    progressMsg,
    uploadMediaOperation } = useStorage();
  const [payload, setPayload] = useState({});

  const emailRef = useRef(null);
  const linkRef = useRef(null);
  const nameRef = useRef(null);
  const videoTitleRef = useRef(null);
  const descRef = useRef(null);
  const [validatingAccess, setValidatingAccess] = useState(true);
  const [invalidError, setInvalidError] = useState(null);
  const {validateBundle, getSubmission,loadPackages} = useDatabase();
  const { createVideoSubmission} = SubmissionService()
  const [editMode, setEditMode] = useState(false);
  const [packages, setPackages] = useState([]);
  const [bundle, setBundle] = useState(null);
  useEffect(() => {
   
    const fetchData = async () => {
      if (!profile) {
        retrieveProfileWithCallback((data) => {
          console.log(data);
          setProfile(data);
          if(emailRef.current && nameRef.current){
            emailRef.current.value = data?.email??'';
            nameRef.current.value = data?.fname??'';
          }
        },()=>{});
        console.log("profile", profile);
        const loadedPackages = await loadPackages({isStory: false});
        setPackages(loadedPackages);  
        console.log(loadedPackages)
        const query = parseUrlForQueryParams(window.location.href);
        setEditMode(query.edit);
        if(query.edit){
          const submission = await getSubmission(query.submissionId);
          videoTitleRef.current.value = submission.videoTitle;
          descRef.current.value = submission.videoDescription;
          linkRef.current.value = submission.link;
          console.log(submission);
          setPayload({...submission, title: submission.videoTitle, description: submission.videoDescription});
          
        }
      }
      updatePreviewBody(_buildConsentForm(JSON.parse(localStorage.getItem("dtv_state"))));
    }
    validatePageAccess().then((success)=>{
      if(success){
        fetchData();
      }
    }
    );

    setExitClick(false)

    return () => {

    }
  }, []);
  const validatePageAccess = async () => {
  
    setValidatingAccess(true);
    const split = window.location.href.split("/");
    const last = split[split.length - 1];
    console.log(last)
    const reg = /Video\?bundleId=(.)+/g;
    const reg2 = /Video\?bundleId=(.)+&edit=true&submissionId=(.)/g;
    const match = last.match(reg);
    if(last.match(reg2)){
      const query = parseUrlForQueryParams(window.location.href);
      console.log(query);  
      const success = await validateBundle({bundleId: query.bundleId, isStory: false});
      console.log(success);
      if (success) {
        console.log("success", success);
        setValidatingAccess(false);
        setBundle(success);
        return true;
    }
  }else if (match) {
      const bundleId = match[0].split("=")[1];
      console.log(bundleId);
      const success = await validateBundle({bundleId: bundleId, isStory: false});
      console.log(success);
      if (success) {
        console.log("success");
        setValidatingAccess(false);
        setBundle(success);
        return true;
      }
    }

    history('/package')
    setInvalidError("Invalid Access");
    setValidatingAccess(false);
    return false;
  }
  const _buildUploading = () => {
    return <Row alignment="centered" style={{ height: "400px", width: "100%", padding: "20px" }}>
      <Column alignment="centered" style={{ height: "100%" }}>
        <Typography>
          Uploading Video
        </Typography>

        <div style={{ width: "100%" }}><LinearProgress value={progress} color="primary" /></div>
        <Typography align="center" variant="caption">{`${progress >= 1 ? '': progressMsg}`}</Typography>
      </Column>
    </Row>
  }
  const submitForm = async () => {
    showUploadingVideo(true);

    try {
      console.log(payload);
      if ((payload.file)) {
      payload.link = await uploadMediaOperation(payload.file, `VIDEO_SUBMISSIONS/${shortUUID.generate()}`, () => {});
      payload.file = "";
      console.log(payload.link);

      }
      setErrorProgress(false);
      const success = await submitToDB();
      console.log(success,payload.link);
      if(success &&  typeof payload.link  === 'string'){
      console.log("success");
      await sendNotification({
        id: shortUUID.generate(),
        sendTo: 'dtv-admin',
        sentFrom: profile.uid,
        type: NotificationTypes.VIDEO_SUBMISSION,
        title: 'New Video Submission',
        message: `${profile.fname} ${profile.lname} has submitted a new video`,
        timestamp: Date.now(),
        seen: false,
        url: `/Submissions/video:${payload.sId}`
      })
      // await sendWithNodeMailer({
      //     from:`'${nameRef.current?.value??''}'`+`<${payload.email}>`,
      //     to:SiteData.email,
      //     subject:"DTV Video Submission",
      //     html:`<div><h1>New Submission from ${nameRef.current?.value??''}</h1><ul><li><strong>Name</strong>: ${nameRef.current?.value??''}</li><li><strong>Title</strong>: ${payload.title}</li><li><strong>Email</strong>: ${payload.email}</li><li><strong>Description</strong>: ${payload.description}</li><li><strong>DOWNLOAD LINK</strong>: <a href=${payload.link}>${payload.link}</a></li></ul></div>`
      // });
      sendFeedback(
        {
          from: `${emailRef.current?.value??''}`,
          to: SiteData.email,
          subject: "DTV Video Submission",
          body: `<div><h1>New Video Submission from ${nameRef.current?.value??''}</h1><ul><li><strong>Name</strong>: ${nameRef.current?.value??''}</li><li><strong>Title</strong>: ${payload.title}</li><li><strong>Email</strong>: ${emailRef.current?.value??''}</li><li><strong>Description</strong>: ${payload.description}</li><li><strong>DOWNLOAD LINK</strong>: <a href=${payload.link}>${payload.link}</a></li></ul></div>`
        },
        () => {
          setErrorProgress(false);
          setShowModal(true);

          showUploadingVideo(false);
          setNotificationMsg({ message: "Submission Sent!", timeout: 3000 });
          showNotification(true)
        },
        (e) => {
          setErrorProgress(true);
          setNotificationMsg({ message: "Error Occured! " + e, timeout: 3000 });
          showNotification(true)
        }
      )
      console.log("saved");
    }

    } catch (e) {
      setErrorProgress(true);
      setNotificationMsg({ message: "Error Occured! " + e, timeout: 3000 });
      showNotification(true)
    }


  }
  const submitToDB = async () => {
    let p = {
      sId:payload?.sId??`SUB${shortUUID.generate()}`,
      userId: profile.uid,
      name: profile.fname + " " + profile.lname,
      title: videoTitleRef.current?.value??'',
      videoTitle: videoTitleRef.current?.value??'',
      email: emailRef.current?.value??'',
      description: descRef.current?.value??'',
      videoDescription: descRef.current?.value??'',
      timestamp: new Date().valueOf(),
      link: linkRef.current?.value??'',
      paid: false,
      seen: false,
      published: false,
      bundleId: bundle.id,
      status: "pending",
      statusDates:{
        submitted: new Date().valueOf(),
        pending: Date.now(),
      }
    }
    console.log(p, editMode);
    if(editMode){
      const {status} =  await updateVideoSubmission(p);
      return status === 200;
    }
    const {status} =  await createVideoSubmission(p);
    return status === 200;
    
  }
  
  const validatefields  = () => {
    const validEmail = (/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/g).test(emailRef?.current?.value?.trim());
    if(!validEmail)
    setInvalidError("Invalid Email Supplied");
    const validLink = (/^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?(.)*$/g).test(linkRef?.current?.value?.trim());
    if(!validLink && !payload.file)
    setInvalidError("PLease supply a link or upload a file");
    console.log(nameRef?.current?.value);
    const validName = (/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g).test(nameRef?.current?.value?.trim());
    console.log(validName);
    if(!validName)
    setInvalidError("Invalid Name Supplied");
    return validEmail && (validLink || payload.file) && validName;
  }


  const _buildConsentForm = (data) => {
    return <><Card style={{ width: "100%", height: "100%", padding: "20px" }}>
      <CardContent>
        <Typography variant="h5"> Service Agreement {'&'} Consent </Typography>
      </CardContent>
      <CardContent style={{ maxHeight: 600, overflowY: "auto" }}>
        {data.forms.sumbission}
      </CardContent>
      <Divider />
    </Card>
    <DialogActions>
       <Row alignment="space-between" style={{ width: "100%" }}>
          <Button onClick={() => { history(-1) }}>I Disagree</Button>
          <Button variant="contained" color="primary" onClick={() => {
            setShowModal(false)
          }}>I Agree</Button>
        </Row>
    </DialogActions>
    </>
  }
  const _buildCompletion = () => {
    return <Card style={{ width: "300px", height: "300px" }}>
      <Center>
        {/* {errorProgress?<Column>
               <Row alignment="centered">
                     
                        <IconButton style={{color:"red"}}>
                            <CloseOutlined/>
                        </IconButton>
                   
              </Row>
              <Typography variant="overline" align="center">{errorMessage}</Typography>
              </Column>:*/} {
            uploadingVideo ? <Column style={{ width: "100%" }}>
          <Row style={{ margin: "20px 0" }} alignment="centered">
            <motion.div animate={{
              y: [10, 20, 10],
              //rotate: [0, 0, 270, 270, 0],
              style: {
                color: 'green',
              },
              borderRadius: ["20%", "20%", "50%", "50%", "20%"],
            }}
              transition={{
                duration: 2,
                ease: "easeInOut",
                times: [0, 0.2, 0.5],
                loop: Infinity,
                repeatDelay: 1
              }}>
              <IconButton style={{ color: theme.palette.primary.main, }}>
                <CloudUpload style={{ fontSize: "26px" }} />
              </IconButton>
            </motion.div>
          </Row>

          <Row alignment="centered" style={{ width: "100%" }}>
            <div style={{ width: "100%", padding: "0 10%" }}><BorderLinearProgress style={{ width: "100%" }} variant="determinate"
              value={progress}
            /></div></Row>
          <small style={{ marginLeft: "8px", textAlign: "center", fontSize: "12px", margin: "15px 0" }}>{progress}</small>

        </Column> :
          <Column>
            <Row alignment="centered">
              <motion.div animate={{
                scale: [1, 2, 2, 1, 1],
                //rotate: [0, 0, 270, 270, 0],
                style: {
                  color: 'green',
                },
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
              }}
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5, 0.8, 1],
                  loop: Infinity,
                  repeatDelay: 1
                }}>
                <IconButton style={{ color: theme.palette.primary.main }}>
                  <CheckCircleOutline />
                </IconButton>
              </motion.div>
            </Row>
            <Typography variant="overline" align="center">Success!</Typography>
            <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">Your Submission has been sent</Typography>
            <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">{`We'll back back to you within`} <span style={{ color: alpha(theme.palette.text.primary, 1) }}>3-5 workin days</span></Typography>
            <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">for any enquiry visit out <a href={"/contact"} style={{ textTransform: "underline", color: alpha(theme.palette.text.primary, 1) }}>contact page</a></Typography>
            <Row alignment="centered">
              <div style={ 
                {
                  marginTop:30,
                  width:80
                }
               }>
              <Button variant={"contained"} color="primary" onClick={() => {
                localStorage.setItem('dtv-profileCurrentTab',0)
              history('/'+profile.uid, {replace:true});
            } }>Done</Button>
              </div>
           </Row>
          </Column>
        }
      </Center>
    </Card>
  }
  const _buildPage = (() => {
    //...
    const innerBody = <>
      <FormControl style={{padding: 2, margin:device === 'xs' ? '10px' : '0 5px'}}>
             {packages && bundle ? <Select
             variant='outlined'
             style={{borderColor: 'transparent'}}
             defaultValue={bundle.id}
             displayEmpty={false}
             value={bundle.id} onChange={(event)=>{

              setBundle(packages.find(item=>item.id===event.target.value))
            }}>
              {packages.map((item, index) => {
                return <MenuItem style={{marginBottom:10}} key={index} value={item.id}>{ <Chip variant='outlined' color={item.id === bundle.id ? 'primary': 'default'} style={{margin:"0 10px"}} label={`${item?.title} Bundle | ${item?.price}`}/>}</MenuItem>
              })}
            </Select>:   <Chip variant='outlined' color={'primary'} style={{margin:"0 10px"}} label={`${bundle?.title} Bundle`}/>}</FormControl>
          
            
           
           
            {uploadingVideo ?
            <Row style={{ margin: "20px 0", alignItems:'center' }} alignment="centered">
              <motion.div animate={{
                y: [0, -10, 0],
                //rotate: [0, 0, 270, 270, 0],
                style: {
                  color: 'green',
                },
                borderRadius: ["20%", "20%", "50%", "50%", "20%"],
              }}
                transition={{
                  duration: 2,
                  ease: "easeInOut",
                  times: [0, 0.2, 0.5],
                  loop: Infinity,
                  repeatDelay: 1
                }}>
                <IconButton style={{ color: theme.palette.primary.main, }}>
                  <CloudUpload style={{ fontSize: "26px" }} />
                </IconButton>
              </motion.div>
            </Row>: <Button variant="contained" onClick={() => {
            if(validatefields()){
              updatePreviewBody(_buildCompletion());

            submitForm();
            }else{
              updatePreviewBody(<Column  alignment = "centered" style={{padding: 20, alignItems: "center"}}>
              <Row style = {{alignItems: "center"}} alignment = "centered">
                <Icon><Error/></Icon>
                <Typography>Please make sure all fields marked * are filled</Typography></Row>
              <Typography style={{margin: "10px 0"}}>{invalidError}</Typography>
              </Column>)
              setShowModal(true);

            }
            
          }} disabled={payload.description === "" || payload.title === "" || payload.name === "" || !(payload.file || payload.link)} color="primary" style={{ fontSize: "15px", height: "45px" }}>Submit Request</Button>
        }
          
    </>
    const body = <>
    <Typography variant="overline" align="center" style={{ fontSize: "22px" }}>Upload Video</Typography>
      {device === 'xs' ? <Column> {innerBody}
      </Column> : <Row style={{alignItems:'center'}}>
        {innerBody}
        </Row>}
      </>
    return <div style={{ height: "100%", width: "100%", padding: device === "pc" ? "8%" : "20% 12px" }}>
      <Column>
        {device === 'xs' ? <Column>
        {body}
        </Column> : <Row alignment="space-between">
        {body}
          </Row>}
        <Card style={{ margin: "20px 0", padding: device === "pc" ? "20px" : "5px" }}>
          <CardContent>
            <OutlinedInput
              fullWidth
              type="text"
              inputRef={nameRef}
              defaultValue={(profile?.fname??"") + " " +(profile?.lname??"")}
              onKeyUp={(e) => { setPayload({ ...payload, name: e.target.value }) }}
              required
              placeholder="Your Name"
              endAdornment={
                <Typography style={{ marginLeft: "10px", color: payload.name !== "" ? alpha(theme.palette.text.primary, 0.6) : "red" }}>{"Required"}</Typography>
              }
              startAdornment={
                <Typography style={{ marginRight: "10px", color: alpha(theme.palette.text.primary, 0.6) }}>{"Name  "}</Typography>
              }
            />
          </CardContent>

          <CardContent>
            <OutlinedInput
              fullWidth
              type="text"
              value={payload.title}
              inputRef={videoTitleRef}
              placeholder="Video Title"
              onChange={(e) => { setPayload({ ...payload, title: e.target.value }) }}
              required
              endAdornment={
                <Typography style={{ marginLeft: "10px", color: payload.title !== "" ? alpha(theme.palette.text.primary, 0.6) : "red" }}>{"Required"}</Typography>
              }
              startAdornment={
                <Typography style={{ marginRight: "10px", color: alpha(theme.palette.text.primary, 0.6) }}>{"V.Title  "}</Typography>
              }
            />
          </CardContent>
          <CardContent>
            <OutlinedInput
              fullWidth
              type="email"
              inputRef={emailRef}
              defaultValue={profile?.email??""}
              placeholder="Example@mail.com"
              required
              value={payload.email}
              onChange={(e) => { setPayload({ ...payload, email: e.target.value }) }}
              endAdornment={
                <Typography style={{ marginLeft: "10px", color: payload.email !== "" ? alpha(theme.palette.text.primary, 0.6) : "red" }}>{"Required"}</Typography>
              }
              startAdornment={
                <Typography style={{ marginRight: "10px", color: alpha(theme.palette.text.primary, 0.6) }}>{"Email  "}</Typography>
              }
            />
          </CardContent>
          <CardContent>
            <TextField
              fullWidth
              type="text"
              variant="outlined"
              multiline
              rows={7}
              label="Description"
              inputRef={descRef}
              required
              onChange={(e) => { setPayload({ ...payload, description: e.target.value }) }}
              value= {payload.description}
              endAdornment={
                <Typography style={{ marginLeft: "10px", color: payload.description !== "" ? alpha(theme.palette.text.primary, 0.6) : "red" }}>{"Required"}</Typography>
              }
              startAdornment={
                <Typography style={{ marginRight: "10px", color: alpha(theme.palette.text.primary, 0.6) }}>{"Email  "}</Typography>
              }
            />
          </CardContent>
        </Card>
        <Card style={{ padding: "20px" }}>
          <CardMedia style={{ height: device === "pc" ? "600px" : "300px" }}>
            <UploadDiv image={payload.link} video={true} onUpload={(file) => {
              loadFromDevice(file, (blob) => {
              console.log(blob, "blob");
                payload.file = {
                  file: blob,
                  blob: blob
                }

                setPayload({ ...payload });
              })
            }} />
          </CardMedia>
          <CardContent>
            <Typography style={{ marginBottom: "10px", color: alpha(theme.palette.text.primary, 0.2) }}>or Enter link to file</Typography>
            <OutlinedInput
              fullWidth
              inputRef={linkRef}
              value={payload.link}
              onChange={(e) => {
                setPayload({ ...payload, link: e.target.value })
              }}
              startAdornment={
                <Typography style={{ marginRight: "10px", color: alpha(theme.palette.text.primary, 0.6) }}>{"Link  "}</Typography>
              }
            />
          </CardContent>
        </Card>
      </Column>
    </div>
  });

  return (
  <div className={classes.root}>
    <CssBaseline />
    {
      validatingAccess ? <Column style={{ height: "100vh", width: "100vw", alignItems: "center", justifyContent: "center" }}>
        <CircularProgress />
      </Column> : <>
       <Stack>

      <div style={{ background: `${alpha(theme.palette.background.default, 1)} `, height: "100%", width: "100%" }}></div>
      {_buildPage()}


    </Stack>
    {<AppBarModule pageIndex={1} />}
    <Dialog
      maxWidth="md"
      onClose={() => { if(invalidError) setShowModal(false) }}

      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
        {preview_body}
    </Dialog>
    <Dialog open={uploading} fullWidth maxWidth={"md"}>
      {_buildUploading()}
    </Dialog>
    <LoginRequired/>
    <ValidUserRequired/>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification}
      autoHideDuration={notificationMsg.timeout}
      onClose={() => {
        showNotification(false)
      }}
      message={notificationMsg.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
      </>
    }
   
  </div>

  );

}
export default VideoSubmissionPage;