import React from "react";
import { useSiteData } from "../config/SiteData";
import ComingSoon from "../pages/coming_soon";
import PropTypes from 'prop-types';

const SiteDataContext = React.createContext();

const SiteDataProvider = ({ children }) => {
    const { SiteData } = useSiteData();

  return (
    <SiteDataContext.Provider value={SiteData}>
      {SiteData.live ? children : <ComingSoon SiteData={SiteData} />}
    </SiteDataContext.Provider>
  );
}
export default SiteDataProvider;


SiteDataProvider.propTypes = {
  children: PropTypes.node
}