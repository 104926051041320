/* eslint-disable react-hooks/exhaustive-deps */

import React,{ useEffect, useState } from "react";
import useServiceBooking from "../contexts/ServiceBookingProvider";
import Column from "./Column";
import { Button, CircularProgress, Dialog, DialogContent, Grid, IconButton, LinearProgress, Typography } from "@material-ui/core";
import Stack from "./Stack";
import { UploadDiv } from "./UploadDiv";
import { isValidLink, loadFromDevice } from "../utils";
import { Add, Build, CloseRounded } from "@material-ui/icons";
import { useStorage } from "../contexts/storage_handler";
import Row from "./Row";
import { useSnackbar } from "notistack";
import PropTypes from 'prop-types';


const AgentPortfolio = (({ agent:Agent, agentId,}) => {
    const [portfolio, setPortfolio] = useState(Agent?.portfolio??[]);
    const [uploadingMatrix, setUploadingMatrix] = useState({});
    const [uploading, setUploading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [agent, setAgent] = useState(Agent);
    const {getAgent,updateAgent} = useServiceBooking();
    const [, setLoading] = useState(false);
    const [serviceType, setServiceType] = useState(Agent?.serviceType??{});
    const {uploadMediaOperation} = useStorage();
    const {enqueueSnackbar} = useSnackbar();
    useEffect(() => {
        console.log(agentId)

        if(agentId){
            setLoading(true);
            getAgent(agentId).then((agent) => {
                console.log(agent);
                setAgent(agent);
                setLoading(false);
                setPortfolio(agent.portfolio??[]);
                setServiceType(agent.serviceType??{});
            })
        } 
    },[])
    const uploadImages = async (n)=>{
        if(n===0)return;
        if(portfolio[n-1].blob){
            console.log("uploading");
            setUploadingMatrix({...uploadingMatrix,[n-1]:true});
            portfolio[n-1].file = await uploadMediaOperation(portfolio[n-1],"portfolio",(p)=>setProgress(p));
            setUploadingMatrix({...uploadingMatrix,[n-1]:false});
            portfolio[n-1] =  portfolio[n-1].file;
            setPortfolio([...portfolio]) 
        }
                return uploadImages(n-1)
           
        }
        const savePortfolio = async ()=>{
            try{
                setUploading(true);
                    await uploadImages(portfolio?.length ?? agent?.portfolio?.length ?? 0);
            agent.portfolio = portfolio;
            setAgent({...agent});
            await updateAgent(agent.id,agent);
            setUploading(false);
            enqueueSnackbar("Portfolio Saved",{variant:"success"})
            }catch(e){
                console.log(e);
                enqueueSnackbar("Error Saving Portfolio",{variant:"error"})
            }
        

        }
    const BuildPortfolio = ()=>{
        console.log(portfolio);
      return <Grid container spacing={3} style={{padding: 30}}>
        <Grid container justifyContent="flex-end" xs={12} md={12}>
           <Row>
            <Button variant="contained" icon={<Add/>}  style={{textAlign:"left"}} size="small" color="primary" onClick={()=>{setPortfolio([...portfolio,""])}}>Add New Portfolio Entry</Button>
            <div style={{width:10}}/>
            <Button disabled={Object.keys(uploadingMatrix).length<1} variant="contained" icon={<Build/>}  style={{textAlign:"left"}} size="small" color="primary" onClick={()=>{savePortfolio()}}>Save Portfolio</Button>
           </Row>
        </Grid>
        <Grid xs={12}>
            <div style={{height:10}}/>
        </Grid>
        {(portfolio).map((p,i)=>(
          <Grid key={"portfolio"+i} xs={12} md={6}>
            <Column key = {p+"-"+i} style={{position:"relative",height: serviceType.style === 'VIDEO' || (/(.)+\\.(mp4|mov|mk4)/g).test(p) ? 200 : 300}}>
          <Stack>
             <div style={{opacity:!uploadingMatrix[i] ? 1 : 0.4,height:'100%'}}>
          
              
             <UploadDiv placeholder="" video = { serviceType.style === 'VIDEO' ||(/(.)+\\.(mp4|mov|mk4)/g).test(p) } image = { p?.blob??p } onUpload = {(file)=>{
                  setUploadingMatrix({...uploadingMatrix,[i]:false})
                   loadFromDevice(file, (blob)=>{
                        
                            if(isValidLink(blob)){
                              portfolio[i] = blob;
                            } 
                            else
                            {  portfolio[i] = {
                                file:file,
                                blob:blob
                    }}
                    setPortfolio([...portfolio]);
                })}
             } >
                {portfolio[i] === "" ?<Typography>Click to Upload</Typography> : !uploadingMatrix[i] ?<></> : <CircularProgress color="default"/>}
             </UploadDiv>
            
            </div>
            <div style={{
            pointerEvent:'none'
           }}>
                {!uploadingMatrix[i] ? <></> : <LinearProgress variant="determinate" value={progress} />}
            </div>
            
           <Row style={{
            pointerEvent:'none'
           }}>
           <IconButton style={{height: "",width:"",pointerEvents:"all"}} onClick={()=>{
                console.log("Hi")
              let newPortfolio = Array.from(portfolio);
              newPortfolio.splice(i,1);
              setPortfolio([...newPortfolio]);
             
            }}><CloseRounded/></IconButton>
           </Row>
           
          </Stack>
         
          </Column> 
         </Grid>
        ))}
       
      </Grid>
    
      }
      return <><BuildPortfolio/>  <Dialog open={uploading}>
        <DialogContent>
            <Column style={{width:"100%", alignItems:"center"}}>
                <CircularProgress size={20}/>
                <Typography variant="caption">Saving Portfolio</Typography>
            </Column>
        </DialogContent>
    </Dialog></>
})
export default AgentPortfolio;

AgentPortfolio.propTypes = {
    agent: PropTypes.object,
    agentId: PropTypes.string,
    style: PropTypes.object,
}