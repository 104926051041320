import { createSlice } from "@reduxjs/toolkit";

export const showProgressSlice = createSlice({
    name: "showsProgress",
    initialState: {
        shows: {}
    },
    reducers: {
        updateProgress: (state, action) => {
            state.shows[action.payload.id] = action.payload.progress;
        }
    }
});
export const { updateProgress } = showProgressSlice.actions;
export default showProgressSlice.reducer;
