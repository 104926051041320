/* eslint-disable react-hooks/exhaustive-deps */

import React,{useState, useEffect, useRef} from "react";
import { fade, makeStyles, useTheme} from '@material-ui/core/styles';
import { Grid, IconButton, InputBase, Link, Tooltip, Input, InputAdornment, Dialog, DialogContent, FormControl, Select, MenuItem, Chip } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import  CloseIcon from '@material-ui/icons/Close';
import Row  from "../components/Row";
import Column from "../components/Column";
import Center from "../components/Center";
import { UploadDiv } from "./UploadDiv";
import ReactPlayer from "react-player";
import { InsertLink,FormatBoldOutlined,
  FormatItalicOutlined,
  FormatColorTextOutlined,
  FormatAlignCenterOutlined,
  FormatAlignLeftOutlined,
  FormatUnderlinedOutlined,
  FormatStrikethroughOutlined,
  FormatAlignRightOutlined,
  MoreHorizRounded,
  ArrowDropUpRounded} from "@material-ui/icons";
import { SketchPicker } from "react-color";
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import { ChecklistRounded, FormatListBulletedRounded, FormatListNumberedRounded, FormatQuoteRounded, HMobiledataRounded, HighlightAltRounded, LinkRounded } from "@mui/icons-material";
import PropTypes from 'prop-types';

const drawerWidth = 240;

export const MarkdownTextField = ({viewMode, onChange, defaultValue, placeholder,style,onStyleUpdate,enableMinimize}) => {
  const [text, setText] = useState(defaultValue??"");
  const [modifiedStyle, updateModifiedStyle] = useState({});
  const [nextIndex, setNextIndex]=useState(1);
  const editorRef = useRef(null);
  const theme = useTheme();
  const styles = {
    textarea: {
      width: "100%",
      fontSize:style?.fontSize??"18px",
      lineHeight:style?.lineHeight??1.5,
      borderColor:"transparent",
      fontFamily:style?.fontFamily??"Arial",
      color:style?.color??"#ffffff",
      textAlign:style?.textAlign??"left",
      textDecoration:style?.textDecoration??"none",
      fontStyle:style?.fontStyle??"normal",
      fontWeight:style?.fontWeight??"normal",
    },
    button: {
      marginRight: 5
    }
  };
  const handleTyping = (evt) => {
    let word = evt.target.value;
    onChange(word);
    setText(word);
  };
  const modifierDict = {
    bold: {
      tag: "strong",
      name: "**"
    },

    italic: {
      tag: "i",
      name: "*"
    },

    heading: {
      tag: "i",
      name: "###"
    },

    underline: {
      tag: "i",
      name: "__"
    },

    strikethrough: {
      tag: "i",
      name: "~~"
    },

    tasklist: {
      tag: "i",
      name: "- [] "
    },

    list: {
      tag: "i",
      name: "-"
    },

    listnumber: {
      tag: "i",
      name: "1. "
    },

    link: {
      tag: "i",
      name: "[](url)"
    },

    blockquote: {
      tag: "i",
      name: ">>"
    },

    highlight: {
      tag: "i",
      name: "=="
    },

    color: {
      tag: "i",
      name: "([color])(...)"
    },



  };
  const handleModifierClick = (tag,color) => {
    var start = editorRef.current.selectionStart;
    var end = editorRef.current.selectionEnd;
    const selectedText = text.substring(Math.max(start, 0), Math.max(end, 0));
    let modifiedText = "";
    if(tag==="heading" || tag==="blockquote" || tag==="highlight"){
      modifiedText = `${modifierDict[tag].name} ${selectedText}`;
    }else if(tag==="listnumber" || tag === "list" || tag === "tasklist"){
       if(tag==="listnumber"){
        //!! fix this
        modifiedText = `\n${nextIndex}. ${selectedText}`;
        setNextIndex(nextIndex+1);
      }else{
          modifiedText = `\n${modifierDict[tag].name} ${selectedText}`;
      }
    }
    else if(tag === "link"){
      modifiedText = `[${selectedText}](url)`;
    }
    else if(tag === "color"){
      modifiedText = `([${color??theme.palette.text.primary}])(${selectedText})`;
    }else{
      modifiedText = `${modifierDict[tag].name}${selectedText}${modifierDict[tag].name}`;
    }
    
    console.log(selectedText, start, end);

    if (selectedText) {
      const startIndex = start;
      const endIndex = end;
      const updatedText = text.replaceByIndex(
        startIndex,
        endIndex,
        modifiedText
      );
      setText(updatedText);
      onChange(updatedText);
    } else {
      const cursorPosition = editorRef.current.selectionStart;
      const updatedText =
        text.substring(0, cursorPosition) +
        modifiedText +
        text.substring(cursorPosition);
      setText(updatedText);
      onChange(updatedText);
      editorRef.current.setSelectionRange(text.length + 2, text.length + 2);
      editorRef.current.focus();
    }
  };
  const getNextListPrefix = (currentLine) => {
    if (currentLine.startsWith('- ')) {
      return '- ';
    } else if (currentLine.match(/^\d+\.\s/)) {
      const currentIndex = parseInt(currentLine.match(/^\d+/)[0]);
      setNextIndex(currentIndex + 1);
      return (currentIndex + 1) + '. ';
    }
    return '';
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      const textarea = event.target;
      const { selectionStart, value } = textarea;
      const lines = value.split('\n');
      const currentLineIndex = value.substr(0, selectionStart).split('\n').length - 1;
      const currentLine = lines[currentLineIndex];
      console.log(currentLine);
      const trailing = currentLine.match((/^ \s*/g))??[];
      if(currentLine.startsWith((trailing.length > 0 ? trailing[0] : '')+'- []') || currentLine.startsWith((trailing.length > 0 ? trailing[0] : '')+'- [x]')){
        const updatedText = value.substring(0, selectionStart) + '\n'+(trailing.length > 0 ? trailing[0] : '')+'- [] ' + value.substring(selectionStart);
        setText(updatedText);
        event.preventDefault();
      }
      else if (currentLine.startsWith('- ') && currentLine.match(/^\d+\.\s/)) {
        const updatedText = value.substring(0, selectionStart) + '\n' + getNextListPrefix(currentLine) + ' ' + value.substring(selectionStart);
        setText(updatedText);
        event.preventDefault();
      }
      else if (currentLine.startsWith((trailing.length > 0 ? trailing[0] : '')+'- ')) {
        const updatedText = value.substring(0, selectionStart) + '\n'+(trailing.length > 0 ? trailing[0] : '')+'- ' + value.substring(selectionStart);
        setText(updatedText);
        event.preventDefault();
      }
    }
    const { key, target, ctrlKey,  } = event;
    const { selectionStart, selectionEnd, value } = target;
    const selectedText = value.substring(selectionStart, selectionEnd);
    let updatedText;
  if (ctrlKey) {
      switch (key.toLowerCase()) {
        case 'b':
          if (selectedText.length > 0) {
            updatedText = value.substring(0, selectionStart) + `**${selectedText}**` + value.substring(selectionEnd);
          } else {
            updatedText = value.substring(0, selectionStart) + '****' + value.substring(selectionEnd);
          }
          break;
        case 'i':
          if (selectedText.length > 0) {
            updatedText = value.substring(0, selectionStart) + `*${selectedText}*` + value.substring(selectionEnd);
          } else {
            updatedText = value.substring(0, selectionStart) + '**' + value.substring(selectionEnd);
          }
          break;
        case 'h':
          if (selectedText.length > 0) {
            updatedText = value.substring(0, selectionStart) + `==${selectedText}==` + value.substring(selectionEnd);
          } else {
            updatedText = value.substring(0, selectionStart) + '====' + value.substring(selectionEnd);
          }
          break;
        case 'y':
          if (selectedText.length > 0) {
            updatedText = value.substring(0, selectionStart) + `~~${selectedText}~~` + value.substring(selectionEnd);
          } else {
            updatedText = value.substring(0, selectionStart) + '~~~~' + value.substring(selectionEnd);
          }
          break;
        case 'u':
          if (selectedText.length > 0) {
            updatedText = value.substring(0, selectionStart) + `__${selectedText}__` + value.substring(selectionEnd);
          } else {
            updatedText = value.substring(0, selectionStart) + '____' + value.substring(selectionEnd);
          }
          break;
          case 'l':
            if (selectedText.length > 0) {
              updatedText = value.substring(0, selectionStart) +'['+selectedText+ '](url)' + value.substring(selectionEnd);
              target.setSelectionRange(updatedText.indexOf('url'), updatedText.indexOf('url')+3);
            }else{
              updatedText = value.substring(0, selectionStart) + '[](url)' + value.substring(selectionEnd);
            }
            break;
            
          case 'k':
            if (selectedText.length > 0) {
              updatedText = value.substring(0, selectionStart) +'([color])('+selectedText+')' + value.substring(selectionEnd);
              target.setSelectionRange(updatedText.indexOf('color'), updatedText.indexOf('color')+5);
            }else{
              updatedText = value.substring(0, selectionStart) + '([color])(...)' + value.substring(selectionEnd);
            }
            break;
            default: break;
      }
    }

    if (updatedText) {
      setText(updatedText);  
      onChange(updatedText);
      event.preventDefault();
    }
    
  
  };
  const applyModifier = () => {
    let formattedText = text;
    
    
    // Apply task list formatting
    formattedText = formattedText.replace(/-\s\[x\](.*?)\s((.^\n)*)/g, '<input class="MuiCheckbox-root" type="checkbox" checked disabled style="background: transparent;" />$1');
    formattedText = formattedText.replace(/-\s\[(.*?)\]\s((.^\n)*)/g, '<input class="MuiCheckbox-root" type="checkbox" disabled />$2');
    // Apply list formatting
    formattedText = formattedText.replace(/(-\s(.*))/g, "<li style='list-style-type: circle;'>$2</li>");

     // Apply blockquote formatting
     formattedText = formattedText.replace(/>>(.*)/g, '<blockquote style="color:#C1C7D0; border-left:0.2em solid #757D87; padding: 0 1em">$1</blockquote>');
    // Apply link formatting
    formattedText = formattedText.replace(/\[(.*?)\]\((.*?)\)/g, '<a style="color:antiquewhite;" href="$2">$1</a>');
   
     // Apply highlight formatting
     formattedText = formattedText.replace(/==(.*?)==/g, '<mark>$1</mark>');
      // Apply strike-through formatting
    formattedText = formattedText.replace(/~~(.*?)~~/g, '<s>$1</s>');
    // Convert nested ordered list notation to HTML
    formattedText = formattedText.replace(/(\d+\.(.*?)\n)+/g, (match) => {
      const lines = match.trim().split('\n');
      let nestedList = '';

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const listContent = line.substring(line.indexOf('.') + 1).trim();
        nestedList += `<li style="list-style-type: decimal;">${listContent}</li>`;
      }

      return `<ol>${nestedList}</ol>`;
    });
    
   // Apply header 1 formatting
   formattedText = formattedText.replace(/### (.*)/g, '<h4 style="font-size: 1.55em;">$1</h4>');

    // Apply bold formatting
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

    // Apply italic formatting
    formattedText = formattedText.replace(/\*(.*?)\*/g, "<i>$1</i>");

    // Apply color formatting
    formattedText = formattedText.replace(/\(\[(.*?)\]\)\((.*?)\)/g, '<span style="color:$1">$2</span>');

    // Apply underline formatting
    formattedText = formattedText.replace(/__(.*?)__/g, "<u>$1</u>");
    formattedText = formattedText.replace(/\n/g,"</br>");
    //console.log(formattedText);
    
    return { __html: formattedText };
  };
  return (
    <Column style={{width:"100%"}}>
     
     
      {!viewMode ? <InputBase
        fullWidth
        inputRef={editorRef}
        multiline
        defaultValue={defaultValue}
        value={text}
        maxRows={60}
        placeholder={placeholder??"Type here..."}
        onChange={handleTyping}
        onKeyDown={handleKeyDown}
        style={{ ...styles.textarea, ...modifiedStyle }}
      />: <p dangerouslySetInnerHTML={applyModifier()} style={{...styles.textarea,...modifiedStyle, wordWrap: "break-word"}}></p>}
     {viewMode ? <></> : <TextEditorPanel 
     enableMinimize={enableMinimize}
     onFontSizeChange={
      (size)=>{
        updateModifiedStyle(style => ({ ...style, fontSize: size }));
        if(onStyleUpdate){onStyleUpdate({fontSize: size})}
      }
     }
     onTextAlignChange={
      (align)=>{
        updateModifiedStyle(style => ({ ...style, textAlign: align }));
        if(onStyleUpdate){onStyleUpdate({textAlign: align})}
      }
     }
     onFontFamilyChange={
      (family)=>{
       updateModifiedStyle(style => ({ ...style, fontFamily: family })); 
        if(onStyleUpdate){onStyleUpdate({fontFamily: family})}
      }
     }
     onColorChange={(color)=>handleModifierClick("color", color)}
      onTextWeightChange={()=>{
        handleModifierClick("bold")
      }}
      onTextStyleChange={()=>{
        handleModifierClick("italic")
      }}
      onTextUnderlineChange={()=>{
        handleModifierClick("underline")
      }}
      onTextStrikeThroughChange={()=>{
        handleModifierClick("strikethrough")
      }}
      onTextHighlightChange={()=>{
        handleModifierClick("highlight")
      }}
      onTextLinkChange={()=>{
        handleModifierClick("link")
      }}
      onTextBlockQuoteChange={()=>{
        handleModifierClick("blockquote")
      }}
      onTextBulletListChange={()=>{
        handleModifierClick("list")
      }}
      onTextNumberListChange={()=>{
        handleModifierClick("listnumber")
      }}
      onTextTaskListChange={()=>{
        handleModifierClick("tasklist")
      }}
      onTextHeaderChange={()=>{
        handleModifierClick("heading")
      }}
      
     />}
    </Column>
  );
};
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      gridList: {
        width: 500,
        height: 450,
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
      toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
     
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background:"linear-gradient(45deg, black, #d09612bd);",
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
      menuButton: {
        marginRight: 36,
        color:"white"
      },
      menuButtonHidden: {
        display: 'none',
      },
      title: {
        flexGrow: 1,
      },
      drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
     list:{
         height:'100%'
     },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      grow:{},
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.text.primary,
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
      fixedHeight: {
        height: 240,
      },
  }));
 
  const defaultParagrphStyle = {
    textAlign:"left",
    textDecoration:"none",
    fontStyle:"normal",
    fontWeight:"normal",
    color:"#ffffff",
    fontFamily:"Arial",
    fontSize:18
  }
  const defaultTitleStyle = {
    textAlign:"left",
    textDecoration:"none",
    fontStyle:"normal",
    fontWeight:"bold",
    color:"#ffffff",
    fontFamily:"Arial",
    fontSize:24
  }
  const defaultMediaStyle = {
    height:300,
    width:300,
    fit:"contain",
    fitPosition:"center",
  }



export const  ParagraphModule  = ((props)=>{
    const [deleteParagragh, setDelete] =  useState(false);
    const [paragragh, setParagraph] = useState(props.paragraph);
    const [, setShowStyleEditor] = useState(false);
    const [previewMode, setPreviewMode] = useState(props.mode==="view");

    const updateParagraphData = ()=>{
            setParagraph({ ...paragragh });
            props.updateParagraph(paragragh);
            
          }
    useEffect(()=>{
      
        if(!(props.paragraph?.style)){
          
          paragragh.style= defaultParagrphStyle
            setParagraph({...paragragh})
        }
    },[]);
    
    
    return <AnimatePresence>

   <motion.div 
   style={{width:'100%', color:deleteParagragh?"red":paragragh?.style?.color}}
   onMouseLeave={()=>{
    if(props.mode!=="view")
    setShowStyleEditor(false)
  }}
   onMouseEnter={()=>{
    if(props.mode!=="view")
    setShowStyleEditor(true);
   }}>
    <Column style={{width:"100%",padding:"0 20px", margin:"10px 0"}}>
      {props.mode==="view" ? <></> : <Row style={{width:"100%"}}>
        <Chip
          onClick={()=>{setPreviewMode(false)}}
          color={!previewMode?"primary":"default"}
          variant={previewMode?"outlined":"default"}
          style={{backgroundColor: previewMode?'transparent':'#faa307'}}
          label={"Write"}/>
          <div style={{width: 10}}/>
        <Chip
          onClick={()=>{setPreviewMode(true)}}
          color={previewMode?"primary":"default"}
          variant={!previewMode?"outlined":"default"}
          style={{backgroundColor: !previewMode?'transparent':'#faa307'}}
          label={"Preview"}/>
      </Row> }
     
    <Row style={{width:"100%"}}>
          {props.mode==="view"?<></>:<IconButton  style={{width:"40px", height:"40px"}} onClick={()=>{
            setDelete(true);
              if(props.onDelete)
              props.onDelete();
              }}><CloseIcon/></IconButton>}
            
         <MarkdownTextField 
              enableMinimize={true}
              viewMode={previewMode} 
              placeholder={"Paragraph"} 
              onStyleUpdate={(style)=>{
                paragragh.style=style;
                updateParagraphData();
              }}
              onChange={previewMode?()=>{
                console.log("preview mode....")
              }:(value)=>{
                console.log(value)
                paragragh.ref= value;
                props.updateParagraph(paragragh);
               // updateStory({...newStory})
            }}
              defaultValue={props.mode==="view"||props.mode==="edit"?(paragragh?.ref??""):""}/>
    </Row>
    </Column></motion.div>
    </AnimatePresence>
})
const FontList = [
  "Arial",
  "Times New Roman",
  "Courier New",
  "Libre Baskerville",
  "Helvetica",
  "Comic Sans MS",
  "Raleway",
  "Poppins",
  "Ubuntu",
  "Roboto",
  "Inter",
  "Inconsolata",
  "Bitter",
  "Pacifico",
  "Caveat",
  "Lustria"

]

export const variants = {
  show: {
    opacity: 1,
    x: 0,
    transition: {
      ease: "easeOut",
      duration: 0.3
    }
  },
  hide: {
    x: -10,
    opacity: 0
  }
};

export const TextEditorPanel = (({
  enableMinimize,
  _textAlign,
  _textStyle,
  _textWeight,
  _color,
  _fontFamily,
  _fontSize,
  onTextAlignChange,
  onTextStyleChange,
  onTextWeightChange,
  onColorChange,
  onFontFamilyChange,
  onFontSizeChange,
  onTextHeaderChange,
  onTextStrikeThroughChange,
  onTextUnderlineChange,
  onTextHighlightChange,
  onTextLinkChange,
  onTextBlockQuoteChange,
  onTextTaskListChange,
  onTextBulletListChange,
  onTextNumberListChange

})=>{
  const theme = useTheme();
  const styles = {
    unselected:{
      border:`1px solid ${theme.palette.text.primary}`,
      borderRadius:"100%",
      padding:"5px",
      fontSize: 16
      
    },
    overall:{
      border:`1px solid ${theme.palette.text.primary}`,
      borderRadius:"10px",
      padding:"5px",
      fontSize: 16
      
    },
    default:{
      fontSize: 16
    }
  }
  const [textAlign, setTextAlign] = useState(_textAlign ?? "left");
  const [textStyle, ] = useState(_textStyle ?? "normal");
  const [textWeight, ] = useState(_textWeight ?? "normal");
  const [color, setColor] = useState(_color ?? "#ffffff");
  const [fontFamily, setFontFamily] = useState(_fontFamily ?? "Arial");
  const [fontSize, setFontSize] = useState(_fontSize ?? 18);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogBody, setDialogBody] = useState(<></>); 
  const [minimized, setMinimized] = useState(enableMinimize??false);
  const toggleAlignment = ()=>{
    const changeTo = textAlign==="left"?"center":textAlign==="center"?"right":"left";
    setTextAlign(changeTo);
    if(onTextAlignChange)
    onTextAlignChange(changeTo);
    
  }

  return <>
  <AnimatePresence mode="wait" custom={"left"} initial={false}>
  { enableMinimize ? <motion.div animate = {"hide"} exit = {"show"} onClick = {()=>{
    setMinimized(!minimized);
  }}><Column>{!minimized ? <ArrowDropUpRounded/> : <MoreHorizRounded/>}</Column></motion.div> :<></>}
  {minimized ?<></> : <motion.div >
    <Row style={{alignItems:'center', flexWrap: 'wrap'}}>
      <motion.div variants = {variants} animate = {"show"} initial={{ opacity: 0 }} exit = {"hide"} >
        <Tooltip title={"Header"}>
      <IconButton onClick={onTextHeaderChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textWeight !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textWeight !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<HMobiledataRounded style={styles.default}/>}
      </Column></IconButton>
      </Tooltip>
      </motion.div>
      <motion.div variants = {variants} animate = {"show"} initial={{ opacity: 0 }} exit = {"hide"} >
        <Tooltip title={"Bold, <ctrl + b>, **text**"}>
      <IconButton onClick={onTextWeightChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textWeight !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textWeight !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<FormatBoldOutlined style={styles.default}/>}
      </Column></IconButton>
      </Tooltip>
      </motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={"Italic, , <ctrl + i>, *text*"}>
      <IconButton onClick={onTextStyleChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textStyle !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textStyle !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<FormatItalicOutlined style={styles.default}/>}
      </Column></IconButton>
      </Tooltip></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={"Underline, , <ctrl + u>, __text__"}>
      <IconButton onClick={onTextUnderlineChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textStyle !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textStyle !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<FormatUnderlinedOutlined style={styles.default}/>}
      </Column></IconButton>
      </Tooltip></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={"Strike-through, <ctrl + y>, ~~text~~"}>
      <IconButton onClick={onTextStrikeThroughChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textStyle !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textStyle !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<FormatStrikethroughOutlined style={styles.default}/>}
      </Column></IconButton>
      </Tooltip></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={"Block Quotes, >>text"}>
      <IconButton onClick={onTextBlockQuoteChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textStyle !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textStyle !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<FormatQuoteRounded style={styles.default}/>}
      </Column></IconButton>
      </Tooltip></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={"Highlight, <Ctrl + h>, "}>
      <IconButton onClick={onTextHighlightChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textStyle !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textStyle !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<HighlightAltRounded style={styles.default}/>}
      </Column></IconButton>
      </Tooltip></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={"Link, <Ctrl + l>, [link-text](link-url)"}>
      <IconButton onClick={onTextLinkChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textStyle !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textStyle !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<LinkRounded style={styles.default}/>}
      </Column></IconButton>
      </Tooltip></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={"List Bullet, - "}>
      <IconButton onClick={onTextBulletListChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textStyle !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textStyle !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<FormatListBulletedRounded style={styles.default}/>}
      </Column></IconButton>
      </Tooltip></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={"List Numbered, 1. "}>
      <IconButton onClick={onTextNumberListChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textStyle !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textStyle !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<FormatListNumberedRounded style={styles.default}/>}
      </Column></IconButton>
      </Tooltip></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={"Checklist, - []"}>
      <IconButton onClick={onTextTaskListChange} style={{marginRight: 2, padding: 5}}><Column style={{...styles.unselected,
         background: textStyle !== "normal" ? theme.palette.text.primary : 'transparent', 
         color: textStyle !== "normal" ? theme.palette.background.paper : theme.palette.text.primary, 
         }}>
      {<ChecklistRounded style={styles.default}/>}
      </Column></IconButton>
      </Tooltip></motion.div>
      <motion.div variants = {variants} animate={"show"} initial = {"hide"} >
      <Tooltip title={`Text Color, <ctrl + k>, ([color])(text)`}>
        <IconButton onClick={
      ()=>{
        setDialogBody(
          <SketchPicker
        onChangeComplete={ (c)=>{
          setColor(c.hex);
          setOpenDialog(false);
          if(onColorChange)
          onColorChange(c.hex);
        }}/>);
        setOpenDialog(true);
        
      }
    } style={{marginRight: 2, padding: 5}}><Column style={styles.unselected}>
      <FormatColorTextOutlined style={{...styles.default, color: color,}}/>
      </Column></IconButton>
      </Tooltip></motion.div>
      <div style={{alignSelf:'stretch', width: 1, background: theme.palette.text.hint, margin:"20px 5px"}}/>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Tooltip title={`Font Family: ${fontFamily}`}>
    <IconButton onClick={
      ()=>{
        setDialogBody(
          <Grid container spacing={2} style={{padding: 20}}>
            {FontList.map((font, index)=>{
              return <Grid item xs={4} key={index}>
                <motion.div onClick={()=>{
                  setFontFamily(font);
                  if(onFontFamilyChange)
                  onFontFamilyChange(font);
                  setOpenDialog(false);
                }} whileHover={{scale: 1.1}}><Typography style={{fontFamily: font, cursor: "pointer",
                borderRadius: 10,
                   background: fontFamily === font ? theme.palette.primary.main : theme.palette.background.default, padding: 20, width: 120}}>{font}</Typography>
                  </motion.div>
              </Grid> 
            })}
          </Grid>
        );
        setOpenDialog(true);
        
      }
    }  style={{marginRight: 2}}><Column style={styles.overall}>
      <Typography style={{fontFamily:fontFamily, fontSize: 10,padding: "0px 5px"}}>{fontFamily}</Typography>
      </Column></IconButton>
      </Tooltip></motion.div>
 
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
      <Tooltip title={`Text Alignment: ${textAlign}`}>
        <IconButton onClick={toggleAlignment} style={{marginRight: 2, padding: 5}}><Column style={styles.overall}>
      {textAlign==="left"?<FormatAlignLeftOutlined style={styles.default}/>:textAlign==="center"?<FormatAlignCenterOutlined style={styles.default}/>:<FormatAlignRightOutlined style={styles.default}/>}
      </Column></IconButton>
          </Tooltip>
      </motion.div>
  <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Column style={{marginLeft: 5}}>
    <FormControl variant="outlined" size="small">
      <Select
        value={fontSize}
        defaultValue={fontSize}
        onChange={(event)=>{
          setFontSize(event.target.value);
          if(onFontSizeChange)
          onFontSizeChange(event.target.value);
        }}
        style={{
          borderRadius: 10,
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Font Size' }}
      >
        <MenuItem value={12}>12px</MenuItem>
        <MenuItem value={16}>16px</MenuItem>
        <MenuItem value={18}>18px</MenuItem>
        <MenuItem value={20}>20px</MenuItem>
        <MenuItem value={24}>24px</MenuItem>
        <MenuItem value={28}>28px</MenuItem>
        <MenuItem value={30}>30px</MenuItem>
        <MenuItem value={34}>34px</MenuItem>
        <MenuItem value={36}>36px</MenuItem>
      </Select>
    </FormControl>
      </Column></motion.div>
  </Row>
    </motion.div>}</AnimatePresence>
  <Dialog open={openDialog} onClose={()=>{
    setOpenDialog(false);
  }} aria-labelledby="form-dialog-title">
    <DialogContent>
      {dialogBody}
    </DialogContent>
    </Dialog>
  </>
})

export const MediaEditorPanel = (({
  _height,
  _width,
  _fit,
  _fitPosition,
  onHeightChange,
  onWidthChange,
  onFitChange,
  onFitPositionChange,

})=>{
  const [height, setHeight] = useState(_height??"400px");
  const [width, setWidth] = useState(_width??'100%');
  const [fit, setFit] = useState(_fit??"contain");
  const [fitPosition, setFitPosition] = useState(_fitPosition??"center");

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogBody, ] = useState(<></>); 
  const [minimized, setMinimized] = useState(true);


  return <>
  <AnimatePresence mode="wait" custom={"left"} initial={false}>
  {minimized ? <motion.div animate = {"hide"} exit = {"show"} onMouseEnter = {()=>{
    setMinimized(false);
  }}><Column><MoreHorizRounded/></Column></motion.div> : <motion.div onMouseLeave = {
    ()=>{
      setMinimized(true);
    }
  }>
    < Row style={{width:"100%"}}>
      <motion.div variants = {variants} animate = {"show"} initial={{ opacity: 0 }} style={{width:'100%'}} exit = {"hide"} >

      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Column style={{marginRight: 2}}>
      <Tooltip title={`Height: ${height}`}>
        
    <FormControl>
      <Select
      label="Height"
        value={height}
        defaultValue={height}
        onChange={(event)=>{
          setHeight(event.target.value);
          if(onHeightChange)
          onHeightChange(event.target.value);
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Height' }}
      >
        <MenuItem value={"100px"}>100px</MenuItem>
        <MenuItem value={"200px"}>200px</MenuItem>
        <MenuItem value={"300px"}>300px</MenuItem>
        <MenuItem value={"400px"}>400px</MenuItem>
        <MenuItem value={"600px"}>600px</MenuItem>
      </Select>
    </FormControl>
        </Tooltip>
      </Column></motion.div>

      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Column style={{marginRight: 2}}>
   <Tooltip title={`Width: ${width}`}>
   <FormControl>
      <Select
      label="Width"
        value={width}
        defaultValue={width}
        onChange={(event)=>{
          setWidth(event.target.value);
          if(onWidthChange)
          onWidthChange(event.target.value);
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Width' }}
      >
        <MenuItem value={"100px"}>100px</MenuItem>
        <MenuItem value={"200px"}>200px</MenuItem>
        <MenuItem value={"300px"}>300px</MenuItem>
        <MenuItem value={"400px"}>400px</MenuItem>
        <MenuItem value={"100%"}>100%</MenuItem>
        <MenuItem value={"60%"}>60%</MenuItem>
        <MenuItem value={"30%"}>30%</MenuItem>
      </Select>
    </FormControl>
    </Tooltip>
      </Column></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Column style={{marginRight: 2}}>
    <Tooltip title = "Image Fit">
    <FormControl>
      <Select
        value={fit}
        label="Fit"
        defaultValue={fit}
        onChange={(event)=>{
          setFit(event.target.value);
          if(onFitChange)
          onFitChange(event.target.value);
        }
        }
        displayEmpty
        inputProps={{ 'aria-label': 'Fit' }}
      >
        <MenuItem value={"contain"}>Contain</MenuItem>
        <MenuItem value={"cover"}>Cover</MenuItem>
        <MenuItem value={"fill"}>Fill</MenuItem>
        <MenuItem value={"none"}>None</MenuItem>
      </Select>
    </FormControl>
    </Tooltip>
        
      </Column></motion.div>
      <motion.div variants = {variants} animate = {"show"} initial = {"hide"} >
    <Column style={{marginRight: 2}}>
    <Tooltip title = "Image Fit Position">
    <FormControl>
      <Select

        value={fitPosition}
        defaultValue={fitPosition}
        onChange={(event)=>{
          setFitPosition(event.target.value);
          if(onFitPositionChange)
          onFitPositionChange(event.target.value);
        }
        }
        label="Fit Position"
        displayEmpty
        inputProps={{ 'aria-label': 'Fit Position' }}
      >
        <MenuItem value={"center"}>Center</MenuItem>
        <MenuItem value={"top"}>Top</MenuItem>
        <MenuItem value={"bottom"}>Bottom</MenuItem>
        <MenuItem value={"left"}>Left</MenuItem>
        <MenuItem value={"right"}>Right</MenuItem>
        <MenuItem value={"top left"}>Top Left</MenuItem>
        <MenuItem value={"top right"}>Top Right</MenuItem>
        <MenuItem value={"bottom left"}>Bottom Left</MenuItem>
        <MenuItem value={"bottom right"}>Bottom Right</MenuItem>
      </Select>
    </FormControl>
        
      </Tooltip>
      </Column></motion.div>


      </motion.div>
  </Row>
    </motion.div>}</AnimatePresence>
  <Dialog open={openDialog} onClose={()=>{
    setOpenDialog(false);
  }} aria-labelledby="form-dialog-title">
    <DialogContent>
      {dialogBody}
    </DialogContent>
    </Dialog>
  </>
})


export const  ImageModule  = ((props)=>{
    const [deleteImage, setDelete] =  useState(false);
    const [image, setImage] = useState(props.image);
    const [backupImage] = useState(props.image);
    const [limit, setLimit] = useState(props.limit??0)
    const classes = useStyles();
    
    const [showStyleEditor, setShowStyleEditor] = useState(false);
    const theme=useTheme();
    useEffect(()=>{
      
      if(!(image?.style)){
          
        image.style= defaultMediaStyle
          setImage({...image})
      }
    },[]);
    useEffect(()=>{
      if(props.mode!=="view"){
           setLimit(limit-1)
           if(props.updateLimit)
         props.updateLimit(limit-1)
      }
     
    },[])
    const loadFromDevice = (file, callback)=>{
        var fileReader = new FileReader();
        fileReader.onload = function(){
           var dataUri = fileReader.result;
            callback(dataUri); 
        }
        if(file)
        fileReader.readAsDataURL(file)
      }
     
    return deleteImage ? <></>:
    <AnimatePresence>
       <motion.div 
   style={{width:'100%'}}
   onMouseLeave={()=>{
    if(props.mode!=="view")
    setShowStyleEditor(false)
  }}
   onMouseEnter={()=>{
    if(props.mode!=="view")
    setShowStyleEditor(true);
   }}>
    
    <Row style={{height:props.height??"100%",width:"100%"}}>
          {props.mode==="view"?<></>:<IconButton style={{width:"40px", height:"40px"}} onClick={()=>{
              setImage(null);
              props.updateImage(image);
              if(props.onDelete)
              props.onDelete();
              setDelete(true)
          }}><CloseIcon/></IconButton>}
        <Column style={{width:'100%', height:"100%"}}>
            <Row alignment="centered" style={{width:'100%',height:"100%"}}>
                 <div style={{width:image?.style?.width,height:image?.style?.height,margin:"10px",padding:"12px",minWidth:100, minHeight:100}}>
        {props.mode==="view"?<>
        <div style={{...props.style, height:"100%",width:"100%"}} onClick={()=>{
          ///view image
        }}>
                {   props.placeholder?<Column style={{width:"100%", height:"100%", border:`2px ${fade(theme.palette.text.primary,0.3)} dotted`}}>
                      <Typography variant="caption">{props.placeholder??""}</Typography>
                    </Column>:<Center style={{width:'100%',height:"100%"}}>
                    {<div style={{width:"100%", height:"100%"}}><img alt="" style={{objectFit:image?.style?.fit, objectPosition:image?.style?.fitPosition??"top"}} width="100%" height="100%" src = {image?.file??image?.src}/></div>}
                </Center>  }
            </div>
        </>:<UploadDiv image={image.file} placeholder="" sizeLimit={props.sizeLimit} objectFitPosition={image?.style?.fitPosition} objectFit = {image?.style?.fit} border={`2px ${fade(theme.palette.text.primary,0.5)} dashed`} onUpload={(file)=>{
            loadFromDevice(file, (blob)=>{
                
                image.blob=blob;
                image.file=file;
                setImage({...image})
                props.updateImage(image)
                
        
            })
        }}>
           {props.mode==="edit"?
           <div style={{width:"100%", height:"100%"}}>
            <img alt="" style={{objectFit:image?.style?.fit, objectPosition:image?.style?.fitPosition??"top"}} width="100%" height="100%" src = {image?.file??image?.src}/>
            </div>:<Column style={{width:'100%',height:"100%"}} alignment="centered">
                <Typography variant="overline" align="center">Upload Image</Typography>
                <Typography variant="caption" style={{color:fade(theme.palette.text.primary,0.3)}} align="center">click here to upload</Typography>
            </Column> }   
        
        </UploadDiv>}
    </div>
            </Row>
                        {props.mode==="view"?<></>:<><Typography align={"center"}> -- or --</Typography>
                        <Input
                        fullWidth
                        defaultValue={image.src??""}
                        onChange={(evt)=>{
                          image.blob=evt.target.value!==''?evt.target.value:backupImage.blob;
                          image.file=evt.target.value!==''?evt.target.value:backupImage.file;
                          image.src=evt.target.value!==''?evt.target.value:backupImage.src;
                          props.updateImage(image)
                        }}
                          onKeyUp={(evt)=>{
                            image.blob=evt.target.value!==''?evt.target.value:backupImage.blob;
                            image.file=evt.target.value!==''?evt.target.value:backupImage.file;
                            
                          image.src=evt.target.value!==''?evt.target.value:backupImage.src;
                            props.updateImage(image)
                          }}
                            label="Paste Image Link"
                            startAdornment={<InputAdornment position="start">Paste Image Link: </InputAdornment>}
                        /></>}
        
    <InputBase
       className={classes.textfield}
       fullWidth
       readOnly={props.mode==="view"}
       onKeyUp={(evt)=>{
        image.ref=evt.target.value;
        props.updateImage(image)
        }}
        defaultValue={props.mode==="view"|"edit"?image.ref:""}
       placeholder={props.mode==="view"|"edit"?"":"Type a caption this image (optional)"}
       inputProps={{
           style:{
               fontSize:"13px",
               textAlign:"center",
               borderColor:"transparent",
               fontStyle:"italic"
           }
       }}
       />
        {  !showStyleEditor ? <></> :<motion.div animate={{opacity: 1}} exit = {{opacity: 0}} initial = {{opacity:0}}>
          <MediaEditorPanel
            _height={image?.style?.height}
            _width={image?.style?.width}
            _objectFit={image?.style?.objectFit}
            _objectFitPosition={image?.style?.objectFitPosition}
            onFitChange={(fit)=>{
              image.style.fit=fit;
              setImage({...image})
              props.updateImage(image)
            }
            }
            onFitPositionChange={(position)=>{
              image.style.fitPosition=position;
              setImage({...image})
              props.updateImage(image)
            }
            }
            onHeightChange={(height)=>{
              image.style.height=height;
              setImage({...image})
              props.updateImage(image)
            }
            }
            onWidthChange={(width)=>{
              image.style.width=width;
              setImage({...image})
              props.updateImage(image)
            }
            }
          />
        </motion.div>
        
        }
        </Column>
    </Row></motion.div>
    </AnimatePresence>
})


export const  VideoModule  = ((props)=>{
  const [deleteVideo, setDelete] =  useState(false);
  const [video, setVideo] = useState(props.video);
  const [backupVideo] = useState(props.video);
  const [limit, setLimit] = useState(props.limit??0)
  const classes = useStyles();
    
  const [showStyleEditor, setShowStyleEditor] = useState(false);
  const theme=useTheme();

  useEffect(()=>{
    
    if(!(video?.style)){
        
      video.style= {...defaultMediaStyle, width:'100%'}
        setVideo({...video})
    }
  },[]);
  useEffect(()=>{
    if(props.mode!=="view"){
         setLimit(limit-1)
         if(props.updateLimit)
       props.updateLimit(limit-1)
    }
   
  },[])
  const loadFromDevice = (file, callback)=>{
      var fileReader = new FileReader();
      fileReader.onload = function(){
         var dataUri = fileReader.result;
          callback(dataUri); 
      }
      if(file)
      fileReader.readAsDataURL(file)
    }
   
  return deleteVideo ? <></>:
  <AnimatePresence>
  <motion.div
   style={{width:'100%'}}
   onMouseLeave={()=>{
    if(props.mode!=="view")
    setShowStyleEditor(false)
  }}
   onMouseEnter={()=>{
    if(props.mode!=="view")
    setShowStyleEditor(true);
   }}>
  <Row style={{height:props.height??"100%",width:"100%"}}>
        {props.mode==="view"?<></>:<IconButton style={{width:"40px", height:"40px"}} onClick={()=>{
            setVideo(null);
            props.updateVideo(video);
            if(props.onDelete)
            props.onDelete();
            setDelete(true)
        }}><CloseIcon/></IconButton>}
      <Column style={{width:'100%', height:"100%"}}>
          <Row alignment="centered" style={{width:'100%',height:"100%"}}>
               <div style={{width:video.style?.width??"100%",height:video.style?.height??"100%",margin:"10px",padding:"12px"}}>
      {props.mode==="view"?<>
      <div style={{...props.style, height:"100%",width:"100%"}} onClick={()=>{
        ///view image
      }}>
              <Center style={{width:'100%',height:"100%"}}>
                  {
                    props.placeholder?<Column style={{width:"100%", height:"100%", border:`2px ${fade(theme.palette.text.primary,0.3)} dotted`}}>
                      <Typography variant="caption">{props.placeholder}</Typography>
                    </Column>:
                    <div style={{width:"100%", height:"100%"}}>
                      <ReactPlayer controls url= {video?.src??video.file} width="100%" height="100%"/>
                      </div>}
              </Center>  
          </div>
      </>:<UploadDiv image={video?.blob??video.file} sizeLimit={props.sizeLimit} objectFitPosition="center" video={true} border={`2px ${fade(theme.palette.text.primary,0.5)} dashed`} onUpload={(file)=>{
          loadFromDevice(file, (blob)=>{
              
              video.blob=blob;
              video.file=file;
              setVideo({...video})
              props.updateVideo(video)
              
      
          })
      }}>
          <Column style={{width:'100%',height:"100%"}} alignment="centered">
              <Typography variant="overline" align="center">Upload Video</Typography>
              <Typography variant="caption" style={{color:fade(theme.palette.text.primary,0.3)}} align="center">click here to upload</Typography>
          </Column>    
      
      </UploadDiv>}
  </div>
          </Row>
          {props.mode==="view"?<></>:<><Typography align={"center"}> -- or --</Typography>
                        <Input
                        fullWidth
                        defaultValue={video?.src??video.file}
                        onChange={(evt)=>{
                          video.blob=evt.target.value!==''?evt.target.value:backupVideo.blob;
                          video.file=evt.target.value!==''?evt.target.value:backupVideo.file;
                          
                          setVideo({...video})
                          props.updateVideo(video)
                        }}
                          onKeyUp={(evt)=>{
                            video.blob=evt.target.value!==''?evt.target.value:backupVideo.blob;
                            video.file=evt.target.value!==''?evt.target.value:backupVideo.file;
                            setVideo({...video})
                            props.updateVideo(video)
                          }}
                            label="Paste Video Link"
                            startAdornment={<InputAdornment position="start">Paste Video Link: </InputAdornment>}
                        /></>}
        
  <InputBase
     className={classes.textfield}
     fullWidth
     readOnly={props.mode==="view"}
     onKeyUp={(evt)=>{
      video.ref=evt.target.value;

      props.updateVideo(video)
      }}
     onChange={(evt)=>{
      video.ref=evt.target.value;
      props.updateVideo(video)
      }}
      defaultValue={props.mode==="view"|"edit"?video.caption:""}
     placeholder={props.mode==="view"|"edit"?"":"Type a caption this Video (optional)"}
     inputProps={{
         style:{
             fontSize:"13px",
             textAlign:"center",
             borderColor:"transparent",
             fontStyle:"italic"
         }
     }}
     />
  {  !showStyleEditor ? <></> :<motion.div animate={{opacity: 1}} exit = {{opacity: 0}} initial = {{opacity:0}}>
          <MediaEditorPanel
            _height={video?.style?.height}
            _width={video?.style?.width}
            _objectFit={video?.style?.objectFit}
            _objectFitPosition={video?.style?.objectFitPosition}
            onFitChange={(fit)=>{
              video.style.fit=fit;
              setVideo({...video})
              props.updateVideo(video)
            }
            }
            onFitPositionChange={(position)=>{
              video.style.fitPosition=position;
              setVideo({...video})
              props.updateVideo(video)
            }
            }
            onHeightChange={(height)=>{
              video.style.height=height;
              setVideo({...video})
              props.updateVideo(video)
            }
            }
            onWidthChange={(width)=>{
              video.style.width=width;
              setVideo({...video})
              props.updateVideo(video)
            }
            }
          />
        </motion.div>
        
        }
        </Column>
    </Row></motion.div>
    </AnimatePresence>
})



export const  TitleModule  = ((props)=>{
    const [deleteTitle, setDelete] =  useState(false);
    const [title, setTitle] = useState(props.title);
    const [, setShowStyleEditor] = useState(false);
    const classes = useStyles();
    useEffect(()=>{
      
      if(!(title?.style)){
          
        title.style= defaultTitleStyle
          setTitle({...title})
      }
    },[]);

  
    return deleteTitle ? <></>:
    <AnimatePresence>

   <motion.div 
   style={{width:'100%'}}
   onMouseLeave={()=>{
    if(props.mode!=="view")
    setShowStyleEditor(false)
  }}
   onMouseEnter={()=>{
    if(props.mode!=="view")
    setShowStyleEditor(true);
   }}>
    <Column style={{width:"100%"}}>
    <Row style={{width:"100%"}}>
          {props.mode==="view"?<></>:<IconButton onClick={()=>{
            if(props.onDelete)
            props.onDelete();
              setDelete(true)
          }}><CloseIcon/></IconButton>}
        <InputBase
        className={classes.textfield}
        fullWidth
        value={title}
        onChange={props.mode==="view"?()=>{}:(evt)=>{
            title.ref=evt.target.value;
            setTitle({...title})
            props.updateTitle(title)
           // updateStory({...newStory})
        }}
        readOnly={props.mode==="view"}
        defaultValue={title?.ref??'--'}
        placeholder="Title"
        inputProps={{
            style:{
              ...props.style,
                fontSize:title?.style?.fontSize ?? props.style?.fontSize??"24px",
                lineHeight:props.style?.lineHeight??1.5,
                borderColor:"transparent",
                fontFamily:title?.style?.fontFamily ?? props.style?.fontFamily??"Arial",
                color:title?.style?.color ?? props.style?.color??"#ffffff",
                textAlign:title?.style?.textAlign ?? props.style?.textAlign??"left",
                textDecoration:title?.style?.textLineDecoration ?? props.style?.textDecoration??"none",
                fontStyle:title?.style?.fontStyle ?? props.style?.fontStyle??"normal",
                fontWeight:title?.style?.fontWeight ?? props.style?.fontWeight??"bold",
            }
        }}
    />
    </Row>
  
    </Column>
    </motion.div>
    </AnimatePresence>
})


export const  LinkModule  = ((props)=>{
  const [deleteLink, setDelete] =  useState(false);
  const [link, ] = useState(props.link);
  const classes = useStyles();
  const theme=useTheme();
  const linkRef = useRef();
  console.log("Link",link);
  return deleteLink ? <></>:<Row style={{width:"100%"}}>
        {props.mode==="view"?<></>:<IconButton style={{width:"40px", height:"40px"}} onClick={()=>{
          
          if(props.onDelete)
          props.onDelete();
            setDelete(true)
        }}><CloseIcon/></IconButton>}

      { props.mode==="view"?<>
          <Tooltip title={"Link: "+link.link} arrow placement="top">
            <Link component="button"
            onClick={() => {
              var win = window.open(link.link,"_blank");
              win.focus()
            }}>
            <Typography  style={
                {...props.style,
                  fontSize:props.style?.fontSize??"2vw",
                  color:props.style?.color??fade(theme.palette.primary.light,1),
                  fontWeight:props.style?.fontWeight??"light",
                  fontStyle:"italic",
                  borderColor:"transparent"}
              }>
                {link.ref}
            </Typography>
          </Link>
          </Tooltip>
          
      </>:
         <Column style={{width:"100%"}}>
        <InputBase
          className={classes.textfield}
          fullWidth
          inputRef={linkRef}
          onKeyUp={props.mode==="view"?()=>{}:(evt)=>{
              link.ref=evt.target.value;
              props.updateLink(link)
            // updateStory({...newStory})
          }}
          readOnly={props.mode==="view"}
          defaultValue={link?.ref??""}
          placeholder="Link Name"
          inputProps={{
              style:{
                ...props.style,
                  fontSize:props.style?.fontSize??"18px",
                  color:props.style?.color??fade(theme.palette.primary.light,1),
                  fontWeight:props.style?.fontWeight??"bold",
                  borderColor:"transparent"
              }
          }}
      />
         <InputBase
          className={classes.textfield}
          fullWidth
         
          startAdornment= {<IconButton>
            <InsertLink/>
          </IconButton>}
          onKeyUp={props.mode==="view"?()=>{}:(evt)=>{
              link.link=evt.target.value;
              if(link.ref===""){
                linkRef.current.value=link.ref
              }
              props.updateLink(link)
          }}
          onChange={props.mode==="view"?()=>{}:(evt)=>{
              link.link=evt.target.value;
              if(link.ref===""){
                linkRef.current.value=link.ref
              }
              props.updateLink(link)
          }}
          readOnly={props.mode==="view"}
          defaultValue={link?.link??""}
          placeholder="Paste Link"
          inputProps={{
              style:{
                ...props.style,
                  fontSize:props.style?.fontSize??"14px",
                  fontWeight:props.style?.fontWeight??"",
                  borderColor:"transparent"
              }
          }}
      />
      {link.link.match(/((http|https):\/\/.+)/g)?<></>:<Typography align="left" variant="caption" style={{
        color:fade(theme.palette.text.primary,0.4),
        fontSize:"11px" 
      }}>Please Enter a valid link</Typography>}
        </Column>

      }
  </Row>
})



MarkdownTextField.propTypes = {
  viewMode: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  style: PropTypes.object,
  onStyleUpdate: PropTypes.func,
  enableMinimize: PropTypes.bool
}

ParagraphModule.propTypes = {
  paragraph: PropTypes.object,
  mode: PropTypes.string,
  updateParagraph: PropTypes.func,
  onDelete: PropTypes.func
}

TextEditorPanel.propTypes = {
  enableMinimize: PropTypes.bool,
  _textAlign: PropTypes.string,
  _textLineDecoration: PropTypes.string,
  _textStyle: PropTypes.string,
  _textWeight: PropTypes.string,
  _color: PropTypes.string,
  _fontFamily: PropTypes.string,
  _fontSize: PropTypes.number,
  onTextAlignChange: PropTypes.func,
  onTextLineDecorationChange: PropTypes.func,
  onTextStyleChange: PropTypes.func,
  onTextWeightChange: PropTypes.func,
  onColorChange: PropTypes.func,
  onFontFamilyChange: PropTypes.func,
  onFontSizeChange: PropTypes.func,
  onTextHeaderChange: PropTypes.func,
  onTextStrikeThroughChange: PropTypes.func,
  onTextUnderlineChange: PropTypes.func,
  onTextHighlightChange: PropTypes.func,
  onTextLinkChange: PropTypes.func,
  onTextBlockQuoteChange: PropTypes.func,
  onTextTaskListChange: PropTypes.func,
  onTextBulletListChange: PropTypes.func,
  onTextNumberListChange: PropTypes.func
}

MediaEditorPanel.propTypes = {
  _height: PropTypes.string,
  _width: PropTypes.string,
  _fit: PropTypes.string,
  _fitPosition: PropTypes.string,
  onHeightChange: PropTypes.func,
  onWidthChange: PropTypes.func,
  onFitChange: PropTypes.func,
  onFitPositionChange: PropTypes.func
}

ImageModule.propTypes = {
  image: PropTypes.object,
  mode: PropTypes.string,
  updateImage: PropTypes.func,
  onDelete: PropTypes.func,
  placeholder: PropTypes.string,
  sizeLimit: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
  fit: PropTypes.string,
  limit: PropTypes.number,
  updateLimit: PropTypes.func,
  style: PropTypes.object
}


VideoModule.propTypes = {
  video: PropTypes.object,
  mode: PropTypes.string,
  updateVideo: PropTypes.func,
  onDelete: PropTypes.func,
  placeholder: PropTypes.string,
  sizeLimit: PropTypes.number,
  height: PropTypes.string,
  width: PropTypes.string,
  fit: PropTypes.string,
  limit: PropTypes.number,
  updateLimit: PropTypes.func,
  style: PropTypes.object

}


TitleModule.propTypes = {
  title: PropTypes.object,
  mode: PropTypes.string,
  updateTitle: PropTypes.func,
  onDelete: PropTypes.func,
  style: PropTypes.object
  
}

LinkModule.propTypes = {
  link: PropTypes.object,
  mode: PropTypes.string,
  updateLink: PropTypes.func,
  onDelete: PropTypes.func,
  style: PropTypes.object
}