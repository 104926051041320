
import React, { useState } from 'react';
import { IconButton, Typography, OutlinedInput, InputAdornment, Chip } from '@material-ui/core';
import Row from '../../Row'
import Column from '../../Column';
import {
 Search} from '@material-ui/icons';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import Swiper styles
import "swiper/swiper.min.css";
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import PropTypes from 'prop-types'

export const MyStoriesFilters = ({ storyFilter, onSearch, onChangeFilter }) => {
    const [search, setSearch] = useState('');

    return <>
    <Column> 
        <Typography style={{ fontWeight: "bold", fontSize: "22px", textAlign: "left", marginBottom: 10 }}>My Stories</Typography>
            <Row>
            <Chip variant={storyFilter==='drafts' ? "default" : "outlined"} label="Drafts" onClick={() => {
                onChangeFilter('drafts')
            }}
            color={storyFilter==='drafts' ? "primary" : "default"}/>
            <div style={{width: 10}}/>
            <Chip variant={storyFilter==='submission' ? "default" : "outlined"} label="Submission Status" onClick={() => {
             
                onChangeFilter('submission')
            }}
            color={storyFilter==='submission' ? "primary" : "default"}/>
            <div style={{width: 10}}/>
            <Chip variant={storyFilter==='published' ? "default" : "primary"} label="Published" onClick={() => {
                onChangeFilter('published')
            }}
            color={storyFilter==='published' ? "primary" : "default"}/>
            </Row>
    </Column>
      <div style={{height: 20}}/>
        <OutlinedInput
            placeholder="Search Stories"
            value={search}
            onChange={(e) => {
                setSearch(e.target.value)
            }}
            endAdornment={<InputAdornment position="end"><IconButton onClick={()=>{
                onSearch(search)
            }}>
                <Search />
                </IconButton></InputAdornment>}
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
                'aria-label': 'weight',
            }}

        />
    </>
}

MyStoriesFilters.propTypes = {
    storyFilter: PropTypes.string,
    onSearch: PropTypes.func,
    onChangeFilter: PropTypes.func
}