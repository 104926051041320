/* eslint-disable react-hooks/exhaustive-deps */

import React,{ useEffect, useState } from "react";
import { useDatabase } from "../../hooks/useDatabase";
import { Chip } from "@material-ui/core";
import PropTypes from 'prop-types'


const ServicePackageChip = ({ packageId }) => {
    const [servicePackage, setPackage] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [, setError] = useState(false);

    const {retrieveServicePackage} = useDatabase();

    useEffect(() => {
        retrieveServicePackage(packageId)
            .then((servicePackage) => {
                setPackage(servicePackage);
                setLoaded(true);
            })
            .catch((error) => {
                console.error(error);
                setError(true);
            });
    }, [packageId]);

    if (!loaded) {
        return <Chip label="Loading..." />;
    }
    if(servicePackage === null){
        return <></>
    }
    return (
        <Chip color="primary" style={{margin:"0 5px", textTransform:'capitalize'}} label={`${servicePackage.name} Package`} size="medium" variant="outlined"/>
    );
    
}

export default ServicePackageChip;

ServicePackageChip.propTypes = {
    packageId: PropTypes.string,
    onDelete: PropTypes.func
}