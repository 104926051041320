/* eslint-disable react-hooks/exhaustive-deps */

import React,{ useEffect, useState } from "react";
import { useProfile } from "../../../hooks/useProfile";
import Column from "../../Column";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardContent, Chip, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, OutlinedInput, Select, Switch, Typography } from "@material-ui/core";
import useServiceBooking from "../../../contexts/ServiceBookingProvider";
import {useNavigate} from 'react-router';
import { ServiceProcess, getStatusColor, getStatusIcon } from "../../../utils";
import { OpenInBrowser, Search } from "@material-ui/icons";
import Row from "../../Row";
import { useMediaQuery } from "../../../contexts/MediaQueryProvider";
import { ProfileAvatar } from "../main/_profile_avatar";
import { Skeleton } from "@mui/material";
import ServicePackageChip from "../../chips/service_package_chip";
import { getNormalTextFromMarkdown } from "../../_markdown_textarea";
import PropTypes from 'prop-types'

const BuildServiceAgentProjectsTab =({uid})=>{
    const [profile,setProfile] = useState({});
    const [projects,setProjects] = useState([]);
    const {retrieveProfile} = useProfile();
    const [loading, setLoading] = useState(false);
    const {loading:Loading, getRequestsForAgent } = useServiceBooking();
    const history= useNavigate();
    const [filteredRequests, setFilter] = useState([]);
    const [serviceTypes, ] = useState([]);
    const [currentTypeFilter, setCurrentTypeFilter] = useState('all');
    const [currentProgressFilter, setCurrentProgressFilter] = useState('all');
    const searchRef = React.useRef(null);
    const {isLargeDevice, device} = useMediaQuery();
    const [onlyAccepted, setOnlyAccepted] = useState(true);
    const [serviceProgress, ] = useState(ServiceProcess.filter((process)=>
    (process !== 'deposit-processing' && process !== 'deposit-paid' && process !== 'deposit-failed' && 
    process !== 'cancelled-1' && process !== 'cancelled-2' && process !== 'payment-failed' && 
    process !== 'payment-recieved' && process !== 'payment-processing') 
    ));
    useEffect(()=>{
       
        setLoading(true); 
        retrieveProfile(uid).then((p)=>{
            console.log(uid);
            setProfile(p);
            setLoading(false);
        })
        getRequestsForAgent(uid, onlyAccepted).then((requests)=>{
            setProjects(requests);
            setFilter(requests);
        })
    },[uid, onlyAccepted]);

 
    const handleSearch = ({ typeFilter, progressFilter}) => {
        const applyTypeFilter = projects.filter((request) => {
            return (typeFilter === 'all' ) ? true : request.serviceType.name === (typeFilter );
        });
        const applyProgressFilter = projects.filter((request) => {
            return (progressFilter === 'all') ? true : request.status === (progressFilter );
        })
        const searchFilter = projects.filter((request) => {
            const description = request.description?.text ? getNormalTextFromMarkdown(request.description?.text) : request.description;
            return description.toLowerCase().includes(searchRef.current?.value?.toLowerCase());
        });
        const filtered = [applyTypeFilter, applyProgressFilter, searchFilter].reduce((a, b) => a.filter(c => {
            return b.includes(c);
        }));
        console.log(applyTypeFilter, applyProgressFilter, searchFilter);
        setFilter(
            filtered
        );

    }
    const _buildFilter = () => {
        const innerBody = <>
        <FormControl variant="outlined" size="small">
            <FormControlLabel control={<Switch checked={onlyAccepted} onChange={(event)=>{setOnlyAccepted(event.target.checked)}}/>} label="Show Only Accepted"/>
        </FormControl>
            <FormControl variant="outlined" size="small">
      <Select
        value={currentProgressFilter}
        onChange={(event)=>{setCurrentProgressFilter(event.target.value);
            handleSearch({
                search: searchRef.current?.value,
                typeFilter: currentTypeFilter,
                progressFilter: event.target.value
            })
        }}
        style={{
          borderRadius: 10,
          margin:'0 10px'
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Font Size' }}
      >
             <MenuItem  value={'all'}>
                            <Typography variant="caption">{'All Status'}</Typography>
                        </MenuItem>
                    {serviceProgress.map((process, index) => {
                        return <MenuItem key={index}  value={process}>
                            <Typography variant="caption">{process.replace('-',' ')}</Typography>
                        </MenuItem>
                    })}
                </Select>
                </FormControl>
                {device === 'xs' ? <div style={{height: 10}}/> : <></>}
                <FormControl variant="outlined" size="small">
        <Select 
        value={currentTypeFilter}
        onChange={(event)=>{setCurrentTypeFilter(event.target.value);
            handleSearch({
                search: searchRef.current?.value,
                typeFilter: event.target.value,
                progressFilter: currentProgressFilter
            })
        }}
        style={{
            borderRadius: 10,
            margin:'0 10px'
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Font Size' }}
            >
            <MenuItem  value={'all'}>
                            <Typography variant="caption">{'All Types'}</Typography>
                        </MenuItem>
                    {Object.values(serviceTypes).map((serviceType, index) => {
                        return <MenuItem key={index} value={serviceType.name}>
                            <Typography variant="caption">{serviceType.name}</Typography>
                        </MenuItem>
                    })}
                </Select>
                </FormControl>
                {device === 'xs' ? <div style={{height: 20}}/> : <></>}

            <OutlinedInput
                placeholder="Search"
                onChange={
                    ()=>{
                        handleSearch({
                            search: searchRef.current?.value,
                            typeFilter: currentTypeFilter,
                            progressFilter: currentProgressFilter
                        })
                    }
                }
                inputRef={searchRef}
                endAdornment={<InputAdornment position="end"><Search /></InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                    'aria-label': 'weight',
                    style:{padding: '10px 15px', borderRadius: 15}
                }}

            />
        </>
        const body = <>
         <Typography style={{ fontWeight: "bold", fontSize: "22px", textAlign: "left", marginBottom: 10 }}>My Projects</Typography>
          {device === 'xs' ? <Column>{innerBody}</Column> : <Row>{innerBody}</Row>}
            </>
        return isLargeDevice ? <Row style={{width:"100%", padding:"0 10px"}} alignment="space-between">{body}</Row> : <Column>
            {body}
        </Column>
    }
    const BuildProjects =()=>{
          return <div style={{margin:"0 auto", width:"100%"}}>
            {
                filteredRequests.length <= 0 && !Loading? 
                <Column style={{height: 200}}>
                    <Typography align="center" variant="h6">No Projects</Typography> 
                    {onlyAccepted&&<Typography align="center" variant="caption" color="textSecondary">You are only view accepted jobs</Typography> }
                </Column>: 
                Loading ? Array.from(new Array(5)).map((_, index) => (
                    <Skeleton key={index} variant="rectangular"
                    sx={{ bgcolor: 'grey.900' }}
                    width={'100%'} height={100} style={{margin: '10px 0'}}/>
                )) :
                filteredRequests.map((request) => {
                    const description = request.description?.text ? getNormalTextFromMarkdown(request.description?.text) : request.description;
      
               return <Accordion 
                style={{
                    margin: "10px 0",
                }}
                onClick={
                    ()=>{
                        console.log(profile.uid)
                        history(`ServiceProject?id=${request.id}`)
                    }
                }
            variant="outlined" expanded={device==='xs'} key={request.id}>
                <AccordionSummary>
                    <Row style={{width: "100%"}} alignment="space-between">
                        <Column style={{width: "80%",alignItems: 'start'}}>
                            <Typography variant="caption" style={{
                                textAlign: "left",
                            }}>{`${new Intl.DateTimeFormat("en-US").format(new Date(request.timestamp))} | ${description?.substring(0, Math.min(description.length, 50))}...`}</Typography>
                            <div style={{height: 5}}/>
                            <ProfileAvatar onlyName={true} uid={request.uid} size={30}/>
                            
                        </Column>
                        {device==='xs' ? <></> : <Row style={{alignItems:"center"}}>
                            <Chip style={{margin:"0 5px",borderColor: getStatusColor(request.status), textTransform:'capitalize'}} icon={ getStatusIcon(request.status)} label={request.status?.replace('-',' ')} size="medium" variant="outlined"/>
                            <ServicePackageChip packageId={request.packageId}/>
                            <Chip style={{margin:"0 5px", textTransform:'capitalize'}} label={request.serviceType.name} size="medium" variant="outlined"/>
                            <IconButton  onClick={
                                ()=>{
                                    console.log(profile.uid)
                                    history(`ServiceProject?id=${request.id}`)
                                }
                            }>
                                <OpenInBrowser/>
                            </IconButton>
                        </Row>}
                    </Row>
                </AccordionSummary>
                {device==='xs' ? <AccordionDetails>
                <Row style={{alignItems:"center", flexWrap:'wrap'}}>
                            <Chip style={{margin:"5px 5px",borderColor: getStatusColor(request.status), textTransform:'capitalize'}} icon={ getStatusIcon(request.status)} label={request.status?.replace('-',' ')} size="medium" variant="outlined"/>
                            <ServicePackageChip packageId={request.packageId}/>
                            <Chip style={{margin:"5px 5px", textTransform:'capitalize'}} label={request.serviceType.name} size="medium" variant="outlined"/>
                            <Button style={{margin:"5px 5px",}} color={'primary'} disableElevation variant="contained" icon={<OpenInBrowser/>} onClick={
                                ()=>{
                                    console.log(profile.uid)
                                    history(`ServiceProject?id=${request.id}`)
                                }
                            }>
                                <Typography variant="caption">Open Tracker</Typography>
                            </Button>
                        </Row>
                </AccordionDetails>:<></>}
                </Accordion>})
            }
        </div>
    }
    const BuildBody = () =>{
        return <Column>
        <Grid container spacing={2}>
            <Grid item xs={12}><Typography align="left" variant="h6">Projects</Typography></Grid>
            <Grid container xs={12}>
                {
                    _buildFilter()
                }
            </Grid>
            <Grid item xs={12}>
            <div style={{ minHeight: 500, width: '100%' }}>
                <BuildProjects/>
            </div>
            </Grid>
        </Grid>
        </Column>
    }
    return loading ? <Column>
        <CircularProgress/>
    </Column> : <Card style={{margin:"0 auto", width:"100%"}} variant="outlined">
        <CardContent>
            <BuildBody/>
        </CardContent>
    </Card>
}
export default BuildServiceAgentProjectsTab;


BuildServiceAgentProjectsTab.propTypes = {
    uid: PropTypes.string
}