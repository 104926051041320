/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { CircularProgress, Link } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import { useDatabase } from "../hooks/useDatabase";
import Row from "./Row";
import PropTypes from 'prop-types';


const ServiceAgreementLink = ({agreementId}) => {
    const [agreement, setAgreement] = useState(null);
  const [loading, setLoading] = useState(false);
    const { getAgreement } = useDatabase();
    const history = useNavigate();
    useEffect(() => {
        setLoading(true);
        getAgreement(agreementId).then((agreement) => {
            console.log(agreement);
            setLoading(false);
            setAgreement(agreement);
        });
    }, [agreementId]);
    return loading ? <Row alignment="centered" style={{alignItems:"center"}}>
        <CircularProgress size={12}/>
    </Row> : <> 
        {agreement ? <Link onClick={
            (e)=>{
                e.preventDefault();
                history(`/service_agreement_page?id=${agreementId}&view=true`);
            }
        }  to = {`/service_agreement_page?id=${agreementId}&view=true`}>{agreement.name}</Link>:<div></div>}
    </>
}
export default ServiceAgreementLink;

ServiceAgreementLink.propTypes = {
    agreementId: PropTypes.string
}