/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState,useRef } from "react";
import { useDatabase } from "../hooks/useDatabase";
import backgroundVideo from "../assets/backgroundVideo.mp4";
import Stack from "./Stack";
import Column from "./Column";
import Logic from "../logic/logic";
import FutureBuilder from "./_future_builder";
import { Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Tooltip, Typography, useTheme } from "@material-ui/core";
import Row from "./Row";
import { CheckCircleOutlineRounded, HighlightOffRounded } from "@material-ui/icons";
import ServiceAgreementPage from "../pages/serviceAgreementPage";
import ServiceAgreementLink from "./_service_agreement_link";
import { useCustomDialog } from "../contexts/CustomDialogProvider";
import { useSnackbar } from "notistack";
import ServicePackageAgreements from "./services_packages";
import useServiceBooking from "../contexts/ServiceBookingProvider";
import BuildPackageCreator from "./_package_creator";
import {motion} from "framer-motion";
import PropTypes from 'prop-types';



const ServicePakageCard = ({enableCardRotation, existingServicePackage,linkMode,linkProp,handlePackageSelection, servicePackageId, style, disable,mini,agentMode,agentOwner, onUpdatePackage }) => {
    const [servicePackage, setServicePackage] = useState(existingServicePackage);
    const {retrieveServicePackage,getAllCustomerAgreements,addServicePackage,updateServicePackage,getActiveProjectsWithPackage,deletePackageServicePackage} = useDatabase();
    const [, setAgreements] = useState([]);
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const theme =useTheme();
    const [currentPolicy, setCurrentPolicy] = useState(null);
    const [viewPolicy, setViewPolicy] = useState(false);
    const serviceAgreementAttacherRef = useRef();
    const {enqueueSnackbar} = useSnackbar();  
    const { updateAgent } = useServiceBooking();
    const [uploading, setUploading] = useState(false);  
    const [agent, ] = useState(agentOwner);
    const [activeProjectsWithPackage, setActiveProjectsWithPackage] = useState(null);
    const {
        showDialog,
        closeDialog,
    } = useCustomDialog();
    const cardStyle = {
        width: "280px",
        height: "380px",
        borderRadius: "20px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        border: "1px solid rgb(255 255 255 / 32%)",
        
    }

    const packageCreatorRef = React.createRef();
    const handleAddPackage = (packageId)=>{
       
        showDialog({
            title:"Create Package",
            body: <BuildPackageCreator agent={agent} packageId = {packageId} existingPackage={servicePackage} ref = {packageCreatorRef}/>,
            yesText:"Save",
            noText:"Cancel",
            onAccept:()=>{
                const packageData = packageCreatorRef.current.getPackage();
                console.log(packageData);
                if(packageData){
                    setUploading(true);
                    if(onUpdatePackage){
                        onUpdatePackage(packageData);
                    }
                    (packageId ? updateServicePackage(packageId, packageData)  : addServicePackage(packageData)).then(()=>{
                    updateAgent(agent.id, agent).then((res)=>{
                        if(res){
                            setUploading(false);
                            enqueueSnackbar("Package created successfully", {variant:"success"});
                            closeDialog();
                        }else{
                            enqueueSnackbar("Error creating package", {variant:"error"});
                        }
                    })
                })
                }
            }
        })
     }
    useEffect(()=>{
        if(!servicePackage)
        {retrieveServicePackage(servicePackageId).then((servicePackage)=>{
            console.log(servicePackage)
            getActiveProjectsWithPackage(servicePackageId).then((projects)=>{
                setActiveProjectsWithPackage(projects);
            })
            if(servicePackage){
                setServicePackage(servicePackage);
                setDiscountedPrice(servicePackage.price-(servicePackage.price*((servicePackage?.discount??0)/100)));
            }
            if(agentMode){
                getAllCustomerAgreements().then((agreements)=>{
                    setAgreements(Object.values(agreements));
                })
            }
        })}
        else{
            setDiscountedPrice(servicePackage.price-(servicePackage.price*((servicePackage?.discount??0)/100)));
        }
    },[]);
    const attachAgreementDB = async (agreement)=>{
      
        servicePackage.agreementsAttached = [...(servicePackage?.agreementsAttached??[]), agreement.id]
        servicePackage.deposit = agreement.paymentType === "percentage" ? (servicePackage.price * (Number(agreement.paymentAmount)/100)) : Number(agreement.paymentAmount);
        await updateServicePackage(servicePackage.id,servicePackage);
      
            
    }
    const  attachAgreement = () =>{
        showDialog({
            title: 'Attach Service Agreement',
            body: <ServicePackageAgreements ref={serviceAgreementAttacherRef}/>,
            yesText: 'Done',
            noText: 'Cancel',
            onAccept: async ()=>{
                  setUploading(true);
                if(serviceAgreementAttacherRef?.current){
                    const agreement = serviceAgreementAttacherRef.current.getSelectedAgreement();
                    setCurrentPolicy(agreement);
                    await attachAgreementDB(agreement);

                }
                setUploading(false);
                closeDialog();
            }
        })
    }
    const BuildLinkMode = ()=>{
        return <motion.a onClick={
            ()=>{
                showDialog({
                    title:"",
                    body: <Row alignment="centered">
                        <div style={{
                        width:300,
                        height:400
                    }}><ServicePakageCard enableCardRotation disable={true} existingServicePackage={servicePackage}/></div>

                    </Row>,
                    yesText:"Close",
                    onAccept:()=>{
                        closeDialog();
                    },
                    noText:"",
                })
            }
        } whileHover={{color:theme.palette.primary.main, scale: 1.01, cursor:'pointer'}}>
            <Typography variant="body1" style={{
            textDecoration:"underline",
            fontSize:12.25,
            color:theme.palette.secondary.main,
            textAlign:"left",}} {...linkProp}>{
            servicePackage?.name
        }</Typography>
        </motion.a> 
    }
    const BuildRotationCard =()=>{

        return  <motion.div
        className="rotating-card"
        animate={{ rotateY: [0, 30, -30, 0, -30, 30, 0] }} // Continuously rotate by 360 degrees
        transition={{ duration: 40, repeat: Infinity, ease: 'linear' }} // Infinite rotation with a 5-second duration
      >  
        <BuildCard/>
      </motion.div>
    }
    const BuildCard = ()=>{
        return <Column style={{...cardStyle,...style, position:'relative',overflow:'hidden'}}>
        <Stack>
            <Column style={{width:"100%",height:"100%"}}>
            <video src={backgroundVideo} style={{width:"100%",height:"100%",borderRadius: "20px",objectFit:'cover'}}/>
           </Column>
            <div style={{background: "linear-gradient(180deg, rgba(0,0,0,0) -95%, rgba(0,0,0,1) 100%)",borderRadius: "20px",height:"100%",width:"100%"}}/>

            {
                servicePackage ? <Column alignment="space-between" style={{width:"100%",alignItems:'stretch',height:"100%",padding:20}}>
                    <Column>
                      <Typography variant="h5" style={{textAlign:"left", fontWeight:"bold",}}>{servicePackage.name}</Typography>
                        <Typography variant="caption" style={{textAlign:"left", marginTop:"1rem"}}>{servicePackage.description}</Typography>
                        <div style={{height:5}}/>
                        <Row style={{flexWrap:'wrap'}}>
                        <FutureBuilder 
                        rebuild
                        future={Logic.toCountryCurrency(servicePackage.price,0)} builder = {
                            (price)=>{
                               return <Typography variant={mini ? "h6" : "h3"} style={{textAlign:"left",textDecoration:servicePackage.discount ? "line-through":"", opacity: servicePackage.discount ? 0.2 : 1, fontWeight:"bold", }}>{`${price}`}</Typography>
                            }
                        }
                        placeholder = {
                            <Typography variant={mini ? "h6" : "h3"} style={{textAlign:"left", textDecoration:servicePackage.discount ? "line-through":"", opacity: servicePackage.discount ? 0.2 : 1, fontWeight:"bold",}}>{`€${servicePackage.price}`}</Typography>
                        }
                        error = {
                            ()=>{

                            }
                        }/>
                        {servicePackage.discount ? <>
                        <div style={{width:5}}/>   
                        <FutureBuilder 
                        rebuild
                        future={Logic.toCountryCurrency(discountedPrice,0)} builder = {
                            (price)=>{
                               return <Typography variant={mini ? "h5" : "h3"} style={{textAlign:"left", fontWeight:"bold", marginTop:"2rem"}}>{`${price}`}</Typography>
                            }
                        }
                        placeholder = {
                            <Typography variant={mini ? "h5" : "h3"} style={{textAlign:"left", fontWeight:"bold", marginTop:"2rem"}}>{`€${discountedPrice}`}</Typography>
                        }
                        error = {
                            ()=>{

                            }
                        }/></>:<></>}
                        </Row>
                       
                        <Typography variant="caption" style={{textAlign:"left", marginTop:"1rem", opacity: 0.9}}>You <span style={{color:theme.palette.primary.main, opacity:1}}>{`Cannot use DTV Coins for this purchase`}</span></Typography>
                                {!disable ? <Button variant={servicePackage.recommended ? "contained" : "outlined"} onClick={()=>{
                                    handlePackageSelection(servicePackage);
                                }} color="primary" style={{marginTop:"2rem", width:"100%", borderRadius:10}}>Select Package</Button>:<></>}
                    
                    <Divider style={{backgroundColor:theme.palette.text.primary, marginTop:"20px", opacity:0.2}}/>
                    <Row style={{margin:"10px 0 2px 0"}}>
                <Chip size="small" label={servicePackage?.deposit > 0 ? `€${servicePackage.deposit} Deposit` : "No Deposit"} variant="outlined"/>
                <div style={{width:10}}/>
                <Chip size="small" label={servicePackage?.discount > 0 ? `${servicePackage.discount}% Discount` : "No Discount"} variant="outlined"/>
            
                    </Row>  
                    <Column  style={{marginTop:"2rem", width:"100%"}}>
                    {
                        servicePackage?.included?.map((incl, index)=>{
                            return <Row key={index} style={{alignItems:"center", marginBottom:8}}>
                                <CheckCircleOutlineRounded style={{color:theme.palette.primary.main}}/>
                                <Typography variant="caption" style={{textAlign:"left", marginLeft:5, opacity: 0.9}}>{incl}</Typography>
                            </Row>
                        })
                    }
                    </Column>
                    {servicePackage.exclude && <Column  style={{marginTop:"1rem", width:"100%"}}>
                    {
                        servicePackage.exclude?.map((incl, index)=>{
                            return <Row key={index} style={{alignItems:"center", marginBottom:8}}>
                                <HighlightOffRounded style={{color:'red'}}/>
                                <Typography variant="caption" style={{textAlign:"left", marginLeft:5, opacity: 0.9}}>{incl}</Typography>
                            </Row>
                        })
                    }
                    </Column>}
                  
                    </Column>
                     {agentMode ? <>
                        <div style={{height: 10}}/>
            {(servicePackage?.agreementsAttached??[]).length > 0? 
            <Column>
                {servicePackage?.agreementsAttached?.map((item, index)=>{
                    return  <Tooltip key={index} title={'Service Agreement #'+(index+1)}>
                    <ServiceAgreementLink agreementId={item} />
                </Tooltip>
                })}
                <div style={{height:10}}/>
                { <Button 
                size="small"
                variant="outlined"
                onClick={()=>{
                    if((activeProjectsWithPackage??[]).length > 0){
                        enqueueSnackbar("You cannot change the agreement of this package as it is currently in use by a project.",{
                            variant:'error'
                        })
                        return;
                    }
                    attachAgreement(servicePackage)
                }}> Change Agreement
                </Button>}
                    </Column>
                    :
                    <Button variant="text" 
                    onClick={()=>{
                       attachAgreement(servicePackage)
                    }}
                    style={{textAlign:"left",fontSize:12, fontStyle:"italic", textDecorationLine:'underline'}} color="textPrimary">{`This Package doesn't have any service agreement attached please click here to add one. (without one you will not be able to take on clients)`}</Button>   }
                    
                    </>:
                    
                    <> {!disable && servicePackage?.agreementsAttached ? <Button variant="text" onClick={
                        ()=>{
                            console.log(servicePackage)
                            setCurrentPolicy(servicePackage.agreementsAttached[0]);
                            setViewPolicy(true);

                        } 
                    } style={{textDecoration:"underline", color:theme.palette.primary.main, marginTop:"2rem", width:"100%", borderRadius:10}}>View Agreement policy</Button>:<></>}
               </>}
               {agentMode &&  <Row alignment="space-between" style={{width:"100%", marginTop: 10}}>
                      <Button onClick={()=>{
                        showDialog({
                            title:"Delete Package",
                            body: <Typography>Are you sure you want to delete this package?</Typography>,
                            yesText:"Yes",
                            noText:"Cancel",
                            onAccept:()=>{
                                const pkg = servicePackage.id;

                                deletePackageServicePackage(pkg).then((res)=>{
                                    if(res){
                                        updateAgent(agent.id, agent).then((res)=>{
                                            if(res){
                                                enqueueSnackbar("Package deleted successfully", {variant:"success"});
                                                closeDialog();
                                            }else{
                                                enqueueSnackbar("Error deleting package", {variant:"error"});
                                            }
                                        })
                                    }
                                })
                            }

                        })
                      }} variant="outlined" color="primary" size="small" style={{width:120}}>DELETE</Button>
                        <div style={{width:10}}/>
                      <Button onClick={()=>{
                            const pkg = servicePackage.id;
                            handleAddPackage(pkg)
                      }} variant="outlined" color="primary" size="small" style={{width:120}}>EDIT</Button>
                      </Row>}
                    </Column>:<></>
            }
        </Stack>
    </Column>
    }
    return linkMode ? <BuildLinkMode/> : <>
    {enableCardRotation ? <BuildRotationCard/> : <BuildCard/>}
    <Dialog open={uploading} onClose={
        ()=>{
            setUploading(false);
        }
    }>
        <DialogContent>
            <Column>
                <CircularProgress/>
                <div style={{height:10}}/>
                <Typography variant="caption">Uploading...</Typography>
            </Column>
            </DialogContent>
    </Dialog>
    <Dialog fullWidth maxWidth={'lg'} open={viewPolicy} onClose={ ()=>{setViewPolicy(false)} }>
        <DialogContent>
          <ServiceAgreementPage viewOnly={true} agreementId={currentPolicy}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={ ()=>{setViewPolicy(false)} } color="primary">
            Close
          </Button>
        </DialogActions>
        </Dialog>
    </>
};
export default ServicePakageCard;


ServicePakageCard.propTypes = {
    enableCardRotation: PropTypes.bool,
    existingServicePackage: PropTypes.object,
    linkMode: PropTypes.bool,
    linkProp: PropTypes.object,
    handlePackageSelection: PropTypes.func,
    servicePackageId: PropTypes.string,
    style: PropTypes.object,
    disable: PropTypes.bool,
    mini: PropTypes.bool,
    agentMode: PropTypes.bool,
    agentOwner: PropTypes.object,
    onUpdatePackage: PropTypes.func
}