import React, { useState } from "react";
import { Button, Checkbox, Divider, FormControlLabel, TextField, Typography } from "@material-ui/core";
import Column from "../../Column";
import shortUUID from "short-uuid";
import { sendChatMessage } from "../../../hooks/useChatMessenger";
import useServiceBooking from "../../../contexts/ServiceBookingProvider";
import { useSnackbar } from "notistack";
import { NotificationTypes } from "../../../logic/notificationHandler";
import FutureBuilder from "../../_future_builder";
import Logic from "../../../logic/logic";
import Row from "../../Row";
import PropTypes from 'prop-types'

const ProjectActionDeposit = ({service:Service,profile,openAgreement,servicePackage}) => {
    const [service, setService] = useState(Service);
    const {updateRequest} = useServiceBooking();
    const {enqueueSnackbar} = useSnackbar();

    return <Column alignment="space-between">
    <Column>
      <Typography>Deposit requested from client</Typography>
      
<div style={{ height: 20 }} />
<Typography variant="caption">{"In Accordance to this package's"}<span><Button onClick={openAgreement} style={{ fontSize: 10, textTransform: "capitalize", textDecoration: 'underline' }} size="small">terms and conditions</Button></span> 
The client is required to pay the below the deposit amount in order to continue with this project</Typography>

<div style={{ height: 20 }} />
<Column style={{width:'100%'}} alignment="center">
  <TextField
        id="datetime-local"
        label={service.depositDeadline ? "Update Deadline" : "Add Deadline"}
        type="date"
        defaultValue={new Date(service?.depositDeadline).toISOString().split('T')[0]}
        onChange={(e) => {
          setService({ ...service, depositDeadline: new Date(e.target.value).getTime()});
         
        }}
        InputProps={{
            inputProps:{
                //service.statusDates[service.status]
                min: new Date().addDays(3).toISOString().split('T')[0],

            }
        }} 
        InputLabelProps={{
            shrink: true,
        }}
    />
    <div style={{height:10}}/>
<Button disableElevation size="small" color="primary" variant="contained" onClick={async() => {
      if(!service.depositDeadline)return;
      if(await  updateRequest(service.id, service)){
        sendChatMessage({
            messageId: shortUUID.generate(),
            senderId: profile.uid,
            reciepientKey: service.id,
            reciepients: [service.uid, 'dtv-admin'],
            content: "A Deposit Deadline has been set to " + new Date(service.depositDeadline).toDateString(),
            timestamp: new Date().getTime(),
            seenby: []

        },{
            type: NotificationTypes.SERVICE,
            url: `/${service.uid}/ServiceBooking?id=${service.id}`,
            title: "Deposit Deadline Updated",
        })

        enqueueSnackbar("Deadline Updated", {variant: 'success'});
      }else{
        enqueueSnackbar("Error Updating Deadline", {variant: 'error'});
      }
    
}}>
    Deposit Deadline
</Button>
<div style={{height:10}}/>
<FormControlLabel 
small
style={{fontSize: 12}}
label={<Typography variant="caption">Automatic Cancellation</Typography>}
control={
    <Checkbox checked={service.automaticCancelation} onChange={(e) => {
        setService({...service, automaticCancelation: e.target.checked});
        updateRequest(service.id, {...service, automaticCancelation: e.target.checked});

    }}/>
} />
</Column>
<div style={{ height: 20 }} />
<Divider />
<div style={{ height: 20 }} />
<Row style={{ width: '100%', alignItems: 'center' }} alignment='space-between'>
  <Typography variant="overline" color="textSecondary">Deposit Amount:</Typography>
  <div style={{ width: 10 }} />
  <FutureBuilder
    rebuild
    future={Logic.toCountryCurrency(servicePackage.deposit??0, 0)} builder={
      (price) => {
        return <Typography variant="overline">{price}</Typography>
      }
    }
    placeholder={
      <Typography variant="caption">€{servicePackage.deposit??0}</Typography>
    }
    error={
      () => {
        return <></>
      }
    } />

</Row>
      
      </Column>
  </Column>
}
export default ProjectActionDeposit;




ProjectActionDeposit.propTypes = {
  service: PropTypes.object,
  profile: PropTypes.object,
  openAgreement: PropTypes.func,
  servicePackage: PropTypes.object
}