/* eslint-disable react-hooks/exhaustive-deps */

import { Avatar, Button, Card, CircularProgress, CssBaseline, IconButton, Typography, useTheme } from '@material-ui/core'
import { ArrowBackIosRounded } from '@material-ui/icons'
import { motion } from 'framer-motion'
import PropTypes from "prop-types"
import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router'
import Column from '../../components/Column'
import Row from '../../components/Row'
import firebase_app from "../../config/firebase"
import { useMediaQuery } from '../../contexts/MediaQueryProvider'
import { getCartItems } from '../../logic/databaseHandler'
import Logic from '../../logic/logic'
import { parseUrlForQueryParams } from '../../utils'
import { useDatabase } from '../../hooks/useDatabase'
import PaymentComp from './payment_component'
import Center from '../../components/Center'
import CheckoutSummaryPage from './checkout_summary_page'
import Stack from '../../components/Stack'
import { AppBarModule } from '../../components/_app_bar_mod'
import { DefaultStyle } from '../../contexts/styles'
import { useProfile } from '../../hooks/useProfile'
import useServiceBooking from '../../contexts/ServiceBookingProvider'



const PaymentPage = ({
    items: ITEMS,
}) => {
    const [, setTempId] = useState(firebase_app.auth()?.currentUser?.uid);
    const history = useNavigate();
    const [, setType] = useState();
    const {getSubmission, retrieveBundle, retrieveServicePackage, getAgreement, } = useDatabase();
    const { retrieveProfileWithCallback} = useProfile();
    const [profile, setProfile] = useState();
    const [price, setPrice] = useState(0);
    const [tax, ] = useState(0);
    const [actualPrice, setActualPrice] = useState(0);
    const today = new Date().valueOf();
    const [, setEcounter] = useState(0);
    const [items, setItems] = useState(ITEMS);
    const [cost, ] = useState(0);
    const theme = useTheme();
    const [loadingItems, setLoadingItems] = useState(false);
    const [, setShipping] = useState(false);
    const {device} = useMediaQuery();
    const {getRequest, } = useServiceBooking();
    const [enableDTVCoins, setEnableDTVCoins] = useState(true);

    const classes = DefaultStyle();
    const params = parseUrlForQueryParams(window.location.href);
    const verifyAccess = () => {
        const url = (window.location.href);
        const reg = /(.)+\?type=(.)+&id=(.)+/g
        console.log(url)
        return reg.test(url);
    }
    const calculatePrice = async (items) => {
        let val = 0;
        console.log("=========");
        console.log(items);
        let arr = items
        let exp = 0;
        if (arr.forEach)
            arr.forEach((i) => {
                console.log(i)
                if(i.startTime){
                        if (!((today > i.startTime) || (today > i.endTime))) {
                        val += i.price;
                    } else {
                        exp++;
                    }
                }else{
                    val += i.price;
                }
                 if (i.type === 'product') {
                    setShipping(true);
                }

            });
        setActualPrice(val);
        setPrice(Number(val) + Number(tax));
        setEcounter(exp);
    }
    const updateCost = async (items) => {
        calculatePrice(items)
    }
    const retrieveCart = async (id) => {
        getCartItems(id, (docs) => {
            const itemsTemp = Object.values(docs).flat().map((v)=>({...(v[`${v['type']}`]),cartId: v.cartId}));
            console.log(itemsTemp)
            setItems(itemsTemp);
            setLoadingItems(false)
            calculatePrice(itemsTemp);
            setLoadingItems(false);
        })
    }
    const retrieveItems = async (profile)=>{
        
        setType(params.type);
        console.log(profile);
        let itemSummary = {
            title: '',
            summary: '',
            price: '',
            link: '',
            type: '',
            viewMode: false
           }
        switch(params.type){
            case 'submit_video': 
                {
                    const videoSubmission = await getSubmission(params.id);
                    const bundle = await retrieveBundle({bundleId: videoSubmission.bundleId, isStory: false});
                    itemSummary = {
                    title: bundle.title+" Bundle",
                    summary: videoSubmission.title,
                    price: bundle.price,
                    link: videoSubmission.link,
                    type: 'video_submission'
                    
                    }
                    console.log(itemSummary);
                    if(itemSummary){
                        updateCost([itemSummary]);
                        setLoadingItems(false);
                    }
                    setItems([itemSummary])
                }
                break;
            case 'service_deposit': case 'service_payment':
                {
                    const isServiceDeposit = params.type === 'service_deposit';
                    const servicePackage = await retrieveServicePackage(params.id.includes("_") ? params.id.split('_')[1] : params.id);
                    const service = await getRequest(params.id.includes("_") ? params.id.split('_')[0] : params.id);
                    
                    const amountPaid = service?.depositPaid ?? 0;
                    const amountDue = servicePackage.price??0 + (
                        service.extraCharges?.length > 0 ? service.extraCharges.reduce((a,b)=>a+Number(b.amount),0) : 0
                    )+tax
                    setEnableDTVCoins(false);
                    itemSummary = {
                    title: servicePackage.name+" Package",
                    summary: isServiceDeposit ? 'Service Deposit' : 'Service Payment',
                    price: isServiceDeposit ? (servicePackage.deposit-amountPaid) : (amountDue - amountPaid),
                    link: '',
                    type: params.type,
                    cartId: params.id.includes("_") ? params.id.split('_')[0] : params.id
                    
                    }
                    console.log(itemSummary);
                    if(itemSummary){
                        updateCost([itemSummary]);
                        setLoadingItems(false);
                    }
                    setItems([itemSummary]);
                }
                
            break;
            case 'service_agent_application': case 'saa':
                if(params.id.includes("_")){
                    const applicationId = params.id.split('_')[0];
                    const agreementId = params.id.split('_')[1];
                    const agreement = await getAgreement(agreementId);
                    const cost = await Logic.toCountryCurrencyValue(Number(agreement.cost??0), 0);

                    setEnableDTVCoins(false);
                    itemSummary = {
                        title: 'Service Agent Application',
                        summary: `Agreement: ${agreement.name}`,
                        price: cost,
                        link: '',
                        type: params.type,
                        cartId: applicationId,
                        viewMode: true

                    }
                    if(itemSummary){
                        updateCost([itemSummary]);
                        setLoadingItems(false);
                    }
                    setItems([itemSummary]);
                }
               
            break;
            case 'cart':
                retrieveCart(profile.uid);
            break;
            default: history('/',{replace: true})
        }
    }
    useEffect(() => {

       if(verifyAccess()){
        setLoadingItems(true);
        setTimeout(()=>{
            retrieveProfileWithCallback((profile) => {
            setProfile(profile);
            setTempId(profile?.uid ?? firebase_app.auth()?.currentUser?.uid);
            console.log("=======> UseEffect 1");
            retrieveItems(profile);
           
        });
        },5000)
         
       }

        
    }, [])
    console.log("ITems",items)
    const _buildPage = () => {
        return <div style={{ maxWidth: 1600, margin: "0 auto", padding: "2vh 30px", height: "100%", paddingTop: device !== "xs" ? "10vh" : "",}}>
            <Column alignment="centered" style={{ width: "100%",  }}>
                <Row style={{ width: "100%", height: "100%" }}>
                    <Card variant="outlined" style={{ padding: "20px", width: "60%", minHeight: "400px", overflowY: "auto" }}>
                        { profile?.uid === null ? <Column>
                            <Typography variant="caption" color="textSecondary">Please login, to see your cart</Typography>
                            <div style={{ height: 20 }} />
                            <motion.div whileHover={{ scale: 1.1 }}>
                                <Button variant={"outlined"} color="primary"
                                    onClick={() => {
                                        history("/login")
                                    }}>Login</Button>
                            </motion.div>
                        </Column> : _buildCheckoutContent()}
                    </Card>
                    <div style={{ width: 30 }} />
                    <Card variant="outlined" style={{ padding: "20px", maxHeight: "100%", minHeight: "400px", width: "40%", overflowY: "auto" }}>
                        {loadingItems ? <Center align="row"><CircularProgress /></Center> :
                         items&&<CheckoutSummaryPage 
                         enableDTVCoins={enableDTVCoins}
                         viewOnly = {
                                params.type === 'service_agent_application' || params.type === 'saa'
                         }
                         uid={profile?.uid} clearCart={()=>{}} cart={items} />
                         
                         }
                    </Card>

                </Row><div style={{ height: 20 }} />
            </Column>
        </div>
    }
  
    const _buildCheckoutContent = () => {

        //console.log(items)
        return (<>
            {loadingItems ? <Center align="row"><CircularProgress /></Center> : 
            <PaymentComp enableDTVCoins={enableDTVCoins} uid={profile?.uid} profile = {profile} price={price} actualPrice={actualPrice} totalCost={price} cost={cost} items={items} />}
        </>)
    }
    const _buildPageMob = () => {
        return <div style={{ margin: "0 auto", padding: "4vh 20px", height: "100%", paddingTop: device !== "xs" ? "10vh" : "", }}>
            <Column alignment="centered" style={{ width: "100%",}}>
                 <motion.div style={{ width: 30 }} whileHover={{ scale: 1.1 }}>
                    <IconButton onClick={() => { history(-1) }}>
                        <Avatar><ArrowBackIosRounded fontSize={"12px"} /></Avatar>
                    </IconButton>
                </motion.div>
                <div style={{ height: 20 }} />
                   <Column style={{ width: "100%", height: "100%" }}>
                        <Card variant="outlined" style={{ padding: "20px", minHeight: "400px", width: "100%", overflowY: "auto" }}>
                        {loadingItems ? <Center align="row"><CircularProgress /></Center> : <CheckoutSummaryPage uid={profile?.uid} clearCart={()=>{}} cart={items} />}
                    </Card>
                               <div style={{ height: 30 }} />
                    <Card variant="outlined" style={{ padding: "4px", width: "100%", overflowY: "auto" }}>
                        { profile?.uid === null ? <Column>
                            <Typography variant="caption" color="textSecondary">Please login, to see your cart</Typography>
                            <div style={{ height: 20 }} />
                            <motion.div whileHover={{ scale: 1.1 }}>
                                <Button variant={"outlined"} color="primary"
                                    onClick={() => {
                                        history("/login")
                                    }}>Login</Button>
                            </motion.div>
                        </Column> : _buildCheckoutContent()}
                    </Card>
         
                   

                </Column>
            
            </Column>
        </div>
    }
 
    return !verifyAccess() ? <Navigate to='/'/> :  <div className={classes().root} >
    <CssBaseline />
    <Stack>
        {/* <div style={{height:"100%", background:"black",width:"100%"}}>
       <img src={studio} width="100%" height="100%" style={{objectFit:"cover",filter:"blur(10px)"}}/>
    </div> */}
        <div style={{ background: theme.palette.background.default, height: "100%", width: "100%" }}></div>
        <div style={{ background: "linear-gradient(0deg, black 20%,#0000009c 54%, transparent)", height: "100%", width: "100%" }}></div>

        {device==="xs" ? _buildPageMob() : _buildPage()}
    </Stack>
    {<AppBarModule pageTitle="Checkout" cartHover={false} checkoutPage={false} showCart={true} pageIndex={-1} style={{ padding: 10 }} customRightModule={<></>} />}
   
</div>
}
PaymentPage.propTypes = {
    totalCost: PropTypes.number,
    items: PropTypes.arrayOf(PropTypes.object),
}
export default PaymentPage;