import axios from "axios";
import { baseUrl, RouteNames } from "../config/api";

export const StoryService = () => {
    const createStory = async ({
        userId, headline, photo, photoCaption, publishedAt, tags,story,author,isDTV,isDraft,orgId}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.story}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                userId,
                headline,
                photo,
                photoCaption,
                publishedAt,
                tags,
                story,
                author,
                isDTV,
                isDraft,
                orgId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getStory = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.story}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                storyId: id
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateStory = async (data) => {
        console.log( `${baseUrl}/${RouteNames.story}`);
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.story}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                storyId:data.storyId
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data);
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteStory = async ({storyId}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.story}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                storyId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const approveStory = async ({storyId, adminId}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.storyApprove}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                storyId,
                adminId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const toggleLike = async ({storyId, userId}) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.storyLike}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                storyId,
                userId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const viewStory = async ({storyId, userId}) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.storyView}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                storyId,
                userId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoryLikes = async ({storyId}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyLike}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                storyId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data?.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoryViews = async ({storyId}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyView}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                storyId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data?.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const searchStory = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storySearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    
    const getStories = async ({cursor, limit, offset}) => {
        console.log(`${baseUrl}/${RouteNames.storyList}`)
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset
            }
        };
        try {
            console.log("getting stories");
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoriesByUser = async ({cursor, limit, offset,filter}) => {
        console.log("getting all stories", filter)

        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListByUsers}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset,
                ...filter
            },
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data);
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getRelatedStoriesByAuthor = async ({storyId, cursor, limit, offset, isDraft}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListFilterRelatedByAuthor}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                storyId,
                cursor,
                limit,
                offset,
                isDraft
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        }
        catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getRelatedStories = async ({storyId, cursor, limit, offset,isDraft}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyRelatedList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                storyId,
                cursor,
                limit,
                offset,
                isDraft
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoryByOrg = async ({orgId, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListFilterOrg}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                orgId,
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoryWithDateFilter = async ({fromDate, endDate, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListFilterDate}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                fromDate,
                endDate,
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoryWithDTVFilter = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListFilterDtv}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoryWithAuthorFilter = async ({query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListFilterAuthor}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoryWithFreelancerFilter = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListFilterFreelance}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoryWithPopularFilter = async ({cursor, limit, offset, order}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListFilterPopular}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset,
                order: order === "asc" ? "asc" : "desc"
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getStoryWithTrendingFilter = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListFilterTrending}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getPublishedStories = async ({cursor, limit, offset, order}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyListFilterPublished  }`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset,
                order: order === "asc" ? "asc" : "desc"
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getUserStories = async ({userId, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyUserStory}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getAllDraftStories = async ({cursor, limit, offset, filter}) => {
        console.log("getting all draft stories", { cursor,
            limit,
            offset,
            ...filter})
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyDraftList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset,
                ...filter
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data);
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const searchDraftStories = async ({userId, query, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyUserDraftSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                query,
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const getUserPublishedStories = async ({userId, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyUserPublished}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }     
    }
    const createDraftStory = async ({ storyId, userId, headline, photo, photoCaption, publishedAt, tags,story,author,isDTV,isDraft,orgId}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.storyDraft}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                storyId,
                userId,
                headline,
                photo,
                photoCaption,
                publishedAt,
                tags,
                story,
                author,
                isDTV,
                isDraft,
                orgId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data);
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getUserDrafts = async ({userId, cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyUserDraftList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getDraftStory = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.storyDraft}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                storyId: id
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateDraftStory = async (data) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.storyDraft}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                storyId:data.storyId
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteDraftStory = async ({storyId}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.storyDraft}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                storyId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateUserDraftStory = async (data) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.storyDraft}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                storyId:data.storyId
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteUsersDraftStory = async ({storyId}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.storyDraft}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                storyId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        createStory,
        getStory,
        updateStory,
        deleteStory,
        approveStory,
        toggleLike,
        viewStory,
        getStoryLikes,
        getStoryViews,
        searchStory,
        getStories,
        getStoriesByUser,
        getRelatedStories,
        getStoryByOrg,
        getStoryWithDateFilter,
        getStoryWithDTVFilter,
        getStoryWithAuthorFilter,
        getStoryWithFreelancerFilter,
        getStoryWithPopularFilter,
        getStoryWithTrendingFilter,
        getPublishedStories,
        getUserStories,
        getAllDraftStories,
        searchDraftStories,
        getUserPublishedStories,
        getRelatedStoriesByAuthor,
        createDraftStory,
        getUserDrafts,
        getDraftStory,
        updateDraftStory,
        deleteDraftStory,
        updateUserDraftStory,
        deleteUsersDraftStory
    }
}