import React, { useEffect, useState } from 'react';
import { Card, CardContent, IconButton, Paper, makeStyles } from '@material-ui/core';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { motion } from 'framer-motion';
import Row from './Row';
import { CustomScrollview } from './custom_scrollview';
import PropTypes from 'prop-types';
const useStyles = makeStyles((theme) => ({
    pageIndicatorContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(2),
      marginLeft:5,
      marginRight:5,
    },
    pageIndicator: {
      width: 12,
      height: 12,
      borderRadius: '50%',
      margin: theme.spacing(0, 0.5),
      cursor: 'pointer',
      backgroundColor: '#ccc',
      opacity: 0.5,
      '&.active': {
        backgroundColor: '#fff',
        opacity: 1,
      },
    },
  }));
const pageVariants = {
  enter: (direction) => ({
    x: direction === 'next' ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    x: 0,
    opacity: 1,
  },
  exit: (direction) => ({
    x: direction === 'next' ? -1000 : 1000,
    opacity: 0,
  }),
};

const SlidingCarousel = ({pages, disableNext, currentPage:cP}) => {
  const [currentPage, setCurrentPage] = useState(cP??0);
  const [direction, setDirection] = useState('next');
  const classes = useStyles();

  useEffect(() => {}, [currentPage])
  const handlePrevPage = () => {
    setDirection('prev');
    setCurrentPage((prevPage) => (prevPage - 1 + pages.length) % pages.length);
  };

  const handleNextPage = () => {
    setDirection('next');
    setCurrentPage((prevPage) => (prevPage + 1) % pages.length);
  };

  return (
    <Card variant='outlined' style={{height:"100%", width:"100%"}}>
      <CustomScrollview style={{ height: "100%" }}>
         <CardContent style={{height:"100%",maxHeight:`${(pages?.length??0) > 1 ?"90%":"100%"}`,overflowY:'auto',overflowX:'none', width:"100%"}}>
        <motion.div
          key={currentPage}
          variants={pageVariants}
          custom={direction}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{ type: 'spring', stiffness: 300, damping: 30 }}
        >
          {pages[currentPage]}
        </motion.div>
    
     

      {(pages?.length??0) > 1 ? <Row alignment={"centered"} style={{width:"100%"}}>
        <IconButton onClick={handlePrevPage} disabled={currentPage === 0}>
        <ArrowBack />
      </IconButton>
       <div className={classes.pageIndicatorContainer}>
        {pages.map((_, index) => (
          <Paper
            key={index}
            className={`${classes.pageIndicator} ${index === currentPage ? 'active' : ''}`}
            onClick={() => setCurrentPage(index)}
          />
        ))}
      </div>
   
      <IconButton onClick={handleNextPage} disabled={disableNext || currentPage === pages.length - 1}>
        <ArrowForward />
      </IconButton>
      </Row>:<></>}
       </CardContent>
      </CustomScrollview>
    </Card>
  );
};

export default SlidingCarousel;


SlidingCarousel.propTypes = {
  pages: PropTypes.array,
  disableNext: PropTypes.bool,
  currentPage: PropTypes.number
}