/* eslint-disable react-hooks/exhaustive-deps */

import { CircularProgress, CssBaseline, Dialog, Grid, GridList, IconButton, LinearProgress, Snackbar } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import shortUUID from "short-uuid";
import Column from "../components/Column";
import Row from "../components/Row";
import Stack from "../components/Stack";
import { AppBarModule } from "../components/_app_bar_mod";
import LoginRequired from "../components/_login_required";
import ValidUserRequired from "../components/_valid_user_required";
import { sendFeedback } from "../config/emailer";
import { SiteDataContext } from "../config/SiteData";
import { useStorage } from "../contexts/storage_handler";
import StoryEditor from "../components/_story_editor";
import { parseUrlForQueryParams } from "../utils";
import { useDatabase } from "../hooks/useDatabase";
import { retrieveStorySubmission, submitStory } from "../logic/databaseHandler";
import { useMediaQuery } from "../contexts/MediaQueryProvider";
import { useProfile } from "../hooks/useProfile";
import { NotificationTypes, sendNotification } from "../hooks/notificationHandler";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',

    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.text.primary,
    },

    '& .MuiChip-root': {
      backgroundColor: "#171717",
    },

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'transparent',
        borderBottomColor: 'transparent',
      }
    },
  },
  center: {
    textAlign: "center",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    background: "linear-gradient(45deg, black, #d09612bd);",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  list: {
    height: '100%'
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  textfield: {
    '&focus': {
      borderBottomColor: theme.palette.text.primary
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const StorySubmissionPage = () => {


  const { enqueueSnackbar,  } = useSnackbar();

  const theme = useTheme()
  const {
    SiteData,
  } = useContext(SiteDataContext)
  const {retrieveProfileWithCallback} = useProfile();
  const {isLargeDevice} = useMediaQuery();
  const [showModal, setShowModal] = useState(false);

  const [, showUploadingVideo] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [disableExitClick, ] = useState(false);
  const [notificationMsg, setNotification] = useState('');
  const classes = useStyles();
  const history = useNavigate();
  const [loading, setLoading] = useState(true);
  const [, setErrorProgress] = useState(null);
  const {getDraft} = useDatabase();
  const [newStory, updateStory] = useState({});
  const [profile, setProfile] = useState();
  const params = parseUrlForQueryParams(window.location.href);
  const [submission, setSubmission] = useState({});
  const { uploading,
    progress,
    progressMsg,
    setUploading,
     } = useStorage();


  useEffect(() => {
    newStory.storyId = params.id;
    console.log(params.id,params);
    setLoading(true);
    retrieveProfileWithCallback((p)=>{
    console.log(p, params);
        setProfile(p);
        retrieveStorySubmission("SUB"+params.id??'').then((s)=>{
          if(s)
        setSubmission(s)
      });
      getDraft(params.id).then((story) => {
        console.log(story);
        if(story){
          updateStory({ ...story });
        }else 
          updateStory({ ...newStory });
        setLoading(false);
      })
    })
    
    
  }, [])



  const submitForm = async (data) => {

    try {
      showUploadingVideo(false);
      setErrorProgress(false);
      const success = await submitToDB(data);
      setUploading(false);
     
      console.log("success");
      // await sendWithNodeMailer({
      //     from:`'${payload.name}'`+`<${payload.email}>`,
      //     to:SiteData.email,
      //     subject:"DTV Video Submission",
      //     html:`<div><h1>New Submission from ${payload.name}</h1><ul><li><strong>Name</strong>: ${payload.name}</li><li><strong>Title</strong>: ${payload.title}</li><li><strong>Email</strong>: ${payload.email}</li><li><strong>Description</strong>: ${payload.description}</li><li><strong>DOWNLOAD LINK</strong>: <a href=${payload.link}>${payload.link}</a></li></ul></div>`
      // });
       if(success){
        await sendNotification({
          id: shortUUID.generate(),
        sendTo: 'dtv-admin',
        sentFrom: profile.uid,
        type: NotificationTypes.STORY_SUBMISSION,
        title: 'New Story Submission',
        message: `${profile.fname} ${profile.lname} has submitted a new story`,
        timestamp: Date.now(),
        seen: false,
        url: `/Submissions/Story:${data.sId}`
      })
      sendFeedback(
        {
          from: `${profile?.email}`,
          to: SiteData.email,
          subject: "DTV Story Submission",
          body: `<div><h1>New Story Submission from ${profile.fname} ${profile.lname}</h1><ul><li><strong>Description</strong>: ${newStory.headline} \n\n ${newStory.paragraphs[0]?.ref}...</li><li><strong>Admin site</strong>: <a href=${SiteData.adminSiteLink}>${SiteData.adminSiteLink}</a></li></ul></div>`
        },
        () => {
          setShowModal(true);
          setErrorProgress(false);
          showUploadingVideo(true);
          setNotification({ message: "Submission Sent!", timeout: 3000 });
          enqueueSnackbar("Submission Sent!");
          showNotification(true)
        },
        (e) => {
          console.log(e)
          setErrorProgress(true);
          setNotification({ message: "Error Occured! " + e, timeout: 3000 });
          enqueueSnackbar("Error Occured! " + e);
          showNotification(true)
        }
      )
      console.log("saved");
      history(-1);
      }
    } catch (e) {
      setErrorProgress(true);
      setNotification({ message: "Error Occured! " + e?.message, timeout: 3000 });
      showNotification(true)
    }


  }
  const submitToDB = async () => {
    let p = {
      sId: "SUB" + newStory.storyId,
      storyId: submission?.storyId ?? newStory.storyId,
      orgId: params.org ?? 'freelance',
      storyHeadline: submission?.storyHeadline ?? newStory.headline,
      userId: submission?.userId ?? profile?.uid,
      timestamp: submission?.timestamp ?? new Date().valueOf(),
      status: submission?.status ?? "pending",
      bundleId: submission?.bundleId ?? newStory.bundleId ?? "FREE", 
      user_name: submission?.user_name ?? `${profile?.fname??''}${" " + profile?.lname}`.trim(),
      statusDates: submission?.statusDates ?? {
        submitted: new Date().valueOf(),
        pending: new Date().valueOf(),
      }
    }
    console.log(p);
   return await submitStory(p);
  }
  const _buildEditor = () => {
    return <div style={{ height: "auto", width: "100%", overflowX: "hidden", margin:  "40px auto", padding: isLargeDevice ? "10%": 10}}>
       {loading ? <Row alignment="centered" style={{width: "100%", height: "50vh", alignItems: "center"}}>
          <CircularProgress/>
        </Row>  :<GridList style={{ height: "100%", width: "100%", }}>
        <Card style={{ height: "100%", width: "100%", background: theme.palette.background.default }}>
          <Grid container spacing={1} style={{ padding: "20px 20px" }}>
            <Grid key="Subheader" xs={12} md={6} style={{ height: 'auto' }}>
              <Column alignment="centered" style={{ height: "100%", width: "100%" }}>
                <Typography align="left" variant={"overline"} style={{ fontSize: "22px" }} component="div">Upload Story</Typography>
              </Column>
            </Grid>
            </Grid>
          <StoryEditor hideApprovedBy={true} editExistingStory={true} createNewStory={!newStory.story} story={newStory} draft customPublishText={"Submit"} orgId={params.org} onSave={
            (data)=>{
              console.log(data);
              updateStory(data);
              submitForm(data)
            }
          }/>

        </Card>
      </GridList>}
    </div>
  }


  const _buildUploading = () => {
    return <Row alignment="centered" style={{ height: "400px", width: "100%", padding: "20px" }}>
      <Column alignment="centered" style={{ height: "100%" }}>
        <Typography>
          Updating Story
        </Typography>

        <div style={{ width: "100%" }}><LinearProgress value={progress} color="primary" /></div>
        <Typography align="center" variant="caption">{`${progressMsg}`}</Typography>
      </Column>
    </Row>
  }


  return (

    <div className={classes.root}>
      <CssBaseline />
      <Stack>

        <div style={{ background: `${alpha(theme.palette.background.default, 1)} `, height: "100%", width: "100%" }}></div>
        {_buildEditor()}


      </Stack>
      {<AppBarModule pageIndex={1} appbarBackground={theme.palette.background.paper} />}
      <Dialog
        maxWidth="md"
        disableBackdropClick={disableExitClick}
        onClose={() => { setShowModal(false) }}

        aria-labelledby="confirmation-dialog-title"
        open={showModal}>
        {preview_body}
      </Dialog>
      <Dialog open={uploading} fullWidth maxWidth={"md"}>
        {_buildUploading()}
      </Dialog>
      <LoginRequired/>
    <ValidUserRequired/>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={notificationMsg.timeout}
        onClose={() => {
          showNotification(false)
        }}
        message={notificationMsg.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>

  );
}
export default StorySubmissionPage;