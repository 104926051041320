import axios from "axios"
import { RouteNames, baseUrl } from "../config/api";

export const AuthService = () => {
    const login = async ({email, password}) => {
        console.log(`${baseUrl}/${RouteNames.authAdminLogin}`,email)
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.authAdminLogin}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                
            },
            data: {
                email,
                password
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                let error = response.data?.error ?? "An error occured";
                console.log(error)
                if (error.includes(":"))
                    error = error.split(":")[1];
                throw new Error(error);
            }
            let message = response.data?.message ?? "";
            if (message.includes(":") && !response.data.sucess)
                message = message.split(":")[1];
            console.log(response.data.data);
            return {
                data: response.data.data,
                message: message,
                status: 200 
            };
        } catch (e) {
            console.log(e);
            let error = e.message;
            if (e.message.includes(":"))
                error = e.message.split(":")[1];
            throw new Error(error);
        }
    }
    const register = async ({email, password, fname, lname}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.authAdminRegister}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                email,
                password,
                fname,
                lname
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200,
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const logout = async ({uid}) => {
        if(!uid) throw new Error("Invalid user id");    
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.authAdminLogout}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                uid
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const resetPassword = async ({uid, password}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.authAdminReset}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                uid,
                password
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const confirmPassword = async ({email, password}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.authAdminConfirm}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                email,
                password
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            console.log(response.data.data);
            return {
                data: response.data.data?.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const isNewUser = async ({email}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.authAdminAvailability}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                email
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getUser = async ({uid}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.authAdmin}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            console.log(response.data, "<<<<");
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
        }
    }
    const getAllAdmins = async ({cursor, limit, offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.authAdminList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total ?? response.data.count,
                cursor: response.data.data?.cursor,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getAllUsers = async ({cursor,limit,offset}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.authUsers}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset}
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total ?? response.data.count,
                cursor: response.data.data?.cursor,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getUserProfile = async ({uid}) => {
        console.log(uid, `${baseUrl}/${RouteNames.authProfile}`)
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.authProfile}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateUserProfile = async ({uid, data}) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.authProfile}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid
            },
            data
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteUser = async ({uid}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.authUsers}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        login,
        register,
        logout,
        resetPassword,
        confirmPassword,
        isNewUser,
        getUser,
        getAllAdmins,
        getAllUsers,
        getUserProfile,
        updateUserProfile,
        deleteUser
    }
}