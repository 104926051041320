import firebase_app from "../config/firebase"
export const NotificationTypes = {
    "VIDEO_SUBMISSION": "VIDEO_SUBMISSION",
    "STORY_SUBMISSION": "STORY_SUBMISSION",
}
export const sendNotification = async ({
    id,
    sendTo,
    sentFrom,
    type,
    title,
    message,
    timestamp,
    seen,
    url
})=>{
    const data = {
        id,
        sendTo,
        sentFrom,
        type,
        title,
        message,
        timestamp,
        seen,
        url
    }
    try{
        await firebase_app.firestore().collection("Notifications").doc(sendTo).collection(type).doc(id).set(data);
        return true;
    }catch(err){
        console.log(err);
    }
}