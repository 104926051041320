/* eslint-disable react-hooks/exhaustive-deps */

import { Dialog, IconButton, makeStyles, Snackbar, Typography, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import cursor from '../../assets/cursor_round.png';
import studio from '../../assets/studio.jpg';
import Stack from '../../components/Stack';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Close } from '@material-ui/icons';
import PropTypes from "prop-types";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Column from '../../components/Column';
import Row from '../../components/Row';
import "../../index.css";

import { motion } from 'framer-motion';
import { AppBarModule } from '../../components/_app_bar_mod';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background:"black",
        overflowY:'hidden',
        maxWidth:'100%',
        flexGrow: 1,
        '& .MuiDivider-root':{
            backgroundColor:'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root':{
            color: 'white'
        }
      },
      iconBtn:{
        '&:hover': {
            color:"white"
        },
      },
      sideBarSocials:{
          color:"#ffffff47",
          fontSize:"12px",
        '&:hover': {
            color:"white"
        },
      },
      divider:{
          backgroundColor: "rgba(1,1,1, 1)"
      },
      center:{
        textAlign:"center",
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      gridList: {
        width: 500,
        height: 450,
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
      toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
     
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        padding:"3px 1px",
        position:"fixed",
       // background:"linear-gradient(45deg, black, #d09612bd);",
        background: "transparent",
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      socialIcon:{
        color:"#ffffff47",
        '&:hover': {
            color:"white"
        },
      },
      menuButtonHidden: {
        display: 'none',
      },
      title: {
        flexGrow: 1,
      },
      listItem:{
        backgroundColor: "#2C2C2C",
        color: "white",
        margin:10
      },
      listItemImage:{
        height:"60px",
        width:"60px",
        margin:"10px"
      },
     list:{
        transition: theme.transitions.create(["background", "background-color"], {
            duration: theme.transitions.duration.complex,
          }),
          "&:hover": {
            backgroundColor: "#6363638c",
            color:"black",
          },
     },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      grow:{
          '&:hover':{
            transform: 'scale(1.1)',
            easing:"linear",
            cursor: 'pointer'
          }
      },
      link:{
          color:"grey",
        '&:hover':{
            transform: 'scale(1.1)',
            cursor: 'pointer',
            color:'white'
          }
      },
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
      mobileNav:{
          width:"60%"
      },
      pcNav:{
          width:"30%"
      },
      logo:{
          height:"60px",
          width:"60px"
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.text.primary,
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
      fixedHeight: {
        height: 240,
      },
  }));


const BookingPageOne = (props)=>{
  const theme= useTheme()
  const classes = useStyles();
  
  const [,setDevice] = useState('pc');
  const [,setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] =useState(false);
  const [notificationMsg, ] =useState('');

  const [deviceSize,updateSize] = useState({"w":0,"h":0})
    useEffect(()=>{
        updateDevice();
        window.addEventListener("resize", updateDevice);
    },[])
  const updateDevice = ()=>{
    updateSize({"w":window.innerWidth,"h":window.innerHeight-6})
      if(window.innerWidth<550)
      {
          setDevice('mobile');
          setDrawerWidth(300)
      }
      else {
          setDevice('pc')
          setDrawerWidth(280)
        }
  }

  const _buildPage = (()=>{
      //...
      return  <Column style={{width:deviceSize.w,height:deviceSize.h,maxWidth:deviceSize.w }}>
        <Stack style={{width:deviceSize.w,height:deviceSize.h,}}>
           <div style={{background:"#0000000d", height:"100%", width:"100%"}}></div>
           <img alt="studio" src={studio} width="100%" height="100%" style={{objectFit:"cover",filter:"grayscale(1)"}}/>
            <div style={{background:"radial-gradient(circle, rgb(0 0 0 / 96%), black)",height:"100%", width:"100%"}}></div>
           
               
           <div style={{height:"100%",width:"100%", margin: "0 auto"}}>
           <motion.div initial={{opacity:0,scale:1}} animate={{opacity:1}} transition={{delay:0.5, type: "tween", ease:"easeIn",duration:1.5}} style={{height:"100%",width:"100%", margin: "0 auto"}}> 
           <Column style={{width:"100%",height:"100%",maxWidth:deviceSize.w }}>
                
                    <Row alignment="space-between" style={{width:"100%",padding:"0 50px",}}>
                        <Column alignment="centered"> 
                        <motion.div  whileHover={{scale:1.2}} transition={{ type: "tween", ease:"easeIn",duration:0.3}}> 
                            {/* <IconButton style={{width:20,height:20}}>
                            <img style = {{width:20,height:20}} src="https://img.icons8.com/cotton/64/000000/circled-left-2.png"/>
                            </IconButton> */}
                        </motion.div>
                        </Column>
                   
                  <motion.div whileHover={{scale:1.1}} transition={{ type: "tween", ease:"easeIn",duration:0.3}}>  <Typography variant="h1" style = {{color:"white", fontSize:"100px",fontWeight:"lighter", fontFamily:"Raleway",textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 8px #FFFFFF"}}>NOIR</Typography> </motion.div>
                  <Column alignment="centered"> 
                  <motion.div  whileHover={{scale:1.3}} transition={{ type: "tween", ease:"easeIn",duration:0.3}}> 
                  <IconButton style={{width:20,height:20}} onClick = {()=>props.onNext()}>
                    <img alt="" style = {{width:20,height:20}} src="https://img.icons8.com/cotton/64/000000/circled-right--v1.png"/>
                    </IconButton>
                    </motion.div>
                    </Column>
                    </Row>
                   
                <Typography variant="caption" style={{color:`${theme.palette.secondary.main}`,fontWeight:"light",fontFamily:"Raleway",fontSize:"20px"}}>Studios</Typography>
                <div style={{height:"20px"}}/>
                <motion.div  whileHover={{scale:1.3}} transition={{ type: "tween", ease:"easeIn",duration:0.3}}> 
                  <IconButton style={{width:20,height:20}} onClick = {()=>props.onNext()}>
                    <img alt="" style = {{width:30,height:30}} src="https://img.icons8.com/cotton/64/000000/circled-down--v1.png"/>
                    </IconButton>
                    </motion.div>
            </Column>
            </motion.div>
        </div> 
        </Stack>
       
      </Column>
     
  });

 return (<div className={classes.root} style={{cursor:`url('${cursor}'),pointer`,width:deviceSize.w,height:deviceSize.h,}}>
         <CssBaseline />
          <div style={{ background:"black"}}>
              <Stack>
              {_buildPage()}
           
        </Stack>
         </div>
         <AppBarModule pageIndex={1} customRightModule = {<></>} centerModule = {<></>}/>
         <Dialog
      maxWidth="lg"
      onClose={()=>{setShowModal(false)}}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
        {preview_body}
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={notificationMsg.timeout}
        onClose={()=>{
          showNotification(false)
        }}
        message={notificationMsg.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{  showNotification(false)}}>
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
    
    )

   
}
BookingPageOne.propTypes = {
    onNext: PropTypes.func,
  };
  


export default BookingPageOne;