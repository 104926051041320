import { useState } from "react";
import firebase_app from "../config/firebase";
import { retrieveSubmission } from "../logic/databaseHandler";
import { NotificationTypes, sendNotification as sendNotificationToUser } from "./notificationHandler";
import { StoryService } from "../services/storyService";
import { ReadingService } from "../services/readingService";
import { SubmissionService } from "../services/submissionService";
import { AuthService } from "../services/authService";
export const useDatabase = ()=>{
    const {
        getStoryPackages,
        getVideoPackages,
        getStoryPackage,
        getVideoPackage,
    } = SubmissionService();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const {
        createDraftStory,
        getDraftStory,
        getAllDraftStories,
        getUserDrafts,
        deleteDraftStory
        
    } = StoryService();
    const {updateReadingList, getReadingList: getReadings} = ReadingService();
    const updateProfile = async (uid,update) => {
        const {updateUserProfile} = AuthService();
        try{
            setError(null);
            setLoading(true);
            await updateUserProfile({uid, data:update})
            setLoading(false);
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const saveStoryDraft = async (data) => {
        try{
            setError(null);
            setLoading(true);
            await createDraftStory(data);
            setLoading(false);
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
        }
    }
    const getAllDrafts = async (returnAsUsers,{cursor, limit, offset, filter}) => {
        try{
            setError(null);
            setLoading(true);
            const {data: drafts} = await getAllDraftStories({cursor, limit, offset, filter});
            setLoading(false);
            if(returnAsUsers){
                let data={};
                drafts.forEach(story=>{
                    const val = story;
                    if(Object.keys(data).includes(val.userId))
                    data[val.userId].push(val);
                    else
                    data[val.userId] = [val];
                })
                return data;
            }
            return drafts;
        }catch(e){
            console.log(e);
            setError(e);   
            setLoading(false); 
        }
    }
    const deleteDraft = async (sId) => {
        try{
            setError(null);
            setLoading(true);
            await deleteDraftStory({storyId: sId})
            setLoading(false);
            return true;
        }
        catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
        }

    }
    const getAllUserDrafts = async (uid,cursor, limit, offset) => {
        try{
            setError(null);
            setLoading(true);
            console.log(uid,"Getting user drafts...");
            const {data: drafts} = await getUserDrafts({userId:uid, cursor, limit, offset});
            console.log(drafts);
            setLoading(false);
            return drafts;
        }catch(e){
            console.log(e);
            setError(e);   
            setLoading(false); 
        }   
    }
    const getDraft = async (sId) => {
        try{
            setError(null);
            setLoading(true);
            const {data: draft} = await getDraftStory({id: sId});
            setLoading(false);
            return draft?.data;
        }catch(e){
            console.log(e);
            setError(e);   
            setLoading(false); 
        }
    }
    const addToReadingList = async (uid,data) => {
        try{
            console.log(uid);
            setError(null);
            setLoading(true);
            await updateReadingList({userId:uid, id:data.id, content:data});
            setLoading(false);
            return true;
        }
        catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
        }
    }
    const getReadingList = async (uid) => {
        try{
            setError(null);
            setLoading(true);
            console.log("Getting reading list...");
            const {data: readingList} = await getReadings({userId:uid});
            console.log(readingList,"Reading List....");
            setLoading(false);
            return readingList?.data;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
        }
    }
    const sendReport = async (data)=>{
        try{
            setError(null);
            setLoading(true);
            await firebase_app.firestore().collection("Reports").doc(data.id).set(data);
            setLoading(false);
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
        }
    }
    const sendNotification = async (data)=>{
        try{
            setError(null);
            setLoading(true);
            await sendNotificationToUser({
                id:data.id,
                sendTo:data.sendTo,
                sentFrom:data.sentFrom,
                type:data.type,
                title:data.title,
                message:data.message,
                timestamp:data.timestamp,
                seen:data.seen,
                url:data.url
            });
            setLoading(false);
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
        }
    }
    const streamNotifications = async (uid,callback)=>{
        try{
            setError(null);
            for(const type of Object.keys(NotificationTypes)){
                firebase_app.firestore().collection("Notifications").doc(uid).collection(type).where("seen","==",false).onSnapshot((snapshot)=>{
                console.log("streaming notifications");
                const notifications = snapshot.docs.map((doc)=>({...doc.data()}));
                callback(notifications);

            });
            }
            
        }catch(e){
            console.log(e);
            setError(e);
        }
    }
    const openNotification = async (uid,notificationId,type)=>{
        try{
            setError(null);
            await firebase_app.firestore().collection("Notifications").doc(uid).collection(type ?? "APPLICATION").doc(notificationId).update({seen:true});
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            return false;
        }
    }
    const loadPackages = async ({isStory}) =>{
        try{
            setError(null);
            setLoading(true);
            const {data: packages} = await (isStory ? getStoryPackages({}) : getVideoPackages({}));
            setLoading(false);
            console.log(packages)
            return (packages??[]).filter((a)=>a.active).sort((a,b)=>a.index-b.index);
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
        }
    }
    const validateBundle = async({bundleId, isStory})=>{
        try{
            setError(null);
            setLoading(true);
            console.log("Validating bundle...")
            const {data: bundle} = await (isStory ? getStoryPackage({id: bundleId}) : getVideoPackage({id: bundleId}));
            console.log(bundle.data);
            setLoading(false);
            return bundle.data;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const retrieveBundle = async({bundleId, isStory})=>{
        try{
            setError(null);
            setLoading(true);
            const {data: bundle} = await (isStory ? getStoryPackage({id: bundleId}) : getVideoPackage({id: bundleId}));
            setLoading(false);
            return bundle.data;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const getTransactions = async({uid,cat})=>{
        try{
            setError(null);
            setLoading(true);
            const transactions = await firebase_app.firestore().collection("Users").doc(uid).collection("Transactions").where("cat","==",cat??"--").get();
            setLoading(false);
            return transactions.docs.map(doc => doc.data());
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }

    const getSubmission = async(sid)=>{
      return retrieveSubmission(sid);
          
    }
    const retrievePublishedVideo = async (uid,sId) => {
        try{
            setError(null);
            setLoading(true);
            const story = await firebase_app.firestore().collection("Users").doc(uid).collection("PublishedVideos").doc(sId).get();
            setLoading(false);
            return story.data();
        }catch(e){
            console.log(e);
            setError(e);   
            setLoading(false); 
        }   
    }
    const retrieveServicePackage = async (id) =>{
        try{
            setError(null);
            setLoading(true);
            const servicePackage = await firebase_app.database().ref("ServicePackages").child(id).once("value");
            setLoading(false);
            console.log(servicePackage.val());
            return servicePackage.val();
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const updateServicePackage = async (id,update) =>{
        try{
            setError(null);
            setLoading(true);
            await firebase_app.database().ref("ServicePackages").child(id).update(update);
            setLoading(false);
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const deletePackageServicePackage = async (id) =>{
        try{
            setError(null);
            setLoading(true);
            await firebase_app.database().ref("ServicePackages").child(id).remove();
            setLoading(false);
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const getAllAgreements = async ()=>{
        try{
            setError(null);
            setLoading(true);
            const agreements = await firebase_app.database().ref("Agreements").once("value");
            setLoading(false);
            return agreements.val();
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }

    const getAllCustomerAgreements = async ()=>{
        try{
            setError(null);
            setLoading(true);
            const agreements = await firebase_app.database().ref("Agreements").orderByChild("agreementType").equalTo("customer").once("value");
            setLoading(false);
            return agreements.val();
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const addServicePackage = async (data) =>{
        try{
            setError(null);
            setLoading(true);
            await firebase_app.database().ref("ServicePackages").child(data.id).set(data);
            setLoading(false);
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
        }
    }
    const addAgreement = async (agreement)=>{
        try{
            setError(null);
            setLoading(true);
            await firebase_app.database().ref("Agreements").child(agreement.id).set(agreement);
            setLoading(false);
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const getAgreement = async (id)=>{
        try{
            setError(null);
            setLoading(true);
            const agreement = await firebase_app.database().ref("Agreements").child(id).once("value");
            setLoading(false);
            return agreement.val();
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const retrieveServicePackagesBy = async (uid)=>{
        try{
            setError(null);
            setLoading(true);
            const servicePackages = await firebase_app.database().ref("ServicePackages").orderByChild("createdBy").equalTo(uid).once("value");
            console.log(servicePackages.val());
            setLoading(false);
            return servicePackages.val();
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    
    const getRoles = async ()=>{
        try{
            setError(null);
            const roles = await firebase_app.database().ref("Roles").once("value");
            return roles.val();
        }catch(e){
            console.log(e);
            setError(e);
            return false;
        }
    }
    const submitServiceAgentForm = async (data)=>{
        try{
            setError(null);
            setLoading(true);
            await firebase_app.firestore().collection("ServiceAgentForms").doc(data.id).set(data);
            setLoading(false);
            return true;
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const updateServiceAgentApplication = async (id,update)=>{
        try{
            setError(null);
            setLoading(true);
            const applications = await firebase_app.firestore().collection("ServiceAgentForms").doc(id).update(update);
            setLoading(false);
            return applications.data();
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const retrieveApplicationsBy = async (uid)=>{
        try{
            setError(null);
            setLoading(true);
            const serviceAgentForm = await firebase_app.firestore().collection("ServiceAgentForms").where("uid","==",uid).get();
            setLoading(false);
            return serviceAgentForm.docs.map(doc => ({...doc.data(), type: "Service Agent"}));
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const retrieveApplication = async (id)=>{
        try{
            setError(null);
            setLoading(true);
            const serviceAgentForm = await firebase_app.firestore().collection("ServiceAgentForms").doc(id).get();
            setLoading(false);
            return serviceAgentForm.data();
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const streamApplicationsBy =  (uid,callback)=>{
        try{
            setError(null);
            setLoading(true);
             firebase_app.firestore().collection("ServiceAgentForms").where("uid","==",uid).onSnapshot((snapshot)=>{
                const applications = snapshot.docs.map((doc)=>({...doc.data(), type: "Service Agent"}));
                callback(applications); setLoading(false);
            });
           
          
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    const getActiveProjectsWithPackage = async (packageId)=>{
        try{
            setError(null);
            setLoading(true);
            const projects = await firebase_app.database().ref("ServiceRequests").orderByChild("packageId").equalTo(packageId).once("value");
            setLoading(false);
            return projects?.docs?.map(doc => doc.data());
        }catch(e){
            console.log(e);
            setError(e);
            setLoading(false);
            return false;
        }
    }
    return {
        
        loading,
        error,
        saveStoryDraft,
        getAllDrafts,
        deleteDraft,
        getAllUserDrafts,
        updateProfile,
        getDraft,
        addToReadingList,
        getReadingList,
        sendReport,
        sendNotification,
        openNotification,
        streamNotifications,
        loadPackages,
        validateBundle,
        retrieveBundle,
        getTransactions,
        getSubmission,
        retrievePublishedVideo,
        retrieveServicePackagesBy,
        retrieveServicePackage,
        getAllAgreements,
        getAllCustomerAgreements,
        addServicePackage,
        addAgreement,
        getAgreement,
        getRoles,
        submitServiceAgentForm,
        retrieveApplicationsBy,
        retrieveApplication,
        updateServiceAgentApplication,
        streamApplicationsBy,
        updateServicePackage,
        deletePackageServicePackage,
        getActiveProjectsWithPackage
    }
}