import React from 'react';

import { motion } from 'framer-motion';
import PropTypes from "prop-types";
import Column from './Column';
function StudioSeatItem(props){

  const isOpen = props?.isOpen === undefined ? true : props.isOpen
    return (
      <div style={{position:"relative"}}>
        <motion.div
        initial={{color: "#C4C4C4"}}
        whileHover={{scale:1.1,cursor: "pointer"}}
          animate={{color:!isOpen ?"#C4C4C4":"#1E1E1E"}}
          transition={{type: "spring", ease: "easeIn", duration: 0.8 }}
          style={{color:!isOpen ? "#C4C4C4":"#1E1E1E",fontSize:"30px",}}
          onClick={()=>{
  
              props.onClick(isOpen,props.value)
          }}
      >
        <Column alignment="centered" style={{height:"100%"}}>
        <i className='fas fa-chair'/>
        </Column>
         
      </motion.div>
      
      </div>
      )
  
    
  }
  StudioSeatItem.propTypes = {
    onClick: PropTypes.func,
    value: PropTypes.number,
    isOpen:PropTypes.bool,
  };

  export default StudioSeatItem