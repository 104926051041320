/* eslint-disable react-hooks/exhaustive-deps */

import { Card, CardActionArea, CardContent, CardMedia, Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import '../bubble.css';
import Column from './Column';
import Row from './Row';
import { ShowcaseCard } from './_showcase_card';
import PropTypes from 'prop-types'


export const MobileShowcaseModule = (props)=>{
    const [currentShowcase, setShowCase] = useState({})


    useEffect(()=>{

        setShowCase(props.showcase[0])
    },[])


    return (
        <>
            <Column style={{width:"100%",height:"100%"}}>
            <Card style={{width:"100%",height:"50%", background:props.backgroundColor??"#0D0D0E"}}>
                <CardActionArea style={{width:"100%",height:"100%"}}>
                    <CardMedia style={{width:"100%",height:"80%"}}>
                        {
                            !currentShowcase.video?  <div style={{width:"100%", height:"100%"}}>
                                <img alt="showcase" src={currentShowcase.image} width="100%" height="100%" style={{objectFit:"cover"}}/>
                            </div>:<div style={{width:"100%", height:"100%"}}>
                                <ReactPlayer playsinline width="100%" height="100%" url={currentShowcase.video}/>
                            </div>
                        }
                    </CardMedia>
                    <CardContent>
                    <Typography variant="title" color="textPrimary" style={{color:"white"}} component="p">{currentShowcase.caption}</Typography>
                    <Typography variant="body2" color="textSecondary" style={{color:"whitesmoke"}} component="p">{currentShowcase.body}</Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <Row alignment="centered"style={{padding:"20px",width:"100%",height:"50%"}}>
               
                     <Grid container spacing={3} style={{width:"100%",height:"100%"}}>
                    {
                        props.showcase.slice(1,props.showcase.length).map((data, index)=>(
                        <Grid xs={6} key={index}>
                            <ShowcaseCard image={data.image}
                            onClick={()=>{
                             
                                var temp = props.showcase[0];
                                props.showcase[0] = props.showcase[index+1];
                                props.showcase[index+1] = temp;
                                console.log('====================================');
                                console.log(props.showcase[0]);
                                console.log('====================================');
                                setShowCase(props.showcase[0]);
                            }}
                            video={data.video}
                            caption={data.caption}
                            body={data.body}
                            fontFraction={"100%"}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Row>  
           
            </Column>
        </>
    )
}

MobileShowcaseModule.propTypes = {
    showcase: PropTypes.array,
    backgroundColor: PropTypes.string
}