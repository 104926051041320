import { useState } from "react";
import firebase_app from "../config/firebase";
import { AuthService } from "../services/authService";


export const useProfile = () => {
    const [loadingProfile, setLoadingProfile] = useState(false);
    const { getUserProfile } = AuthService();
    const retrieveProfileWithCallback = (callback, errorCallback)=>{
        firebase_app.auth().onAuthStateChanged(async (user) => {
            try{
                const profileResult = await retrieveProfile(user.uid);
                callback(profileResult);
            }catch(e){
                errorCallback && errorCallback(e);
            }
        }, (error) => {
            errorCallback && errorCallback(error);
        });
    }
    const retrieveProfile= async (uid) => {
        setLoadingProfile(true);
      
        const {data: profile} = await getUserProfile({uid: uid});
        console.log(profile);
          const profileData = profile.data;
            setLoadingProfile(false);
          return profileData;
    }
    return {retrieveProfile,retrieveProfileWithCallback,loadingProfile}
}