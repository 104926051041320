/* eslint-disable react-hooks/exhaustive-deps */

import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import firebase_app from "../config/firebase";

import PropTypes from 'prop-types';


const ValidUserRequired = ()=>{
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)
    const parseUrl = ()=>{
        let url = window.location.pathname.replace(window.location.origin+"/","");
        
        let urlParts = url.split("/");
        console.log(url,urlParts);
        let len = urlParts.length;
        if(len > 2)
        return {id: urlParts[len-2], name: urlParts[len-1]}
        else if(len >= 1)
        return {id: urlParts[len-1]}
        else return {id: "", name: ""}
    }
    useEffect(()=>{
        let userId = parseUrl()["id"];
        console.log(userId)
        firebase_app.database().ref("Users").child(userId).once("value",(snapshot)=>{
           console.log(snapshot.val())
            if(snapshot.val() === null || snapshot.val()["uid"] !== firebase_app.auth().currentUser?.uid){
             setShowModal(true);
             navigate(-1);
            }
           
        })
    },[]);
   
    return (
        <div>
            <Dialog open = {showModal} >
                <DialogTitle>This is not your account</DialogTitle>
                <DialogContent>You will now be redirected</DialogContent>
               
            </Dialog>
        </div>
    )
}
export default ValidUserRequired;

ValidUserRequired.propTypes = {
    children: PropTypes.node,
}