
import React,{ createContext, useContext, useState } from "react";
import { StoryService } from "../services/storyService";
import PropTypes from 'prop-types';


export const useStoryProvider = () => {
    const {
        allStories,
        currentStoryLikes,
        currentStoryViews,
        maxStorySize,
        queryResults,
        maxQueryResultsSize,
        addStory,
        getStory,
        getAllStories,
        getAllStoriesByPublished,
        getAllStoriesByPopular,
        getAllStoriesByTrending,
        getAllStoriesUsers,
        getDTVStories,
        getPublicStories,
        getAllStoriesBy,
        getStoriesBetween,
        getLikes,
        likeStory,
        viewStory,
        queryStories,
        updateStoryDB,
        approveStory,
        deleteStory,
        lastStoryCursor,
        getAllUserDrafts,
        getAllDrafts,
        deleteDraftStory,
        getAllDraftsAsUsers,
        getMoreStoriesBy

    } = useContext(StoryContext);
    return {
        allStories,
        currentStoryLikes,
        currentStoryViews,
        maxStorySize,
        queryResults,
        maxQueryResultsSize,
        addStory,
        getStory,
        getAllStories,
        getAllStoriesByPublished,
        getAllStoriesByPopular,
        getAllStoriesByTrending,
        getAllStoriesUsers,
        getDTVStories,
        getPublicStories,
        getAllStoriesBy,
        getStoriesBetween,
        getLikes,
        likeStory,
        viewStory,
        queryStories,
        updateStoryDB,
        approveStory,
        deleteStory,
        lastStoryCursor,
        getAllDraftsAsUsers,
        getAllUserDrafts,
        getAllDrafts,
        deleteDraftStory,
        getMoreStoriesBy
    }
}
export const StoryContext = createContext();



const StoryProvider = ((props) => {

    const [allStories, updateAllStories] = useState();
    const [currentStoryLikes, updateLikes] = useState([]);
    const [currentStoryViews, updateViews] = useState();

    const [maxStorySize, setMaxSize] = useState(0);
    const [queryResults, setQueryResults] = useState([]);
    const [maxQueryResultsSize, setMaxQuerySize] = useState([]);
    const [lastStoryCursor, setLastStoryCursor] = useState(null);
    const { createStory,
        getStory,
        updateStory,
        deleteStory,
        approveStory,
        toggleLike,
        viewStory,
        getStoryLikes,
        getStoryViews,
        searchStory,
        getStories,
        getStoriesByUser,
        getRelatedStories,
        getStoryWithDateFilter,
        getStoryWithDTVFilter,
        getStoryWithFreelancerFilter,
        getStoryWithPopularFilter,
        getStoryWithTrendingFilter,
        getPublishedStories,
        getUserStories,
        getAllDraftStories,
        getUserDrafts,
        deleteDraftStory,
        getRelatedStoriesByAuthor,} = StoryService();
    
    const updateStoryDB = async (data) => {
        try {
                await updateStory(data);
                return true;
        } catch (e) {
            console.error()
            return e;
        }
    }

    //Method to get all the stories from pages (min->max)
    const getAllStories = async (cursorData, limit = 20, offset = 0) => {
       
       
        try { 
                const {total, cursor, data} = await getStories({cursor:cursorData, limit, offset});
                console.log(data)
                updateAllStories(data??[])
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;

        } catch (e) {
            console.error()
            return e;
        }
    }

    //Method to get all the stories from pages (min->max) ordered by publishedAt
    const getAllStoriesByPublished = async (cursorData, limit,offset=0,order) => {
       
        try {
                const {data, total, cursor} = await getPublishedStories({cursor:cursorData, limit, offset,order:order});
                console.log(data)    
                updateAllStories(data)
                    setLastStoryCursor(cursor)
                    setMaxSize(total)
                    return data;
            

        } catch (e) {
            console.error()
            return e;
        }
    }

    //Method to get all the stories from pages (min->max) ordered by popularity (likes+views)
    const getAllStoriesByPopular = async (cursorData, limit,offset=0,order) => {

        try {
                const {data, total, cursor} = await getStoryWithPopularFilter({cursor:cursorData, limit, offset,order:order});
                updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;

        } catch (e) {
            console.error()
            return e;
        }
    }
    //Method to get all the stories from pages (min->max) ordered by popularity (likes+views)
    const getAllStoriesByTrending = async (cursorData, limit,offset=0) => {
    
        try {
                const {data, total, cursor} = await getStoryWithTrendingFilter({cursor:cursorData, limit, offset});
                updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;

        } catch (e) {
            console.error()
            return e;
        }
    }


    //Method to get all stories from pages(min->max) in map form (userId -> {...})
    const getAllStoriesUsers = async (cursorData,limit,offset=0,{isAdmin}) => {
       
        try {
                const {data, total, cursor} = await getStoriesByUser({cursor:cursorData, limit, offset, filter: { isAdmin }});
                console.log(data)
                updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;

        } catch (e) {
            console.error()
            return e;
        }
    }

    //Method to get all stories from pages(min->max) in map form (userId -> {...})
    const getAllStoriesBy = async (id, cursorData, limit,offset=0) => {
      
        
        try {
            console.log(id, cursorData, limit,offset)
                const {data, total, cursor} = await getUserStories({cursor:cursorData, limit: limit ?? 50, userId: id, offset});
                //updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;
        } catch (e) {
            console.error()
            return e;
        }
    }

    const getDTVStories = async (cursorData, limit,offset=0) => {
      
        try {
                const {data, total, cursor} = await getStoryWithDTVFilter({cursor:cursorData, limit, offset});
                updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;

        } catch (e) {
            console.error()
            return e;
        }
    }

    const getPublicStories = async (cursorData, limit,offset=0) => {
        try {
                const {data, total, cursor} = await getStoryWithFreelancerFilter({cursor: cursorData, limit, offset});
                updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;
        } catch (e) {
            console.error()
            return e;
        }
    }
    const getAllUserDrafts = async ({userId, cursorData, limit,offset=0}) => {
        try {
                const {data, total, cursor} = await getUserDrafts({userId, cursor:cursorData, limit, offset});
                updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;
        } catch (e) {
            console.error()
            return e;
        }
    }
    const getAllDrafts = async (cursorData, limit,offset=0) => {
        try {
                const {data, total, cursor} = await getAllDraftStories({cursor:cursorData, limit, offset});
                updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;
        } catch (e) {
            console.error()
            return e;
        }
    }
    const getAllDraftsAsUsers = async (cursorData, limit,offset=0,{isAdmin}) => {
        try {
                const {data, total, cursor} = await getAllDraftStories({cursor:cursorData, limit, offset, filter: { isAdmin }});
                let users = {}
                data.forEach((story)=>{
                    if(users[story.userId]){
                        users[story.userId].push(story)
                    }else{
                        users[story.userId] = [story]
                    }
                })
                console.log(users)
                updateAllStories(users)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return users;
        } catch (e) {
            console.error()
            return e;
        }
    }
    const getMoreStoriesBy = async (id, cursorData, limit,offset=0) => {
        try{
            const {data, total, cursor} = await getRelatedStoriesByAuthor({cursor:cursorData, limit, storyId: id, offset});
            updateAllStories(data)
            setLastStoryCursor(cursor)
            setMaxSize(total)
            return data;
        }catch(e){
            console.error()
            return e;
        }
    }
    const getRelatedStoriesByTags = async (tags, cursorData, limit,offset=0) => {
        try {
                const {data, total, cursor} = await getRelatedStories({cursor:cursorData, limit, tags, offset});
                updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;
        } catch (e) {
            console.error()
            return e;
        }
    }
    const getStoriesBetween = async (fromDate, toDate, cursorData, limit,offset=0) => {
       
        try {
                const {data, total, cursor} = await getStoryWithDateFilter({cursor:cursorData, limit, fromDate, endDate: toDate, offset});
                updateAllStories(data)
                setLastStoryCursor(cursor)
                setMaxSize(total)
                return data;
        } catch (e) {
            console.error()
            return e;
        }
    }

    //Method to get add a new story
    const addStory = async (story) => {
        return await createStory(story);
    }


    //Method to get the likes for a specific story
    const getLikes = async (storyId) => {
        try {
            const {data} = await getStoryLikes({storyId});
            updateLikes(isNaN(data.count) ? 0 : data.count)
            return data;
            
        } catch (e) {
            console.error()
            return e;
        }
    }

    //Method to inc. likes for a specific story
    const likeStory = async (storyId, userId) => {
        return await toggleLike({storyId, userId});
    }

    //Method to get views for a specific story
    const getViews = async (storyId) => {
        try {
            const {data} = await getStoryViews({storyId});
            updateViews(isNaN(data.count) ? 0 : data.count)
            return data;
        } catch (e) {
            console.error()
            return e;
        }
    }

    //Method to get the stories searched for matching headline and tags from (min->max)
    const queryStories = async (query, cursorData, limit,offset=0) => {
        
        try {
                const {data, total,} = await searchStory({cursor:cursorData, limit, query, offset});
                setQueryResults(data??[])
                setMaxQuerySize(total)
                return data;
        } catch (e) {
            return e;
        }


    }
    const getStoryWithId = async (id) => {
        try {
            const {data} = await getStory({id});
            return data;
        } catch (e) {
            return e;
        }
    }
    return <StoryContext.Provider value={{
        allStories,
        currentStoryLikes,
        currentStoryViews,
        maxStorySize,
        queryResults,
        maxQueryResultsSize,
        lastStoryCursor,
        addStory,
        getStory:getStoryWithId,
        getAllStories,
        getAllStoriesByPublished,
        getAllStoriesByPopular,
        getAllStoriesByTrending,
        getAllStoriesUsers,
        getDTVStories,
        getPublicStories,
        getAllStoriesBy,
        getStoriesBetween,
        getLikes,
        likeStory,
        viewStory,
        queryStories,
        updateStoryDB,
        approveStory,
        deleteStory,
        getAllUserDrafts,
        getAllDrafts,
        deleteDraftStory,
        getAllDraftsAsUsers,
        getMoreStoriesBy,
        getRelatedStories: getRelatedStoriesByTags,
        getViews

    }}>
        {props.children}
    </StoryContext.Provider>
})
export default StoryProvider;

StoryProvider.propTypes = {
    children: PropTypes.node
}