import React, { useEffect, useState } from 'react';
import { Box, Slider, } from '@material-ui/core';
import '../ProgressSlider.css';
import PropTypes from 'prop-types';


const ProgressSlider = ({progress:Progress, onUpdateProgress, total}) => {
  const [progress, setProgress] = useState(Progress);
  useEffect(() => {
    setProgress(Progress);
  }, [Progress]);
  const handleSliderChange = (event, value,) => {
    setProgress((value/100)*total);
    if(onUpdateProgress)
    onUpdateProgress((value/100)*total);
  };


  return (
    <Box sx={{ width: '100%',padding: '0 30px' }}>
      <Slider
        size="small"
        // marks={markers.map((value) => ({ value, label: `${value}%` }))} // Add markers
        valueLabelDisplay="off"
        value={(progress/total)*100}
        min={0}
        max={100}
        style={{padding:0}}
        onChange={handleSliderChange}
        aria-label="Small"
      />
    </Box>
  );
};

export default ProgressSlider;

ProgressSlider.propTypes = {
  progress: PropTypes.number,
  onUpdateProgress: PropTypes.func,
  total: PropTypes.number,
}