/* eslint-disable react-hooks/exhaustive-deps */

import { Avatar, Button, Card, CardActions, CardMedia, CssBaseline, Grid, IconButton, Tooltip, Typography } from "@material-ui/core"
import React, { useEffect, useRef, useState } from "react"
import { Gallery } from "react-grid-gallery"
import { useLocation, useNavigate } from "react-router"
import { CardContent, Icon } from "semantic-ui-react"
import Column from "../../components/Column"
import Row from "../../components/Row"
import Stack from "../../components/Stack"
import useServiceBooking from "../../contexts/ServiceBookingProvider"
import { useStyles } from "../show_page"
import { ArrowDownward, StarBorder } from '@material-ui/icons'
import Lightbox from "react-image-lightbox"
 import "react-image-lightbox/style.css"
import { AppBarModule } from "../../components/_app_bar_mod"
import { Rating } from "@mui/material"
import { MarkdownTextField } from "../../components/_new_story_comp"
import {AnimatePresence, motion} from 'framer-motion'
import { useDatabase } from "../../hooks/useDatabase"
import ServicePakageCard from "../../components/_service_package_card"
import FutureBuilder from "../../components/_future_builder"
import { parseUrlForQueryParams } from "../../utils"
import BookAgentRoom from "./book_agent_room"
import Ratings from "../../components/ratings"
import { ProfileAvatar } from "../../components/profile/main/_profile_avatar"
const ServiceAgentPage = () => {
    const classes = useStyles();
    const [index, setIndex] = useState(-1);
    const [images, setImages] = useState(null);
    const [currentImage, setCurrentImages] = useState(null);
    const [nextIndex, setNextIndex] = useState(-1);
    const [nextImage, setNextImage] = useState(null);
    const [prevIndex, setPrevIndex] = useState(-1);
    const [prevImage, setPrevImage] = useState(null);
    const [showMoreBio, setShowMoreBio] = useState(false);
    const [, updatePackages] = useState([]);


    
    const {retrieveServicePackagesBy,} = useDatabase();

  
    const handleClick = (index,)=>{
        setIndex(index);
        updateImages(images);
    }
    const handleClose = () => {
        setIndex(-1);
        setCurrentImages(null)
    }
    const handleMovePrev = () => setIndex(prevIndex);
    const handleMoveNext = () => setIndex(nextIndex);
    const {
        getAgent,
    } = useServiceBooking();
    const history = useNavigate();
    const location = useLocation();
    const bookingRef = useRef(null);
    
  const params = parseUrlForQueryParams(window.location.href);
    const [agent, setAgent] = useState(null);
   
    const parseID = (path, key) => {
        //we want wants directly after the key
        const parts = path.split("/");
        let pathObj = {};
        let index = 0;
        parts.forEach((part) => {
            pathObj[part] = parts[index + 1];
            if(index<parts.length-1)index++;
        });
        console.log(pathObj);
        return pathObj[key];
    }
    const updateImages = (imgs) => {

        setCurrentImages(index === -1 ? imgs[index]:imgs[index]);
        setNextIndex((index + 1) % imgs.length);
        setPrevIndex((index + imgs.length - 1) % imgs.length);
        setNextImage(imgs[nextIndex] || currentImage);
        setPrevImage(imgs[prevIndex] || currentImage);
        
     }
    const updateDataset = (agent) => {
        setAgent(agent);
        let images = [];
        if(agent.portfolio)
        agent.portfolio.forEach((src) => {
            images.push({
                src: src,
            });
        })  
        setImages(images);
        updateImages(images);
       
    }
    useEffect(() => { 
        const fetchData = async () => {
         
            const agent = await getAgent(parseID(location.pathname,"booking"));
            console.log(agent)
            retrieveServicePackagesBy(agent.id).then((pcks)=>{
                const arrPackages = Object.values(pcks);
                console.log(arrPackages)
                 if(pcks)
                 updatePackages(arrPackages);
              })
            updateDataset(agent);
            
        }
        if(!params.agent){
            fetchData();
        }
       
    },[]);
    //build ratings stars here using ratings where each item is a rating
  const _buildRatings = ()=>{
    const ratings = Object.values(agent?.ratings ?? {});
    const ratingsNumber = ratings.map((rating)=>Number(rating));
        const avgRating = ratingsNumber.length > 1 ? 
        ratingsNumber.reduce((a,b)=>a+b,0)/ratingsNumber.length : 
        ratingsNumber[0]??0;
      return <Row>
        <Tooltip title={`${avgRating} out of 5`}>
        <Rating
          name="star-rating"
          value={avgRating}
          precision={0.5}
          readOnly
          size="large"
          color="textPrimary"
          style={{
            color:'white',
          }}
          max={5}
          emptyIcon={<StarBorder style={{ opacity: 0.55, color: 'white' }} fontSize="inherit" />}
          />
          </Tooltip>
      </Row>
  }   
    const _buildIntro = () => {
        const src = (agent.portfolio ?? ["https://media.istockphoto.com/id/1312002976/photo/portrait-of-beard-african-american-professional-cameraman-with-glasses-in-the-studio.jpg?s=612x612&w=0&k=20&c=RNMKcxNm78n5SXxluk5VwGoWlfUyOLGMRClGNNy4tWk="])[0];

        return <Column style={{ widht: "100%", height: "100vh", }}>
           
            <Stack>
                {
                    <img alt="" src={src} style={{ objectFit: "cover", width: "100%", height: "95%", filter:"blur(5px)" }} />
                    
                }
                {
                    <div style={{width:"100%",height:"100%", background: "linear-gradient(0deg, black,#000000cf)" }} />
                }
                <Row style={{width:"100%", height:"100%"} }>
                    <Column crossAlignment="center" alignment={"centered" } style={{width:"100%", height:"100%"}}>
                    <Avatar style={{ width: 200, height: 200 }} src={agent.picture} />
                    <div style={{ height:30} } />
                    <Typography variant="h4" style={{ fontSize: "6vh", fontWeight:"bold", textTransform:"uppercase", margin:"0 15px"} } >{agent.name}</Typography>
                    <Typography variant="h6" style={{ color: "white", fontSize: "2vh", textTransform:"uppercase",margin:"0 15px",fontWeight:"400" }}>{agent.serviceType?.name}</Typography>
                    {_buildRatings()}
                    {/* <Typography variant="caption" style={{ color: "white",margin:"0 15px",fontSize: "1.5vh",fontWeight:"bold" }}>{`${ SiteData?.bookings?.services?.enablePayment ? "" : (formatter.format(agent.minPrice)+ "-")} ${formatter.format(agent.maxPrice)}`}</Typography> */}
                    <Typography variant="h6" style={{ color: "white",margin:"0 15px",fontWeight:"400",textTransform:"uppercase", }}>{agent.location}</Typography>
                        <div style={{ height: 20 }} />
                        <Button variant="contained" color="primary" onClick={() => {
                           scrollToBooking()
                        } }>Book Now</Button>
                    <div style={{ height:80} } />
                        <IconButton  onClick={() => {
                           scrollToBooking()
                        }}><Icon>
                        <ArrowDownward/>
                        </Icon></IconButton>
                </Column>

            </Row>
                
            </Stack>
        </Column>
    }

    const buildImagePortfolio = () => {
        return <Column>
        <Gallery
            onClick={handleClick}
            images={images}
            enableImageSelection={false}
        />
        {!!currentImage && (
          /* @ts-ignore */
          <Lightbox
            mainSrc={currentImage?.src}
            mainSrcThumbnail={currentImage?.src}
            nextSrc={nextImage?.src}
            nextSrcThumbnail={nextImage?.src}
            prevSrc={prevImage?.src}
            prevSrcThumbnail={prevImage?.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
    </Column>
    }
    const _buildVideoPortfolio = () => {
        return <Grid container spacing={2}>
            {
                agent.portfolio?.map((src,index) => {
                    return <Grid key={"prtf"+index} item xs={12} sm={6} md={6} lg={6} xl={6}>
                        <Card variant="outlined" style={{ width: "100%", height: "100%", background: "#080808" }}>
                            <CardMedia
                                component="video"
                                controls
                                muted
                                src={src}
                                style={{ width: "100%", height: "100%" }}
                            />
                        </Card>
                    </Grid>
                })
            }
            </Grid>
    }
    const _buildPortfolio = () => {
            return agent?.serviceType?.style === 'VIDEO' ?  _buildVideoPortfolio() : buildImagePortfolio();
        }
    const _buildBio = () => {
        return <Row alignment={ "centered" } style={{zIndex:105}}>
           
              <Card variant="outlined" style={{width:"100%",padding:40, background: '#080808'} }>
               <CardContent>
                    <Column>
                    
                <Typography style={{fontSize:"2vh",marginBottom: 10} } align="left">ABOUT ME</Typography>
 <AnimatePresence initial={false}>
                    <motion.div  
                    style={{ overflow: 'hidden'}}
                    initial="collapsed"
              animate={showMoreBio ? 'expanded' : 'collapsed'}
              variants={{
                expanded: { height: 'auto' },
                collapsed: { height: '21.6em' },
              }}
              transition={{ duration: 0.3 }} >
                <MarkdownTextField  defaultValue={agent?.bio?.text} style={agent?.bio?.style} viewMode={true} />
            </motion.div>
                </AnimatePresence>
                 {agent?.bio?.text?.length > 300 && (
                    <Row style={{width:"100%"}} alignment="right">
                        <Button style={{marginTop: 20, width: 100}} onClick={()=>{
                        setShowMoreBio(!showMoreBio);
                    }} color="default" size="small">
                        {showMoreBio ? 'Show Less' : 'Show More'}
                    </Button>
                    </Row>
                    )}
                    </Column>
                   
                </CardContent>
            </Card>
        </Row>
    }
   
   const _buildReviews = () => {
        let reviews = Object.values(agent?.reviews ?? {});
        reviews = reviews.slice(0, 3);
        return <Grid container spacing={2} style={{marginTop:"2rem",}}>
            {reviews.map((review, index)=>{
                return <Grid item xs={12} sm={12} md={12} lg={4} key={index} >
                    <Card variant="outlined" style={{width:"100%",padding:20, maxWidth:400, minWidth:300, background: '#080808', textAlign:"start"} }>
                        <CardContent>
                            <Column>
                            <Ratings value={review?.rating} editable={false}/>
                            <div style={{height:10}}/>
                            <Typography variant="body2">{review?.review}</Typography>
                            </Column>
                        </CardContent>
                        <CardActions style={{paddingLeft:0}}>
                            <ProfileAvatar textProps={{
                               style:{fontSize: 10}
                            }} uid={review?.reviewer} onlyName/>
                          <Typography variant="caption" style={{fontSize: 10}}>{
                            Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(review?.timestamp))
                          }</Typography>
                        </CardActions>
                    </Card>
                </Grid>
            })}
        </Grid>
   }
   const scrollToBooking = () => {
    // Scroll to the div element
    bookingRef.current.scrollIntoView({ behavior: 'smooth' });
  };
   const _buildServicePackages = ()=>{
         return <div ref={bookingRef}><FutureBuilder 
            errorChild = {<></>}
            futureFunc = {()=>retrieveServicePackagesBy(agent.id)}
            builder = {
                (pkgs)=>{
                    const packages = Object.values(pkgs);
                    console.log(packages)
                    return <Grid container spacing={10} style={{marginTop:"2rem",}}>
                    {packages.map((item, index)=>{
            
                       
                        return <Grid item xs={12} sm={12} md={12} lg={6} key={index} >
                            <motion.div initial={{scale: Math.floor(packages.length / 2) === index ? 1.02 : 0.95}} whileHover={{scale:Math.floor(packages.length / 2) === index ? 1.02:1, cursor: 'pointer'}}>
                                
                                <ServicePakageCard style={{
                                    height: 400,
                                    width: "100%",
                                    maxWidth: 300,
                                }} handlePackageSelection={(item)=>{
                                    history(`/booking/book?agent=${agent.id}&package=${item.id}`)
                                }} existingServicePackage={item}/>
                                </motion.div>
                            </Grid>
                    })}
                        </Grid>
                }
            }
         />
            </div>
   }
   
    const _buildPage = () => {
        return !agent ? <></> : <Column >
            {_buildIntro()}
            
            <div style={{ maxWidth:800, margin:"30px auto",padding:30,width:"100%",zIndex:110} }>
                {_buildBio()}
                <div style={{height:100}} />
                <Column style={{width:"100%", alignItems: "center",}} >
                <Typography style={{fontWeight:"bold", textTransform:"uppercase", margin:"0 15px"} } >
                    portfolio
                </Typography>
                <Row style={{width:"100%"}} alignment="centered">
                    <div style={{height:5, width:100, backgroundColor:"white", borderRadius: 10,margin:"5px 0"}} />
                </Row>
                </Column>
                
                <div style={{height:50}} />
                {_buildPortfolio()}
                { <>
                <div style={{height:150}} />
                <Column style={{width:"100%", alignItems: "center",}} >
                <Typography style={{fontWeight:"bold", textTransform:"uppercase", margin:"0 15px"} } >
                packages
                </Typography>
                
                <Row style={{width:"100%"}} alignment="centered">
                    <div style={{height:5, width:100, backgroundColor:"white", borderRadius: 10,margin:"5px 0"}} />
                </Row>
                </Column>
                <div style={{height:30}} />
                {_buildServicePackages()}
                </>}
                {!agent?.reviews?<></> :<>
                 <div style={{height:150}} />
                <Column style={{width:"100%", alignItems: "center",}} >
                <Typography style={{fontWeight:"bold", textTransform:"uppercase", margin:"0 15px"} } >
                REVIEWS
                </Typography>
                
                <Row style={{width:"100%"}} alignment="centered">
                    <div style={{height:5, width:100, backgroundColor:"white", borderRadius: 10,margin:"5px 0"}} />
                </Row>
                </Column>
                {_buildReviews()}
                <div style={{height:100}} />
            </>}
            </div>
        </Column>
     }
     console.log(params);
    return (
        params.package ? <BookAgentRoom/>: <div className={classes.root}>
            <CssBaseline />
            <Stack>
            <div style={{ background: "#0000000d", height: "100%", width: "100%" }}></div>
            <div style={{ background: "linear-gradient(0deg, black 20%,#000000ff 54%, transparent)", height: "100%", width: "100%" }}></div>
                {_buildPage()} 
              
                
            </Stack>
            <AppBarModule pageIndex={ 7 } />
         
        </div>
    )
}
export default ServiceAgentPage;