import { Avatar, Typography } from "@material-ui/core";
import { HeadsetRounded } from "@material-ui/icons";
import PropTypes from "prop-types";
import { firestore } from "../config/firebase";
import FutureBuilder from "./_future_builder";
import React from "react";
export const StudioRoomImage = (props)=>{
    return <>
        <FutureBuilder 
            future = {firestore.collection("StudioRooms").doc(props.id).get()}
            builder={(snap)=>{
                console.log(snap.data())
                return (snap.data()?.image??"") !== "" ? <Avatar style={{...props.style}} {...props} src = {snap.data().image}/>:<HeadsetRounded {...props}/>}}
            errorChild={<p>..</p>}
            placeholder={<Typography>--</Typography>}
        />
    </>
}
StudioRoomImage.propTypes = {
    id:PropTypes.string,
    style:PropTypes.object
}