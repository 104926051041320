import { Typography } from "@material-ui/core"
import firebase_app from "../config/firebase"
import FutureBuilder from "./_future_builder"
import React from "react"

import PropTypes from 'prop-types';


export const UsernameText = (props)=>{
    return !props?.uid ? <div /> : <>
        <FutureBuilder 
            // rebuild ={true}
            future = {firebase_app.database().ref().child("Users").child(props.uid).once('value')}
            builder={(snap)=>{
                return <Typography style={{...props.style,}}>{`${snap.val().fname} ${snap.val().lname}`}</Typography>}}
            errorChild={<p>..</p>}
            placeholder={<Typography>--</Typography>}
        />
    </>
}
UsernameText.propTypes = {
    uid: PropTypes.string,
    style: PropTypes.object,
}