/* eslint-disable react-hooks/exhaustive-deps */

import { Card, alpha, Typography, useTheme } from '@material-ui/core';
import { AccessTime, Payment, People } from '@material-ui/icons';
import React, { useContext } from 'react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import logoBanner from '../assets/STORY.png';
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { SiteDataContext } from '../config/SiteData';
import "../index.css";
import PropTypes from 'prop-types'
import { Skeleton } from '@material-ui/lab';
import { motion } from 'framer-motion';
import QRCode from 'react-qr-code';
import FutureBuilder from '../components/_future_builder';
import { UsernameText } from '../components/_user_name_mod';
import { firestore } from '../config/firebase';
import { useMediaQuery } from '../contexts/MediaQueryProvider';

const BookingCard = ((props) => {
    const theme = useTheme();
    const { device } = useMediaQuery()
    const {
        SiteData,
    } = useContext(SiteDataContext);
    const msToTime = (duration) => {
            var minutes = Math.floor((duration / (1000 * 60)) % 60),
            hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

        hours = (hours < 10) ? "0" + hours : hours;
        minutes = (minutes < 10) ? "0" + minutes : minutes;

        return `${hours}h ${minutes}m`
    }
    const getHours = (date1, date2) => {

        return msToTime(date2 - date1);
    }
    const _buildGusts = (b) => {
        return <Row>
            <People style={{ fontSize: 18 }} />
            <div style={{ width: "10px" }} />
            <Typography style={{ marginBottom: "10px", fontWeight: "bold", fontSize: 12 }}>{`${b.guest} Guest(s)`}</Typography>
            <div style={{ width: "10px" }} />
        </Row>


    }
    const _buildpay = (b) => {
        return <Row>
            <Payment style={{ fontSize: 18 }} />
            <div style={{ width: "10px" }} />
            <Typography style={{ marginBottom: "10px", fontWeight: "bold", fontSize: 12 }}>{`€${b.price} Paid`}</Typography>
            <div style={{ width: "10px" }} />
        </Row>
    }
    const _buildId = (b) => {
        return <Row alignment="space-between" style={{ width: "100%" }}>
            <UsernameText style={{ color: "black", fontWeight: "bold", fontSize: 18, textTransform: "capitalize" }} uid={b.info} />
            <Typography style={{ marginBottom: "10px", opacity: 0.4, fontSize: 12 }}>{`ID: ${b.id}`}</Typography>
        </Row>
    }
    const _buildHours = (b) => {
        return <Row>
            <AccessTime style={{ fontSize: 18 }} />
            <div style={{ width: "10px" }} />
            <Typography style={{ marginBottom: "10px", fontWeight: "bold", fontSize: 12 }}>{`${getHours(b.startTime, b.endTime)}`}</Typography>
            <div style={{ width: "10px" }} />
        </Row>
    }
    const _buildDate = (b) => {
        return <Row>
            <Typography style={{ marginBottom: "10px", fontWeight: "bold", fontSize: 14 }}>{`DATE: ${new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(b?.startTime)}`}</Typography>
            <div style={{ width: "10px" }} />
        </Row>

    }
    const _buildTime = (b) => {
        return <Row>
            <Typography style={{ marginBottom: "10px", fontWeight: "bold" }}>{
                `TIME: ${new Intl.DateTimeFormat("en-US", { hour: "numeric", minute: "numeric" }).format(b.startTime)} - 
${new Intl.DateTimeFormat("en-US", { hour: "numeric", minute: "numeric" }).format(b.endTime)}`}</Typography>
            <div style={{ width: "10px" }} />
        </Row>
    }
    const _buildQr = (b) => {
        return (props?.showQR ?? true) ? <Card style={{ height: device === "xs" ? 150 : 100, width: device === "xs" ? 150 : 100 }}>
            <QRCode value={SiteData.siteLink + "verifyticket/" + b.temp + "_" + b.id} size={150} />
        </Card> : <></>
    }
    const _buildCode = (b) => {
        return props.showCode && Date.now() > (b?.startTime ?? Date.now()) ? <Column>

            <Row>
                <Typography style={{ textAlign: "center" }}>{`Entry Code »`}</Typography>
                <div style={{ width: "10px" }} />
                <Typography style={{ fontWeight: "bold", textAlign: "center" }}>{`${b.code}`}</Typography>


            </Row>
        </Column> : <></>
    }
    const _buildMobile = (data, b) => {
        return <Stack style={{ width: "250px", height: "400px", }}>
            <div style={{ background: "black", opacity: "0.7", width: "100%", height: "100%" }}></div>
            <div style={{ width: "100%", height: "100%" }}><img alt="" src={logoBanner} width="100%" height="100%" style={{ objectFit: "cover" }} /></div>
            <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.paper, 1)} 5%, ${alpha(theme.palette.background.paper, 0.8)} 60%, ${alpha(theme.palette.background.default, 1)})`, height: "100%", width: "100%" }}></div>
            {/* <div style={{ background: `180deg, ${theme.palette.primary.main},${theme.palette.secondary.main} 80%)`, height: "100%", width: "100%" }}></div> */}

            <Column alignment="top" style={{ width: "250px", height: "400px", }}>
                <div style={{ height: "20%", width: "100%", position: "relative" }}>
                    <Stack>
                        <img alt=""src={`${data.image}`} style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                        <div style={{ background: `linear-gradient(180deg, transparent, black 80%)`, width: "100%", height: "100%" }}>
                            <Column alignment="space-between" style={{ height: "100%" }}>
                                {_buildId(b)}

                                <Typography style={{ marginBottom: "10px" }}>{data.name}</Typography>
                            </Column>
                        </div>
                    </Stack>

                </div>
                <Row style={{ margin: "10px 0" }} alignment="centered">
                    {_buildQr(b)}
                </Row>
                <Row style={{ margin: "10px 0" }} alignment="centered">
                    {_buildCode(b)}
                </Row>
                <Row style={{ margin: "10px 0" }} alignment="centered">
                    {_buildHours(b)}
                </Row>
                <Row style={{ margin: "2px 0" }} alignment="centered">
                    {_buildDate(b)}

                </Row>
                <Row style={{ margin: "2px 0" }} alignment="centered">
                    {_buildTime(b)}

                </Row>
                <Row style={{ margin: "2px 0" }} alignment="centered">
                    {_buildGusts(b)}

                </Row>


            </Column>
        </Stack>
    }
    const _buildPcCard = (data, b) => {
        return <Stack style={{ width: "100%" }}>
            <div style={{ background: "black", opacity: "0.7", width: "100%", height: "100%" }}></div>
            <div style={{ width: "100%", height: "100%" }}><img alt=""src={logoBanner} width="100%" height="100%" style={{ objectFit: "cover" }} /></div>
            <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.paper, 1)} 5%, ${alpha(theme.palette.background.paper, 0.8)} 60%, ${alpha(theme.palette.background.default, 1)})`, height: "100%", width: "100%" }}></div>
            {/* <div style={{ background: `180deg, ${theme.palette.primary.main},${theme.palette.secondary.main} 80%)`, height: "100%", width: "100%" }}></div> */}
            <Row style={{ width: "100%", height: "200px", background: `linear-gradient(180deg, ${theme.palette.primary.main},${theme.palette.secondary.main} 80%)` }}>
                <div style={{ width: "30%", height: "100%", position: "relative" }}>
                    <Stack>
                        <img alt=""src={`${data.image}`} style={{ objectFit: "cover", width: "100%", height: "100%" }} />
                        <div style={{ background: `linear-gradient(180deg, transparent, black 80%)`, width: "100%", height: "100%" }}>
                            <Column alignment="space-between" style={{ height: "100%" }}>
                                <div />

                                <Typography style={{ marginBottom: "10px" }}>{data.name}</Typography>
                            </Column>
                        </div>
                    </Stack>

                </div>
                <Row alignment="space-between" style={{ padding: "10px", color: "black", width: "70%", }}>
                    <Column alignment="space-between" style={{ width: "100%" }}>
                        {_buildGusts(b)}
                        {_buildId(b)}
                        <div style={{ height: "10px" }} />
                        <Row alignment="space-between" style={{ width: "100%" }}>
                            <Column>
                                {_buildpay(b)}
                                {_buildHours(b)}
                            </Column>
                            {_buildQr(b)}
                        </Row>

                        <Row alignment="space-between" style={{ width: "100%" }}>
                            <Column>
                                {_buildDate(b)}
                                {_buildTime(b)}
                            </Column>

                            {_buildCode(b)}
                        </Row>

                    </Column>
                </Row>
            </Row>
        </Stack>
    }
    const _buildBookedCard = ((b) => {
        console.log(new Date(b.date))
        return !b ? <></> : <motion.div style={{ cursor: "pointer" }}>
            <Card variant="outlined" className={Date.now() > b.startTime ? "" : "commercial-ticket"}
                style={{ ...props.style, height: device === "xs" ? "400px" : "200px", width: device === "xs" ? "250px" : "500px", opacity: Date.now() > b.startTime ? 0.6 : 1, filter: Date.now() > b.startTime ? "grayscale(1)" : "none" }}>
                <FutureBuilder
                    future={firestore.collection("StudioRooms").doc(b.room ?? "").get()}
                    rebuild
                    builder={(snapshot) => {
                        var data = snapshot.data();
                        console.log(data)
                        return device === "xs" ? _buildMobile(data, b) : _buildPcCard(data, b)
                    }}
                    errorChild={<div style={{ background: "red", height: 150, width: 150 }}></div>}
                    placeholder={<div></div>}
                />
            </Card></motion.div>
    })

    return <>
        {(props.bookingId ?? "") !== "" ? <FutureBuilder
            builder={(snapshot) => {

                console.log(props.bookingId, "I this data -> ", snapshot.data());

                return _buildBookedCard(snapshot.data());
            }}
            errorChild={<div></div>}
            future={firestore.collection("Users").doc(props.uid).collection("Bookings").doc(props.bookingId).get('value')}
            placeholder={<Skeleton width={device === "xs" ? 300 : 600} height={device === "xs" ? 500 : 200} sx={{ bgcolor: 'grey.900' }}
                variant="rectangular" />}
        /> : _buildBookedCard(props.booking)}
    </>
})
export default BookingCard

BookingCard.propTypes = {
    booking: PropTypes.object,
    bookingId: PropTypes.string,
    showQR: PropTypes.bool,
    showCode: PropTypes.bool,
    style: PropTypes.object,
    uid: PropTypes.string
}