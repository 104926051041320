/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { Card, Checkbox, FormControlLabel, IconButton, InputBase, Typography } from "@material-ui/core"
import Column from "./Column"
import Row from "./Row";
import { CheckCircle, Close, Delete, Edit } from "@mui/icons-material";
import { useTodoHandler } from "../hooks/useToDoList";
import shortUUID from "short-uuid";
import { useSnackbar } from "notistack";
import { sendChatMessage } from "../hooks/useChatMessenger";
import { NotificationTypes, sendNotification } from "../hooks/notificationHandler";

import PropTypes from 'prop-types';

const TaskListTile = ({item,uid,agentId: targetId,editMode: allowEdit})=>{
    const {updateToDo, deleteToDoItem, } = useTodoHandler();
    const {enqueueSnackbar} = useSnackbar();
  
    const [editMode, setEditMode] = useState(false);
    const text = useRef();
    useEffect(()=>{
        console.log(item);
    },[])
    console.log(item);
    const updateCompleted =
        async (e) => {
            if (await updateToDo({
                ...item,
                completed: e.target.checked
            })) {
                if(!e.target.checked){
                     sendChatMessage({
                    messageId: shortUUID.generate(),
                    content: `To Do Item "${item.content}" Marked as Complete`,
                    timestamp: Date.now(),
                    senderId: uid,
                    reciepientKey: item?.listId,
                    reciepients: ['dtv-admin', targetId],
                    seenby:[uid],
                    })
                    sendNotification({
                        title: `Project task Completed`,
                        message: `${item.content?.substring(0,Math.min(20,item.content.length))}...`,
                        timestamp: Date.now(),
                        sendTo: targetId,
                        id: shortUUID.generate(),
                        sentFrom: uid,
                        type: NotificationTypes.SERVICE_TASK_UPDATE,
                        url: `/${uid}/ServiceProject?id=${item?.listId}`,
                        seen:false,
                    })
                }
               
                enqueueSnackbar("Item Marked as " + (!e.target.checked ? "Complete" : "Not Complete"), { variant: 'success' });
            } else {
                enqueueSnackbar("Failure to mark kas complete", { variant: 'error' });
            }


        };
    const updateItem =
        async () => {
            if(text.current.value ==='' || item.content) return;
            if (await updateToDo({
                ...item,
                content: text.current.value,
                timestamp: Date.now()
            })) {
                sendChatMessage({
                    messageId: shortUUID.generate(),
                    content: `${item.content?.substring(0,Math.min(20,item.content.length))}...`,
                    timestamp: Date.now(),
                    senderId: uid,
                    reciepientKey: item?.listId,
                    reciepients: ['dtv-admin', targetId],
                    seenby:[],
                
                })
                sendNotification({
                    title: `Project task updated`,
                    message: `${item.content?.substring(0,Math.min(20,item.content.length))}...`,
                    timestamp: Date.now(),
                    sendTo: targetId,
                    id: shortUUID.generate(),
                    sentFrom: uid,
                    type: NotificationTypes.SERVICE_TASK_UPDATE,
                    url: `/${uid}/ServiceProject?id=${item?.listId}`,
                    seen:false,

                })
                enqueueSnackbar("Item Updated", { variant: 'success' });
            } else {
                enqueueSnackbar("Failure to update", { variant: 'error' });
            }
            setEditMode(!editMode);
        };
    const deleteItem =
        async () => {

            if (await deleteToDoItem(item)) {
                enqueueSnackbar("Item Deleted", { variant: 'success' });
            } else {
                enqueueSnackbar("Failure to delete", { variant: 'error' });
            }
        };
    return item ? <Card variant={'outlined'} style={{padding: "5px 20px", borderRadius: 20}}>
        <Row style={{width: '100%', alignItems: 'center'}} alignment="space-between">
        <Row style={{width: '100%' }}>
            {
                allowEdit&&editMode ? <InputBase inputRef={text} defaultValue={item.content} variant="outlined" multiline minRows={1} style={{width: '100%', padding: "0 10px"}}/> : 
                 <FormControlLabel disabled={!allowEdit} checked={item.completed} control={<Checkbox onChange={
                    updateCompleted
                } checked={item.completed} />} label={<Column>
                    <Typography variant="body1">{item.content}</Typography>
                    <Typography variant="caption" color="textSecondary">{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(item.timestamp??0))
                    }</Typography>
                </Column>}/>
            }
            
        </Row>
        {<Row>{allowEdit&&editMode ? <>
        <IconButton onClick={()=>{
            setEditMode(false);
        }}>
        <Close/>
        </IconButton>
            <IconButton onClick={
         updateItem   
        }>
            <CheckCircle/>
            </IconButton>
        </> : item.access === 'READ_ONLY' || !allowEdit ? <></> :<>{
                ( <IconButton onClick={
                    ()=>{
                        setEditMode(!editMode);
                    }
                }>
                    <Edit/>
                </IconButton> )}
                <IconButton onClick={
                    deleteItem
                }>
                  <Delete/>
                </IconButton></>}
                </Row>}
    </Row>
    </Card>:<></>
}
export default TaskListTile;


TaskListTile.propTypes = {
    item: PropTypes.object,
    uid: PropTypes.string,
    agentId: PropTypes.string,
    editMode: PropTypes.bool,
    allowEdit: PropTypes.bool
}