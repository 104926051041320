import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import siteDataReducer from '../reducer/siteDataReducer'
import storage from 'redux-persist/lib/storage'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import showProgressReducer from '../reducer/showProgressReducer'
import organisationReducer from '../reducer/organisationReducer'

const persistConfig = {
  key: 'dtv-state',
  storage: storage,
}
export const rootReducers = combineReducers({
  siteData: siteDataReducer,
  showsProgress: showProgressReducer,
  organisation: organisationReducer
})
const persistedReducer = persistReducer(persistConfig,rootReducers)
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})
setupListeners(store.dispatch)
export default store;