/* eslint-disable react-hooks/exhaustive-deps */

import { Card, CardActions, CardContent, CardMedia, alpha, IconButton, LinearProgress, Tooltip, Typography, useTheme, withStyles } from '@material-ui/core';
import { DynamicFeed, PlayArrow, VolumeMute, VolumeUp } from '@material-ui/icons';
import { motion } from 'framer-motion';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router';
import '../bubble.css';
import Center from './Center';
import Column from './Column';
import Row from './Row';
import Stack from './Stack';
import { formatDuration } from '../utils';


const CustomLinearProgress = withStyles((theme) => ({
    root: {
        height: 3,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: theme.palette.primary.light,
    },
}))(LinearProgress);


export const ContinueWatchingCard = (props) => {
    const [isHovered, setHovered] = useState(false);
    const theme = useTheme();
    const history = useNavigate();
    const [play, setPlay] = useState(false);
    const [mute, setMute] = useState(false);
    const [progress, setProgress] = useState(props.currentWatching.timestamp);
    const [cardDetails, updateCardDetails] = useState({ width: props.mobile ? "300px" : "300px", height: props.mobile ? 250 : 250, mediaHeight: "90%", showVideo: false })
    useEffect(() => {
        console.log(props.show)
    }, [])

    let t;
    const startPlayCounter = () => {
        t = setTimeout(function () {
            if (isHovered) { setPlay(true) }
        }, 1000)
    }
    const stopPlaycounter = () => {
        setPlay(false);
        clearTimeout(t)
    }
    return props.mobile ? (<motion.div whileHover={{ scale: 1.1 }} onHoverStart=
        {() => {
            console.log("enter");
            setHovered(true);
            startPlayCounter();
            console.log(isHovered);
            updateCardDetails({ ...cardDetails, height: 380, mediaHeight: "70%", width: "80px" })
        }}
        onHoverEnd={() => { stopPlaycounter(); setHovered(false); updateCardDetails({ ...cardDetails, height: 200, mediaHeight: "90%", width: "300px" }) }} style={{ margin: "0 5px" }}>
        <Card style={{ height: cardDetails.height, width: cardDetails.width, paddingBottom: "10px" }}

            onClick={() => {
                if (props.onClick)
                    props.onClick();
                else {
                   
                    history(`/episode/w?id=${props.episode.episodeId}&time=${props.currentWatching.timestamp}`, { episode: props.episode, progress: props.currentWatching.timestamp ?? 0 })

                }
            }}>
            {<CardMedia style={{ height: cardDetails.mediaHeight, position: "relative" }}>

                {<Stack>
                    <div style={{ width: "100%", height: "100%" }}>
                        <img alt="" src={`${props.episode.ep_thumb ? props.episode.ep_thumb : props.show.showCover}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
                    </div>
                    <div style={{ width: "100%", height: "100%", background: "linear-gradient(0deg, black,transparent)" }}></div>
                    <Column alignment={"end"} style={{ width: "100%", height: "100%" }}>
                        <Row alignment="space-between" style={{ width: "100%" }}>
                            <Column alignment="end" style={{ width: "100%" }}>
                                <Row alignment={"centered"}>
                                    {props.show.logo ? <div style={{ width: "50px", height: "50px" }}>
                                        <img alt="" src={`${props.show.logo}`} width="100%" height="100%" style={{ objectFit: "contain" }} />
                                    </div> : <></>}
                                </Row>
                                <CustomLinearProgress variant={"determinate"} value={((progress) / props.episode.duration) * 100} />
                            </Column>

                        </Row>
                    </Column>
                </Stack>
                }
            </CardMedia>}
            <div style={{ padding: "2px 5px 2px 0px" }}>
                <Typography align={"center"} variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.9), fontSize: "10px" }}>{`S${props.episode.ep_season} • E ${props.episode.num}`}</Typography>
            </div>
        </Card></motion.div>
    ) : (
        <motion.div whileHover={{ scale: 1.1 }} onHoverStart=
            {() => {
                console.log("enter");
                setHovered(true);
                startPlayCounter();
                console.log(isHovered);
                updateCardDetails({ ...cardDetails, height: 380, mediaHeight: "70%", width: "350px" })
            }}
            onHoverEnd={() => { stopPlaycounter(); setHovered(false); updateCardDetails({ ...cardDetails, height: 200, mediaHeight: "90%", width: "300px" }) }} style={{ margin: "0 20px" }}>
            <Card style={{ height: cardDetails.height, width: cardDetails.width, paddingBottom: "10px" }}

                onClick={() => {
                    if (props.onClick)
                        props.onClick();
                    else {
                        
                        history(`/episode/w?id=${props.episode.episodeId}&time=${props.currentWatching.timestamp}`, { episode: props.episode, progress: props.currentWatching.timestamp ?? 0 })

                    }
                }}>
                {<CardMedia style={{ height: cardDetails.mediaHeight, position: "relative" }}>

                    {isHovered ? <Stack><div style={{ width: "100%", height: "100%" }}>
                        {
                            play && props.hoverPlay ?
                                <ReactPlayer playsinline url={`${props.episode.ep_link}`}
                                    width="100%"
                                    height="100%"
                                    canPlay="true"
                                    muted={mute}
                                    onProgress={(p) => { setProgress(p.playedSeconds) }}
                                    onReady={(p) => { p.seekTo(props.currentWatching.timestamp); setProgress(props.currentWatching.timestamp) }}
                                    playing={isHovered} /> : <img alt="" src={`${props.episode.ep_thumb ? props.episode.ep_thumb : props.show.showCover}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
                        }

                    </div>
                        <div style={{ width: "100%", height: "100%", background: "linear-gradient(0deg, black,transparent)" }}></div>
                        <Column alignment={"end"} style={{ width: "100%", height: "100%" }}>
                            <Row alignment={"centered"}>
                                {props.show.logo ? <div style={{ width: "80px", height: "80px" }}>
                                    <img alt="" src={`${props.show.logo}`} width="100%" height="100%" style={{ objectFit: "contain" }} />
                                </div> : <></>}
                            </Row>
                            <Row alignment="space-between" style={{ width: "100%" }}>
                                <Column alignment="end" style={{ width: "100%" }}>
                                    <CustomLinearProgress variant={"determinate"} value={((progress) / props.episode.duration) * 100} />
                                </Column>

                            </Row>
                        </Column>
                    </Stack> :
                        <div style={{ width: "100%", height: "100%" }}>
                            <img alt="" src={`${props.episode.ep_thumb ? props.episode.ep_thumb : props.show.showCover}`} width="100%" height="100%" style={{ objectFit: "cover" }} />
                        </div>}
                </CardMedia>}
                {
                    <CardContent style={{ height: "20%" }}>
                        {isHovered ? <motion.div>
                            <Column>
                                <Row alignment="space-between">
                                    <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.4) }}>{`Season ${props.episode.ep_season} • Episode ${props.episode.ep_no}`}</Typography>
                                    <Typography style={{ color: alpha(theme.palette.text.primary, 0.9) }}>{`"${props.episode.ep_title}"`}</Typography>
                                </Row>
                                <Row alignment="centered">
                                    <Tooltip arrow title={`${mute ? 'unmute' : 'mute'}`}>
                                        <IconButton onClick={() => { setMute(!mute) }}><div style={{ border: " 1px white solid", height: "40px", width: "40px", borderRadius: "100%" }}>
                                            <Center>
                                                <Row alignment="centered">
                                                    {mute ? <VolumeMute /> : <VolumeUp />}
                                                </Row>
                                            </Center>

                                        </div> </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow title={`Resume`}>
                                            <IconButton onClick={() => { 
                                                
                              history(`/episode/w?id=${props.episode.episodeId}&time=${props.currentWatching.timestamp}`, { episode: props.episode, progress: props.currentWatching.timestamp ?? 0 })
                                             }}><div style={{ border: " 1px white solid", height: "40px", width: "40px", borderRadius: "100%" }}>
                                            <Center>
                                                <Row alignment="centered">
                                                    {<PlayArrow />}
                                                </Row>
                                            </Center>

                                        </div> </IconButton>
                                    </Tooltip>
                                    <Tooltip arrow title={`More Episodes`}>
                                        <IconButton onClick={() => {
                                                history("/show/" + props.show.showId, { show: props.show })
                                        }}><div style={{ border: " 1px white solid", height: "40px", width: "40px", borderRadius: "100%" }}>
                                                <Center>
                                                    <Row alignment="centered">
                                                        {<DynamicFeed />}
                                                    </Row>
                                                </Center>

                                            </div> </IconButton>
                                    </Tooltip>

                                </Row>
                            </Column>
                        </motion.div> :
                            <Row alignment="space-between" style={{ width: "100%", height: "100%" }}>
                                <div style={{ width: "80%" }}>
                                    <CustomLinearProgress variant={"determinate"} value={((props.currentWatching.timestamp) / props.episode.duration) * 100} />
                                </div>
                                <Column alignment={"centered"} style={{ height: "100%" }}>
                                    <Typography variant="overline">{formatDuration(props.episode.duration - props.currentWatching.timestamp)}</Typography>
                                </Column>

                            </Row>}
                    </CardContent>
                }
                <CardContent style={{ width: "100%", position: "relative", overflow: "hidden" }}>
                    <Column>
                        {/* <Typography variant="h6" align="left">{episode.ep_title}</Typography> */}
                    </Column>
                </CardContent>
                <CardActions>
                </CardActions>
            </Card></motion.div>
    )
};
export default ContinueWatchingCard;

ContinueWatchingCard.protoTypes = {
    show: PropTypes.func,
    episode: PropTypes.func,
    hoverPlay: PropTypes.bool,
    episodeId: PropTypes.string,
    currentWatching: PropTypes.any,
    continueWathing: PropTypes.any,
    mobile: PropTypes.bool,
    onClick: PropTypes.func

}
ContinueWatchingCard.propTypes = {
    show: PropTypes.func,
    episode: PropTypes.func,
    hoverPlay: PropTypes.bool,
    episodeId: PropTypes.string,
    currentWatching: PropTypes.any,
    continueWathing: PropTypes.any,
    mobile: PropTypes.bool,
    onClick: PropTypes.func
}
