/* eslint-disable react-hooks/exhaustive-deps */

import React,{ useEffect, useState } from "react";
import { useProfile } from "../../../hooks/useProfile";
import Column from "../../Column";
import { DataGrid } from "@material-ui/data-grid";
import { Card, CardContent, Chip, CircularProgress, FormControl, Grid, InputAdornment, MenuItem, OutlinedInput, Select, Typography, useTheme } from "@material-ui/core";
import useServiceBooking from "../../../contexts/ServiceBookingProvider";
import {useNavigate} from 'react-router';
import { ServiceProcess, getStatusColor } from "../../../utils";
import { Search } from "@material-ui/icons";
import Row from "../../Row";
import { useMediaQuery } from "../../../contexts/MediaQueryProvider";
import { ProfileAvatar } from "../main/_profile_avatar";
import ServicePakageCard from "../../_service_package_card";
import { getNormalTextFromMarkdown } from "../../_markdown_textarea";
import PropTypes from 'prop-types'

const BuildServiceAgentRequestsTab =({uid})=>{
    const [,setProfile] = useState({});
    const {retrieveProfile} = useProfile();
    const [loading, setLoading] = useState(false);
    const {getRequestsForAgent } = useServiceBooking();
    const history= useNavigate();
    const [serviceRequests, ] = useState({});
    const [, setFilter] = useState({});
    const [serviceTypes, ] = useState([]);
    const [currentTypeFilter, setCurrentTypeFilter] = useState('all');
    const [currentProgressFilter, setCurrentProgressFilter] = useState('all');
    const searchRef = React.useRef(null);
    const {isLargeDevice, device} = useMediaQuery();
    const [cols, setCols] = useState([]);
    const [rows, setRows] = useState([]);
    const theme = useTheme();
    const [serviceProgress, ] = useState(ServiceProcess.filter((process)=>
    (process !== 'deposit-processing' && process !== 'deposit-paid' && process !== 'deposit-failed' && 
    process !== 'cancelled-1' && process !== 'cancelled-2' && process !== 'payment-failed' && 
    process !== 'payment-recieved' && process !== 'payment-processing') 
    ));
    useEffect(()=>{
       
        setLoading(true); 
        retrieveProfile(uid).then((p)=>{
            console.log(uid);
            setProfile(p);
            setLoading(false);
            createTable();
        })
    },[uid]);
    const createTable = async()=>{
        const columns =[
            {field: 'id', headerName: 'ID', width: 100, renderCell: (params)=>{
                return <Typography variant="caption">{params.value.substring(0, Math.min(params.value.length, 6))}</Typography>
            }},
            {field: 'status', headerName: 'Status', width: 130, renderCell: (params)=>{
                return <Chip size={'small'} label={params.value.replace('-',' ')} variant="outlined" style={{borderColor: getStatusColor(params.value), color:getStatusColor(params.value)}}/>
            }},
            {field: 'serviceType', headerName: 'Service Type', width: 150, renderCell: (params)=>{
                return <Typography variant="caption">{params.value.name}</Typography>
            }},
            {field:"uid", headerName: "Sent By", width: 130, renderCell: (params)=>{
                console.log(params.value);
                return <ProfileAvatar textProps={
                    {
                        style:{
                            color: theme.palette.primary.main,
                            fontSize: 12.25,
                            textDecoration: 'underline',
                        }
                    }
                } uid={params.value} onlyName/>
            }},
            {field: 'description', headerName: 'Description', width: 210},
            {field: 'package', headerName: 'Package', width: 120, renderCell: (params)=>{
                return <ServicePakageCard servicePackageId={params.value} linkMode/>
            }},
            {field: 'date', headerName: 'Date Sent', width: 150, renderCell: (params)=>{
                const date = new Date(params.value??0);
                return <Typography variant="caption">{`${date.toDateString()}, ${date.toTimeString()}`}</Typography>
            }},
            {field: 'deadline', headerName: 'Deadline', width: 150, renderCell: (params)=>{
                const date = new Date(params.value??0);
                return params.value ? <Typography variant="caption">{`${date.toDateString()}, ${date.toTimeString()}`}</Typography> :
                <Typography variant="caption">N/A</Typography>
            }},

        ]
        setCols(columns);
        const rws = [];
        const requests = await getRequestsForAgent(uid,false);
        console.log(requests);
        
        for(let request of requests.filter(a=>!a.statusDates?.approved)){
            rws.push({
                id: request.id,
                uid: request.uid,
                status: request.status,
                serviceType: request.serviceType,
                name: request.name,
                description:request.description?.text ? getNormalTextFromMarkdown(request.description?.text) : request.description,
                date: request.timestamp,
                deadline: request.deadline,
                package: request.packageId,
            })
        }
        setRows(rws);

    }
    const handleSearch = ({ typeFilter, progressFilter}) => {
        const applyTypeFilter = Object.values(serviceRequests).filter((request) => {
            return (typeFilter === 'all' ) ? true : request.serviceType.name === (typeFilter );
        });
        const applyProgressFilter = Object.values(serviceRequests).filter((request) => {
            return (progressFilter === 'all') ? true : request.status === (progressFilter );
        })
        const searchFilter = Object.values(serviceRequests).filter((request) => {
            const description = request.description?.text ? getNormalTextFromMarkdown(request.description?.text) : request.description;
            return getNormalTextFromMarkdown(description).toLowerCase().includes(searchRef.current?.value?.toLowerCase());
        });
        const filtered = [applyTypeFilter, applyProgressFilter, searchFilter].reduce((a, b) => a.filter(c => {
            return b.includes(c);
        }));
        console.log(applyTypeFilter, applyProgressFilter, searchFilter);
        setFilter(
            filtered
        );

    }
    const _buildFilter = () => {
        const innerBody = <>
      
            <FormControl variant="outlined" size="small">
      <Select
        value={currentProgressFilter}
        onChange={(event)=>{setCurrentProgressFilter(event.target.value);
            handleSearch({
                search: searchRef.current?.value,
                typeFilter: currentTypeFilter,
                progressFilter: event.target.value
            })
        }}
        style={{
          borderRadius: 10,
          margin:'0 10px'
        }}
        displayEmpty
        inputProps={{ 'aria-label': 'Font Size' }}
      >
             <MenuItem  value={'all'}>
                            <Typography variant="caption">{'All Status'}</Typography>
                        </MenuItem>
                    {serviceProgress.map((process, index) => {
                        return <MenuItem key={index}  value={process}>
                            <Typography variant="caption">{process.replace('-',' ')}</Typography>
                        </MenuItem>
                    })}
                </Select>
                </FormControl>
                {device === 'xs' ? <div style={{height: 10}}/> : <></>}
                <FormControl variant="outlined" size="small">
        <Select 
        value={currentTypeFilter}
        onChange={(event)=>{setCurrentTypeFilter(event.target.value);
            handleSearch({
                search: searchRef.current?.value,
                typeFilter: event.target.value,
                progressFilter: currentProgressFilter
            })
        }}
        style={{
            borderRadius: 10,
            margin:'0 10px'
            }}
            displayEmpty
            inputProps={{ 'aria-label': 'Font Size' }}
            >
            <MenuItem  value={'all'}>
                            <Typography variant="caption">{'All Types'}</Typography>
                        </MenuItem>
                    {Object.values(serviceTypes).map((serviceType, index) => {
                        return <MenuItem key={index} value={serviceType.name}>
                            <Typography variant="caption">{serviceType.name}</Typography>
                        </MenuItem>
                    })}
                </Select>
                </FormControl>
                {device === 'xs' ? <div style={{height: 20}}/> : <></>}

            <OutlinedInput
                placeholder="Search"
                onChange={
                    ()=>{
                        handleSearch({
                            search: searchRef.current?.value,
                            typeFilter: currentTypeFilter,
                            progressFilter: currentProgressFilter
                        })
                    }
                }
                inputRef={searchRef}
                endAdornment={<InputAdornment position="end"><Search /></InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                    'aria-label': 'weight',
                    style:{padding: '10px 15px', borderRadius: 15}
                }}

            />
        </>
        const body = <>
         <Typography style={{ fontWeight: "bold", fontSize: "22px", textAlign: "left", marginBottom: 10 }}>Service Bookings</Typography>
          {device === 'xs' ? <Column>{innerBody}</Column> : <Row>{innerBody}</Row>}
            </>
        return isLargeDevice ? <Row alignment="space-between" style={{width:'100%',padding:"0 10px"}}>{body}</Row> : <Column>
            {body}
        </Column>
    }
    const BuildBody = () =>{
        return <Column>
        <Grid container spacing={2}>
            <Grid item xs={12}><Typography align="left" variant="h6">Requests</Typography></Grid>
            <Grid container xs={12}>
                {_buildFilter()}
            </Grid>
            <Grid item xs={12}>
            <div style={{ height: 500, width: '100%' }}>
                <DataGrid 
                    onRowClick={(params)=>{
                        history(`ServiceProject?id=${params.row.id}`)
                    
                    }}
                columns = {cols} rows = {rows} />
            </div>
            </Grid>
        </Grid>
        </Column>
    }
    return loading ? <Column>
        <CircularProgress/>
    </Column> : <Card style={{margin:"0 auto", width:"100%"}} variant="outlined">
        <CardContent>
            <BuildBody/>
        </CardContent>
    </Card>
}
export default BuildServiceAgentRequestsTab;


BuildServiceAgentRequestsTab.propTypes = {
    uid: PropTypes.string
}