/* eslint-disable react-hooks/exhaustive-deps */

import React, { createContext, useContext, useEffect, useState } from 'react';
import firebase_app from '../config/firebase';
import PropTypes from 'prop-types';

export const useProfile = () => useContext(UserContext);



export const UserContext = createContext();
const UserProvider=(props)=>{
    const [currentUser,setCurrentUser]=useState(firebase_app.auth().currentUser);
    const [sessionId, setSessionId]=useState('');
    const [profile, setProfile]=useState(null);
    
useEffect(()=>{
    firebase_app.auth().onAuthStateChanged((user)=>{
        if(user){
            console.log("SIGNED ID")
            setCurrentUser(user);
            retrieveData(currentUser);
        }
         else{
             console.log("NOT SIGNED ID")
         }
    });
   
    
},[currentUser?.uid])
const updateSessionId = (sid)=>{
    setSessionId(sid)
}
const updateProfile = (profile)=>{
    setProfile(profile)
}
const retrieveData = async (user,) => {
    console.log(user?.uid??currentUser?.uid??firebase_app.auth()?.currentUser?.uid??"none")
  
    var profile = await firebase_app.database().ref()
      .child("Users")
      .child(user?.uid??currentUser?.uid??firebase_app.auth()?.currentUser?.uid??"none")
      .once('value');
      const profileData = profile.val();
      updateProfile(profileData);
      setProfile(profileData);
      console.log(profileData);
      return profileData;
}
return (
    <UserContext.Provider value={{
        currentUser,
        sessionId,
        profile,
        updateSessionId,
        updateProfile,
        retrieveData
    }}>
        {props.children}
    </UserContext.Provider>
)
}
export default UserProvider;

UserProvider.propTypes = {
    children: PropTypes.node
}