import {getAllInfoByISO} from 'iso-country-currency';
import { uuid } from "short-uuid";
import { addNewTransaction } from './databaseHandler';

class Logic{
    static getDaysDiff(date1, date2){
        
        const diffTime = Math.abs(date2 - date1);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
       return {
           days:diffDays,
           time:diffTime
       }
    }
    static calcBookedHrs = ({start,end})=>{
        let hours= [];
        for (var date = start; start <= end; date.setHours(start.getHours() + 1)){
            hours.push(date.valueOf())
        }
        return hours;
    }
    static async saveTransaction({description,items,paymentType,uid,totalCharge,totalPaid,cost}){
        const id = uuid();
        var data = {
            transactionId: id,
            date: new Date().valueOf(),
            description: description??"",
            items: items??[],
            paymentType: paymentType??"",
            totalCharge:totalCharge??"",
            totalPaid:totalPaid??"",
            cost:cost
        }
        try{
            await addNewTransaction({
                data:data,
                uid:uid
            });  
            return true;    
        }catch(e){
            console.log(e);
            return false;
        }
        

    }
    static async toCountryCurrency(cost,decimals=2){
       
        try{
            var res = await (await fetch("http://ip-api.com/json",{method:"GET"})).json();
           // console.log(res)
              
                let country = getAllInfoByISO(res.countryCode);
                //console.log(country);
                let convert = await (await fetch(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${'eur'}.json`,{method:"GET"})).json();
                let rate = convert['eur'][country.currency?.toLowerCase()??""];
                //console.log("Rate", rate,cost,`${country.symbol}${((cost * rate)??0).toFixed(decimals)}`);
                return  `${country.symbol}${((cost * rate)??0).toFixed(decimals)}`
                
        }catch(e){
            console.log(e);
            return `€${cost}`}

    }
    static async toCountryCurrencyValue(cost,decimals=2){
       
        try{
            var res = await (await fetch("http://ip-api.com/json",{method:"GET"})).json();
            console.log(res)
              
                let country = getAllInfoByISO(res.countryCode);
               // console.log(country);
                let convert = await (await fetch(`https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${'eur'}.json`,{method:"GET"})).json();
                let rate = convert['eur'][country.currency?.toLowerCase()??""];
                //console.log("Rate", rate,cost,`${country.symbol}${((cost * rate)??0).toFixed(decimals)}`);
                return  ((cost * rate)??0).toFixed(decimals)
                
        }catch(e){
            console.log(e);
            return cost}

    }
    static capitalize = (val="")=>`${val.charAt(0).toUpperCase()}${val.substring(1,val.length)}`
}
export default Logic