import React, { useRef, useState } from "react";
import Column from "./Column";
import { Chip, InputBase, Typography } from "@material-ui/core";
import Row from "./Row";

import PropTypes from 'prop-types';

const TagBar = ({onTagUpdated, viewOnly, defaultTags })=>{
    const [tag, setTag] = useState("");
    const [tags, setTags] = useState(defaultTags?.split(",")?.filter((tag)=>tag.trim()!=="")??[]);
    const [tagsString, setTagsString] = useState(defaultTags??"");
    const tagRef = useRef(null);

    return tags.length<=0 ? <></> : <Column style={{ width: "100%", margin:"20px 0" }}>
    {viewOnly ? <></> : <Typography variant="overline">{`Tags`}<span style={{ opacity: 0.3 }}>{`${viewOnly ?'': '(Separate by space)'}`}</span></Typography>}
    <Row style={{flexWrap:'wrap-reverse', width:'100%'}}>
        {(tags.length<0) ? <></> : tags.map((tag, i) => <Chip variant={viewOnly ? "outlined": "default"} color = {viewOnly ? "primary": "default"} onDelete={viewOnly ? null : () => {
            tags.splice(i, 1);
            setTags([...tags]);
            setTagsString( tagsString.replace(tag + ",", ''));
            if(onTagUpdated)
            onTagUpdated( tagsString.replace(tag + ",", ''));

        }} style={{ marginLeft: "10px" }} key={"tag_" + i} label={tag} />)}
        {viewOnly ? <></> : <InputBase
            inputRef={tagRef}
            onKeyUp={(evt) => {
                var val = evt.target.value;
                console.log(tags)

                if (val.trimLeft().includes(' ')) {
                    if (!tags.includes(tag)) {
                        tags.push(tag)
                        tagRef.current.value = "";
                        let updatedTag = tagsString;
                         updatedTag += tag + ",";
                        setTagsString(updatedTag);
                        if(onTagUpdated)
                        onTagUpdated(updatedTag);
                        //    updateStory({...newStory}) 
                    } else tagRef.current.value = ""
                    setTag("")
                } else {
                    setTag(val.trim())
                }


            }}

            placeholder="Add Tag"
            inputProps={{
                style: {
                    fontSize: "16px",
                    marginRight: "10px",
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    borderColor: "transparent"
                }
            }}
        />}
    </Row>
</Column>
}

export default TagBar;



TagBar.propTypes = {
    onTagUpdated: PropTypes.func,
    viewOnly: PropTypes.bool,
    defaultTags: PropTypes.string
}