import React, { useEffect } from "react";
import { Tooltip,  } from "@material-ui/core";
import { Rating } from "@mui/material";
import Row from "./Row";
import { StarBorder } from "@material-ui/icons";

const Ratings = ({value, ratings, editable, onChange})=>{
  const [, setRating] = React.useState(value ?? 0);
  const stringedRatings = JSON.stringify(ratings);
  useEffect(()=>{
    if(stringedRatings)
      setRating(JSON.parse(stringedRatings));
  },[stringedRatings]);
    const _buildRatings = ()=>{
      console.log(ratings);
      const ratingsNumber = !ratings ? value : ratings.map((rating)=>Number(rating));
        const avgRating = !ratings ? value : (ratingsNumber.length > 1 ? 
        ratingsNumber.reduce((a,b)=>a+b,0)/ratingsNumber.length : 
        ratingsNumber[0]??0);
        console.log(avgRating)
          return <Row>
            <Tooltip title={`${avgRating} out of 5`}>
            <Rating
              name="star-rating"
              value={avgRating}
              onChange={(e)=>onChange(e.target.value)}
              precision={0.5}
              readOnly={!editable}
              size="large"
              color="textPrimary"
              style={{
                color:'white',
              }}
              max={5}
              emptyIcon={<StarBorder style={{ opacity: 0.55, color: 'white' }} fontSize="inherit" />}
              />
              </Tooltip>
              {/* <div style={{width:3}}/>
              <Typography variant="caption">{`(${ratings.length} Ratings)`}</Typography> */}
    
          </Row>
      }   
      return _buildRatings();
}
export default Ratings;