import { RouteNames, baseUrl } from "../config/api";
import axios from "axios";
export const ReportService = () => {
    const createConductReport = async ({reporterId,offenderId,offenceId,contentReported,comment,contentId }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.conductReport}`,
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                reporterId,
                offenderId,
                offenceId,
                contentReported,
                comment,
                contentId
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getConductReports = async ({ cursor, offset, limit, order }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.conductReportList}`,
            method: "get",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                offset,
                limit,
                order
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getConductReport = async ({ id }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.conductReport}`,
            method: "get",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteConductReport = async ({ id }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.conductReport}`,
            method: "delete",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateConductReport = async ({ id, content }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.conductReport}`,
            method: "put",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
            data: {
                content
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }


    const getReportOffences = async ({ cursor, offset, limit, order }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.reportOffenceList}`,
            method: "get",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                offset,
                limit,
                order
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                total: response.data.data?.total,
                count: response.data.data?.count,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const createReportOffence = async ({ name, description, severity }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.reportOffence}`,
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                name,
                description,
                severity
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getReportOffence = async ({ id }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.reportOffence}`,
            method: "get",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateReportOffence = async ({ id, content }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.reportOffence}`,
            method: "put",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
            data: {
                content
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteReportOffence = async ({ id }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.reportOffence}`,
            method: "delete",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        createConductReport,
        getConductReports,
        getConductReport,
        deleteConductReport,
        updateConductReport,
        getReportOffences,
        createReportOffence,
        getReportOffence,
        updateReportOffence,
        deleteReportOffence
    }
}