import { sendFeedback } from "../config/emailer";

export const sendStorySubmition = async()=>{

}

export const sendInvoice = async (billing, SiteData) => {

    console.log(billing);
    let reciept = {
      orderID: billing.orderID,
        header: billing.items ? `${(billing.name?.split(" ")[0] ?? "").toUpperCase()}, YOUR ORDER IS COMPLETED` : "TOP-UP SUCCESSFUL",
        title: `${billing.items ? '' : billing.topUp ?? ''}`,
        items: billing.items ?? billing.shopping ?? undefined,
        subtotal: billing.subtotal,
        total: billing.total,
        tax: billing.tax,
        dateAdded: billing.dateAdded,
        profilelink: `${SiteData.siteLink}profile:${billing.uid}/`,
        ticketLink:  `${SiteData.siteLink}ticket:${billing.uid}/`,
        copyright: ` © ${new Date().getFullYear()} DTV. All rights reserved.`,
        companyName: "DEARFXCH TV",
        siteLink: SiteData.siteLink,
        logo: "https://firebasestorage.googleapis.com/v0/b/dearfachtv-2e464.appspot.com/o/logo.png?alt=media&token=5963ad18-a032-4bda-b48b-a0978ce9b938",
        uid:`${billing.uid}`
    };

    sendFeedback(
        { 
          to:`${billing?.email}`,
          from:SiteData.email,
          subject:`DTV ${billing.bookings ?'Studio Booking':"Wallet Top-up"}`,
          body:billing.items ? BookingReciept(reciept) : WalletReciept(reciept)
        },
        ()=>{
         console.log("email sent");
        },
        ()=>{
        }
      )

}

const WalletReciept = ({ header, title, subtotal, tax, total, copyright, companyName, siteLink, logo }) => {

    return `<table align="center" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0;margin-left:auto;margin-right:auto;margin-top:20px;text-align:left;width:420px" width="420">
    <tbody>
      <tr>
        <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="320">
          <table bgcolor="#ffba08" border="0" cellpadding="0" cellspacing="0" style="background:#ffba08;border-collapse:collapse;border-spacing:0;border:0;width:520px" width="320">
            <tbody>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" valign="top"></td>
              </tr>
              <tr>
                <td colspan="1" height="6" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
              </tr>
              <tr>
                <td align="center" style="border-Sonyercollapse:collapse;color:#001219;font-size:11px;line-height:16px;padding:0;text-align:center"><span class="il"></td>
              </tr>
              <tr>
                <td colspan="1" height="6" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
              </tr>
            </tbody>
          </table>
          <table border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0">
            <tbody>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;overflow:hidden;padding:0;width:520px" valign="top" width="320">
                  <table cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0">
                    <tbody>
                      <tr>
                        <td style="border-collapse:collapse;font-size:0;line-height:0;padding:0"><img alt="" height="3" src="https://ci4.googleusercontent.com/proxy/GWhj4SAs8YYRjSx_tVCK5C1hQlz7AcfsWvz-vEp9BBGru1TseeO2M-f8YHByG_CuGsmtFVUYkmdNLErjWP7EaukcMeOcs4aCNSCPu-bAJVAdo4fbxFMgw-JmW7LnR-vPg5vZbCx9BN6eYotiEG98Vupk6wmFBQDGrw4hQcBqI28E9m32Lt26J_Nf7ZnMImGG8Y_qWb3ht915yGZI_qIVqMPGRAJZpfpC679iiko_kyHPpKAWaVOwALwRlRL5V99rPsF-N82gt_nGkyU3J3Dq3Gc4OXZzndPqqjpTtBcN5rD8XdXPau8An4rTEjyKq5RaK3hVu_kFebOs9w19Y5FAsy2hWvJbFn9herYRCXSawwdvcUJRACeHHQZWhtkiUr0TG5Jp5ED7xEZO9h-2upypLIwYAYC5cCFrBQr7X4n-NeqUunceld-B72SukpTXC9hhXlmY7QpIUpM4Aw=s0-d-e1-ft#https://d2isyty7gbnm74.cloudfront.net/yiUkp5VW7HW2dH1o8gYhPAiGBOU=/fit-in/640x6/filters:fill(43505e):watermark(https://d3g64w74of3jgu.cloudfront.net/assets/receipt-top-edge-mask-e6bbaf30c69e83509da5153571bd94ca.png,0,0,0):quality(100)/https://d3g64w74of3jgu.cloudfront.net/assets/spacer-239465b1c71b79ae1c276e8ef6c73207.png"
                          style="border:0;display:block" width="420" class="CToWUd"></td>
                      </tr>
                    </tbody>
                  </table>
                  <table cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0;width:100%" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" bgcolor="#001219" style="background:#001219;border-collapse:collapse;font-size:0;line-height:0;padding:0;width:520px" valign="middle" width="320">
                          <table cellpadding="0" cellspacing="0" height="100%" style="border-collapse:collapse;border-spacing:0;border:0;table-layout:fixed;width:100%;word-wrap:break-word" width="100%">
                            <tbody>
                              <tr>
                                <td colspan="1" height="35" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
                              </tr>
                              <tr>
                                <td align="center" style="border-collapse:collapse;color:white;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:30px;padding:0 36px;width:100%"
                                width="100%">
                                  <div align="center" style="min-height:80px;margin-bottom:5px;text-align:center;vertical-align:middle;width:80px"><img alt="" border="0" height="80" width="80" style="object-fit:contain" src="${logo}"
                                    style="vertical-align:middle" width="80" class="CToWUd"></div><span style="color:white;font-size:20px;line-height:26px;margin:0;font-weight:bold;display:flex;justify-content:center;flex-direction:column"><span class="il">${header ?? 'TOP-UP SUCCESSFUL'}</span>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" height="21" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
                              </tr>
                              <tr>
                                <td align="center" height="8" style="border-collapse:collapse;font-size:0;height:8px;line-height:0;padding:0" valign="bottom">
                                  <div style="border-bottom-color:#546476;border-bottom-style:solid;border-left-color:transparent;border-left-style:solid;border-right-color:transparent;border-right-style:solid;border-width:0 8px 8px;min-height:0;vertical-align:bottom;width:0"></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table bgcolor="white" cellpadding="0" cellspacing="0" style="background:white;border-collapse:collapse;border-spacing:0;border:0;width:100%" width="100%">
            <tbody>
              <tr>
                <td colspan="1" height="24" style="border-collapse:collapse;color:#363636;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:0;font-weight:normal;line-height:0;padding:0" width="100%"> </td>
              </tr>
              <tr>
                <td align="center" style="border-collapse:collapse;color:#363636;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:30px;padding:0">
                  <div align="center" style="color:#363636;font-family:SQMarket-Medium,HelveticaNeue-Medium,Helvetica-Bold,Helvetica,Arial,sans-serif;font-size:64px;font-weight:500;line-height:72px;text-align:center;white-space:nowrap">${title ?? ""}</div>
                </td>
              </tr>
              <tr>
                <td colspan="1" height="28" style="border-collapse:collapse;color:#363636;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:0;font-weight:normal;line-height:0;padding:0" width="100%"> </td>
              </tr>
            </tbody>
          </table>
          <table bgcolor="white" border="0" cellpadding="0" cellspacing="0" style="background:white;border-collapse:collapse;border-spacing:0;border:0;width:100%" width="100%">
            <tbody>
              <tr>
                <td bgcolor="#ffffff" style="border-collapse:collapse;font-size:0;line-height:0;padding:0"><img alt="" border="0" height="1" src="https://ci4.googleusercontent.com/proxy/QoQHcFtSHZQ36-hElJaHREN5X2kwMc2IZoVtPkLYU6uqsP4YJ_8RNnIhex6jeOIE_1LEfctTenZ7JngQltAsIdSN-PGXE-PIp-g3Jfqf3KW4aA4wwLzeuj662n7NZymqcXWNGMgTX3PnDWh395E=s0-d-e1-ft#https://d3g64w74of3jgu.cloudfront.net/assets/spacer-239465b1c71b79ae1c276e8ef6c73207.png"
                  style="font-size:0;line-height:0" width="20" class="CToWUd"></td>
                <td style="border-collapse:collapse;font-size:0;line-height:0;padding:0">
                  <table bgcolor="white" border="0" cellpadding="0" cellspacing="0" style="background:white;border-collapse:separate;border-spacing:0;border:0;table-layout:fixed;width:100%;word-wrap:break-word" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" valign="top"></td>
                      </tr>
                      <tr>
                        <td colspan="2" height="1" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
                      </tr>
                      <tr>
                        <td colspan="2" height="1" style="border-collapse:collapse;border-top-color:#e0e1e2;border-top-style:dashed;border-top-width:1px;font-size:0;line-height:0;padding:0"><img alt="" border="0" height="1" src="https://ci4.googleusercontent.com/proxy/QoQHcFtSHZQ36-hElJaHREN5X2kwMc2IZoVtPkLYU6uqsP4YJ_8RNnIhex6jeOIE_1LEfctTenZ7JngQltAsIdSN-PGXE-PIp-g3Jfqf3KW4aA4wwLzeuj662n7NZymqcXWNGMgTX3PnDWh395E=s0-d-e1-ft#https://d3g64w74of3jgu.cloudfront.net/assets/spacer-239465b1c71b79ae1c276e8ef6c73207.png"
                          style="font-size:0;line-height:0" width="1" class="CToWUd"></td>
                      </tr>
                      <tr>
                        <td align="left" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:207px" width="207">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:24px;margin:0;padding:0;width:207px">Subtotal</div>
                        </td>
                        <td align="right" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:88px" width="88">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:24px;margin:0;padding:0">${subtotal ?? ""}</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="left" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:207px" width="207">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:24px;margin:0;padding:0;width:207px">Tax Paid:</div>
                        </td>
                        <td align="right" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:88px" width="88">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:bold;line-height:24px;margin:0;padding:0">${tax ?? ""}</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="left" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:207px" width="207">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:bold;line-height:24px;margin:0;padding:0;width:207px">Total Paid:</div>
                        </td>
                        <td align="right" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:88px" width="88">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:bold;line-height:24px;margin:0;padding:0">${total ?? ""}</div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" height="22" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td bgcolor="#ffffff" style="border-collapse:collapse;font-size:0;line-height:0;padding:0"><img alt="" border="0" height="1" src="https://ci4.googleusercontent.com/proxy/QoQHcFtSHZQ36-hElJaHREN5X2kwMc2IZoVtPkLYU6uqsP4YJ_8RNnIhex6jeOIE_1LEfctTenZ7JngQltAsIdSN-PGXE-PIp-g3Jfqf3KW4aA4wwLzeuj662n7NZymqcXWNGMgTX3PnDWh395E=s0-d-e1-ft#https://d3g64w74of3jgu.cloudfront.net/assets/spacer-239465b1c71b79ae1c276e8ef6c73207.png"
                  style="font-size:0;line-height:0" width="20" class="CToWUd"></td>
              </tr>
            </tbody>
          </table>
         
          <table bgcolor="#ebebeb" cellpadding="0" cellspacing="0" style="background:#ebebeb;border-collapse:collapse;border-spacing:0;border:0;width:420px" width="420">
            <tbody>
              <tr>
                <td colspan="1" height="30" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
              </tr>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" valign="top">
                  <div style="color:#a0a0a0;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:11px;font-weight:normal;line-height:16px;margin:0;padding:0">${companyName ?? ""}</div>
                </td>
              </tr>
              <tr>
              </tr>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" valign="top">               
                  <div style="color:#a0a0a0;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:11px;font-weight:normal;line-height:16px;margin:0;padding:0"><a href="${siteLink ?? ""}"
                    style="color:#a0a0a0;text-decoration:none!important" target="_blank">${copyright ?? ""}</a></div>
                </td>
              </tr>  
              <tr>
                <td height="10">
                  
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  `
}

const BookingReciept = ({ header, orderID, title, items, subtotal, tax, total, profilelink, copyright, companyName, siteLink, logo,uid }) => {

    let itemsElem = '';
    let type = items.every((item) => item.type?.includes('submission')) ? 'submission' : items.every((item) => item.type?.includes('booking')) ? 'booking' : 'all';
    items.forEach((item) => {
      var link =  item.type?.includes('submission')  ? profilelink :`${siteLink}ticket:${uid}_${item.bid}`//ticketLink + '/' + item.code
        itemsElem += `
        <tr>
        <td colspan="2" height="10" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
      </tr>
      <tr>
        <td align="left" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:207px" valign="top" width="207">
          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:24px;margin:0;padding:0;width:207px;display:flex;justify-content:center;flex-direction:column"><span>${item.type.includes('booking') ? 'Room Code':'Details'}</span></div>
        </td>
        <td align="right" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:88px" valign="top" width="88">
        ${item.type?.includes('submission') ? `<div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:bold;line-height:24px;margin:0;padding:0;display:flex;justify-content:center;flex-direction:column"><a href=${link}><span>${item.type.includes('video') ? `Video Submission | ${item.title}`:''}</span></a><span>${item.summary}</span></div>`:`<div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:bold;line-height:24px;margin:0;padding:0;display:flex;justify-content:center;flex-direction:column"><a href=${link}><span>${item.code}</span></a><span>${item.guest} Guest(s) | ${item.startTime <= 0 ? "-" : new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric", hour: "numeric", minute: "numeric", hour12: false }).format(item.startTime)} - ${item.startTime <= 0 ? "" : new Intl.DateTimeFormat("en-US", { hour: "numeric", minute: "numeric", hour12: false }).format(item.endTime)}</span></div>`}
        </td>
      </tr>
      <tr>
        <td colspan="2" height="11" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
      </tr>

      <tr>
        <td colspan="2" height="1" style="border-collapse:collapse;border-top-color:#e0e1e2;border-top-style:dashed;border-top-width:1px;font-size:0;line-height:0;padding:0"><img alt="" border="0" height="1" src="https://ci4.googleusercontent.com/proxy/QoQHcFtSHZQ36-hElJaHREN5X2kwMc2IZoVtPkLYU6uqsP4YJ_8RNnIhex6jeOIE_1LEfctTenZ7JngQltAsIdSN-PGXE-PIp-g3Jfqf3KW4aA4wwLzeuj662n7NZymqcXWNGMgTX3PnDWh395E=s0-d-e1-ft#https://d3g64w74of3jgu.cloudfront.net/assets/spacer-239465b1c71b79ae1c276e8ef6c73207.png"
          style="font-size:0;line-height:0" width="1" class="CToWUd"></td>
      </tr>
      <tr>
        <td colspan="2" height="11" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
      </tr>
        `
    })
    return `<table align="center" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0;margin-left:auto;margin-right:auto;margin-top:20px;text-align:left;width:420px" width="420">
    <tbody>
      <tr>
        <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="320">
          <table bgcolor="#ffba08" border="0" cellpadding="0" cellspacing="0" style="background:#ffba08;border-collapse:collapse;border-spacing:0;border:0;width:520px" width="320">
            <tbody>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" valign="top"></td>
              </tr>
              <tr>
                <td colspan="1" height="6" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
              </tr>
              <tr>
                <td align="center" style="border-Sonyercollapse:collapse;color:#001219;font-size:11px;line-height:16px;padding:0;text-align:center"><span class="il"></td>
              </tr>
              <tr>
                <td colspan="1" height="6" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
              </tr>
            </tbody>
          </table>
          <table border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0">
            <tbody>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;overflow:hidden;padding:0;width:520px" valign="top" width="320">
                  <table cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0">
                    <tbody>
                      <tr>
                        <td style="border-collapse:collapse;font-size:0;line-height:0;padding:0"><img alt="" height="3" src="https://ci4.googleusercontent.com/proxy/GWhj4SAs8YYRjSx_tVCK5C1hQlz7AcfsWvz-vEp9BBGru1TseeO2M-f8YHByG_CuGsmtFVUYkmdNLErjWP7EaukcMeOcs4aCNSCPu-bAJVAdo4fbxFMgw-JmW7LnR-vPg5vZbCx9BN6eYotiEG98Vupk6wmFBQDGrw4hQcBqI28E9m32Lt26J_Nf7ZnMImGG8Y_qWb3ht915yGZI_qIVqMPGRAJZpfpC679iiko_kyHPpKAWaVOwALwRlRL5V99rPsF-N82gt_nGkyU3J3Dq3Gc4OXZzndPqqjpTtBcN5rD8XdXPau8An4rTEjyKq5RaK3hVu_kFebOs9w19Y5FAsy2hWvJbFn9herYRCXSawwdvcUJRACeHHQZWhtkiUr0TG5Jp5ED7xEZO9h-2upypLIwYAYC5cCFrBQr7X4n-NeqUunceld-B72SukpTXC9hhXlmY7QpIUpM4Aw=s0-d-e1-ft#https://d2isyty7gbnm74.cloudfront.net/yiUkp5VW7HW2dH1o8gYhPAiGBOU=/fit-in/640x6/filters:fill(43505e):watermark(https://d3g64w74of3jgu.cloudfront.net/assets/receipt-top-edge-mask-e6bbaf30c69e83509da5153571bd94ca.png,0,0,0):quality(100)/https://d3g64w74of3jgu.cloudfront.net/assets/spacer-239465b1c71b79ae1c276e8ef6c73207.png"
                          style="border:0;display:block" width="420" class="CToWUd"></td>
                      </tr>
                    </tbody>
                  </table>
                  <table cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0;width:100%" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" bgcolor="#001219" style="background:#001219;border-collapse:collapse;font-size:0;line-height:0;padding:0;width:520px" valign="middle" width="320">
                          <table cellpadding="0" cellspacing="0" height="100%" style="border-collapse:collapse;border-spacing:0;border:0;table-layout:fixed;width:100%;word-wrap:break-word" width="100%">
                            <tbody>
                              <tr>
                                <td colspan="1" height="35" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
                              </tr>
                              <tr>
                                <td align="center" style="border-collapse:collapse;color:white;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:30px;padding:0 36px;width:100%"
                                width="100%">
                                  <div align="center" style="min-height:80px;margin-bottom:5px;text-align:center;vertical-align:middle;width:80px"><img alt="" border="0" height="80" width="80" style="object-fit:contain" src="${logo}"
                                    style="vertical-align:middle" width="80" class="CToWUd"></div><span style="color:white;font-size:20px;line-height:26px;margin:0;font-weight:bold;display:flex;justify-content:center;flex-direction:column"><span class="il">${header ?? 'YOUR ORDER IS COMPLETED'}</span><span>Order ID #${orderID}</span>
                                </td>
                              </tr>
                              <tr>
                                <td colspan="1" height="21" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
                              </tr>
                              <tr>
                                <td align="center" height="8" style="border-collapse:collapse;font-size:0;height:8px;line-height:0;padding:0" valign="bottom">
                                  <div style="border-bottom-color:#546476;border-bottom-style:solid;border-left-color:transparent;border-left-style:solid;border-right-color:transparent;border-right-style:solid;border-width:0 8px 8px;min-height:0;vertical-align:bottom;width:0"></div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table bgcolor="white" cellpadding="0" cellspacing="0" style="background:white;border-collapse:collapse;border-spacing:0;border:0;width:100%" width="100%">
            <tbody>
              <tr>
                <td colspan="1" height="24" style="border-collapse:collapse;color:#363636;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:0;font-weight:normal;line-height:0;padding:0" width="100%"> </td>
              </tr>
              <tr>
                <td align="center" style="border-collapse:collapse;color:#363636;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:30px;padding:0">
                  <div align="center" style="color:#363636;font-family:SQMarket-Medium,HelveticaNeue-Medium,Helvetica-Bold,Helvetica,Arial,sans-serif;font-size:64px;font-weight:500;line-height:72px;text-align:center;white-space:nowrap">${title ?? ""}</div>
                </td>
              </tr>
              <tr>
                <td colspan="1" height="28" style="border-collapse:collapse;color:#363636;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:0;font-weight:normal;line-height:0;padding:0" width="100%"> </td>
              </tr>
            </tbody>
          </table>
          <table bgcolor="white" border="0" cellpadding="0" cellspacing="0" style="background:white;border-collapse:collapse;border-spacing:0;border:0;width:100%" width="100%">
            <tbody>
              <tr>
                <td bgcolor="#ffffff" style="border-collapse:collapse;font-size:0;line-height:0;padding:0"><img alt="" border="0" height="1" src="https://ci4.googleusercontent.com/proxy/QoQHcFtSHZQ36-hElJaHREN5X2kwMc2IZoVtPkLYU6uqsP4YJ_8RNnIhex6jeOIE_1LEfctTenZ7JngQltAsIdSN-PGXE-PIp-g3Jfqf3KW4aA4wwLzeuj662n7NZymqcXWNGMgTX3PnDWh395E=s0-d-e1-ft#https://d3g64w74of3jgu.cloudfront.net/assets/spacer-239465b1c71b79ae1c276e8ef6c73207.png"
                  style="font-size:0;line-height:0" width="20" class="CToWUd"></td>
                <td style="border-collapse:collapse;font-size:0;line-height:0;padding:0">
                  <table bgcolor="white" border="0" cellpadding="0" cellspacing="0" style="background:white;border-collapse:separate;border-spacing:0;border:0;table-layout:fixed;width:100%;word-wrap:break-word" width="100%">
                    <tbody>
                      <tr>
                        <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" valign="top"></td>
                      </tr>
                      <tr>
                        <td colspan="2" height="1" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
                      </tr>
                      <tr>
                        <td colspan="2" height="1" style="border-collapse:collapse;border-top-color:#e0e1e2;border-top-style:dashed;border-top-width:1px;font-size:0;line-height:0;padding:0"><img alt="" border="0" height="1" src="https://ci4.googleusercontent.com/proxy/QoQHcFtSHZQ36-hElJaHREN5X2kwMc2IZoVtPkLYU6uqsP4YJ_8RNnIhex6jeOIE_1LEfctTenZ7JngQltAsIdSN-PGXE-PIp-g3Jfqf3KW4aA4wwLzeuj662n7NZymqcXWNGMgTX3PnDWh395E=s0-d-e1-ft#https://d3g64w74of3jgu.cloudfront.net/assets/spacer-239465b1c71b79ae1c276e8ef6c73207.png"
                          style="font-size:0;line-height:0" width="1" class="CToWUd"></td>
                      </tr>
                        ${itemsElem ?? ""}
                      <tr>
                        <td align="left" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:207px" width="207">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:24px;margin:0;padding:0;width:207px">Subtotal</div>
                        </td>
                        <td align="right" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:88px" width="88">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:24px;margin:0;padding:0">${subtotal ?? ""}</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="left" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:207px" width="207">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:normal;line-height:24px;margin:0;padding:0;width:207px">Tax Paid:</div>
                        </td>
                        <td align="right" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:88px" width="88">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:bold;line-height:24px;margin:0;padding:0">${tax ?? ""}</div>
                        </td>
                      </tr>
                      <tr>
                        <td align="left" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:207px" width="207">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:bold;line-height:24px;margin:0;padding:0;width:207px">Total Paid:</div>
                        </td>
                        <td align="right" style="border-collapse:collapse;font-size:0;line-height:0;padding:0;width:88px" width="88">
                          <div style="color:#363636;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:14px;font-weight:bold;line-height:24px;margin:0;padding:0">${total ?? ""}</div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" height="22" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td bgcolor="#ffffff" style="border-collapse:collapse;font-size:0;line-height:0;padding:0"><img alt="" border="0" height="1" src="https://ci4.googleusercontent.com/proxy/QoQHcFtSHZQ36-hElJaHREN5X2kwMc2IZoVtPkLYU6uqsP4YJ_8RNnIhex6jeOIE_1LEfctTenZ7JngQltAsIdSN-PGXE-PIp-g3Jfqf3KW4aA4wwLzeuj662n7NZymqcXWNGMgTX3PnDWh395E=s0-d-e1-ft#https://d3g64w74of3jgu.cloudfront.net/assets/spacer-239465b1c71b79ae1c276e8ef6c73207.png"
                  style="font-size:0;line-height:0" width="20" class="CToWUd"></td>
              </tr>
            </tbody>
          </table>
          <table bgcolor="white" cellpadding="0" cellspacing="0" style="background:white;border-collapse:collapse;border-spacing:0;border:0;table-layout:fixed;width:100%;word-wrap:break-word" width="100%">
            <tbody>
             
                <td colspan="1" height="15" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
              </tr>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0 10">
                  <div align="center" style="color:#363636;font-family:SQMarket-Medium,HelveticaNeue-Medium,'Helvetica Neue Medium',Helvetica-Bold,Helvetica,Arial,sans-serif;font-size:14px;font-weight:500;line-height:24px;margin:0;padding:0;text-align:center;text-decoration:none"><span class="il">${type === 'submission' ? 'What Happens Next?':'How do i view get tickets?'}</div>
                  <div align="center"  style="color:#363636;padding:10 20;font-family:SQMarket-Regular,HelveticaNeue,'Helvetica Neue',Helvetica,Arial,sans-serif;font-size:12px;font-weight:normal;line-height:24px;margin:0;padding:0;text-align:center;text-decoration:none">
                    <div align="center" style="color:#363636;font-size:12px;line-height:24px;text-align:center;text-decoration:none">${type === 'submission' ? 'Once your payment has been processed, based on your bundle you video will be upload to the approciate channels, you will get a confirmation email once this is done. Thats it!': type === 'booking' ? "Click on the Room Code Id and you'll be taking to a page with a QR Code show this at the door to be scanned, That's it!":''}</div>
                    <div align="center" style="color:#363636;font-size:12px;line-height:24px;text-align:center;text-decoration:none">Visit our website for any other Questions</div>
                  </div>
                  
                </td>
              </tr>
              <tr>
                <td colspan="1" height="15" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
              </tr>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%">
                  <table border="0" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0">
                    <tbody>
                      <tr>
                        <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" valign="top">
                          <div>
                            <a href="${profilelink ?? ""}"
                            style="background:#ffba08;border-radius:40px;color:white;display:block;font-family:SQMarket-Medium,HelveticaNeue-Medium,'Helvetica Neue Medium',Helvetica-Bold,Helvetica,Arial,sans-serif;font-size:14px;font-weight:500;line-height:32px;padding-left:20px;padding-right:20px;text-align:center;text-decoration:none;text-transform:uppercase;white-space:nowrap;width:150px"
                            target="_blank">Visit Profile</a>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colspan="1" height="15" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
              </tr>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0">
                  <table align="center" cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0">
                 
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
          <table cellpadding="0" cellspacing="0" style="border-collapse:collapse;border-spacing:0;border:0" width="320">
            <tbody>
              <tr>
                <td bgcolor="#e0e0e0" colspan="2" height="1" style="border-collapse:collapse;font-size:0;line-height:0;padding:0"> </td>
              </tr>
            </tbody>
          </table>
          
          <table bgcolor="#ebebeb" cellpadding="0" cellspacing="0" style="background-color:#ebebeb!important;border-collapse:collapse;border-spacing:0;border:0">
            <tbody>
              <tr>
                <td style="border-collapse:collapse;font-size:0;line-height:0;padding:0"><img alt="Receipt bottom edge" height="3" src="https://ci3.googleusercontent.com/proxy/6woRqkcsgzv5OzSLz6G0xZX62BVYy6PPY4a9OSCzC5paiLDpNhRusANuRaX56gBUWXE5LXYIDBgslede_UM-7LB292LWY_wjFRS6ZdvcjKZP1sB_XaE7aQwrUwU861mmhk-81bYvQEmI2kWuSiRu9wm8WHLIHDBh74sL=s0-d-e1-ft#https://d3g64w74of3jgu.cloudfront.net/assets/receipt-bottom-edge-03756aff54ee465372ab1db0a5916aa6.png"
                  style="border:0;display:block" width="420" class="CToWUd"></td>
              </tr>
            </tbody>
          </table>
          <table bgcolor="#ebebeb" cellpadding="0" cellspacing="0" style="background:#ebebeb;border-collapse:collapse;border-spacing:0;border:0;width:420px" width="420">
            <tbody>
              <tr>
                <td colspan="1" height="30" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" width="100%"> </td>
              </tr>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" valign="top">
                  <div style="color:#a0a0a0;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:11px;font-weight:normal;line-height:16px;margin:0;padding:0">${companyName ?? ""}</div>
                </td>
              </tr>
              <tr>
              </tr>
              <tr>
                <td align="center" style="border-collapse:collapse;font-size:0;line-height:0;padding:0" valign="top">               
                  <div style="color:#a0a0a0;font-family:SQMarket-Regular,HelveticaNeue,Helvetica,Arial,sans-serif;font-size:11px;font-weight:normal;line-height:16px;margin:0;padding:0"><a href="${siteLink ?? ""}"
                    style="color:#a0a0a0;text-decoration:none!important" target="_blank">${copyright ?? ""}</a></div>
                </td>
              </tr>  
              <tr>
                <td height="10">
                  
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
  `
}
