/* eslint-disable no-undef */
import * as firebase from "firebase/app"
import 'firebase/auth'
import 'firebase/database'
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";
const firebase_app=firebase.default.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID

})
export const firestore = firebase_app.firestore();
firestore.enablePersistence();
export const storage = firebase_app.storage();

export default firebase_app;