import FormControl from '@material-ui/core/FormControl';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import React from 'react';
import PropTypes from 'prop-types';


const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
}));



export default function DropdownModule(props) {
  const BootstrapInput = withStyles((theme) => ({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: props.style?.borderRadius ?? 4,
      position: 'relative',
      backgroundColor: props.style?.backgroundColor ?? theme.palette.background.paper,
      border: props.style?.border ?? '1px solid ' + alpha(theme.palette.text.primary, 0.6),
      fontSize: props.style?.fontSize ?? 16,
      padding: props.style?.padding ?? '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      // Use the system font instead of the default Roboto font.
      fontFamily: props.style?.fontFamily ?? [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: props.style?.borderRadius ?? 4,
        borderColor: props.style?.focusColor ?? theme.palette.primary.main,
        boxShadow: '0 0 0 0.2rem ' + alpha(theme.palette.primary.main, 0.25)// rgba(0,123,255,.25)',
      },
    },
  }))(InputBase);
  const classes = useStyles();
  const [selected, setSelected] = React.useState(props.defaultValue);
  const handleChange = (event) => {
    if (props.onSelected) {
      props.onSelected(event.target.value);
      setSelected(event.target.value);
    }
    else {
      console.warn("No Selected callback!");
    }
  };
  return (
    <div style={{ ...props.style, height: props.style?.height ?? "auto", width: props.style?.width ?? "100%" }}>
      <FormControl className={classes.margin}>
        <InputLabel id="demo-customized-select-label">{props.label}</InputLabel>
        <Select
          disabled={props.disabled}
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={selected}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {props.items.map((item, index) => (<MenuItem key={index} value={item.value}>{item.label}</MenuItem>))}
        </Select>
      </FormControl>
    </div>
  );
}

DropdownModule.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  onSelected: PropTypes.func,
  defaultValue: PropTypes.any,
  style: PropTypes.object,
  disabled: PropTypes.bool,
}