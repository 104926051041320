
import firebase_app from "../config/firebase";
import { sendNotification } from "./notificationHandler";
export const sendChatMessage = async ({messageId, senderId, reciepientKey, reciepients, content, timestamp, seenby },notification)=>{
    const message = {
        messageId: messageId,
        senderId: senderId,
        reciepientKey: reciepientKey,
        reciepientId: reciepients,
        content: content,
        timestamp: timestamp,
        seenby: seenby
    };
    try{
       await firebase_app.firestore().collection('Messages').doc(messageId).set(message); 
       if(notification){
            const {type, title, url} = notification;
            for(const sendTo of reciepients)
            sendNotification({
                id: messageId,
                sendTo: sendTo,
                sentFrom: senderId,
                type: type,
                title: title,
                message: `${content}`,
                timestamp: timestamp,
                seen: false,
                url: url
            })
       }
       return true;
    }catch(e){
        console.log(e);
        return false
    }
}
export const useChatMessanger = () => {
    //sender = userA
    //reciepientKey= userA_userB_dtv-adminId or userB_userA_dtv-adminId
    const sendMessage = async({messageId, senderId, reciepientKey, reciepients, content, timestamp, seenby })=>{
        sendChatMessage({messageId, senderId, reciepientKey, reciepients, content, timestamp, seenby });
        
    }
    const streamMessages = (key, callback)=>{
     firebase_app.firestore().collection('Messages').where('reciepientKey', '==', key).onSnapshot((snapshot)=>{
         const messages = snapshot.docs.map((doc)=>doc.data());
         callback(messages);
     })
    }
    const updateSeenBy = async (key,uid)=>{
        try{
            console.log(uid);
            const transactions = await firebase_app.firestore().collection('Messages').where('reciepientKey', '==', key).where('seenby', 'not-in', [uid]).get();
            transactions.forEach((transaction)=>{
                transaction.ref.update({seenby: [...transaction.data().seenby, uid]})
            });
            return true;
        }
        catch(e){
            console.log(e);
            return false;
        }
    }
    // const streamMessages = (senderId, reciepientId, callback)=>{
    //     const reciepientKeyV1 = `${senderId}_${reciepientId}_dtv-adminId`;
    //     const reciepientKeyV2 = `${reciepientId}_${senderId}_dtv-adminId`;
    //     firebase_app.firestore().collection('Messages').where('reciepientKey', 'in', [reciepientKeyV1, reciepientKeyV2]).onSnapshot((snapshot)=>{
    //         const messages = snapshot.docs.map((doc)=>doc.data());
    //         callback(messages);
    //     })
    //    }
    return {
        sendMessage,
        streamMessages,
        updateSeenBy
    }
};
