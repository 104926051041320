/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, Typography } from "@material-ui/core"
import Row from "./Row";
import ServiceAgreementPage from "../pages/serviceAgreementPage";
import { useDatabase } from "../hooks/useDatabase";
import { Skeleton } from "@mui/material";
import PropTypes from 'prop-types';

const ServiceAgreementCard = ({ serviceAgreement, highlight }) => {
    const { getAgreement } = useDatabase();
    const [agreement, setAgreement] = useState();
    const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(true);
        if(typeof serviceAgreement === 'string'){
             getAgreement(serviceAgreement).then((agreement) => {
            setAgreement(agreement);
            setLoading(false);
        })
        }else{
            setAgreement(serviceAgreement);
            setLoading(false);
        }
       
    },[])
    return <>
       { loading ? <>
        <Skeleton variant="rectangular" width={200} height={100} sx={{bgcolor: 'grey.900'}} />
       </> : agreement && <Card style={{
            padding: 10, maxWidth: 200,
            borderColor: highlight ? 'green' : 'grey',
        }} variant="outlined">
            <CardContent>
                <Typography variant="body1">{agreement.name}</Typography>
            </CardContent>
            <div style={{ height: 10 }} />
            <Row alignment="space-between">
                <Chip label={agreement.cost > 0 ? `${agreement.cost}` : 'FREE'} variant="outlined" color="primary" />
                <Button onClick={
                    () => {
                        setOpenAgreementDialog(true);
                    }
                }>
                    <Typography variant="caption">READ</Typography>
                </Button>
            </Row>
        </Card>}
        <Dialog open={openAgreementDialog} onClose={() => {
            setOpenAgreementDialog(false);
        }} maxWidth="md" fullWidth aria-labelledby="form-dialog-title">
            <DialogContent>
                <ServiceAgreementPage agreement={agreement} viewOnly />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    setOpenAgreementDialog(false);
                }}>Close</Button>
            </DialogActions>
        </Dialog>
    </>
};
export default ServiceAgreementCard;


ServiceAgreementCard.propTypes = {
    serviceAgreement: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    highlight: PropTypes.bool
}