import React, { useState } from "react";
import { Button, Card, Chip, Divider, Typography } from "@material-ui/core";
import Column from "./Column";
import Row from "./Row";
import { MarkdownTextField } from "./_new_story_comp";
import { AlignHorizontalLeft } from "@mui/icons-material";
import { motion, AnimatePresence } from 'framer-motion/dist/framer-motion';
import PropTypes from 'prop-types'
export const getNormalTextFromMarkdown = (text) => {

    let formattedText = text;
    // Apply task list formatting
    formattedText = formattedText.replace(/-\s\[x\](.*?)\s((.^\n)*)/g, '$1');
    formattedText = formattedText.replace(/-\s\[(.*?)\]\s((.^\n)*)/g, '$2');
    // Apply list formatting
    formattedText = formattedText.replace(/(-\s(.*))/g, "$2");
    // Apply blockquote formatting
    formattedText = formattedText.replace(/>>(.*)/g, '$1');
    // Apply link formatting
    formattedText = formattedText.replace(/\[(.*?)\]\((.*?)\)/g, '$1');
    // Apply highlight formatting
    formattedText = formattedText.replace(/==(.*?)==/g, '$1');
    // Apply strike-through formatting
    formattedText = formattedText.replace(/~~(.*?)~~/g, '$1');
    // Convert nested ordered list notation to HTML
    formattedText = formattedText.replace(/(\d+\.(.*?)\n)+/g, (match) => {
        const lines = match.trim().split('\n');
        let nestedList = '';
    
        for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const listContent = line.substring(line.indexOf('.') + 1).trim();
        nestedList += `${listContent}`;
        }
    
        return `${nestedList}`;
    });
    // Apply header 1 formatting
    formattedText = formattedText.replace(/### (.*)/g, '$1');
    // Apply bold formatting
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, "$1");
    // Apply italic formatting
    formattedText = formattedText.replace(/\*(.*?)\*/g, "$1");
    // Apply color formatting
    formattedText = formattedText.replace(/\(\[(.*?)\]\)\((.*?)\)/g, '$2');
    // Apply underline formatting
    formattedText = formattedText.replace(/__(.*?)__/g, "$1");
    formattedText = formattedText.replace(/\n/g,"");
    return formattedText;
    
}
export  const getHTMLFromMarkdown = (text) => {
    let formattedText = text;
    
    
    // Apply task list formatting
    formattedText = formattedText.replace(/-\s\[x\](.*?)\s((.^\n)*)/g, '<input class="MuiCheckbox-root" type="checkbox" checked disabled style="background: transparent;" />$1');
    formattedText = formattedText.replace(/-\s\[(.*?)\]\s((.^\n)*)/g, '<input class="MuiCheckbox-root" type="checkbox" disabled />$2');
    // Apply list formatting
    formattedText = formattedText.replace(/(-\s(.*))/g, "<li style='list-style-type: circle;'>$2</li>");

     // Apply blockquote formatting
     formattedText = formattedText.replace(/>>(.*)/g, '<blockquote style="color:#C1C7D0; border-left:0.2em solid #757D87; padding: 0 1em">$1</blockquote>');
    // Apply link formatting
    formattedText = formattedText.replace(/\[(.*?)\]\((.*?)\)/g, '<a style="color:antiquewhite;" href="$2">$1</a>');
   
     // Apply highlight formatting
     formattedText = formattedText.replace(/==(.*?)==/g, '<mark>$1</mark>');
      // Apply strike-through formatting
    formattedText = formattedText.replace(/~~(.*?)~~/g, '<s>$1</s>');
    // Convert nested ordered list notation to HTML
    formattedText = formattedText.replace(/(\d+\.(.*?)\n)+/g, (match) => {
      const lines = match.trim().split('\n');
      let nestedList = '';

      for (let i = 0; i < lines.length; i++) {
        const line = lines[i];
        const listContent = line.substring(line.indexOf('.') + 1).trim();
        nestedList += `<li style="list-style-type: decimal;">${listContent}</li>`;
      }

      return `<ol>${nestedList}</ol>`;
    });
    
   // Apply header 1 formatting
   formattedText = formattedText.replace(/### (.*)/g, '<h4 style="font-size: 1.55em;">$1</h4>');

    // Apply bold formatting
    formattedText = formattedText.replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");

    // Apply italic formatting
    formattedText = formattedText.replace(/\*(.*?)\*/g, "<i>$1</i>");

    // Apply color formatting
    formattedText = formattedText.replace(/\(\[(.*?)\]\)\((.*?)\)/g, '<span style="color:$1">$2</span>');

    // Apply underline formatting
    formattedText = formattedText.replace(/__(.*?)__/g, "<u>$1</u>");
    formattedText = formattedText.replace(/\n/g,"</br>");
    console.log(formattedText);
    
    return formattedText;
  };



const MarkdownTextarea = ({ 
    labelComponent,
    label,
    labelIcon,
    markdownValue,
    editmode,
    enableShowMore,
    maxHeight,
    onChange }) => {

    const [value, setValue] = useState(markdownValue??{text:"",style:{}});
    const [edit, setEdit] = useState(editmode??false);
    const [showMore, setShowMore] = useState(!enableShowMore??true);
    return !editmode ?   
    <Column> <AnimatePresence initial={false}>
    <motion.div  className="overflow"
    style={{ overflow: 'hidden'}}
    initial="collapsed"
animate={showMore ? 'expanded' : 'collapsed'}
variants={{
expanded: { height: 'auto' },
collapsed: { height: maxHeight ?? '21.6em' },
}}
transition={{ duration: 0.3 }} > <MarkdownTextField
        onStyleUpdate={(style)=>{
         setValue({...value,style:style});
        }}
        style={value?.style} defaultValue={value?.text} viewMode={true}/>  
        </motion.div> 
        </AnimatePresence>
        {(enableShowMore && value?.text?.length) > 300 && (
                    <Row style={{width:"100%"}} alignment="right">
                        <Button style={{marginTop: 20, width: 100}} onClick={()=>{
                        setShowMore(!showMore);
                    }} color="default" size="small">
                        {showMore ? 'Show Less' : 'Show More'}
                    </Button>
                    </Row>
                    )}
      </Column>
   :
    <Card style={{padding: 20}}variant="outlined">
    <Column>
  {labelComponent ?? <Row>
      {labelIcon&&<AlignHorizontalLeft style={{marginRight: 10}}/>}
      <Typography variant="h6">{label??""}</Typography>
  </Row>}
  <Divider style={{margin:"20px 0"}}/>
  <Row>
    <Chip label={"Write"} variant={edit ? "default" : "outlined"} onClick={()=>{
      setEdit(!edit);
    }}/>
    <div style={{width:10}}/>
    <Chip label={"Read"} variant={!edit ? "default" : "outlined"} onClick={()=>{
      setEdit(!edit);
    }}/>
  </Row>
  <div style={{height: 10}}/>
  {<MarkdownTextField
  onStyleUpdate={(style)=>{
   setValue({...value,style:style});
  }}
  style={value?.style} 
  onChange={
    (e)=>{
        setValue({...value,text:e});
        onChange && onChange({...value,text:e});
    }
  } defaultValue={value?.text} viewMode={!edit}/>}
  </Column>
  </Card>
}
export default MarkdownTextarea

MarkdownTextarea.propTypes = {
  labelComponent: PropTypes.element,
  label: PropTypes.string,
  labelIcon: PropTypes.element,
  markdownValue: PropTypes.object,
  editmode: PropTypes.bool,
  enableShowMore: PropTypes.bool,
  maxHeight: PropTypes.string,
  onChange: PropTypes.func
}