/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext,useEffect,useState } from "react"
import { ShowContext } from "../contexts/ShowProvider"
import { Card, CardContent, CircularProgress, Typography, useTheme } from "@material-ui/core";
import Column from "./Column";
import FutureBuilder from "./_future_builder";
import Row from "./Row";
import ReactPlayer from "react-player";
import { parseUrlForQueryParams, youtubeConfig } from "../utils";
import {motion} from 'framer-motion';
import { Skeleton } from "@mui/material";
import PropTypes from 'prop-types';


const EpisodeList = ({ showId, currentEpisodeId }) => {
    const {  getShow, getEpisode } = useContext(ShowContext);
    const [show, setShow,] = useState(null);
    const [seasons, setSeasons] = useState(null);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();
    useEffect(() => {
        setLoading(true);
        getShow(showId).then((show) => {
            console.log(show);
            setShow(show);
            setSeasons(Object.keys(show.seasons));
            setLoading(false);
        });
    }, [showId]);

    return <div>
        {
            loading ? <Column>
                <CircularProgress/>
            </Column> : seasons && <Column>
                {
                    seasons.map((season,index) => {
                        return <Column key={index}>
                            <Typography variant="h6" style={{textAlign:'left'}}>{`Season ${season}`}</Typography>
                            <div style={{height: 10}}/>
                            <Column alignment={'start'}>
                                {
                                    show.seasons[season].map((episodeId,index) => {
                                       return <FutureBuilder  key={episodeId}
                                        placeholder = {
                                            <Skeleton  sx={{ bgcolor: 'grey.900' }} variant="rectangular" width={'100%'} height={140} style={{margin: '10px 0'}}/>
                                        }
                                        futureFunc = {() => getEpisode(episodeId)}
                                        builder = {(episode) => {
                                            console.log(episode);
                                            return <motion.div whileHover={{scale: 1.03, cursor:'pointer'}} initial={{x:-10}} animate={{x:0}} exit={{x:-10}} transition={{duration:0.2}} key={index}>
                                                <Card style={{margin: '10px 0',padding: 15,
                                                border: currentEpisodeId === episode.episodeId ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.divider}`,
                                                }} variant={"outlined"}>
                                                    <CardContent  onClick={
                                                        ()=>{
                                                            window.location.replace(`/episode/w?id=${episode.episodeId}`);
                                                            
                                                        }
                                                    } style={{padding: 0}}>
                                                        
                                                <Row style={{width: '100%', height: 140}}>
                                                {episode.thumbnail ? 
                                                <img alt="" src={episode.thumbnail} style={{maxWidth: 140,objectFit:'cover'}}/> :
                                                 episode.isYoutube ? 
                                                    <img alt="" src={`https://i.ytimg.com/vi/${parseUrlForQueryParams(episode.link)['v']}/default.jpg`} style={{maxWidth: 140,objectFit:'cover'}}/> :
                                                <ReactPlayer 
                                                playsinline
                                                    muted
                                                    config={youtubeConfig}
                                                    playing={false}
                                                    controls={false}
                                                    url={episode.link}
                                                    height="100%"
                                                    onReady={(p) => { p.seekTo(20); }}
                                                    style={{maxWidth: 120}}
                                                    startTime={10}
                                                />}
                                                <div style={{width: 40}}/>
                                                <Column alignment={'start'}>
                                                    <Typography style={{textAlign:'left'}} variant="body1">{episode.title}</Typography>
                                                    <Typography style={{textAlign:'left'}} variant="caption" color={'textSecondary'}>{`Season ${episode.season_no} Episode ${episode.episode_no}`}</Typography>
                                                    <div style={{height: 10}}/>
                                                    <Typography style={{textAlign:'left'}} variant="caption">{episode.description}</Typography>
                                                </Column>
                                            </Row>
                                                    </CardContent>
                                            </Card>
                                                </motion.div>
                                        }}
                                    />
                                    })
                                }
                            </Column>
                        </Column>
                    })
                }
            </Column>
        }
    </div>
}
export default EpisodeList;

EpisodeList.propTypes = {
    showId: PropTypes.string,
    currentEpisodeId: PropTypes.string
}