import { Button, Dialog, Typography } from "@material-ui/core";
import { CheckCircleRounded } from "@material-ui/icons";
import PropTypes from 'prop-types';
import React, { useState } from "react";
import Column from "../../components/Column";
import Row from "../../components/Row";
import CartItem from "../../components/_cart_item";
export class SuccessMessage{
    constructor(icon,title,message,action,cartItems){
        this.icon = icon ?? <CheckCircleRounded style={{fontSize:25}}/> ;
        this.title = title ?? "Thank You for Your Purchase";
        this.message = message ?? "Your payment was successful and your order is complete.we have sent you an email as proof of delivery. The email will provide purchase details.You can use the DTV coins purchased in the D&B application";
        this.action = action;
        this.cartItems = cartItems??[];
    }
}
const SuccessPage = ({size,info,disableBackdropClick,open:OPEN})=>{
    const [open, setOpen] = useState(OPEN)

    const _buildBody= ()=>{
        console.log(info?.cartItems)
        return <Column classNAme="centered" style={{padding:20,width:"100%",minHeight:400}}>
            <Row alignment="centered">{info?.icon}
                </Row>
            <div style={{height:10}}/>
            <Column >
             {<Typography style={{fontSize:18,fontWeight:"bold",textAlign:"center"}}>{info?.title}</Typography>}
            <div style={{height:15}}/>
            <Row alignment="centered">
                {<Typography style={{fontSize:12,textAlign:"center",maxWidth:500}}>{info?.message}</Typography>}
            </Row>
            <div style={{height:10}} /><Row alignment="centered">
                {info?.cartItems?.map((e,i)=><CartItem key={e+"_"+i} item={e} viewOnly/>)}
            </Row>
            
            <div style={{height:10}}/>
            {info?.action ? <Button onClick={info?.action}>Close</Button>:<></>}
            </Column>
           
        </Column>
    }
    return <>
        <Dialog fullWidth maxWidth={size??"md"} open={open} hideBackdrop={disableBackdropClick} onClose={()=>{setOpen(false)}}>
            {_buildBody()}
        </Dialog>
    </>
}
SuccessPage.defaultProps = {
    info:new SuccessMessage()
}
SuccessPage.propTypes={
    info:PropTypes.object,
    open:PropTypes.bool,
    disableBackdropClick:PropTypes.bool,
    size:PropTypes.string
}
export default SuccessPage;

