import ReactPlayer from "react-player";
import Stack from "../components/Stack";

import React from "react";
import logo from '../assets/logo.png';
import backgroundVideo from '../assets/backgroundVideo.mp4';
import { Avatar, Typography } from "@material-ui/core";
import {motion} from 'framer-motion';
import Column from "../components/Column";
import Row from "../components/Row";
import PropTypes from 'prop-types';
const ComingSoon = ({SiteData})=>{

    return <div>
        <Stack>
            <div style={{background:"#000000", height:"100%", width:"100%"}}></div>
        <div style={{height:"100%", width:"100%",overflow:'hidden'}}>
            {<ReactPlayer playsinline url={backgroundVideo} 
                playing={true}
                height="100%"
                width="100%"
                loop={true}
                muted={true}
                    style={{objectFit: "cover"}} 
                    />}
            </div>
            <div style={{background:"#0000000d", height:"100%", width:"100%"}}></div>
            <div style={{background:"linear-gradient(0deg, black 14%, transparent)",height:"100%", width:"100%"}}></div>
            <motion.div style={{height:"100%", width:"100%"}}>
            <Column alignment="centered" style={{height:"100%", width:"100%", alignItems:'center'}}>
           
              
               <div style={{width:"10px"}}></div>
               <Typography variant={"overline"} style={{fontSize:"1.5vw",fontWeight:"lighter",fontFamily:"",color:"white"}}>{SiteData.maintenance?"Sorry, Site is Offline":"Coming Soon"}</Typography>
             <Row style={{alignItems:"center"}}>
                <Avatar style={{width:"80px",height:"80px"}} src={logo}/>
                <Typography style={{fontSize:"4vw",textTransform:"uppercase",fontFamily:"",color:"white"}}>{SiteData.name}</Typography>
           
               </Row>
             <Typography variant={"caption"} style={{fontWeight:"lighter",fontFamily:"",color:"white"}}>{SiteData.slogan}</Typography>
            </Column>
            </motion.div>
        </Stack>
    </div>
}
export default ComingSoon;

ComingSoon.propTypes = {
    SiteData: PropTypes.object
}