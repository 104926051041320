import { Card, CardActionArea, CardContent, CardMedia,  Typography, } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import '../bubble.css';
import Row from './Row';
import Stack from './Stack';
import Column from './Column';
export const NavBubble = (props)=>{
    const [isHovered, setHovered]  = useState(false);


    const createBubble = () => {
        return <div style={{...props.style,width:props.width??"200px",height:props.height??"300px", overflowY:"hidden",overflowX:"hidden"}}>
               <motion.div style={{width:'100%', height:'100%'}} animate={{y:isHovered? 0:150}} transition={{type:"tween", ease:"linear", duration:0.2}}>
                <Card style={{width:'100%',background:props.backgroundColor??"#0D0D0E",height:'100%'}} onMouseEnter={()=>setHovered(true)} onMouseLeave={()=>setHovered(false)}>
                    
                        <CardActionArea  style={{width:'100%',height:'100%'}} onClick={props.onClick}>
                            <CardMedia style={{height:220}}>
                                {
                                
                                props.backgroundVideo?
                                <div className="video-wrapper" style={{width:'100%', height:'100%',transform: !isHovered?'scale(1.3)':"" , background:"black", filter:isHovered?'blur(0px)':'blur(4px)'}}> 

                                
                                {props.name === "Stories" ? 
                                <Stack>
                                    <img alt="" src={props.backgroundVideo} style={{objectFit: "cover", height: '100%', width: '100%',  filter:  'brightness(0.25)'}}/>
                                    {isHovered ? <Column alignment="start" style={{height:'100%', width: '100%', padding:20}}>
                                        <Typography style={{color: 'white', fontSize: '20px', fontFamily: 'Pridi'}}>{props.title??""}</Typography>
                                        <Typography style={{color: 'white', fontSize: '12px', fontFamily: 'Pridi'}}>{"Read More"}</Typography>
                                    </Column>:<></>}
                                </Stack> :<ReactPlayer
                                    playing={isHovered}
                                    muted={true}
                                    className="react-player"
                                url={props.backgroundVideo} style={{objectFit:"cover", objectPosition:"center"}} width="100%" height="100%"/>} </div>:<></>}
                            </CardMedia>
                            <CardContent>
                            <motion.div style={{position:'relative'}}animate={{y:isHovered?0:-170}}>
                                <Stack>
                                    
                              {isHovered?<></>:  <motion.div 
                                transition={{type:"tween", ease:"linear", duration:0.2}}
                                animate={{y:isHovered?0:-170,x:isHovered?0:-16}}
                                style={{background:"linear-gradient(0deg, black 25%, transparent)",
                                //    transform: translate(-16px,-130px);
                                width:props.width??"200px",height:props.height??"300px"}} />}
                                <Row alignment="centered" style={{width:"100%", zIndex:"200px", alignItems:'center'}}>
                                        {isHovered?<></>:<div style={{color:!isHovered?'white':"white",fontSize:"20px", margin:"5px 8px"}}>{props.icon}</div>}
                                        {
                                            <motion.label whileHover={{scale:1.1,color:"white"}} onClick={props.onClick} transition={{type:"tween", ease:"linear", duration:0.5}} style={{fontFamily:"Pridi", fontSize:isHovered?"20px":"28px"}}animate={{color:!isHovered?'white':"#ffffff9c",fontSize:isHovered?"20px":"25px"}}>
                                                {props.name.toUpperCase()}
                                            </motion.label>
                                        }
                                </Row>
                                </Stack>
                                 
                            </motion.div>
                    
                            </CardContent>
                            </CardActionArea>   
                          
                         
                      
                </Card>
                </motion.div>
            </div>
    }
    const createMobileBubble = () => {
        return <div style={{...props.style,width:props.width??"90vw",height:props.height??"100px", overflowY:"hidden",overflowX:"hidden", transform:'skew(-5deg, 0deg)'}}>
                <motion.div 
                animate={{x:0}}
                initial={{x:-50}}
                onClick={props.onClick}
                style={{width:'100%', height:'100%', }} whileTap={{scale:0.9}} 
                transition={{type:"tween", ease:"linear", duration:0.2}} onMouseEnter={()=>setHovered(true)} onMouseLeave={()=>setHovered(false)}>
                <Card style={{width:'100%',background:props.backgroundColor??"#0D0D0E",height:'100%', position:"relative",border:'1px #ffd70026 solid'}}>
                <Stack>
                {props.backgroundVideo?
                                <div className="video-wrapper" style={{width:'100%', height:'100%', background:"black", }}> 

                                
                                {props.name === "Stories" ? 
                              
                                    <img alt="backgroundvideo" src={props.backgroundVideo} style={{objectFit: "cover", height: '100%', width: '100%',  filter:  'brightness(0.25)'}}/>
                                    :<ReactPlayer
                                    playing={true}
                                    muted={true}
                                    className="react-player"
                                url={props.backgroundVideo} style={{objectFit:"cover",transform:'scale(1.4)', objectPosition:"center"}} width="100%" height="100%"/>} </div>:<></>}
                                <div style={{width:'100%', height:'100%', background: "linear-gradient(-90deg, #000000cc 50%, transparent 78%)"}}/>
                                <Column alignment="centered" style={{width:"100%", height:"100%", alignItems:'center', padding: 20}}>
                                <Row style={{width:"100%", alignItems:'center'}} alignment="right">
                                    <Column style={{color:"white", fontSize:"14px", margin:'0 5px'}}>{props.icon}</Column>
                                    <motion.label style={{fontFamily:"Pridi", fontSize:"15px", color:"white"}}>
                                        {props.name.toUpperCase()}
                                    </motion.label>
                                </Row>
                                </Column>
                </Stack>
                </Card>
                </motion.div>
            </div>
    }
    return (
           props?.mobile ? createMobileBubble() : createBubble() 
    )
}