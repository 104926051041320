import React, { createContext,useState } from "react";
import { PlaylistService } from "../services/playlistService";
import PropTypes from 'prop-types';

export const PlaylistContext = createContext();

const PlaylistProvider = (props)=>{
    const [playlists, setPlaylist] = useState([]);
    const [maxSize, setMaxSize] = useState(0);
    const [, setLoading] = useState(false);
    const {createPlaylist,  getAllPlaylist, updatePlaylist} = PlaylistService();
    const addPlaylist = async(data)=>{
        return createPlaylist(data);
    }
    const editPlaylist = async(data)=>{
        return updatePlaylist(data);
    }
    const getPlaylists = async({limit,offset})=>{
       
        try{
            console.log("getting playlists");
           setLoading(true);
            const {data, total} = await getAllPlaylist({limit, offset});
            console.log(data);
            if(data){
                setPlaylist(data);
                setMaxSize(total);
            }
            setLoading(false);
        }catch(e){
            return e;
        }
    }
    return <PlaylistContext.Provider value = {
       { playlists,
        maxSize,
        addPlaylist,
        editPlaylist,
        getPlaylists,
    }
    } >{props.children}</PlaylistContext.Provider>
}
export default PlaylistProvider;
PlaylistProvider.propTypes = {
    children: PropTypes.node
}