import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Card, Dialog, IconButton, Typography, useTheme, LinearProgress } from "@material-ui/core";

import { motion } from 'framer-motion';
import { CheckCircleOutlineRounded, CloudUpload } from "@material-ui/icons";
import Row from "./Row";
import Column from "./Column";
import Center from "./Center";
import PropTypes from 'prop-types'


const SubmissionModal = forwardRef(function SM(props,ref) {
    const { open, uploading,onCompleted,progress, successText } = props;
   const [uploadingContent, ] = useState(uploading)
   const [openModal, setOpenModal] = useState(open);
   const theme = useTheme();

   useImperativeHandle(ref, () => ({
      open: (isOpen) => {
         setOpenModal(isOpen);
      }
   }));
   const BuildCompletion = () => {
        return <Card style={{ width: "100%", height: "300px" }}>
          <Center>
            {/* {errorProgress?<Column>
                   <Row alignment="centered">
                         
                            <IconButton style={{color:"red"}}>
                                <CloseOutlined/>
                            </IconButton>
                       
                  </Row>
                  <Typography variant="overline" align="center">{errorMessage}</Typography>
                  </Column>:*/} {
                uploadingContent ? <Column style={{ width: "100%" }}>
              <Row style={{ margin: "20px 0" }} alignment="centered">
                <motion.div animate={{
                  y: [10, 20, 10],
                  //rotate: [0, 0, 270, 270, 0],
                  style: {
                    color: 'green',
                  },
                  borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                }}
                  transition={{
                    duration: 2,
                    ease: "easeInOut",
                    times: [0, 0.2, 0.5],
                    loop: Infinity,
                    repeatDelay: 1
                  }}>
                  <IconButton style={{ color: theme.palette.primary.main, }}>
                    <CloudUpload style={{ fontSize: "26px" }} />
                  </IconButton>
                </motion.div>
              </Row>
    
              <Row alignment="centered" style={{ width: "100%" }}>
                <div style={{ width: "100%", padding: "0 10%" }}><LinearProgress style={{ width: "100%" }} variant="determinate"
                  value={progress}
                /></div></Row>
              <small style={{ marginLeft: "8px", textAlign: "center", fontSize: "12px", margin: "15px 0" }}>{progress}</small>
    
            </Column> :
              <Column>
                <Row alignment="centered">
                  <motion.div animate={{
                    scale: [1, 2, 2, 1, 1],
                    //rotate: [0, 0, 270, 270, 0],
                    style: {
                      color: 'green',
                    },
                    borderRadius: ["20%", "20%", "50%", "50%", "20%"],
                  }}
                    transition={{
                      duration: 2,
                      ease: "easeInOut",
                      times: [0, 0.2, 0.5, 0.8, 1],
                      loop: Infinity,
                      repeatDelay: 1
                    }}>
                    <IconButton style={{ color: theme.palette.primary.main }}>
                      <CheckCircleOutlineRounded />
                    </IconButton>
                  </motion.div>
                </Row>
                <Typography variant="overline" align="center">Success!</Typography>
               {successText??<></>}
                <Row alignment="centered">
                  <div style={ 
                    {
                      marginTop:30,
                      width:80
                    }
                   }>
                  <Button variant={"contained"} color="primary" onClick={() => {
                  onCompleted();
                } }>Done</Button>
                  </div>
               </Row>
              </Column>
            }
          </Center>
        </Card>
    }
    return <Dialog open={openModal} onClose={() => {
        setOpenModal(false);
     }} maxWidth="sm" fullWidth>
        <BuildCompletion/>
    </Dialog>
});
export default SubmissionModal;


SubmissionModal.propTypes = {
  open: PropTypes.bool,
  uploading: PropTypes.bool,
  onCompleted: PropTypes.func,
  completed: PropTypes.bool,
  profile: PropTypes.object,
  progress: PropTypes.number,
  successText: PropTypes.string
}