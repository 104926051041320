/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, createContext, useContext, useState} from 'react';
import firebase_app from '../config/firebase';
import { updateProfileDB } from '../logic/databaseHandler';
import { UserContext } from './UserProvider';
import PropTypes from 'prop-types';

export const useWallet = ()=>
    useContext(WalletContext);

export const WalletContext = createContext();



export const WalletProvider = (props)=>{
    const {profile,retrieveData} = useContext(UserContext);
    const [transactionStatus, setTransactionStatus] = useState({error:false, message:""});
    const [loadingTransaction,setLoading] = useState(false);
    const [transactions,setTransactions] = useState([]);
 
    useEffect(()=>{
       
        retrieveTransactions().then(()=>{
             console.log(transactions);
        });
    },[])
    const runPaymentTransaction = async(cost)=>{
        console.log(cost);
        setLoading(true);
        if((profile?.bank??0) >= cost){
            profile.bank = Math.max(0,(profile?.bank??0)-cost);
            await updateProfileDB(profile);
            await retrieveData();
            setLoading(false);
            setTransactionStatus({error: false, message: "Tranaction Completed Successfully"})
            return true;
        }else{
            console.log("false")
            setTransactionStatus({error: true, message: "Tranaction Failed: Insufficient funds"})
           
        }
        setLoading(false);
         return false;
    }
    const retrieveTransactions = async()=>{
        let trans = await firebase_app.firestore().collection("Users").doc( firebase_app.auth().currentUser?.uid??"n5iq2vvBJWdmhBoane1dkYZpYxt2").collection("Transactions").get();
        let res = [];
        trans.docs.forEach((v)=>{res.push(v.data())})
        setTransactions(res)
    }
    const topUpWallet = async(amount)=>{
        setLoading(true);
            profile.bank = (profile?.bank??0)+amount;
            await updateProfileDB(profile);
            await retrieveData();
            setTransactionStatus({error: false, message: "Top-up Completed Successfully"})
        
        setLoading(false);
    }
    return <WalletContext.Provider
        value={{
            runPaymentTransaction,
            retrieveTransactions,
            transactionStatus,
            loadingTransaction,
            transactions,
            topUpWallet,

        }}
    >
  {props.children}
    </WalletContext.Provider>
}
export default WalletProvider;

WalletProvider.propTypes = {
    children: PropTypes.node
}