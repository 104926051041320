import axios from "axios";
import { RouteNames, baseUrl } from "../config/api";

export const CheckoutService = ()=>{
    const getAllCheckoutItems = async({uid, limit, offset, cursor})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.checkoutList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                uid,
                limit,
                offset,
                cursor
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data?.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getCheckoutItemById = async ({uid,id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.checkout}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                uid
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const addCheckoutItem = async ({uid, item}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.checkout}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{  
                uid,
            },
            data: item
            
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteCheckoutItem = async ({uid, id}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.checkout}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                uid
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateCheckoutItem = async ({uid, id, item}) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.checkout}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                uid
            },
            data: item
            
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const clearCheckoutItems = async ({uid}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.checkoutClear}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchCheckoutItems = async ({uid, query,limit, offset, cursor}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.checkoutSearchCart}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid,
                query,
                limit,
                offset,
                cursor
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getAllTransactions = async ({uid,limit, offset, cursor}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.checkoutTransactionList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid,
                limit,
                offset,
                cursor
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getTransaction = async ({uid, id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.checkoutTransaction}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid,
                id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const addTransaction = async ({uid, transaction}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.checkoutTransaction}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid
            },
            data: transaction
            
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteTransaction = async ({uid, id}) => {
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.checkoutTransaction}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid,
                id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateTransaction = async ({uid, id, transaction}) => {
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.checkoutTransaction}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid,
                id
            },
            data:  transaction
            
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchTransactions = async ({uid, query, limit, offset, cursor}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.checkoutSearchTransaction}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                uid,
                query,
                limit,
                offset,
                cursor
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data.data,
                status: response.data.status ?? 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        getAllCheckoutItems,
        getCheckoutItemById,
        addCheckoutItem,
        deleteCheckoutItem,
        updateCheckoutItem,
        clearCheckoutItems,
        searchCheckoutItems,
        getAllTransactions,
        getTransaction,
        addTransaction,
        deleteTransaction,
        updateTransaction,
        searchTransactions
    }

}