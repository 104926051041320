import { Dialog, IconButton, makeStyles, Snackbar } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Stack from '../components/Stack';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Close } from '@material-ui/icons';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SiteDataContext } from '../config/SiteData';
import "../index.css";

import Slider from "react-slick";
import { AppBarModule } from '../components/_app_bar_mod';
import { firestore } from '../config/firebase';
import BookingPageOne from './booking/bk_page_1';
import BookingRoom from './booking/bk_room';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background:"black",
        overflowY:'hidden',
        maxWidth:'100%',
        flexGrow: 1,
        '& .MuiDivider-root':{
            backgroundColor:'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root':{
            color: 'white'
        }
      },
      iconBtn:{
        '&:hover': {
            color:"white"
        },
      },
      sideBarSocials:{
          color:"#ffffff47",
          fontSize:"12px",
        '&:hover': {
            color:"white"
        },
      },
      divider:{
          backgroundColor: "rgba(1,1,1, 1)"
      },
      center:{
        textAlign:"center",
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      gridList: {
        width: 500,
        height: 450,
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
      toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
     
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        padding:"3px 1px",
        position:"fixed",
       // background:"linear-gradient(45deg, black, #d09612bd);",
        background: "transparent",
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      socialIcon:{
        color:"#ffffff47",
        '&:hover': {
            color:"white"
        },
      },
      menuButtonHidden: {
        display: 'none',
      },
      title: {
        flexGrow: 1,
      },
      listItem:{
        backgroundColor: "#2C2C2C",
        color: "white",
        margin:10
      },
      listItemImage:{
        height:"60px",
        width:"60px",
        margin:"10px"
      },
     list:{
        transition: theme.transitions.create(["background", "background-color"], {
            duration: theme.transitions.duration.complex,
          }),
          "&:hover": {
            backgroundColor: "#6363638c",
            color:"black",
          },
     },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      grow:{
          '&:hover':{
            transform: 'scale(1.1)',
            easing:"linear",
            cursor: 'pointer'
          }
      },
      link:{
          color:"grey",
        '&:hover':{
            transform: 'scale(1.1)',
            cursor: 'pointer',
            color:'white'
          }
      },
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
      mobileNav:{
          width:"60%"
      },
      pcNav:{
          width:"30%"
      },
      logo:{
          height:"60px",
          width:"60px"
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.text.primary,
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
      fixedHeight: {
        height: 240,
      },
  }));


const BookingPage = ()=>{
  useContext(SiteDataContext)
  const classes = useStyles();
  
  const [,setDevice] = useState('pc');
  const [,setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] =useState(false);
  const [notificationMsg,] =useState('');
  const [deviceSize,updateSize] = useState({"w":0,"h":0})
  const [rooms, updateRooms] = useState([])


  const getRooms = async()=>{
   
    firestore.collection("StudioRooms").onSnapshot((querySnapshot) => {
  let temp = []
  querySnapshot.forEach((doc) => {
      temp.push(doc.data());
  });
 updateRooms(temp)
});
   
  }
  const slideRef= useRef(null)
    useEffect(()=>{
        getRooms();
        updateDevice();
        window.addEventListener("resize", updateDevice);
    },[])
  const updateDevice = ()=>{
    updateSize({"w":window.innerWidth,"h":window.innerHeight})
      if(window.innerWidth<550)
      {
          setDevice('mobile');
          setDrawerWidth(300)
      }
      else {
          setDevice('pc')
          setDrawerWidth(280)
        }
  }
  const settings = {
    className: "slider variable-width",
    dots: false,
    arrows:false,
    nextArrow:<></>,
    prevArrow:<></>,
    //lazyLoad: true,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,//Math.floor(window.innerWidth/350),
    slidesToScroll: 1,
    variableWidth: false,
    adaptiveHeight: false

};

  const _buildPage = (()=>{
      //...
      console.log(rooms)
      return <div style={{width:"100%", height:"100%"}}>
      <Slider {...settings} ref={slideRef} className="slider">
                    {
                      <div style={{width:deviceSize.w,height:deviceSize.h}}>
                        <BookingPageOne onNext={()=>{
                            console.log("next")
                            slideRef.current.slickNext()}
                          }/>
                      </div>
                      }
                      {rooms.map((r,index)=><BookingRoom 
                      onPrev={()=>{
                        slideRef.current.slickPrev()
                     }}
                      onNext={()=>{
                         slideRef.current.slickNext()
                      }} key = {"Room_"+index}room = {r}/>)}
                    
                </Slider>
      </div>
  });

 return (<div className={classes.root}>
         <CssBaseline />
         <Stack>
           {_buildPage()}
         </Stack>
         {<AppBarModule pageIndex={8} customRightModule={<></>} centerModule={<></>} cartClick={true} showCart={true} style={{ width: deviceSize.w,paddingRight:20}}/>}
         <Dialog
      maxWidth="lg"
      onClose={()=>{setShowModal(false)}}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
        {preview_body}
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={notificationMsg.timeout}
        onClose={()=>{
          showNotification(false)
        }}
        message={notificationMsg.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{  showNotification(false)}}>
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
    
    )

   
}
export default BookingPage;