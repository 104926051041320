export class Profile {
    constructor(data) {
        this.fname = data?.fname;
        this.lname = data?.lname;
        this.email = data?.email ?? '';
        this.bank = data?.bank;
        this.picture = data?.picture;
        this.uid = data?.uid ?? '';
    }
}

export const ProfileTranslator = {
    fromJson: (data) => {
        return new Profile(data);
    }
};