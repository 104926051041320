
import { IconButton, Typography, } from '@material-ui/core';
import { motion } from 'framer-motion';
import React from 'react';
import Row from './Row';

import PropTypes from 'prop-types'

const  ExtendedButton = (props)=>{

  const [showMenu, setOpenMenu] = React.useState(false);
const isBooked = props?.isBooked === undefined ? false : props.isBooked
const isOpen = props?.isOpen === undefined ? true : props.isOpen
  return (
    <IconButton  {...props}>

    
      <motion.div
      style={{cursor:"pointer"}}
      whileHover={{scale:1.2}}
      onMouseEnter = {()=>{
        setOpenMenu(true)
      }}
      onMouseLeave = {()=>{
        setOpenMenu(false)
      }}
        transition={{type: "spring", ease: "easeIn", duration: 0.8 }}
        onClick={()=>{
          if(props.available && isOpen)
            props.onClick(isBooked,props.value)
        }}
    >
    <Row  style={{borderRadius:"200em",padding:"5px 12px",backgroundColor:"rgb(30, 30, 30)",color:"white"}}>
    {showMenu ? (props.leftIcon??<></>):<></>}
              <Typography align="center" style={{fontWeight:"bold",fontSize:"12px"}}>{`${props.value}`}</Typography>
              {showMenu ? (props.rightIcon??<></>):<></>}
      </Row>
       
    </motion.div>
    </IconButton>
    )

}
export default ExtendedButton


ExtendedButton.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  isBooked: PropTypes.bool,
  isOpen: PropTypes.bool,
  leftIcon: PropTypes.element,
  rightIcon: PropTypes.element,
  available: PropTypes.bool
}