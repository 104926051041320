/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState,forwardRef,useImperativeHandle } from "react";
import { useDatabase } from "../hooks/useDatabase";
import Column from "./Column";
import { Button, Card, CardActions, CircularProgress, Grid, Typography, useTheme } from "@material-ui/core";
import { CardContent } from "semantic-ui-react";

const ServicePackageAgreements = forwardRef(function SPA(props,ref){
    const [agreements, setAgreements] = useState([]);
    const {getAllCustomerAgreements} = useDatabase();
    const [loading, setLoading] = useState(true);
    const [servicePackage, setServicePackage] = useState(null);
    const [currentPolicy, setCurrentPolicy] = useState(null);
    const theme = useTheme();
    useImperativeHandle(ref, () => ({
        getSelectedAgreement: () => {
            return currentPolicy;
        }
    }));
    useEffect(()=>{
        getAllCustomerAgreements().then((agreements)=>{
            console.log(Object.values(agreements))
            setAgreements(Object.values(agreements));
            setLoading(false);
        })
    },[]);

    return<>
        {
            loading ? <Column>
                <CircularProgress/>
            </Column> :  <Grid container spacing={2}>
    {
        agreements.map((agreement, index)=>{
            return <Grid key={index} item xs={12} sm={6} md={4}>
                <Card style={{padding:10, border: currentPolicy?.id === agreement.id ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.divider}`,}} variant="outlined">
                    <CardContent>
                        <Column>
                            <Typography variant="h6">{agreement.name}</Typography>
                            <Typography variant="caption">{`${agreement.paymentType === 'percentage' ? `Deposit is ${agreement.paymentAmount}% of Total price` : `Deposit is €${agreement.paymentAmount}`}`}</Typography>
                        </Column>
                    </CardContent>
                    <CardActions>
                        <Button variant="outlined" onClick={()=>{
                            if(currentPolicy?.id === agreement.id){
                                setCurrentPolicy(null);
                                setServicePackage({
                                    ...servicePackage,
                                    agreementsAttached: []
                                })
                                return;
                            }
                            setCurrentPolicy(agreement);
                            setServicePackage({
                                ...servicePackage,
                                agreementsAttached: [agreement.id]
                            })
                        }}>
                            {
                                currentPolicy?.id === agreement.id ? 'Detach' : 'Attach'
                            }
                        </Button>
                    </CardActions>
                </Card>
            </Grid>
        })
    }
</Grid>
        }
    </>
});

export default ServicePackageAgreements;