import { CircularProgress, FormControl, IconButton, InputLabel, OutlinedInput } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { alpha, makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router';
import backgroundVideo from '../../assets/backgroundVideo_short.mp4';
import logo from '../../assets/logo.png';
import Column from '../../components/Column';
import Row from '../../components/Row';
import Stack from '../../components/Stack';
import { UploadProfile } from '../../components/_upload_mod';
import firebase_app from '../../config/firebase';
import { SiteDataContext } from '../../config/SiteData';
import { useStorage } from '../../contexts/storage_handler';
import { useMediaQuery } from '../../contexts/MediaQueryProvider';
import { useStripePaymentContext } from '../../contexts/StripeProvider';
function Copyright() {
  const {
    SiteData
    
  } = useContext(SiteDataContext)
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        {SiteData.name}
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
  },
  image: {
    backgroundImage: 'url(https://source.unsplash.com/random)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  background: {
    width: "100%",
    height: "100%"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function RegisterPage() {
  const theme = useTheme();
  const [errorEmail, setErrorEmail] = useState(false);
  const [error, setErrorMsg] = useState('');
  const [validEmailText, setValidEmailText] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [validPasswordText, setValidPasswordText] = useState(false);
  const [currentImage, ] = useState({});
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [fname, setfname] = useState('');
  const [lname, setlname] = useState('');
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [passwordToggle, togglePassword] = useState(false);
  const {device} = useMediaQuery();
  const classes = useStyles();
  const history = useNavigate();

  const { 
    uploadMediaOperation } = useStorage();
  const {
    createCustomer
  } = useStripePaymentContext();

  const _buildVersion1 = () => {
    return <Stack style={{ width: '100%', height: "100%" }}>
      <div style={{ width: "100%", height: "100%", filter: "blur(30px)" }}>
        <video playsInline src={backgroundVideo} width="100%" height="100%" loop={true} style={{ objectFit: "cover" }} autoPlay={true} muted={true}></video>
      </div>
      <div className="centered" style={{ filter: "blur(17px)", width: "100%", height: "70%" }}>
        <video playsInline src={backgroundVideo} width="100%" height="100%" loop={true} style={{ objectFit: "cover" }} autoPlay={true} muted={true}></video>
      </div>
      <div className="centered" style={{ width: device !== 'pc' ? "100%" : "50%", background: "#ffffff17", borderRadius: "10px", height: "80%" }}></div>
      {device !== 'pc' ? <div style={{ width: "100%", height: "100%", background: alpha(theme.palette.background.paper, 0.67) }}></div> : <></>}
      <div className="centered" style={{ padding: "2", width: device !== 'pc' ? "100%" : "", maxWidth: 400, overflow: 'hidden'  }}>
        {isSigningUp ? _buildRegistering() : _buildLogin()}
      </div>

    </Stack>
  }

  const handleSubmit = async () => {
    let imageLink = { Location: "" };
    if (!errorEmail && !errorPassword) {
      setIsSigningUp(true);
      //save profile image to aws
      try {
        if (currentImage.file) {
          console.log("uploading image...");
          imageLink = await uploadMediaOperation(currentImage.file);

        }

      } catch (e) {
        console.log(e);
      }
      //authenticate with firebase
      try {
        console.log(imageLink);
        let user = await firebase_app.auth().createUserWithEmailAndPassword(email, password);
        let stripeCustomer = await createCustomer({
          email:email,
          name:`${fname} ${lname}`,
        })
        const profile = 
        { email: email, 
          fname: fname, 
          lname: lname,
           wallet: 0, 
           uid: user.user.uid,
           accounts: {default:[ user.user.uid]},
           currentAccount: "default_"+user.user.uid,
           role:"customer", 
           picture: imageLink,
           stripeCustomerId: stripeCustomer.id
        }
        await firebase_app.database().ref("Users").child(user.user.uid).set(profile);
        history("/", { replace: true });
      } catch (e) {

        setErrorMsg(e.message);
        setIsSigningUp(false);
      }

    }

  }
  const verifyEmail = () => {
    var emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    if (emailReg.exec(email)) {
      setErrorEmail(false);
      setValidEmailText("");
    }
    else {
      setErrorEmail(true);
      setValidEmailText("Invalid Email: Example@mail.com");

    }
  }
  const verifyPassword = () => {
    var passReg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/g
    if (passReg.exec(password)) {
      setErrorPassword(false);
      setValidPasswordText("Strong password");
    }
    else {
      setErrorPassword(true);
      setValidPasswordText("Weak password");
    }
  }
  const handleImageAdded = (file) => {
    currentImage.file = file;
    var fileReader = new FileReader();
    fileReader.onload = () => {
      currentImage.blob = fileReader.result;
    }
    fileReader.readAsDataURL(file)
  }
  const _buildLogin = () => {
    return <div className={classes.paper}>
      <UploadProfile alt={logo} onUpload={handleImageAdded} style={{ margin: "20px 0", height: "80px", width: "80px" }} />
      <Typography component="h1" style={{ margin: "0 0 20px 0", color: theme.palette.text.secondary }} variant="caption">click to upload picture</Typography>
      {device !== 'pc' ? <></> : <Typography component="h1" variant="h5">Sign Up</Typography>}

      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email"
          error={errorEmail}
          helperText={errorEmail ? validEmailText : ""}
          label="Email Address"
          name="email"
          autoComplete="email"
          autoFocus
          onKeyUp={(evt) => {
            let val = evt.target.value;
            setEmail(val);
            verifyEmail();
          }}
          onChange={
            (evt) => {
              let val = evt.target.value;
              setEmail(val);
              verifyEmail();
            }
          }
        />
        <FormControl fullWidth className={`${classes.margin} ${classes.textField}`} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>

          <OutlinedInput
            variant="outlined"
            margin="normal"
            required
            name="password"
            label="Password"
            endAdornment={<IconButton onClick={() => { togglePassword(!passwordToggle) }}>
              {passwordToggle ? <Visibility /> : <VisibilityOff />}
            </IconButton>}
            error={errorPassword}
            helperText={errorPassword ? validPasswordText : "Password Must Contain Uppercase, Lowercase and a number"}
            type={passwordToggle ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            onKeyUp={(evt) => {
              let val = evt.target.value;
              setPassword(val);
              verifyPassword();
            }}
          />
        </FormControl>
        <Row>
          <TextField
            variant="outlined"
            margin="normal"

            fullWidth
            name="password"
            label="First name"
            type="text"
            autoComplete="given-name"
            onKeyUp={(evt) => {
              let val = evt.target.value;
              setfname(val);
            }}
            onChange={(evt) => {
              let val = evt.target.value;
              setfname(val);
            }}
          />
          <div style={{width: 20}}/>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Last name"
            type="text"
            autoComplete="family-name"
            onKeyUp={(evt) => {
              let val = evt.target.value;
              setlname(val);

            }}
            onChange={(evt) => {
              let val = evt.target.value;
              setlname(val);

            }}
          />
        </Row>
        <Typography variant="caption" style={{ color: "red" }}>{error}</Typography>

        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={handleSubmit}
          disabled={errorEmail || errorPassword}
        >
          Sign Up
        </Button>
        <Grid container>

          <Grid item xs={12}>
            <div style={{ height: "10px" }}></div>
            <Link href="/login" variant="body2">
              {"Already a Member? Login"}
            </Link>
          </Grid>
        </Grid>
        <Box mt={5}>
          <Copyright />
        </Box>
      </form>
    </div>
  }
  const _buildRegistering = () => {
    return <Row alignment="centered" style={{ width: "100%" }}>
      <Column alignment="centered">
        <Row alignment="centered">
          <Avatar style={{ width: 70, height: 70, margin: 10 }} src={currentImage?.blob} />
        </Row>

        <Typography align="center" variant={"overline"}>{email}</Typography>
        <Typography align="center" variant={"overline"}>{`${fname} ${lname}`}</Typography>
        <div style={{ height: "20px" }}></div>
        <Column>
          <Row alignment="centered" style={{ margingTop: "20px", width: "100%" }}>
            <CircularProgress />
          </Row>
          <div style={{ height: "20px" }}></div>
          <Typography align="center" variant={"caption"}>{"Signing you up..."}</Typography>
        </Column>



      </Column>
    </Row>
  }

  const _buildVersion2 = () => {
    return <Row className={classes.root} style={{ width: "100%", height: "100%" }}>

      <div style={{ width: "60%", height: "100%" }}>
        <video playsInline src={backgroundVideo} width="100%" height="100%" loop={true} style={{ objectFit: "cover" }} autoPlay={true} muted={true}></video>
      </div>

      {isSigningUp ? _buildRegistering() : <Row style={{ width: "40%", padding: "70px" }} alignment="centered" className={classes.paper}>
        {_buildLogin()}
      </Row>}
    </Row>

  }
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <CssBaseline />
      {device === 'pc' ? _buildVersion2() : _buildVersion1()}

    </div>
  );
}