/* eslint-disable react-hooks/exhaustive-deps */

import  {Typography } from "@material-ui/core";
import React,{ useEffect, useState } from "react";
import  ProfileAvatar  from "./profile/main/_profile_avatar";
import Row from "../components/Row";
import { motion } from 'framer-motion';
import Column from "./Column";
import PropTypes from 'prop-types'


const MessageTile = ({message, myId, hideProfileImage,isAgent,isAdmin})=>{
    const [showAboutMessage, setShowAboutMessage] = useState(false);
    useEffect(()=>{
        console.log(message,myId);
    },[])
    const isMe = message.senderId === myId;
    const slideInLeft = {
        hidden: { opacity: 0, x: 10},
        visible: { opacity: 1, x: 0 },
    }
    const handleOnMouseEnter = ()=>{
        setShowAboutMessage(true);
    }
    const handleOnMouseLeave = ()=>{
        setShowAboutMessage(false);
    }
    return <motion.div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} style={{overflow: 'visible'}} transition={ { duration: 0.2, ease: "easeInOut"}} variants={slideInLeft} initial="hidden" animate="visible">
        <Column>
        <Row alignment={
            isMe ? "right" : "left"
        } style={{width: '100%', alignItems:'center'}}>
        {hideProfileImage ? <></>:<><ProfileAvatar admin={isAdmin} agent={isAgent} showTooltip={true} avatarStyle={{height:30, width:30}} disable uid={message.senderId}/>
        <div style={{width:'1em'}}/></>}
        <Row style={{
        padding: '0.5em 1em',
        margin: '0.5em 0',
        backgroundColor: !isMe ? '#f4a261' : '#757D87',
        borderRadius: '1em',
        color: isMe ? '#ffffff' : '#ffffff',
        width: 'fit-content',
        maxWidth: '80%',
    }}>
        <Typography variant="caption">{message.content}</Typography>
    </Row>
        </Row>
        {(!isMe && showAboutMessage) && <motion.div animate={{opacity: 1}} initial={{opacity: 0}} transition={{duration: 0.2}}>
            <Row alignment={
        isMe ? "right" : "left"
    }style={{fontSize: 10,opacity: 0.6,alignItems:'center',width:"100%"}}>
            <span>{`sent  by `}</span>
            <div style={{width: 5}}/>
            <span>{isMe? 'me' : <ProfileAvatar admin={isAdmin} agent={isAgent} disable uid={message.senderId} onlyName textProps={{variant:"caption"}}/>}</span>
            <div style={{width: 5}}/>
            <span>{`on ${new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(message.timestamp)}`}</span>
            </Row>
        </motion.div>}
        </Column>
        </motion.div>
}
export default MessageTile;


MessageTile.propTypes = {
    message: PropTypes.object,
    myId: PropTypes.string,
    hideProfileImage: PropTypes.bool,
    isAgent: PropTypes.bool,
    isAdmin: PropTypes.bool
}