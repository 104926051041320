import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { createContext, useContext } from "react";
import PropTypes from "prop-types";


export const useCustomDialog = ()=>useContext(CustomDialogContext);


const CustomDialogContext = createContext({
    showDialog: () => {},
    closeDialog: () => {},
});
CustomDialogContext.propTypes = {
    showDialog: PropTypes.func,
    // PropTypes.func(
    //     PropTypes.shape({
    //         title: PropTypes.string,
    //         body: PropTypes.node,
    //         width: PropTypes.string,
    //         onAccept: PropTypes.func,
    //         onDecline: PropTypes.func,
    //         yesText: PropTypes.string,
    //         noText: PropTypes.string,
    //     })
    // ),
    closeDialog: PropTypes.func,
}
export const CustomDialogProvider = ({ children }) => {
    const [open, setOpen] = React.useState(false);
    const [dialog, setDialog] = React.useState({
        open: false,
        title: "",
        body: <></>,
        width: "md",
        yesText: "Yes",
        noText: "No",
        onAccept: () => {},
        onDecline: () => {},
    });
    
    const showDialog = ({title, body,width, onAccept, onDecline,yesText, noText}) => {
        setDialog({
            title,
            body,
            width: width ?? "md",
            yesText,
            noText,
            onAccept,
            onDecline,
        });
        setOpen(true);
    }
    const closeDialog = () => {
        setOpen(false);
    }
    return (
        <CustomDialogContext.Provider value={{ showDialog, closeDialog }}>
        {children}
        <Dialog fullWidth width={dialog.width} open={open} onClose={()=>{
            setOpen(false);
        }}>
            <DialogTitle>{dialog.title}</DialogTitle>
            <DialogContent>
                {dialog.body}
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{
                    if(dialog.onDecline)
                    dialog.onDecline();
                setOpen(false);
            }}>{dialog.noText??"No"}</Button>
            <Button variant="outlined" color={'primary'} onClick={dialog.onAccept}>{dialog.yesText??"Yes"}</Button>
            </DialogActions>
        </Dialog>
        </CustomDialogContext.Provider>
    );
};
CustomDialogProvider.propTypes = {
    children: PropTypes.node,
}