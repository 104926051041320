import {firestore} from "../config/firebase"
export const NotificationTypes = {
    "VIDEO_SUBMISSION": "VIDEO_SUBMISSION",
    "STORY_SUBMISSION": "STORY_SUBMISSION",
    "APPLICATION": "APPLICATION",
    SERVICE : "SERVICE",
    SERVICE_TASK_UPDATE : "SERVICE_TASK_UPDATE",
    SERVICE_CHAT_MESSAGE : "SERVICE_CHAT_MESSAGE",
    AGENT_SERVICE : "AGENT_SERVICE",
    SERVICE_REVIEW: "SERVICE_REVIEW",
    SERVICE_PAYMENT: "SERVICE_PAYMENT",
}
export const sendNotification = async ({
    id,
    sendTo,
    sentFrom,
    type,
    title,
    message,
    timestamp,
    seen,
    url
})=>{
    const data = {
        id,
        sendTo,
        sentFrom,
        type,
        title,
        message,
        timestamp,
        seen,
        url
    }
    try{
        await firestore.collection("Notifications").doc(sendTo).collection(type).doc(id).set(data);
        return true;
    }catch(err){
        console.log(err);
    }
}