/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useMemo,useContext } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { Badge, Button, Checkbox, Chip, CircularProgress, Dialog, Divider, FormControlLabel, Grid, GridList, IconButton, Input, InputAdornment, InputBase, LinearProgress, Switch, Tooltip, Typography } from '@material-ui/core';
import { _buildConfirmation, loadFromDevice } from '../utils.js';
import { UploadDiv } from './UploadDiv';
import Column from './Column';
import Row from './Row';
import { Title as TitleIcon, Image as ImageIcon, ViewHeadline as ParagraphIcon, InsertLink, Tv, DragIndicator } from '@material-ui/icons';

import { uuid } from "short-uuid";
import { motion } from 'framer-motion';
import { ImageModule, LinkModule, ParagraphModule, TitleModule, VideoModule } from './_new_story_comp';
import { SiteDataContext } from '../config/SiteData';
import { useStorage } from '../contexts/storage_handler.js';
import { useSnackbar } from 'notistack';
import { StoryContext } from '../contexts/StoryProvider.js';
import { useDatabase } from '../hooks/useDatabase.js';
import { ReactSortable } from 'react-sortablejs';
import TagBar from './_tag_list.jsx';
import { ProfileAvatar } from './profile/main/_profile_avatar.jsx';
import { useNavigate } from 'react-router';
import { useProfile } from '../hooks/useProfile.js';
import { useMediaQuery } from '../contexts/MediaQueryProvider.js';
import PropTypes from 'prop-types';

const StoryEditor = ({packet:PACKET, story, editExistingStory,hideToolbar, createNewStory, onSave = () => { }, draft, viewOnly: ViewOnly, fullView, customPublishText, orgId }) => {
    const [newStory, updateStory] = useState(story);
    const theme = useTheme();
    const history = useNavigate();
    const [imageLimit, setImageLimit] = useState(5)
    const [videoLimit, setVideoLimit] = useState(1);
    const [packet, setPacket] = useState(PACKET);
    const [modalbody, updateModaBody] = useState([])
    const [showModal, setShowModal] = useState(false);
    const [author, ] = useState(null);
    const [profile, setProfile] = useState(null);
    const { isLargeDevice } = useMediaQuery();
    const {
        SiteData,
    } = useContext(SiteDataContext)
    const { retrieveProfileWithCallback } = useProfile();
    const { enqueueSnackbar } = useSnackbar();
    const [autoSave, setAutoSave] = useState(localStorage.getItem("dtv-story-autoSave") ?? false);
    const [autoSaveInterval] = useState(30000);
    const [isSaving, setIsSaving] = useState(false);
    const { error: DBError, saveStoryDraft } = useDatabase();
    const [viewOnly, setViewOnly] = useState(ViewOnly ?? false);
    const { uploading,
        progress,
        progressMsg,
        uploadMediaOperation } = useStorage();
    const { deleteStory, } = useContext(StoryContext);
    const storyMemo = useMemo(() => ({ id: `${story?.id}` }), [
        story?.id,
    ])

    useEffect(() => {
        console.log("AutoSave",autoSave);
        if(autoSave && !viewOnly)
         triggerAutoSave();
        else{
            clearInterval(autoSaveController);
        }
    },[autoSave]);

    useEffect(() => {
        retrieveProfileWithCallback((profile) => {
            setProfile(profile);
        });
        if (editExistingStory && storyMemo.id) {
            console.log("VIEWING STORY", newStory)
            unpackStoryBundle(JSON.parse(newStory?.story ?? '{}'));
        }
        else if (((!createNewStory || fullView)) && storyMemo.id) {
            console.log("VIEWING STORY", newStory)
            unpackStoryBundle(JSON.parse(newStory?.story ?? '{}'));
        }
        return () => {
            clearInterval(autoSaveController);
        }
    }, [storyMemo.id, createNewStory]);

    useEffect(() => {
        const beforeUnloadHandler = (event) => {
            // Clear the interval when the user navigates away from the page
            window.clearInterval(autoSaveController);
            event.preventDefault();
        };

        window.addEventListener('beforeunload', beforeUnloadHandler);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, []);
    const updateArray = (array, data) => array.map(obj => {
        if (obj.id === data.id) {
            return { ...data };
        }
        return obj;
    });
    const _buildTitle = (title, disableAnim = false) => {
        const anim = disableAnim ? {} : { initial: { x: 40, opacity: 0 }, animate: { x: 0, opacity: 1 }, transition: { delay: 0.4, type: "tween", duration: 0.4 } }

        return <motion.div style={{ width: "100%", margin: "10px 0 40px 0" }}
            {...anim}><Row alignment="left" >
                <TitleModule style={{ fontSize: "3vw", fontFamily: "raleway", textTransform: "capitalize" }}
                    onDelete={() => {
                        const packetTemp = packet.filter(obj => obj.id !== title.id);
                        setPacket(packetTemp);
                        newStory.titles = newStory.titles.filter(p => p.id !== title.id);
                        updateStory({ ...newStory })
                    }}
                    updateTitle={(t) => {
                        if (newStory.titles?.filter(p => p.id === t.id).length === 0)
                            newStory.titles.push(t);
                        packet[t.id] = { id: t.id, type: t.type, data: t };
                        newStory.titles = updateArray(newStory.titles, t);
                    }}
                    mode={viewOnly ? "view" : "edit"} title={title} />
            </Row></motion.div>
    }
    const _buildLink = (link, disableAnim = false) => {
        const anim = disableAnim ? {} : { initial: { x: 40, opacity: 0 }, animate: { x: 0, opacity: 1 }, transition: { delay: 0.4, type: "tween", duration: 0.4 } }

        return <motion.div style={{ width: "100%", margin: "10px 0 0px 0" }} {...anim}><Row alignment="left" >
            <LinkModule
                onDelete={() => {
                    const packetTemp = packet.filter(obj => obj.id !== link.id);
                    setPacket(packetTemp);
                    newStory.links = newStory.links.filter(p => p.id !== link.id);
                    updateStory({ ...newStory })
                }}
                updateLink={(t) => {
                    if (newStory.links?.filter(p => p.id === t.id).length === 0)
                        newStory.links.push(t);
                    packet[t.id] = { id: t.id, type: t.type, data: t };
                    newStory.links = updateArray(newStory.links, t);
                }}
                style={{ fontSize: "1.6vw", fontFamily: "raleway", textTransform: "capitalize" }} mode={viewOnly ? "view" : "edit"} link={link} />
        </Row></motion.div>
    }
    const _buildParagraph = (paragraph, disableAnim = false) => {
        
        const anim = disableAnim ? {} : { initial: { x: 40, opacity: 0 }, animate: { x: 0, opacity: 1 }, transition: { delay: 0.4, type: "tween", duration: 0.4 } }
            ;
        return <motion.div style={{ width: "100%", margin: "10px 0 7px 0" }} {...anim}><Row alignment="left" >
            <ParagraphModule style={{ fontSize: "2vw", fontFamily: "raleway", color: fade(theme.palette.text.primary, 0.8) }}
                mode={viewOnly ? "view" : "edit"}
                paragraph={paragraph}
                onDelete={() => {
                    const packetTemp = packet.filter(obj => obj.id !== paragraph.id);
                    setPacket(packetTemp);
                    newStory.paragraphs = newStory.paragraphs.filter(p => p.id !== paragraph.id);
                    updateStory({ ...newStory })
                }}
                updateParagraph={(t) => {
                    if (newStory.paragraphs?.filter(p => p.id === t.id).length === 0)
                        newStory.paragraphs.push(t);
                    packet[t.id] = { id: t.id, type: t.type, data: t };
                    newStory.paragraphs = updateArray(newStory.paragraphs, t);
                 
                }}

            />
        </Row></motion.div>
    }
    const _buildImage = (image, disableAnim = false) => {
        
        const anim = disableAnim ? {} : { initial: { x: 40, opacity: 0 }, animate: { x: 0, opacity: 1 }, transition: { delay: 0.4, type: "tween", duration: 0.4 } }
        return image.src === "" ? <></> :
            <motion.div style={{ margin: "10px 0 30px 0" }} {...anim}>
                <ImageModule
                    onDelete={() => {
                        setImageLimit(imageLimit + 1);

                        const packetTemp = packet.filter(obj => obj.id !== image.id);
                        setPacket([...packetTemp]);
                        newStory.images = newStory.images.filter(obj => obj.id !== image.id);
                        updateStory({ ...newStory })
                    }}
                    mode={viewOnly ? "view" : "edit"}
                    updateImage={(img) => {

                        packet[img.id] = { id: img.id, type: img.type, data: img, src: img.file };
                    }} image={image} />
            </motion.div>
    }
    const _buildVideo = (video, disableAnim) => {
       
        const anim = disableAnim ? {} : { initial: { x: 40, opacity: 0 }, animate: { x: 0, opacity: 1 }, transition: { delay: 0.4, type: "tween", duration: 0.4 } }

        return video.file === "" ? <></> : <motion.div style={{ height: "350px", margin: "10px 0 30px 0", }} {...anim}>
            <VideoModule mode={viewOnly ? "view" : "edit"}
                onDelete={() => {
                    setImageLimit(videoLimit + 1);

                    const packetTemp = packet.filter(obj => obj.id !== video.id);
                    setPacket([...packetTemp]);
                    newStory.videos = newStory.videos.filter(obj => obj.id !== video.id);
                    updateStory({ ...newStory })
                }}

                updateVideo={(vid) => {
                    packet[vid.id] = { id: vid.id, type: vid.type, data: vid, src: vid.file };

                }} video={video} />
        </motion.div>
    }

    const unpackStoryBundle = (bundle => {
        let data = bundle;
        console.log(data);
        let packet = [];
        if (data.images)
            data.images.forEach(image => {
                if (image.id !== null)
                    packet[image.id] = image;
            });
        if (data.videos)
            data.videos.forEach(video => {
                if (video.id !== null)
                    packet[video.id] = video;
            });
        if (data.paragraphs)
            data.paragraphs.forEach(p => {
                if (p.id !== null && p.data?.ref) {
                    packet[p.id] = p
                }
            });
        if (data.titles)
            data.titles.forEach(t => {
                if (t.id !== null && t.data?.ref)
                    packet[t.id] = t;
            });
        if (data.links)
            data.links.forEach(l => {
                if (l.id !== null)
                    packet[l.id] = l;
            });
        newStory.images = data.images;
        newStory.videos = data.videos;
        newStory.paragraphs = data.paragraphs;
        newStory.titles = data.titles;
        newStory.links = data.links;
        updateStory({ ...newStory });
        const filteredEmpty = packet.filter(p => p !== undefined);
        console.log(filteredEmpty);
        setPacket(filteredEmpty)
        setImageLimit(Math.abs(SiteData.storyPage.imageLimit - (data.images?.length ?? 0)));
        setVideoLimit(Math.abs(SiteData.storyPage.videoLimit - (data.videos?.length ?? 0)));
    })

    const SortableComponent = ({ packet, disableAnim }) => {
        const data = packet;
        switch (packet.type) {
            case 'image':
                return _buildImage(data, disableAnim)
            case 'video':
                return _buildVideo(data, disableAnim)
            case 'paragraph':
                return _buildParagraph(data, disableAnim)
            case 'title':
                return _buildTitle(data, disableAnim);
            case 'link':
                return _buildLink(data, disableAnim)
            default:
                return null;
        }
    };
    
    SortableComponent.propTypes = {
        packet: PropTypes.object,
        disableAnim: PropTypes.bool
    }
    const saveDraft = async () => {
        setIsSaving(true);
        try {
            await saveStoryDraft(await encodeStoryObject({ isDraft: true}));
            updateStory({ ...newStory, version: newStory.version + 1});
            
            setIsSaving(false);
            if (!DBError) {
                enqueueSnackbar("Draft Saved", { variant: "success" }, { autoHideDuration: 1000, maxSnack: 1 });
            } else {
                enqueueSnackbar("Error Saving Draft: " + DBError, { variant: "error" }, { autoHideDuration: 1000, maxSnack: 1 });
            }
        } catch (e) {
            console.log(e);
            enqueueSnackbar("Error Saving Draft: " + DBError, { variant: "error" }, { autoHideDuration: 1000, maxSnack: 1 });

        }
        setIsSaving(false);
    }
    let autoSaveController;
    const triggerAutoSave = () => {
        autoSaveController = setInterval(() => {
            saveDraft();

        }, autoSaveInterval);
    }
    const publishStory = async () => {
        saveDraft();
        onSave(await encodeStoryObject({ isDraft: false }));

    }

    const encodeStoryObject = async ({ isDraft = true }) => {

        setPacket([...packet]);
        console.log(newStory);
        const images = packet.filter(e => e.type === "image");
        const videos = packet.filter(e => e.type === "video");
        if (newStory.headline && (newStory.paragraphs)) {
            if (newStory.photo) {
                if (!(typeof (newStory.photo?.file ?? newStory.photo) === "string")) {
                    newStory.photo = await uploadMediaOperation(newStory.photo);
                } else {
                    newStory.photo = newStory.photo?.file ?? newStory.photo;
                }
            }
            if (images) {
                console.log("----Uploading media ...");
                await uploadMedia(images.length, "image");
            }
            if (videos) {
                await uploadMedia(videos.length, "video");
            }
            var data = {
                userId: newStory.userId ?? profile.uid,
                storyId: newStory.storyId ?? uuid(),
                headline: newStory.headline ?? null,
                photo: newStory.photo ?? null,
                photoCaption: newStory.photoCaption ?? null,
                publishedAt: newStory.publishedAt ?? Date.now().valueOf(),
                lastUpdated: Date.now().valueOf(),
                approved: newStory?.approved === 1 ? true : false,
                approvedBy: newStory?.approvedBy ?? '',
                tags:(newStory.tags?.split(",").filter(e=>e!==""))?.join(",")??"",
                isDraft: isDraft,
                orgId, 
                story: JSON.stringify({
                    paragraphs: (packet?.filter(e => (e.data?.ref !== "" && e.type === "paragraph"))),
                    images: (packet?.filter(e => (e.src !== "" && e.type === "image"))),
                    videos: (packet?.filter(e => (e.src !== "" && e.type === "video"))),
                    titles: (packet?.filter(e => (e.data?.ref !== "" && e.type === "title"))),
                    links: (packet?.filter(e => (e.data?.ref !== "" && e.type === "link"))),
                    headline: newStory.headline,
                }),
                isDTV: newStory?.isDTV === 1 ? true : false,
                author: newStory?.author ?? `${profile.fname} ${profile.lname}`
            }
            console.log("+++++>>", packet, data)
            return data;
            //upload
        } else {
            enqueueSnackbar("HEADLINE and at least 1 PARAGRAPH is required", { variant: "error", autoHideDuration: 5000 });
            throw new Error("HEADLINE and at least 1 PARAGRAPH is required");
        }

    }
    const uploadMedia = async (n,type) => {
        let packetImages = packet.filter(e=>e.type===type);
        if (n === 0) return;
        if(!(typeof (packetImages[n-1]?.data?.src??packetImages[n-1]?.data?.blob) === "string")){
            packetImages[n - 1].data.blob = packetImages[n - 1].data.file = await uploadMediaOperation(packetImages[n - 1].data);
           packetImages[n - 1].data.src =packetImages[n - 1].file;
        }else{
           packetImages[n - 1].data.src =packetImages[n - 1]?.data.src;
        }
        
        const packetFinal = packet.map(e=>e.id === packetImages[n - 1].id ? packetImages[n - 1] : e)
        setPacket(packetFinal);
        return uploadMedia(n - 1,type);

    }
    const handleNewEntry = (field, data) => {
        //add new image div to the elementdiv with addToList
        //create a refrence in the newStory Object that listens for changes 
        packet[packet.length] = { id: packet.length, type: field, data: { ...data, id: packet.length, type: field } }
        if (newStory[`${field}s`]) {
            newStory[`${field}s`].push(packet[packet.length - 1])
        } else {
            newStory[`${field}s`] = [packet[packet.length - 1]]
        }

        setPacket([...packet]);
    }
    const handleSort = () => {
        const sortedPacket = packet.map((e, i) => {
            e.data.id = i;
            e.id = i;
            return e;
        })
            // packet[sortedIds.newIndex].data.id = sortedIds.newIndex;
            // packet[sortedIds.newIndex].id = sortedIds.newIndex;
            // packet[sortedIds.oldIndex].data.id = sortedIds.oldIndex;
            // packet[sortedIds.oldIndex].id = sortedIds.oldIndex;

            ;
        setPacket(sortedPacket);

    };
    const _buildHeadLine = () => {
        return <div style={{ padding: "20px" }}>
            <InputBase
                fullWidth
                readOnly={viewOnly}
                maxRows={60}
                multiline
                defaultValue={newStory.headline ?? ""}
                placeholder="Headline"
                value={newStory.headline ?? ""}
                onChange={(evt) => {
                    newStory.headline = evt.target.value;
                    updateStory({ ...newStory })
                }}
                inputProps={{
                    style: {
                        fontSize: "46px",
                        lineHeight: 1.5,
                        fontWeight: "bold",
                        textTransform: "capitalize",
                        borderColor: "transparent"
                    }
                }}

            />
        </div>
    }
    const _buildHeadlinePhoto = () => {
        return <><Grid>
            <div style={{ width: '100%', height: fullView ? "400px" : "300px", margin: "10px", padding: "12px", }}>
                <UploadDiv
                    borderRadius={5}
                    viewOnly={viewOnly}
                    placeholder={''} onUpload={(file) => {
                        loadFromDevice(file, (blob) => {
                            newStory.photo = {
                                file: blob,
                                blob: blob
                            }
                                ;
                            updateStory({ ...newStory });
                        })
                    }} image={newStory.photo?.blob ?? newStory.photo}><Column alignment="centered">
                        <Typography variant="overline" align="center">Upload Featured Cover</Typography>
                        <Typography variant="caption" style={{ color: fade(theme.palette.text.primary, 0.3) }} align="center">click here to upload</Typography>
                    </Column>

                </UploadDiv>
            </div>
        </Grid>
            <Grid xs={12} style={{ padding: 10 }}>
                {viewOnly ? <></> : <Typography align={"center"}> -- or --</Typography>}
                {viewOnly ? <></> : <Input
                    fullWidth
                    onChange={(evt) => {
                        newStory.photo = {
                            file: evt.target.value !== '' ? evt.target.value : undefined,
                            blob: evt.target.value !== '' ? evt.target.value : undefined
                        }
                        updateStory({ ...newStory });
                    }}
                    onKeyUp={(evt) => {
                        newStory.photo = {
                            file: evt.target.value !== '' ? evt.target.value : undefined,
                            blob: evt.target.value !== '' ? evt.target.value : undefined
                        }
                        updateStory({ ...newStory });
                    }}
                    defaultValue={newStory.photo ?? ""}
                    label="Paste video/Image Link"
                    startAdornment={<InputAdornment position="start">Paste Image Link: </InputAdornment>}
                />}
                {viewOnly ? <Typography variant='caption'>
                    {newStory.photoCaption}
                </Typography> : <Input
                    fullWidth
                    onChange={(evt) => {
                        newStory.photoCaption = evt.target.value;
                        updateStory({ ...newStory });
                    }}
                    value={newStory.photoCaption ?? ""}
                    style={{ marginTop: "10px" }}
                    defaultValue={newStory.photoCaption ?? ""}
                    placeholder="Caption"
                />}
            </Grid></>

    }
    console.log(packet)
    const buildMenuBar = () => {
        return <>
        <Row alignment={isLargeDevice?"left":"center"}>
             {
                isSaving || viewOnly ? <></> : <Tooltip title={"Every " + (autoSaveInterval / 1000) + "s"}>
                    <FormControlLabel control={<Switch checked={autoSave} onChange={
                        () => {
                            setAutoSave(!autoSave);
                            localStorage.setItem("dtv-story-autoSave", !autoSave);

                        }
                    } />} label={"Autosave"} />
                </Tooltip>}

            {
                !draft ? <></> :
                    <FormControlLabel control={<Switch checked={viewOnly} onChange={
                        () => {
                            setViewOnly(!viewOnly);

                        }
                    } />} label={"View Mode"} />
            }
        </Row>
           
            {createNewStory || isSaving || viewOnly ? <></> : <Button style={{ marginRight: "10px", color: "#ff5151" }} onClick={() => {
                updateModaBody(_buildConfirmation({
                    title: "Delete Story", okText: "Yes, Delete Permanently",
                    onCancelClicked: () => { setShowModal(false) },
                    onOkClicked: () => {
                        deleteStory(story.storyId).then(() => {

                            history(-1)
                        })
                    }
                }));
                setShowModal(true);
            }}>DELETE</Button>}
            {viewOnly ? <></> : isSaving ? <Column style={{ margin: "0 10px" }}><CircularProgress size={"20px"} /> </Column> : <Button style={{ marginRight: "10px" }} onClick={() => {
                saveDraft();
            }}>SAVE DRAFT</Button>}

            {
                viewOnly ? <Button variant="contained" color="primary" onClick={() => {
                    history("/edit_story:" + (newStory?.storyId) + ("/draft"), { story: newStory, author: author, isDtv: true });
                }}>Begin Edit</Button> :
                    isSaving ? <></> :
                        uploading ? <div style={{ width: "50%" }}>
                            <Row alignment="space-between"><div style={{ width: "70%" }}>
                                <LinearProgress variant="determinate"
                                    value={progress}
                                /></div>
                                <small style={{ marginLeft: "8px", fontSize: "12px" }}>{progressMsg}</small>
                            </Row></div>
                            : <Button variant='contained' color={"primary"} onClick={() => {
                                updateModaBody(_buildConfirmation({
                                    title: `Are You Sure you want to ${customPublishText ?? 'Live Publish'} this Story`, okText: `Yes, ${customPublishText ?? 'Publish'}`, onCancelClicked: () => { setShowModal(false) },

                                    onOkClicked: () => {
                                        publishStory();
                                        setShowModal(false);
                                    }
                                }));
                                setShowModal(true)
                            }} disabled={createNewStory && (!newStory.headline || !newStory.paragraphs)}>{`${createNewStory ? customPublishText ?? 'Publish' : customPublishText ?? 'Publish Live'}`}</Button>}</>
    }
    return <><div style={{ height: "auto", width: "100%" }}>
        <GridList style={{ height: "100%", width: "100%", overflowY: 'hidden', overflow: 'hidden', paddingLeft: isLargeDevice ? 0 : 0 }}>

            <Grid container spacing={1} style={{ padding: "0px 0px", height: "100%", width: "100%" }}>
                { fullView ? <></> :
                    <>{hideToolbar?<></> : <Grid key="Subheader" xs={12} md={4} style={{ height: 'auto' }}>
                        <Row style={{ width: "100%", margin:10}}>
                            <Chip variant={newStory.isDTV ? "outlined" : "default"} color={newStory.isDTV ? "primary" : "default"} label={newStory.isDTV ? "DTV Exclusive" : "Public"} style={{ marginRight: "10px" }} />
                            <Chip variant={newStory.isDraft ? "outlined" : "default"} color={!draft ? "primary" : "default"} label={draft ? "Draft" : "Published"} style={{ marginRight: "10px" }} />,
                        </Row></Grid>}
                       {hideToolbar?<></> : <Grid xs={12} md={createNewStory ? 12 : 8}>
                            {
                                isLargeDevice ? <Row alignment="right" style={{ height: "100%", width: "100%" }}>
                                    {buildMenuBar()}
                                </Row> : <Column  style={{width:"100%", alignmItems:"center", margin:"20px 0"}}>
                                    {buildMenuBar()}
                                </Column>
                            }

                        </Grid>}

                        {createNewStory || !newStory.approvedBy ? <></> : <><Grid xs={12}>
                            <FormControlLabel control={<Checkbox checked={newStory.approved} />} label={
                                <Row>
                                    <Typography style={{ marginRight: '5px' }}>{`Approved By `}</Typography>
                                    <ProfileAvatar url={'/profile/:' + (newStory.isDTV ? 'profile-' : '') + newStory.userId} hoverType={'opacity'} profile={true} uid={newStory.approvedBy} onlyName={true} />
                                </Row>
                            } />
                        </Grid>
                           
                        <Grid xs={12}>
                            <Grid xs={12}>
                                <Typography variant='caption'>
                                {`Last Updated: ${Intl.DateTimeFormat("en-US",{month: 'long',day:'2-digit', year:'numeric'}).format(newStory.lastUpdated)}`}
                            </Typography>
                        </Grid>
                        {newStory.version && <Grid xs={12}>
                        <Typography variant='caption'>
                                {`Latest version: ${newStory.version ?? 0}`}
                            </Typography>
                            </Grid>}
                        </Grid>
                            </>}
                        {newStory?.userId ? <Grid xs={12} style={{ marginTop: 30, padding: 20 }}>
                            <Row style={{ alignmItems: 'center' }}>
                                <Typography style={{ marginRight: 10 }}> {`Written By `}</Typography>
                                <ProfileAvatar hoverType={'opacity'} url={'/authors/:' + (newStory.isDTV ? 'profile-' : '') + newStory.userId} textProps={{ style: { textDecoration: 'underline' } }} onlyName uid={newStory.userId ?? profile.uid} profile={newStory.isDTV} tile={true} />
                                <Typography style={{ marginLeft: 10 }}>{`| ${Intl.DateTimeFormat("en-US", { month: 'long', day: '2-digit', year: 'numeric' }).format(newStory.publishedAt)}`}</Typography>
                            </Row>
                        </Grid> : <></>}
                    </>}


                <Grid xs={12}>
                    {fullView ? <></> : _buildHeadLine()}

                </Grid>

                {fullView ? <></> : <Grid xs={12}>
                    <TagBar defaultTags={newStory.tags} viewOnly={viewOnly} onTagUpdated={(tags) => {
                        newStory.tags = tags;
                    }} />
                </Grid>}
                <Grid xs={12}>
                    {fullView ? <></> : _buildHeadlinePhoto()}
                </Grid>

                <Grid xs={12} >

                    {
                        (packet?.length ?? 0) <= 0 ? <></> :
                            <ReactSortable
                                style={{ listStyleType: "none", padding: !isLargeDevice ? 0 : 'default' }}
                                disabled={viewOnly}
                                tag={"ul"}
                                animation="200"
                                easing="ease-out"
                                handle={'.handle'}
                                onSort={handleSort}
                                list={packet}
                                setList={setPacket}>
                                {packet.map(p => (
                                    <li key={p.id} data-id={p.id}>
                                        {viewOnly ? <></> : <Row alignment="right" style={{ width: "100%", alignItems: 'center' }}>
                                            <Chip label={p.type} style={{ textTransform: 'capitalize' }} />
                                            <IconButton className="handle" style={{ opacity: 0.5 }}><DragIndicator /></IconButton>
                                        </Row>}
                                        <SortableComponent packet={p.data} disableAnim={true} />
                                    </li>
                                ))}
                            </ReactSortable>}
                </Grid>
                {(viewOnly) ? <></> : <Grid xs={12}>
                    <Divider />
                    <Row>
                        <motion.ui ><IconButton onClick={() => {
                            handleNewEntry("title", { ref: "", })
                        }}><TitleIcon /></IconButton></motion.ui>
                        <IconButton disabled={imageLimit < 1} onClick={() => {
                            handleNewEntry("image", { blob: "", file: null })
                        }}><Badge color={imageLimit < 1 ? "error" : "primary"} badgeContent={imageLimit}><ImageIcon /></Badge></IconButton>
                        <motion.ui><IconButton onClick={(() => {
                            handleNewEntry("paragraph", { ref: "", })
                        })}><ParagraphIcon /></IconButton></motion.ui>
                        <IconButton disabled={videoLimit < 1} onClick={() => {
                            handleNewEntry("video", { blob: "", file: null })
                        }}><Badge color={videoLimit < 1 ? "error" : "primary"} badgeContent={videoLimit}><Tv /></Badge></IconButton>
                        <motion.ui><IconButton onClick={() => {
                            handleNewEntry("link", { ref: "", link: "" })
                        }}><InsertLink /></IconButton></motion.ui>
                    </Row>
                </Grid>}
                {!fullView ? <></> : <TagBar defaultTags={newStory.tags} viewOnly={viewOnly} onTagUpdated={(tags) => {
                    newStory.tags = tags;
                }} />}
            </Grid>
        </GridList>
    </div>

        <Dialog
            onClose={() => { setShowModal(false) }}
            fullWidth
            aria-labelledby="confirmation-dialog-title"
            open={showModal}>
            {modalbody}
        </Dialog>
    </>

}
export default StoryEditor;


StoryEditor.propTypes = {
    story: PropTypes.object,
    orgId: PropTypes.string,
    editExistingStory: PropTypes.bool,
    hideToolbar: PropTypes.bool,
    createNewStory: PropTypes.bool,
    onSave: PropTypes.func,
    draft: PropTypes.bool,
    hideApprovedBy: PropTypes.bool,
    viewOnly: PropTypes.bool,
    fullView: PropTypes.bool,
    customPublishText: PropTypes.string,
    packet: PropTypes.array
}