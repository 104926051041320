import axios from 'axios';
import { RouteNames, baseUrl } from '../config/api';

export const PaymentService = () => {
    const paypalPayment = async ({paymentId, payerId}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.paypalPayment}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                paymentId,
                payerId
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data,
                status: 200 
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    };

    const createPaymentCustomer = async ({email, name}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.stripePaymentCustomer}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                email,
                name
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data,
                status: 200 
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getPaymentCustomer = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.stripePaymentCustomer}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data,
                status: 200 
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const createServiceAccount = async ({email}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.stripeServiceAccount}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                email
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data,
                status: 200 
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getServiceAccount = async ({id}) => {
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.stripeServiceAccount}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data,
                status: 200 
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const makePayout = async ({amount, linkedAccountId, currency}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.stripePayout}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                amount,
                linkedAccountId,
                currency
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data,
                status: 200 
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    };
    const makePayment = async ({amount,currency,tax,id,customer,email,shipping}) => {
       
        try { 
            
            const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.stripePayment}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                amount,
                currency,
                tax,
                id,
                customer,
                email,
                shipping
            }
        };
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data,
                status: 200 
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }   
    }
    const createInvoice = async ({customer,description,account}) => {
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.stripeInvoice}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                customer,
                description,
                account
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data,
                status: 200 
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }   
    }

    return {
        paypalPayment,
        createPaymentCustomer,
        getPaymentCustomer,
        createServiceAccount,
        getServiceAccount,
        makePayout,
        makePayment,
        createInvoice
    }
}