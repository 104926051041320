/* eslint-disable react-hooks/exhaustive-deps */

import { Avatar, Dialog, Divider, alpha, Grid, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography, useTheme, CircularProgress } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Pagination from '@material-ui/lab/Pagination';
import React, { useContext, useEffect, useState } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { useNavigate } from 'react-router';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import logo from '../assets/logo.png';
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { AppBarModule } from '../components/_app_bar_mod';
import { StoryCard } from '../components/_story_mod';
import { SiteDataContext } from '../config/SiteData';
import { StoryContext } from '../contexts/StoryProvider';
import "../index.css";
import { useProfile } from '../hooks/useProfile';
import { parseUrlForQueryParams } from '../utils';
import { useAdmin } from '../hooks/useAdmin';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    },
    '& label.Mui-focused': {
      color: '#e49726',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#e49726',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: '#e49726',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#b77514',
      },
      '&:hover fieldset': {
        borderColor: '#b77514',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#e49726',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.24)',
      color: '#e49726',
      borderColor: "#e49726"
    },
    //  '& .MuiPaginationItem-root':{
    //    border:"1px solid rgba(255, 255, 255, 0.23)",
    //    color:"white",
    //    '&:hover':{
    //     backgroundColor: 'rgba(255, 255, 255, 0.14)',
    //     border:"1px solid rgba(255, 255, 255, 0.53)",
    //     cursor:"pointer"
    //    }
    //  },
    '& .MuiCircularProgress-colorPrimary': {
      color: "white"
    }

  },
  iconBtn: {
    color: alpha(theme.palette.text.primary, 0.5),
    '&:hover': {
      color: alpha(theme.palette.text.primary, 1)
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer',
      color: "white"
    }
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  textField: {
    '&focus': {
      borderBottomColor: '#e49726'
    }
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  pagination: {
    color: "white"
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


const StoryAuthorPage = () => {
  const {
    SiteData,
  } = useContext(SiteDataContext)
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [device, setDevice] = useState('pc');
  const [, setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body,] = useState(<></>);
  const [searchResults, ] = useState(false);
  const [maxPageSize,] = useState(SiteData.storyPage.maxPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [filter, ] = useState("all")

  
  const {
    maxStorySize,
    getAllStoriesBy,
  } = useContext(StoryContext);
  //const {admin,login,getUser} = useContext(UserContext);



  const { retrieveProfile } = useProfile();
  const [profile, setProfile] = useState(null);
  const {retrieveAdmin} = useAdmin();
  const params = parseUrlForQueryParams(window.location.href);
  const [loadingPage, setLoadingPage] = useState(false);
  const [stories, setStories] = useState([]);
  useEffect(() => {
    async function fetchData() {
      setLoadingPage(true);
      let p;
      if(params?.isDtv==='1'){
         p = await retrieveAdmin(params.aid);
      }else{
         p = await retrieveProfile(params.aid);
      }
      setProfile(p);
      console.log(p,"<<<<<<",params)
      await showPage(1)
      setLoadingPage(false);
    }
    fetchData();
    updateDevice();
    window.addEventListener("resize", updateDevice);
  }, [])

  const handlePageChange = async (e, page) => {
    setLoadingPage(true);
    setCurrentPage(page);
    await showPage(page, filter);
    setLoadingPage(false);
  }
  const showPage = async (page) => {
    const temp = await getAllStoriesBy(params.aid,null, maxPageSize,Math.max(0,page-1)*maxPageSize);
    console.log(temp);
    setStories([...(temp??[])]);
  }
  const updateDevice = () => {
    if (window.innerWidth < 550) {
      setDevice('mobile');
      setDrawerWidth(300)
    }
    else {
      setDevice('pc')
      setDrawerWidth(280)
    }
  }

  const _buildAllStories = () => {
    return <div style={{ width: "100%", height: "100%", margin: "3vh 0", padding: "3vh" }}>
      <Grid container spacing={5}>

        {stories?.map((story, index) => {
          console.log(story);
          return <Grid key={story?.storyId ?? index} xs={device === "pc" ? 3 : 12} style={{ margin: "10px" }}>

            {story.isDTV && story.isDTV === 1 ? (<StoryCard onClick={() => {
              history({ pathname: `/stories/${story.storyId}`,replace:true, state: { story: story } })
            }} showLabel={true} views={story.views} story={story} />) : 
              <StoryCard onClick={() => {
                history({ pathname: `/stories/${story.storyId}`,replace:true, state: { story: story } })
              }} showLabel={true} views={story.views} story={story} />}
          </Grid>
        })}

      </Grid>
    </div>
  }
  const _buildPageBackGround = (() => {
    //animate = {{opacity:1-scrollPos}}style={{opacity:1-scrollPos}}
    return <Stack style={{ height: "40%" }}>
      <div style={{ width: '100%', height: '100%' }}>
        <img alt="logo" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={profile?.cover ?? logo} />
      </div>
      <Column alignment="space-between" style={{ height: "100%", width: "100%" }}>
        <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 20%, transparent)`, height: "100%", width: "100%" }}></div>
      </Column>

    </Stack>
  });
  const _buildPage = (() => {
    //...
    return loadingPage ? 
    <Column style={{width:'100%', height: '100%', alignItems: 'center'}} >
      <CircularProgress color="primary" />
    </Column> : <Column alignment="start" style={{ height: "100%", maxHeight: "100%", overflowY: "auto" }}>

      <div style={{ width: "100%", height: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 50%,transparent)` }}>

        <Grid container spacing={5} style={{ width: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 50%,transparent)`, margin: device === 'pc' ? "5% 0 0 0" : device === "pc" ? "60% 0 10px 0px" : "30% 0 10px 0", padding: device === 'pc' ? "0% 0" : "0" }}>

          <Grid xs={12}>{
            device === "pc" ? <ListItem>
              <ListItemAvatar>
                <Avatar style={{ width: "100px", height: "100px" }} className={classes.avatar} src={profile?.picture ?? logo} />
              </ListItemAvatar>
              <ListItemText secondary={<Typography variant="overline"><NumericLabel>{stories?.length}</NumericLabel> Stories</Typography>}
                primary={<Typography style={{ fontSize: "24px" }}>
                  {`${profile?.fname} ${profile?.lname}`}

                </Typography>}
              />

            </ListItem> : <Row alignment="centered">
              <Column>
                  <Avatar style={{ width: "100px", height: "100px" }} className={classes.avatar} src={profile?.picture ?? logo} />
                <Typography align="center" style={{ fontSize: "24px" }}>
                    {`${profile?.fname} ${profile?.lname}`}

                </Typography>
                <Typography align="center" variant="overline"><NumericLabel>{stories?.length}</NumericLabel> Stories</Typography>
              </Column>
            </Row>
          }

          </Grid>
          <Divider />

          <Grid container xs={12}>
            {stories ? _buildAllStories() : <></>}
            {<Grid xs={12}>
              <Row alignment="centered" style={{ width: "100%", margin: "10px 0" }} >
                <Pagination onChange={handlePageChange} count={Math.max(Math.floor(maxStorySize / maxPageSize), 1)} page={currentPage} variant="outlined" />
              </Row>
            </Grid>}
          </Grid>

        </Grid>
      </div>

    </Column>
  });

  return (<div className={classes.root}>
    <CssBaseline />
    <Stack style={{ height: "100%", width: "100%", overflow: "hidden" }}>
      <div style={{ background: ` ${alpha(theme.palette.background.default, 1)} `, height: "100%", width: "100%" }}></div>
      {_buildPageBackGround()}
      <div style={{ width: "100%", maxHeight: "100%", height: "100%", }}>{_buildPage()}</div>
    </Stack>
    {<AppBarModule pageIndex={6} appbarBackground={searchResults ? theme.palette.background.default : "transparent"} />}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
  </div>)


}
export default StoryAuthorPage;