
import firebase_app from "../config/firebase";
export const useTodoHandler = () => {
    //sender = userA
    //reciepientKey= userA_userB_dtv-adminId or userB_userA_dtv-adminId
    const addToDo = async({listId, itemId, completed, content, timestamp, access,index })=>{
        const item = {
            listId: listId,
            itemId: itemId,
            completed: completed,
            content: content,
            timestamp: timestamp,
            access: access,
            index: index,
        }
        try{
           await firebase_app.firestore().collection('ToDos').doc(listId).collection("Lists").doc(itemId).set(item); 
           return true;
        }catch(e){
            console.log(e);
            return false
        }
        
    }
    const deleteToDoItem = async({listId, itemId})=>{
        try{
            await firebase_app.firestore().collection('ToDos').doc(listId).collection("Lists").doc(itemId).delete(); 
            return true;
         }catch(e){
             console.log(e);
             return false
         }
    }
    const streamToDos = (key, callback)=>{
     firebase_app.firestore().collection('ToDos').doc(key).collection("Lists").onSnapshot((snapshot)=>{
         const items = snapshot.docs.map((doc)=>doc.data());
         callback(items);
     })
    }
    const getToDos = async (key)=>{
        try{
            const snapshot = await firebase_app.firestore().collection('ToDos').doc(key).collection("Lists").get();
            const items = snapshot.docs.map((doc)=>doc.data());
            return items;
        }catch(e){
            console.log(e);
            return [];
        }
    }
    const  updateToDo = async (updateToDo)=>{
        try{
            await firebase_app.firestore().collection('ToDos').doc(updateToDo.listId).collection("Lists").doc(updateToDo.itemId).update(updateToDo);
            return true;
        }catch(e){
            console.log(e);
            return false;
        }

    }
    const updateIndexes = async (listId, items)=>{
        try{
            items.forEach(async (item)=>{
                await firebase_app.firestore().collection('ToDos').doc(listId).collection("Lists").doc(item.itemId).update({index: item.index});
            })
            return true;
        }catch(e){
            console.log(e);
            return false;
        }
    }
    return {
        addToDo,
        streamToDos,
        deleteToDoItem,
        updateToDo,
        updateIndexes,
        getToDos
    }
};
