/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Dialog, Typography, Card, Chip } from '@material-ui/core';
import Row from '../../../components/Row'
import Column from '../../../components/Column';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import Swiper styles
import "swiper/swiper.min.css";
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import { Skeleton } from '@material-ui/lab';
import VideoSubmissionCard from '../../../components/video_sub_card';
import { useDatabase } from '../../../hooks/useDatabase';
import { SubmissionService } from '../../../services/submissionService';
import { useNavigate } from 'react-router';
import { FieldKeys } from '../../../utils';
import { useMediaQuery } from '../../../contexts/MediaQueryProvider';
import { MyVideosFilters } from './filters';
import ReactPlayer from 'react-player';

import PropTypes from 'prop-types'

export const MyVideosContent = ({ profile }) => {
    const [allVideoSubs, setAllVideoSubs] = useState([]);
    const [showVideoLoading, setShowVideoLoading] = useState(true);
    const [videoTransactions, setVideoTransactions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [preview_body, updatePreviewBody] = useState(null);
    const { getTransactions } = useDatabase();
    const { getVideoSubmissionsByUser, searchVideoSubmissionsByUser } = SubmissionService();
    const history = useNavigate();
    const {device} = useMediaQuery();

    const handleRetrieveVideoSubmission = async (profile) => {
        setShowVideoLoading(true)
        console.log(profile)
        const { data } = await getVideoSubmissionsByUser({ userId: profile?.uid, limit: 100 });
        setAllVideoSubs(data);
        setShowVideoLoading(false);
    }
    const handleSearch = async (search) => {
        if (search.length > 0) {
            const {data} = await searchVideoSubmissionsByUser({ userId: profile?.uid, query: search });
            setAllVideoSubs(data);
        } else {
            handleRetrieveVideoSubmission(profile);
        }
    
    }

    useEffect(() => {
        if (profile) {
            //!! fetch all video submissions from new api
            getTransactions({ uid: profile?.uid, cat: FieldKeys.topUp }).then((docs) => {
                console.log(docs);
                setVideoTransactions(docs);
            });
            handleRetrieveVideoSubmission(profile);
        }
    }, [])
    
    const _buildVideoPreview = (videosubs) => {
        return <Column style={{alignItems: 'center', width:'100%',padding:"10%"}}> 
            <ReactPlayer controls width={'100%'} url={videosubs.link} />
            <Typography align='left' style={{width:'100%', fontWeight: "bold", fontSize: "20px", textAlign: "left",marginTop:20 }}>{videosubs.videoTitle}</Typography>
            <Typography align='left' style={{width:'100%', fontWeight: "400", fontSize: "10px", textAlign: "left", marginTop:10,opacity:0.6 }}>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'long', day: '2-digit' }).format(videosubs.date)}</Typography>
            <Typography align='left' style={{width:'100%', fontWeight: "400", fontSize: "14px", textAlign: "left", marginTop:10 }}>{videosubs.videoDescription}</Typography>
        </Column>
    }
    
    if (!profile) return <></>

    return <><div>
        <Card variant='outlined' style={{ padding: 20 }}>{
            device === "xs" ? <Column>
                <MyVideosFilters onSearch={handleSearch}/>
            </Column> : <Row alignment="space-between">
                <MyVideosFilters onSearch={handleSearch} />
            </Row>}
            <Column style={{ margin: "20px 0" }}>
                {showVideoLoading ? <Skeleton
                    sx={{ bgcolor: 'grey.900' }}
                    variant="rectangular"
                    width={'100%'}
                    height={150}
                    style={{ margin: "20px 0" }}
                /> : <><Typography style={{ fontWeight: "bold", fontSize: "16px", textAlign: "left" }}>Summary</Typography>
                    <Row
                        style={{ marginTop: 10, flexWrap: 'wrap' }}>
                        <Chip label={`${allVideoSubs?.length ?? 0} submissions`} style={{ margin: 5 }} />
                        <Chip label={`${allVideoSubs?.filter((v) => v.status === "pending").length ?? 0} Pending`} style={{ margin: 5 }} />
                        <Chip label={`${allVideoSubs?.filter((v) => v.status === "approved").length ?? 0} Approved`} style={{ margin: 5 }} />
                        <Chip label={`${allVideoSubs?.filter((v) => v.status === "rejected").length ?? 0} Rejected`} style={{ margin: 5 }} />
                        <Chip label={`${allVideoSubs?.filter((v) => v.status === "cancelled").length ?? 0} Cancelled`} style={{ margin: 5 }} />
                        <Chip label={`${allVideoSubs?.filter((v) => v.status === "payment").length ?? 0}  Payment Required`} style={{ margin: 5, backgroundColor: allVideoSubs?.filter((v) => v.status === "payment").length > 0 ? 'red' : '' }} />
                        <Chip label={`${allVideoSubs?.filter((v) => v.status === "published").length ?? 0} Published`} style={{ margin: 5 }} />
                        {videoTransactions?.length > 0 && <Chip label={`${videoTransactions?.reduce((acc, cur) => acc + cur.cost, 0) ?? 0} Total DTV Coins Spent`} style={{ margin: 5 }} />}
                    </Row></>}
            </Column>
            {showVideoLoading ? <Column>
                {[1, 2, 3, 4, 5, 6].map((num,index) => <Skeleton key={num+""+index}
                    sx={{ bgcolor: 'grey.900' }}
                    variant="rectangular"
                    width={210}
                    height={118}
                    style={{ margin: 20 }}
                />)}
            </Column> : <Column>
                {
                    (allVideoSubs ?? []).length < 1 ? <Typography variant="caption" style={{ opacity: 0.5 }}> {`No video submissions found!`}</Typography> :
                        allVideoSubs.map((s, index) => {
                            s.statusDates = typeof s.statusDates === 'string' ? JSON.parse(s.statusDates) : s.statusDates;
                            return <div key={index+s.sId} onClick={() => {
                                history(`/${profile.uid}/VideoSubmission?id=${s.sId}`)
                            }}><VideoSubmissionCard  disable onOpenPreview={
                                (data) => {
                                    updatePreviewBody(_buildVideoPreview(data));
                                    setShowModal(true)
                                }
                            } data={s} key={`ms_${index}`} style={{ margin: 10 }} />
                            </div>
                        })
                }
            </Column>}
        </Card>
</div >

<Dialog
            maxWidth={device !== "xs" ? "md" : "xs"}
            onClose={() => { setShowModal(false) }}
            BackdropProps={{
                style: {
                    filter: "blur(10px)"
                }
            }}
            fullWidth
            aria-labelledby="confirmation-dialog-title"
            open={showModal}>
            {preview_body}
        </Dialog>
</>
}
MyVideosContent.propTypes = {
    profile: PropTypes.object,

}