/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails,CardContent, AccordionSummary, Card, CardHeader, CircularProgress, Grid, MenuItem, Select, TextField, Typography, Button, Chip } from "@material-ui/core";
import { useDatabase } from "../hooks/useDatabase";
import { ApplicationProcess, getStatusColor, isValidLink, loadFromDevice, parseUrlForId } from "../utils";
import Column from "./Column";
import Tracker from "./generic_tracker";
import Row from "./Row";
import { Skeleton } from "@mui/material";
import {  useNavigate } from "react-router";
import { ArrowDownward } from "@material-ui/icons";
import { UploadProfilePicture } from "./UploadDiv";
import useServiceBooking from "../contexts/ServiceBookingProvider";
import ServiceAgreementCard from "./service_agreement_card";
import { motion } from "framer-motion";    
import { useStorage } from "../contexts/storage_handler";
import { sendNotification } from "../logic/notificationHandler";
import { sendFeedback } from "../config/emailer";
import { useSiteData } from "../config/SiteData";
import { useSnackbar } from "notistack";
import { useProfile } from "../hooks/useProfile";
import { useCustomDialog } from "../contexts/CustomDialogProvider";
import FutureBuilder from "./_future_builder";
import Logic from "../logic/logic";
import { HelpCenterOutlined } from "@mui/icons-material";
import PropTypes from 'prop-types';

const BuildTracker = ({status, statusDates})=>{
        return <Tracker status={status} processFilters={
            ApplicationProcess.filter(p => {
                return (
                  (p === 'reviewed' && statusDates.reviewed) ||
                  (p === 'rejected' && !statusDates.reviewed && statusDates.rejected && !statusDates["change-requested"] && !statusDates["cancelled"]) ||
                  (p === 'change-requested' && !statusDates.reviewed && !statusDates.rejected && statusDates["change-requested"]&& !statusDates["cancelled"]) ||
                  (p === 'cancelled' && statusDates.cancelled) ||
                  (p === 'payment-failed' && statusDates['payment-failed']) ||
                  (p !== 'reviewed' && p !== 'rejected' && p !== 'cancelled' && p !== 'payment-failed' && p!=="change-requested" )
                );
              })
        }/>
}
const BuildApplicationActions = ({application}) => {
    const { updateServiceAgentApplication, getAgreement } = useDatabase();
    const {enqueueSnackbar} = useSnackbar();
    const [agreement, setAgreement] = useState();
    const { showDialog, closeDialog } = useCustomDialog();
    const history = useNavigate();
    useEffect(() => {
        getAgreement(application.serviceAgreement).then((agreement) => {
            setAgreement(agreement);
        })
    },[]);
    const handleCancelApplication = (application)=>{
        showDialog({
            title: "Cancel Application",
            body: "Are you sure you want to cancel this application?",
            onAccept: ()=>{
            application.status = "cancelled";
                    application.statusDates = {
                        ...application.statusDates,
                        cancelled: new Date().getTime()
                    }
                    updateServiceAgentApplication(application.id,application).then(()=>{
                        closeDialog();
                        enqueueSnackbar("Application Cancelled",{
                            variant: "success"
                        });
                    })
            }
        })
       
    }
    const BuildBody = ()=>{
        switch (application.status) {
            case 'submitted' : case 'pending':
                return <Column style={{textAlign:'left'}}>
                    <Typography variant="h6">Application Submitted for review</Typography>
                    <Typography color="textSecondary" variant="caption">{new Date(application.statusDates[application.status]).toDateString()}</Typography>
                    <div style={{height: 10}}/>
                    <Typography variant="body2">Your application is currently being reviewed, this could take 3-5 days</Typography>
                    <Button onClick={()=>{
                        handleCancelApplication(application);
                    }} size="small" variant="outlined" color="red.800" style={{marginTop: 10, borderColor:"red", color:"red"}}>Cancel Application</Button>
                </Column>
            case 'change-requested':
                return <Column style={{textAlign:'left'}}>
                    <Typography variant="h6">Attention!! Change Requested</Typography>
                    <Typography color="textSecondary" variant="caption">{new Date(application.statusDates[application.status]).toDateString()}</Typography>
                    <div style={{height: 10}}/>
                    <Typography variant="body1">A change was requested</Typography>
                    <Typography color={"textSecondary"} style={{fontStyle:'italic'}} variant="body2">{application.changeRequest}</Typography>
                </Column>
            case 'payment-processing':
                return <Column style={{textAlign:'left'}}>
                    <Typography variant="h6">Awesome, Almost there</Typography>
                    <Typography color="textSecondary" variant="caption">{new Date(application.statusDates[application.status]).toDateString()}</Typography>
                    <div style={{height: 10}}/>
                    <Typography variant="caption">Payment has been sent, and is now being processed this might take 3-5 business days</Typography>
                    <div style={{height: 10}}/>
                    <Row alignment="right">
                        <Button size="small" variant="outlined" startIcon={<HelpCenterOutlined/>}>Get Help</Button>
                    </Row>
                </Column>
            case 'payment':
                return <Column style={{textAlign:'left'}}>
                    <Typography variant="h6">Review Completed</Typography>
                    <Typography color="textSecondary" variant="caption">{new Date(application.statusDates[application.status]).toDateString()}</Typography>
                    <div style={{height: 10}}/>
                    <Typography variant="body1">Payment Details</Typography>
                    {agreement && <Row alignment={"space-between"} style={{width:"100%"}}>
                        <Typography variant="caption">{agreement.name}</Typography>
                       {agreement.cost ? <FutureBuilder 
                       futureFunc = {
                        ()=>{
                            Logic.toCountryCurrency(Number(agreement.cost??0),0)
                        }
                       }
                       builder = {
                        (res)=>{
                            return <Typography variant="caption">{res}</Typography>
                        }
                       }
                       errorChild = {
                        <Typography variant="caption">0</Typography>
                       }
                        placeholder = {
                            <Typography variant="caption">--</Typography>
                        }
                       /> : <Typography variant="caption">{'FREE'}</Typography>}
                    </Row>}
                    <div style={{height: 10}}/>
                    <Row alignment="right">

                        <Button onClick={()=>{
                            handleCancelApplication(application);
                        }} size="small"  color="red.800" style={{marginTop: 10 }}>Cancel Application</Button>
                        <div style={{width: 10}}/>
                        <Button onClick={()=>{
                            history(`/payment?type=saa&id=${application.id}_${application.serviceAgreement}`);
                        }} size="small" variant="outlined" color="primary" style={{marginTop: 10, }}>Pay Now</Button>
                    </Row>
                </Column>
            case 'cancelled':
                return <Column style={{textAlign:'left'}}>
                    <Typography variant="h6">{"You've Decided to close this application"}</Typography>
                    <Typography color="textSecondary" variant="caption">{new Date(application.statusDates[application.status]).toDateString()}</Typography>
                    <div style={{height: 10}}/>
                    <Button onClick={()=>{
                        application.status = "pending";
                        application.statusDates = {
                            submitted: new Date().getTime(),
                            pending: new Date().getTime()
                        }
                        updateServiceAgentApplication(application.id,application).then(()=>{
                            enqueueSnackbar("Application Cancelled",{
                                variant: "success"
                            });
                        })
                    }} size="small" variant="outlined"style={{marginTop: 10,}}>Re-Open Application</Button>
                </Column>
            default: return <></>
        }
        }
        return <BuildBody/>
}

BuildApplicationActions.propTypes = {
    application: PropTypes.object
}
const BuildApplicationData = ({application:Application}) => {
    const { getAllCustomerAgreements,updateServiceAgentApplication } = useDatabase();
    const { getServiceTypes, } = useServiceBooking();
    const [agreements, setAgreements] = useState([]);
    const [serviceTypes, setServiceTypes] = useState([]);
    const [application, setApplication] = useState(Application);
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(true);
    const { uploadMediaOperation } = useStorage();
    const {SiteData} = useSiteData();
    useEffect(() => {
        setLoading(true);
            getServiceTypes().then((types) => {
                console.log(">>",Object.keys(types))
                getAllCustomerAgreements().then((a) => {
                    console.log(a);
                    setAgreements(Object.values(a));
                    setServiceTypes(Object.keys(types));
                    setLoading(false);
                })
               
            
        })
    },[]);
    const uploadProfileImage = async ()=>{
        if(application.picture && !isValidLink(application.picture)){
            return await uploadMediaOperation(application.picture,"agents_profile_picture");   
        }
        return application.picture;
    }
    const handleReSubmit = async()=>{
        try{
            setUploading(true);
            const picture = await uploadProfileImage();
            application.picture = picture;
            application.status = "pending";
            delete application.statusDates["change-requested"];
            application.statusDates ={
                ...application.statusDates,
                pending: new Date().getTime()
            }
            updateServiceAgentApplication(application.id,application).then(async ()=>{
                setUploading(false);
                await sendNotification({
                    title: "Application Change Submitted",
                    body: `${application.sentBy} has submitted a change to their application`,
                    userId: application.uid,
                    parentId: application.id,
                    type: "service_agent_submission",
                    timestamp: new Date().getTime()
                })
                sendFeedback({
                    to: SiteData.email,
                    from: SiteData.email,
                    subject: "Service Agent Application",
                    body: `<p>Dear Admin,</p>
                    <p>${application.sentBy} has submitted a change to their service agent application</p>
                    <p>Kind Regards,</p>
                    <p>DearFxch.com</p>
                    `
                })
                window.location.reload();

            })
        }catch(e){
            console.log(e);
        }
    }
    return loading ? <>
        <CircularProgress/>
    </> : application && <Column>
        <UploadProfilePicture disabled={application.status !== "change-requested"}  onUpload={
            (file)=>{
                loadFromDevice(file,(blob)=>{
                    application.picture = isValidLink(blob) ? blob : {
                        blob: blob,
                        file:file
                    }
                })
            }
        } image={application?.picture} size={100}/>
        <div style={{height: 15}}/>
        <TextField variant="outlined" onChange={(e)=>{
            application.name = e.target.value;
            setApplication({...application});
        }} InputLabelProps={{shrink:true}} label="Name" value={application.name} disabled={application.status !== "change-requested"} readonly={application.status !== "change-requested"}/>
        <div style={{height: 15}}/>
        <TextField variant="outlined" onChange={
            (e)=>{
                application.email = e.target.value;
                setApplication({...application});
            }
        } InputLabelProps={{shrink:true}} label="Email" value={application.email} disabled={application.status !== "change-requested"} readonly={application.status !== "change-requested"}/>
        <div style={{height: 15}}/>
        <TextField variant="outlined" onChange={
            (e)=>{
                application.location = e.target.value;
                setApplication({...application});
            }
        } InputLabelProps={{shrink:true}} label="Location" value={application.location} disabled={application.status !== "change-requested"} readonly={application.status !== "change-requested"}/>
        <div style={{height: 15}}/>
        <Select disabled={application.status !== "change-requested"} variant="outlined"  onChange={
            (e)=>{
                application.serviceType = e.target.value;
                setApplication({...application});
            }
        } defaultValue={application.serviceType}>
            {serviceTypes.map((type,index) => {
                return <MenuItem key={index} value={type}>{type}</MenuItem>
            })}
        </Select>
        <div style={{height: 10}}/>
        { application.status === "change-requested"  && agreements ? <Row>
            {
                agreements.map((agreement,index) => {
                    return <motion.div  onClick={()=>{
                        console.log(agreement);
                        application.serviceAgreement = agreement.id;
                        setApplication({...application});
                        updateServiceAgentApplication(application.id,application).then((res)=>{
                            console.log(res);
                        })
                    }}whileHover={{scale: 1.03, cursor:'pointer'}} initial={{x:-10}} animate={{x:0}} exit={{x:-10}} transition={{duration:0.2}} key={index}>
                        <ServiceAgreementCard key={index} 
                           
                        serviceAgreement={agreement} highlight={application.serviceAgreement === agreement.id}/>
                    </motion.div>
                })
            }
        </Row>:<ServiceAgreementCard serviceAgreement={application.serviceAgreement} highlight={application.status === 'approved'}/>}
        <div style={{height: 10}}/>
        {
            application.status === "change-requested" && <Button onClick={
                handleReSubmit
            } size="small" variant="contained" color="primary" style={{marginTop: 10}}>
                {
                    uploading ? <CircularProgress size={20}/> : "Submit Application"
                }
            </Button>
        }
    </Column>
}

BuildApplicationData.propTypes = {
    application: PropTypes.object
}
BuildTracker.propTypes = {
    status: PropTypes.string,
    statusDates: PropTypes.object,
}

const MyApplications = () => {
    const { streamApplicationsBy } = useDatabase();
    const { getServiceTypes, } = useServiceBooking();
    const [applications, setApplications] = useState([]);
    const [loading, setLoading] = useState(true);
    const { retrieveProfile } = useProfile();
    const [profile, setProfile] = useState();
    
    const uid = parseUrlForId(window.location.href);
    useEffect(() => {
        setLoading(true);
         getServiceTypes().then((types) => {
                console.log(">>",Object.keys(types))
                  
                    setLoading(false);
            
               
            })
            retrieveProfile(uid).then((profile)=>{
            console.log(profile);
             setProfile(profile);
         })
         const unsubs =  streamApplicationsBy(uid, (applications)=>{
           
            console.log(applications);
             setApplications(applications);
        });
        return unsubs;
    },[]);



    return <Column>
        <Typography align="left" variant="h5">My Applications</Typography>
        <div style={{height: 10}}/>
        <Column>
            {
                loading ? <>
                  {Array.from(Array(3).keys()).map((index) => {
                    return <Skeleton key={index} sx={{ bgcolor: 'grey.900' }} variant="rectangular" width={'100%'} height={140} style={{margin: '10px 0'}}/>
                  })}
            </> : applications.length > 0 ? <>
                {
                    applications.map((application,index) => {
                        return <Accordion variant="outlined" key={index} >
                                <AccordionSummary expandIcon={<ArrowDownward/>}>
                                   <Row style={{width:"100%", alignItems:'center'}} alignment="space-between">
                                    <Column>
                                        <Typography align="left" variant="h6">{`${application.type} Application`}</Typography>
                                        <Typography color="textSecondary" align="left" variant="body2">{`Submitted on ${new Date(application.dateSent).toDateString()}`}</Typography>
                                    </Column>
                                   { application.status !== "completed" ? <BuildTracker statusDates={application.statusDates} status={application.status}/>:
                                   <Chip label={"Completed"} variant="outlined" color="primary" style={{
                                    borderColor: getStatusColor(application.status),
                                    color: getStatusColor(application.status),
                                }}/>
                                   }
                                   </Row>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6}>
                                            <Card variant="outlined">
                                                <CardHeader title={
                                                    <Typography variant="body2">Application Details</Typography>
                                                }/>
                                                <CardContent>
                                                    <BuildApplicationData application={application} />
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                        {application.status !== "completed" &&  <Grid item xs={12} sm={6}>
                                            <Card variant="outlined">
                                                <CardHeader title={
                                                    <Typography variant="body2">Application Actions</Typography>
                                                }/>
                                                <CardContent>
                                                    <BuildApplicationActions application={application}/>
                                                </CardContent>
                                            </Card>
                                        </Grid>}
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                    })
                } </> : <>
                   {profile && <Typography variant="h6">You have no applications</Typography>}
                </>
            }
        </Column>
    </Column>
}
export default MyApplications;
