/* eslint-disable react-hooks/exhaustive-deps */
import { CloseRounded, Delete } from "@material-ui/icons";
import { calcDaysPast, getStatusColor, loadFromDevice } from "../../../utils";
import React, { useEffect, useRef, useState } from "react";
import useServiceBooking from "../../../contexts/ServiceBookingProvider";
import { useProfile } from "../../../hooks/useProfile";
import { Button, Card, CardActions, CardContent, Checkbox, Chip, CircularProgress, Dialog, DialogContent, Divider, FormControlLabel, Grid, IconButton, LinearProgress, Link, MenuItem, Radio, Select, TextField, Typography } from "@material-ui/core";
import Column from "../../Column";
import { useSnackbar } from "notistack";
import shortUUID from "short-uuid";
import Row from "../../Row";
import Ratings from "../../ratings";
import { useStorage } from "../../../contexts/storage_handler";
import { useChatMessanger } from "../../../hooks/useChatMessenger";
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { UploadDiv } from "../../UploadDiv";
import Stack from "../../Stack";
import { useSiteData } from "../../../config/SiteData";
import { NotificationTypes, sendNotification } from "../../../hooks/notificationHandler";
import ProjectActionDeposit from "./_project_action_deposit";
import { useTodoHandler } from "../../../hooks/useToDoList";
import { createInvoice } from "../../../logic/invoice";
import PropTypes from 'prop-types'


const BuildPreview = ({service, onUpload})=>{
  const [previews, setPreviews] = useState(service.previews??[]); // [{url: "", type: "video" | "image"}
  const [activeIndex, setActiveIndex] = useState(0);
  const handleCarouselChange = newIndex => {
      setActiveIndex(newIndex);
    
    };
  console.log(previews);
  return <Column>
  <Typography variant="caption" color="textSecondary">Please Create a preview for the client to review. We Recommend that images should have watermark, to prevent stolen property. Videos should be kept to 0:30s video preview</Typography>
  <div style={{height: 10}}/>
  <Button disableElevation size="small" color="default" variant="outlined" onClick={()=>{
      if(previews.filter((p)=>p.url === "").length > 0){
          return;
      }
      console.log(service.serviceType?.name)
      service.serviceType.name === "VIDEOGRAPHY" ? 
      setPreviews([...previews, {url: "", type: "video"}]) : setPreviews([...previews, {url: "", type: "image"}]);
  }}>Add Another Preview</Button>
  <div style={{height: 10}}/>
<Carousel 
selectedItem={activeIndex}
onChange={handleCarouselChange}
showStatus={false}
showThumbs={false}
centerMode
centerSlidePercentage={100}
emulateTouch
>
{previews.map((image, index) => (
<Column key={index} style={{width:'100%', height:'100%',padding: "60px 20px"}}>
  <div style={{height: 300}}>{
  <UploadDiv  image={image?.blob ?? image?.url} video={service.serviceType === "VIDEOGRAPHY"} label="Upload Preview" onUpload={(file)=>{
     loadFromDevice(file, (blob)=>{
      if(typeof blob ==='string' && blob.match('https://|http://')){
          previews[index] = {url: blob,type: service.serviceType === "VIDEOGRAPHY" ? "video" : "image"};
        } 
        else
        {  previews[index] = {
            file:file,
            blob:blob,
            type: service.serviceType === "VIDEOGRAPHY" ? "video" : "image"
      }}
     })
      setPreviews(previews);
      onUpload(previews);
      // service.previewUrl = url;
      // setService(service);
      // updateRequest(serviceId, service).then((res)=>{});
  }}/> 
}
  </div>
<Button disableElevation size="small" color="default" variant="outlined" onClick={()=>{
  previews.splice(index,1);
  setPreviews(previews);
  onUpload(previews);
}}>DELETE</Button>

</Column>
))}
</Carousel>
  
  </Column>
}

BuildPreview.propTypes = {
    service: PropTypes.object,
    onUpload: PropTypes.func
}

const ProjectAction = ({ agent:Agent, service:Service,servicePackage:ServicePackage, openAgreement,onOpenToDoPanel,onReadDescription}) => {

    const { getAgent, updateRequest } = useServiceBooking();
    const { retrieveProfileWithCallback } = useProfile();
    const { SiteData } = useSiteData();
    const [service, setService] = useState(Service);
    const [agent, setAgent] = useState(Agent);
    const [servicePackage, ] = useState(ServicePackage);
    const [profile, setProfile] = useState({});
    const { enqueueSnackbar } = useSnackbar();
    const [, setShowReview] = useState(false);
    const [status, ] = useState(Service.status);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogBody, setDialogBody] = useState(null);
    const [extraCharges, setExtraCharges] = useState(service?.extraCharges??[]);
    const {uploadMediaOperation} = useStorage();
    const { sendMessage } = useChatMessanger();
    const previewCommentRef = useRef(null);
    const [uploading, setUploading] = useState(false);
    const [deliverables, setDeliverables] = useState(service?.deliverables??[]);
    const serviceId = service.id;
  const [progress,setProgress] = useState(0);
  const [MAX_EXTRACHARGE, setMAX_EXTRACHARGE] = useState(3);
  const [uploadingMatrix, setUploadingMatrix] = useState({});
  const [serviceTodoList, setServiceTodoList] = useState([]);
  const {streamToDos} = useTodoHandler();
    const serviceString = JSON.stringify(Service);
    useEffect(()=>{
        setService(JSON.parse(serviceString));
        setMAX_EXTRACHARGE(SiteData.MAX_EXTRACHARGE??3);
        retrieveProfileWithCallback((profile)=>{
            setProfile(profile);
            getAgent(Service.agentId).then((agent)=>{
                setAgent(agent);
                
            })
        })
        const unsubscribe = streamToDos(Service.id, (data)=>{
            setServiceTodoList(data);
        })
        return unsubscribe;
    }, [serviceString]);
    
  const uploadImages = async (n)=>{
    if(n===0)return;
    console.log(typeof deliverables[n-1].blob )
    if(deliverables[n-1].blob){
        console.log("uploading");
        setUploadingMatrix({...uploadingMatrix,[n-1]:true});
        
        deliverables[n-1].file = await uploadMediaOperation(deliverables[n-1],"deliverables",(p)=>setProgress(p));
        setUploadingMatrix({...uploadingMatrix,[n-1]:false});
        deliverables[n-1].url =  deliverables[n-1].file;
        setDeliverables([...deliverables]) 
    }
            return uploadImages(n-1)
       
    }
const handleConfirmPayment = async ()=>{
  await uploadImages(deliverables?.length);
  const statusDates = {
    ...service.statusDates,
    'payment-recieved': new Date().getTime(),
    'delivered': new Date().getTime(),
}
const update = {
    status: "delivered",
    deliverables:deliverables,
    completed:true,
    statusDates: statusDates
}
setDialogBody(<BuildConfirmation title="Are you sure the payment has been recieved?" body={
   <Column style={{padding: 20}}>
    <Typography variant="caption">By Clicking I Confirm you are accepting the legal conditions involving the contract between DTV and the Agent involved.</Typography></Column>
    
} 
okText="I Confirm" onOkClicked={()=>{
    setShowDialog(false);
    sendMessage({
        messageId: shortUUID.generate(),
        senderId: agent.agentId,
        reciepientKey: service.id,
        reciepients: [service.uid, 'dtv-admin'],
        content: "Your payment has been confirmed & deliverables are now available for download",
        timestamp: new Date().getTime(),
        seenby: [agent.agentId]
    });
    
    updateRequest(serviceId, update).then((res)=>{
        if(res){
            enqueueSnackbar("Payment Confirmed", {variant:"success"});
        }else{
            enqueueSnackbar("Failed to confirm payment", {variant:"error"});
        }
    })
}} onCancelClicked={()=>{setShowDialog(false)}
}/>);
setShowDialog(true);

}
    const uploadMedia = async (n,listOfData,onUploaded) => {
        if (n === 0) return;
         console.log(">>>UPLOADING", listOfData[n - 1]);
        if(!(typeof (listOfData[n-1]?.url??listOfData[n-1]?.file) === "string")){
           
            listOfData[n - 1].blob = listOfData[n - 1].file = await uploadMediaOperation(listOfData[n - 1]);
           listOfData[n - 1].url =listOfData[n - 1].file;
        }else{
           listOfData[n - 1].url =listOfData[n - 1]?.url;
        }
        onUploaded(listOfData[n-1]);
        return uploadMedia(n - 1,listOfData,onUploaded);
      
      }
    const BuildConfirmation = ({title="",body=<></>, okText="", onOkClicked = ()=>{}, onCancelClicked = () => {}}) => {
        return <Column style={{width:'100%', padding:20}}>
            <Typography variant="h6">{title}</Typography>
            {body}
            <div style={{height:'1em'}}/>
            <Row alignment="right" style={{width:'100%'}}>
                <Button onClick={
                     ()=> onCancelClicked()
                }>Cancel</Button>
                <div style={{width:'1em'}}/>
                <Button color="primary" onClick={() => {
                        onOkClicked();
                }} >{okText}</Button>
                </Row>
        </Column>
    }
      BuildConfirmation.propTypes = {
        title: PropTypes.string,
        body: PropTypes.any,
        okText: PropTypes.string,
        onOkClicked: PropTypes.func,
        onCancelClicked: PropTypes.func
      }

    const BuildRejectionForm = ({onChanged})=>{

        const [reason, setReason] = useState(null);
        return  <Column>
            <Typography style={{marginBottom: 10}} variant="caption">Please provide a reason for rejecting this request</Typography>
            {
                ['Client is not a good fit', 'Client is not willing to pay the required amount', 'Client is not willing to pay the deposit'].map((r,index)=>{
                    return <FormControlLabel key={index}
                    control={
                    <Radio checked={reason === r} onChange={()=>{
                        setReason(r)
                        onChanged(r);
                    }} />
                    }
                    label={r}
                />
                })
            }
            <div style={{height: 10}}/>
            <TextField value={reason} onChange={
                (e)=>{
                    setReason(e.target.value);
                    onChanged(e.target.value);
                }
            } fullWidth multiline variant="outlined" label="Full Reason" />
            
        </Column>
    }
    
    BuildRejectionForm.propTypes = {
        onChanged: PropTypes.func
    }
    const handleAccept = ()=>{
        const statusDates = {
            ...service.statusDates,
            pending: new Date().getTime(),
            approved: new Date().getTime(),
            deposit: new Date().getTime(),
        }
        const update = {
            status: "deposit",
            statusDates: statusDates
        }
        setDialogBody(<BuildConfirmation title="Are you sure you want to approve this request?" 
        okText="Approve" onOkClicked={()=>{
            setShowDialog(false);
            sendMessage({
                messageId: shortUUID.generate(),
                senderId: agent.agentId,
                reciepientKey: service.id,
                reciepients: [service.uid, 'dtv-admin'],
                content: "Your request has been approved",
                timestamp: new Date().getTime(),
                seenby: [agent.agentId]
            });
            
            sendNotification({
                id: shortUUID.generate(),
                sendTo: agent.agentId,
                sentFrom: profile.uid,
                type: NotificationTypes.SERVICE,
                title: "Request Approved",
                message: "Your request has been approved",
                timestamp: new Date().getTime(),
                seen: false,
                url: `/${service.uid}/ServiceBooking?id=${service.id}`
            })
            updateRequest(serviceId, update).then((res)=>{
                if(res){
                    enqueueSnackbar("Request Approved", {variant:"success"});
                }else{
                    enqueueSnackbar("Failed to Approve Request", {variant:"error"});
                }
            })
        }} onCancelClicked={()=>{setShowDialog(false)}
        }/>);
        setShowDialog(true);
     
    }
    const handleReject = ()=>{
        const statusDates = {
            ...service.statusDates,
            rejected: new Date().getTime()
        }
        const update = {
            status: "rejected",
            statusDates: statusDates
        }
       
        setDialogBody(<BuildConfirmation title="Are you sure you want to reject this request?" 
        body={
           <BuildRejectionForm onChanged={(reason)=>{
                service.rejectionReason = reason ?? '';
           }}/>

        }
        okText="Yes, Reject" onOkClicked={()=>{
            setShowDialog(false);
            sendMessage({
                messageId: shortUUID.generate(),
                senderId: agent.agentId,
                reciepientKey: service.id,
                reciepients: [service.uid, 'dtv-admin'],
                content: "Your request has been declined",
                timestamp: new Date().getTime(),
                seenby: [agent.agentId]
            });
            sendNotification({
                id: shortUUID.generate(),
                sendTo: service.uid,
                sentFrom: profile.uid,
                type: NotificationTypes.SERVICE,
                title: "Request Rejected",
                message: "Unfortunately, your Request has been rejected, please contact us for more information",
                timestamp: new Date().getTime(),
                seen: false,
                url: `/contact`

                
            })
            updateRequest(serviceId, update).then((res)=>{
                if(res){
                    enqueueSnackbar("Succefully Declined", {variant:"success"});
                }else{
                    enqueueSnackbar("Failed to Decline", {variant:"error"});
                }
            })
        }} onCancelClicked={()=>{setShowDialog(false)}
        }/>);
        setShowDialog(true);
     
    }
    const handleRequestPayment = ()=>{
        const statusDates = {
            ...service.statusDates,
            payment: new Date().getTime(),
        }
        const update = {
            status: "payment",
            paymentDeadline: new Date().addDays(5), 
            statusDates: statusDates
        }
        setDialogBody(<BuildConfirmation title="Are you sure? You will not be able to cancel this request once payment is requested" 
        okText="Request Now" onOkClicked={()=>{
            setShowDialog(false);
            sendMessage({
                messageId: shortUUID.generate(),
                senderId: service.agentId,
                reciepientKey: service.id,
                reciepients: [service.uid, 'dtv-admin'],
                content: "Payment has been requested, please pay in order to continue",
                timestamp: new Date().getTime(),
                seenby: [service.agentId]
            });
            sendNotification({
                id: shortUUID.generate(),
                sendTo: service.uid,
                sentFrom: service.agentId,
                type: NotificationTypes.SERVICE_PAYMENT,
                title: "Payment Requested",
                message: "Payment has been requested, please pay in order to continue",
                timestamp: new Date().getTime(),
                seen: false,
            })
            
            updateRequest(serviceId, update).then((res)=>{
                if(res){
                    enqueueSnackbar("Sent", {variant:"success"});
                }else{
                    enqueueSnackbar("Failed to send", {variant:"error"});
                }
            })
        }} onCancelClicked={()=>{setShowDialog(false)}
        }/>);
        setShowDialog(true);
     
    }
    const handlePreview = ()=>{
        const statusDates = {
            ...service.statusDates,
            review: new Date().getTime(),
        }
        const update = {
            status: "review",
            statusDates: statusDates
        }
        setDialogBody(<BuildConfirmation title="Preview" 
        body={
        <Column>
        <BuildPreview service={service} onUpload={(previews)=>{
            service.previews = previews;
            setService(service);
        }}/>
        <div style={{height: 10}}/>
        <TextField inputRef={previewCommentRef} fullWidth multiline variant="outlined" placeholder="Add a comment" />
        </Column>}
        okText="Submit Review" onOkClicked={async()=>{
            
            sendMessage({
                messageId: shortUUID.generate(),
                senderId: service.agentId,
                reciepientKey: service.id,
                reciepients: [service.uid, 'dtv-admin'],
                content: "A Preview was uploaded for your review. Please check it out and leave a review",
                timestamp: new Date().getTime(),
                seenby: [service.agentId]
            });
            sendNotification({
                id: shortUUID.generate(),
                sendTo: service.uid,
                sentFrom: service.agentId,
                type: NotificationTypes.SERVICE_REVIEW,
                title: "Your Preview is Available",
                message: "A Preview was uploaded for your review. Please check it out and leave a review",
                timestamp: new Date().getTime(),
                seen: false,
                url: `/${service.uid}/ServiceBooking?id=${service.id}`

            })
            console.clear();
            console.log(service.previews);
            setUploading(true);
            if(service.previews?.filter((p)=>p.url !== "").length > 0){
                await uploadMedia(service.previews.length, service.previews, (preview)=>{
                    console.log(preview,"<<<<<");
                    service.previews[service.previews.findIndex((p)=>p.url === preview.url)] = preview;
                    setService(service);
                   
                })
            }
            setUploading(false);
            update.previews = service.previews;
            update.previewComment = previewCommentRef.current?.value??"";
            updateRequest(serviceId, update).then((res)=>{
                if(res){
                    enqueueSnackbar("Review Requested", {variant:"success"});
                }else{
                    enqueueSnackbar("Failed to Mark Request as Completed", {variant:"error"});
                }
                setShowDialog(false);
            })
        }} onCancelClicked={()=>{setShowDialog(false)}
        }/>);
        setShowDialog(true);    
    }
    const handleConfirmDeposit = ()=>{
        const statusDates = {
            ...service.statusDates,
            'deposit-paid': new Date().getTime(),
            'in-progress': new Date().getTime(),
        }
        const update = {
            status: "in-progress",
            statusDates: statusDates
        }
        setDialogBody(<BuildConfirmation title="Are you sure the deposit has been recieved?" body={
           <Column style={{padding: 20}}>
            <Typography variant="caption">By Clicking I Confirm you are accepting the legal conditions involving the contract between DTV and the Agent involved.</Typography></Column>
            
        } 
        okText="I Confirm" onOkClicked={()=>{
            setShowDialog(false);
            sendMessage({
                messageId: shortUUID.generate(),
                senderId: service.agentId,
                reciepientKey: service.id,
                reciepients: [service.uid,'dtv-admin'],
                content: "Your deposit has been confirmed",
                timestamp: new Date().getTime(),
                seenby: [service.agentId]
            });
            sendNotification({
                id: shortUUID.generate(),
                sendTo: service.uid,
                sentFrom: profile.uid,
                type: NotificationTypes.SERVICE,
                title: "Deposit Confirmed",
                message: "Your deposit has been confirmed",
                timestamp: new Date().getTime(),
                seen: false,
                url: `/${service.uid}/ServiceBooking?id=${service.id}`
            })
            updateRequest(serviceId, update).then((res)=>{
                if(res){
                    enqueueSnackbar("Deposit Confirmed", {variant:"success"});
                }else{
                    enqueueSnackbar("Failed to confirm deposit", {variant:"error"});
                }
            })
        }} onCancelClicked={()=>{setShowDialog(false)}
        }/>);
        setShowDialog(true);
     
    }

      
      const BuildDeliverables = ()=>{
        console.log(deliverables);
      return <Grid container spacing={10} style={{padding: 30}}>
        {(deliverables).map((p,i)=>(
          <Grid key={p?.type + i} xs={12} md={6}>
            <Column key = {p+"-"+i} style={{position:"relative",height: p?.type === 'video' || (/(.)+\\.(mp4|mov|mk4)/g).test(p) ? 200 : 300}}>
          <Stack>
             <div style={{opacity:!uploadingMatrix[i] ? 1 : 0.4,height:'100%'}}>
          
              
             <UploadDiv placeholder="" video = {p?.type === 'video' || (/(.)+\\.(mp4|mov|mk4)/g).test(p) } image = { p?.blob??p?.url } onUpload = {(file)=>{
                  setUploadingMatrix({...uploadingMatrix,[i]:false})
                   loadFromDevice(file, (blob)=>{
                        
                            if(typeof blob ==='string' && blob.match('https://|http://')){
                              deliverables[i].url = blob;
                              deliverables[i].type = p?.type ?? "image"
                            } 
                            else
                            {  deliverables[i] = {
                                file:file,
                                blob:blob,
                                url: blob,
                                type: p?.type ?? "image"
                    }}
                    setDeliverables([...deliverables]);
                })}
             } >
                {deliverables[i] === "" ?<Typography>Click to Upload</Typography> : !uploadingMatrix[i] ?<></> : <CircularProgress color="default"/>}
             </UploadDiv>
            
            </div>
            {!uploadingMatrix[i] ? <></> : <LinearProgress variant="determinate" value={progress} />}
            <IconButton style={{height: "",width:""}} onClick={()=>{
                console.log("Hi")
              let newdeliverables = Array.from(deliverables);
              newdeliverables.splice(i,1);
              setDeliverables([...newdeliverables]);
             
            }}><CloseRounded/></IconButton>
           
          </Stack>
         
          </Column> 
         </Grid>
        ))}
       
      </Grid>
      }
      const ActionPanelBody = ()=>{
        switch(status){
            case "delivered":
            return <Column alignment="space-between">
                <Column>
                <Typography>Project Completed</Typography>
                <Typography variant="caption" color="textSecondary">{
                    new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.timestamp))
                    }</Typography>
                <Typography variant="overline" >{`Total Paid: € ${service.amountPaid}`}</Typography>
                
                {
                    service?.review ? <div style={{margin:'1em 0'}}>
                    <Typography variant="overline" >{"Review"}</Typography>
                      <Card variant="outlined" style={{margin:'0.2em 0'}}>
                        <CardContent style={{padding: 8}}>
                        <Column>
                            <Ratings value={agent.reviews[service.review]?.rating} editable={false}/>
                            <div style={{height:10}}/>
                            <Typography variant="body2">{agent.reviews[service.review]?.review}</Typography>
                        </Column>
                        </CardContent>
                        <CardActions>
                          <Typography variant="caption" style={{fontSize: 10}}>{
                            Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(agent.reviews[service.review]?.timestamp))
                          }</Typography>
                        </CardActions>
                      </Card>
                    </div> : <Button onClick={
                      ()=>{
                        setShowReview(true);
                      }
                    } size="small" variant="outlined" >Leave a Review</Button>
                  }
                
                <Typography variant="overline" >{"Deliverables"}</Typography>
                <Typography variant="caption" color="textSecondary">{"The final deliverables for the client"}</Typography>
                <div style={{height:'1em'}}/>
                {service.deliverables?.length > 0 ? <Grid spacing={20} container>
                {
                    service.deliverables.map((deliverable, index)=>{
                        return <Grid key={index} item xs={12} md={6}>
                            <Card style={{height:300,margin:'0 3px'}} variant="outlined">
                                {deliverable.type === "video" ? <video src={deliverable.url} style={{width:'100%', height: 300}}/> : <img alt="deliverable" src={deliverable.url} style={{width:'100%', height: 300,objectFit:'cover'}}/>}
                            </Card>
                            </Grid>
                    })
                }
                </Grid>: <Typography variant="caption" color="textSecondary">{"No Deliverables"}</Typography>}
                </Column>
            </Column>
            case "submitted": case "pending" :
            return <Column alignment="space-between">
                <Column>
                <Typography>Submission recieved from client</Typography>
                <Typography variant="caption" color="textSecondary">{
                    new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.timestamp))
                    }</Typography>
                <Link style={{margin: '1em 0'}} onClick={
                    onReadDescription
                } >Read Description</Link>
                </Column>
                <Row alignment="right">
                    <Button disableElevation size="small" color="primary" variant="contained" onClick={()=>{
                        handleAccept();
                    }}>Accept</Button>
                    <div style={{width:'1em'}}/>
                    <Button disableElevation size="small" style={{borderColor:'red', color: 'red'}} variant="outlined" onClick={()=>{
                        handleReject(); 
                    }}>Reject</Button>
                </Row>
            </Column>
            case "rejected":
            return <Column alignment="space-between">
                <Column>
                <Typography>You Rejected this Project</Typography>
                <Typography variant="caption" color="textSecondary">{
                    new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.timestamp))
                    }</Typography>
                <Typography variant="caprion" >{"Reason you rejected this project:"}</Typography>
                <blockquote style={{color:"#C1C7D0", borderLeft:'0.2em solid #757D87', padding:'0 1em' }}>
                    <Typography variant="caption" style={{fontStyle:'italic'}}>{service.rejectionReason}</Typography>
                </blockquote>
                </Column>
               <Row alignment="right">
               {service.status === "rejected" ? <Button onClick={()=>{
                    
                    const statusDates = {
                         ...service.statusDates,
                         pending: new Date().getTime()
                    }
                    delete statusDates.rejected;
                    const update = {
                         status: "pending",
                         statusDates: statusDates
                    }
                    const message = {
                        messageId: shortUUID.generate(),
                        senderId: service.agentId,
                        reciepientKey: service.id,
                        reciepients: [service.uid, 'dtv-admin'],
                        content: "Your request has been re-opened",
                        timestamp: new Date().getTime(),
                        seenby: [service.agentId]
                    }
                    sendMessage(message);
                    setService({...service, update})
                    updateRequest(serviceId, update).then((res)=>{
                         if(res){
                              enqueueSnackbar("Request Re-Opened", {variant:"success"});
                              
                         }else{
                              enqueueSnackbar("Failed to Re-Open Request", {variant:"error"});
                         }
                    })
               }}
               style={{width: 100}} disableElevation size="small" color="primary" variant="contained">Re-Open</Button>:<></>}
               </Row>
            </Column>
            case "cancelled": case "cancelled-1": case "cancelled-2":
            return <Column alignment="space-between">
                <Column>
                <Typography>Client has decided to cancel this request</Typography>
                <Typography variant="caption" color="textSecondary">{
                    new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.timestamp))
                    }</Typography>
                <Typography variant="overline" >{"Reason for Cancellation"}</Typography>
                <blockquote style={{color:"#C1C7D0", borderLeft:'0.2em solid #757D87', padding:'0 1em' }}>
                    <Typography variant="caption" style={{fontStyle:'italic'}}>{service.cancelReason}</Typography>
                </blockquote>
                </Column>
            </Column>
            case "deposit-processing": 
            return <Column alignment="space-between">
                
                
                { service.completedPayouts?.[service.status] ? 
                 <>
                <Typography>Payout Recieved</Typography>
                <Typography variant="caption" color="textSecondary">{
                    new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
                    }</Typography>
                    <div style={{height:'1em'}}/>
                    <Typography variant="caption" color="textSecondary">{"Please Ensure that the Deposit has been recieved, one confirmed please click 'Confirm Deposit'"}</Typography>
                    {agent.stripeLinkedAccount && <Link target="_blank" href={`https://dashboard.stripe.com/connect/accounts/${ agent.stripeLinkedAccount}/activity`} style={{margin:'3px 0'}}>{
                        agent.stripeLinkedAccount ? "View Account" : ""
                    }</Link>}
                    <div style={{ height: 20 }} />
                    <Button disableElevation size="small" color="primary" variant="contained" onClick={()=>{
                        handleConfirmDeposit();
                    }}>Confirm Deposit</Button>
                    <div style={{width:'1em'}}/>
                    {/* <Button disableElevation size="small" style={{borderColor:'red', color: 'red'}} variant="outlined" onClick={()=>{}}>Reject</Button> */}
                
                </> : 
                    <>
                        <Typography>Deposit recieved from client</Typography>
                        <Typography variant="caption" color="textSecondary">{"We will be sending the deposit to your account as soon as possible, hold tight!"}</Typography>
                    </>
                }
                {
                    service.completedPayouts?.[service.status] ? <></> :
                    <>
                <div style={{ height: 20 }} />
                  <Typography variant="caption" color="textSecondary">
                    {"Deposits are paid out in full to your account within 2-5 days. if you have not recieved your deposit within 5 days please request a payout below."}
                  </Typography>
                  <div style={{ height: 20 }} />
                  <Button disabled={
                    calcDaysPast(service.statusDates[status]) < 5
                  } disableElevation size="small" color="primary" variant="contained" onClick={() => {
                    //sendInvoice();
                    createInvoice();
                  }}>
                    <span>
                    {`Request Payout`}
                    </span>
                  </Button></>}
                  <div style={{ height: 20 }} />
            </Column>
            case "deposit":
              return ProjectActionDeposit({service,profile,openAgreement,servicePackage})
            case "payment": 
            return <Column alignment="space-between">
                <Column>
                <Typography>Payment Requested</Typography>
                <Typography variant="caption" color="textSecondary">{
                    new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
                    }</Typography>
                    <div style={{height:'1em'}}/>
                    <Typography variant="caption" color="textSecondary">{"We have notified the customer to pay the final amount for this project"}</Typography>
                <Typography variant="overline" >{"Payment Deadline"}</Typography>
                <Typography variant="caption" color="textSecondary">{"The maximum time allowed for the client to pay"}</Typography>
                <TextField onChange={
                    (e)=>{
                        service.paymentDeadline = e.target.value;
                        updateRequest(serviceId, service).then((res)=>{
                            if(res){
                                enqueueSnackbar("Payment Deadline Updated", {variant:"success"});
                            }else{
                                enqueueSnackbar("Failed to update Payment Deadline", {variant:"error"});
                            }
                        });
                    }
                } variant="outlined" type="date" defaultValue={new Date().toISOString().split('T')[0]} 
                            InputProps={{
                                inputProps:{
                                    min: new Date().toISOString().split('T')[0],
    
                                }
                            }} InputLabelProps={{shrink: true,}}/>
                <div style={{height:'1em'}}/>
                <Typography variant="overline">{`Extra Charges(${extraCharges.length})`}</Typography>
                <Typography variant="caption" color="textSecondary">{"Only add extra charges when appropiate"}</Typography>
                <div style={{height:'1em'}}/>
                <Divider/>
                <div style={{height:'1em'}}/>
                {extraCharges?.length > 0 ? <Column>
                {
                    extraCharges.map((charge, index)=>{
                        return <Row style={{margin:'10px 0'}} key={index}>
                         
                           
                            <Select variant="outlined" defaultValue={charge.type} style={{width: 200}} onChange={(e)=>{
                               
                                if(extraCharges?.filter((c)=>c.type===e.target.value).length <= 0)
                                 charge.type = e.target.value;
                                setExtraCharges(extraCharges);
                            }}>
                                {<MenuItem value="change_request">{"Change Request"}</MenuItem>}
                                {<MenuItem value="late_payment">{"Late Payment"}</MenuItem>}
                            </Select>
                            <div style={{width:'1em'}}/>
                            <TextField variant="outlined"
                             type="number" defaultValue={charge.amount} style={{width: 200}} onChange={(e)=>{
                                charge.amount = e.target.value;
                                setExtraCharges(extraCharges);
                            }}/>  <div style={{width:'1em'}}/>  <IconButton onClick={()=>{
                                extraCharges.splice(index,1);
                                setExtraCharges(extraCharges);
                            }}><Delete/></IconButton>
                        </Row>
                    })
                }
                <div style={{height:'1em'}}/>
                </Column> : <Typography variant="caption" color="textSecondary">{"No Extra Charges"}</Typography>}
                <div style={{height:'1em'}}/>
                <Row>
                    <Button disabled={extraCharges?.length >= MAX_EXTRACHARGE} disableElevation size="small" color="primary" variant="outlined" onClick={()=>{
                       if(extraCharges?.length < MAX_EXTRACHARGE)
                        extraCharges.push({type: "change_request", amount: 0});
                        console.log(extraCharges, "<<<");
                        setExtraCharges([...extraCharges]);
                    }}>Add Extra Charge</Button>
                    <div style={{width:'1em'}}/>
                    {extraCharges ? <Button disableElevation size="small" color="primary" variant="outlined" onClick={()=>{
                        service.extraCharges = extraCharges;
                        setService(service);
                        updateRequest(serviceId, service).then(()=>{});
                    }}>Apply Extra Charge</Button>:<></>}
                </Row>
                </Column>
                {/* <Row alignment="right">
                    <Button disableElevation size="small" color="primary" variant="contained" onClick={()=>{
                        handleConfirmDeposit();
                    }}>Confirm Payment</Button>
                    <div style={{width:'1em'}}/>
                  
                </Row> */}
            </Column>
            case "payment-processing": 
            return <Column alignment="space-between">
                <Column>
                <Typography>Payment recieved from client</Typography>
                <Typography variant="caption" color="textSecondary">{
                    new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
                    }</Typography>
                    <div style={{height:'1em'}}/>
                    <Typography variant="caption" color="textSecondary">{"Please Ensure that the Full Payment has been recieved and sent to the Agent. once the Agent has confirmed payment. Please Click 'Confirm Payment'"}</Typography>
                </Column>
                <div style={{height:'1em'}}/>
                <Button disableElevation size="small" color="primary" variant="outlined" onClick={()=>{
                    setDeliverables([...deliverables, {url: "", type: "image"}]);
                }}>Add Deliverable</Button>
                <div style={{height:'1em'}}/>
                {deliverables.length <= 0 ?
                <Typography variant="caption" color="textSecondary">{"Please Upload the deliverables for this project"}</Typography>:
                <BuildDeliverables/>}
                <div style={{height:'1em'}}/>
                <Row alignment="right">
                    <Button disableElevation size="small" color="primary" variant="contained" onClick={()=>{
                        handleConfirmPayment();
                    }}>Confirm Payment</Button>
                    <div style={{width:'1em'}}/>
                    {/* <Button disableElevation size="small" style={{borderColor:'red', color: 'red'}} variant="outlined" onClick={()=>{}}>Reject</Button> */}
                </Row>
            </Column>
            case "review": 
            return <Column alignment="space-between">
                <Column>
                <Typography>In Review</Typography>
                <Typography variant="caption" color="textSecondary">{
                    new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
                    }</Typography>
                    <div style={{height:'1em'}}/>
                    {service.requestChange ? <Chip variant="outlined" label="Client Requested Changes" style={{borderColor:'red', color: 'red', width:200}}/> :
                    <></>}
                    <div style={{height:'1em'}}/>
                    <Typography variant="overline">Preview</Typography>
                    <Typography variant="caption" color="textSecondary">{service.previewComment ?? "You left no preview comments"}</Typography>
                    <div style={{height:'1em'}}/>
                    {
                        service.previews?.length > 0 ? <Grid container spacing={3}>
                            {
                                service.previews.map((preview, index)=>{
                                    return <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                        <Card variant="outlined">
                                            <CardContent>
                                                {
                                                    preview.type === "image" ? <img alt="preview" src={preview.url} style={{width:'100%', height:'100%'}}/> : <video style={{width:'100%', height:'100%'}} controls src={preview.url}/>
                                                }
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                })
                            }
                      
                        </Grid>:<Typography variant="caption" color="textSecondary">{"No Preview Uploaded"}</Typography>
                    }
                    <div style={{height:'1em'}}/>
                    <Button disableElevation style={{width: 150}} size="small" color="primary" variant="outlined" onClick={()=>{
                        handlePreview();
                    }}>Add Preview</Button>
                    <div style={{height:'1em'}}/>
                    <Divider />
                    <div style={{height:'1em'}}/>
                    <Typography variant="overline">{"Client Review"}</Typography>
                    <div style={{height:'1em'}}/>
                    <Typography variant="caption" color="textSecondary">{service.reviewComment ?? "Client hasn't left a review yet"}</Typography>
                </Column>
                <Row alignment="right">
                    {service.requestChange ? 
                    <Button disableElevation size="small" color="primary" variant="contained" onClick={()=>{
                        service.requestChange = false;
                        setService(service);
                        updateRequest(serviceId, service).then((res)=>{
                            if(res){
                                enqueueSnackbar("Changes Confirmed", {variant:"success"});
                            }else{
                                enqueueSnackbar("Failed to Confirm Changes", {variant:"error"});
                            }
                        });
                    }}> Confirm Changes </Button> :
                    service.previewApproved ? 
                    <Button disableElevation size="small" color="primary" variant="contained" onClick={()=>{
                        handleRequestPayment();
                    }}> Request Payment </Button> : 
                    <Typography variant="caption" color="textSecondary">{"waiting for the client approval"}</Typography>}
                    <div style={{width:'1em'}}/>
                    {/* <Button disableElevation size="small" style={{borderColor:'red', color: 'red'}} variant="outlined" onClick={()=>{}}>Reject</Button> */}
                </Row>
            </Column>
            case "in-progress": 
            return <Column alignment="space-between">
                <Column>
                <Typography>In Progress</Typography>
                <Typography variant="caption" color="textSecondary">{
                    new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
                    }</Typography>
                    <div style={{height:'1em'}}/>
                    { service.enableTasklist ? <>
                        <Link onClick={()=>{
                            onOpenToDoPanel();
                        }}>View Tasklist</Link>
                    </>  : <>
                    <Typography variant="overline">{"Enable Tasklists"}</Typography>
                    <Typography variant="caption" color="textSecondary">{"This feature allows the client, to see the exact progress of their project."}</Typography>
                    <div style={{height:'1em'}}/>
                    <Button disableElevation size="small" style={{maxWidth: 150}} color="primary" variant="outlined" onClick={()=>{
                        service.enableTasklist = true;
                        setService(service);
                        updateRequest(serviceId, service).then((res)=>{
                            if(res){
                                enqueueSnackbar("Success", {variant:"success"});
                            }else{
                                enqueueSnackbar("Change Failed", {variant:"error"});
                            }
                        });
                    }}>Enable Now</Button>  </>}
                     {
                        //service.enableTasklist ? <ToDoList listId={service.id} viewOnly = {false}/> : <></>
                     }
                     <div style={{height:'1em'}}/>
                </Column>
                <FormControlLabel label={'Lock client from editing'} control={
                    <Checkbox checked={service.lockEdit} onChange={(e)=>{
                        service.lockEdit = e.target.checked;
                        setService(service);
                        updateRequest(serviceId, service).then((res)=>{
                            if(res){
                                enqueueSnackbar("Tasklist Enabled", {variant:"success"});
                            }else{
                                enqueueSnackbar("Failed to Enable Tasklist", {variant:"error"});
                            }
                        });
                    }}/>
                }/>
                <div style={{height:'1em'}}/>
                {service.enableTasklist && serviceTodoList?.filter((e)=>!e.completed).length > 0 ? <Typography variant="caption" color="textSecondary">{"Please ensure that all tasks are completed before marking this project as completed"}</Typography>:<></>}
                <div style={{height:'0.5em'}}/>
                    <Button disabled={service.enableTasklist && serviceTodoList?.filter((e)=>!e.completed).length > 0} disableElevation size="small" color="primary" variant="contained" onClick={()=>{
                        handlePreview();
                    }}>Job Completed</Button>
                    <div style={{width:'1em'}}/>
                    {/* <Button disableElevation size="small" style={{borderColor:'red', color: 'red'}} variant="outlined" onClick={()=>{}}>Reject</Button> */}
                
            </Column>
            default: return <></>
        }
    }
    const canceled = service ? (service.status === "cancelled" ||
    service.status === "cancelled-1" || service.status === "cancelled-2"
    ):false;
   return <>
     <Row style={{flexWrap: 'wrap'}}>{
           service.status === "rejected" || canceled ? <></> : service.status !== "delivered"  ? <Chip style={{
                borderColor: getStatusColor(service.status),
                color: getStatusColor(service.status),
                margin: 2
            }} variant="outlined" label={
                service.status === "in-progress" ? "Dual Action" :
                SiteData?.enableServiceAccounts && 
                (service.status === "deposit-processing" || service.status === "payment-processing") &&
                !service.completedPayouts ? "Awaiting Admin Action" :
                (/^(pending)|(deposit-processing)|(in-Progress)|(payment-processing)/g).test(service.status) ? "Action Required":"Awaiting Client Action"
            }/> : <></>}
            <div style={{width:'1em'}}/>
            {service.status !== "delivered" && service.deadline ? <Chip style={{
                borderColor: new Date(service.deadline) < new Date() ? '#ff6f6f' : '#00ceff',
                color: new Date(service.deadline) < new Date() ? '#ff6f6f' : '#00ceff',
                margin: 2
            }} variant="outlined" label={<Typography variant="caption">
                {`Deadline: ${new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.deadline??0))}`}
            </Typography>}/>:<></>}
    
                </Row><div style={{height:'1em'}}/>
   {<ActionPanelBody/>}
    
   <Dialog open={showDialog} onClose={()=>{
    setShowDialog(false);
  }}>
        <DialogContent>{dialogBody??<></>}</DialogContent>
  </Dialog>
  
 
  <Dialog open={uploading}>
    <DialogContent>
        <Column style={{alignItems:'center'}}>
            <CircularProgress/>
            <div style={{height:'1em'}}/>
            <Typography variant="caption">{"Uploading Preview Media"}</Typography>
            <div style={{height:'1em'}}/>

        </Column>
    </DialogContent>
  </Dialog>
   </>
  }
  export default ProjectAction;

  ProjectAction.propTypes = {
    agent: PropTypes.object,
    service: PropTypes.object,
    servicePackage: PropTypes.object,
    openAgreement: PropTypes.func,
    onOpenToDoPanel: PropTypes.func,
    onOpenCommunicationPanel: PropTypes.func,
    onReadDescription: PropTypes.func
}