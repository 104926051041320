import React from 'react';
import '.././utils.css';

import PropTypes from "prop-types";

function Row(props) {
    const {
        alignment = "space-evenly",
        className = "",
        id,
        style,
        onClick = () => {}
    } = props;

    return (
        <div id={id} className={`${alignment}-row ${className}`} style={style} onClick={onClick}>
            {props.children}
        </div>
    );
}

Row.propTypes = {
    alignment: PropTypes.oneOf(['space-evenly', 'space-between', 'centered', 'left', 'right']),
    className: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.node
};

export default Row;
