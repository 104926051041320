/* eslint-disable react-hooks/exhaustive-deps */

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, ListItem, ListItemText } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Column from "../Column";
import { OrganisationService } from "../../services/organisationService";
import {useProfile} from "../../hooks/useProfile"
import { useDispatch } from "react-redux";
import { setCurrentOrganisation } from "../../reducer/organisationReducer";
import PropTypes from 'prop-types'

const OrganisationPicker = ({showModal,setShowModal, subtitle, onAcceptedCallback, filter})=>{
    
    const [loading,setLoading] = useState(false);
    const {retrieveProfileWithCallback} = useProfile();
    const {getUserOrganisations} = OrganisationService();
    const [organisations, setOrganisations] = useState([]);
    const dispatch = useDispatch();
    
    useEffect(()=>{
          retrieveProfileWithCallback((profile)=>{
            setLoading(true);
            getUserOrganisations({userId: profile.uid}).then((response)=>{
                const {data} = response;
                let organisationFor = data ?? [];
                if(filter){
                    organisationFor = data.filter(o=>o.org_type.includes(filter));
                }
                setOrganisations(organisationFor??[]);
                setLoading(false);
            }).catch((error)=>{
                console.log(error);
            });
          });
    },[]);
   
    const handleLeave = ()=>{
        setShowModal(false);
    }
    const handleSelectedOrg = (orgId)=>{
        dispatch(setCurrentOrganisation(orgId));
        onAcceptedCallback(orgId);
        setShowModal(false);
    }
    return (
        <div>
            <Dialog maxWidth="sm" fullWidth open = {showModal} onClose={
                ()=>setShowModal(false)
            
            } >
                {loading ? <DialogContent>
                    <Column><CircularProgress /></Column>
                </DialogContent> :
                <>
                <DialogTitle>Select an Organisation</DialogTitle>
                <DialogContent>{subtitle}</DialogContent>
                <DialogContent>
                    {
                        loading ? <Column style={{alignItems:"center", width: "100%", height:"100%"}}><CircularProgress /></Column> :
                        <Column>
                            {
                                organisations.map((organisation)=>{
                                    return <ListItem button onClick={()=>handleSelectedOrg(organisation.orgId)} key={organisation.id}>
                                        <ListItemText primary={organisation.name} secondary={`@${organisation.alias}`} />
                                    </ListItem>
                                })
                            }
                            <ListItem button onClick={()=>handleSelectedOrg("freelance")}>
                                <ListItemText primary="Freelance" secondary="No Organisational Ties"/>
                            </ListItem>
                        </Column>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleLeave}>Leave</Button>
                    {/* <Button variant="contained" color = "primary" onClick={handleLogin}>Login</Button> */}
                </DialogActions></>
                }
            </Dialog>
        </div>
    )
}
export default OrganisationPicker;

OrganisationPicker.propTypes = {
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func,
    subtitle: PropTypes.string,
    onAcceptedCallback: PropTypes.func,
    filter: PropTypes.string
}