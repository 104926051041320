import axios from 'axios';
import { baseUrl,RouteNames } from '../config/api';

export const MediaService = ()=>{

    const upload = async (objectParams = { key: "", file: new File() }, uploadingCallback, errorCallback) => {
        
        try {
            const data = new FormData();
            let result = "";
            data.append('file', objectParams.file, objectParams.file.name);
            data.append('objectParams', JSON.stringify(objectParams),);
            const response = await axios
                .post(`${baseUrl}/${RouteNames.media}`, data, {
                    onUploadProgress: ProgressEvent => {
                        let value = (ProgressEvent.loaded / ProgressEvent.total * 100);
                        console.log(value);
                        uploadingCallback(value)
                    },
                })
            if (response.status < 200 || response.status >= 300) {
                console.log("An error has occured: " + response.status);
                errorCallback(response.data);
                throw new Error(response.statusText);
            }
            console.log("File uploaded successfully", response.data.data);
            result = response.data.data?.url;
            console.log("WE GOOD!", result);
            return result;
        } catch (e) {
            console.log(e);
            errorCallback(e);
            throw new Error(e);
        }
    }
    const download = async (url) => {
        const request = {
            method: 'get',
            url: url,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return {
                data: response.data,
                status: 200
            }
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        upload,
        download
    }
}