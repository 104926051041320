/* eslint-disable react-hooks/exhaustive-deps */

import { Badge, Button, Chip, List, Typography, useTheme } from '@material-ui/core';
import { PaymentRounded, ShoppingBasketRounded } from '@material-ui/icons';
import { motion } from 'framer-motion';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import firebase_app from '../config/firebase';
import { getCartItems } from "../logic/databaseHandler";
import Column from './Column';
import Row from './Row';
import CartItem from "./_cart_item";
import { useProfile } from '../hooks/useProfile';

function Cart(props){
    const [,setTempId] = useState("none");
    const { retrieveProfileWithCallback } = useProfile();
    const [profile,setProfile] = useState(null);
    const history = useNavigate()
    const theme = useTheme();
    const [currentFilter,setCurrentFilter] = useState("All");
    const [filters, ] = useState(["All","Bookings","Submissions"])
    const [items,updateItems] = useState(0);
    const [showMenu, setOpenMenu] = React.useState(0);
    const [itemsObject, updateObjs] = useState({
        bookings:[]
    })
    const getCarts  = async()=>{
        
      let id= profile?.uid??firebase_app.auth()?.currentUser?.uid;
      console.log(id);
            if(id){
                console.log("getting cart..");
                getCartItems(id,(obj,n)=>{
                updateItems(n);
                updateObjs(obj);
            })
        }
        
       
    }
    const _buildQuickView = ()=>{
        
        return <Column alignment="space-between"style={{width:360,minHeight:"360px",maxHeight:"660px",color:"white",padding:"20px 0"}}>
            <Row alignment="space-between">
                    <Typography style={{}} variant="overline">Your DTV Cart</Typography>
                    <Row>
                        {
                            filters.map((v,i)=>{
                                return <motion.div  key ={v+i} whileHover={{scale:1.1,cursor:"pointer"}}><Chip onClick={()=>{
                                    setCurrentFilter(v);
                                }} variant={v !== currentFilter ? "outlined":"default"} color="primary" size="small" style={{margin:"0 5px",cursor:"pointer"}} label={v}/></motion.div>
                            })
                        }
                    </Row>
            </Row>
            <div style={{height:"20px"}}/>
           <Column alignment="start" style={{width:"100%",overflowY:"auto",minHeight:"360px",maxHeight:"500px",color:"white"}}>
           <List
      sx={{ width: '100%',bgcolor: 'background.paper' }}
    >
        {
           (currentFilter !== "All" ? itemsObject[currentFilter.toLowerCase()]??[] : Object.values(itemsObject).flat()).map((v,key)=>{
                return <CartItem key ={v+key} item={v} hidePrice={props.hidePrice} onDelete={()=>{
                    console.log("Deleted");
                    getCarts()
                }} />
            })
        }
        {Object.values(itemsObject).filter((v)=>v.length > 0).length === 0 ? <Typography variant="overline">No items in cart</Typography> : <></>}
      </List>
           </Column>
           <div style={{height:"40px"}}/>

           {Object.values(itemsObject).filter((v)=>v.length > 0).length > 0 ?  <Row alignment="right">
               <Button onClick={()=>{
                   if(props.enableCheckoutPage){
                    history(`/payment?type=cart&id=${profile?.uid ?? firebase_app.auth()?.currentUser.uid }`); 
                    // history({ pathname: "/checkout", state: { uid: profile?.uid ?? firebase_app.auth()?.currentUser.uid } })
                   }
               }} variant="contained" startIcon={<PaymentRounded/>} color="primary">Checkout</Button>
           </Row>:<></>}
        </Column>
    }
    useEffect(()=>{
         retrieveProfileWithCallback((p)=>{
                setTempId(p.uid);
                setProfile(p);
                getCarts()
            });
    },[])
    if(items<=0)
        return <></>
    return profile?.uid ? (
        <div style = {{position:"relative",}}>
           <motion.div style={{cursor:"pointer",color:props.color??"white"}} 
           onClick={
               ()=>{
                if(props.enableClick) setOpenMenu(!showMenu)
               }
           }
            onMouseEnter = {()=>{
               if(props.enableHover) setOpenMenu(items>0)
              }}
              onMouseLeave = {()=>{
                if(props.enableHover) setOpenMenu(false)
              }}
           initial={{color:props.color??"white"}} whileHover={{scale:1.1,color:`${theme.palette.primary.main}`}}>
        <Badge badgeContent={items}>
            <ShoppingBasketRounded/>
        </Badge>
        </motion.div> 
        <motion.div 
          onMouseEnter = {()=>{
            if(props.enableHover)setOpenMenu(items>0)
          }}
          onMouseLeave = {()=>{
            if(props.enableHover) setOpenMenu(false)
          }}
        initial={{opacity:0,x:0,y:5}}
        animate={{opacity:showMenu ? 1 : 0,y:5,x: showMenu ? -360 : 0}}
        exit={{opacity:0,x:0}}
         transition={{type: "spring", ease: "easeIn", duration: 0.8 }}
            style={{position:"absolute", padding:"5px 10px",backgroundColor:"#131313",borderRadius:4,zIndex:!showMenu ? "3000":"3000",boxShadow:"0px 0px 15px 1px rgba(0, 0, 0, 0.24)"}}>
                {_buildQuickView()}
         </motion.div>
        </div>
        
    ) : <></>
}

Cart.propTypes = {
    color:PropTypes.string,
    enableHover:PropTypes.bool,
    enableClick:PropTypes.bool,
    enableCheckoutPage:PropTypes.bool,
    hidePrice:PropTypes.bool

}
export default Cart