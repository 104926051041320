/* eslint-disable react-hooks/exhaustive-deps */

import { CircularProgress, Dialog, alpha, FilledInput, Grid, IconButton, InputAdornment, makeStyles, Typography, useTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ChevronLeft, ChevronRight, Search } from '@material-ui/icons';
import Pagination from '@material-ui/lab/Pagination';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useNavigate } from 'react-router';
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Center from '../components/Center';
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { AppBarModule } from '../components/_app_bar_mod';
import { FooterModule } from '../components/_footer_mod';
import { VideoCard } from '../components/_video_card_mod';
import { SiteDataContext } from '../config/SiteData';
import { useLocation } from "react-router-dom";
import { ReleasesContext } from '../contexts/ReleasesProvider';
import "../index.css";
import { parseUrlForQueryParams } from '../utils';
const useStyles = makeStyles((theme) => ({
  
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    },
    '& label.Mui-focused': {
      color: '#e49726',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#e49726',
    },
    '& .MuiFilledInput-underline:after': {
      borderBottomColor: '#e49726',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#b77514',
      },
      '&:hover fieldset': {
        borderColor: '#b77514',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#e49726',
      },
    },
    '& .Mui-selected': {
      backgroundColor: 'rgba(255, 255, 255, 0.24)',
      color: '#e49726',
      borderColor: "#e49726"
    },
    //  '& .MuiPaginationItem-root':{
    //    border:"1px solid rgba(255, 255, 255, 0.23)",
    //    color:"white",
    //    '&:hover':{
    //     backgroundColor: 'rgba(255, 255, 255, 0.14)',
    //     border:"1px solid rgba(255, 255, 255, 0.53)",
    //     cursor:"pointer"
    //    }
    //  },
    '& .MuiCircularProgress-colorPrimary': {
      color: "white"
    }

  },
  iconBtn: {
    color: alpha(theme.palette.text.primary, 0.5),
    '&:hover': {
      color: alpha(theme.palette.text.primary, 1)
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer',
      color: "white"
    }
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  textField: {
    '&focus': {
      borderBottomColor: '#e49726'
    }
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  pagination: {
    color: "white"
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const ReleasePage = () => {
  const {
    SiteData,
  
  } = useContext(SiteDataContext)
  const history = useNavigate();
  const classes = useStyles();
  const location = useLocation();
  const theme = useTheme();
  const [device, setDevice] = useState('pc');
  const [, setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [search, setSearch] = useState('');
  const [searchResults, showSearchResults] = useState(false);
  const [videoPage, showVideoPage] = useState(false);
  const [currentSlide, ] = useState(0);
  const [, setLoadResults] = useState(false);
  const [maxPageSize, ] = useState(SiteData.releasesPage.maxVideoPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [, setCurrentVideo] = useState(1);

  const [q_maxPageSize, ] = useState(SiteData.releasesPage.maxVideoPerPage);
  const [q_currentPage, setQCurrentPage] = useState(1);

  const slideRef = useRef(null);
  const searchRef = useRef(null);
  const query = parseUrlForQueryParams(window.location.href);
  const {
    youtubeVideos,
    maxVideoSize,
    maxQueryVideoSize,
    queryResults,
    loading,
    getYoutubeVideos,
    getMaxQueryVideoSize,
    queryYoutubeVideos

  } = useContext(ReleasesContext);

  useEffect(() => {
    //refreshSiteData()
    console.log(query?.q)
    if (location.pathname.includes('search')) {
      showSearchResults(location.state.showSearch);
      setSearch(query?.q ?? location.state.search);
      showQueryPage(1, location.state.search);
      searchRef.current.value = query?.q ?? location.state.search;
    } else if (location.pathname.includes('video-')) {
     
      showVideoPage(true);
      setCurrentVideo(location.state?.currentVideo ?? JSON.parse(localStorage.getItem('currentVideoToView')) ?? 1);
      localStorage.removeItem('currentVideoToView');
    } else {
      showPage(1);
    }
    updateDevice();

    window.addEventListener("resize", updateDevice);
  }, [location.pathname])

  const handlePageChange = async (e, page) => {
    setCurrentPage(page);
    await showPage(page);
  }
  const q_handlePageChange = async (e, page) => {
    setQCurrentPage(page);
    await showQueryPage(page, location.state.search);
  }
  const showPage = async (page) => {
    var x = (page * maxPageSize) - (maxPageSize - 1);
    await getYoutubeVideos(x, page * maxPageSize);
  }
  const showQueryPage = async (page, s) => {
    var x = (page * q_maxPageSize) - (q_maxPageSize - 1);
    //  alert(s)
    await queryYoutubeVideos(s, x, page * q_maxPageSize);
    setLoadResults(false)
  }
  const updateDevice = () => {
    if (window.innerWidth < 550) {
      setDevice('mobile');
      setDrawerWidth(300)
    }
    else {
      setDevice('pc')
      setDrawerWidth(280)
    }
  }

  const _buildPage = (() => {
    //...
    return <Column alignment="start" style={{ height: "100%" }}>
      <div style={{ width: "100%" }} onMouseEnter={() => { slideRef.current.muted = true; }} onMouseLeave={() => { slideRef.current.muted = false; }}>
        <Slider
          dots={false}

          slidesToShow={1}
          slidesToScroll={SiteData.releasesPage.featured.length}
          autoplay={true}
          infinite={true}
          afterChange={() => { }}
          onSwipe={() => { }}
          autoplaySpeed={3000}
          adaptiveHeight={true}
        ><div style={{ width: '100%', height: '100%' }}>
            {
              SiteData.releasesPage.featured.map((data, i) =>
                <div key={i} className="video-wrapper" style={{ width: '100%', height: '350px' }} onClick={() => {
                  history(`video-${data.id}`, {state: { showVideo: true, currentVideo: data }} )
                }} >
                  <ReactPlayer playsinline controls={true} config={{ youtube: { playerVars: { showinfo: 1 } } }} className="react-player" loop={true} playing={currentSlide === i} muted={true} ref={slideRef} width='100%' height='100%' url={`https://www.youtube.com/watch?v=${data.id}`} />
                </div>)
            }
          </div>

        </Slider>
      </div>
      {  loading ? <Column style={{
        width:'100%',
        height: '100%',
        alignItems: 'center',
      }}><CircularProgress/> 
      </Column> : <div style={{ width: "100%" }}>

        <Grid container spacing={6} style={{ width: "100%", margin: device === 'pc' ? "0" : "1% 0 10px 0px", padding: device === 'pc' ? "1% 6% 10% 6%" : "0" }}>
          <Grid xs={12} style={{ padding: device === 'pc' ? "0" : "10px 0px 10px 0px" }}>
            <Row alignment="space-between" style={{ width: "100%" }}>
              {device !== 'pc' ? <></> : <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 1), fontSize: "16px" }}>{SiteData.youtubeChannelName} videos</Typography>}
              <Column style={{ marginTop: device !== "pc" ? "20px" : "" }} alignment="centered">
                <Row style={{ width: "100%" }} alignment={device === "pc" ? "right" : "centered"}>
                  <IconButton className={classes.iconBtn} onClick={(e) => {
                    if (currentPage > 1)
                      handlePageChange(e, currentPage - 1)
                  }}><ChevronLeft /></IconButton>
                  <Column alignment="centered">
                    <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 1), fontSize: "12px" }}>Showing {((currentPage * maxPageSize) - maxPageSize) + 1} - {(currentPage * maxPageSize)} Videos</Typography>

                  </Column>
                  <IconButton className={classes.iconBtn} onClick={(e) => {
                    if (currentPage < Math.max(Math.floor(maxVideoSize / maxPageSize), 1))
                      handlePageChange(e, currentPage + 1)
                  }}><ChevronRight /></IconButton>
                </Row>
              </Column>

            </Row>

          </Grid>
          {

            youtubeVideos.map((youtube, index) => {
              return <Grid xl={2} lg={3} md={4} sm={6} xs={12} key={"youtube_v_" + index}>
                <div style={{ width: device === 'pc' ? "100%" : "100%", height: device === 'pc' ? "300px" : "350px", padding: device === 'pc' ? "10px" : "10px 0" }}>
                  <VideoCard device={device} borderRadius="6px" onClick={() => {
                    history(`video-${youtube.id}`, { state: { showVideo: true, currentVideo: youtube } })
                    //window.location.reload();
                  }} fontSize={device === "pc" ? window.innerWidth * 0.012 : '2.4vh'} youtubeData={youtube} cardColor={theme.palette.type === "dark" ? "#232323" : "#ffffff"} subtitleColor={alpha(theme.palette.text.primary, 0.5)} titleColor={alpha(theme.palette.text.primary, 1)} hoverPlay={true} />
                </div>

              </Grid>
            })
          }
          {device === "pc" ? <></> : <Grid xs={12}>
            <Row alignment="centered" style={{ width: "100%", height: device === "pc" ? "" : "100px", margin: "30px 0" }} >
              <Pagination onChange={handlePageChange} count={Math.max(Math.floor(maxVideoSize / maxPageSize), 1) + ((maxVideoSize % maxPageSize) === 0 ? 0 : 1)} page={currentPage} variant="outlined" />
            </Row>
          </Grid>}
        </Grid>
      </div>}

    </Column>
  });
  const _buildVideoPage = () => {
    return <Stack style={{ width: "100%", height: "100%", background: "black" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <ReactPlayer playsinline controls={true} config={{ youtube: { playerVars: { showinfo: 1 } } }} url={`https://www.youtube.com/watch?v=${location.pathname.split("-")[1]}`} height="100%" width="100%" />
      </div>
    </Stack>
  }
  const _buildSearchResults = () => {
    return <>
      <Column alignment={queryResults.length < 1 ? "centered" : "start"} style={{ padding: device === 'pc' ? "12%" : "", height: "100%" }}>
        <motion.label initial={{ x: -30, opacity: 0.3 }} animate={{ x: 0, opacity: 0.8 }} style={{ textAlign: "center", fontSize: "30px", color: alpha(theme.palette.text.primary, 0.65) }}>{`Search for "${search}" `}
        </motion.label>
        <motion.div >
          { loading ? <Column style={{
        width:'100%',
        height: '100%',
        alignItems: 'center',
      }}><CircularProgress/> 
      </Column> : <></>}
          {queryResults.length < 1 ? <>
            <Column alignment="centered" style={{ height: "100%" }}>
              <motion.label initial={{ y: -30, opacity: 0.0 }} animate={{ y: 0, opacity: 0.3 }} style={{ textAlign: "center", fontSize: "30px", color: alpha(theme.palette.text.primary, 0.5) }}>{`No Results Found`}
              </motion.label>
            </Column>
          </> : <Grid container spacing={6} style={{ width: "100%", height: "100%", margin: device === 'pc' ? "5% 0 0 10px" : "20% 0 10px 0px", padding: device === 'pc' ? "0 0 5% 0" : "0" }}>
            {

              queryResults.map((youtube, index) => {
                return <Grid xl={2} lg={3} md={4} sm={6} xs={12} key={"youtube_v_" + index}>
                  <motion.div initial={{ y: -30, opacity: 0.1 }} animate={{ y: 0, opacity: 1 }} transition={{ type: "tween", easings: "linear", duration: 0.1, delay: index / 10 }}>
                    <div style={{ width: device === 'pc' ? "100%" : "100%", height: device === 'pc' ? "350px" : "350px", padding: device === 'pc' ? "10px" : "10px 0" }}>
                      <VideoCard device={device} borderRadius="6px" onClick={() => {
                        localStorage.setItem("currentVideoToView", JSON.stringify(youtube));
                       window.location.href = `${window.location.origin}/releases/video-${youtube.id}`;
                       // history(`video-${youtube.id}`, { state: { showVideo: true, currentVideo: youtube } })
                      }} fontSize={device === "pc" ? window.innerWidth * 0.01 : '1.4vh'} youtubeData={youtube} cardColor={theme.palette.type === "dark" ? "#232323" : "#ffffff"} subtitleColor={alpha(theme.palette.text.primary, 0.5)} titleColor={alpha(theme.palette.text.primary, 1)} hoverPlay={true} />
                    </div></motion.div>

                </Grid>
              })
            }
            {device === "pc" ? <></> : <Grid xs={12}>
              <Row alignment="centered" style={{ width: "100%", margin: "30px 0" }} >
                <Pagination onChange={q_handlePageChange} count={Math.max(Math.floor(maxQueryVideoSize / q_maxPageSize), 1) + ((maxQueryVideoSize % q_maxPageSize) === 0 || ((maxQueryVideoSize % q_maxPageSize)) === maxQueryVideoSize ? 0 : 1)} page={q_currentPage} variant="outlined" />
              </Row>
            </Grid>}
          </Grid>}
        </motion.div>
      </Column>
    </>
  }
  const handleSearch = async () => {
    setSearch(searchRef.current.value)
    if (searchRef.current.value === '')
    history(`/releases`)
    else if (!SiteData.releasesPage.searchOption.liveResults && location.pathname === '/releases') {
      history( `search?q=${searchRef.current.value}`, {state: { showSearch: true, search: searchRef.current.value } })
    } else {
      await getMaxQueryVideoSize(searchRef.current.value)
      setLoadResults(true);
      setQCurrentPage(1);
      showQueryPage(1, searchRef.current.value)
      if (searchRef.current.value === '') {
        showSearchResults(false)
      }
      else {
        showSearchResults(true)
      }
    }

  }
  const handleSearchSuggestions = async (evt) => {
    if (SiteData.releasesPage.searchOption.liveResults)
      await handleSearch(evt.target.value);
  }
  const _buildSearchBar = () => {
    return <div style={{ width: "80%" }}>
      <Center>
        <FilledInput
          id="outlined-adornment-password"
          type={'text'}
          className={classes.textField}
          defaultValue={search}
          fullWidth
          placeholder="Search"
          inputRef={searchRef}
          inputProps={{
            style: {
              color: alpha(theme.palette.text.primary, 1),
              background: theme.palette.type === "dark" ? "#343434" : "#f1f1f1",
              paddingTop: "15px",
              borderBottomColor: "red"
            }
          }}
          onKeyUp={handleSearchSuggestions}
          endAdornment={
            <InputAdornment position="end" >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleSearch}
                edge="end"
                className={classes.iconBtn}
              ><Search />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </Center>
    </div>
  }
  return (<div className={classes.root}>
    <CssBaseline />
    <Stack style={{ maxHeight: "100%", height: "100%", width: "100%", overflow: "hidden" }}>
      <div style={{ background: theme.palette.type === "dark" ? "#0C0C0Cf1" : "#ffffff", height: "100%", width: "100%" }}></div>
      <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 20%,${alpha(theme.palette.background.paper, 0.5)} 54%, transparent)`, height: "100%", width: "100%" }}></div>
      {/* <FooterModule device={device} hideBranding={false}/> */}
      <div style={{ margin: searchResults ? "" : "66px 0 0 0", height: "100%", width: "100%", maxHeight: "100%", overflowY: "auto" }}>{
      videoPage ? _buildVideoPage() : searchResults ? _buildSearchResults() : _buildPage()
      }</div>
      {device === "pc" && !videoPage ? <FooterModule style={{ sheight: "0" }} customFoot={
        !searchResults ? <Row alignment="centered" style={{ width: "100%", height: device === "pc" ? "" : "100px",background: "black", padding: "5px 0" }} >
          <Pagination onChange={handlePageChange} count={Math.max(Math.floor(maxVideoSize / maxPageSize), 1) + ((maxVideoSize % maxPageSize) === 0 ? 0 : 1)} page={currentPage} variant="outlined" />
        </Row> : <Row alignment="centered" style={{ width: "100%",background: "black", padding: "5px 0" }} >
          <Pagination onChange={q_handlePageChange} count={Math.max(Math.floor(maxQueryVideoSize / q_maxPageSize), 1) + ((maxQueryVideoSize % q_maxPageSize) === 0 || ((maxQueryVideoSize % q_maxPageSize)) === maxQueryVideoSize ? 0 : 1)} page={q_currentPage} variant="outlined" />
        </Row>
      } /> : null}
    </Stack>
    {<AppBarModule  showCart pageIndex={5} appbarBackground={theme.palette.type === "dark" ? "#232323" : "#ffffff"} centerModule={_buildSearchBar()} />}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
  </div>)


}
export default ReleasePage;