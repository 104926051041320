import React from 'react';
import PropTypes from 'prop-types';

const Stack = (props) => {
    const {
        alignment = "",
        style,
        startLayer = 100,
        children
    } = props;


    let tf = startLayer ?? 100;

    return (
        <div
            style={{
                ...style,
                position: "absolute",
                height: style?.height ? style.height : "100%",
                width: style?.width ? style.width : "100%",
            }}
            className={`jsk-stack jsk-stack-align-${alignment}`}
        >
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
                {React.Children.map(children, (elem, i) => {
                    return elem !== null ? (
                        <div
                            style={{
                                pointerEvents: elem.props?.style?.pointerEvent ?? "all",
                                position: "absolute",
                                zIndex: tf++,
                                width: "100%",
                                height: elem.props?.style?.sheight ?? "100%",
                            }}
                            key={elem.key ? elem.key : 'stack_' + i}
                        >
                            {elem}
                        </div>
                    ) : <></>;
                })}
            </div>
        </div>
    );
};



Stack.propTypes = {
    element: PropTypes.element,
    startLayer: PropTypes.number,
    children: PropTypes.node,
    alignment: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func
};

export default Stack;