import React from 'react';
import Row from './Row';
import Column from './Column';
import PropTypes from 'prop-types';


const Center = (props) => {

    return (
        <div style={props.style} className={props.className} {...props}>
            {props.align === 'row' ? (
                <Row alignment="centered" style={{ height: "100%", width: "100%", alignItems: "center" }}>
                    {props.children}
                </Row>
            ) : (
                <Column alignment="centered" style={{ width: "100%", height: "100%", alignItems: "center" }}>
                    {props.children}
                </Column>
            )}
        </div>
    );
};


export default Center;

Center.propTypes = {
    align: PropTypes.oneOf(['row', 'column']),
    elements: PropTypes.array,
    style: PropTypes.object,
    className: PropTypes.string,
    children: PropTypes.node
};