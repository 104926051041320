
import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Stack from '../components/Stack';

import CssBaseline from '@material-ui/core/CssBaseline';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { SiteDataContext } from '../config/SiteData';
import "../index.css";
import backgroundVideo from '../assets/backgroundVideo.mp4';

import { useNavigate } from 'react-router';
import { AppBarModule } from '../components/_app_bar_mod';
import { FooterModule } from '../components/_footer_mod';
import Column from '../components/Column';


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background:"black",
        overflowY:'hidden',
        maxWidth:'100%',
        flexGrow: 1,
        '& .MuiDivider-root':{
            backgroundColor:'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root':{
            color: 'white'
        }
      },
      iconBtn:{
        '&:hover': {
            color:"white"
        },
      },
      sideBarSocials:{
          color:"#ffffff47",
          fontSize:"12px",
        '&:hover': {
            color:"white"
        },
      },
      divider:{
          backgroundColor: "rgba(1,1,1, 1)"
      },
      center:{
        textAlign:"center",
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      gridList: {
        width: 500,
        height: 450,
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
      toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
     
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        padding:"3px 1px",
        position:"fixed",
       // background:"linear-gradient(45deg, black, #d09612bd);",
        background: "transparent",
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      socialIcon:{
        color:"#ffffff47",
        '&:hover': {
            color:"white"
        },
      },
      menuButtonHidden: {
        display: 'none',
      },
      title: {
        flexGrow: 1,
      },
      listItem:{
        backgroundColor: "#2C2C2C",
        color: "white",
        margin:10
      },
      listItemImage:{
        height:"60px",
        width:"60px",
        margin:"10px"
      },
     list:{
        transition: theme.transitions.create(["background", "background-color"], {
            duration: theme.transitions.duration.complex,
          }),
          "&:hover": {
            backgroundColor: "#6363638c",
            color:"black",
          },
     },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      grow:{
          '&:hover':{
            transform: 'scale(1.1)',
            easing:"linear",
            cursor: 'pointer'
          }
      },
      link:{
          color:"grey",
        '&:hover':{
            transform: 'scale(1.1)',
            cursor: 'pointer',
            color:'white'
          }
      },
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
      mobileNav:{
          width:"60%"
      },
      pcNav:{
          width:"30%"
      },
      logo:{
          height:"60px",
          width:"60px"
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.text.primary,
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
      fixedHeight: {
        height: 240,
      },
  }));


const PageNotFound = ()=>{
  const {
    SiteData,
  } = useContext(SiteDataContext)
  const history = useNavigate();
  const classes = useStyles();
  
  const [device,setDevice] = useState('pc');
  const [,setDrawerWidth] = useState("30%");

    useEffect(()=>{
        updateDevice();
        window.addEventListener("resize", updateDevice);
    },[])
  const updateDevice = ()=>{
      if(window.innerWidth<550)
      {
          setDevice('mobile');
          setDrawerWidth(300)
      }
      else {
          setDevice('pc')
          setDrawerWidth(280)
        }
  }

  const _buildPage = (()=>{
      return <Column className = "centered">
        <Typography variant={"overline"} style={{fontSize:"2.5vw",fontWeight:"lighter",fontFamily:""}}>{"This Page is Unavailable"}</Typography>
        <Typography variant={"caption"} >{"Sorry for the Inconvience, but it seems that this page is not available right now"}</Typography>
        <Typography variant={"caption"} >{"This could be because of Maintance or an invalid url."}</Typography>
        <Typography variant={"caption"} >{"Please feel free to browse our entire site"}</Typography>
        <div style = {{height:20}}/>
        <Button onClick={()=>{
            history("/",{replace:true})
        }}>{SiteData.companyName}</Button>
      </Column>
  });

 return (<div className={classes.root}>
         <CssBaseline />
        <Stack>
            <div style={{height:"100%", background:"black",width:"100%"}}>
              <video playsInline src={backgroundVideo} 
                autoPlay={true}
                loop={true}
                muted={true}
                    style={{height:"100%", width:"100%", objectFit: "cover"}} 
                    className="react-player" ></video>
            </div>
            <div style={{background:"#0000000d", height:"100%", width:"100%"}}></div>
            <div style={{background:"linear-gradient(0deg, black 50%,#0000009c 104%, transparent)",height:"100%", width:"100%"}}></div>
            <FooterModule device={device} hideBranding={false}/>
           {_buildPage()}
        </Stack>
        {<AppBarModule showCart pageIndex={-1}/>}
    
    </div>
    
    )

   
}
export default PageNotFound;