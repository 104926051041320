/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import Column from "../components/Column";
import { AppBarModule } from "../components/_app_bar_mod";
import { useDatabase } from "../hooks/useDatabase";
import { Button, Divider, Grid, Typography, useTheme } from '@material-ui/core';
import { motion } from 'framer-motion';
import { useSiteData } from "../config/SiteData";
import Stack from "../components/Stack";
import ReactPlayer from "react-player";
import { Ribbon, RibbonContainer } from "react-ribbons";
import backgroundVideo from '../assets/backgroundVideo.mp4';
import banner from '../assets/banner.png';
import Row from "../components/Row";
import { useStyles } from "../utils";
import Logic from "../logic/logic";
import FutureBuilder from "../components/_future_builder";
import { CheckCircleOutlineRounded, HighlightOffRounded } from "@material-ui/icons";
import { useProfile } from "../hooks/useProfile";
import { useNavigate } from "react-router";

const PackageSelection = () => {
    
    const classes = useStyles();
    const [packages, setPackages] = useState([]);
    const {loadPackages} = useDatabase();
    const {SiteData} = useSiteData();
    const theme = useTheme();
    const [profile, setProfile] = useState({});
    const { retrieveProfileWithCallback } = useProfile();
    const history = useNavigate();
    const [isStory, setIsStory] = useState(false);
    useEffect(() => {
        retrievePackages();
        retrieveProfileWithCallback((profile) => {
            setProfile(profile);
        })
    }, []);
    const retrievePackages = async () => {
        const isStory = (window.location.pathname.includes("/story"));
        setIsStory(isStory);
        const loaded = await loadPackages({isStory: isStory});
        setPackages(loaded);    
    };
    const handlePackageSelection= async({id})=>{
        if(profile){
            console.log(`/${profile.uid}/Video?bundleId=${id}`);
            history(`/${profile.uid}/Video?bundleId=${id}`)
        }
    }
    const _buildPage = ()=>{
        return <Column style={{height:"100%",width:"100%", padding: "10%", maxWidth:1440, margin:"0 auto"}} >
            <Typography variant="h4" style={{textAlign:"center", width:"100%", padding:"0 20%", fontWeight:"bold", marginTop:"2rem"}}>Choose one of our DTV Bundles that best matches your needs.</Typography>
            <Typography variant="p" style={{textAlign:"center", width:"100%", padding:"0 20%",opacity:0.5, marginTop:"2rem"}}>{`${isStory ? 'Share your story with the world!': 'Submit your video to DTV Youtube - Our Exclusive Video Sharing Platform'}`}</Typography>
            <Grid container spacing={10} style={{marginTop:"2rem", justifyContent:"center"}}>
        {packages.map((item, index)=>{

            const discountedPrice = (item.price-(item.price*((item.discount/100)??0)));
            return <Grid item xs={12} sm={12} md={12} lg={3} key={index} >
                <motion.div initial={{scale: Math.floor(packages.length / 2) === index ? 1.02 : 0.95}} whileHover={{scale:Math.floor(packages.length / 2) === index ? 1.05:1, cursor: 'pointer'}}>
                    <RibbonContainer className={classes.packageCard}>
                {item.recommended ? <Ribbon
                        side="right"
                        type="corner"
                        size="large"
                        backgroundColor={theme.palette.primary.main}
                        color="#000000"
                        withStripes={true}
                    >Recommended
                    </Ribbon> : <></>}
                    <Column style={{height:'100%'}}>
                        <Typography variant="h5" style={{textAlign:"left", fontWeight:"bold", marginTop:"2rem"}}>{item.title}</Typography>
                        <Typography variant="caption" style={{textAlign:"left", marginTop:"1rem"}}>{item.description}</Typography>
                        <Row>
                        <FutureBuilder 
                        rebuild
                        future={Logic.toCountryCurrency(item.price/10,0)} builder = {
                            (price)=>{
                               return <Typography variant="h3" style={{textAlign:"left",textDecoration:item.discount ? "line-through":"", opacity: item.discount ? 0.2 : 1, fontWeight:"bold",  marginTop:"2rem"}}>{`${price}`}</Typography>
                            }
                        }
                        placeholder = {
                            <Typography variant="h3" style={{textAlign:"left", textDecoration:item.discount ? "line-through":"", opacity: item.discount ? 0.2 : 1, fontWeight:"bold", marginTop:"2rem"}}>{`€${item.price/10}`}</Typography>
                        }
                        error = {
                            ()=>{

                            }
                        }/>
                        {item.discount ? <>
                        <div style={{width:5}}/>   
                        <FutureBuilder 
                        rebuild
                        future={Logic.toCountryCurrency(discountedPrice/10,0)} builder = {
                            (price)=>{
                               return <Typography variant="h3" style={{textAlign:"left", fontWeight:"bold", marginTop:"2rem"}}>{`${price}`}</Typography>
                            }
                        }
                        placeholder = {
                            <Typography variant="h3" style={{textAlign:"left", fontWeight:"bold", marginTop:"2rem"}}>{`€${discountedPrice/10}`}</Typography>
                        }
                        error = {
                            ()=>{

                            }
                        }/></>:<></>}
                        </Row>
                        <Typography variant="caption" style={{textAlign:"left", marginTop:"1rem", opacity: 0.9}}>You can use <span style={{color:theme.palette.primary.main, opacity:1}}>{`${discountedPrice} DTV Coins`}</span> Instead </Typography>
                    <Button variant={item.recommended ? "contained" : "outlined"} onClick={()=>{
                        handlePackageSelection(item);
                    }} color="primary" style={{marginTop:"2rem", width:"100%", borderRadius:10}}>Select Package</Button>
                    <Divider style={{backgroundColor:theme.palette.text.primary, marginTop:"20px", opacity:0.2}}/>
                    <Column  style={{marginTop:"2rem", width:"100%"}}>
                    {
                        item.include.map((incl, index)=>{
                            return <Row key={index} style={{alignItems:"center", marginBottom:8}}>
                                <CheckCircleOutlineRounded style={{color:theme.palette.primary.main}}/>
                                <Typography variant="caption" style={{textAlign:"left", marginLeft:5, opacity: 0.9}}>{incl}</Typography>
                            </Row>
                        })
                    }
                    </Column>
                    <Column  style={{marginTop:"2rem", width:"100%"}}>
                    {
                        item.exclude?.map((incl, index)=>{
                            return <Row key={index} style={{alignItems:"center", marginBottom:8}}>
                                <HighlightOffRounded style={{color:'red'}}/>
                                <Typography variant="caption" style={{textAlign:"left", marginLeft:5, opacity: 0.9}}>{incl}</Typography>
                            </Row>
                        })
                    }
                    </Column>
                    </Column>
                </RibbonContainer>
                    </motion.div>
                </Grid>
        })}
            </Grid>
        </Column>
    }
    return <>
     <Stack>
            <div style={{height:"100%",position:'fixed', width:"100%",}}>
               {SiteData.isVideo? <ReactPlayer playsinline url={SiteData.featuredCover??backgroundVideo} 
                playing={true}
                height="100%"
                width="100%"
                loop={true}
                muted={true}
                    style={{objectFit: "cover"}} 
                    />:<img alt="" style={{
                      height:"100%",
                      width:"100%",
                      objectFit:"cover"
                    }} src={SiteData.featuredCover??banner}/>}
            </div>
            <div style={{background:"#0000000d",position:'fixed', height:"100%", width:"100%"}}></div>
            <div style={{background:"linear-gradient(0deg, black 14%, transparent)",height:"100%", position:'fixed',width:"100%"}}></div>
              <div style={{background:"linear-gradient(0deg, black 5%, transparent)",height:"100%",position:'fixed', width:"100%"}}></div>
            
           {SiteData.live? <Column alignment="end" style={{ width:'100%', overflowX:"hidden",overflowY:'auto'}}>{_buildPage()}
                </Column>:<></> }
        </Stack>
        <AppBarModule showCart/>
    </>
}
export default PackageSelection;