/* eslint-disable react-hooks/exhaustive-deps */

import { Dialog, alpha, Grid, IconButton, makeStyles, Snackbar, useTheme, CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import Stack from '../components/Stack';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Close } from '@material-ui/icons';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Column from '../components/Column';
import Row from '../components/Row';
import { SiteDataContext } from '../config/SiteData';
import "../index.css";

import Pagination from '@material-ui/lab/Pagination';
import { AppBarModule } from '../components/_app_bar_mod';
import { FooterModule } from '../components/_footer_mod';
import { PlaylistCard } from '../components/_playlist_mod';
import { PlaylistContext } from '../contexts/PlaylistProvider';
import { useMediaQuery } from '../contexts/MediaQueryProvider';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    }
  },
  iconBtn: {
    '&:hover': {
      color: "white"
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      color: "white"
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const PlaylistsPage = () => {

  const theme = useTheme()
  const classes = useStyles();
  const {
    SiteData,
  } = useContext(SiteDataContext)
  const { 
    loading,
    playlists,
    maxSize,
    getPlaylists,
  } = useContext(PlaylistContext);
  const {device} = useMediaQuery();
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [notificationMsg, ] = useState('');
  const [maxPageSize, ] = useState(SiteData.storyPage.maxPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const showPage = async (page = 1) => {
    await getPlaylists({limit: maxPageSize, offset: Math.max(0, (page - 1) * maxPageSize)});
    
  }
  useEffect(()=>{
    showPage(1)
  },[])
  const _buildPlaylist = () => {
    return <div style={{ width: "100%", height: "100%", margin: "3vh 0", padding: "3vh" }}>
      <Grid container spacing={5}>

        {playlists.map((key, index) => {
          return <Grid key={index} xs={12} md={4} lg={3} xl={2} style={{ margin: "15px" }}><PlaylistCard style={{ height: device === "pc" ? "300px" : "300px" }} playlist={key} /></Grid>
        })}

      </Grid>

    </div>
  }
  const handlePageChange = async (e, page) => {
    setCurrentPage(page);
    await showPage(page);
  }
  const _buildPage = (() => {
    return <Column alignment={!loading ? "start" : 'centered'} style={{ height: "100%", alignItems:"center" }}>
      {loading? <CircularProgress/> : <Grid container spacing={6} style={{ width: "100%", margin: device === "pc" ? "60px 10px" : "10px 0" }}>
        {_buildPlaylist()}
      </Grid>}

    </Column>
  });

  return (<div className={classes.root}>
    <CssBaseline />
    <Stack>

      <div style={{ background: "#0000000d", height: "100%", width: "100%" }}></div>
      <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 20%,${alpha(theme.palette.background.default, 0.6)} 54%, transparent)`, height: "100%", width: "100%" }}></div>

      {_buildPage()}
      <FooterModule style={{ sheight: "0" }} customFoot={<Row alignment="centered" style={{ width: "100%", height: device === "pc" ? "" : "10px", margin: "30px 0" }} >
        <Pagination onChange={handlePageChange} count={Math.max(Math.floor(maxSize / maxPageSize), 1) + ((maxSize % maxPageSize) === 0 || (maxSize / maxPageSize) < 1 ? 0 : 1)} page={currentPage} variant="outlined" />
      </Row>} />
    </Stack>

    {<AppBarModule showCart pageIndex={4} />}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification}
      autoHideDuration={notificationMsg.timeout}
      onClose={() => {
        showNotification(false)
      }}
      message={notificationMsg.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  </div>

  )


}
export default PlaylistsPage;