/* eslint-disable react-hooks/exhaustive-deps */

import { CheckCircleRounded, InfoOutlined } from "@material-ui/icons";
import { handleDownload, parseUrlForQueryParams } from "../../../utils";
import {  useNavigate } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import useServiceBooking from "../../../contexts/ServiceBookingProvider";
import { Avatar, Button, Card, CardActions, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, Divider, Grid, Link, TextField, Typography } from "@material-ui/core";
import Column from "../../Column";
import { useSnackbar } from "notistack";
import { useMediaQuery } from "../../../contexts/MediaQueryProvider";

import Row from "../../Row";
import Logic from "../../../logic/logic";
import FutureBuilder from "../../_future_builder";
import { motion } from "framer-motion";
import Ratings from "../../ratings";
import shortUUID from "short-uuid";
import { useProfile } from "../../../hooks/useProfile";
import { sendChatMessage } from "../../../hooks/useChatMessenger";
import { NotificationTypes, sendNotification } from "../../../hooks/notificationHandler";
import PropTypes from 'prop-types'

const ServiceAction = ({ agent:Agent, service:Service,servicePackage:ServicePackage, openAgreement,onOpenToDoPanel,onOpenCommunicationPanel,onReadDescription}) => {
    const params = parseUrlForQueryParams(window.location.href);
    console.log(params);
    const history = useNavigate();
    const { updateRequest,updateAgent  } = useServiceBooking();
    const [service, setService] = useState(Service);
    const [agent, setAgent] = useState(Agent);
    const [servicePackage, ] = useState(ServicePackage);
    
  const [profile, setProfile] = useState({});
  const { retrieveProfileWithCallback } = useProfile();
    const { isSmallDevice } = useMediaQuery();
    const { enqueueSnackbar } = useSnackbar();
    //const communicationPanelRef = useRef(null);
    //const todoPanelRef = useRef(null);
    const [, setOpenComms] = useState(false);
    const [, setOpenToDo] = useState(false);
    const [preview, setPreview] = useState({});
    const [showPreview, setShowPreview] = useState(false);
    const [deliverable, setDeliverable] = useState({});
    const [showDeliverable, setShowDeliverable] = useState(false);
    const reviewCommentRef = useRef(null);
    const [tax, ] = useState(0);
    const [downloding, setDownloading] = useState(false);
    const [showReview, setShowReview] = useState(false);
    const reviewRef = useRef(null);
    const [rating, setRating] = useState(0);
    const [status, ] = useState(Service.status);

    const serviceString = JSON.stringify(Service);
    useEffect(()=>{
      retrieveProfileWithCallback((data)=>{
        setProfile(data);
        setService(JSON.parse(serviceString));
      });
    }, [serviceString]);
    const handlePayDeposit = () => {
        history(`/payment?type=service_deposit&id=${service.id}_${service.packageId}`);
      }
      const handleFullpayment = () => {
        history(`/payment?type=service_payment&id=${service.id}_${service.packageId}`);
      }
    const buildPage = ()=>{
         switch (status) {
      case "delivered":
        return <Column alignment="space-between">
        <Column>
        <Typography>Project Completed</Typography>
        <Typography variant="caption" color="textSecondary">{
            new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.timestamp))
            }</Typography>
        <Typography variant="overline" >{`Total Paid: € ${service.amountPaid}`}</Typography>
        {
          service?.review ? <div style={{margin:'1em 0'}}>
          <Typography variant="overline" >{"Review"}</Typography>
            <Card variant="outlined" style={{margin:'0.2em 0'}}>
              <CardContent style={{padding: 8}}>
              <Column>
                  <Ratings value={agent.reviews[service.review]?.rating} editable={false}/>
                  <div style={{height:10}}/>
                  <Typography variant="body2">{agent.reviews[service.review]?.review}</Typography>
              </Column>
              </CardContent>
              <CardActions>
                <Typography variant="caption" style={{fontSize: 10}}>{
                  Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(agent.reviews[service.review]?.timestamp))
                }</Typography>
              </CardActions>
            </Card>
          </div> : <Button onClick={
            ()=>{
              setShowReview(true);
            }
          } size="small" variant="outlined" >Leave a Review</Button>
        }
        <Typography variant="overline" >{"Deliverables"}</Typography>
        <Typography variant="caption" color="textSecondary">{"The final deliverables"}</Typography>
        <div style={{height:'1em'}}/>
        {service.deliverables?.length > 0 ? <Grid spacing={20} container>
        {
            service.deliverables.map((deliverable, index)=>{
              const height = isSmallDevice ? 250 : 150;
                return <Grid key={index} item xs={12} md={6}>
                    <motion.div  whileHover={{cursor:'pointer', scale: 1.03}} onClick={()=>{
                    setDeliverable(deliverable);
                    setShowDeliverable(true);
                  }}>
                      <Card style={{height:height,margin:'0 3px'}} variant="outlined">
                        {deliverable.type === "video" ? <video src={deliverable.url} style={{width:'100%', height: height}}/> : <img alt="deliverable" src={deliverable.url} style={{width:'100%', height: height,objectFit:'cover'}}/>}
                    </Card>  </motion.div>
                    </Grid>
                    
            })
        }
        </Grid>: <Typography variant="caption" color="textSecondary">{"No Deliverables"}</Typography>}
        </Column>
    </Column>
      case "submitted": case "pending":
        return <Column alignment="space-between">
          <Column>
            <Typography>{"You've Submitted Your Request"}</Typography>
            <Typography variant="caption" color="textSecondary">{
              new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.timestamp))
            }</Typography>
            <Link style={{margin: '1em 0'}} onClick={
                    onReadDescription
                } >Read Description</Link>
            <Row alignment={'centered'} style={{ margin: '1em 0', alignItems: 'center' }}>
              <InfoOutlined />
              <div style={{ width: '0.5em' }} />
              <Typography align="center" variant="caption" color="textSecondary">When this request is accepted you will be notified!</Typography>
            </Row>
          </Column>
        </Column>
      case "cancelled": case "cancelled-1": case "cancelled-2":
        return <Column alignment="space-between">
          <Column>
            <Typography>Project Canceled</Typography>
            <Typography variant="caption" color="textSecondary">{
              new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.timestamp))
            }</Typography>
            <blockquote style={{ color: "#C1C7D0", borderLeft: '0.2em solid #757D87', padding: '0 1em' }}>
              <Typography variant="caption" style={{ fontStyle: 'italic' }}>{service.cancelReason}</Typography>
            </blockquote>
            <Row alignment={'centered'} style={{ margin: '1em 0', alignItems: 'center' }}>
              <InfoOutlined />
              <div style={{ width: '0.5em' }} />
              <Typography align="center" variant="caption" color="textSecondary">You can still re-open this request</Typography>
            </Row>
          </Column>
        </Column>
      case "rejected": 
        return <Column alignment="space-between">
          <Column>
            <Typography>Unfortunately, Your request has been rejected</Typography>
            <Typography variant="caption" color="textSecondary">{
              new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.timestamp))
            }</Typography>
            <Typography variant="caption">Reason for rejection</Typography>
            <blockquote style={{ color: "#C1C7D0", borderLeft: '0.2em solid #757D87', padding: '0 1em' }}>
              <Typography variant="caption" style={{ fontStyle: 'italic' }}>{service.rejectionReason}</Typography>
            </blockquote>
          </Column>
        </Column>
      case "deposit":
        return <Column alignment="space-between">
          <Column>
            <Typography>Your Request has been approved</Typography>
            <Typography variant="caption" color="textSecondary">{
              new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
            }</Typography>
            <div style={{ height: 20 }} />
            <Typography variant="caption">{"In Accordance to this package's"}<span><Button onClick={openAgreement} style={{ fontSize: 10, textTransform: "capitalize", textDecoration: 'underline' }} size="small">terms and conditions</Button></span> In Order to progress with your Request you must pay the deposit first.</Typography>
            <div style={{ height: 20 }} />
            <Divider />
            <div style={{ height: 20 }} />
            <Row style={{ width: '100%', alignItems: 'center' }} alignment='space-between'>
              <Typography variant="overline" color="textSecondary">Deposit Amount:</Typography>
              <div style={{ width: 10 }} />
              <FutureBuilder
                rebuild
                future={Logic.toCountryCurrency(servicePackage.deposit??0, 0)} builder={
                  (price) => {
                    return <Typography variant="overline">{price}</Typography>
                  }
                }
                placeholder={
                  <Typography variant="caption">€{servicePackage.deposit??0}</Typography>
                }
                error={
                  () => {
                    return <></>
                  }
                } />

            </Row>
            <div style={{ height: 20 }} />
            <Row style={{ width: '100%', alignItems: 'center' }} alignment='centered'>
              <div style={{ width: 10 }} />
              <Button onClick={handlePayDeposit} variant="contained" disableElevation size="small" color="primary">Pay Now</Button>

            </Row>
            <div style={{ height: 20 }} />
            <Row alignment={'centered'} style={{ margin: '1em 0', alignItems: 'center' }}>
              <InfoOutlined />
              <div style={{ width: '0.5em' }} />
              <Typography align="center" variant="caption" color="textSecondary">{
                service.depositDeadline ? 
                `You have ${Logic.getDaysDiff(new Date(service.depositDeadline), new Date()).days} days left to pay the deposit`:
                `You have ${Logic.getDaysDiff(new Date(service.statusDates[status]).addDays(5), new Date()).days} days left to pay the deposit`
              }</Typography>
            </Row>

          </Column>
        </Column>
      case "deposit-processing":
        return <Column alignment="space-between">
          <Column>
            <Typography>{"Nice! You've Paid your Deposit"}</Typography>
            <Typography variant="caption" color="textSecondary">{
              new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
            }</Typography>
            <div style={{ height: 20 }} />
            <Typography variant="caption">Please be patient as we are now processing your deposit</Typography>
            <div style={{ height: 20 }} />
            <Divider />
            <div style={{ height: 20 }} />
            <Row style={{ width: '100%', alignItems: 'center' }} alignment='space-between'>
              <Typography variant="overline" color="textSecondary">Deposit Paid:</Typography>
              <div style={{ width: 10 }} />
              <FutureBuilder
                rebuild
                future={Logic.toCountryCurrency(service.depositPaid, 0)} builder={
                  (price) => {
                    return <Typography variant="overline">{price}</Typography>
                  }
                }
                placeholder={
                  <Typography variant="caption">€{servicePackage.deposit}</Typography>
                }
                error={
                  () => {
                    return <></>
                  }
                } />

            </Row>
            <div style={{ height: 20 }} />
            <Row alignment={'centered'} style={{ margin: '1em 0', alignItems: 'center' }}>
              <InfoOutlined />
              <div style={{ width: '0.5em' }} />
              <Typography align="center" variant="caption" color="textSecondary">This might take 2-3 days</Typography>
            </Row>

          </Column>
        </Column>
      case "payment-processing":
        return <Column alignment="space-between">
          <Column>
            <Typography>Congratulations, Final payment made</Typography>
            <Typography variant="caption" color="textSecondary">{
              new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
            }</Typography>
            <div style={{ height: 20 }} />
            <Typography variant="caption">Please be patient as we are now processing your payment</Typography>
            <div style={{ height: 20 }} />
            <Divider />
            <div style={{ height: 20 }} />
            <Row style={{ width: '100%', alignItems: 'center' }} alignment='space-between'>
              <Typography variant="overline" color="textSecondary">Total Paid:</Typography>
              <div style={{ width: 10 }} />
              <FutureBuilder
                rebuild
                future={Logic.toCountryCurrency(service.amountPaid, 0)} builder={
                  (price) => {
                    return <Typography variant="overline">{price}</Typography>
                  }
                }
                placeholder={
                  <Typography variant="caption">€{servicePackage.amountPaid}</Typography>
                }
                error={
                  () => {
                    return <></>
                  }
                } />

            </Row>
            <div style={{ height: 20 }} />
            <Row alignment={'centered'} style={{ margin: '1em 0', alignItems: 'center' }}>
              <InfoOutlined />
              <div style={{ width: '0.5em' }} />
              <Typography align="center" variant="caption" color="textSecondary">This might take 2-3 days</Typography>
            </Row>

          </Column>
        </Column>
              case "payment":
                return <Column alignment="space-between">
                  <Column>
                    <Typography>Almost There!</Typography>
                    <Typography variant="caption" color="textSecondary">{
                      new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
                    }</Typography>
                    <div style={{ height: 20 }} />
                    <Typography variant="caption">Please find the full details of the final payment below.</Typography>
                    <div style={{ height: 20 }} />
                    <Typography variant="overline">Deadline</Typography>
                    <div style={{ height: 2 }} />
                    <Typography variant="caption">{new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service?.paymentDeadline??0))}</Typography>
                    <div style={{ height: 20 }} />
                    <Divider />
                    <div style={{ height: 20 }} />
                    <Row style={{ width: '100%', alignItems: 'center' }} alignment='space-between'>
                      <Typography variant="caption">Package Price:</Typography>
                      <div style={{ width: 10 }} />
                      <FutureBuilder
                        rebuild
                        future={Logic.toCountryCurrency(servicePackage.price, 0)} builder={
                          (price) => {
                            return <Typography variant="overline">{price}</Typography>
                          }
                        }
                        placeholder={
                          <Typography variant="caption">€{servicePackage.price}</Typography>
                        }
                        error={
                          () => {
                            return <></>
                          }
                        } />
        
                    </Row>
                    <div style={{ height: 5 }} />
                    <Row style={{ width: '100%', alignItems: 'center' }} alignment='space-between'>
                      <Typography variant="caption" color="textSecondary">Deposit Paid:</Typography>
                      <div style={{ width: 10 }} />
                      <FutureBuilder
                        rebuild
                        future={Logic.toCountryCurrency(servicePackage.deposit, 0)} builder={
                          (price) => {
                            return <Typography variant="caption"  color="textSecondary">{price}</Typography>
                          }
                        }
                        placeholder={
                          <Typography variant="caption"  color="textSecondary">€{servicePackage.deposit}</Typography>
                        }
                        error={
                          () => {
                            return <></>
                          }
                        } />
        
                    </Row>
                    <div style={{ height: 5 }} />
                    <Row style={{ width: '100%', alignItems: 'center' }} alignment='space-between'>
                      <Typography variant="caption">Subtotal:</Typography>
                      <div style={{ width: 10 }} />
                      <FutureBuilder
                        rebuild
                        future={Logic.toCountryCurrency(servicePackage.price - (service.depositPaid??0), 0)} builder={
                          (price) => {
                            return <Typography variant="overline">{price}</Typography>
                          }
                        }
                        placeholder={
                          <Typography variant="overline">€{servicePackage.price - (service.depositPaid??0)}</Typography>
                        }
                        error={
                          () => {
                            return <></>
                          }
                        } />
        
                    </Row>
                    <div style={{ height: 5 }} />
                    <Divider />
                    
                    <div style={{ height: 5 }} />
                    {
                      service?.extraCharges?.length > 0 ? <Column>
                      <div style={{ height: 5 }} />
                      <Typography variant="caption">Extra Charges</Typography>
                      {
                        service.extraCharges.map((charge, index)=>{
                          return <Row key = {index} style={{ width: '100%', alignItems: 'center', marginBottom:5 }} alignment='space-between'>
                          <Typography variant="caption" color="textSecondary" style={{textTransform:'capitalize'}}>{charge.type?.replace("_"," ")}:</Typography>
                          <div style={{ width: 10 }} />
                          <FutureBuilder
                            rebuild
                            future={Logic.toCountryCurrency(Number(charge.amount), 0)} builder={
                              (price) => {
                                return <Typography variant="overline">{price}</Typography>
                              }
                            }
                            placeholder={
                              <Typography variant="overline">€{Number(charge.amount)}</Typography>
                            }
                            error={
                              () => {
                                return <></>
                              }
                            } />
            
                        </Row>
                        })
                      }
                      </Column> : <></>
                    }
                     <Row style={{ width: '100%', alignItems: 'center' }} alignment='space-between'>
                      <Typography variant="caption">Tax:</Typography>
                      <div style={{ width: 10 }} />
                      <FutureBuilder
                        rebuild
                        future={Logic.toCountryCurrency(tax, 0)} builder={
                          (price) => {
                            return <Typography variant="overline">{price}</Typography>
                          }
                        }
                        placeholder={
                          <Typography variant="overline">€{tax}</Typography>
                        }
                        error={
                          () => {
                            return <></>
                          }
                        } />
        
                    </Row>
                    <div style={{ height: 5 }} />
                    <Divider />
                    <div style={{ height: 5 }} />
                     <Row style={{ width: '100%', alignItems: 'center' }} alignment='space-between'>
                      <Typography variant="caption">Total:</Typography>
                      <div style={{ width: 10 }} />
                      <FutureBuilder
                        rebuild
                        future={Logic.toCountryCurrency(
                          (servicePackage.price - (service.depositPaid??0)) + (
                          service.extraCharges?.length > 0 ? service.extraCharges.reduce((a,b)=>a+Number(b.amount),0) : 0
                        )+tax, 0)} builder={
                          (price) => {
                            return <Typography variant="overline">{price}</Typography>
                          }
                        }
                        placeholder={
                          <Typography variant="overline">€{ (servicePackage.price - (service.depositPaid??0)) +(
                            service.extraCharges?.length > 0 ? service.extraCharges.reduce((a,b)=>a+Number(b.amount),0) : 0
                          )+tax}</Typography>
                        }
                        error={
                          () => {
                            return <></>
                          }
                        } />
        
                    </Row>
                    <div style={{ height: 20 }} />
                    <Row style={{ width: '100%', alignItems: 'center' }} alignment='centered'>
                      <div style={{ width: 10 }} />
                      <Button onClick={handleFullpayment} variant="contained" disableElevation size="small" color="primary">Pay Now</Button>
        
                    </Row>
                    <div style={{ height: 20 }} />
                    <Row alignment={'centered'} style={{ margin: '1em 0', alignItems: 'center' }}>
                      <InfoOutlined />
                      <div style={{ width: '0.5em' }} />
                      <Typography align="center" variant="caption" color="textSecondary">{`You have less than ${
                        Math.floor((new Date(service.paymentDeadline).getTime() - new Date().getTime()) / (1000 * 3600 * 24))+1
                      } days left to pay`}</Typography>
                    </Row>
        
                  </Column>
                </Column>
      case "review":
        return <Column alignment="space-between">
          <Column>
            <Typography>Check out these previews</Typography>
            <Typography variant="caption" color="textSecondary">{
              new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
            }</Typography>
            <div style={{ height: 20 }} />
            <Typography variant="caption">{service.previewComment}</Typography>
            <div style={{ height: 20 }} />
            <Grid container spacing={20}>
              {!service.previews? <Typography>No Previews Available</Typography> :service.previews?.map((preview, index) => {
                return <Grid key={index} item xs={12} md={4}>
                  <motion.div whileHover={{cursor:'pointer', scale: 1.1}}>
                    <Card onClick={()=>{
                    setPreview(preview);
                    setShowPreview(true);
                  }} variant="outlined" style={{ padding: 2 }}>
                    {
                    preview.type === "image" ? <img alt="preview" src={preview.url} style={{width:'100%', height:'100%'}}/> : <video style={{width:'100%', height:'100%'}} controls src={preview.url}/>
                  }
                  </Card>
                    </motion.div> 
                </Grid>
              })}
              
            </Grid>
         
            <div style={{ height: 20 }} />
             <Divider />
            <div style={{ height: 20 }} />
         
            <TextField multiline fullWidth variant="outlined" label="Leave a review / request changes" inputRef={reviewCommentRef} />
            <div style={{ height: 10 }} />
            {service.previewApproved ? <></> : <Row alignment={'centered'} style={{ margin: '1em 0', alignItems: 'center' }}>
              <Button disabled={service.previewApproved} variant="contained" disableElevation size="small" color="primary" onClick={
                async()=>{
                  service.previewApproved = true;
                  service.reviewComment = reviewCommentRef.current?.value??'';
                  setService(service);
                  if(await updateRequest(service.id, service)){
                    sendChatMessage({
                      messageId: shortUUID.generate(),
                      senderId: profile.uid,
                      reciepientKey: service.id,
                      reciepients: [service.agentId, 'dtv-admin'],
                      content: `Preview Approved: ${service.previewComment}`,
                      timestamp: Date.now(),
                      seenby:[profile.uid],
                    })
                    sendNotification({
                      title: `Preview Approved`,
                      message: `${service.previewComment}`,
                      timestamp: Date.now(),
                      sendTo: service.agentId,
                      id: shortUUID.generate(),
                      sentFrom: profile.uid,
                      type: NotificationTypes.SERVICE_REVIEW,
                      url: `/${profile.uid}/ServiceProject?id=${service.id}`,
                      seen:false,
                    })
                    enqueueSnackbar("Review Submitted", {variant: 'success'});
                  }else{
                    enqueueSnackbar("Error Submitting Review", {variant: 'error'});
                  }
                }
              }>Approve</Button>
              {!service?.requestChange ? <><div style={{ width: '0.5em' }} />
              <Button variant="outlined" disableElevation size="small" color="default" onClick={
                async()=>{
                  service.requestChange = true;
                  service.previewApproved = false;
                  service.reviewComment = reviewCommentRef.current?.value??'';
                  setService(service);
                  if(await updateRequest(service.id, service)){
                    sendChatMessage({
                      messageId: shortUUID.generate(),
                      senderId: profile.uid,
                      reciepientKey: service.id,
                      reciepients: [service.agentId, 'dtv-admin'],
                      content: `Changes Requested: ${service.reviewComment}`,
                      timestamp: Date.now(),
                      seenby:[profile.uid],
                    })
                    sendNotification({
                      title: `Changes Requested`,
                      message: `${service.reviewComment}`,
                      timestamp: Date.now(),
                      sendTo: service.agentId,
                      id: shortUUID.generate(),
                      sentFrom: profile.uid,
                      type: NotificationTypes.SERVICE_REVIEW,
                      url: `/${profile.uid}/ServiceProject?id=${service.id}`,
                      seen:false,
                    })
                    enqueueSnackbar("Request Changes", {variant: 'success'});
                  }else{
                    enqueueSnackbar("Error Requesting Changes", {variant: 'error'});
                  }
                }
              }>Request Changes</Button></>:<></>}
            </Row>}
            <div style={{ height: 10 }} />
          </Column>
        </Column>
      case "in-progress":
        return <Column alignment="space-between">
          <Column>
            <Typography>{"Let's get to work!"}</Typography>
            <Typography variant="caption" color="textSecondary">{
              new Intl.DateTimeFormat('en-GB', { dateStyle: 'full' }).format(new Date(service.statusDates[status]))
            }</Typography>
            <div style={{ height: 20 }} />
            <Typography variant="caption">Now that your deposit has been cleared. We can get into the good stuff.</Typography>
            <div style={{ height: 20 }} />
            <Divider />
            <div style={{ height: 20 }} />
            <Column style={{width:"100%"}} alignment='space-between'>
              <Row style={{width:"100%"}} alignment='space-between'>
                <Typography variant="overline" color="textSecondary">Deadline</Typography>
                {service.completed ?  <Chip style={{marginLeft:10, borderColor: '#00ff9b', color:'#00ff9b' }} variant="contained" label="Completed"/> :
                (new Date(service.deadline) < new Date()) ? <Chip style={{marginLeft:10, borderColor: '#ff6f6f', color:'#ff6f6f' }} variant="outlined" label="Overdue"/> :
                 <Chip style={{marginLeft:10, borderColor: '#00ceff', color:'#00ceff' }} variant="outlined" label="On Track"/>}
                </Row>
              <div style={{ width: 10 }} />
              <Row style={{width:'100%', alignItems:'center'}} alignment="space-between">
              <TextField
                    id="datetime-local"
                    label={service.deadline ? "Update Deadline" : "Deadline(optional)"}
                    type="date"
                    defaultValue={service.deadline}
                    onChange={(e) => {
                      setService({ ...service, deadline: e.target.value });
                     
                    }}
                    InputProps={{
                        inputProps:{
                            min: new Date().toISOString().split('T')[0],

                        }
                    }} 
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <div style={{width:5}}/>
                <Button style={{
                  textTransform: 'capitalize',
                  fontSize: 10,
                  maxHeight: 30
                }} size="small" variant="outlined" disableElevation endIcon={<CheckCircleRounded/>} color="primary" onClick={
                  async()=>{
                    if(await  updateRequest(service.id, service)){
                      enqueueSnackbar("Deadline Updated", {variant: 'success'});
                    }else{
                      enqueueSnackbar("Error Updating Deadline", {variant: 'error'});
                    }
                  }
                }>
                  <Typography variant="caption">Set Deadline</Typography>
                </Button>
              </Row>
            </Column>
            <div style={{ height: 20 }} />
            <Typography variant="caption"> {`A task list has been created for you to track the progress of your project. This agent will be updating tasks and you can follow along here `}
            <Button size="small" style={{ fontSize: 10, textTransform: "capitalize", textDecoration: 'underline' }} onClick={()=>{
              setOpenToDo(true);
              onOpenToDoPanel();
            }}>View TaskList</Button>
            </Typography>
            <div style={{ height: 20 }} />
            <Row alignment={'centered'} style={{ margin: '1em 0', alignItems: 'center' }}>
              <Typography align="center" variant="caption" color="textSecondary">{`You can use the `} <Button size="small" style={{ fontSize: 10, textTransform: "capitalize", textDecoration: 'underline' }} onClick={
                ()=>{
                  setOpenComms(true);
                  onOpenCommunicationPanel();
                }
              } >Communication channel</Button> {` to communicate with the agent directly`} </Typography>
            </Row>

          </Column>
        </Column>
      default: return <></>
    }
    }
   return <>
   {buildPage()}
   
    <Dialog open = {showPreview} onClose={()=>{
      setShowPreview(false);
    }}>
      <DialogContent>
        {
          preview?.type === 'image' ? <img alt="preview" style={{width:"100%", height:"100%"}} src={preview.url} /> : <video style={{width:"100%", height:"100%"}}  controls src={preview.url} />
        }
        </DialogContent>
    </Dialog>
    <Dialog fullWidth maxWidth={"lg"} open = {showDeliverable} onClose={()=>{
      setShowDeliverable(false);
    }}>
      <DialogContent>
        <Row style={{width:"100%"}}>
          {
            deliverable?.type === 'image' ? <img alt="deliverable" style={{width:"80%", height:"80%", objectFit:'contain'}} src={deliverable.url} /> : <video style={{width:"80%", height:"80%"}}  controls src={deliverable.url} />
          }
          <div style={{width:10}}/>
          <Column alignment={"start"}>
            {
              deliverable?.type === 'image' ? <>
              <Button variant="outlined" color="primary" onClick={async ()=>{
                setDownloading(true)
                 await handleDownload(deliverable.url, 'png');
                setDownloading(false);

              }}> Download as PNG </Button>
               <div style={{width:10}}/>
            <Button variant="outlined" color="primary" onClick={()=>{
              handleDownload(deliverable.url, 'jpg');
            }}> Download as JPEG </Button>
              </>:<>
              <Button variant="outlined" color="primary" onClick={()=>{}}> Download as MP4 </Button>
              </>
            }
          </Column>
        </Row>
        </DialogContent>
    </Dialog>
    <Dialog open={downloding}>
      <DialogContent>
        <Column>
            <CircularProgress/>
            <Typography variant="caption">Downloading...please wait</Typography>
        </Column>
        </DialogContent>
    </Dialog>
    <Dialog maxWidth={'sm'} fullWidth open={showReview} onClose={()=>{
      setShowReview(false);
    }} aria-labelledby="form-dialog-title">
        <DialogContent>
            <Column style={{width: '100%', alignItems:'center'}}>
                 <Avatar src={agent?.picture??''} style={{height: 100, width: 100}}/>
                    <div style={{height: 10}}/>
                    <Typography variant="h6">{agent?.name??''}</Typography>
                    <div style={{height: 10}}/>
                    <Typography variant="caption">{agent?.email??''}</Typography>
                    <div style={{height: 10}}/>
                    <Typography variant="caption">{`Rate ${agent.name} out of 5 stars`}</Typography>
                    <div style={{height: 10}}/>
                    <Ratings ratings={
                      Object.values(agent.ratings??{})
                    } editable onChange={
                      (rating)=>{
                        setRating(rating);
                        agent.ratings = {
                          ...(agent.ratings??{}),
                          [profile.uid]: rating
                        }
                        setAgent(agent);
                      }
                    }/>
                    <div style={{height: 10}}/>
                    <TextField defaultValue={agent?.reviews?.[profile.uid]??''} inputRef={reviewRef} label="Write a review " variant="outlined" multiline rows={4} fullWidth/>
            </Column>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{
            if(reviewCommentRef.current?.value?.length < 2){
              enqueueSnackbar("Sorry, review not sent, Too short", {variant: 'success'});
              setShowReview(false);
            }
            const reviewId = shortUUID.generate();
            agent.reviews = {
              ...(agent.reviews??{}),
              [reviewId]: {
                id:reviewId,
                rating: rating,
                review: reviewRef.current?.value??'',
                timestamp: Date.now(),
                reviewer: profile.uid,
                projectId: `Service_${service.id}_${service.packageId}`
              }
            }
            updateRequest(service.id, {review: reviewId});
            updateAgent(agent.id, agent);
            enqueueSnackbar("Review Submitted, Thank you", {variant: 'success'});
            setShowReview(false);
          }} color="primary">
            Submit
          </Button>

          </DialogActions>
    </Dialog>
   </>
  }
  export default ServiceAction;

  ServiceAction.propTypes = {
    agent: PropTypes.object,
    service: PropTypes.object,
    servicePackage: PropTypes.object,
    openAgreement: PropTypes.func,
    onOpenToDoPanel: PropTypes.func,
    onOpenCommunicationPanel: PropTypes.func,
    onReadDescription: PropTypes.func,
  }