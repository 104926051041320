/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from 'react';
import { Avatar,  Dialog, Snackbar, IconButton, Button, TextField, Typography, makeStyles, Card,  useTheme, Badge, CircularProgress, Chip, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import Stack from '../components/Stack';
import CssBaseline from '@material-ui/core/CssBaseline';
import Row from '../components/Row'
import Column from '../components/Column';
import { useStoryProvider } from '../contexts/StoryProvider';
import {
 Close,Settings
} from '@material-ui/icons';
import "../index.css";
import { SiteDataContext } from '../config/SiteData';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import Swiper styles
import "swiper/swiper.min.css";
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import { useNavigate } from 'react-router';
import { AppBarModule } from '../components/_app_bar_mod';
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Skeleton } from '@material-ui/lab';
import BookingCard from '../components/_booking_card';
import { getUserBookings, retrieveUserStorySubmissions } from '../logic/databaseHandler';
import SwiperCore, {
    EffectCoverflow, Pagination
} from 'swiper';
import { useMediaQuery } from '../contexts/MediaQueryProvider';
import LoginRequired from '../components/_login_required';
import ValidUserRequired from '../components/_valid_user_required';
import { useProfile } from '../hooks/useProfile';
import { FieldKeys, camelCaseToSentenceCase, parseUrlForId } from '../utils';
import { useDatabase } from '../hooks/useDatabase';
import FutureBuilder from '../components/_future_builder';
import ServiceTracker from '../components/profile/main/service_tracker';
import MyApplications from '../components/my_applications';
import { useCustomDialog } from '../contexts/CustomDialogProvider';
import useServiceBooking from '../contexts/ServiceBookingProvider';
import BuildServiceAgentProjectsTab from '../components/profile/agent/_build_service_agent_projects_tab';
import BuildServiceAgentRequestsTab from '../components/profile/agent/_build_service_agent_requests';
import { MyVideosContent } from '../components/profile/myVideos';
import { MyStoriesContent } from '../components/profile/myStories';
import PropTypes from 'prop-types';
//http://localhost:3000/n5iq2vvBJWdmhBoane1dkYZpYxt2/VideoSubmission?id=481cb2c5-46e7-11ee-8888-02df132a943e
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        background: "black",
        overflowY: 'hidden',
        maxWidth: '100%',
        flexGrow: 1,
        '& .MuiDivider-root': {
            backgroundColor: 'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root': {
            color: 'white'
        }
    },
    iconBtn: {
        '&:hover': {
            color: "white"
        },
    },
    sideBarSocials: {
        color: "#ffffff47",
        fontSize: "12px",
        '&:hover': {
            color: "white"
        },
    },
    divider: {
        backgroundColor: "rgba(1,1,1, 1)"
    },
    center: {
        textAlign: "center",
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    gridList: {
        width: 500,
        height: 450,
    },
    icon: {
        color: 'rgba(255, 255, 255, 0.54)',
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        padding: "3px 1px",
        position: "fixed",
        // background:"linear-gradient(45deg, black, #d09612bd);",
        background: "transparent",
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    socialIcon: {
        color: "#ffffff47",
        '&:hover': {
            color: "white"
        },
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    listItem: {
        backgroundColor: "#2C2C2C",
        color: "white",
        margin: 10
    },
    listItemImage: {
        height: "60px",
        width: "60px",
        margin: "10px"
    },
    list: {
        transition: theme.transitions.create(["background", "background-color"], {
            duration: theme.transitions.duration.complex,
        }),
        "&:hover": {
            backgroundColor: "#6363638c",
            color: "black",
        },
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    grow: {
        '&:hover': {
            transform: 'scale(1.1)',
            easing: "linear",
            cursor: 'pointer'
        }
    },
    link: {
        color: "grey",
        '&:hover': {
            transform: 'scale(1.1)',
            cursor: 'pointer',
            color: 'white'
        }
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    mobileNav: {
        width: "60%"
    },
    pcNav: {
        width: "30%"
    },
    logo: {
        height: "60px",
        width: "60px"
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.text.primary,
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));


const ProfilePage = () => {
    const {
        SiteData,
    } = useContext(SiteDataContext);
    const {
        getAllStoriesBy: getUserStories
    } = useStoryProvider();

    const [, updateAllStories] = useState([]);
    const{retrieveProfile, loadingProfile} = useProfile();
    const [profile, updateProfile] = useState();
    const theme = useTheme()
    const history = useNavigate();
    const classes = useStyles(theme);
    const [startDate, setStartDate] = useState(new Date());
    const { device } = useMediaQuery('pc');
    const [showModal, setShowModal] = useState(false);
    const [preview_body, ] = useState(<></>);
    const [notification, showNotification] = useState(false);
    const [notificationMsg, ] = useState('');
    const [, setShowStoryLoading] = useState(false);
    const [bookings, updateBookings] = useState([]);
    const [loadingBookings, setLoadingBookings] = useState(false);
    const {getTransactions,getAllUserDrafts,updateProfile: updateUserProfile} = useDatabase();
    const {getAgentByAgentId,getAgent} = useServiceBooking();
    const [currentTab, updateCurrentTab] = useState(localStorage.getItem('dtv-profileCurrentTab') ?? 0);
    const [, updateVideoTransactions] = useState([]);
    const [, updateStorySubmissions] = useState([]);
    const [loading, setLoading] = useState(false);
    const {showDialog,closeDialog} = useCustomDialog();
    const [accountType, setAccountType] = useState('default');
    const [userProfile, setUserProfile] = useState({});
    SwiperCore.use([EffectCoverflow, Pagination]);

    const handleRetrieveStory = async (draftMode,profile) => {
        setShowStoryLoading(true)
        console.log(profile)
        if(draftMode){
            getAllUserDrafts(profile?.uid).then((stories) => {
                console.log(stories,"RESULTS")
                updateAllStories(stories);
                setShowStoryLoading(false)
            });
        }else{
            getUserStories(profile?.uid).then((stories) => {
                updateAllStories(stories);
                setShowStoryLoading(false)
            })
        }
    }
    const getBookings = async () => {
        setLoadingBookings(true);
        console.log(profile?.uid, "Getting bookings...")
        getUserBookings(profile?.uid, (docs) => {
            setLoadingBookings(false);
            console.log(docs);
            updateBookings(docs);
        })


    }

    useEffect(() => {
        setLoading(true);
        retrieveProfile(parseUrlForId(window.location.pathname)).then((profile)=>{
            setUserProfile(profile)
            // dateSearchref.current.value = `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`;
            if (profile?.uid) {
                console.log(profile);
                getTransactions({uid: profile?.uid, cat:FieldKeys.topUp}).then((docs) => {
                    console.log(docs);
                    updateVideoTransactions(docs);
                });
                if(profile.currentAccount?.includes("serviceAgent")){
                    getAgentByAgentId(profile.uid).then((agent)=>{
                        console.log(agent);
                        agent.uid= agent.id;
                        updateProfile(agent);
                        
                        setLoading(false);
                        setAccountType('serviceAgent');
                    })
                }else{
                    updateProfile(profile)
                    getBookings()
                    handleRetrieveStory(true,profile)
                    retrieveUserStorySubmissions(profile?.uid).then((docs) => {
                        console.log(docs);
                        updateStorySubmissions(docs);
                        setLoading(false);
                        setAccountType('default');
                    })
                }
                    
            }
        })
      

    }, [])

    
    const handleAccountSelected = (key, account)=>{
        userProfile.currentAccount = `${key}-${account}`;
        setUserProfile({...userProfile});
        updateUserProfile(userProfile.uid, userProfile).then(()=>{
            closeDialog();
            window.location.reload();
        })
    }

    const BuiltAccountsTile = ({user,type, account})=>{
        return <ListItem key={account} button onClick={()=>{
            handleAccountSelected(type, account)
        }}>
        <ListItemAvatar>
            <Avatar src={user.picture}/>
        </ListItemAvatar>
        <ListItemText primary={
            <Row style={{alignItems:"center"}}>
                <Typography>{`${user.fname ? `${user.fname} ${user.lname}` : user.name??'Unknown'}`}</Typography>
                <Chip variant='outlined' size='small' label={type && type==="default" ? "Main" : camelCaseToSentenceCase(type)} style={{marginLeft:10}}/>
            </Row>
        } secondary={user.email} />
     </ListItem>
    }
    BuiltAccountsTile.propTypes = {
        user: PropTypes.object,
        type: PropTypes.string,
        account: PropTypes.string
    }

    const handleChangeAccount = () => {
        showDialog({
            title: "Change Account",
            width:'sm',
            body:<List>
                {Object.keys(userProfile?.accounts)?.map((key) => {
                   return userProfile?.accounts?.[key]?.map((account) => {
                    console.log(account,key)
                        switch (key) {
                            case "serviceAgent": return <FutureBuilder

                                futureFunc={() => getAgent(account)}
                                placeholder={<Skeleton variant="rectangular" width={300} height={100} />}
                                builder={(user) => {
                                    return <BuiltAccountsTile user={user} account={account} type={key} />
                                }} />
                                    
                            default: return <FutureBuilder 
                                futureFunc = {()=>retrieveProfile(account)}
                                placeholder={<Skeleton variant="rectangular" width={300} height={100} />}
                                builder = {(user)=>{
                                    return <BuiltAccountsTile user={user}  account={account} type={key} />
                                }}
                            />
                        }
                    })
                })}
            </List>
        })
    }
    const _buildProfileName = () => {
        return <>
            <Column >
                <Typography style={{ fontWeight: "bold", textAlign: device === "xs" ? "center" : "left", }}>{profile?.email ?? "No email"}</Typography>
                <Typography style={{ fontWeight: "400", fontSize: "12px", textAlign: device === "xs" ? "center" : "left" }}>{
                    `${profile?.fname ? `${profile?.fname} ${profile?.lname}` : profile?.name??'Unknown'}`
                }</Typography>
                <Typography align='left' variant='caption' color='textSecondary'>{accountType && accountType==="default" ? "Main Account" : camelCaseToSentenceCase(accountType)}</Typography>
                {(profile?.accounts || accountType !== 'default') &&<Button onClick={handleChangeAccount} style={{justifyContent: device === "xs" ? "center" :"flex-start",paddingLeft:0, textTransform:'capitalize', textDecoration:'underline'}}>Change Account</Button>}
            </Column>
        </>
    }
    const _buildProfilePic = () => {
        return <Row alignment={device === "xs" ? "centered" : "left"}>
            <Badge
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            // badgeContent={<Avatar src={logo} style={{ height: 40, width: 40 }} />}>
            >
                <Avatar src={profile?.picture ?? ""} style={{ width: "100px", height: "100px" }} />
            </Badge>
        </Row>
    }
    const _buildProfileData = () => {
        return <>
            {device === "xs" ? <Column alignment="centered">
                {_buildProfilePic()}
                {_buildProfileName()}
            </Column> : <Row>
                <div style={{ width: 20 }} />
                {_buildProfilePic()}
                <div style={{ width: 20 }} />
                {_buildProfileName()}
            </Row>}
        </>
    }
    const _buildProfilePanel = () => {
        return <>
          
            <Row alignment="centered">
                {profile?.currentAccount?.includes("default") ? 
                <><Avatar style={{ backgroundColor: "#faa307" }}><Typography style={{ color: "white", fontWeight: "bold", fontSize: 20 }}>¢</Typography></Avatar>
                <div style={{ width: 15 }} />
                <Column >
                    <Typography style={{ fontWeight: "bold", textAlign: "center" }}>{profile?.bank?.toFixed(2) ?? "0"}</Typography>
                    <Typography style={{ fontWeight: "400", fontSize: "12px", textAlign: "center" }}>{`DTV Coins`}</Typography>

                </Column>
                <div style={{ width: 35 }} /></>:<></>}
                
                <Row style={{alignItems: 'center'}}>
                    <Typography>Manage Account</Typography>
                    <IconButton onClick={
                        ()=>{
                            if(accountType === 'serviceAgent'){
                                history(`/${profile.uid}/settings/agent?id=${profile.uid}&type=${accountType}`)
                            }else{
                                history(`/${profile.uid}/settings`)
                            }
                        }
                    }>
                        <Settings />
                    </IconButton>
                </Row>
            </Row>
        </>
    }
    const _buildProfileCard = (() => {
        return  loadingProfile ? <Skeleton
                    sx={{ bgcolor: 'grey.900' }}
                    variant="rectangular"
                    width={'100%'}
                    height={150}
                    style={{ margin: "20px 0" }}
                /> : <><Card variant="outlined" style={{ padding: "20px" }}> {device === "xs" ? <Column alignment="centered">
               
            
                {_buildProfileData()}
                <div style={{ height: 20 }} />
                <Column>
                    <Row alignment="centered">
                        {_buildProfilePanel()}
                    </Row>

                </Column>
            </Column> : <Row alignment="space-between">
                {_buildProfileData()}
                <Column>
                    <Row>
                        {_buildProfilePanel()}
                    </Row>

                </Column>
            </Row>} </Card>
            </> 
       
    })

  
    const _buildTimeFilter = (() => {
        return <>
            <Typography style={{ fontWeight: "bold", fontSize: "22px", textAlign: "left" }}>Studio Bookings</Typography>
            <Row>
                {/* <Chip label="Today" />
                            <div style={{ width: 10 }} />
                            <Chip label={new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(startDate)} onClick={()=>{
                              setIsDPOpen(!isDPOpen)
                            }}/> 
                            <>
                            {isDPOpen && (
                                    <DatePicker selected={startDate} onChange={(e)=>{ 
                                        setIsDPOpen(!isDPOpen);
                                        setStartDate(e);
                                    }
                                    
                                    } inline />
                                  )}
                            </>  */}
                <TextField
                    id="datetime-local"
                    label="Search date"
                    type="date"
                    defaultValue={`${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`}
                    className={classes.textField}
                    onChange={(e) => {
                        console.log(e.target.value)
                        setStartDate(new Date(e.target.value))
                    }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Row>
        </>
    })
    const _buildBookedHours = (() => {
        /**
         * Booking
         * id
         * hours
         * date
         * code
         */
        console.log(startDate)

        return <div>
            { loadingProfile ? <Skeleton
                    sx={{ bgcolor: 'grey.900' }}
                    variant="rectangular"
                    width={'100%'}
                    height={200}
                    style={{ margin: "20px 0" }}
                /> : 
               <>
               <Card variant='outlined' style={{ padding: 20 }}>
                <Column>
                    {device === "xs" ? <Column>
                        {_buildTimeFilter()}
                    </Column> :
                        <Row alignment="space-between">
                            {_buildTimeFilter()}
                        </Row>}
                    <div style={{ height: 20 }} />
                    {
                        loadingBookings ? <Skeleton
                            sx={{ bgcolor: 'grey.900' }}
                            variant="rectangular"
                            width={600}
                            height={200}
                            style={{ margin: 20 }}
                        /> :
                            (bookings.filter((val) => {
                                console.log(startDate.getMonth())
                                const m = startDate.getMonth()
                                return new Date(val.data().startTime).getMonth() >= m
                            }) ?? []).length <= 0 ? <Typography variant="caption" style={{ opacity: 0.5 }}> {`No Booking for ${new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long" }).format(startDate)}`}</Typography> :
                                <Swiper
                                    style={{
                                        width: "100%", "--swiper-navigation-color": "#fff",
                                        "--swiper-pagination-color": "#fff",
                                    }} effect="" grabCursor={true} centeredSlides={true} slidesPerView={device === "xs" ? 1 : 2} coverflowEffect={{
                                        "rotate": 30,
                                        "stretch": 0,

                                        "depth": 100,
                                        "modifier": 1,
                                        "slideShadows": false
                                    }} pagination={false} className="mySwiper"
                                >
                                    {
                                        bookings.filter((val) => {
                                            console.log(startDate.getMonth())
                                            const m = startDate.getMonth()
                                            return new Date(val.data().date).getMonth() >= m
                                        }).sort((a, b) => a.data().startTime > b.data().startTime).map((b, index) => <SwiperSlide key={index} style={{ margin: device === "xs" ? "0" : "0 20px" }}>
                                            <div key={`booked_${index}`} onClick={()=>{window.open(`${SiteData.siteLink}ticket:${profile.uid}_${b.data().bid}`,"_blank")}}>
                                            <BookingCard booking={(b.data())} uid={profile.uid} />
                                        </div></SwiperSlide>)}
                                </Swiper>}
                </Column>
                
            </Card>
            </>}
        </div>


    })
    const _buildServiceBookings = (() => {
        return <ServiceTracker profile={profile}/>
    })
    const _buildAplications = (() => {
        return <MyApplications profile={profile}/>
    })
    const handleTabChange = (index) => {
        localStorage.setItem('dtv-profileCurrentTab', index);
        updateCurrentTab(index);
    }
    const BuildDefaultProfile = ()=>{
        return  <Column style={{
            width: "100%", paddingTop: device !== "xs" ? "10vh" : "", margin: device !== "xs" ? " 0 auto" : "",
            maxWidth: " 1180px"
        }}>
            {_buildProfileCard()}
            <div style={{ height: 20 }} />
            <Row alignment="centered" style={{padding: 20}}>
                {SiteData.liveSections?.submissions ? <Chip color={currentTab === 0 ? 'primary':'default'}  variant={currentTab === 0 ? "filled" : "outlined"} label="My Videos" onClick={()=>handleTabChange(0)} />:<></>}
                <div style={{ width: 10 }} />
                {SiteData.liveSections?.story_submissions  ? <Chip color={currentTab === 1 ? 'primary':'default'}  variant={currentTab === 1 ? "filled" : "outlined"} label="My Stories" onClick={() => { handleTabChange(1) }} />:<></>}
                 <div style={{ width: 10 }} />
                {SiteData.liveSections?.bookings ? <Chip color={currentTab === 2 ? 'primary':'default'}  variant={currentTab === 2 ? "filled" : "outlined"} label="Bookings" onClick={() => { handleTabChange(2) }} />:<></>}
                <div style={{ width: 10 }} />
                {SiteData.liveSections?.application_submission ? <Chip color={currentTab === 3 ? 'primary':'default'}  variant={currentTab === 3 ? "filled" : "outlined"} label="My Applications" onClick={() => { handleTabChange(3) }} />:<></>}
                <div style={{ width: 10 }} />
                {SiteData.liveSections?.noir_studios ? <Chip color={currentTab === 4 ? 'primary':'default'}  variant={currentTab === 4 ? "filled" : "outlined"} label="Studio Bookings" onClick={() => { handleTabChange(4) }} />:<></>}
            </Row>
            <div style={{ height: 20 }} />
            {
                currentTab === 0 ? ( SiteData.liveSections?.submissions ? <MyVideosContent profile={profile}/>:<></>) 
                : currentTab === 1 ? (SiteData.liveSections?.story_submissions  ? <MyStoriesContent usePresistedFilters profile={profile}/>:<></>) 
                : currentTab === 2 ? (SiteData.liveSections?.bookings  ? _buildServiceBookings():<></>) 
                : currentTab === 3 ? (SiteData.liveSections?.application_submission  ? _buildAplications():<></>) 
                : (SiteData.liveSections?.noir_studios ?  _buildBookedHours():<></>)
            }
            
            <div style={{ height: 20 }} />
        </Column>
    }
    const BulidTabPage = ()=>{
        if(!profile)return;
            switch(accountType){
            case "serviceAgent": return <> 
                {
                    currentTab === 0 ? <BuildServiceAgentRequestsTab uid={profile.id} /> :
                    currentTab === 1 ? <BuildServiceAgentProjectsTab uid={profile.id} /> :
                    // currentTab == 1 ? <BuildServiceAgentFinanceTab profileId={profile.id} /> :
                    // currentTab == 2 ? <BuildServiceAgentStatisticsTab profileId={profile.id} /> :
                    //  <BuildServiceAgentReviewsTab profileId={profile.id} /> 
                    <></>

                }
            </>
            default: return <>
                 {
                currentTab === 0 ? ( SiteData.liveSections?.submissions ? <MyVideosContent profile={profile}/>:<></>) 
                : currentTab === 1 ? (SiteData.liveSections?.story_submissions  ? <MyStoriesContent usePresistedFilters profile={profile}/>:<></>) 
                : currentTab === 2 ? (SiteData.liveSections?.bookings  ? _buildServiceBookings():<></>) 
                : currentTab === 3 ? (SiteData.liveSections?.application_submission  ? _buildAplications():<></>) 
                : (SiteData.liveSections?.noir_studios ?  _buildBookedHours():<></>)
            }
            </>
        }
    }
    const BuildServiceProfile = ()=>{
        return  <Column style={{
            width: "100%", paddingTop: device !== "xs" ? "10vh" : "", margin: device !== "xs" ? " 0 auto" : "",
            maxWidth: " 1180px"
        }}>
            {_buildProfileCard()}
            <div style={{ height: 20 }} />
            <Row alignment="centered" style={{padding: 20}}>
                {SiteData.liveSections?.submissions ? <Chip color={currentTab === 0 ? 'primary':'default'}  variant={currentTab === 0 ? "filled" : "outlined"} label={accountType ==='serviceAgent' ? "Booking Requests" : "My Videos"} onClick={()=>handleTabChange(0)} />:<></>}
                <div style={{ width: 10 }} />
                {SiteData.liveSections?.story_submissions  ? <Chip color={currentTab === 1 ? 'primary':'default'}  variant={currentTab === 1 ? "filled" : "outlined"} label={accountType ==='serviceAgent' ? "Projects" :"My Stories"} onClick={() => { handleTabChange(1) }} />:<></>}
                 <div style={{ width: 10 }} />
                {SiteData.liveSections?.bookings ? <Chip color={currentTab === 2 ? 'primary':'default'}  variant={currentTab === 2 ? "filled" : "outlined"} label={accountType ==='serviceAgent' ? "Finance" : "Bookings"} onClick={() => { handleTabChange(2) }} />:<></>}
                <div style={{ width: 10 }} />
                {SiteData.liveSections?.application_submission ? <Chip color={currentTab === 3 ? 'primary':'default'}  variant={currentTab === 3 ? "filled" : "outlined"} label={accountType ==='serviceAgent' ? "Statistics" : "My Applications"} onClick={() => { handleTabChange(3) }} />:<></>}
                <div style={{ width: 10 }} />
                {(SiteData.liveSections?.noir_studios||accountType ==='serviceAgent') ? <Chip color={currentTab === 4 ? 'primary':'default'}  variant={currentTab === 4 ? "filled" : "outlined"} label={accountType ==='serviceAgent' ? "Reviews" :"Studio Bookings"} onClick={() => { handleTabChange(4) }} />:<></>}
            </Row>
            <div style={{ height: 20 }} />
             <BulidTabPage/>
            <div style={{ height: 20 }} />
        </Column>
    }
    const _buildPage = (() => {
        //...
        if(loading) return <Column style={{width:"100%", alignItems:'center', height:"100%"}}>
            <CircularProgress/>
        </Column>
        if(!profile)return <></>
    
        switch (accountType){
            case 'serviceAgent': return <BuildServiceProfile/>
            default: return <BuildDefaultProfile/>
        }
    });
  console.log(profile);
    return (<div className={classes.root}>
        <CssBaseline />
        <Stack>

            <div style={{ background: "#0000000d", heigóht: "100%", width: "100%" }}></div>
            <div style={{ background: "linear-gradient(0deg, black 20%,#0000009c 54%, transparent)", height: "100%", width: "100%" }}></div>
        
             {_buildPage()}
           
            
        </Stack>
        {<AppBarModule showCart pageIndex={1} hideSocials hideCartPrice/>}
        <Dialog
            maxWidth={device !== "xs" ? "md" : "xs"}
            onClose={() => { setShowModal(false) }}
            BackdropProps={{
                style: {
                    filter: "blur(10px)"
                }
            }}
            fullWidth
            aria-labelledby="confirmation-dialog-title"
            open={showModal}>
            {preview_body}
        </Dialog>
        <LoginRequired/>
    <ValidUserRequired/>
        <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            open={notification}
            autoHideDuration={notificationMsg.timeout}
            onClose={() => {
                showNotification(false)
            }}
            message={notificationMsg.message}
            action={
                <React.Fragment>
                    <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
                        <Close fontSize="small" />
                    </IconButton>
                </React.Fragment>
            }
        />
    </div>

    )

}
export default ProfilePage;

