import React, { createContext, useState } from "react";
import PropTypes from 'prop-types';

import { createTheme } from '@material-ui/core';
export const ThemeChangerContext = createContext();

export const Colors = {
  primary: "#faa307",
  primaryText: '#000000',
  secondary: "#ffba08",
}

 export default function ThemeChangerProvider (props){
   
     const darkTheme= createTheme({
        overrides:{
          MuiCssBaseline: {
            "@global": {
              body: {
                scrollbarColor: "#6b6b6b #2b2b2b",
                "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                  backgroundColor: "#2b2b2b",
                  width:2
                },}}},
        },
        palette:{
          type:"dark",
            background:{
            paper:'#080809',//"#1e1e1f",//#080809
            default:"#000000",//0A0A0B
          },
          text:{
            primary:"#ffffff",
            secondary:"rgba(255, 255, 255, 0.7)"
          },
          action:{
            selected:"rgba(255, 255, 255, 0.16)"
          },
          primary:{
            main:"#faa307",
            dark:"#ffba08",
          },
          secondary:{
            main:"#ffba08",
          }
        }
        
      })
      const lightTheme= createTheme({
        palette:{
          type:"light",
            background:{
              default:"#FEFEFE",
            paper:"#FFFFFF"
          },
          primary:{
            main:"#faa307",
          },
          secondary:{
            main:"rgb(203 124 11)",
          }
        }
        
      })
      const [currentTheme,setTheme] = useState(darkTheme);
      const updateTheme=(theme)=>{
            setTheme(theme === 'dark' ? darkTheme : lightTheme)
      }

     return <ThemeChangerContext.Provider value={
        {
             currentTheme,
            updateTheme
        }
     }>
         {props.children}
     </ThemeChangerContext.Provider>
 }

 
ThemeChangerProvider.propTypes = {
  children: PropTypes.node
}