/* eslint-disable no-undef */

export const YoutubeApi = {
    getChannel:"https://www.googleapis.com/youtube/v3/channels"
}
export const GoogleApi = {
    key:"AIzaSyDYu8lSe9CaFg_54r1ADlQF4KeoY_rIInc"
}
export const DTVApi = {
    url:"https://dearfachtv-server.herokuapp.com",
    testUrl:"http://localhost:3300"
}
export const baseUrl = `${process.env.REACT_APP_SERVER_URL}/${process.env.REACT_APP_BASE_URL}`;
export const RouteNames = {
    health: "health",
    media: "media",
    paypalPayment: "payments/paypal",
    stripePaymentCustomer: "payments/customer",
    stripePayment: "payment",
    stripeServiceAccount: "payments/serviceAccount",
    stripePayout: "payments/payout",
    stripeInvoice: "payments/invoice",
    authAdmin: "auth/admin",
    authProfile: "auth/profile",
    authAdminRegister: "auth/admin/register",
    authAdminLogin: "auth/admin/login",
    authAdminLogout: "auth/admin/logout",
    authAdminConfirm: "auth/admin/confirm",
    authAdminReset: "auth/admin/reset",
    authAdminAvailability: "auth/admin/availability",
    authAdminList: "auth/admin/list",
    authUsers: "auth/users",
    admin: "admin",
    show: "show",
    showList: "show/list",
    showListSize: "show/list/size",
    showSearch: "show/search",
    showEpisodes: "show/episodes/list",
    showSearchEpisodes: "show/episodes/search",
    showSeasonEpisodes: "show/season/episodes/list",
    showComingSoon: "show/season/soon",
    episode: "episode",
    episodeList: "episode/list",
    episodeListSize: "episode/list/size",
    watchListShow: "watchlist/shows/list",
    watchList: "watchlist/list",
    watchListSize: "watchlist/list/size",
    watchListConitinue: "watchlist/continueWatching",
    watchListConitinueList: "watchlist/continueWatching/list",
    video: "video",
    videoList: "video/list",
    videoListAll: "video/listAll",
    videoSize: "video/list/size",
    videoSearch: "video/search",
    videoSearchSize: "video/search/size",
    story: "story",
    storyList: "story/list",
    storyListByUsers: "story/list/orderByUsers",
    storyListSize: "story/list/size",
    storyRelatedList: "story/related/list",
    storySearch: "story/search",
    storyDraftList: "story/draft/list",
    storyDraft: "story/draft",
    storyLike: "user/story/like",
    storyView: "user/story/view",
    storyApprove: "story/approve",
    storyUserStory: "user/story/list",
    storyUserDraft: "user/story/draft",
    storyUserPublished: "user/story/published",
    storyUserDraftList: "user/story/draft/list",
    storyUserDraftListSize: "user/story/draft/list/size",
    storyUserDraftSearch: "user/story/draft/search",
    storyOrgStory: "org/story/list",
    storyUserStorySize: "user/story/list/size",
    storyListFilterDate: "story/list/filters/date",
    storyListFilterOrg: "story/list/filters/org",
    storyListFilterDtv: "story/list/filters/dtv",
    storyListFilterAuthor: "story/list/filters/author",
    storyListFilterRelatedByAuthor: "story/list/filters/related",
    storyListFilterFreelance: "story/list/filters/freelance",
    storyListFilterPopular: "story/list/filters/popular",
    storyListFilterTrending: "story/list/filters/trending",
    storyListFilterPublished: "story/list/filters/published",
    storyListFilterPublishedAt: "story/list/filters/publishedAt",
    submissionVideo: "submission/video",
    submissionStory: "submission/story",
    submisisonVideoList: "submission/video/list",
    submissionStoryList: "submission/story/list",
    submissionStoryListSize: "submission/story/list/size",
    submissionStorySearch: "submission/story/search",
    submissionVideoListSize: "submission/video/list/size",
    submissionVideoSearch: "submission/video/search",
    submissionVideoListNew: "submission/video/list/new",
    submissionStoryListNew: "submission/story/list/new",
    submissionStoryListNewSize: "submission/story/list/new/size",
    submissionVideoListNewSize: "submission/video/list/new/size",
    submissionStorySearchNew: "submission/story/search/new",
    submissionStoryFromUser: "submission/user/story/list",
    submissionVideoFromUser: "submission/user/video/list",
    submissionSearchVideoFromUser: "submission/user/video/search",
    submissionSearchStoryFromUser: "submission/user/story/search",
    submissionVideoSearchNew: "submission/video/search/new",
    submissisonVideoListOld: "submission/video/list/old",
    submissionStoryListOld: "submission/story/list/old",
    submissionStoryListOldSize: "submission/story/list/old/size",
    submissionVideoListOldSize: "submission/video/list/old/size",
    submissionStorySearchOld: "submission/story/search/old",
    submissionVideoSearchOld: "submission/video/search/old",
    submissionApproveVideo: "submission/video/approve",
    submissionApproveStory: "submission/story/approve",
    submissionVideoSeen: "submission/video/seen",
    submissionStorySeen: "submission/story/seen",
    submissionVideoPublish: "submission/video/publish",
    submissionVideosPublished: "submission/video/publish/list",
    submissionPublishStory: "submission/story/publish",
    submissionVideoPackage: "submission/video/package",
    submissionStoryPackage: "submission/story/package",
    submissionVideoPackageList: "submission/video/package/list",
    submissionStoryPackageList: "submission/story/package/list",
    submissionStoryPackageSearch: "submission/story/package/search",
    submissionVideoPackageSearch: "submission/video/package/search",
    submissionStoryPackagesByUser: "submission/user/story/package/list",
    submissionVideoPackagesByUser: "submission/user/video/package/list",
    event: "event",
    eventList: "event/list",
    eventListSize: "event/list/size",
    eventSearch: "event/search",
    eventAsObject: "event/object",
    eventSearchDate: "event/search/date",
    playlist: "playlist",
    playlistList: "playlist/list",
    playlistListSize: "playlist/list/size",
    playlistSearch: "playlist/search",
    organisation: "organisation",
    organisationList: "organisation/list",
    organisationSearch: "organisation/search",
    organisationUser: "organisation/user/list",
    organisationMembers: "organisation/members",
    organisationMembersInvite: "organisation/members/invite",
    organisationMembersSearch: "organisation/members/search",
    organisationRole: "organisation/role",
    organisationRoles: "organisation/roles",
    organisationRolesSearch: "organisation/roles/search",
    organisationMemberRoleChange: "organisation/member/role",
    readingList: "readingList",
    conductReport: "reports/conduct",
    conductReportList: "reports/conduct/list",
    reportOffence: "reports/offence",
    reportOffenceList: "reports/offence/list",
    checkout: "checkout",
    checkoutList: "checkout/list",
    checkoutClear: "checkout/clear",
    checkoutTransaction: "checkout/transaction",
    checkoutTransactionList: "checkout/transaction/list",
    checkoutSearchCart: "checkout/search/cart",
    checkoutSearchTransaction: "checkout/search/transaction",
    canActInOrganisation: "organisation/ability/canAct",

}