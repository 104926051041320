import { useState } from "react"
import firebase_app from "../config/firebase";


const useServiceBooking = () => {
    const [serviceAgents, setServiceAgents] = useState({});
    const [serviceRequests, setServiceRequests] = useState([]);
    const [loading, setLoading] = useState(false);

    const getAgents = async () => {

        const agents = await firebase_app.database().ref('serviceAgents').once('value');
        setServiceAgents(agents.val());
       return agents.val();
    }
    const getAgent = async (id) => {
        const agent = await firebase_app.database().ref('serviceAgents').child(id).once('value');
        return agent.val();
     }
     const streamAgent = (id, callback)=>{
        firebase_app.database().ref('serviceAgents').child(id).on('value', (snapshot)=>{
            if(callback)
            callback(snapshot.val());
        });
     }
    const getAgentByAgentId = async (id) => {
        const agent = await firebase_app.database().ref('serviceAgents').orderByChild('agentId').equalTo(id).once('value');
        const data = agent.val();
        return data ? Object.values(data)[0] : null;
    }
    const getRequest = async (id) => {
        setLoading(true);
        try{
            const request = await firebase_app.database().ref('serviceRequests').child(id).once('value');
            setLoading(false);
            return request.val();
        }catch(e){
            console.log(e);
        }
        setLoading(false);
    }
    const getRequests = async () => {
        const requests = await firebase_app.database().ref('serviceRequests').once('value');
        setServiceRequests(requests.val());
        return requests.val();
    }
    const getRequestsByUser = async (id) => {
        setLoading(true);
        try{
            const requests = await firebase_app.database().ref('serviceRequests').orderByChild('uid').equalTo(id).once('value');
            setLoading(false);
            return requests.val();
        }catch(e){
            console.log(e);
        }
        
    }
    const getRequestsForAgent = async (id,ignorePending) => {
        setLoading(true);
        try{
            const requests = await firebase_app.database().ref('serviceRequests').orderByChild('agentId').equalTo(id).once('value');
            setLoading(false); 
            const data = requests.val();
            const result = Object.values(data).filter((item)=>ignorePending?(item.status !== "pending"):true);
            console.log(result);
            return result;
        }catch(e){
            console.log(e);
            
        }
       
    }
    const updateRequest = async (id, update) => {
        await firebase_app.database().ref('serviceRequests').child(id).update(update);
        return await getRequests();
     }
     
    const streamRequests =  (serviceId, callback)=>{
        firebase_app.database().ref('serviceRequests').child(serviceId).on('value', (snapshot)=>{
            if(callback)
            callback(snapshot.val());
        });
    }

    const streamAgentRequests =  (agentId, callback)=>{
        firebase_app.database().ref('serviceRequests').orderByChild("pending").orderByChild('agentId').equalTo(agentId).on('value', (snapshot)=>{
            if(callback)
            callback(snapshot.val());
        });
    }
    const streamAgentsProject =  (agentId, callback, ignorePending = false)=>{
       
        firebase_app.database().ref('serviceRequests').orderByChild('agentId').equalTo(agentId).on('value', (snapshot)=>{
            if(callback)
            {
                const data = snapshot.val();
                const result = Object.values(data).filter((item)=>ignorePending?(item.status !== "pending"):true);
                
                callback(result);
            }
        });
    }
    const streamAgents =  (callback)=>{
        firebase_app.database().ref().child('serviceAgents').on('value', (snapshot)=>{
            if(callback)
            callback(snapshot.val());
        });
    }
     const updateAgent = async (id, update) => {
        await firebase_app.database().ref('serviceAgents').child(id).update(update);
        return await getAgents();
        }
    const addRequest = async (newRequest) => {
        await firebase_app.database().ref('serviceRequests').child(newRequest.id).set(newRequest);
        const purchasedBy = (await firebase_app.database().ref('ServicePackages').child(newRequest.packageId).once('value')).val()?.purchasedBy??[];
        await firebase_app.database().ref('ServicePackages').update({'purchasedBy':[...purchasedBy,newRequest.uid]});

    }
    const addAgent = async ({id,name,email,serviceType,bio,portfolio,reviews,minPrice,maxPrice,verified,location,picture}) => {
        const agent = {
            id:id,
            name: name,
            email: email,
            serviceType:serviceType,
            bio:bio,
            portfolio:portfolio,
            reviews:reviews,
            minPrice:minPrice,
            maxPrice:maxPrice,
            verified:verified,
            location:location,
            picture:picture,
            createdAt: Date.now()
        }
        const profile = {
            uid:id,
            fname: name,
            email: email,
            picture:picture,
            wallet: 0,
            role: 'serviceAgent',
            createdAt: Date.now()
        }
        await firebase_app.database().ref('serviceAgents').child(id).set(agent);
        if(((await firebase_app.database().ref('Users').child(id).once('value'))).val() === null){
            await firebase_app.database().ref('Users').child(id).set(profile);
        }else{
            await firebase_app.database().ref('Users').child(id).update({role:'serviceAgent'});
        }
        getAgents();
    }
    const getServiceTypes = async () => {
        const types = await firebase_app.database().ref('ServiceTypes').once('value');
        return types.val();
    }
    return {
        serviceAgents,
        serviceRequests,
        updateRequest,
        getAgents,
        getAgent,
        getAgentByAgentId,
        addAgent,
        getRequest,
        getRequests,
        addRequest,
        setServiceAgents,
        loading,
        getRequestsByUser,
        getRequestsForAgent,
        updateAgent,
        streamRequests,
        streamAgentRequests,
        streamAgentsProject,
        getServiceTypes,
        streamAgents,
        streamAgent
    }
};

export default useServiceBooking;