import React,{ useContext, createContext, useState } from "react"
import PropTypes from 'prop-types';
import {uploadToAWS} from "../config/aws_config";
import { useSnackbar } from "notistack";
import { isValidLink } from "../utils";

export const StorageHandlerContext = createContext();
export const useStorage = ()=>useContext(StorageHandlerContext);
export const StorageHandlerProvider = ((props)=>{
    
  const { enqueueSnackbar, } = useSnackbar();
  const [uploading,setUploading] = useState(false);
    const [media,] = useState({});
    const [progress,setProgress] = useState(0);
    const [progressMsg, setProgressMsg] = useState("Please wait...");
    const [errorProgress,setErrorProgress] = useState(null);
    const [notifyMe,] = useState(false);
    const [notification,] = useState({});
   
    const uploadS3 = async (dir,file,onUploading,onError)=>{
        var fileName = file?.file?.name??dir??`MEDIA_${Date.now()}`;
        
          var encodedDir = encodeURIComponent(dir) + "/";
          const searchRegExp = /%2F/g;
        const replaceWith = "/";
        const searchRegExp2 = /%20/g;
        const replaceWith2 = "_";
        
          var key = encodedDir.replace(searchRegExp,replaceWith).replace(searchRegExp2,replaceWith2) + fileName;
          console.log("KEY: ", key);
          return uploadToAWS({key:key,file:file.file},onUploading,onError)
      }
      
      const uploadImages = async ({size, images, onCurrentlyPlaying = ()=>{}, onCompletedUpload = ()=>{},label, onProgressChange = ()=>{}}, index = 0)=>{
        if(size===0)return;
        console.log(typeof images[size-1].blob )
        if(images[size-1].blob){
            console.log("uploading");
            onCurrentlyPlaying(true);
            images[size-1].file = await uploadMediaOperation(images[size-1],label,(p)=>{
              onProgressChange(p);
              setProgress(p);
            });
            onCurrentlyPlaying(false)
            images[size-1] =  images[size-1].file;
        }
            onCompletedUpload(images[size-1],index) 
                return uploadImages({size:size-1,images, onCurrentlyPlaying, onCompletedUpload,label},index+1)
           
        }
    const uploadMediaOperation = async (image,dir,onProgress)=>{
    console.log(image)
    if(isValidLink(image?.blob)) return image.blob;
    try{
        dir=dir??`MEDIA_${Date.now()}`
        console.log("Uploading...");
        setUploading(true)
        let upload = await uploadS3(dir,image,
        ( )=>{
          console.log("PROGRESS: " + progress);
          if(onProgress)
            onProgress(progress);
          setProgressMsg('Uploading Image: '+progress+"%")
          setProgress(progress);
         
          },(e)=>{
            
            if(e)
              setErrorProgress(e)
          })
           setProgress(0)
          
          setProgressMsg('')
          setUploading(false)
            return upload;
        }catch(e){
        console.log("ERROr: "+e)
        setErrorProgress("An Error Occured: "+e.message)
        enqueueSnackbar("An Error Occured: "+e.message,{ variant: 'failure', anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
      },})
         setUploading(false)
        }
}
    return <StorageHandlerContext.Provider value={
        {
            uploading,
            media,
            progress,
            progressMsg,
            notifyMe,
            errorProgress,
            notification,
            setProgressMsg,
            setUploading,
            setProgress,
            uploadMediaOperation,
            uploadImages
        }
    }>
        {props.children}
    </StorageHandlerContext.Provider>
})


StorageHandlerProvider.propTypes={
    media:PropTypes.string,
    dir:PropTypes.string,
    progress:PropTypes.number,
    children:PropTypes.node,
}