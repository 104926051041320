/* eslint-disable react-hooks/exhaustive-deps */

import { Avatar, Card, Dialog, alpha, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, makeStyles, Typography, useTheme } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MicTwoTone, Videocam, Visibility, YouTube } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import logo from '../assets/logo.png';
import Center from '../components/Center';
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { SiteDataContext } from '../config/SiteData';
import "../index.css";

import { motion } from 'framer-motion';
import NumberFormat from 'react-number-format';
import { AppBarModule } from '../components/_app_bar_mod';
import { MobileShowcaseModule } from '../components/_mobile_showcase_mod';
import { ShowcaseCard } from '../components/_showcase_card';
import { SocailsList, SocialsGrid } from '../components/_socail_mod';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    }
  },
  iconBtn: {
    '&:hover': {
      color: "white"
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      color: "white"
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


const AboutUsPage = () => {

  const {
    SiteData,
    reloadsiteData
  } = useContext(SiteDataContext)
  const classes = useStyles();
  const theme = useTheme();
  const [device, setDevice] = useState('pc');
  const [, setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body, updatePreviewBody] = useState(<></>);
  const [youtubeData,] = useState({});

  useEffect(() => {
    updateDevice();
    reloadsiteData();
    window.addEventListener("resize", updateDevice);
  }, [SiteData.aboutPage.featuredCover])
  const updateDevice = () => {
    if (window.innerWidth < 550) {
      setDevice('mobile');
      setDrawerWidth(300)
    }
    else {
      setDevice('pc')
      setDrawerWidth(280)
    }
  }
  // const getSubscribers = () => {
  //   console.log(`https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${SiteData.channelId}&key=${GoogleApi.key}`)
  //     fetch(`https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${SiteData.channelId}&key=${GoogleApi.key}`)
  //     .then(response => {
  //         return response.json()
  //     })
  //     .then(data => {
  //         console.log(data);
  //         setYoutubeData(data.items[0].statistics);
  //     }).catch(err=>{
  //         console.log(err);
  //     })
    
  //   }
  const _buildPage = () => {

    return (
      <Column>
        <div style={{ width: "100%", height: "100vh" }}>
          <Stack>
            {
              <Column alignment={"end"} style={{ width: "100%", height: "100vh", padding: device === 'pc' ? "10vh" : "10vh 4vh", marginBottom: device === 'pc' ? "12vh" : "5vh", }}>
                <motion.label
                  initial={{ x: 60, opacity: 0.2 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ ease: "linear", fontSize: "6.1vw", duration: 0.5 }}
                  style={{ fontFamily: "Pridi", textAlign: device === 'pc' ? "left" : 'center', fontSize: device === "pc" ? "3vw" : "6vw", margin: "2vw 0", color: alpha(theme.palette.text.primary, 1) }}>{SiteData.aboutPage.title}</motion.label>
                <motion.label
                  initial={{ x: 70, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ ease: "linear", fontSize: "6.1vw", duration: 0.5, delay: 0.5 }}
                  style={{ fontFamily: "Zilla Slab Highlight", textAlign: device === 'pc' ? "left" : 'center', margin: "2vw 0", fontSize: device === "pc" ? "4vw" : "9vw", color: alpha(theme.palette.text.primary, 1) }}>{SiteData.aboutPage.subtitle}</motion.label>
                <motion.label
                  initial={{ x: 80, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ ease: "linear", duration: 0.5, delay: 0.3 }}
                  style={{ fontFamily: "Pridi", fontSize: device === "pc" ? "1.5vw" : "3vw", textAlign: device === 'pc' ? "left" : 'center', paddingRight: "2vw", color: alpha(theme.palette.text.primary, 0.5) }}>{SiteData.aboutPage.about}</motion.label>
              </Column>
           }
            <Column style={{ width: "100%" }}>
              <div style={{ width: "100%", height: "100vh"}}></div>
              {!SiteData.aboutPage?.showcase ? <></> : <div style={{ width: "100%", height: "100%", backgroundColor: theme.palette.background.default, padding: device === 'pc' ? "14vh 12vh" : '8vh 2vh' }}>
                <Column alignment="start" style={{ width: "100%", height: window.innerHeight - (device === 'pc' ? 150 : 70) }}>

                  <motion.label
                    initial={{ x: 60, opacity: 0.2 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ ease: "linear", duration: 0.5 }}
                    style={{ fontFamily: "Pridi", letterSpacing: "10px", fontWeight: "lighter", textAlign: device === 'pc' ? "left" : "center", fontSize: device === 'pc' ? "2vw" : "4.4vw", color: alpha(theme.palette.text.primary, 1) }}>
                    SHOWCASE
                  </motion.label>
                  <div style={{ margin: "40px" }}></div>
                  <div style={{ margin: device === 'pc' ? "10px" : "0", height: "100%", width: "100%" }}>
                    {device === 'pc' ? _buildShowCaseGrid() : _buildMobileShowCaseGrid()}
                  </div>
                </Column>
              </div>}
             
                <div style={{ width: "100%", height: "100%", backgroundColor: alpha(theme.palette.background.default, 1), padding: device === 'pc' ? "14vh 12vh" : '8vh 2vh' }}>
                <Column alignment="start" style={{ width: "100%" }}>
 {
                !SiteData.aboutPage?.team ? <></>:<>
                  <motion.label
                    initial={{ x: 60, opacity: 0.2 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ ease: "linear", fontSize: "6.1vw", duration: 0.5 }}
                    style={{ fontFamily: "Pridi", letterSpacing: "3px", fontWeight: "lighter", textAlign: device === 'pc' ? "left" : "center", fontSize: device === 'pc' ? "2vw" : "4.4vw", color: alpha(theme.palette.text.primary, 1) }}>
                    MEET THE TEAM
                  </motion.label>
                  <div style={{ margin: "20px 0" }}></div>
                  <div style={{ height: "100%", width: "100%" }}>
                    {_buildTeam()}
                  </div>
                  <div style={{ margin: "20px 0" }}></div>
                  </>}
                  {!SiteData.aboutPage?.accolades ? <></> : <>
                  <motion.label
                    initial={{ x: 60, opacity: 0.2 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ ease: "linear", fontSize: "6.1vw", duration: 0.5 }}
                    style={{ fontFamily: "Pridi", letterSpacing: "3px", fontWeight: "lighter", textAlign: device === 'pc' ? "left" : "center", fontSize: device === 'pc' ? "2vw" : "4.4vw", color: alpha(theme.palette.text.primary, 1) }}>
                    Accolades
                  </motion.label>
                  <div style={{ margin: "20px 0" }}></div>
                  <Row alignment="centered">
                    <Grid container spacing={5} style={{ width: "100%" }}>
                      <Grid xs={device === 'pc' ? 3 : 12} style={{ width: device === 'pc' ? "90%" : "100%" }}>
                        <Card style={{ width: device === 'pc' ? "90%" : "100%", backgroundColor: "#ffffff11", padding: "1vw" }}>
                          <Column>
                            <Row alignment="centered" style={{ width: "100%", padding: "10px", color: alpha(theme.palette.text.primary, 1) }}><YouTube style={{ fontSize: device === 'pc' ? "4vw" : "5vh", color: "#ff9c07" }} /></Row>
                            <Row alignment="centered">
                              <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 1) }} ><NumberFormat value={youtubeData?.subscriberCount ?? SiteData.aboutPage.accolades.youtube.subscribers} style={{ fontSize: device === 'pc' ? "1.3vw" : "3.4vw", color: alpha(theme.palette.text.primary, 1) }} displayType={"text"} thousandSeparator={true} /> Subscribers</Typography>
                            </Row>
                          </Column>
                        </Card>
                      </Grid>
                      <Grid xs={device === 'pc' ? 3 : 12} style={{ width: device === 'pc' ? "90%" : "100%" }}>
                        <Card style={{ width: device === 'pc' ? "90%" : "100%", backgroundColor: "#ffffff11", padding: "1vw" }}>
                          <Column>
                            <Row alignment="centered" style={{ width: "100%", padding: "10px", color: alpha(theme.palette.text.primary, 1) }}><Visibility style={{ fontSize: device === 'pc' ? "4vw" : "5vh", color: "#ff9c07" }} /></Row>
                            <Row alignment="centered">
                              <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 1) }} ><NumberFormat value={youtubeData?.viewCount ?? SiteData.aboutPage.accolades.youtube.views} style={{ fontSize: device === 'pc' ? "1.3vw" : "4vw", color: alpha(theme.palette.text.primary, 1) }} displayType={"text"} thousandSeparator={true} /> Views</Typography>
                            </Row>
                          </Column>
                        </Card>
                      </Grid>
                      <Grid xs={device === 'pc' ? 3 : 12} style={{ width: device === 'pc' ? "90%" : "100%" }}>
                        <Card style={{ width: device === 'pc' ? "90%" : "100%", backgroundColor: "#ffffff11", padding: "1vw" }}>
                          <Column>
                            <Row alignment="centered" style={{ width: "100%", padding: "10px", color: alpha(theme.palette.text.primary, 1) }}><Videocam style={{ fontSize: device === 'pc' ? "4vw" : "5vh", color: "#ff9c07" }} /></Row>
                            <Row alignment="centered">
                              <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 1) }} ><NumberFormat value={youtubeData?.videoCount ?? SiteData.aboutPage.accolades.youtube.videos} style={{ fontSize: device === 'pc' ? "1.3vw" : "4vw", color: alpha(theme.palette.text.primary, 1) }} displayType={"text"} thousandSeparator={true} /> Videos</Typography>
                            </Row>
                          </Column>
                        </Card>
                      </Grid>
                      <Grid xs={device === 'pc' ? 3 : 12} style={{ width: device === 'pc' ? "90%" : "100%" }}>
                        <Card style={{ width: device === 'pc' ? "90%" : "100%", backgroundColor: "#ffffff11", padding: "1vw" }}>
                          <Column>
                            <Row alignment="centered" style={{ width: "100%", padding: "10px", color: alpha(theme.palette.text.primary, 1) }}><MicTwoTone style={{ fontSize: device === 'pc' ? "4vw" : "5vh", color: "#ff9c07" }} /></Row>
                            <Row alignment="centered">
                              <Typography variant="overline" style={{ color: alpha(theme.palette.text.primary, 1) }} ><NumberFormat value={SiteData.aboutPage.accolades.youtube.artists} style={{ fontSize: device === 'pc' ? "1.3vw" : "4vw", color: alpha(theme.palette.text.primary, 1) }} displayType={"text"} thousandSeparator={true} /> Artists</Typography>
                            </Row>
                          </Column>
                        </Card>
                      </Grid>
                    </Grid>

                  </Row>
                  </>}
                </Column>
              </div>

              <Row alignment="space-between" style={{ width: "100%", height: "100%", padding: "2vw", backgroundColor: "black", }}>
                <motion.div
                  initial={{ x: 60, opacity: 0.2 }}
                  animate={{ x: 0, opacity: 1 }}
                  transition={{ ease: "linear", fontSize: "6.1vw", duration: 0.5 }}>
                  {device === 'pc' ? <Row>
                    <Avatar src={logo} className={classes.logo} />
                    <Column >
                      <motion.label style={{ fontFamily: "Pridi", letterSpacing: "3px", fontWeight: "lighter", textAlign: "left", fontSize: "2vw", color: "#ffffff99" }}>
                        {SiteData.name.toUpperCase()}
                      </motion.label>
                    </Column>
                  </Row> : <></>}
                </motion.div>
                <motion.div>
                  <Center>
                    <Row>
                      <motion.label className={classes.link} align="center" style={{ marginRight: "12px", textDecoration: "underline", fontSize: `${device === 'pc' ? '1.1vh' : '1.2vh'}` }}>
                        {"TERMS OF USE"}
                      </motion.label>
                      <motion.label className={classes.link} align="center" style={{ marginRight: "12px", textDecoration: "underline", fontSize: `${device === 'pc' ? '1.1vh' : '1.2vh'}` }}>
                        {"PRIVACY & POLICY"}
                      </motion.label>
                      {device === 'pc' ? <Typography variant="caption" align="center" style={{ color: "grey", fontSize: `${device === 'pc' ? '1.1vh' : '1.2vh'}` }}>
                        {SiteData.copyright}
                      </Typography> : <></>}
                    </Row>

                  </Center>

                </motion.div>
              </Row>
            </Column>
          </Stack>
        </div>

      </Column>

    )
  }
  const _buildTeam = () => {
    return <>
      <Grid container spacing={3}>
        {
          SiteData.aboutPage.team.map((team, index) => (
            <Grid key={team?.id ?? index} xs={device === 'pc' ? 6 : 12}>
              {
                <List className={classes.listItem}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar src={team.image} className={classes.listItemImage} />
                    </ListItemAvatar>
                    <Column>
                      <motion.label className={classes.listItemText} style={{ fontFamily: "Pridi", textAlign: "left" }}>
                        {team.name}
                      </motion.label>
                      <motion.label className={classes.listItemText} style={{ fontFamily: "Pridi", textAlign: "left", color: "#ffffff99" }}>
                        {team.desc}
                      </motion.label>
                    </Column>
                    <ListItemSecondaryAction style={{ width: device === 'pc' ? "" : "20%", height: "100%" }}>
                      {

                        device === 'pc' ? <Center style={{ width: "100%", height: "100%" }}>
                          {
                            <SocailsList alignment="right"
                              className={classes.socialIcon}
                              facebook={team.facebook}
                              instagram={team.instagram}
                              twitter={team.twitter}
                              fontSize="28px" />
                          }

                        </Center> : <Center style={{ width: "100%", height: "100%" }}>
                          {
                            <SocialsGrid spacing={2}
                              className={classes.socialIcon}
                              facebook={team.facebook}
                              instagram={team.instagram}
                              twitter={team.twitter}
                              fontSize="25px" />
                          }

                        </Center>
                      }
                    </ListItemSecondaryAction>
                  </ListItem>

                </List>
              }
            </Grid>
          ))
        }

      </Grid>
    </>
  }
  const _buildShowCaseCard = (index) => (
    <ShowcaseCard image={SiteData.aboutPage.showcase[index].image}
      video={SiteData.aboutPage.showcase[index].video}
      caption={SiteData.aboutPage.showcase[index].caption}
      body={SiteData.aboutPage.showcase[index].body}
      fontFraction={index < 1 ? "100%" : "50%"}
      hoverPlay={true}
      onClick={() => {
        updatePreviewBody(
          <Row style={{ width: "100%", height: "600px", maxHeight: "600px", background: "black" }}>
            <div style={{ width: SiteData.aboutPage.showcase[index].body ? "70%" : "100%", height: "100%" }}>
              {
                SiteData.aboutPage.showcase[index].video ?
                  <ReactPlayer playsinline playing={false}
                    url={SiteData.aboutPage.showcase[index].video}
                    width='100%'
                    height='100%'
                    controls={true}
                    config={{
                      youtube: {
                        playerVars: { showinfo: 1 }
                      },
                    }}
                  /> : <img alt="showcase" width='100%'
                    height='100%' style={{ objectFit: "cover" }} src={SiteData.aboutPage.showcase[index].image} />

              }
            </div>
            {SiteData.aboutPage.showcase[index].body ? <Column style={{ width: "30%", padding: "20px", overflowY: "scroll" }}>
              <Typography variant="overline" style={{ color: "#ffffff" }} >SHOWCASE</Typography>
              <Typography style={{ color: "#ffffff", fontSize: "20px", fontWeight: "bold", margin: "10px 0" }} >{SiteData.aboutPage.showcase[index].caption}</Typography>
              <Typography style={{ color: "#ffffffac" }} >{SiteData.aboutPage.showcase[index].body}</Typography>
            </Column> : <></>}
          </Row>
        )
        setShowModal(true)
      }}
    //className={index!=0?classes.grow:""}
    />
  )
  const _buildMobileShowCaseGrid = () => {
    return <MobileShowcaseModule showcase={SiteData.aboutPage.showcase} />
  }
  const _buildShowCaseGrid = () => {
    switch (SiteData.aboutPage?.showcase?.length??0) {
      case 0: return <></>;
      case 1: return <>
        <Grid container spacing={3} style={{ width: "100%", height: "100%", padding: "0" }}>
          <Grid item xs={12} style={{ padding: 0 }}>

            {_buildShowCaseCard(0)}

          </Grid>
        </Grid>
      </>;
      case 2: return <>
        <Grid container spacing={3} style={{ width: "100%", height: "100%", padding: "0" }}>
          <Grid item xs={6} >

            {_buildShowCaseCard(0)}

          </Grid>
          <Grid item xs={6}>

            {_buildShowCaseCard(1)}

          </Grid>

        </Grid></>;
      case 3: return <>
        <Grid container spacing={3} style={{ width: "100%", height: "100%", padding: "0" }}>
          <Grid item xs={4} >

            {_buildShowCaseCard(0)}

          </Grid>
          <Grid item xs={4}>

            {_buildShowCaseCard(1)}

          </Grid>
          <Grid item xs={4}>

            {_buildShowCaseCard(2)}

          </Grid>

        </Grid></>;
      case 4: return <>
        <Grid container spacing={3} style={{ width: "100%", height: "100%", padding: "0" }}>
          <Grid item xs={6} >

            {_buildShowCaseCard(0)}

          </Grid>
          <Grid item xs={6}>

            {_buildShowCaseCard(1)}

          </Grid>
          <Grid item xs={6}>

            {_buildShowCaseCard(2)}

          </Grid>
          <Grid item xs={6}>

            {_buildShowCaseCard(3)}

          </Grid>
        </Grid></>;
      case 5: return <>
        <Grid container spacing={3} style={{ width: "100%", height: "100%", padding: "0" }}>
          <Grid item xs={6} >

            {_buildShowCaseCard(0)}

          </Grid>
          <Grid container spacing={3} xs={6} style={{ width: "100%", height: "100%", padding: "10px" }}>
            <Grid item xs={6}>

              {_buildShowCaseCard(1)}

            </Grid>
            <Grid item xs={6}>

              {_buildShowCaseCard(2)}

            </Grid>
            <Grid item xs={6}>

              {_buildShowCaseCard(3)}

            </Grid>

            <Grid item xs={6}>

              {_buildShowCaseCard(4)}

            </Grid>
          </Grid>

        </Grid></>;
      default: return <></>
    }
  }

  return (<div className={classes.root}>
    <CssBaseline />
    <Stack>
      <div style={{ height: "100%", 
      position: "relative",
      width: "100%", /* 16:9 aspect ratio */
      overflow: "hidden",
      background: alpha(theme.palette.background.default, 1), }}>
        <div 
          style={
            {position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,}
          }
        >{SiteData.aboutPage?.isVideo ? 
       
         <ReactPlayer playsinline width = "100%" height = "100%" url={SiteData.aboutPage?.featuredCover}
          autoPlay={true}
          playing={true} 
            controls={false}
          loop={true}
          muted={true}
          style={{overflow:"hidden", objectFit: "cover", transform: `scale(${device==='mobile'? '4.5': '1.5'})` }}
          className="react-player" /> : <img alt="featured" src={SiteData.aboutPage?.featuredCover} width="100%" height="100%" style={{ objectFit: "cover" }} />}</div>
      </div>
      <div style={{ background: alpha(theme.palette.background.default, 0.1), height: "100vh", width: "100%" }}></div>
      <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 7)} 20%,${alpha(theme.palette.background.default, 0.5)}54%, transparent)`, height: "100%", width: "100%" }}></div>

      {_buildPage()}
    </Stack>
    {<AppBarModule showCart pageIndex={1} />}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
  </div>)


}
export default AboutUsPage;