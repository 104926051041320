/* eslint-disable react-hooks/exhaustive-deps */

import  {Button,Card, CardContent, Accordion, AccordionSummary, Typography, AccordionDetails, TextField, CardActionArea } from "@material-ui/core";
import { TimeAgo } from "../utils";
import React,{ useEffect, useRef, useState } from "react";
import { ProfileAvatar } from "./profile/main/_profile_avatar";
import Row from "../components/Row";
import Column from "../components/Column";
import { useChatMessanger } from "../hooks/useChatMessenger";
import shortUUID from "short-uuid";
import { useSnackbar } from "notistack";
import MessageTile from "./_message_tile";
import { CustomScrollview } from "./custom_scrollview";
import PropTypes from 'prop-types';


const BuildCommunicationPanel = ({myId, chatKey, reciepients, isAgent})=>{
   
    const {sendMessage, streamMessages,updateSeenBy} = useChatMessanger();
    const [messages, setMessages] = useState([]);
    const {enqueueSnackbar} = useSnackbar();
    const lattestMessage = messages.length > 0 ? messages[messages.length - 1] : null;
    const messageTextRef = useRef(null);
    const seenByRef = useRef(null);
    const [expanded, setExpanded] = useState(false);
    const [countUnseen, setCountUnseen] = useState(0);
    useEffect(()=>{
        console.log(myId, chatKey)
        const unsubscribe = streamMessages(chatKey, (data)=>{
            setMessages(data.sort((msg,msg2)=>msg.timestamp - msg2.timestamp));
            setCountUnseen(data.filter((msg)=>!msg.seenby.includes(myId)).length);
        });
        
        return unsubscribe;
        
    },[])
  
    const handleSendMessage = async ()=>{
        const message = {
            messageId: shortUUID.generate(),
            senderId: myId,
            reciepientKey: chatKey,
            reciepients: reciepients??[],
            content: messageTextRef.current.value,
            timestamp: new Date().getTime(),
            seenby: [myId]
        }
        messageTextRef.current.value = "";
     
            if(await sendMessage(message))
            {
            enqueueSnackbar("Message Sent", {variant: 'success'});
        }else{
            enqueueSnackbar("Message Failed to Send", {variant: 'error'});
        }
    }
    const scrollToBottom = ()=>{
        seenByRef.current.scrollIntoView({ behavior: 'smooth' });
        updateSeenBy(chatKey, myId)

    }
    return <Accordion expanded={expanded} onChange={
        (e, expanded)=>{
            setExpanded(expanded);
            if(expanded){
                scrollToBottom();
            }
        }
    }>
        <AccordionSummary expandIcon>
         <Row style={{width:'100%', alignItems: 'center'}} alignment={"space-between"}>
         {messages.length > 0 ? <Row>
            
                <ProfileAvatar admin={lattestMessage?.senderId.includes('dtv-admin')} agent={isAgent && lattestMessage?.senderId === myId} disable uid={lattestMessage?.senderId}/>
                <div style={{width:'1em'}}/>
                <Column>
                <ProfileAvatar admin={lattestMessage?.senderId.includes('dtv-admin')} agent={isAgent && lattestMessage?.senderId === myId} disable uid={lattestMessage?.senderId} onlyName textProps={{variant:"caption"}}/>
                <Row style={{alignItems:'center'}}>
                    <Typography variant="caption" color={countUnseen === 0 ? 'textSecondary': 'textPrimary'} style={{fontWeight: countUnseen === 0 ? "normal" : "bold"}}>{lattestMessage?.content}</Typography>
                    <div style={{margin:' 0 0.25em'}}>··</div>
                   <Typography variant="caption" color="textSecondary"><TimeAgo timestamp={lattestMessage?.timestamp}/></Typography>
                </Row>
                </Column>

            
         </Row>:<Typography variant="overline">{"No Messages at Pressent"}</Typography>}
         <Typography variant="caption">{`See Message(${countUnseen})`}</Typography>
        </Row>
        </AccordionSummary>
        <AccordionDetails>
           <Card style={{width:'100%'}} variant="outlined">
            <CustomScrollview style={{height:400, overflowX: 'none'}}>
                <CardContent style={{height: "100%", width:'100%',overflowY:'auto'}}>
            <Column style={{width:"100%", padding:20}}>
                <Column alignment={"end"} style={{height: '100%', maxWidth:1000, overflowX: 'hidden', overflowY:'auto', padding: 0}}>
                    {
                        messages.length > 0 ? messages.map((message, index) => {
                            return <MessageTile key={myId + index} myId={myId}
                            isAgent={isAgent && lattestMessage?.senderId === myId}
                            isAdmin={lattestMessage?.senderId.includes('dtv-admin')}
                             hideProfileImage={
                                message.senderId === myId || message.senderId === messages[messages.findIndex((msg)=>msg.messageId === message.messageId) - Math.min(1, messages.length)]?.senderId
                            } message={message}/>
                        }):<Column style={{
                            width:'100%',
                            height:'100%',
                            alignItems: 'center',
                        }}>
                        <Typography variant="overline">{"No Messages"}</Typography>
                        </Column>
                    }
                    <div style={{height:2}}ref={seenByRef}/>
                </Column>
               
            </Column>
            </CardContent>
            </CustomScrollview>
            
            <CardActionArea>
                 <Row style={{width:'100%', padding: 10}}>
                    <TextField inputProps={{style: {padding: "10px 15px"}}} inputRef={messageTextRef} fullWidth variant="outlined" placeholder="Type your message here" />
                    <div style={{width:'1em'}}/>
                    <Button onClick={handleSendMessage} size="small" variant="contained" color="primary" disableElevation>Send</Button>
                </Row>
            </CardActionArea>
           </Card>
        </AccordionDetails>
    </Accordion>
}
export default BuildCommunicationPanel;

BuildCommunicationPanel.propTypes = {
    myId: PropTypes.string,
    chatKey: PropTypes.string,
    reciepients: PropTypes.array,
    autoOpen: PropTypes.bool,
    isAgent: PropTypes.bool

}