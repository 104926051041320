import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { RouteNames, baseUrl } from "../config/api";


const SecureServerConnection = ()=>{
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false)
    useEffect(()=>{
        setTimeout(async()=>{
            try{
                await fetch(`${baseUrl}/${RouteNames.health}`)
            }catch(e){
                setShowModal(true);
            }
            
        }, 2000)
    },[]);
   
    const handleLeave = ()=>{
        navigate(-1);
    }
    const handleRefresh = ()=>{
        window.location.reload();
    }
    return (
        <div>
            <Dialog open = {showModal} >
                <DialogTitle>Secure Server Connection Failed</DialogTitle>
                <DialogContent>We are having some trouble reaching our server, this site might be under going some maintainance</DialogContent>
                <DialogActions>
                    <Button onClick={handleLeave}>Close</Button>
                    <Button variant="contained" color = "primary" onClick={handleRefresh}>Refresh</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
export default SecureServerConnection;