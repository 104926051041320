/* eslint-disable react-hooks/exhaustive-deps */

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import firebase_app from "../config/firebase";
import { useSnackbar } from "notistack";
import Column from "./Column";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { UserContext } from "../contexts/UserProvider";
import PropTypes from 'prop-types';


const PasswordRequest = ({onValidPassword,showModal,setShowModal})=>{
    const [viewPassword, setViewPassword] = useState(false);
    const passwordRef = useRef(null);
    const {enqueueSnackbar} = useSnackbar();
    const [loading, setLoading] = useState(false);
    const {profile} = useContext(UserContext);
    useEffect(()=>{
        console.log("open",profile);
        if(localStorage.getItem("dtv-verified")){
            setShowModal(false);
          }
    },[]);
   
    const handleLeave = ()=>{
        setShowModal(false);
    }
    const handleLogin = async()=>{
        setLoading(true);
        try{
            if(passwordRef.current.value){
            const email = profile.email;
           await firebase_app.auth().signInWithEmailAndPassword(email, passwordRef.current.value);
            setLoading(false);
            setShowModal(false);
            localStorage.setItem("dtv-verified", true);
            if(onValidPassword)
                onValidPassword();
            }else{
                setLoading(false);
                enqueueSnackbar("Wrong Password", {variant: 'error'});
                localStorage.setItem("dtv-verified", false);
            }
        }catch(e){
            setLoading(false);
            enqueueSnackbar(e, {variant: 'error'});
            localStorage.setItem("dtv-verified", false);
        }
        
    }
    return (
        <div>
            <Dialog maxWidth="md" open = {showModal} >
                {loading ? <DialogContent>
                    <Column><CircularProgress /></Column>
                </DialogContent> :
                <>
                <DialogTitle>Please Enter Your Account Password</DialogTitle>
                <DialogContent>Before you view this sensitive information</DialogContent>
                <DialogContent><TextField 
                    inputRef={passwordRef}
                    InputProps={{
                        endAdornment: <IconButton onClick={()=>setViewPassword(!viewPassword)}>{viewPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
                    }}
                    variant="outlined"
                    label="Password"
                    type={viewPassword ? "text" : "password"}
                    fullWidth
                /></DialogContent>
                <DialogActions>
                    <Button onClick={handleLeave}>Leave</Button>
                    <Button variant="contained" color = "primary" onClick={handleLogin}>Login</Button>
                </DialogActions></>
                }
            </Dialog>
        </div>
    )
}
export default PasswordRequest;


PasswordRequest.propTypes = {
    onValidPassword: PropTypes.func,
    showModal: PropTypes.bool,
    setShowModal: PropTypes.func
}