import { Button, Divider, List, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Center from '../../components/Center';
import Column from '../../components/Column';
import CartItem from '../../components/_cart_item';
import { clearCart } from "../../logic/databaseHandler";

const CheckoutSummaryPage = ({cart:CART, enableDTVCoins,viewOnly, uid})=>{
 
    
    const [cart,setCart] = useState(CART??[])
 console.log("---",cart);
    
return (<>

{ cart.length <= 0 ?<Center><Typography>No items in cart</Typography></Center>:
<div style={{padding:"1vw"}}>
    <Typography>Cart Summary</Typography>
    <Divider style={{margin:"10px 0"}}/>
    <Column alignment = "start" style={{width:"100%",overflowY:"auto",height:"100%",color:"white",}}>
           <List 
      sx={{ width: '100%',bgcolor: 'background.paper' }}
    >
    {
       
       cart.map((item,key)=>{
           console.log("---",item,"EnableDTVCoins",enableDTVCoins);
        return <CartItem key={item?.id ?? key} enableDTVCoins={enableDTVCoins} viewOnly={item.viewMode} onDelete={()=>{
            setCart(cart.filter((itm)=>itm.cartId !== item.cartId));
        }} item={item}/>
    })}
    </List>
    </Column>
    <div style={{height:20}}/>
    {!viewOnly && <Button variant="contained" onClick={()=>{
        clearCart({uid: uid});
        setCart([]);
    }} style={{background:"red",color:"white"}}>Clear All</Button>}
</div>}
</>)
}
CheckoutSummaryPage.propTypes = {
    cart: PropTypes.arrayOf(PropTypes.object),
    uid:PropTypes.string,
    clearCart:PropTypes.func,
    enableDTVCoins:PropTypes.bool,
    viewOnly:PropTypes.bool
}
export default CheckoutSummaryPage;

