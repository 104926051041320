
/* eslint-disable */
import { Avatar, Button, Card, CardActionArea, CardContent, Chip, CircularProgress, Dialog, alpha, IconButton, InputAdornment, makeStyles, OutlinedInput, Snackbar, Typography, useTheme, withStyles, Grid } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import logo from '../assets/logo.png';
import Stack from '../components/Stack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ArrowForwardIos, ChevronLeft, ChevronRight, Close, Event as EventIcon, Opacity, Search, VolumeMute, VolumeUp } from '@material-ui/icons';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Center from '../components/Center';
import Column from '../components/Column';
import Row from '../components/Row';
import { SiteDataContext } from '../config/SiteData';
import "../index.css";

import { motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import Slider from "react-slick";
import { AppBarModule } from '../components/_app_bar_mod';
import ContinueWatchingCard from "../components/_continue_watching_mod";
import FutureBuilder from '../components/_future_builder';
import { LargeShowCard, ShowCard } from '../components/_show_card_mod';
import { ShowContext } from '../contexts/ShowProvider';
import { UserContext } from '../contexts/UserProvider';
import { Skeleton } from '@mui/material';
import { useMediaQuery } from '../contexts/MediaQueryProvider';
import { useProfile } from '../hooks/useProfile';

import { Carousel } from 'primereact/carousel';
import { ArrowBackIos } from '@mui/icons-material';
import { PrimeReactPassThrough } from '../utils';
        
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    }
  },
  iconBtn: {
    '&:hover': {
      color: "white"
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      color: "white"
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginLeft: 5,
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


const ShowsPage = (props) => {

  const theme = useTheme()
  const history = useNavigate();
  const classes = useStyles();
  const {
    SiteData,
    //ata,
    updateSiteData
  } = useContext(SiteDataContext)
const {device, isSmallDevice} = useMediaQuery();
  const [drawerWidth, setDrawerWidth] = useState("30%");
  const [openSidebar, showSidebar] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [preview_body, updatePreviewBody] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [notificationMsg, setNotificationMsg] = useState('');
  const [search, setSearch] = useState('');
  const [mute, setMute] = useState(true);
  const [currentSlide, setCurrentslide] = useState(0);
  const [loadResults, setLoadResults] = useState(false);
  const [continueWatching, setContinueWatching] = useState([])

  const searchRef = useRef(null);
  const slideRef = useRef(null);
  const slideRef2 = useRef(null);
  const videoRef = useRef(null);
  const {
    sessionId,
    profile,
    updateSessionId,
    updateProfile } = useContext(UserContext);
  const [currentUser, setCurrentUser] = useState(null);
  const {retrieveProfileWithCallback} = useProfile();
  const {
    dearfach_shows,
    getShows,
    getShow,
    getEpisode,
    getContinueWatchingListData: getRecentData,
    getComingSoonShows,
    coming_shows

  } = useContext(ShowContext);
 
  const initShows = async (profile) => {
    _getContinueWatching(profile).then((res) => {
      getComingSoonShows().then((res) => {
      getShows().then((res) => {
        setLoadResults(false);
        });
      });
      });
  }
  useEffect(() => {
     setLoadResults(true);
     retrieveProfileWithCallback((profile) => {
      setCurrentUser(profile);
      void initShows(profile)
     },(e)=>{
      console.log(e);
      void initShows(profile)
     })
   
  }, [])
  const _getContinueWatching = async (profile) => {
    const data = await getRecentData(profile?.uid ?? sessionId);
    setContinueWatching(data??[]);
  }
  const _buildPage = (() => {
    console.log(dearfach_shows);
    //...
    return <>
      <Column>
        {_buildContinueWatching()}
        {SiteData.showPage.enableCommingSoon ? _buildComingSoon() : <></>}
        {dearfach_shows.length > 0 && _buildDearfachOriginals()}

      </Column>
    </>
  });
  const BuildTrailer = ({show, isPlaying, isMute}) => {
  const [playing, setPlaying] = useState(isPlaying);
  const [mute, setMute] = useState(isMute);
  const videoRef = useRef(null);

  return <div style = {{width: "100%",height:"60vh", position:'relative'}}>
    <Stack>
      <div style={{ background: "#0000000d", height: "100%", width: "100%" }}></div>
              <ReactPlayer playsinline url={show?.trailer??''}
                playing={playing}
                loop={true}
                muted={mute}
                ref={videoRef}
                onProgress={(progress)=>{
                  if(progress.playedSeconds >= videoRef.current.getDuration()){
                    console.log("SWAPPING")
                    slideRef.current?.slickNext();
                  }
                }}
                height={"100%"}
                width={"100%"}
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
        <Column alignment={"end"} style={{ padding: "20px 0 10vh 0", width: "100%", height: "100%" }}>
            {!isSmallDevice ? <motion.div >
                <Row alignment="left" style={{ paddingLeft: "30px", width: "40%" }}> <Avatar src={logo} />
                  <Column>
                    <Typography variant="overline" style={{ fontWeight: 'bold' }}>Dearfxch <span style={{ fontWeight: "lighter" }}>Originals</span></Typography>
                  </Column>
                </Row></motion.div> : <></>}
              <motion.div >
                <Row alignment={!isSmallDevice ? "left" : "centered"} style={{ paddingLeft: "30px", margin: "10px 0" }}>
                  <div style={{ width: "120px" }}>
                    <img src={show?.logo} style={{ width: "100%", width: "100%", objectFit: "contain" }} />
                  </div>

                </Row>

              </motion.div> 
              <Row alignment={"space-between"} style={{  width: "100%" }}>
             
              <Row alignment="centered" style={{ paddingLeft: "30px", width: !isSmallDevice ? "50%" : "" }}>
                {!isSmallDevice ? <Column>
                  <Typography align="left" variant="caption" style={{ fontWeight: '' }}>{show?.showDesc}</Typography>
                </Column> : <></>}
              </Row>
              <Row alignment={!isSmallDevice ? "right" : "centered"} style={{ paddingRight: "30px" }}>
                <IconButton onClick={() => { setMute(!mute) }}><div style={{ border: " 1px white solid", height: "40px", width: "40px", borderRadius: "100%" }}>
                  <Center>
                    <Row alignment="centered">
                      {mute ? <VolumeMute /> : <VolumeUp />}
                    </Row>
                  </Center>

                </div> </IconButton>
               {show &&  <Column alignment="centered" style={{ marginLeft: "10px" }}>
                  <motion.div whileHover={{ scale: 1.1 }}>
                    <Button onClick={() => {
                      history("/show/" + show.showId, { show: show })
                    }} variant="contained" style={{ background: `${alpha(theme.palette.primary.main, 0.8)}`, height: !isSmallDevice ? "50px" : "40px" }}>Watch Now</Button>
                  </motion.div>

                </Column>}
              </Row>
            </Row>

    </Column>
    </Stack>
  </div>
  }
  const _buildTrailer = () => {
    return <Column style={{ width: "100%", height: "60vh" }}>
      <div style={{ height: "100%", background: "black", width: "100%" }}>
        <Slider ref={slideRef} afterChange={(index) => {
          setCurrentslide(index)
          slideRef.current?.slickGoTo(index, true)
        }} {...settings} variableWidth={false} lazyLoad arrows={!isSmallDevice} infinite={true} style={{ height: "100%", background: "black" }}>
          {
            SiteData.showPage?.featured?.filter((e)=>e).map((data, i) => <div style={{ height: "100%", background: "black" }}>
            <BuildTrailer show={data} isPlaying={currentSlide === i} isMute={mute} />
            </div>)
          }</Slider>


      </div>
    </Column>
  }

  const handleSearchSuggestions = () => {


  }
  const handleSearch = () => {


  }
  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <IconButton
        style={{ ...style, }}
        onClick={onClick}>
        <ChevronRight />
      </IconButton>
    );
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <Column >
        <IconButton
          style={{ ...style, }}
          onClick={onClick}>
          <ChevronLeft />
        </IconButton></Column>
    );
  }
  const settings = {
    className: "slider variable-width",
    dots: true,
    infinite: false,
    centerMode: false,
    slidesToShow: 1,//Math.floor(window.innerWidth/350),
    slidesToScroll: 1,
    variableWidth: true,

  };
  const trailer_settings = {
    className: "slider variable-width",
    dots: true,
    infinite: true,
    centerMode: false,
    slidesToShow: 1,//Math.floor(window.innerWidth/350),
    slidesToScroll: 1,
    variableWidth: false,

  };

  const getCurrentWatch = async (showId, episodeId) => {
    let result = { show: {}, episode: {} }
    result.show = await getShow(showId);
    result.episode = await getEpisode(episodeId);
    console.log(result)
    return result;
  }
  const _buildContinueWatching = () => {
    return  <Column style={{ marginTop: continueWatching.length <= 0 ? 0 : "2vh", padding: "30px" }}>
    {continueWatching.length > 0 && <Typography align="left" style={{ fontWeight: "bold", fontFamily: "pridi", textTransform: "uppercase", fontSize: !isSmallDevice ? "1.5vw" : "5vw" }}>Continue Watching</Typography>}
     {continueWatching.length > 0 && <div style={{ height: '40px' }}></div>}
     {loadResults ? <Row>
        {Array.from(Array(Math.floor(window.outerWidth/300)).keys()).map((e,i)=>{
          return <Skeleton sx={{ bgcolor: 'grey.900' }} key={i} variant="rectangular" width={!isSmallDevice ? 300 : 200} height={!isSmallDevice ? 250 : 150} style={{margin:"10px",minWidth:300,borderRadius: 20}}/>
        })}
      </Row>:<Slider {...settings} >
        {

          continueWatching.map((data, i) => <FutureBuilder
            getError={(e) => { console.log(e); }}
            future={getCurrentWatch(data.showId, data.episodeId)}
            builder={(res) => {

              return <ContinueWatchingCard mobile={isSmallDevice} hoverPlay={true}
                show={res.show}
                episode={res.episode} currentWatching={data} />
            }}
            placeholder={
              <Card style={{ padding: "10px", height: "300px" }}>
                <CardActionArea>
                  <CardContent>
                    <Center>
                      <CircularProgress />
                    </Center>
                  </CardContent>
                </CardActionArea>
              </Card>
            }
            errorChild={
              <Center><Typography>Error..</Typography></Center>
            }
          />
          )
        }

      </Slider>}
    </Column>
  }
  const DtvComingSoonTemplate = (show) => {
    if(show.showRelease < Date.now().valueOf()) return <></>
    return <ShowCard mobile={isSmallDevice} width={!isSmallDevice ? 300 : 300} height={!isSmallDevice ? 250 : 150}  onClick={() => {
      updatePreviewBody(_buildPreviewBody(show))
      setShowModal(true);
    }}
      customOverlay={
        <Chip color="primary" icon={<EventIcon />} label={new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(new Date(show.showRelease))} />}

      hoverPlay={true}
      show={show}
    />
  }
  const _buildComingSoon = () => {
    return <Column style={{ marginTop: coming_shows.length>0 ? "2vh": 0, padding: "30px", width:"100%" }}>
      {coming_shows.length>0 ? <Typography align="left" style={{ fontWeight: "bold", fontFamily: "pridi", textTransform: "uppercase", fontSize: !isSmallDevice ? "1.5vw" : "5vw" }}>Coming Soon</Typography>:null}
      { coming_shows.length>0 ?  <div style={{ height: '40px' }}></div>:null}
      {loadResults ? <Row>
        {Array.from(Array(Math.floor(window.outerWidth/300)).keys()).map((e,i)=>{
          return <Skeleton sx={{ bgcolor: 'grey.900' }} key={i} variant="rectangular" width={!isSmallDevice ? 300 : 200} height={!isSmallDevice ? 300 : 150} style={{margin:"10px",minWidth:300,borderRadius: 20}}/>
        })}
      </Row>:
        <Carousel 
        showIndicators
        value={coming_shows}
        partialVisible
        itemTemplate={DtvComingSoonTemplate} 
        pt={PrimeReactPassThrough}
        nextIcon={<ArrowForwardIos />}
        prevIcon={<ArrowBackIos/>}
        numVisible={isSmallDevice ? 1 : 6} 
        showNavigators
        numScroll={1} 
        responsiveOptions={responsiveOptions}
        
        />}
    </Column>
  }
  const DtvOriginsTemplate = (show) => {
    return <LargeShowCard key={`${show.showId}`} width="100%" mobile={isSmallDevice} hoverPlay={true} height={!isSmallDevice ? 600 : 400}
    show={show}
  />
  }
  const responsiveOptions = [
    {
        breakpoint: '1400px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
    }
];
  const _buildDearfachOriginals = () => {
    return <Column style={{ marginTop: dearfach_shows.length>0  ? "2vh" : 0, padding: "30px",width:"100%" }}>
          { dearfach_shows.length>0 ?  <><Typography align="left" style={{ fontWeight: "bold", fontFamily: "pridi", textTransform: "uppercase", fontSize: !isSmallDevice ? "2vw" : "5vw" }}>Dearfxch <span style={{ fontWeight: "200", color: alpha(theme.palette.text.primary, 0.7), textTransform: "capitalize" }}>Originals</span></Typography>
      <div style={{ height: '40px' }}></div></>:null}
      {loadResults ? <Row>
        {[0,1,2,3,4,5,6,7].map((e,i)=>{
          return <Skeleton   sx={{ bgcolor: 'grey.900' }} key={i} variant="rectangular" width={!isSmallDevice ? 300 : 300} height={!isSmallDevice ? 400 : 400} style={{margin:"10px",minWidth:300,borderRadius: 20}}/>
        })}
      </Row>:
        <Carousel value={dearfach_shows}
        itemTemplate={DtvOriginsTemplate} 
        numVisible={isSmallDevice ? 1 : 6} 
        numScroll={1} 
        responsiveOptions={responsiveOptions}
        pt={PrimeReactPassThrough}
        />}
     <Row alignment="centered">
     <Chip color="primary"  style={{margin: "30px 0", maxWidth: 200}} variant='outlined' label={`Showing ${Math.min(6,dearfach_shows.length)}/${dearfach_shows.length} Shows`} />
     </Row>
    </Column>
  }
  const _buildSearchResults = () => {
    return <>
      <Column>
        <Typography >{`Search for ${search}`}</Typography>
      </Column>
    </>
  }
  const _buildSearchBar = () => {
    return <div style={{ width: "30%", marginRight: "20px" }}>
      <Center>
        <OutlinedInput
          id="outlined-adornment-password"
          type={'text'}
          className={classes.textField}
          defaultValue={search}
          fullWidth
          placeholder="Search"
          inputRef={searchRef}
          inputProps={{
            style: {
              color: alpha(theme.palette.text.primary, 1),
              paddingTop: "15px",
              borderColor: "white"
            }
          }}
          onKeyUp={handleSearchSuggestions}
          endAdornment={
            <InputAdornment position="end" >
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleSearch}
                edge="end"
                className={classes.iconBtn}
              ><Search />
              </IconButton>
            </InputAdornment>
          }
          labelWidth={70}
        />
      </Center>
    </div>
  }
  const _buildPreviewBody = (show) => {
    return <div style={{ width: "100%", height: !isSmallDevice ? "800px" : "400px" }}>
      <ReactPlayer playsinline url={show.trailer} width="100%" height="100%" style={{ width: "100%", height: "100%" }} />
    </div>
  }
  return (<div className={classes.root}>
    <CssBaseline />
    <Stack>

      <div style={{ background: "#0000000d", height: "100%", width: "100%" }}></div>
      <div style={{ background: "linear-gradient(0deg, black 20%,#0000009c 54%, transparent)", height: "100%", width: "100%" }}></div>
      {_buildTrailer()}
    </Stack>
    <Stack startLayer={106} style={{ marginTop: "60vh" }}>
      <div style={{ pointerEvents: "none", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 80%, transparent)`, height: "100%", width: "100%", transform: ' translate(0, -21vh)' }}></div>
      { !loadResults && dearfach_shows.length <= 0 ? <Column style={{width:'100%', height:'100%', alignItems:'center'}}>
        <Typography align="center" variant="h4" style={{ fontWeight: "bold", fontFamily: "pridi", textTransform: "uppercase", fontSize: !isSmallDevice ? "2vw" : "5vw" }}>Dearfxch <span style={{ fontWeight: "200", color: alpha(theme.palette.text.primary, 0.7), textTransform: "capitalize" }}>Originals</span></Typography>
        <Typography align="center" variant="body2" style={{marginTop:5, opacity:0.6}} >No Shows Avialable right now, stay tuned!</Typography>
      </Column> :  _buildPage()}
    </Stack>
    {<AppBarModule pageIndex={3} customRightModule={<></>} />}
    {/**customRightModule={_buildSearchBar()}*/}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification}
      autoHideDuration={notificationMsg.timeout}
      onClose={() => {
        showNotification(false)
      }}
      message={notificationMsg.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  </div>

  )


}
export default ShowsPage;