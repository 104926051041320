

/* eslint-disable react-hooks/exhaustive-deps */

import { Dialog, alpha, IconButton, makeStyles, Snackbar, Typography, useTheme, CircularProgress, Select, MenuItem, FormControl, Grid, Chip, Avatar, Accordion, AccordionDetails, AccordionSummary, CardContent, Card } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import Stack from '../components/Stack';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ArrowBack, Close, DynamicFeed, ExpandMore, FastForwardOutlined, FastRewindOutlined, Fullscreen, Pause, PlayArrow, VolumeMute, VolumeUp } from '@material-ui/icons';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Column from '../components/Column';
import Row from '../components/Row';
import FutureBuilder from '../components/_future_builder';
import { EpisodeCard } from '../components/_show_card_mod';
import { ShowContext } from '../contexts/ShowProvider';
import { UserContext } from '../contexts/UserProvider';
import "../index.css";
import logo from '../assets/logo.png';
import { formatDuration, parseUrlForQueryParams, youtubeConfig } from '../utils';
import { useProfile } from '../hooks/useProfile';
import { useMediaQuery } from '../contexts/MediaQueryProvider';
import { useDispatch, useSelector } from 'react-redux';
import { updateProgress } from '../reducer/showProgressReducer';
import ProgressSlider from '../components/progress_slider';
import { FitScreenRounded, PlayCircle } from '@mui/icons-material';
import SlidingPanel from '../components/side_panel';
import EpisodeList from '../components/buildEpisodeList';
import { Skeleton } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    }
  },
  iconBtn: {
    '&:hover': {
      color: "white"
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      color: "white"
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));


///
const WatchShowPage = () => {

  const theme = useTheme()
  const history = useNavigate();
  const classes = useStyles();
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [notificationMsg, ] = useState('');
  const [mute, setMute] = useState(false);
  const [playAnimation, setPlayAnimation] = useState(false);
  const [quality, setQuality] = useState(720);
  const [notExists, setNotExists] = useState(false);
  const isHD = (q)=>{
    return q>720;
  }
  //const [currentProgress, setProgress] = useState(0);
  let currentProgress = 0;
  const {isSmallDevice}=useMediaQuery();
  const {retrieveProfileWithCallback} = useProfile();
  const dispatch = useDispatch();
 
  const {
    getShow,
    getEpisode,
    getWatchedData,
    updateWatchList,
    addWatchListData,
    updateRecentEpisode,

  } = useContext(ShowContext);
  const {  sessionId } = useContext(UserContext);
  const [profile, setProfile] = useState({});
  const params = parseUrlForQueryParams(window.location.href);
  const [progress, setProgress] = useState(Number((params.time)??0));
   const reduxProgress = useSelector((state) => state.showsProgress.shows);
  const [episode, setEpisode] = useState({});
  const [loading, setLoading] = useState(true);
  const [showOverlay, setShowOverlay] = useState(true);
  const [show,setShow] = useState({});//show_episodes?.showTitle
  const playerRef = useRef(null);
  const [playing, setPlaying] = useState(true);
  const [showEpisodePanel, setShowEpisodePanel] = useState(false);
  const [minimized, setMinimized] = useState(true);

  const initEpisode = () => {
    getEpisode(params.id).then((e)=>{
      setEpisode(e);
      console.log(e);
        if(!e){
            setNotExists(true);
            setLoading(false);
          }else{
              getShow(e.showId).then((s)=>{
                setShow(s);
                setProgress(reduxProgress?.[params.id]??0);
                if(profile?.uid){
                        
                  getWatchData(e, profile?.uid).then(()=>{
                      setProgress(reduxProgress?.[params.id]??0);
                      setLoading(false);
                  });
                }else{
                  setLoading(false);
                }
          })
        }
      })
  }
  useEffect(() => {
    console.log('->>', reduxProgress)
     setLoading(true);
     retrieveProfileWithCallback((p)=>{
      setProfile(p);
      initEpisode();
    },()=>{ 
      initEpisode();
      console.log("Playing episode for Guest user")
    })
    return (() => {
      console.log("unloading",reduxProgress[params.id], params.id, episode.showId)
      if(profile?.uid){
        updateWatchList(profile.uid ?? sessionId, params.id, episode.showId, reduxProgress[params.id]).then(() => {
          updateRecentEpisode(profile.uid ?? sessionId, params.id, episode.showId, reduxProgress[params.id])
        })
      }
    })
  }, [episode?.showId])


  const getWatchData = async (episode, uid) => {
    console.log(uid)
    if(!uid) return;
    var data = await getWatchedData(episode.episodeId, uid)
    console.log("..><>", data)
    if (!data || data?.length < 1) {
      console.log("NEW!!")
      let n = await addWatchListData({ userId: uid, episodeId: episode.episodeId, showId: episode.showId, progress: currentProgress })
      console.log(n)
    }
    // var recentdata = await getRecentWatchShow(episode.episodeId, episode.showId);
    // console.log(recentdata)
    // if (!recentdata || recentdata?.length < 1) {
    //   console.log("DATA!!")
    //   let n = await addRecentEpisode({ userId: uid, episodeId: episode.episodeId, showId: episode.showId, progress: currentProgress })
    //   console.log(n)
    // }
  }
  const togglePlay = () => {
    setPlaying(!playing);
    setPlayAnimation(true);
    setTimeout(() => {
      setPlayAnimation(false);
    }, 300)
  }
  const _buildPlayer = (() => {
    //...
    console.log(show)
    return loading ?  <Column style={{width: '100%', height: '100%', alignItems:'center',backgroundColor:"black"}}>
    <CircularProgress />
    </Column> : episode && show && <>
     <motion.div style={{ width: "100%", height: "100%", position:'relative'}} onMouseLeave={
      ()=>{setShowOverlay(false)}
     } onMouseEnter={
      ()=>{
        setShowOverlay(true);
      }
    }
    onDoubleClick={
      ()=>{
        setMinimized(!minimized)
      }
    }
      ontouchstart={
        ()=>{
          console.log("touch");
          setShowOverlay(!showOverlay);
        }
      }
     >
     <Stack style={{height:"100%"}}>
      <Column style={{ width: "100%", height: "100%", background: "black" }}>
        {<ReactPlayer
        playsinline
          playing={playing}
          ref={playerRef}
          config={youtubeConfig}
          onEnded={()=>{
            setPlaying(false);
          }}
          onProgress={(p) => {
            console.log(p.playedSeconds,progress)
            dispatch(updateProgress({id: params.id, progress: p.playedSeconds})) 
            setProgress(p.playedSeconds)
          }}
          onReady={(p) => { p.seekTo(progress); }}
          url={episode?.link} width="100%" controls={true} height="100%" />}
      </Column>
      {
        showOverlay && <motion.div onClick={
          ()=>{
            if(isSmallDevice){
              setShowOverlay(!showOverlay)
              return;
            }
            togglePlay()
          }
        } initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{
          duration: 0.3
        }} style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%",}}>
         <Column alignment="space-between" style={{ width: "100%", height: "100%", background: "linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.5) 100%)"
        }}>
          <Row alignment="space-between" style={{ width: "100%",padding: 15}}>
            <Row style={{alignItems:'center'}}>
            <IconButton onClick={() => { history(-1) }} style={{ color: "white" }}>
              <ArrowBack style={{fontSize: 26}} />
            </IconButton>
              <div style={{width: 2, height:'100%', color:'white',opacity: 0.4, margin:'0 10px'}}/>
              <Column>
              <Typography variant="h6" style={{ textAlign:'left', color: "white",  fontSize: "1.5vw" }}>{episode?.ep_title}</Typography>
              <Typography variant="caption" style={{ textAlign:'left' , color: "white", }}>{`${show?.showTitle} | Season ${episode?.season_no??1} Episode ${episode?.episode_no??1}`}</Typography>
              </Column>
            </Row>
            <Row>
               
              <IconButton onClick={() => { 
                
              setShowEpisodePanel(true)
               }} style={{ color: "white" }}>
              <DynamicFeed style={{fontSize: 26}} />
            </IconButton>
            </Row>
            
            </Row>
          <Column>
           <Row style={{width: '100%', }} alignment="centered">
            <Row>
               <IconButton onClick={() => {}}>
                <FastRewindOutlined style={{fontSize: 26}} />
              </IconButton>
              <IconButton onClick={() => {
                togglePlay()
              }}>
                {playing ? 
                <Pause style={{fontSize: 32}} /> :
                <PlayCircle style={{fontSize: 32}} />}
              </IconButton>
             <IconButton onClick={() => {}}>
                <FastForwardOutlined style={{fontSize: 26}} />
              </IconButton>
            </Row>
          </Row>
          <ProgressSlider 
            onUpdateProgress={
              (p)=>{
                console.log(p)
                setProgress(p);
                playerRef.current.seekTo(p);
              }
            }
            total={playerRef?.current?.getDuration()??episode?.duration??0}
            progress={progress} setProgress={setProgress} />

          <Row style={{width: '100%', padding: '10px 30px', alignItems:'center'}} alignment="space-between">
            <Row style={{alignItems:'center'}}>
              <Row>
                 <Typography>{`${formatDuration(Math.floor(progress))} / `}</Typography>
                 <div style={{width: 3}}/>
                 <Typography color="textSecondary">{formatDuration(playerRef?.current?.getDuration()??episode?.duration??0)}</Typography>
              </Row>
              <div style={{width: 10}}/>
              {isHD(quality)  && <><Typography color="textSecondary">{"HD"}</Typography>
              <div style={{width: 10}}/></>}
              <FormControl size='small' style = {{background: 'transparent'}}>
                <Select  variant='outlined' value={quality} onChange={(e)=>{ 
                setQuality(e.target.value);
              }} style={{color: 'white',border: 'none'}}>
                <MenuItem value={360}>360p</MenuItem>
                <MenuItem value={720}>720p</MenuItem>
                <MenuItem value={1080}>1080p</MenuItem>
                <MenuItem value={1440}>1440p</MenuItem>
              </Select>
              </FormControl>
              
            </Row><Row>
            <IconButton onClick={() => { 
              setMute(!mute)
             }} style={{ color: "white" }}>
             {mute ? <VolumeMute/> : <VolumeUp/>}
            </IconButton>
            <IconButton onClick={() => { 
              setMinimized(!minimized)
            }} style={{ color: "white" }}>
              {
                minimized ? <Fullscreen/> : <FitScreenRounded/>
              }
            </IconButton>
            </Row>
          </Row>
         
          </Column>
          </Column>
          </motion.div>
      }
      {
        playAnimation &&
      <motion.div initial={{ opacity: 0,scale:0.9 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }} style={{ pointerEvent:'none',height:'100%', width:'100%' }}>
        <Column style={{height:'100%', width:'100%', alignItems:'center'}}>
        {
          playing ? <Pause style={{ fontSize: 100, color: "white" }} /> : <PlayArrow style={{ fontSize: 100, color: "white" }} />
        }
        </Column>
        
      </motion.div>
      }
      </Stack>
      </motion.div>
    </>
  });
  const _buildPage = (() => {
    if(loading) return <Column style={{width: '100%', height: '100%', alignItems:'center',backgroundColor:"black"}}>
    <CircularProgress />
    </Column>

    if(!episode || !show) return <></>

    return <Grid container style={{width:"100%", height:"100%",alignItems:'center',backgroundColor:"black"}} >
      <Grid item xs={12}>
        <Column style={{ width: "100%", height: minimized ? "40vh" : "100vh", background: "black" }}>
         {_buildPlayer()}
        </Column>
      </Grid>
      

      {minimized && <Grid item xs={12}>
        { <Grid item xs={12}  style={{padding: 20}}>
             <Card variant='outlined'>
              <CardContent>
              <Typography variant="h5" style={{ textAlign: 'left' }}>{`${episode.title} | Season ${episode.season_no} Episode ${episode.episode_no}`}</Typography>
              <Typography color={"textSecondary"} style={{ textAlign: 'left',width:"100%", margin:"10px 0" }}>{`${episode.description}`}</Typography>
              <Typography color={"textSecondary"} style={{ textAlign: 'left',width:"100%", margin:"10px 0", fontSize:12 }}>{
                `Released on ${new Date(episode.release).toDateString()}`
              }</Typography>
              {
                <Column style={{margin:"10px 0"}}>
                  {(episode?.casts || show.casts) && <Typography variant="body" style={{ textAlign: 'left', fontSize:15, fontWeight:"bold" }}>Casts</Typography>}
               
                <Row style={{flexWrap:"wrap", marginBottom:20}}>
                {(episode?.casts ?? show.casts??[]).map((cast, index) => {
                  return <Chip variant='outlined' color="primary" key={index} label={
                    <Typography>
                        {cast.name} | <span style={{opacity:0.5}}>{cast.role}</span>
                    </Typography>
                }avatar={<Avatar src={cast.image}/>} clickable={cast.url !== ""} onClick={
                    ()=>{
                      if(cast.url !== ""){
                        window.open(cast.url, '_blank');
                      }
                    }
                  
                  } style={{ margin: 5 }} />
                })}
                </Row>
                 </Column>
              }
              {
                <Column style={{margin:"3px 0"}}>
                  {(episode?.directors || show.directors) && <Typography color={"textSecondary"}  style={{ textAlign: 'left' }}>Directed By</Typography>}
               
                <Row style={{flexWrap:"wrap", marginBottom:5}}>
                {(episode?.directors ?? show.directors??[]).map((director, index) => {
                  return <Chip key={index} size={"small"} label={director?.name} avatar={<Avatar src={director.image}/>} clickable={director.url !== ""} onClick={
                    ()=>{
                      if(director.url !== ""){
                        window.open(director.url, '_blank');
                      }
                    }
                  
                  } style={{ margin: 5 }} />
                })}
                </Row>
                 </Column>
              }
              {
                <Column style={{margin:"3px 0"}}>
                  {(episode?.producers || show.producers) && <Typography color={"textSecondary"} style={{ textAlign: 'left' }}>Produced By</Typography>}
               
               <Row style={{flexWrap:"wrap", marginBottom:5}}>
               {(episode?.producers ?? show.producers??[]).map((producer, index) => {
                  return <Chip  size={"small"} key={index} label={producer?.name} avatar={<Avatar src={producer.image}/>} clickable={producer.url !== ""} onClick={
                    ()=>{
                      if(producer.url !== ""){
                        window.open(producer.url, '_blank');
                      }
                    }
                  
                  } style={{ margin: 5 }} />
                })}
               </Row>
                 </Column>
              }
              {
                <Column style={{margin:"3px 0"}}>
                  {(episode?.genres || show.genres) && <Typography style={{ textAlign: 'left', fontWeight:"bold" }}>Genres</Typography>}
               
                <Row style={{margin:"10px 0"}}>
                  {(episode?.genres ?? show.genres??[]).map((g, index) => {
                    return <Chip variant='outlined' key={index} label={g} style={{ marginRight: 5 }} />
                  })}
                  </Row>
                 </Column>
              }
              </CardContent>
             </Card>
              </Grid>
              }
              <div style={{ height: 10 }} />
        <Grid item xs={12}  style={{padding: 20}}>
        {
                 Object.keys(show.seasons).map((season, index) => {
                 return <Accordion variant='outlined' defaultExpanded={
                  season === episode.season_no
                 } key={`seasonShowCase${index}`}>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                      <Row>
                        <Typography variant="h6" style={{ textAlign: 'left' }}>{`Season ${season}`}</Typography>
                      </Row>
                    </AccordionSummary>
                    <AccordionDetails>
              <Grid container spacing={1}>
              {
                  show.seasons[season].map((episodeId, index) => {
                    return <Grid item xs={12} md={6} lg={4} key={index}>
                      <FutureBuilder
                        placeholder={
                          <Skeleton sx={{ bgcolor: 'grey.900' }} variant="rectangular" width={'100%'} height={140} style={{ margin: '10px 0' }} />
                        }
                        futureFunc={() => getEpisode(episodeId)}
                        builder={() => {
                          return <EpisodeCard width="100%" style={{
                            width: '100%',
                            border: episode.episodeId === episodeId ? `1px solid ${theme.palette.primary.main}` : `1px solid ${theme.palette.divider}`,
                          }} mobile={isSmallDevice} episodeId={episodeId} timestamp={
                            reduxProgress?.[episodeId]??0
                          
                          } />
                        }}
                        />
                          </Grid>
                      })
                    }
              </Grid>
                    </AccordionDetails>
                  </Accordion>
                 })
              }
        </Grid>
      </Grid>}
    </Grid>
  })

  return (<div className={classes.root}>
    <CssBaseline />
    <Stack startLayer={106} >
      <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 80%, transparent)`, height: "100%", width: "100%", transform: ' translate(0, -21vh)' }}></div>
      { notExists ? <Column style={{width:'100%', height:'100%'}}>
    <img alt="logo" src={logo} style={{width:"200px", height:"200px", objectFit:"cover"}}/>
      <Typography variant="h5" style={{color:"white"}}>Sorry this show no longer exists</Typography>
      <Typography variant="caption" color={'textSecondary'} style={{color:"white"}}>If you think this is a mistake please contact us</Typography>
   </Column> : _buildPage()}
    </Stack>
    {episode && show && <SlidingPanel width={400} position={'right'} isOpen={showEpisodePanel} onClose={
      ()=>{
        setShowEpisodePanel(false);
      }
    }>
      <EpisodeList showId={show.showId} currentEpisodeId={episode.episodeId}/>
    </SlidingPanel>}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification}
      autoHideDuration={notificationMsg.timeout}
      onClose={() => {
        showNotification(false)
      }}
      message={notificationMsg.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  </div>

  )


}
export default WatchShowPage;