
import React from 'react'
import FutureBuilder from "../../_future_builder"
import PropTypes from 'prop-types'
import {Typography, Avatar, Tooltip, CircularProgress} from '@material-ui/core'
import firebase_app from "../../../config/firebase"
import Row from "../../Row"

import { motion } from 'framer-motion/dist/framer-motion';
import { useAdmin } from '../../../hooks/useAdmin'
import { useNavigate } from 'react-router'

export const ProfileAvatar = ({rebuild, admin,agent,uid,tile,onlyName, textProps, avatarStyle,avatarProp, disable,showTooltip, hoverType = "scale", url})=>{
    const history = useNavigate();
    const {retrieveAdmin } = useAdmin();
    return <>
        <FutureBuilder 
            rebuild = {rebuild}
            future = {(admin ? 
                retrieveAdmin(uid) : 
                agent ? 
                firebase_app.database().ref().child("serviceAgents").child(uid??"--").once('value') :
                firebase_app.database().ref().child("Users").child(uid??"--").once('value'))}
            builder={(snap)=>{
                const user = admin ? snap : snap.val();
                console.log(user,snap)
                return <Tooltip title={`${user?.fname??user?.name??''} ${user?.lname??''}`} placement="top" arrow interactive={showTooltip} disableHoverListener={disable}>
                    <motion.div onClick = {()=>{
                    if(disable) return;
                    history(url??`/profile/${uid}`)
                }} whileHover={{opacity: 1, cursor:disable ? 'default' : 'pointer',scale: disable || hoverType !== 'scale' ? null : 1.1 }} initial={{opacity: disable || hoverType !== 'opacity' ? 1 : 0.8}}>{onlyName? <Typography {...textProps}>{`${user?.fname??user?.name??''} ${user?.lname??''}`}</Typography> : tile? <Row style = {{alignItems: "center"}}>
                    <Avatar style={{backgroundColor: '#676767',...avatarStyle}} src={user?.image??user?.picture??''}/>
                    <Typography style={{marginLeft: 10}} {...textProps} >{`${user?.fname??user?.name??''} ${user?.lname??''}`}</Typography>
                </Row> : <Avatar style={{backgroundColor: '#676767',...avatarStyle}} src={user?.image??user?.picture??''} {...avatarProp}/>}</motion.div>
                </Tooltip> 
            }}
            errorChild={onlyName?<>--</>:<Avatar style={avatarStyle} />}
            placeholder={onlyName?<CircularProgress size={"small"}></CircularProgress>:<Avatar style={avatarStyle}/>}
        />
    </>
}
export default React.memo(ProfileAvatar)

ProfileAvatar.propTypes = {
    rebuild: PropTypes.any,
    admin: PropTypes.bool,
    agent: PropTypes.bool,
    uid: PropTypes.string,
    tile: PropTypes.bool,
    onlyName: PropTypes.bool,
    textProps: PropTypes.object,
    avatarStyle: PropTypes.object,
    avatarProp: PropTypes.object,
    disable: PropTypes.bool,
    showTooltip: PropTypes.bool,
    hoverType: PropTypes.string,
    url: PropTypes.string
}
