/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useDatabase } from "../hooks/useDatabase";
import backgroundVideo from "../assets/backgroundVideo.mp4";
import Stack from "./Stack";
import Column from "./Column";
import Logic from "../logic/logic";
import FutureBuilder from "./_future_builder";
import { Divider, Typography, useTheme } from "@material-ui/core";
import Row from "./Row";
import { CheckCircleOutlineRounded, HighlightOffRounded } from "@material-ui/icons";
import PropTypes from 'prop-types'

const BundleCard = ({ bundleId, style }) => {
    const [bundle, setBundle] = useState(null);
    const {retrieveBundle,} = useDatabase();
    const [discountedPrice, setDiscountedPrice] = useState(0);
    const theme =useTheme();

    const cardStyle = {
        width: "280px",
        height: "380px",
        borderRadius: "20px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
        border: "1px solid rgb(255 255 255 / 32%)",
        
    }
    useEffect(()=>{
        retrieveBundle({bundleId:bundleId, isStory: false}).then((bundle)=>{
            console.log(bundle)
            if(bundle){
                
                setBundle(bundle);
            setDiscountedPrice(bundle.price-(bundle.price*((bundle.discount/100)??0)));
            }
        })
    },[])
    return <Column style={{...cardStyle,...style, position:'relative',overflow:'hidden'}}>
        <Stack>
            <Column style={{width:"100%",height:"100%"}}>
            <video src={backgroundVideo} style={{width:"100%",height:"100%",borderRadius: "20px",objectFit:'cover'}}/>
           </Column>
            <div style={{background: "linear-gradient(180deg, rgba(0,0,0,0) -95%, rgba(0,0,0,1) 100%)",borderRadius: "20px",height:"100%",width:"100%"}}/>

            {
                bundle ? <Column style={{width:"100%",padding:20}}>
                     <Typography variant="h5" style={{textAlign:"left", fontWeight:"bold",}}>{bundle.title}</Typography>
                        <Typography variant="caption" style={{textAlign:"left", marginTop:"1rem"}}>{bundle.description}</Typography>
                        <Row>
                        <FutureBuilder 
                        rebuild
                        future={Logic.toCountryCurrency(bundle.price/10,0)} builder = {
                            (price)=>{
                               return <Typography variant="h6" style={{textAlign:"left",textDecoration:bundle.discount ? "line-through":"", opacity: bundle.discount ? 0.2 : 1, fontWeight:"bold",  marginTop:"2rem"}}>{`${price}`}</Typography>
                            }
                        }
                        placeholder = {
                            <Typography variant="h6" style={{textAlign:"left", textDecoration:bundle.discount ? "line-through":"", opacity: bundle.discount ? 0.2 : 1, fontWeight:"bold", marginTop:"2rem"}}>{`€${bundle.price/10}`}</Typography>
                        }
                        error = {
                            ()=>{

                            }
                        }/>
                        {bundle.discount ? <>
                        <div style={{width:5}}/>   
                        <FutureBuilder 
                        rebuild
                        future={Logic.toCountryCurrency(discountedPrice/10,0)} builder = {
                            (price)=>{
                               return <Typography variant="h6" style={{textAlign:"left", fontWeight:"bold", marginTop:"2rem"}}>{`${price}`}</Typography>
                            }
                        }
                        placeholder = {
                            <Typography variant="h6" style={{textAlign:"left", fontWeight:"bold", marginTop:"2rem"}}>{`€${discountedPrice/10}`}</Typography>
                        }
                        error = {
                            ()=>{

                            }
                        }/></>:<></>}
                        </Row>
                       
                    
                    <Divider style={{backgroundColor:theme.palette.text.primary, marginTop:"20px", opacity:0.2}}/>
                    <Column  style={{marginTop:"2rem", width:"100%"}}>
                    {
                        bundle.include.map((incl, index)=>{
                            return <Row key={incl+index} style={{alignItems:"center", marginBottom:8}}>
                                <CheckCircleOutlineRounded style={{color:theme.palette.primary.main}}/>
                                <Typography variant="caption" style={{textAlign:"left", marginLeft:5, opacity: 0.9}}>{incl}</Typography>
                            </Row>
                        })
                    }
                    </Column>
                    <Column  style={{marginTop:"2rem", width:"100%"}}>
                    {
                        bundle.exclude?.map((incl, index)=>{
                            return <Row key={incl+index} style={{alignItems:"center", marginBottom:8}}>
                                <HighlightOffRounded style={{color:'red'}}/>
                                <Typography variant="caption" style={{textAlign:"left", marginLeft:5, opacity: 0.9}}>{incl}</Typography>
                            </Row>
                        })
                    }
                    </Column>
                    </Column>:<></>
            }
        </Stack>
    </Column>
};
export default BundleCard;


BundleCard.propTypes = {
    bundleId: PropTypes.string,
    style: PropTypes.object
}