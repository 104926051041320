/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */

import { Card, CardActionArea, IconButton, Tooltip, Typography } from '@material-ui/core';
import { VolumeMute, VolumeUp } from '@material-ui/icons';
import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import '../bubble.css';
import Center from './Center';
import Row from './Row';
import Stack from './Stack';

import NumericLabel from 'react-pretty-numbers';
import ReactTimeAgo from 'react-time-ago';
import Column from './Column';

import EllipsisText from "react-ellipsis-text";

import PropTypes from 'prop-types';


export const VideoCard = (props)=>{
    const [isHovered, setHovered]  = useState(false);
    const [views] = useState(0);

    const [mute,setMute] = useState(true);

    const [play,setPlay] = useState(false);

    const playerRef = useRef(null);

    useEffect(()=>{
        getViews();
    },[])

    const getViews = async()=>{
    }
    return (
        <motion.div style={{width:"100%",height:"100%",}} whileHover={{scale:1.05}}
        onHoverStart = {()=>{
                                setHovered(true); 
                                //startPlayCounter();
                                setPlay(true)
                          }}   onHoverEnd ={()=>{
                              //**ISSUE HERRE MULTIPLE PLAYS */
                              //stopPlaycounter();
                              setPlay(false);
                              setHovered(false);
                              }}>
            <Card style={{width:"100%",height:"100%",background:"url(`https://i.ytimg.com/vi/${props.youtubeData.thumbnail}/maxresdefault.jpg`)", backgroundColor:"black",backgroundObjectFit:"cover",borderRadius:props.borderRadius??"10px", overflow:"hidden"}} className={props.className}>
        <CardActionArea style={{width:"100%",height:"100%"}} onClick={props.onClick} >
        <div style={{width:"100%", height:"100%"}}>
        <Stack>
            <div style={{width:"100%", height:"100%"}}> 
            {
                props.youtubeData&&play?
                <ReactPlayer playsinline url={`https://www.youtube.com/watch?v=${props.youtubeData.id}`} 
                width="100%" 
                height="100%"
                ref={playerRef}
                canPlay="true"
                muted={mute}
                playing={play}/>:<img alt="" src={`https://i.ytimg.com/vi/${props.youtubeData.thumbnail}/hqdefault.jpg`} width="100%" height="100%" style={{objectFit:"cover"}}/>
            }
                
            </div>
             <motion.div initial={{y:0}} 
                                animate={{ y:isHovered ? 40:0}}
                                transition={{ ease: "linear",fontSize:"100%", duration: 0.2 }}
                                style={{borderTopRightRadius:"6px",
                                bottom:"0",
                                position:"absolute",
                                borderTopLeftRadius:"6px",
                                backgroundColor:props.cardColor??"#424242",
                                width:"100%", height:"32%"}}>
            <Column alignment="start" style={{fontSize:props.fontSize,  padding:"20px", width:"100%", height:"100%"}}>
             <motion.label style={{textAlign:isHovered?"center":"left",fontWeight:"bold",maxLines:2,textOverflow:"ellipsis",fontSize: props.fontFraction??"80%", color:props.titleColor??"white"}}>
             <EllipsisText text={props.youtubeData.title??''} length={props?.device??''==="pc"?"70":"100"} /></motion.label>
                                    <Column alignment="end" style={{height:"100%", padding:"10px 0"}}>
                                        <Row alignment="space-between">
                                            {views>0?<Typography style={{color:props.subtitleColor??"white", maxLines:"2",textOverflow:"ellipsis"}} variant="overline"><NumericLabel>{views}</NumericLabel> Views</Typography>:<Typography style={{color:props.subtitleColor??"white"}} variant="overline">{props.youtubeData.views?`${<NumericLabel>{props.youtubeData.views}</NumericLabel>} Views`:'--'}</Typography>}
                                            <Typography style={{color:props.subtitleColor??"white",fontSize:props.fontSize*0.6}} variant="overline">{<ReactTimeAgo locale="en-US" date={new Date(props.youtubeData.publishedAt)}/>}</Typography>
                                        </Row>
                                    </Column>
            </Column>
           </motion.div>
                      {isHovered?<motion.div initial={{y:0}}><Column style={{width:"100%"}}>
                <Row alignment="right">
                <Tooltip arrow style={{height:"auto"}} onClick={()=>{setMute(!mute)}} title={`${mute?'unmute':'mute'}`}>
                                                             <IconButton onClick={()=>{setMute(!mute)}}><div style={{border:" 1px white solid", height:"40px", width:"40px", borderRadius:"100%"}}>
                                                                <Center>
                                                                <Row alignment="centered">
                                                                {mute?<VolumeMute/>:<VolumeUp/>}
                                                                </Row> 
                                                                </Center>
                                                                
                                                                </div> </IconButton>
                                                        </Tooltip>
                </Row>
            </Column></motion.div>:<></>}
                    
        </Stack>
    </div>
    </CardActionArea>
    </Card>
   </motion.div>
   )
};

VideoCard.propTypes = {
    youtubeData: PropTypes.object,
    cardColor: PropTypes.string,
    onClick: PropTypes.func,
    fontSize: PropTypes.number,
    subtitleColor: PropTypes.string,
    device: PropTypes.string,
    fontFraction: PropTypes.string,
    titleColor: PropTypes.string,
    borderRadius: PropTypes.string,
    className: PropTypes.string
}