import { createSlice } from "@reduxjs/toolkit";

export const OrganisationSlice = createSlice({
    name: "organisation",
    initialState: {
        organisations: [],
        currentOrganisation: null
    },
    reducers: {
        setOrganisations: (state, action) => {
            state.organisations = action.payload;
        },
        setCurrentOrganisation: (state, action) => {
            state.currentOrganisation = action.payload;
        },
    }
});

export const { setOrganisations, setCurrentOrganisation } = OrganisationSlice.actions;

export default OrganisationSlice.reducer;