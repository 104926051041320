// ModalContext.js
import React, { createContext, useState, useContext } from 'react';
import OrganisationPicker from '../components/modal_picker/_organisation_picker';
import { Dialog, DialogContent } from '@material-ui/core';
import PropTypes from 'prop-types'
const ModalContext = createContext();

export const ModalDialogProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showOrganisation, setShowOrganisation] = useState(false);
  const [onAcceptedCallback, setOnAcceptedCallback] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const openModal = (content) => {
    setModalContent(content);
    setShowOrganisation(false);
    setIsOpen(true);
  };

  const closeModal = () => {
    setShowOrganisation(false);
    setIsOpen(false);
    setModalContent(null);
  };

  const openOrganisationModal = (callback) => {
    setShowOrganisation(true);
    setOnAcceptedCallback(()=>callback)
  };

  const closeModalOrganisationModal = () => {
    setShowOrganisation(false);
    setOnAcceptedCallback(null);
  }

  return (
    <ModalContext.Provider value={{ showOrganisation, openOrganisationModal, closeModalOrganisationModal, openModal, closeModal }}>
      {children}
      {!showOrganisation &&
        <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogContent>{modalContent}</DialogContent>
      </Dialog>}
      <OrganisationPicker filter={"stories"} showModal={showOrganisation} setShowModal={setShowOrganisation} onAcceptedCallback={onAcceptedCallback} />
    </ModalContext.Provider>
  );
};

export const useModal = () => {
    const {
        showOrganisation,
        openOrganisationModal,
        closeModalOrganisationModal,
        openModal,
        closeModal
    } = useContext(ModalContext);
    return {
        showOrganisation,
        openOrganisationModal,
        closeModalOrganisationModal,
        openModal,
        closeModal
    };
};

ModalDialogProvider.propTypes = {
    children: PropTypes.node
}