/*eslint-disable no-unused-expressions*/
/* eslint-disable react-hooks/exhaustive-deps */

import { Avatar, Button, Chip, Dialog, alpha, Grid, IconButton, makeStyles, Snackbar, Typography, useTheme, Tooltip, Menu, MenuItem, Divider, FormControlLabel, Radio, TextField, CardActionArea, Card } from '@material-ui/core';
import React, { useContext, useEffect, useRef, useState } from 'react';

import AdSense from 'react-adsense';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BookmarkBorderRounded, BookmarkRounded, Close, Email, FavoriteBorder, FavoriteRounded, Link, LinkedIn, ReportProblemRounded, ShareRounded, Twitter, VisibilityOutlined } from '@material-ui/icons';
import { motion } from 'framer-motion';
import NumericLabel from 'react-pretty-numbers';
import { useLocation, useNavigate } from 'react-router';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { AppBarModule } from '../components/_app_bar_mod';
import { FooterModule } from '../components/_footer_mod';
import FutureBuilder from '../components/_future_builder';
import { SiteDataContext } from '../config/SiteData';
import { StoryContext } from '../contexts/StoryProvider';
import "../index.css";
import { getUserProfile } from '../logic/databaseHandler';
import StoryEditor from '../components/_story_editor';
import { useDatabase } from '../hooks/useDatabase';
import { useProfile } from '../hooks/useProfile';
import {useSnackbar} from 'notistack';
import { useAdmin } from '../hooks/useAdmin';
import { StoryCard } from '../components/_story_mod';
import shortUUID from 'short-uuid';
import { useMediaQuery } from '../contexts/MediaQueryProvider';
import { Skeleton } from '@mui/material';
import { ReportService } from '../services/reportService';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    }
  },
  iconBtn: {
    '&:hover': {
      color: "white"
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      color: "white"
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

const ReportPage = ({userId, storyId, authorId, onComplete})=>{
  const [, setReportComment] = useState("");
  const [reportType, setReportType] = useState("spam");
  const theme = useTheme();
  const {sendNotification} = useDatabase();
  const {
    createConductReport,
    getReportOffences
  } = ReportService()
  const {enqueueSnackbar} = useSnackbar();
  const detailsRef = useRef();
  const [reportOffences, setReportOffences] = useState([]);
  const {isSmallDevice} = useMediaQuery();


  const styles = {
    link:{
      textDecoration: "underline",
      color: theme.palette.text.primary,
      opacity: 0.6,
    }
  }

useEffect(()=>{
  getReportOffences({}).then(({data})=>{
    
    setReportOffences(data);
  })
},[])

  return <Column alignment="centered" style={{
    padding: isSmallDevice ? "8% 10px" : "8% 30%",
   
  }}>
  <Typography  style={{textAlign:"center", marginBottom: 20,fontWeight:"bold"}} variant="h4">Report Story</Typography>
  {reportOffences.map((type, index)=>{
    return <FormControlLabel key={type?.id ?? index}
    style={{textTransform:"capitalize"}}
      control={
        <Radio
          checked={reportType === type.id}
          onChange={() => {
            setReportType(type.id)
          }}
          value={type.id}
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'A' }}
        />
      }
      label={type.title}
    />
  })}
  <TextField innerRef={detailsRef} style={{ marginBottom: 20}} label="Additional Comments" variant="outlined" multiline rows={4} onChange={(e) => {
    setReportComment(e.target.value)
  }} />
  <Row alignment="centered">
    <Button style={{marginRight: 20}} variant="outlined" onClick={()=>{
      onComplete();
    }}>Cancel</Button>
    <Button variant="contained" color="primary"  onClick={async()=>{
      const id =shortUUID().generate();
      const report = {
        id: id,
        type: reportType,
        comment: detailsRef.current.value??"",
        storyId: storyId,
        userId: userId,
        timestamp: Date.now()
      }
      console.log(report);
      await createConductReport({
        reporterId: userId,
        comment: report.comment,
        offenderId: authorId,
        offenceId: reportType,
        contentReported: "story",
        contentId: storyId
      })
      await sendNotification({
        id: shortUUID().generate(),
        type: "report",
        parentId: id,
        timestamp: Date.now()

      });
      enqueueSnackbar("Report Sent", {variant: "success"});
      onComplete();
    }}>Report</Button>
  </Row>
  <div style={{height:20}}/>
  <Typography variant='caption' style={{textAlign:'center'}}>Report <a href="/" style={styles.link}>copyright infringement</a>{` or `}<a href="/" style={styles.link}>trademark infringement</a></Typography>
  <Typography variant='caption'  style={{textAlign:'center'}}>Read <a href="/" style={styles.link}>Rules and Policies</a></Typography>
</Column>
}
const ViewStoryPage = () => {
  const {
    SiteData,
  } = useContext(SiteDataContext)
  const history = useNavigate();
  const classes = useStyles();
  const theme = useTheme();
  const [device, setDevice] = useState('pc');
  const [, setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body, updatePreviewBody] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [notificationMsg, ] = useState('');
  const [story, setStory] = useState({});
  const [, setPacket] = useState([]);
  const [readingList, setReadingList] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [author, setAuthor] = useState({});
  const {enqueueSnackbar} = useSnackbar();
  const { retrieveProfileWithCallback, retrieveProfile } = useProfile();
  const { retrieveAdmin } = useAdmin();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [moreAuthorsStories,setMoreAuthorsStories] = useState([]);
  const [relatedStories,setRelatedStories] = useState([]);
  const [loading, setLoading] = useState(true);
  const {
    getStory,
    currentStoryLikes,
    currentStoryViews,
    likeStory,  
    viewStory,
    getMoreStoriesBy,
    getRelatedStories,
    getLikes,
    getViews,
  } = useContext(StoryContext);
  const location = useLocation();
  const { addToReadingList,getReadingList } = useDatabase();
  const handleShare = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const _buildMoreAuthorsStories = ()=>{
    return moreAuthorsStories.length <=0 ? <></> : <Column alignment="centered" style={{width:"100%", alignItems:"start",marginTop:20}}>
        <Typography variant="h6" style={{marginBottom: 20}}>More Stories by {author.fname} {author.lname}</Typography>
        {
          <Grid container spacing={10}>
            {
              moreAuthorsStories.map((story, index)=>{
                return <Grid  key = {index} item xs={12} sm={6}>
                  <Card variant='outlined'>
                    <CardActionArea onClick={()=>{
                      history(`/stories/${story.storyId}`, { state: { story: story } })
                    }}>
                      <StoryCard story={story} />
                    </CardActionArea>
                  </Card>
                </Grid>
              })
            }
             <Grid xs={12}>
              <Button style={{margin:"20px 0px", borderRadius:12}} variant="outlined" color="primary" onClick={()=>{}}>View All Stories By {author.fname} {author.lname}</Button>
              </Grid>
            <Grid xs={12}>
              <Divider style={{opacity: 0.2}}/>
            </Grid>
           
            </Grid>
        }
      </Column>
  }
  const _buildReccomendations = ()=>{
    return relatedStories.length <=0 ? <></> : <Column alignment="centered" style={{width:"100%", alignItems:"start",marginTop:20}}>
        <Typography variant="h6" style={{marginBottom: 20}}>More Stories You Might Like</Typography>
        {
          <Grid container spacing={10}>
            {
              relatedStories.map((story, index)=>{
                return <Grid key = {index} item xs={12} sm={6}>
                  <StoryCard story={story} />
                </Grid>
              })
            }
            </Grid>
        }
    </Column> 
  }
  const handleShareClose = (share) => {
    setAnchorEl(null);
    switch (share) {
      case "fb":
        window.open('https://www.facebook.com/v11.0/dialog/share?redirect_uri=' + encodeURIComponent(`${SiteData.siteLink}/Stories/${story.storyId}`),'_blank');
        break;
      case "tw":
        window.open('https://twitter.com/intent/tweet?text='+encodeURIComponent(`${story.headline} by ${author.fname} ${author.lname} ${SiteData.siteLink}/Stories/${story.storyId}`),"_blank");
        break;
      case "li":
        window.open('https://www.linkedin.com/sharing/share-offsite/?url='+encodeURIComponent(`${SiteData.siteLink}/Stories/${story.storyId}`),"_blank");
        break;
      case "email":
        window.open('mailto:?subject='+encodeURIComponent(`${story.headline} by ${author.fname} ${author.lname}`)+'&body='+encodeURIComponent(`${story.headline} by ${author.fname} ${author.lname} ${SiteData.siteLink}/Stories/${story.storyId}`),"_blank");
        break;
      case "link":
        navigator.clipboard.writeText(`${SiteData.siteLink}/Stories/${story.storyId}`);
        enqueueSnackbar("Link Copied to Clipboard", {variant:"success"});
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    updateDevice();
   if(location.pathname.split("/").length >= 2)
    retrieveStory(location.pathname.split("/")[2])
    retrieveProfileWithCallback((profile) => {
      console.log("?????---->",profile)
      setCurrentUser(profile);
      getReadingList(profile?.uid ?? sessionStorage.getItem("sessionId")).then((res) => {
        console.log(res,"????");
        setReadingList(res??[]);
      })
    })
   
    window.addEventListener("resize", updateDevice);
  }, []);
  useEffect(() => {
    //create an interva that retrieves the views and likes every 5 seconds
    const interval = setInterval(() => {
      if(story.storyId){
        
      getLikes(story.storyId);
      getViews(story.storyId);
      }
    }, 15000);
    return () => clearInterval(interval);
  },[

  ]);
  const retrieveStory = async (storyId) => {
    setLoading(true);
    const ThisStory = location.state?.story ??  await getStory(storyId);
    let user = {};
    if(ThisStory.isDTV){
       user = await retrieveAdmin(ThisStory.userId);
    }else{
       user = await retrieveProfile(ThisStory.userId);
    }
    getMoreStoriesBy(ThisStory.storyId, null, 4, 0).then((res)=>{
      console.log(res)
      if(res)
      setMoreAuthorsStories(res.filter((story)=>{return story.storyId !== ThisStory.storyId && !(story.isDraft!==null&&story.isDraft)}));
    })
    getRelatedStories(ThisStory.storyId,null,4, 0).then((res)=>{
      if(res)
      setRelatedStories(res.filter((story)=>{return story.storyId !== ThisStory.storyId}));
    })
    setAuthor(user);
    getViews(ThisStory.storyId);
    getLikes(ThisStory.storyId);
    //ThisStory = await getStory(storyId);
    setStory(ThisStory);
    unpackStoryBundle(ThisStory.story);
    viewStory({storyId: ThisStory.storyId, userId: currentUser?.uid ?? sessionStorage.getItem("sessionId")});
    console.log(ThisStory);
    setLoading(false);
  }
  const updateDevice = () => {
    if (window.innerWidth < 500) {
      setDevice('mobile');
      setDrawerWidth(300)
    }
    else {
      setDevice('pc')
      setDrawerWidth(280)
    }
  }
  const unpackStoryBundle = (bundle) => {
    let data = JSON.parse(bundle);
    console.log(data);
    let packet = [];
    
    
    data.images?.forEach((image) => {
      packet[image.id] = { data: image, type: "image" };
    });
    data.videos?.forEach((video) => {
      packet[video.id] = { data: video, type: "video" };
    });
    data.paragraphs?.forEach((p) => {
      packet[p.id] = { data: p, type: "paragraph" };
    });
    data.titles?.forEach((t) => {
      packet[t.id] = { data: t, type: "title" };
    });
    data.links?.forEach((l) => {
      packet[l.id] = { data: l, type: "link" }
    });
    setPacket(packet)
  }
  const _buildAuthorMod = ({hideProfile})=>{
    return  <Row alignment={device === "pc" ? "left" : "centered"}>
    {hideProfile ?<></> : <>{
    story.userId ? <FutureBuilder style={{ margin: "10px 0" }}
      futureFunc={()=>getUserProfile(story.userId)}
      builder={(user) => {
        console.log(user)
        return user && <Chip onClick={() => {
          history(`/author/opts?aid=${user.uid}&isDtv=${story.isDTV}`, { replace: true, state: { userId: user.uid, user: user } })
        }} variant="outlined" style={{ fontSize: "15px" }} avatar={<Avatar src={user.photo} />} label={`${user.fname} ${user.lname}`} />
      }
      }
      placeholder={<Chip avatar={<Avatar src="" />} label="loading" />}
    /> : <></>}</>}
      {currentUser?.uid ? <Row style={{ margin: "0 20px" }}>
        <IconButton onClick={() => {
          likeStory(story.storyId, currentUser?.uid ?? sessionStorage.getItem("sessionId")).then(() => {
            getLikes(story.storyId);
          })
          
        }}>{story?.likes?.includes(currentUser?.uid) ? <FavoriteRounded /> : story?.likes?.includes(sessionStorage.getItem("sessionId")) ? <FavoriteRounded /> : <FavoriteBorder />}
        </IconButton> 
        <Column alignment="centered">
          <Typography align="left" variant="overline" style={{ fontSize: "16px", margin: "10", color: alpha(theme.palette.text.primary, 0.7) }}><NumericLabel>{Math.max(currentStoryLikes, 0)}</NumericLabel></Typography>
        </Column>
      </Row>: <></>}
      <Row>
        <Row style={{alignItems:"center", margin:"0 10px"}} alignment="centered">
          <VisibilityOutlined />
          <Typography align="left" variant="overline" style={{ fontSize: "16px", marginLeft: "5px", color: alpha(theme.palette.text.primary, 0.7) }}><NumericLabel>{Math.max(currentStoryViews, 0)}</NumericLabel></Typography>
        </Row>
      </Row>
        </Row>
  }
  const _buildActions = ({mobileMod})=>{
    return <Row alignment={"right"}>
    {!currentUser?.uid ? <></> : <Tooltip title="Add to Reading List" placement="top">
      <Button style={{borderRadius: 100, textTransform:'capitalize', marginRight: 3,}} variant={mobileMod ? 'outlined' : 'text'} startIcon={readingList.filter(read=>read.id === story.storyId)?.length > 0 ? <BookmarkRounded/> : <BookmarkBorderRounded/>} onClick={() => {
      let result = "Reading List Updated";
      addToReadingList(currentUser?.uid,{
        id: story.storyId,
        content:"story",
      }).then(()=>{
        enqueueSnackbar(result, { variant: "success" });
        getReadingList(currentUser?.uid).then(() => {
          
        });
      })
    }}>{mobileMod ? 'Save' : ''}</Button></Tooltip>}
    <Tooltip title="Share" placement="top">
      <Button aria-controls="simple-menu" aria-haspopup="true" style={{borderRadius: 100, textTransform:'capitalize', marginRight: 3,}} variant={mobileMod ? 'outlined' : 'text'} onClick={handleShare} startIcon={<ShareRounded/>}>{mobileMod ? 'Share' : ''}</Button>
    </Tooltip>
    <Menu
id="simple-menu"
anchorEl={anchorEl}
keepMounted
open={Boolean(anchorEl)}
onClose={handleShareClose}
>
<MenuItem onClick={()=>handleShareClose('link')}><Row ><Link/> <Typography style={{marginLeft:3}} >Copy Link</Typography> </Row></MenuItem>
<Divider/>
<MenuItem onClick={()=>handleShareClose('tw')}><Row ><Twitter/> <Typography style={{marginLeft:3}} >Share on Twitter</Typography> </Row></MenuItem>
<MenuItem onClick={()=>handleShareClose('email')}><Row ><Email/> <Typography style={{marginLeft:3}} >Share on Email</Typography> </Row></MenuItem>
<MenuItem onClick={()=>handleShareClose('li')}><Row ><LinkedIn/> <Typography style={{marginLeft:3}} >Share on LinkedIn</Typography> </Row></MenuItem>
</Menu>
    <Tooltip title="Report" placement="top">
    <Button startIcon={<ReportProblemRounded/>} style={{borderRadius: 100, textTransform:'capitalize', marginRight: 3}} variant={mobileMod ? 'outlined' : 'text'}  onClick={() => {
      setShowModal(true);
      updatePreviewBody(
        <ReportPage userId={currentUser?.uid??"anononymus"} authorId={story.userId} storyId={story.storyId} onComplete = {()=>{
          setShowModal(false);
        }}/>
      );

    }}>{mobileMod ? 'Report' : ''}</Button>
    </Tooltip>
    </Row>
  }
  const _buildActionBar = ({hideProfile})=>{
   
    return  <motion.div initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.25, type: "tween", duration: 0.4 }}>
    <Row alignment={"space-between"} style={{margin:"10px 0",width:'100%', padding:"10px 2px", border:"1px solid grey", borderImage:"linear-gradient( to left, rgb(124 124 124 / 42%), rgba(0, 0, 0, 0)) 100% 1"}}>
       {device !== "pc" ? <></> : _buildAuthorMod({hideProfile:hideProfile})}
        { _buildActions({mobileMod: device !== "pc"})}
    </Row>
   
  </motion.div>
  }
  const buildSkeleton = ()=>{
    return <Column alignment="start" style={{ maxHeight: "100%", overflowY: "auto" }}>

    <div style={{ width: "100%", height: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 50%,transparent)` }}>
      <Grid container spacing={5} style={{ width: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 50%,transparent)`, margin: device === 'pc' ? "5% 0 0 0" : "60% 0 10px 0px", padding: device === 'pc' ? "0% 0" : "0" }}>
        <Grid xs={12}>
        <Grid container spacing={5} style={{ width: "100%", padding: device === "pc" ? "20vh 20px" : "8px", margin: "0 auto", maxWidth:900 }}>
          <Grid xs={12}>
             <Skeleton sx={{ bgcolor: 'grey.900' }} variant="rectangular" width={'100%'} height={60} style={{margin: '10px 0'}} />
          </Grid>
          <Grid xs={12}>
             <Skeleton sx={{ bgcolor: 'grey.900' }} variant="rectangular" width={'100%'} height={340} style={{margin: '10px 0'}} />
          </Grid>
          <Grid xs={12}>
             <Skeleton sx={{ bgcolor: 'grey.900' }} variant="rectangular" width={'100%'} height={120} style={{margin: '10px 0'}} />
          </Grid>
          <Grid xs={12}>
             <Skeleton sx={{ bgcolor: 'grey.900' }} variant="rectangular" width={'100%'} height={80} style={{margin: '10px 0'}} />
          </Grid>
          </Grid>
        </Grid>
      </Grid>
      </div>

    </Column>
  }
  const _buildPage = (() => {
    if(loading)
      return buildSkeleton();
    if(story?.storyId === undefined && !loading)
      return <></>
    
    return <Column alignment="start" style={{ maxHeight: "100%", overflowY: "auto" }}>

      <div style={{ width: "100%", height: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 50%,transparent)` }}>

        <Grid container spacing={5} style={{ width: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 50%,transparent)`, margin: device === 'pc' ? "5% 0 0 0" : "60% 0 10px 0px", padding: device === 'pc' ? "0% 0" : "0" }}>
           <Grid xs={12}>
            <Grid container spacing={5} style={{ width: "100%", padding: device === "pc" ? "20vh 20px" : "8px", margin: "0 auto", maxWidth:900 }}>
             <Grid xs={12}>
            <Column alignment="start" style={{ width: "100%", padding: "7% 5% 0% 2%", }}>
              <Row style={{alignItems:'center'}}> 
                {!story.isDTV ? <></> : <Chip variant='outlined' color='primary' style={{marginRight: 10}} label={'DTV Exclusive'} />}
                {device !== "pc" ? _buildAuthorMod({hideProfile: false}):<></>}
              </Row>
              <motion.div initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.1, type: "tween", duration: 0.4 }}>
                <Typography align={device === "pc" ? "left" : "left"} style={{ fontSize: device==='pc'? "6vh" : "8vw", width: device === "pc" ? "100%" : "", fontFamily: "raleway", textTransform: "capitalize", fontWeight: "700", margin: "10px 0" }}>{story.headline}</Typography>
              </motion.div>
              <motion.div initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.3, type: "tween", duration: 0.4 }}><Row>
                <Chip style={{maxWidth:200}} variant="outlined" label = {new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(story.publishedAt)}/>
              </Row>
                </motion.div>
             {_buildActionBar({hideProfile: false})}
            </Column>
          </Grid>
          <Grid xs={12}>
            {story?.story ? <StoryEditor story={story} fullView={true} viewOnly={true}/>:<></>}
          </Grid>
          
             <Grid xs={12}>
          {_buildActionBar({hideProfile: true})}
            </Grid>
            <Grid xs={12}>
              {_buildMoreAuthorsStories()}
              </Grid>
            <Grid xs={12}>
              {_buildReccomendations()}
              </Grid>
            <Grid xs={12}>
            <AdSense.Google
                client='ca-pub-4749149982351318'
                slot='7806394673'
                style={{ width: "100vw", height: 40}}
                format=''
              />
              </Grid>
            </Grid>
          </Grid>
       
        </Grid>
      </div>
      <FooterModule />

    </Column>
  });

  const _buildPageBackGround = (() => {
    //animate = {{opacity:1-scrollPos}}style={{opacity:1-scrollPos}}
    return <Stack style={{ height: "40%" }}>
      <div style={{ width: '100%', height: '100%' }}>
        <img alt="background" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={story?.photo?.blob ?? story?.photo} />
      </div>
      <Column alignment="space-between" style={{ height: "100%", width: "100%" }}>
        <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 20%, transparent)`, height: "100%", width: "100%" }}></div>
      </Column>

    </Stack>
  });
  return (<div className={classes.root}>
    <CssBaseline />
    <Stack style={{ height: "100%", width: "100%", overflow: "hidden" }}>
      <div style={{ background: ` ${alpha(theme.palette.background.default, 1)} `, height: "100%", width: "100%" }}></div>
      {_buildPageBackGround()}
      <div style={{ width: "100%", maxHeight: "100%", height: "100%", }}>{_buildPage()}</div>
    </Stack>
    {<AppBarModule pageIndex={6} />}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification}
      autoHideDuration={notificationMsg.timeout}
      onClose={() => {
        showNotification(false)
      }}
      message={notificationMsg.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  </div>

  )


}
export default ViewStoryPage;

ViewStoryPage.propTypes = {
  story: PropTypes.object
}
ReportPage.propTypes = {
  userId: PropTypes.string,
  storyId: PropTypes.string,
  authorId: PropTypes.string,
  onComplete: PropTypes.func
}