import axios from "axios";
import { baseUrl, RouteNames } from "../config/api";

export const ShowsService = () => {
    const createShow = async ({showTitle, showDesc, showRelease, showCover, views,comments,likes,trailerLink, seasons,casts,genres,directors,producers,countries})=>{
        console.log("Creating Show", showTitle, showDesc, showRelease, showCover, views,comments,likes,trailerLink, seasons);
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.show}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                showTitle,
                showDesc,
                showRelease,
                showCover,
                views,
                comments,
                likes,
                trailerLink,
                seasons,
                casts,
                genres,
                directors,
                producers,
                countries,
                isShowReleased: showRelease < new Date().valueOf()
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data)
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getShow = async ({id})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.show}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getShowList = async ({cursor, limit, offset, all})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.showList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset,
                all
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const comingSoon = async ({cursor, limit, offset})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.showComingSoon}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
    }
}
    const searchShow = async({query, cursor, limit, offset, all})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.showSearch}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                cursor,
                limit,
                offset,
                all
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateShow = async (data)=>{
        console.log("Updating Show", data);
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.show}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id: data.showId
            },
            data
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data)
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteShow = async ({id})=>{
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.show}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getEpisodesForShow = async ({id,cursor, limit, offset})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.showEpisodes}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getEpisodesForSeason = async ({id,season,cursor, limit, offset})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.showSeasonEpisodes}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id,
                season,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getEpisode = async ({id})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.episode}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const createEpisode = async ({showId, title,description,season_no,episode_no,next_episode,views,link,thumbnail,release,duration,isYoutube,casts,genres,directors,producers,countries}) => {
    
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.episode}`,
            params:{
                showId
            },
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                showId,
                title,
                description,
                season_no,
                episode_no,
                next_episode,
                views,
                link,
                thumbnail,
                release,
                duration,
                isYoutube,
                casts,
                genres,
                directors,
                producers,
                countries
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data)
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateEpisode = async (data)=>{
        const request = {
            method: 'put',
            url: `${baseUrl}/${RouteNames.episode}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params:{
                id: data.episodeId
            },
            data
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data)
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const deleteEpisode = async ({id})=>{
        const request = {
            method: 'delete',
            url: `${baseUrl}/${RouteNames.episode}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                id
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            console.log(response.data)
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const searchEpisode = async({query, id, cursor, limit, offset})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.showSearchEpisodes}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                query,
                id,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const addToWatchlist = async ({userId, showId, episodeId, timestamp,date_started, date_finished})=>{
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.watchList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                userId,
                showId,
                episodeId,
                timestamp,
                date_started,
                date_finished
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const addToContinueWatching = async ({userId, showId, episodeId, progress})=>{
        const request = {
            method: 'post',
            url: `${baseUrl}/${RouteNames.watchListConitinue}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            data: {
                userId,
                showId,
                episodeId,
                progress
            }
        };
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getWatchList = async ({userId, cursor, limit, offset})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.watchList}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getContinueWatching = async ({userId,episodeId,showId, cursor, limit, offset})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.watchListConitinue}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                showId,
                episodeId,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const getContinueWatchingList = async ({userId, cursor, limit, offset})=>{
        const request = {
            method: 'get',
            url: `${baseUrl}/${RouteNames.watchListConitinue}`,
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data.data?.data,
                count: response.data.data?.count,
                total: response.data.data?.total,
                cursor: response.data.data?.cursor,
                status: response.data.status ?? 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        createShow,
        getShow,
        getShowList,
        searchShow,
        updateShow,
        deleteShow,
        getEpisodesForShow,
        getEpisodesForSeason,
        getEpisode,
        createEpisode,
        updateEpisode,
        deleteEpisode,
        searchEpisode,
        addToWatchlist,
        addToContinueWatching,
        getWatchList,
        getContinueWatching,
        getContinueWatchingList,
        comingSoon
    }
};