/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { Button, Card, CardContent, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, TextField, Typography, alpha, useTheme } from "@material-ui/core"
import Column from "../../Column";
import { UploadProfilePicture } from "../../UploadDiv";
import useServiceBooking from "../../../contexts/ServiceBookingProvider";
import { useDatabase } from "../../../hooks/useDatabase";
import Row from "../../Row";
import {motion} from 'framer-motion';
import ServiceAgreementPage from "../../../pages/serviceAgreementPage";
import { isValidLink, loadFromDevice } from "../../../utils";
import { useStorage } from "../../../contexts/storage_handler";
import { useSnackbar } from "notistack";
import shortUUID from "short-uuid";
import { sendFeedback } from "../../../config/emailer";
import { useSiteData } from "../../../config/SiteData";
import SubmissionModal from "../../_build_submission_modal";
import PropTypes from 'prop-types'


const ServiceAgentApplication = ({ profile, open, onClose, onSubmit }) => {
    const [openDialog, setOpenDialog] = useState(open);
    const {SiteData} = useSiteData();
    const [serviceTypes, setServiceTypes] = useState({});
    const [loading, setLoading] = useState(true);
    const [newAgentApplication, setNewAgentApplication] = useState({});
    const { getAllCustomerAgreements, submitServiceAgentForm,sendNotification} = useDatabase();
    const [serviceAgreement, setServiceAgreements] = useState({});
    const [currentServiceAgreement, setCurrentServiceAgreement] = useState({});
    const [openAgreementDialog, setOpenAgreementDialog] = useState(false);
    const { uploadMediaOperation } = useStorage();
    const [uploading, setUploading] = useState(false);
    const { getServiceTypes } = useServiceBooking();
    const {enqueueSnackbar} = useSnackbar();
    const modalRef = React.useRef();
    const theme = useTheme ();
    useEffect(()=>{
        getAllCustomerAgreements().then((agree)=>{
            const agmts = Object.values(agree);
            setServiceAgreements(agmts);
            newAgentApplication.serviceAgreement = agmts[0].id;
            getServiceTypes().then((res)=>{
            setServiceTypes(res);
            newAgentApplication.serviceType = res[Object.keys(res)[0]];
            setLoading(false);
        })
        })
        
        setOpenDialog(open);
    },[open]);
    const uploadProfileImage = async ()=>{
        if(newAgentApplication.picture && !isValidLink(newAgentApplication.picture)){
          return await uploadMediaOperation(newAgentApplication.picture,"agents_profile_picture");   
        }
        return newAgentApplication.picture;
    }
    const notify = async (id, onSuccess)=>{
       await  sendNotification({
            title: "New Service Agent Submission",
            body: `${profile.fname} ${profile.lname} has submitted a new service agent application`,
            userId: profile.uid,
            type: 'service_agent_submission',
            parentId: id,
            timestamp: new Date().getTime(),
            seen: false
          });
        sendFeedback({
            to: profile.email,
            from: SiteData.email,
            subject: "Service Agent Application",
            body: `<p>Dear ${profile.fname} ${profile.lname},</p>
            <p>Thank you for submitting your application to become a service agent on DearFach. We will review your application and get back to you as soon as possible.</p>
            <p>Kind Regards,</p>
            <p>DearFach Team</p>
            `

        }, ()=>{
            sendFeedback({
                to: SiteData.email,
                from: SiteData.email,
                subject: "Service Agent Application",
                body: `<p>Dear Admin,</p>
                <p>${profile.fname} ${profile.lname} has submitted a new service agent application</p>
                <p>Kind Regards,</p>
                <p>DearFxch.com</p>
                `
    
            },()=>{
                modalRef.current.open(true);
                onSuccess();
            })
        })
        
    }
    const handleSubmit = async () => {
        console.log(newAgentApplication);
        if(!newAgentApplication.name || !newAgentApplication.email || !newAgentApplication.serviceType?.name || !newAgentApplication.location){
            enqueueSnackbar("Please fill in all fields", {variant: 'error'});
            return;
        }else if(!newAgentApplication.serviceAgreement){
            enqueueSnackbar("Please select a service agreement", {variant: 'error'});
            return; 
        }
        setUploading(true);
       
        try{
        
             const picture = await uploadProfileImage();
             const submission = {
                id: shortUUID.generate(),
                name: newAgentApplication.name,
                email: newAgentApplication.email,
                serviceType: newAgentApplication.serviceType?.name,
                serviceAgreement: newAgentApplication.serviceAgreement,
                location: newAgentApplication.location,
                uid: profile.uid,
                sentBy: `${profile.fname} ${profile.lname}`,
                picture: picture??'',
                dateSent: Date.now(),
                status: 'pending',
                statusDates: {
                    pending: Date.now()
                 },
                }  
                console.log(submission);
        //send to firestore
        if(await submitServiceAgentForm(submission)){
            //send email
            notify(submission.id, ()=>{
                enqueueSnackbar("Application Submitted Successfully", {variant: 'success'});
                onClose()
                setUploading(false);
            });
            
            if(onSubmit)
            onSubmit(newAgentApplication);
        }else{
            enqueueSnackbar("Error Occured", {variant: 'error'});
            setUploading(false);
        }
        
        }catch(e){
            enqueueSnackbar(e.message??"Error Occured", {variant: 'error'});
            setUploading(false);
        }
        
    }
    return <>
    <Dialog open={openDialog}  maxWidth="md" fullWidth  aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Service Agent Application</DialogTitle>
        <DialogContent>
            { loading ? <Column>
                <CircularProgress/>
            </Column> : (serviceTypes && <Grid container spacing={6}>
                <Grid item xs={12}>
                    <UploadProfilePicture onUpload={
                        (file)=>{
                            loadFromDevice(file,(blob)=>{
                                newAgentApplication.picture = isValidLink(blob) ? blob : {
                                    blob: blob,
                                    file:file
                                }
                            })
                        }
                    } image={profile?.picture}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        onChange={
                            (e)=>{
                                setNewAgentApplication({...newAgentApplication, name: e.target.value});
                            }
                        }
                    InputLabelProps={{ shrink:true }} label="Create Agent Name" variant="outlined" fullWidth/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField 
                        onChange={
                            (e)=>{
                                setNewAgentApplication({...newAgentApplication, email: e.target.value});
                            }
                        }
                    InputLabelProps={{ shrink:true }} label="Create Agent Email" variant="outlined" fullWidth/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField select onChange={
                        (e)=>{
                            setNewAgentApplication({...newAgentApplication, serviceType: serviceTypes[e.target.value]});
                        }
                    } value={newAgentApplication.serviceType.name} InputLabelProps={{ shrink:true }} label="Service Type" variant="outlined" fullWidth>
                        {
                            Object.keys(serviceTypes).map((key, index)=>{
                                return <MenuItem key={index}  value={key}>{serviceTypes[key].name}</MenuItem>
                            })
                        }
                    </TextField>
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <TextField onChange={
                        (e)=>{
                            setNewAgentApplication({...newAgentApplication, location: e.target.value});
                        }
                    } InputLabelProps={{ shrink:true }} label="Where are you based, (city, country)" variant="outlined" fullWidth/>
                </Grid>
                <Grid container item xs={12} spacing={2}>
                        {
                            serviceAgreement.map((agreement, index)=>{
                                return <Grid key={index+agreement.id} item xs={12} sm={6}>
                                    <motion.div whileHover={{scale: 1.06}} whileTap={{scale: 0.9}} style={{maxWidth: 200,}}>
                                    <Card style={{padding: 10, maxWidth: 200,
                                        borderColor: newAgentApplication.serviceAgreement === agreement.id ? 'green' : 'grey',
                                    }} variant="outlined">
                                        <CardContent>
                                            <Typography variant="body1">{agreement.name}</Typography>
                                        </CardContent>
                                        <div style={{height: 10}}/>
                                            <Row alignment="space-between">
                                                <Chip label={agreement.cost ? `${agreement.cost}` : 'FREE'} variant="outlined" color="primary"/>
                                                <Button onClick={
                                                    ()=>{
                                                        setCurrentServiceAgreement(agreement);
                                                        setOpenAgreementDialog(true);
                                                    }
                                                }>
                                                    <Typography variant="caption">READ</Typography>
                                                </Button>
                                            </Row>
                                    </Card>
                                    </motion.div>
                                </Grid>
                            })
                        }
                </Grid>
                
            </Grid>)}
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>{
                setUploading(false);
            }}>Cancel</Button>
            <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
    </Dialog>
    <Dialog open={openAgreementDialog} onClose={()=>{
        setOpenAgreementDialog(false);
    }} maxWidth="md" fullWidth  aria-labelledby="form-dialog-title">
         <DialogContent>
         <ServiceAgreementPage agreement={currentServiceAgreement} viewOnly/>       
            </DialogContent>      
            <DialogActions>
                <Button onClick={()=>{
                    setOpenAgreementDialog(false);
                }}>Close</Button>
            </DialogActions> 
    </Dialog>
    <Dialog open={uploading} onClose={()=>{
        setUploading(false);
    }} maxWidth="md" fullWidth  aria-labelledby="form-dialog-title">
            <DialogContent>
                <Column style={{
                    width: '100%',
                    minHeight:120,
                    alignItems: 'center'
                }}>
                    <CircularProgress/>
                    <div style={{height: 10}}/>
                    <Typography variant="caption">Uploading...</Typography>
                </Column>
            </DialogContent>
    </Dialog>
    <SubmissionModal ref={modalRef} successText={
                <Column>
                 <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">Your Submission has been sent</Typography>
                <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">{"We'll back back to you within"} <span style={{ color: alpha(theme.palette.text.primary, 1) }}>3-5 workin days</span></Typography>
                <Typography variant="caption" style={{ color: alpha(theme.palette.text.primary, 0.5) }} align="center">for any enquiry visit out <a href={"/contact"} style={{ textTransform: "underline", color: alpha(theme.palette.text.primary, 1) }}>contact page</a></Typography>
                
                </Column>
            } onCompleted={
                ()=>{
                    modalRef.current.open(false);
                }
            } open={false}/>
    </>
};
export default ServiceAgentApplication;

ServiceAgentApplication.propTypes = {
    agent: PropTypes.object,
    profile: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func
}