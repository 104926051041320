/* eslint-disable react-hooks/exhaustive-deps */

import { alpha, Button, Card, CardContent, Dialog, Grid, IconButton, makeStyles, Snackbar, TextField, Typography, useTheme, withStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Close } from '@material-ui/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Center from '../components/Center';
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { SiteDataContext } from '../config/SiteData';
import "../index.css";

import { motion } from 'framer-motion';
import { Outlet } from 'react-router';
import { AppBarModule } from '../components/_app_bar_mod';
import { FooterModule } from '../components/_footer_mod';
import { SocailsList } from '../components/_socail_mod';
import { configEmailJs, sendFeedback } from '../config/emailer';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
   
    root: {
        display: 'flex',
        background:"black",
        overflowY:'hidden',
        maxWidth:'100%',
        flexGrow: 1,
        '& .MuiDivider-root':{
            backgroundColor:'white'
        },
        '& .MuiList-root .MuiListItem-root .MuiListText-root':{
            color: 'white',
            borderBottomColor: '#e49726',
        },
        '& label.Mui-focused': {
            color: '#e49726',
            borderBottomColor: '#e49726',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#e49726',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#b77514',
            },
            '&:hover fieldset': {
              borderColor: '#b77514',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#e49726',
            },
          },
    
      },
      iconBtn:{
        color:"#ffffff8c",
        '&:hover': {
            color:"white"
        },
      },
      sideBarSocials:{
          color:"#ffffff47",
          fontSize:"12px",
        '&:hover': {
            color:"white"
        },
      },
      divider:{
          backgroundColor: "rgba(1,1,1, 1)"
      },
      center:{
        textAlign:"center",
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
      },
      gridList: {
        width: 500,
        height: 450,
      },
      icon: {
        color: 'rgba(255, 255, 255, 0.54)',
      },
      toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
      },
     
      appBar: {
        zIndex: theme.zIndex.drawer + 1,
        padding:"3px 1px",
        position:"fixed",
       // background:"linear-gradient(45deg, black, #d09612bd);",
        background: "transparent",
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
      },
      socialIcon:{
        color:"#ffffff91",
        '&:hover': {
            color:"white"
        },
      },
      menuButtonHidden: {
        display: 'none',
      },
      title: {
        flexGrow: 1,
      },
      listItem:{
        backgroundColor: "#2C2C2C",
        color: "white",
        margin:10
      },
      listItemImage:{
        height:"60px",
        width:"60px",
        margin:"10px"
      },
     list:{
        transition: theme.transitions.create(["background", "background-color"], {
            duration: theme.transitions.duration.complex,
          }),
          "&:hover": {
            backgroundColor: "#6363638c",
            color:"black",
          },
     },
      drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      },
      grow:{
          '&:hover':{
            transform: 'scale(1.1)',
            easing:"linear",
            cursor: 'pointer'
          }
      },
      textfield:{
          color:"white",
          "&::placeholder": {
            color: "white"
          },
      },
      textfieldHint:{
        color:"white"
    },
      link:{
          color:"#ffffff9a",
        '&:hover':{
            transform: 'scale(1.1)',
            cursor: 'pointer',
            color:'white'
          }
      },
      appBarSpacer: theme.mixins.toolbar,
      content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
      },
      mobileNav:{
          width:"60%"
      },
      pcNav:{
          width:"30%"
      },
      logo:{
          height:"60px",
          width:"60px"
      },
      avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.text.primary,
      },
      container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
      paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
      },
      fixedHeight: {
        height: 240,
      },
  }));

  const BorderColorButton = withStyles((theme) => ({
    root: {
      color: theme.palette.getContrastText("#ffb03a"),
      padding:"12px",
      borderColor: '#de8500',
      '&:hover': {
        color: '#ffb03a',
      },
    },
    label:{
        color:"white",
        '&:hover': {
            color: '#ffb03a',
          },
    }
  }))(Button);
///
const ContactPage = ()=>{
  const {
    SiteData,
  } = useContext(SiteDataContext)
  const classes = useStyles();
  const theme = useTheme();
  const [device,setDevice] = useState('pc');
  const [,setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] =useState(false);
  const [notificationMsg, setNotificationMsg] =useState('');
  const [copyText,setcopyText]=useState('');
  const [value, setValue] = useState()
  const [fname, setFname] = useState()
  const [lname, setLname] = useState()
  const [ename, setEname] = useState()
  const [subject, ] = useState()


    useEffect(()=>{
        updateDevice();
        configEmailJs();
        window.addEventListener("resize", updateDevice);
    },[])
  const updateDevice = ()=>{
      if(window.innerHeight<900 && window.innerWidth<750)
      {
          setDevice('mobile');
          setDrawerWidth(300)
      }
      else {
          setDevice('pc')
          setDrawerWidth(280)
        }
  }
  const submitEmail = ()=>{
      var payload={
          "from":emailRef.current.value,
          "to":SiteData.contactPage.mainEmail,
          "subject":subjectRef.current.value??`Message From ${fnameRef.current.value} ${lnameRef.current.value}`,
          "body":`Message From ${fnameRef.current.value} ${lnameRef.current.value}\n\n${messageRef.current.value}`
      }
      setEname("")
      setFname("")
      setLname("")
      setLname("")
      setValue("")
      if(payload.body.length>10&&payload.from!=='')
      sendFeedback(payload,()=>{
        setNotificationMsg({message: "Message Sent! We'll Get in Touch",timeout:10000})
        showNotification(true);
      },(e)=>{
          console.log(e)
        setNotificationMsg({message: "Cant Send Messages Right Now! Email us directly",timeout:10000})
        showNotification(true);
      })
      else{
        setNotificationMsg({message: "Invalid Form (Email and Message is required)",timeout:10000})
        showNotification(true);
      }
      console.log(payload);
  }
  const textAreaRef = useRef(null);
  const emailRef = useRef(null);
  const fnameRef = useRef(null);
  const lnameRef = useRef(null);
  const messageRef = useRef(null);
  const subjectRef = useRef(null);
  function openInNewTab(url) {
    if(url!=="/")
   {
        var win = window.open(url, '_blank');
        win.focus();
    }
  }
  function copyToClipboard(val) {
    setcopyText(val);
    textAreaRef.current.select();
    
    document.execCommand('copy');
    setNotificationMsg({message: "Copied!",timeout:2000})
    showNotification(true);
  }
  const _buildPage = (()=>{
      //...
      return <>
        <Center style={{background:"linear-gradient(0deg, black 30%, transparent 150%)"}}>
            <Column style={{height:"100%",display: device==='pc' ? "flex":"block"}}>
           
                <div style={{margin:device==='pc'?"5vw":"8vh"}}></div>
                <motion.div>
                    <Row alignment="centered">
                    
                        <Card style={{width:device==='pc'?"100%":"90%", maxWidth:"900px", maxHeight:device==='pc'?"700px":"100vh", backgroundColor:"#ffffff11", padding:device==='pc'?"1.5vw":"2vw"}}>
                <motion.div initial={{opacity:0.2,x:100}} animate={{opacity:1,x:0}} ><Column>
                  <motion.label style={{color:"#ffffffac", fontSize:"1.2vh" , fontWeight:"lighter", fontFamily:"Pridi"}}>
                    { `${SiteData.name.toUpperCase()}`}
                </motion.label>
                <motion.label style={{color:"white", fontSize:device==='pc'?"3vh":"6vh", fontFamily:"Pridi"}}>
                    {SiteData.contactPage.title.toUpperCase()}
                </motion.label>
                <motion.div>
                    <SocailsList 
                        youtube={SiteData.socials.youtube}
                        facebook={SiteData.socials.facebook}
                        twitter={SiteData.socials.twitter}
                        instagram={SiteData.socials.instagram}
                        className={classes.socialIcon}
                    />
                </motion.div>
                <Column style={{padding:"1.3vw"}}>
                    <Row alignment="left">
                        <TextField inputRef={fnameRef}
                        style={{margin:device==='pc'?"10px":"5px",width:"50%",color:"white"}}
                    id="filled-read-only-input"
                    label="First Name"
                    defaultValue={fname}
                    placeholder="First Name"
                    InputLabelProps={{
                        classes: {
                            input: classes.textfieldHint,
                        },
                        style:{
                            color:"white"
                        }
                    }}
                    InputProps={{
                        classes: {
                            input: classes.textfield,
                            
                        },
                        style:{
                            background:"#ffffff1c"
                        }
                    }}
                    variant="filled"
                    />
                    <TextField  inputRef={lnameRef}
                    style={{margin:device==='pc'?"10px -10px 10px 10px":"5px",width:"50%",color:"white"}}
                    id="filled-read-only-input"
                    label="Last Name"
                    placeholder="Last Name"
                    defaultValue={lname}
                    InputLabelProps={{
                        classes: {
                            input: classes.textfieldHint,
                        },
                        style:{
                            color:"white"
                        }
                    }}
                    InputProps={{
                        classes: {
                            input: classes.textfield
                        },
                        style:{
                            background:"#ffffff1c"
                        }
                    }}
                    variant="filled"
                    />
                    </Row>
                    <TextField inputRef={emailRef}
                    style={{margin:device==='pc'?"5px 10px":"5px 0px",width:"100%",color:"white"}}
                    id="filled-read-only-input"
                    label="Email"
                    type="email"
                    required
                    placeholder="Example@gmail.com"
                    defaultValue={ename}
                    InputLabelProps={{
                        classes: {
                            input: classes.textfieldHint,
                        },
                        style:{
                            color:"white"
                        }
                    }}
                    InputProps={{
                        classes: {
                            input: classes.textfield
                        },
                        style:{
                            background:"#ffffff1c"
                        }
                    }}
                    variant="filled"
                    />
                    <TextField inputRef={subjectRef}
                    style={{margin:device==='pc'?"5px 10px":"5px 0px",width:"100%",color:"white"}}
                    id="filled-read-only-input"
                    label="subject"
                    placeholder=""
                    defaultValue={subject}
                    InputLabelProps={{
                        classes: {
                            input: classes.textfieldHint,
                        },
                        style:{
                            color:"white"
                        }
                    }}
                    InputProps={{
                        classes: {
                            input: classes.textfield
                        },
                        style:{
                            background:"#ffffff1c"
                        }
                    }}
                    variant="filled"
                    />
                     <TextField inputRef={messageRef}
                    style={{margin:device==='pc'?"10px 10px":"10px 0px",width:"100%",color:"white"}}
                    id="filled-read-only-input"
                    label="Message"
                    multiline={true}
                    rows={5}
                    placeholder=""
                    required
                    defaultValue={value}
                    InputLabelProps={{
                        classes: {
                            input: classes.textfieldHint,
                        },
                        style:{
                            color:"white"
                        }
                    }}
                    InputProps={{
                        classes: {
                            input: classes.textfield
                        },
                        style:{
                            background:"#ffffff1c"
                        }
                    }}
                    variant="filled"
                    />
                    <BorderColorButton variant="outlined" fullWidth={true} onClick={()=>{
                        submitEmail();
                    }} className={classes.borderButton}>Submit</BorderColorButton>
                </Column>
                </Column>
              </motion.div>
                </Card>
                    </Row>
               
                </motion.div>
                <div style={{margin:"20px"}}></div>
                <Row alignment="centered" style={{width:"100%",background:"black"}}>
                     <Grid container style={{padding:"20px"}} spacing = {30}>
                     {( SiteData.contactPage?.numbers?.length??0)<1?<></>:
                    <Grid xs={device==='pc'?3:12} style={{width:device==='pc'?"90%":"100%", marginBottom:"20px"}} >
                      <motion.div initial={{scale:0.8}} animate={{scale:1}} >  <Card className={classes.grow} style={{width:device==='pc'?"90%":"100%", backgroundColor:"#ffffff11", padding:"1.5vw"}}>
                            <CardContent >
                                <Column>
                                    <Typography style={{color:"white", fontFamily:"Pridi" }} align="center">Phone Number</Typography>
                                    <div style={{margin:"10px"}}></div>
                                    {
                                        SiteData.contactPage.numbers?.map((data, index)=> <div key={index} onClick={()=>{copyToClipboard(data)}}><Typography style={{fontSize:device==='pc'?"14px":"12px",fontFamily:"Pridi" }} className={classes.link} align="center">{data}</Typography></div>)
                                    }
                                </Column>
                            </CardContent>
                        </Card> </motion.div>
                    </Grid>
                  }
                   
                    
                   { (SiteData.contactPage?.emails?.length??0)<1?<></>: 
                   <Grid xs={device==='pc'?3:12} style={{width:device==='pc'?"90%":"100%", marginBottom:"20px"}}>
                    <motion.div initial={{scale:0.8}} animate={{scale:1}} transition={{delay:0.2}}> <Card className={classes.grow} style={{width:device==='pc'?"90%":"100%", backgroundColor:"#ffffff11", padding:"1.5vw"}}>
                            <CardContent >
                                <Column>
                             
                                    <Typography style={{color:"white", fontFamily:"Pridi" }} align="center">Email</Typography>
                                    <div style={{margin:"10px"}}></div>
                                    {
                                        SiteData.contactPage.emails?.map((data, index)=>
                                        
                                        <div key={index} onClick={()=>{copyToClipboard(data)}}><Typography className={classes.link} style={{ fontFamily:"Pridi", fontSize:device==='pc'?"14px":"12px"}} align="center">{data}</Typography></div>)
                                    }
                                </Column>
                            </CardContent>
                        </Card> </motion.div>
                    </Grid>}
                   
                    
                   { (SiteData.contactPage?.locations?.length??0)<1?<></>: <Grid xs={device==='pc'?3:12}  style={{width:device==='pc'?"90%":"100%", marginBottom:"20px"}}>
                    <motion.div initial={{scale:0.8}} animate={{scale:1}} transition={{delay:0.3}}>  <Card className={classes.grow} style={{width:device==='pc'?"90%":"100%", backgroundColor:"#ffffff11", padding:"1.5vw"}}>
                            <CardContent >
                                <Column>
                                    <Typography style={{color:"white", fontFamily:"Pridi" }} align="center">Locations</Typography>
                                    <div style={{margin:"10px"}}></div>
                                    {
                                        SiteData.contactPage.locations.map((data, index)=> <div key={index}  onClick={()=>{copyToClipboard(data)}}><Typography className={classes.link} style={{ fontSize:device==='pc'?"14px":"12px",fontFamily:"Pridi" }} align="center">{data}</Typography></div>)
                                    }
                                </Column>
                            </CardContent>
                        </Card>  </motion.div>
                    </Grid>}
                  
                    { (SiteData.contactPage?.links?.length??0)<1?<></>:
                    <Grid xs={device==='pc'?3:12}>
                    <motion.div initial={{scale:0.8}} animate={{scale:1}} transition={{delay:0.4}} >  <Card className={classes.grow} style={{width:device==='pc'?"90%":"100%", backgroundColor:"#ffffff11", padding:"1.5vw"}}>
                            <CardContent >
                                <Column>
                                    <Typography style={{color:"white", fontFamily:"Pridi" }} align="center">Links</Typography>
                                    <div style={{margin:"10px"}}></div>
                                    {
                                        SiteData.contactPage?.links?.map((data, index)=> <div key={index}  onClick={()=>{openInNewTab(data)}}><Typography className={classes.link} style={{fontSize:device==='pc'?"14px":"12px", fontFamily:"Pridi" }} align="center">{data}</Typography></div>)
                                    }
                                </Column>
                            </CardContent>
                        </Card>  </motion.div>
                    </Grid>
                    }
                  
                </Grid>
           
                </Row>
                </Column>
        </Center>
      </>
  });
console.log("Contact Page")
 return (<div className={classes.root}>
         <CssBaseline />
        <Stack>
            <div style={{height:"100%", filter:'blur(3px)',  background:"black",width:"100%"}}>
            <div style={{ height: "100%", 
      position: "relative",
      width: "100%", /* 16:9 aspect ratio */
      overflow: "hidden",
      background: alpha(theme.palette.background.default, 1),}}>
        <div 
          style={
            {position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,}
          }
        >{SiteData.contactPage?.isVideo ? 
       
         <ReactPlayer playsinline width = "100%" height = "100%" url={SiteData.contactPage?.featuredCover}
          autoPlay={true}
          playing={true} 
            controls={false}
          loop={true}
          muted={true}
          style={{overflow:"hidden", objectFit: "cover", transform: `scale(${device==='mobile'? '4.5': '1.5'})` }}
          className="react-player" /> : <img alt=""src={SiteData.contactPage?.featuredCover} width="100%" height="100%" style={{ objectFit: "cover" }} />}</div>
      </div>
            </div>
            <div style={{background:"#0000000d", height:"100%", width:"100%"}}></div>
            {/* <div style={{background:"linear-gradient(0deg, black 30%, transparent 150%)",height:"100%", width:"100%"}}></div>
            */}
           
           <FooterModule device={device} hideBranding={true}/>
           {_buildPage()}
        </Stack>
        {SiteData.live?<AppBarModule showCart pageIndex={2} iconClass={classes.iconBtn} menuButton={classes.iconBtn}/>:<></>}
         <Dialog
      maxWidth="lg"
      onClose={()=>{setShowModal(false)}}
      fullWidth
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
        {preview_body}
      </Dialog>
      <textarea
          ref={textAreaRef}
          style={{display:"hidden"}}
          value={copyText}
        />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={notification}
        autoHideDuration={notificationMsg.timeout}
        onClose={()=>{
          showNotification(false)
        }}
        message={notificationMsg.message}
        action={
          <React.Fragment>
            <IconButton size="small" aria-label="close" color="inherit" onClick={()=>{  showNotification(false)}}>
              <Close fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
     
   />
   <Outlet/>
    </div>)

   
}
export default ContactPage;