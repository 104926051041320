import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { createContext, useContext, useState } from "react"
import { useSnackbar } from "notistack";
import { useTheme } from "@material-ui/core";
import { PaymentService } from "../services/paymentService";
import PropTypes from 'prop-types';

const StripeContext = createContext();

export const useStripePaymentContext = ()=>useContext(StripeContext);


export const StripeProvider = (props)=>{

const [stripePaymentLoading, setPaymentLoading] = useState(false);
const { enqueueSnackbar, } = useSnackbar();
const [paymentSuccess, ] = useState(false);
const stripe = useStripe();
const elements = useElements();
const theme = useTheme();
const {
    createPaymentCustomer,
    getPaymentCustomer,
    getServiceAccount,
    createServiceAccount,
    makePayment

} = PaymentService();
    const StripeOptions = {
        // passing the client secret obtained from the server
       style:{
           base:{
               color:theme.palette.text.primary
           }
       },
       hidePostalCode:true  
      };
      const StripeElement = () => {
       
        return (
            
          <CardElement options={StripeOptions}/>
        );
    };
    const runTransaction = async(price, {
        customerId,
        email,
        shipping,
        tax
    } )=>{
        try{
            
      
        setPaymentLoading(true);
        
        let amnt = (price * 100);
        
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type:'card',
            card: elements.getElement(CardElement)
        })
           
        if(!error){
              console.log("------",amnt,price); 
            const {id} = paymentMethod;
            try{
                
                const {status} = await makePayment({
                    amount:amnt,
                    id,
                    customer:customerId,
                    email,
                    currency: 'eur',
                    shipping,
                    tax: tax??0
                })
                setPaymentLoading(false);
                return status === 200;
            }catch(e){
                enqueueSnackbar("Payment failed!",{ variant: 'error', anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },})
                console.log(e);
            }
           setPaymentLoading(false);
    
            return false;
        }else{
            console.log(error);
            enqueueSnackbar("Payment failed!",{ variant: 'error', anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
            },})
            setPaymentLoading(false);
            return false;
        }
        
        }catch(e){
            console.log(e);
            setPaymentLoading(false);
        }
         
       
    }
    const getConnectedAccount = async({id})=>{
        try{
            const {
                data,
                status
            } = await getServiceAccount({id});
            if(status === 200){
                return data
            }
            return false;
        }catch(e){
            console.log(e);
        }
    }
    const createConnectedAccount = async({
        email,
    })=>{
        const params = {
            email
         }
        try{
            const {status, data} = await createServiceAccount(params);
            if(status === 200){
                console.log("Account created");
                return data;
            }
            return false;
        }
        catch(e){
            console.log(e);
        }
    }
    const createCustomer = async({email, name})=>{
        try{
            const {status, data} = await createPaymentCustomer({email, name});
            if(status === 200){
                console.log("Customer created");
                return data;
            }
            return false;
        }catch(e){
            console.log(e);
        }
    }
    const getCustomer = async({uid})=>{
        try{
            const {data, status} = await getPaymentCustomer({id:uid});
            if(status === 200){
                console.log("Customer created");
                return data;
            }
            return false;
        }catch(e){
            console.log(e);
        }
    }
    return <StripeContext.Provider 
        value={{
            runTransaction,
            stripePaymentLoading,
            paymentSuccess,
            StripeElement,
            createCustomer,
            getCustomer,
            createConnectedAccount,
            getConnectedAccount
        
        }}
    >
        {props.children}
    </StripeContext.Provider>
}

StripeProvider.propTypes = {
    children: PropTypes.node
}