/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, Chip, CircularProgress, Dialog, IconButton, Step, StepLabel, Stepper, Typography, makeStyles, useTheme, withStyles } from "@material-ui/core"
import React, { useContext, useEffect, useState } from "react"
import { useDatabase } from "../hooks/useDatabase"
import Row from "./Row";
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Check, LocalLibrary } from "@material-ui/icons";
import Column from "./Column";
import { VideoSubmissionProgress, _buildConfirmation,getStatusColor, getStatusIcon  } from "../utils";
import StepConnector from '@material-ui/core/StepConnector';
import BundleCard from "./_bundle_card";
import { Button } from "semantic-ui-react";
import { useNavigate } from "react-router";
import { addSubmissionToCart, isInCart,updateVideoSubmission } from "../logic/databaseHandler";
import FutureBuilder from "./_future_builder";
import { useSnackbar } from "notistack";
import { sendFeedback } from "../config/emailer";
import { SiteDataContext } from '../config/SiteData';
import { useMediaQuery } from "../contexts/MediaQueryProvider";
import { Skeleton } from "@mui/material";


const QontoConnector = withStyles({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    completed: {
      '& $line': {
        borderColor: '#784af4',
      },
    },
    line: {
      borderColor: '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  })(StepConnector);
  
  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#784af4',
    },
    circle: {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
    completed: {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
  });
  
  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed,name} = props;
  
    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <Check className={classes.completed} /> : getStatusIcon(name,active ? 'white':'grey',16)}
      </div>
    );
  }
  
  QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,

    icon: PropTypes.number,

    name: PropTypes.string,
  };
//status: submitted, approved, rejected, pending, cancelled, payment, validating, published, refund

const StorySubmissionCard= ({data, profile, onOpenPreview, disabled}) => {
    const {bundleId, storyId,paid,published,sId, seen, status, timestamp,userId, statusDates, useSkeleton} = data;
    const {retrieveBundle, getDraft,sendNotification, loading} = useDatabase();
    const [bundle, setBundle] = useState(null);
    const history = useNavigate();
    const {isLargeDevice} = useMediaQuery();
    const {enqueueSnackbar} = useSnackbar();
    const [story, setStory] = useState(null);
    const [hideCartButton, setHideCartButton] = useState(false);
    const [open, setShowOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState(<></>);
    const {
      SiteData,
    } = useContext(SiteDataContext)
    const [filteredProgression, ] = useState(VideoSubmissionProgress.filter(p => {
        return (
          (p === 'approved' && statusDates.approved) ||
          (p === 'rejected' && !statusDates.approved && statusDates.rejected) ||
          (p === 'cancelled' && statusDates.cancelled) ||
          (p !== 'approved' && p !== 'rejected' && p !== 'cancelled')
        );
      }).filter(p=>p !=='payment'&& p!== 'validating'));
    useEffect(() => {
        getDraft(storyId).then((story) => {
          setStory(story);
            retrieveBundle({bundleId: bundleId, isStory: true}).then((bundle) => {
              setBundle(bundle);
          })
        })
        
    }, []);
    const theme= useTheme();
    const handleAddToCart = async()=>{
      console.log({
        uid: userId,
        bundleId: bundleId, 
        sId: sId, 
        title: bundle?.title+" Bundle",
        summary: story?.headline,
        price: bundle?.price,
        currency: 'DTV',
        type: 'story_submission'})
      await addSubmissionToCart({
        uid: userId,
        bundleId: bundleId, 
        sId: sId, 
        title: bundle?.title+" Bundle",
        summary: story?.headline,
        price: bundle?.price,
        currency: 'DTV',
        type: 'story_submission'});
        enqueueSnackbar("Added to cart", {variant: 'success'});
        setHideCartButton(true);
    };
    const cancelOrder = ()=>{
      const submission = {
        bundleId: bundleId,
        sId: sId,
        userId: userId,
        storyId: storyId,
        paid: paid,
        published: published,
        status: 'cancelled',
        timestamp: timestamp,
        seen: seen,
        statusDates: {
          ...statusDates??{},
          cancelled: new Date().getTime()
        },
      }
      setDialogContent(_buildConfirmation({
        title: "Are you sure you want to cancel your submission?",
        okText: "Yes, Cancel Submission",
        cancelText: "No",
        onOkClicked: async()=>{
          await updateVideoSubmission(submission);
          enqueueSnackbar("Order cancelled", {variant: 'success'});
          //send notification
          sendFeedback({
            to: profile?.email,
            from: SiteData?.email??'info@dearfxch.tv',
            subject: `DTV Submission Cancelation`,
            body: `<div>
            <p>You Submission ${story?.headline} has now been canceled</p>
            </div>`
        },()=>{
            enqueueSnackbar("Email Sent", {variant: 'success'});
            sendNotification({
              title: "Submission Cancelled",
              body: `Your submission ${story?.headline} has been cancelled`,
              userId: userId,
              type: 'video_submission',
              parentId: sId,
              timestamp: new Date().getTime(),
              seen: false
            })
        },(error)=>{
            enqueueSnackbar("Couldn't send email", {variant: 'error'});
            console.log(error);
        })
          setShowOpen(false);
        }
      }));
      setShowOpen(true);
    }
    const reSubmitSubmission = ()=>{
      
      history(`/${userId}/Video?bundleId=${bundleId}&edit=true&submissionId=${sId}`)
      setShowOpen(true);
    }
   const _buildContent = (step)=>{
    return <Row style={{width:'100%', padding: "20px 40px",alignItems:'start'}}>
        <div style={{width:'4px',alignSelf:'stretch', backgroundColor: theme.palette.text.primary, marginRight: 20}}/>
        <Column style={{width:'100%', alignItems:'start'}}>
    { step === 'submitted' ? <>
        <Typography align="left" variant="h4">{`Congratulations! you're story has been submitted `}</Typography>
        <div style={{height: 8}}/>
        <Typography align="left" color={"textSecondary"} variant="caption">{`Our Highly talented team will review your submission and get back to you within 2-3 working days`}</Typography>
        <div style={{height: 20}}/>
        <Typography align="left" color={"textSecondary"}  variant="caption">{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(statusDates['submitted']))}</Typography>
    </>:step === 'pending' ? <>
        <Typography align="left" variant="h4">{`Hold Tight! Your Story is under review `}</Typography>
        <div style={{height: 8}}/>
        <Typography align="left"  color={"textSecondary"} variant="caption">{`Our Highly talented team will review your submission and get back to you within 2-3 working days`}</Typography>
        <div style={{height: 20}}/>
        <Typography align="left" color={"textSecondary"}  variant="caption">{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(statusDates['pending']))}</Typography>
    </>:step === 'cancelled' ? <>
        <Typography align="left" variant="h4">{`Your Submission has been cancelled`}</Typography>
        <div style={{height: 8}}/>
        <Typography align="left"  color={"textSecondary"} variant="caption">{`You decided to cancel your submission but you could always re-submit at any time`}</Typography>
        <div style={{height: 20}}/>
        <Button onClick={()=>{
          reSubmitSubmission();
        }} variant="outlined" color="primary">Re-Submit</Button>
        <div style={{height: 20}}/>
        <Typography align="left" color={"textSecondary"}  variant="caption">{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(statusDates['pending']))}</Typography>
    </>:step === 'payment' ? <>
        <Typography align="left" variant="h4">{`Perfect! We're almost there`}</Typography>
        <div style={{height: 8}}/>
        <Typography align="left"  color={"textSecondary"} variant="caption">{`Now that we've approved you story, please proceed to the payment page to continue the process`}</Typography>
        <div style={{height: 20}}/>
        <BundleCard bundleId={bundleId} />
        <div style={{height: 20}}/>
        <Row>
           {hideCartButton ? <></> : <FutureBuilder 
              rebuild
              future = {isInCart(userId,sId,'submission')}
              builder = {(isInCart)=>{
                return !isInCart ? <Button variant="contained" color="primary" onClick={handleAddToCart}>Add To Cart</Button>:<></>
              }}
              placeholder = {<></>}
              errorChild =  {<></>}
              />}
            <Button variant="contained" color="primary" onClick={async()=>{
              await handleAddToCart();
                history(`/payment?type=cart&id=${userId}`);
            }}>Pay Now</Button>
            <div style={{width: 20}}/>
            <Button variant="outlined" color="default" onClick={()=>{cancelOrder()}}>Cancel Order</Button>
        </Row>
        <div style={{height: 20}}/>
        <Typography align="left" color={"textSecondary"}  variant="caption">{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(statusDates['approved']))}</Typography>
        
    </>:
    step === 'validating' ? <>
    <Typography align="left" variant="h4">{`Almost there`}</Typography>
    <div style={{height: 8}}/>
    <Typography align="left"  color={"textSecondary"} variant="caption">{`We are validating your payment and will get back to you within 2-3 working days`}</Typography>
    <div style={{height: 20}}/>
    <Typography align="left" color={"textSecondary"}  variant="caption">{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(statusDates['validating']))}</Typography>
</>:
    
    step === 'published' ? <>
    <Typography align="left" variant="h4">{`Finalized & Published`}</Typography>
    <div style={{height: 8}}/>
    <Typography align="left"  color={"textSecondary"} variant="caption">{`Please find the links to your items below.`}</Typography>
    <div style={{height: 20}}/>
    <Chip variant="outlined" color="primary" label="View Story" onClick={()=>{history(`/Stories/${storyId}`)}}/>
    <div style={{height: 20}}/>
    <Typography align="left" color={"textSecondary"}  variant="caption">{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(statusDates['published']))}</Typography>
</>:
    <></>
    }
    </Column>
        </Row>
   }
   const buildSummary = ()=>{
    return <>
    <Chip icon={getStatusIcon(status??'submitted')} style={{margin: 5,textTransform:'capitalize', paddingLeft:5, borderColor: getStatusColor(status??'submitted'), color: status === 'published' ? 'white' : getStatusColor(status??'submitted')}} label={status??'Submitted'} variant={status !== 'published' ? "outline":'default'}/> 
                        <Chip style={{margin: 5, textTransform:'capitalize'}}  label={paid ? "Paid" : "Unpaid"} variant={ "outline" } color={paid ? "primary" : "deafult"}/>
                        <Chip style={{margin: 5,textTransform:'capitalize',}}  label={published ? "published" : "unpublished"} variant="outline" color={published ? "primary" : "default"}/>
                       {bundle ?  <>
                            <Chip style={{margin: 5,textTransform:'capitalize'}} color="primary"  label={`${bundle?.title} Bundle`} variant="outline" />   
                            <Chip style={{margin: 5,textTransform:'capitalize'}}  label={`${bundle?.price}`} variant="outline" /> 
                        </> : <></>}
                        <IconButton onClick={()=>{
                            if(onOpenPreview)
                            onOpenPreview(story)
                        }}><LocalLibrary/></IconButton></>
   }
    return <>{
    loading ? <Row alignment="centered" style={{width:"100%", height:"100%",padding: 10,alignItems:'center'}}>
        {useSkeleton ? <Skeleton sx={{ bgcolor: 'grey.900' }} variant="rectangular" width={"100%"} height={90}/>:<CircularProgress />}
        </Row> : <Accordion expanded={!disabled} variant="outlined">
            <AccordionSummary>
                <Row alignment="space-between" style={{width:"100%",alignItems:'center'}}>
                    <Column>
                    
                    <Typography align="left" variant="overline">{story?.headline}</Typography>
                    <Typography align="left"  color="textSecondary" variant="caption">{new Intl.DateTimeFormat('en-US', { dateStyle: 'medium', timeStyle: 'short' }).format(new Date(timestamp))}</Typography>
                    {
                        isLargeDevice ? <></> : <Row style={{flexWrap: 'wrap'}}>
                          {buildSummary()}
                        </Row>
                    }
                    </Column>
                    {isLargeDevice ? <Row style={{alignItems:'center'}}>
                        {buildSummary()}
                    </Row> : <></>}
                </Row>
                </AccordionSummary>
                <AccordionDetails style={{width:'100%',padding: isLargeDevice ? null : '3px'}}>
                    {
                        <Column style={{width:'100%'}}>
                         <Stepper style={{padding:isLargeDevice ? null : '2px'}} alternativeLabel activeStep={filteredProgression.indexOf(status)} connector={<QontoConnector />}>
                        {
                             filteredProgression.map((step) => {
                                const Modified = ({active, completed})=>{
                                    return <QontoStepIcon name={step} active={active} completed={completed}/>
                                }
                              Modified.propTypes = {
                                active: PropTypes.bool,
                                completed: PropTypes.bool,
                              }
                                return <Step key={step} active={status === step} completed={filteredProgression.indexOf(status) > filteredProgression.indexOf(step) || status === "published"} >
                                    <StepLabel StepIconComponent={Modified} style={{textTransform:'capitalize'}}>{step}</StepLabel>
                                
                                </Step>
                             })
                        }
                       </Stepper>
                       {_buildContent(status)}
                        </Column>

                      
                    }
                </AccordionDetails>
    </Accordion>}
    <Dialog open={open} onClose={()=>{setShowOpen(false)}} aria-labelledby="form-dialog-title">
      {dialogContent}
      </Dialog>
    </>
}
export default StorySubmissionCard;

StorySubmissionCard.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  onOpenPreview: PropTypes.func,
  useSkeleton: PropTypes.bool,
  profile: PropTypes.object,
}