import { Card, CardActionArea } from '@material-ui/core';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import '../bubble.css';
import Column from './Column';
import Stack from './Stack';
import PropTypes from 'prop-types';


export const ShowcaseCard = (props)=>{
    const [isHovered, setHovered]  = useState(false);


    return (
        <Card style={{width:"100%",height:"100%"}} className={props.className}>
        <CardActionArea style={{width:"100%",height:"100%"}} onClick={props.onClick} onMouseEnter={()=>{
            if(props.hoverPlay)
            setHovered(true)}} onMouseLeave={()=>{
                if(props.hoverPlay)
                setHovered(false)}}>
        <div style={{width:"100%", height:"100%"}}>
        <Stack>
            <div style={{width:"100%", height:"100%"}}> 
            {
                props.video&&isHovered&&props.hoverPlay?
                <ReactPlayer playsinline url={props.video} 
                width="100%" 
                height="100%"
                canPlay="true"
                playing={isHovered}/>:<img alt="" src={props.image} width="100%" height="100%" style={{objectFit:"cover"}}/>
            }
                
            </div>
           
            <Column alignment="end" style={{fontSize:"6vw", padding:"20px", width:"100%", height:"100%"}}>
             <motion.label initial={{x:10, opacity:0.6}}
                                animate={{ x: -10, opacity: props.video&&isHovered&&props.hoverPlay?0:1}}
                                transition={{ ease: "linear",fontSize:"100%", duration: 0.5 }}
                                style={{fontFamily:"Zilla Slab Highlight", textAlign:"left",fontSize: props.fontFraction??"80%", color:"white"}}>
                                    {props.caption?.toUpperCase()}</motion.label>
            </Column>
           
                               
        </Stack>
    </div>
    </CardActionArea>
    </Card>
    )
};


ShowcaseCard.propTypes = {
    image: PropTypes.string,
    caption: PropTypes.string,
    video: PropTypes.string,
    hoverPlay: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    fontFraction: PropTypes.string
}