/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { Dialog, Typography, Card,  Grid } from '@material-ui/core';
import Row from '../../Row'
import Column from '../../Column';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Import Swiper styles
import "swiper/swiper.min.css";
import 'swiper/components/effect-coverflow/effect-coverflow.min.css'
import { Skeleton } from '@material-ui/lab';
import { StoryCard } from '../../_story_mod';
import StorySubmissionCard from '../../story_sub_tracker';
import { useMediaQuery } from '../../../contexts/MediaQueryProvider';
import { MyStoriesFilters } from './filters';
import { useDatabase } from '../../../hooks/useDatabase';
import { retrieveUserStorySubmissions } from '../../../logic/databaseHandler';
import { useNavigate } from 'react-router';
import { useStoryProvider } from '../../../contexts/StoryProvider';
import StoryEditor from '../../_story_editor';
import { SubmissionService } from '../../../services/submissionService';
import PropTypes from 'prop-types'

export const MyStoriesContent = ({profile, usePresistedFilters}) => {
    const [showStoryLoading, setShowStoryLoading] = useState(true);
    const [allStories, updateAllStories] = useState([]);
    const {device} = useMediaQuery();
    const [storyFilter, setStoryFilter] = useState(usePresistedFilters ? localStorage.getItem('dtv-profileStoriesTab') ?? 'drafts' : 'drafts');
    const [showModal, setShowModal] = useState(false);
    const [preview_body, updatePreviewBody] = useState(<></>);
    const history = useNavigate();
    const {getAllUserDrafts} = useDatabase();
    const {
        getAllStoriesBy: getUserStories
    } = useStoryProvider();
    const { searchStorySubmissionsByUser } = SubmissionService();

    const handleRetrieveStory = async (filter) => {
        setShowStoryLoading(true)
        console.log(profile)
        if (filter === 'drafts') {
            getAllUserDrafts(profile?.uid).then((stories) => {
                console.log(stories, "RESULTS")
                updateAllStories(stories);
                setShowStoryLoading(false)
            });
        } else if (filter === 'published') {
            getUserStories(profile?.uid).then((stories) => {
                updateAllStories(stories);
                setShowStoryLoading(false)
            });
        } else {
            retrieveUserStorySubmissions(profile?.uid).then((docs) => {
                updateAllStories(docs);
                setShowStoryLoading(false)
            });
        }
    }
    const handleOnFilterChange = (filter) => {
        setStoryFilter(filter)
        handleRetrieveStory(filter)
        if(usePresistedFilters){
            localStorage.setItem('dtv-profileStoriesTab', filter)
        }
    }
    const handleSearch = async (search) => {
        if (search.length > 0) {
            console.log('searching', search)
            const {data} = await searchStorySubmissionsByUser({ userId: profile?.uid, query: search });
            updateAllStories(data??[]);
        } else {
            handleRetrieveStory();
        }
    }
    const _buildStoryPreview =(data)=>{
        return  <Column style={{height:"100%", width:"100%",padding:"30px"}}>
         
             <StoryEditor hideToolbar story = {data} viewOnly={true}/> 
         </Column>
     }
    useEffect(() => {
        if (profile) {
            handleRetrieveStory(storyFilter);
        }   
    },[])

    if(!profile) return <></>

    return <><div>
          
    {
         <>  <Card variant='outlined' style={{ padding: 20 }}>
    {device === "xs" ? <Column>
       <MyStoriesFilters onSearch={handleSearch} storyFilter={storyFilter} onChangeFilter={handleOnFilterChange} />
    </Column> : <Row alignment="space-between">
       <MyStoriesFilters onSearch={handleSearch} storyFilter={storyFilter} onChangeFilter={handleOnFilterChange} />
    </Row>}
    {showStoryLoading ? storyFilter !== 'submission' && <Grid container spacing={10} style={{ marginTop: 20 }}>
        {[1, 2, 3, 4, 5, 6].map((num,index) => <Grid key={num+""+index} xs={12} md={6} lg = {3} style={{padding: 30}}>
            <Skeleton
            sx={{ bgcolor: 'grey.900' }}
            variant="rectangular"
            width={"100%"}
            height={200}
            style={{ margin: 20 }}
        />
            </Grid>)}
    </Grid> :
        storyFilter !== 'submission' ? <Grid container spacing={10} style={{ marginTop: 20 }}>{
            (allStories).length < 1 ? <Typography variant="caption" style={{ opacity: 0.5 }}> {`No story submissions found!`}</Typography> :
            Object.values(allStories).map((s, index) => <Grid key={s?.storyId+""+index}  xs={12} md={6} lg = {3} style={{padding: 30}}> <div key={`ms_${index}`} style={{margin:10}}>
                    {<StoryCard variant="outlined" onClick={()=>{
                        if(storyFilter === 'published'){
                            history(`/stories/${s.storyId}`)
                        }else{
                            history(`/${profile.uid}/Story?draft=true&id=${s.storyId}`) 
                        }
                    }} story={s} profile={profile} />}
                </div></Grid>)
        }
    </Grid>:
        <Grid container spacing={20}>{
                (allStories).length < 1 ? <Typography variant="caption" style={{ opacity: 0.5 }}> {`No story submissions found!`}</Typography> :
                allStories.map((s, index)=>
                {
                    s.statusDates = typeof s.statusDates === 'string' ?  JSON.parse(s.statusDates) : s.statusDates;
                    return <Grid key={s?.storyId+""+index}  xs={12} md={12} lg = {12} > <div key={`ms_${index}`} style={{margin:10}}>
                        <div onClick={()=>{
                            history(`/${profile.uid}/StorySubmission?id=${s.sId}`)
                        }}>
                            {<StorySubmissionCard
                        disabled
                        useSkeleton
                        onOpenPreview={
                            (data)=>{
                                updatePreviewBody(_buildStoryPreview(data));
                                setShowModal(true)
                            }
                        }
                            data={s}
                            profile={profile}
                        />}
                        </div>
                        
                    </div></Grid>})
            }
        </Grid>}</Card></>}

</div>

<Dialog
            maxWidth={device !== "xs" ? "md" : "xs"}
            onClose={() => { setShowModal(false) }}
            BackdropProps={{
                style: {
                    filter: "blur(10px)"
                }
            }}
            fullWidth
            aria-labelledby="confirmation-dialog-title"
            open={showModal}>
            {preview_body}
        </Dialog>
</>
}
MyStoriesContent.propTypes = {
    profile: PropTypes.object,
    usePresistedFilters: PropTypes.bool
}