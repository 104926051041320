import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Stack from './Stack';
import { IconButton, useTheme } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import Row from './Row';
import PropTypes from 'prop-types';

const SlidingPanel = ({ isOpen, onClose, children, left, width }) => {
    const theme= useTheme();
  return (
    <AnimatePresence>
        {isOpen && ( <Stack startLayer={1000}>
        <motion.div 
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="sliding-panel__backdrop" onClick={onClose} style={{position:'fixed',top:0,left:0,width:'100%',height:'100%',backgroundColor:'rgba(0,0,0,0.5)'}}></motion.div>
       
            <motion.div
            className="sliding-panel"
            initial={{ x: left ? '-100%' :'100%' }}
            animate={{ x: 0 }}
            exit={{ x:left ? '-100%' :'100%' }}
            transition={{ type: 'spring', stiffness: 120, damping: 15 }}
            style={{
                position: 'fixed',
                top: 0,
                left: left ? 0 : null,
                right: left ? null : 0,
                width: width ?? '300px',
                height: '100%',
                backgroundColor: theme.palette.background.paper,
                boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                borderLeft: !left ? `1px solid ${theme.palette.divider}` : 'none',
                borderRight: left ? `1px solid ${theme.palette.divider}` : 'none',
                padding: '16px',
            }}
            >
           <Row style={{width:'100%',justifyContent:'space-between'}}>
           <IconButton 
                onClick={onClose}
            >
                <ArrowBack/>
            </IconButton>
            </Row>
            {children}
            </motion.div>
      
    </Stack>  )}
    </AnimatePresence>
  );
};
export default SlidingPanel;

SlidingPanel.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    children: PropTypes.node,
    left: PropTypes.bool,
    width: PropTypes.string,
}