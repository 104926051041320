
/*eslint-disable no-unused-expressions*/
/* eslint-disable react-hooks/exhaustive-deps */

import { Avatar, Chip, Dialog, alpha, Grid, IconButton, makeStyles, Snackbar, Typography, useTheme } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';

import CssBaseline from '@material-ui/core/CssBaseline';
import { Close } from '@material-ui/icons';
import { motion } from 'framer-motion';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import logo from '../assets/logo.png';
import Column from '../components/Column';
import Row from '../components/Row';
import Stack from '../components/Stack';
import { FooterModule } from '../components/_footer_mod';
import FutureBuilder from '../components/_future_builder';
import { ImageModule, LinkModule, ParagraphModule, TitleModule, VideoModule } from '../components/_new_story_comp';
import { DTVApi } from "../config/api";
import firebase_app from '../config/firebase';
import { StoryContext } from '../contexts/StoryProvider';
import { UserContext } from '../contexts/UserProvider';
import "../index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    background: "black",
    overflowY: 'hidden',
    maxWidth: '100%',
    flexGrow: 1,
    '& .MuiDivider-root': {
      backgroundColor: 'white'
    },
    '& .MuiList-root .MuiListItem-root .MuiListText-root': {
      color: 'white'
    }
  },
  iconBtn: {
    '&:hover': {
      color: "white"
    },
  },
  sideBarSocials: {
    color: "#ffffff47",
    fontSize: "12px",
    '&:hover': {
      color: "white"
    },
  },
  divider: {
    backgroundColor: "rgba(1,1,1, 1)"
  },
  center: {
    textAlign: "center",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  gridList: {
    width: 500,
    height: 450,
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },

  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    padding: "3px 1px",
    position: "fixed",
    // background:"linear-gradient(45deg, black, #d09612bd);",
    background: "transparent",
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  socialIcon: {
    color: "#ffffff47",
    '&:hover': {
      color: "white"
    },
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  listItem: {
    backgroundColor: "#2C2C2C",
    color: "white",
    margin: 10
  },
  listItemImage: {
    height: "60px",
    width: "60px",
    margin: "10px"
  },
  list: {
    transition: theme.transitions.create(["background", "background-color"], {
      duration: theme.transitions.duration.complex,
    }),
    "&:hover": {
      backgroundColor: "#6363638c",
      color: "black",
    },
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  grow: {
    '&:hover': {
      transform: 'scale(1.1)',
      easing: "linear",
      cursor: 'pointer'
    }
  },
  link: {
    color: "grey",
    '&:hover': {
      transform: 'scale(1.1)',
      cursor: 'pointer',
      color: 'white'
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  mobileNav: {
    width: "60%"
  },
  pcNav: {
    width: "30%"
  },
  logo: {
    height: "60px",
    width: "60px"
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.text.primary,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

//CUSTOM MUI

const StoryPagePreview = () => {


  const classes = useStyles();
  const theme = useTheme();
  const [device, setDevice] = useState('pc');
  const [, setDrawerWidth] = useState("30%");
  const [showModal, setShowModal] = useState(false);
  const [preview_body, ] = useState(<></>);
  const [notification, showNotification] = useState(false);
  const [notificationMsg, ] = useState('');
  const [story, setStory] = useState({});
  const [packet, setPacket] = useState([])
  const { currentUser} = useContext(UserContext);
  const {
    currentStoryViews,
    viewStory,
  } = useContext(StoryContext);
  useEffect(() => {
    updateDevice();
    var data = localStorage.getItem("story");
    if (data) {
      const previewStory = JSON.parse(data)
      setStory(previewStory)
      unpackStoryBundle(previewStory.story)
      viewStory(previewStory.storyId, currentUser?.uid ?? sessionStorage.getItem("sessionId"));

    }


    window.addEventListener("resize", updateDevice);
  }, [])
  const updateDevice = () => {
    if (window.innerHeight < 900 && window.innerWidth < 1350) {
      setDevice('mobile');
      setDrawerWidth(300)
    }
    else {
      setDevice('pc')
      setDrawerWidth(280)
    }
  }
  const getAdminUsername = async (uid) => {
    const request = new Request(`${DTVApi.url}api/dearfach/auth_get_user?id=${uid}`,
      {
        method: 'GET',
        mode: 'cors',
        credentials: 'same-origin',
        headers: new Headers({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' }),
      });
    const response = await fetch(request);
    try {
      if (response.status < 200 || response.status > 300) {
        console.error(response.status)
      } else {
        const data = await response.json();
        console.log(data);
        return data.data;
      }

    } catch (e) {
      console.error()
      return e;
    }

  }
  const unpackStoryBundle = (bundle => {
    let data = JSON.parse(bundle);
    console.log(data);
    let packet = [];
    
    data.images?.forEach(image => {
      packet[image.id] = { data: image, type: "image" };
    });
    data.videos?.forEach(video => {
      packet[video.id] = { data: video, type: "video" };
    });
    data.paragraphs?.forEach(p => {
      packet[p.id] = { data: p, type: "paragraph" };
    });
    data.titles?.forEach(t => {
      packet[t.id] = { data: t, type: "title" };
    });
    data.links?.forEach(l => {
      packet[l.id] = { data: l, type: "link" }
    });
    console.log(packet);
    setPacket(packet)
  })
  const getAuthorProfile = async () => {
    let p = await firebase_app.database().ref("Users").child(JSON.parse(localStorage.getItem("story")).userId).once('value');
    return p.val();
  }
  const _buildPage = (() => {
    //...
    console.log('====================================');
    console.log(currentStoryViews);
    console.log(story.userId);
    console.log('====================================');
    return <Column alignment="start" style={{ height: "100%", maxHeight: "100%", overflowY: "auto" }}>

      <div style={{ width: "100%", height: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 50%,transparent)` }}>

        <Grid container spacing={5} style={{ width: "100%", background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 50%,transparent)`, margin: device === 'pc' ? "5% 0 0 0" : "60% 0 10px 0px", padding: device === 'pc' ? "0% 0" : "0" }}>
          <Grid xs={12}>
            <Column alignment="start" style={{ width: "100%", padding: "7% 5% 0% 2%", }}>
              <motion.div initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.1, type: "tween", duration: 0.4 }}>
                <Typography align={device === "pc" ? "left" : "center"} style={{ fontSize: "8vh", width: device === "pc" ? "85%" : "", fontFamily: "raleway", textTransform: "capitalize", fontWeight: "700", margin: "10px 0" }}>{story.headline}</Typography>
              </motion.div>
              <motion.div initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.25, type: "tween", duration: 0.4 }}>
                <Row alignment={device === "pc" ? "left" : "centered"}>
                  {<FutureBuilder style={{ margin: "10px 0" }}
                    future={story.isDTV ? getAdminUsername(story.userId) : getAuthorProfile()}
                    builder={(user) => <Chip variant="outlined" style={{ fontSize: "15px" }} avatar={<Avatar src={logo} />} label={`${user.fname} ${user.lname}`} />
                    }
                    placeholder={<Chip avatar={<Avatar src="" />} label="loading" />}
                  />}

                </Row>
              </motion.div>
              <motion.div initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.3, type: "tween", duration: 0.4 }}>
                <Row alignment={device === "pc" ? "left" : "centered"}>
                  <Typography align={device === "pc" ? "left" : "center"} variant="overline" style={{ fontSize: "16px", margin: "10", color: alpha(theme.palette.text.primary, 0.7) }}>{new Intl.DateTimeFormat("en-US", { year: "numeric", month: "long", day: "numeric" }).format(story.publishedAt)}</Typography>

                </Row></motion.div>
            </Column>
          </Grid>
          <Grid xs={12}>
            <Grid container spacing={5} style={{ width: "100%", padding: device === "pc" ? "10% 8%" : "1%", margin: device === "pc" ? "" : "6% 0 0 0" }}>
              {
                packet.map((p, index) => {
                  return <Grid key={index} xs={12}>
                    <Row alignment="centered">
                      {
                        p.type === "title" ? _buildTitle(p.data) : p.type === "image" ? _buildImage(p.data) : p.type === "video" ? _buildVideo(p.data) : p.type === "link" ? _buildLink(p.data) : _buildParagraph(p.data)
                      }
                    </Row>
                  </Grid>
                })
              }
            </Grid>
          </Grid>

        </Grid>
      </div>
      <FooterModule />

    </Column>
  });
  const _buildTitle = (title) => {
    return title.ref === "" ? <></> : <motion.div style={{ width: device === "pc" ? "72%" : "100%", margin: device === "pc" ? "10px 0 40px 0" : "20px" }} initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.4, type: "tween", duration: 0.4 }}><Row alignment="left" >
      <TitleModule style={{ fontSize: "3vw", fontFamily: "raleway", textTransform: "capitalize" }} mode="view" title={title.ref} />
    </Row></motion.div>
  }
  const _buildLink = (link) => {
    return link.link === "" ? <></> : <motion.div style={{ width: device === "pc" ? "72%" : "100%", margin: device === "pc" ? "10px 0 0px 0" : "20px" }} initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.4, type: "tween", duration: 0.4 }}><Row alignment="left" >
      <LinkModule style={{ fontSize: "1.6vw", fontFamily: "raleway", textTransform: "capitalize" }} mode="view" link={link} />
    </Row></motion.div>
  }
  const _buildParagraph = (paragraph) => {
    console.log(paragraph);
    return paragraph.ref === '' ? <></> : <motion.div style={{ width: device === "pc" ? "72%" : "100%", margin: device === "pc" ? "10px 0 7px 0" : "20px" }} initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.4, type: "tween", duration: 0.4 }}><Row alignment="left" >
      <ParagraphModule style={{ fontSize: "2vw", fontFamily: "raleway", color: alpha(theme.palette.text.primary, 0.8) }} mode="view" paragraph={paragraph.ref} />
    </Row></motion.div>
  }
  const _buildImage = (image) => {
    return image.file === "" ? <></> : <motion.div style={{ height: device === "pc" ? "650px" : "300px", margin: device === 'pc' ? "10px 0 30px 0" : "", width: device === "pc" ? "75%" : "100%" }} initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.4, type: "tween", duration: 0.4 }}>
      <ImageModule mode="view" placeholder="Your Image Will appear here when Published" image={{ src: image.blob, caption: image.ref }} />
    </motion.div>
  }
  const _buildVideo = (video) => {
    return video.file === "" ? <></> : <motion.div style={{ height: device === "pc" ? "350px" : "300px", margin: device === 'pc' ? "10px 0 30px 0" : "", width: device === "pc" ? "75%" : "100%" }} initial={{ x: 40, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ delay: 0.4, type: "tween", duration: 0.4 }}>
      <VideoModule mode="view" placeholder="Your Video Will appear here when Published" video={{ src: video.file, caption: video.ref }} />
    </motion.div>
  }
  const _buildPageBackGround = (() => {
    //animate = {{opacity:1-scrollPos}}style={{opacity:1-scrollPos}}
    return <Stack style={{ height: "40%" }}>
      <div style={{ width: '100%', height: '100%' }}>
        <img alt="" style={{ width: "100%", height: "100%", objectFit: "cover" }} src={story.photo?.blob} />
      </div>
      <Column alignment="space-between" style={{ height: "100%", width: "100%" }}>
        <div style={{ background: `linear-gradient(0deg, ${alpha(theme.palette.background.default, 1)} 20%, transparent)`, height: "100%", width: "100%" }}></div>
      </Column>

    </Stack>
  });
  return (<div className={classes.root}>
    <CssBaseline />
    <Stack style={{ height: "100%", width: "100%", overflow: "hidden" }}>
      <div style={{ background: ` ${alpha(theme.palette.background.default, 1)} `, height: "100%", width: "100%" }}></div>
      {_buildPageBackGround()}
      <div style={{ width: "100%", maxHeight: "100%", height: "100%", }}>{_buildPage()}</div>
    </Stack>
    {/* {<AppBarModule pageIndex={6} />} */}
    <Dialog
      maxWidth="lg"
      onClose={() => { setShowModal(false) }}
      disableBackdropClick={true}
      aria-labelledby="confirmation-dialog-title"
      open={showModal}>
      {preview_body}
    </Dialog>
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={notification}
      autoHideDuration={notificationMsg.timeout}
      onClose={() => {
        showNotification(false)
      }}
      message={notificationMsg.message}
      action={
        <React.Fragment>
          <IconButton size="small" aria-label="close" color="inherit" onClick={() => { showNotification(false) }}>
            <Close fontSize="small" />
          </IconButton>
        </React.Fragment>
      }
    />
  </div>

  )


}
export default StoryPagePreview;