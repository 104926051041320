import React from 'react';
import '.././utils.css';
import PropTypes from "prop-types";

const Column = (props) => {
    const {
        crossAlignment = 'left',
        alignment = 'centered',
        className = '',
        id,
        style,
        onClick = () => {},
        children
    } = props;

    return (
        <div id={id} className={`${alignment}-col ${className}`} style={{ alignItems: crossAlignment, ...style }} onClick={onClick}>
            {children}
        </div>
    );
};

export default Column;

Column.propTypes = {
    crossAlignment: PropTypes.oneOf(['flex-start', 'center', 'flex-end', 'stretch', 'baseline',"space-between","space-around","space-evenly"]),
    alignment: PropTypes.oneOf(['centered', 'left', 'right', 'end', 'start',"space-between","space-around","space-evenly"]),
    className: PropTypes.string,
    id: PropTypes.string,
    style: PropTypes.object,
    onClick: PropTypes.func,
    children: PropTypes.node
}