/* eslint-disable react-hooks/exhaustive-deps */

import { Edit } from '@material-ui/icons';
import { Avatar, Badge, fade, IconButton, InputAdornment, InputBase, Tooltip, Typography, useTheme, } from '@material-ui/core';
import React, { useRef, useState, useEffect } from 'react';
import Center from './Center';
import ReactPlayer from 'react-player';
import Column from './Column';
import Stack from './Stack';
import { InfoOutlined, LinkRounded } from '@mui/icons-material';
import PropTypes from 'prop-types';

export const UploadDiv = ({
  viewOnly,
  image: IMAGE,
  video,
  sizeLimit,
  onUpload,
  style,
  placeholder,
  border,
  objectFit,
  children,
  disableLink,
  objectFitPosition
}) => {

  const theme = useTheme();
  const fileUploadRef = useRef(null);
  const [image, setImage] = useState(IMAGE);
  const [backupImage] = useState(IMAGE);
  const [error, setError] = useState(false);
  const [isSizeLimit, setIsSizeLimit] = useState(false);
  const handleClick = () => {
    console.log("hey")
    if(!viewOnly)
      fileUploadRef.current.click();
  }
  useEffect(() => {

    console.log("----->>", image ? "Image Available" : "No Image");
    setImage(image?image!==""?image:backupImage:backupImage)

  }, [image])
  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    var fileReader = new FileReader();
    console.log('====================================');
    console.log(fileUploaded);
    console.log('====================================');
    if (fileUploaded) {
      if (video && !fileUploaded.type.includes("video")) {
        console.log("err");
        setError(true)
      }
      else
        if (fileUploaded.size > (sizeLimit ?? 500000000)) {
          setIsSizeLimit(true)
          setError(true)
        }

        else if (!video && !fileUploaded.type.includes("image")) { setError(true) }
        else {
          fileReader.onload = function () {
            var dataUri = fileReader.result;
            setImage(dataUri)
          }
          fileReader.readAsDataURL(fileUploaded)

          if (onUpload !== null)
            onUpload(fileUploaded);
          setError(false)
        }
    }
  }
  return (
    <Column style={{ width: "100%", height: "100%", position: "relative",cursor:'pointer'}} alignment="end">
      <Stack> 
        {video ? <div className="video-wrapper" style={{ ...style, border: !image ? border ?? `2px ${theme.palette.divider} dashed` : "", width: '100%', height: '100%' }} onClick={handleClick}>
        {!image ? viewOnly ? <></> : 
        <Center>{placeholder ?? <Typography variant={"overline"} style={{ color: fade(theme.palette.text.primary, 0.5) }}>Upload Video File</Typography>}</Center> :
          <ReactPlayer controls={true}
            config={{ youtube: { playerVars: { showinfo: 1 } } }} width='100%' height='100%' url={image?.blob? image?.blob:image} />}
      </div> : <div style={{ ...style, border: !image ? border ?? `2px ${theme.palette.divider} dashed` : "", height: "100%", width: "100%" }} >
       
          <Center onClick={handleClick}>
          {image || image === "" ? <div style={{ width: "100%", height: "100%" }}><img alt="" style={{ objectFit: objectFit??"cover", objectPosition: objectFitPosition ?? "center center" }} width="100%" height="100%" src={image?.blob? image?.blob:image} /></div> :
            image?  <img alt="" src={image} style={{height:"100%", width:"100%", objectFit:objectFit??"cover"}}/>
            : viewOnly ? <></> : <Center>{placeholder ?? <Typography variant={"overline"} style={{textAlign:"center", color: fade(theme.palette.text.primary, 0.5) }}>Upload Image File</Typography>}</Center>
          }
            </Center></div>}
            <Center alignment="col" style={{width: "100%", height: "100%", pointerEvent:"none"}}>
              {viewOnly ? <></> : children}
            </Center>
            {disableLink ? <></> : <Column style={{height:'100%', alignItems:'end',pointerEvent:"none"}} alignment={video ? 'start': 'end'}>
              <Column style={{
                pointerEvents:"all"}}>
                <InputBase
                value =  { (/^(http(s)?:\/\/(.)+)/g).test(image) ? image:""}
                startAdornment={ <InputAdornment  position="start">
                <LinkRounded/>
              </InputAdornment>}
                endAdornment={<InputAdornment position="end">
                  <Tooltip title="You must only paste valid links">
                    <InfoOutlined color='textSecondary'/>
                  </Tooltip>
                </InputAdornment>}
                style={{  borderRadius: 20,
                  margin:10,
                  
                  padding:"5px 15px",
                  background:'rgb(53,53,53)',
                  fontSize:12,
                  pointerEvents:"all"}}
                  onChange={
                    (e)=>{
                      console.log(e.target.value);
                      setImage(e.target.value);
                      if(onUpload && (/^(http(s)?:\/\/(.)+)/g).test(e.target.value))
                        {
                          onUpload({file:e.target.value, blob:e.target.value, link:e.target.value});
                        }
                    }
                  }
              inputProps={{style: {
                fontSize:12
              }}}
              
              placeholder={video ? 'Paste Video Link' :'Paste Image URL'}
            />
              </Column>
            
            </Column>}
         
       </Stack>
      <input type="file" ref={fileUploadRef} onChange={handleChange} style={{ display: 'none' }}></input>
      {!error ? <></> : <Typography style={{ color: "red", fontSize: "12px", margin: "10px" }}>{isSizeLimit ? `Please upload a ${video ? 'video':'image'} file ${(sizeLimit) ? "less than (" + sizeLimit * 1e-6 + "mb)" : "!"}` : `Please upload ${video ? 'a video':'an image'} file!`}</Typography>}
    
      
    </Column>
  )

}

export const UploadProfilePicture = ({
  video,
  sizeLimit,
  onUpload,
  disabled,
  avatarStyle,
  children,
  image: IMAGE
}) => {


  const fileUploadRef = useRef(null);
  const [image, setImage] = useState(IMAGE);
  const [error, setError] = useState(false);
  const handleClick = () => {
    console.log("hey")
    fileUploadRef.current.click();
  }
  useEffect(() => {

    console.log("----->>", image);
    setImage(image)

  }, [])
  const handleChange = (e) => {
    const fileUploaded = e.target.files[0];
    var fileReader = new FileReader();
    console.log('====================================');
    console.log(fileUploaded);
    console.log('====================================');
    if (fileUploaded) {
      if (video && !fileUploaded.type.includes("video")) {
        console.log("err");
        setError(true)
      }
      else
        if (sizeLimit ? (fileUploaded.size > sizeLimit) : 1 === 0) {
          setError(true)
        }

        else if (!video && !fileUploaded.type.includes("image")) { setError(true) }
        else {
          fileReader.onload = function () {
            var dataUri = fileReader.result;
            setImage(dataUri)
          }
          fileReader.readAsDataURL(fileUploaded)

          if (onUpload !== null)
            onUpload(fileUploaded);
          setError(false)
        }
    }
  }
  return (
    <Column style={{ height: "100%", position: "relative"}}>
      {<div onClick={!disabled &&handleClick}>
        
          <IconButton disabled={disabled}><Badge  badgeContent={!disabled &&<Edit/>} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }} style={{position:'relative'}}
          > 
          {image ? <Avatar style={{ ...avatarStyle }} src={image} /> : <Avatar>
            {children}
          </Avatar> ?? <Avatar />} </Badge>
          </IconButton>
         
      </div>}
      <input type="file" ref={fileUploadRef} onChange={handleChange} style={{ display: 'none' }}></input>
      {!error ? <></> : <Typography style={{ color: "red", fontSize: "12px", margin: "10px" }}>{video ? `Please upload a video file ${sizeLimit ? "less than (" + sizeLimit * 1e-6 + "mb)" : "!"}` : "Please upload an image file!"}</Typography>}
    </Column>
  )

}



UploadDiv.propTypes = {
  buttonColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  image: PropTypes.object,
  video: PropTypes.bool,
  sizeLimit: PropTypes.number,
  onUpload: PropTypes.func,
  style: PropTypes.object,
  placeholder: PropTypes.node,
  border: PropTypes.string,
  objectFit: PropTypes.string,
  children: PropTypes.node,
  disableLink: PropTypes.bool,
  objectFitPosition: PropTypes.string,
  viewOnly: PropTypes.bool
}
UploadProfilePicture.propTypes = {
  buttonColor: PropTypes.string,
  buttonHoverColor: PropTypes.string,
  video: PropTypes.bool,
  sizeLimit: PropTypes.number,
  onUpload: PropTypes.func,
  disabled: PropTypes.bool,
  avatarStyle: PropTypes.object,
  children: PropTypes.node,
  image: PropTypes.object
}