import React,{ createContext,useContext,useState } from "react";
import { EventService } from "../services/eventService";
import PropTypes from "prop-types";

export const useDTVEvents = ()=>{
    const {events, addEvent, getAllEvents, loading, getEvents, getEventsBySearch, setCursor} = useContext(EventContext);
    return {events, addEvent, getAllEvents, loading, getEvents, getEventsBySearch, setCursor};
}
export const EventContext = createContext();

const EventProvider = (props)=>{
    const [events, setEvents] = useState([]);
    const [eventCursor, setCursor] = useState(null);
    const {createEvent, getAllEvents: retrieveAllEvents,searchEventsByDate,searchEvent,getEvent,updateEvent} = EventService();
    
  const [loading, setLoading] = useState(false);
    const addEvent = async({title, poster,dateFrom, dateTo, headlineArtist, artists, location, tickets, logo,hosts,sponsors, socials, website})=>{
        setLoading(true);
        try{
            console.log("Creating event....");
            await createEvent({title, poster,dateFrom, dateTo, headlineArtist, artists, location, tickets,logo,hosts,sponsors, socials, website});   
            setLoading(false);
        }catch(e){
            setLoading(false);
            return e;
        }
    }
    const getAllEvents = async({limit, offset})=>{
        setLoading(true);
    
        try{
            const {data, cursor} = await retrieveAllEvents({cursor: eventCursor, limit, offset});
            setEvents(data);
            setCursor(cursor);
            setLoading(false);
        }catch(e){
            setLoading(false);
            return e;
        }
        setLoading(false);
    }
    const getEvents = async({limit, offset, from, to})=>{
        setLoading(true);
        try{
            console.log("Getting events....", from, to);
            const {data, cursor} = await searchEventsByDate({cursor: eventCursor, limit, offset, dateFrom: from, dateTo: to});
            console.log(data);
            setEvents(data);
            setCursor(cursor);
        }catch(e){
            setLoading(false);
            return e;
        }
        setLoading(false);
    }
    const getEventsBySearch = async({search, limit, offset})=>{
        setLoading(true);
        console.log("Searching events....");
        try{
            const {data, cursor} = await searchEvent({cursor: eventCursor, query: search,limit,offset});
            setEvents(data);
            setCursor(cursor);
        }catch(e){
            setLoading(false);
            return e;
        }
        setLoading(false);
    }
    return <EventContext.Provider value = {
       { events,
        addEvent,
        updateEvent,
        getEvent,
        getAllEvents,
        loading,
        getEvents,
        getEventsBySearch,
        setCursor
    }
    } >{props.children}</EventContext.Provider>
}
export default EventProvider;

EventProvider.propTypes = {
    children: PropTypes.node
}