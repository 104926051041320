

import { Badge, Typography, useTheme } from '@material-ui/core';
import { ShoppingBasketRounded } from '@material-ui/icons';
import { motion } from 'framer-motion';
import PropTypes from "prop-types";
import React from 'react';
import Column from './Column';


function BookingHoursBubble(props){

    const [showMenu, setOpenMenu] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const theme = useTheme()
    const canBeOpen = showMenu && Boolean(anchorEl);
    const id = canBeOpen ? 'spring-popper' : undefined;
  const isBooked = props?.isBooked === undefined ? false : props.isBooked
  const isOpen = props?.isOpen === undefined ? true : props.isOpen
  const _buildCircle = ()=>{
      return  <div style={{position:"relative"}}>
        <motion.div
        aria-describedby={id} 
        initial={{backgroundColor: !isOpen ? "rgba(196 196 196 ,0.3)": "#C4C4C4"}}
        whileHover={{scale:1.1,cursor: !props.available ||
           !isOpen ?"not-allowed":"pointer"}}
        onMouseEnter = {(event)=>{
          setAnchorEl(event.currentTarget);
          setOpenMenu(true)
        }}
        onMouseLeave = {(event)=>{
          setAnchorEl(event.currentTarget);
          setOpenMenu(false)
        }}
          animate={{backgroundColor:!isOpen ? "rgba(196 196 196 ,0.3)": !props.available ? "#ff00006b": !isBooked && !props.inCart?"#C4C4C4":"#1E1E1E"}}
          transition={{type: "spring", ease: "easeIn", duration: 0.8 }}
          style={{opacity:props.inCart?0.7:1, backgroundColor:!isOpen ? "rgba(196 196 196 ,0.3)": !props.available ? "#ff00006b":!isBooked && !props.inCart?"#C4C4C4":"#1E1E1E",width:"30px",height:"30px",borderRadius: !isBooked && !props.inCart ? 9 : "2rem"}}
          onClick={()=>{
             if(props.available && isOpen)
              props.onClick(isBooked,props.value)
          }}
      >
        <Column alignment="centered" style={{height:"100%"}}>
        <Typography style={{color:isBooked ? "white" : "#f1f1f1",fontSize:"12px",fontWeight:"bold"}}>{`${new Intl.DateTimeFormat("en-US", { hour: "numeric", hour12:false}).format(props.value)}`}</Typography>
          
        </Column>
         
      </motion.div>
        <motion.div 
        initial={{opacity:0}}
        animate={{opacity:showMenu ? 1 : 0,x : showMenu ? 20 : 0}}
        exit={{opacity:0}}
         transition={{type: "spring", ease: "easeIn", duration: 0.8 }}
        style={{position:"absolute", padding:"5px 10px",backgroundColor:!isOpen ? "#C4C4C4":!props.available ? "red":!isBooked && !props.inCart ?`${theme.palette.primary.main}`:"black",borderRadius:4,zIndex:!showMenu ? "-1":"3000"}}>
          <Typography style={{color:!props.available || (isBooked||props.inCart) ? "white" : "black",fontSize:"12px",fontWeight:"bold"}}>{`${new Intl.DateTimeFormat("en-US", { hour: "numeric",minute:"numeric",hour12:false}).format(props.value)} ${!isOpen ? "Closed":!props.available ? "Unavailable":isBooked ? "Selected":props.inCart?"Cart":"Available"}`}</Typography>
        </motion.div>
      </div>
  }
    return (
        props.inCart ? <Badge badgeContent={<ShoppingBasketRounded style={{fontSize:"12px"}}/>} color="primary">
        {_buildCircle()}
      </Badge>:
      _buildCircle()
      )
  
    
  }
  BookingHoursBubble.propTypes = {
    isBooked: PropTypes.bool,
    onClick: PropTypes.func,
    value: PropTypes.number,
    available:PropTypes.bool,
    isOpen:PropTypes.bool,
    inCart:PropTypes.bool,
  };
  export default BookingHoursBubble