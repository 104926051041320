/* eslint-disable react-hooks/exhaustive-deps */
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, Chip, CircularProgress, FilledInput, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip, Typography, useTheme } from '@material-ui/core';
import { CardGiftcardRounded, CreditCardRounded } from '@material-ui/icons';
import WalletIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import { motion } from 'framer-motion';
import { useSnackbar } from "notistack";
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import NumberFormat from "react-number-format";
import Column from '../../components/Column';
import Row from '../../components/Row';
import CartItem from '../../components/_cart_item';
import FutureBuilder from '../../components/_future_builder';
import { UsernameText } from '../../components/_user_name_mod';
import { DTVApi } from '../../config/api';
import firebase_app from "../../config/firebase";
import { SiteDataContext } from '../../config/SiteData';
import { useMediaQuery } from '../../contexts/MediaQueryProvider';
import { useStripePaymentContext } from '../../contexts/StripeProvider';
import { useProfile } from '../../contexts/UserProvider';
import { useWallet } from '../../contexts/WalletProvider';
import { getCartItem, verifyVoucher } from '../../logic/databaseHandler';
import Logic from '../../logic/logic';
import { sendInvoice } from '../../logic/paymentHandler';
import SuccessPage, { SuccessMessage } from '../booking/success_page';
function Wallet({
  stripeForm,
  style,
}) {
  const theme = useTheme();
  const [tempId, setTempId] = useState(firebase_app.auth()?.currentUser?.uid);
  const {
    SiteData,
  } = useContext(SiteDataContext);
  const { device } = useMediaQuery();
  const [tax, ] = useState(0);
  const [taxString, setTaxString] = useState(0);
  const [actualCost, setActualCost] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const { profile, retrieveData } = useProfile();
  const [expanded, setExpanded] = useState("px");
  const [completed, setCompleted] = useState(false);
  const iRef = useRef(null);
  const { 
    retrieveTransactions,
    loadingTransaction,
    topUpWallet, transactions } = useWallet();
  const [v_error, updateVError] = useState({ err: false, message: "" })
  const [voucherLoading, setVoucherLoading] = useState(false);

  const { runTransaction, stripePaymentLoading,  } = useStripePaymentContext();
  const [topUpAmnts, ] = useState({
    "50": 5.00,
    "100": 10.00,
    "200": 20.00,
    "500": 50.00,
    "1000": 100.00
  });
  const [currentAmnt, setCurrentAmnt] = useState("50");
  const [cost, setCost] = useState("5")
  const handleChange =
    (panel) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
    };
  const handleVoucherPayment = async () => {
    v_error.err = false;
    v_error.message = "";
    updateVError({ ...v_error });
    setVoucherLoading(true);
    try {
      console.log(iRef.current.value);
      let data = await verifyVoucher({ vid: iRef.current.value, uid: tempId });
      setCurrentAmnt(data.value)
      v_error.message = `Voucher Applied! ¢${data.value} added to account.`
      v_error.err = false;
      updateVError({ ...v_error });
      setVoucherLoading(false);
      enqueueSnackbar(v_error.message, {
        variant: 'success', anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
      startTopUp(data.value, "V");

    } catch (e) {
      console.log(e);
      v_error.message = e;
      v_error.err = true;
      updateVError({ ...v_error });
      setVoucherLoading(false);
      enqueueSnackbar(e, {
        variant: 'error', anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      })
    }
  }
  const handlePayment = async (ev) => {
    ev.preventDefault();
    let amnt = (topUpAmnts[currentAmnt]);
    if(await runTransaction(amnt,{
      customerId: profile?.stripeCustomerId,
      email: profile?.email
      
    })){
      await startTopUp();
    }
    
  }

  useEffect(() => {
    console.log("*********", profile);
    retrieveData();

    setTempId(profile?.uid ?? firebase_app.auth()?.currentUser?.uid);
    updateCost();
    retrieveTransactions();

  }, [])
  const _buildAmounts = () => {
    return <Row style={{ cursor: 'pointer',flexFlow: "wrap" }} alignment={"centered"}>
      {
        Object.keys(topUpAmnts).map((v, i) => {
          return <motion.div key={"v"+i} onClick={() => {
            updateCost(topUpAmnts[v])
            setCurrentAmnt(v)
          }} whileHover={{ scale: 1.1, borderColor: `${theme.palette.primary.main}` }}> <Chip style={{ marginLeft: 5 }} label={`¢${v}`} color={currentAmnt === v ? "primary" : "default"} variant="outlined" /> </motion.div>
        })
      }
    </Row>
  }
  const updateCost = async (v) => {
    
    let s = await Logic.toCountryCurrency(Number(v ?? topUpAmnts[currentAmnt]) + tax);
    let ac = await Logic.toCountryCurrency(Number(v ?? topUpAmnts[currentAmnt]));
    Logic.toCountryCurrency(tax).then((tx) => {
      setTaxString(tx);
    })
    setCost(s);
    setActualCost(ac);

  }
  const startTopUp = async (amount, type) => {
    await topUpWallet(Number(amount ?? currentAmnt));
    enqueueSnackbar(`Added: ¢${amount ?? currentAmnt} to Wallet! New Balance: ¢${profile?.bank ?? 0}`, {
      variant: 'success', anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
    await Logic.saveTransaction({
      description: `Added DTV Coins: ¢${amount ?? currentAmnt}`,
      totalPaid: cost,
      totalCharge: cost,
      paymentType: type ?? "C",
      uid: tempId,
      cost: amount,
      cat: "TopUp",
      
    });
    const billing = {
      name: `${profile.fname} ${profile.lname}`,
      email: `${profile.email}`,
      address: {
        city: profile?.address?.address2 ?? "",
        line1: profile?.address?.full ?? "",
        country: profile?.address?.country ?? "",
        postal_code: profile?.address?.postal_code
      },
      orderID: `DTV-${Date.now()}`,
      subtotal: actualCost,
      tax: taxString,
      total: cost,
      topUp: `¢${amount ?? currentAmnt}`,
      profileLink: `${DTVApi.siteUrl}/profile/${profile.uid}`,

    }
    sendInvoice(billing, SiteData);
    setCompleted(true);
    retrieveTransactions();
  }
  return (
    <>
      <Column style={{ padding:" 20px 6px" }}>
        <Row alignment="centered">
          <Column>
            <Row alignment="centered">
            </Row>

            <div style={{ height: device === "xs" ? 5 : 10 }} />
            <UsernameText uid={tempId} />
            <Typography color="primary" align="center" style={{ ...style, }}>{`¢${profile?.bank ?? 0}`}</Typography>
          </Column>

        </Row>
        <div style={{ height: 10 }} />
        <Accordion expanded={expanded === 'p1'} onChange={handleChange('p1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>TOP-UP WALLET</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ width: "100%" }}>
            <Column style={{ width: "100%"}}>
              {_buildAmounts()}
              <div style={{ height: 20 }} />
              {/* <FutureBuilder rebuild future={Logic.toCountryCurrency(topUpAmnts[currentAmnt])} placeholder={()=>{
                return <Row alignment="centered">
                  
                <Typography variant='overline' style={{ textAlign: "center" }} color='primary'></Typography>
              </Row>
              }} builder={(price)=>{
                return <Row alignment="centered">
                <Typography variant='overline' style={{ textAlign: "center" }} color='primary'>{"This will cost you " + price + ""}</Typography>
              </Row>
              }} /> */}
              <Typography variant='overline' style={{ textAlign: "center", fontSize: 14 }} color='primary'>{"This will cost you €" + topUpAmnts[currentAmnt] + ""}</Typography>
              <div style={{ height: 20 }} />
              <Typography align="left" style={{ fontSize: 13 }}>Pay with Voucher</Typography>
              <div style={{ height: 10 }} />

              <NumberFormat
                customInput={FilledInput}
                format={"DTV-####-####-####-####"}
                mask={"X"}
                error={v_error.err}
                label={v_error.message}
                inputRef={iRef}
                onChange={() => { }}
                inputProps={{
                  style: {
                    textAlign: "center",
                    fontWeight: "bold",
                    fontSize: "18px",
                    letterSpacing: 2,
                  },
                }}
              />
              <div style={{ height: 5 }} />
              {v_error.err ? <Typography style={{ textAlign: "center", color: "red", fontSize: "12px" }}>{v_error.message}</Typography> : <Typography style={{ textAlign: "center", color: "green", fontSize: "12px" }}>{v_error.message}</Typography>}
              <div style={{ height: 5 }} />
              {voucherLoading ? <Row style={{ width: "100%" }} alignment="centered"><div><CircularProgress style={{ height: 20, width: 20, margin: "0 10px" }} /></div><Typography style={{ fontSize: 12 }}>Verifying Voucher..</Typography></Row> : <Button variant="contained" color="primary" onClick={handleVoucherPayment}>
                {'Apply & Confirm'}
              </Button>}
              <div style={{ height: 10 }} />
              <div className="ui horizontal divider">
                <Typography style={{ color: theme.palette.text.primary }}>OR</Typography>
              </div>

              <div style={{ height: 20 }} />
              <Typography align="left" style={{ fontSize: 13 }}>Pay by Card</Typography>
              <div style={{ height: 10 }} />

              <Card variant={"outlined"} style={{ padding: 12 }}>
                {stripeForm}
              </Card>
              <div style={{ height: 20 }} />
              <a href="/" target={"_blank"} style={{ textAlign: 'center' }}><small>Terms and conditions apply, click here to read</small></a>
              <div style={{ height: 5 }} />

              {stripePaymentLoading || loadingTransaction ? <Row style={{ width: "100%" }} alignment="centered"><div><CircularProgress style={{ height: 20, width: 20, margin: "0 10px" }} /></div><Typography style={{ fontSize: 12 }}>transaction running..</Typography></Row> : <Button variant="contained" color="primary" onClick={handlePayment}>
                Confirm
              </Button>}
            </Column>


          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>VIEW TRANSACTIONS</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Column style={{ width: "100%" }}>

              <List>
                {transactions.sort((a, b) => a.date - b.date).map((item, index) => {

                  return <Accordion key={index} variant='outlined'>
                    <AccordionSummary style={{ width: "100%" }}>
                      <Column style={{ width: "100%" }}> <ListItem >
                        <ListItemText primary={item.description ?? "lol"} secondary={new Intl.DateTimeFormat("en-US", { year: "numeric", day: "numeric", month: 'short' }).format(item.date)} />

                        <ListItemSecondaryAction> <Row>
                         {device==="xs" ? <></> : <Column>
                            <Tooltip style={{ color: "grey" }} title={item.paymentType === 'V' ? 'Voucher Payment' : item.paymentType === 'C' ? 'Card Payment' : 'Wallet Payment'}>
                              {item.paymentType === 'V' ? <CardGiftcardRounded /> : item.paymentType === 'C' ? <CreditCardRounded /> : <WalletIcon />}
                            </Tooltip></Column>}
                          <div style={{ width: 10 }} />
                          <Chip variant='outline' label={item.totalCharge} />
                        </Row> </ListItemSecondaryAction></ListItem>

                      </Column>

                    </AccordionSummary>
                    <AccordionDetails>
                      {item.items?.map((v, k) => {
                        return <FutureBuilder key={k}
                        futureFunc={()=>getCartItem(tempId, v)} placeholder={<></>} builder={(it) => {
                          return it ? <CartItem item={it} /> : <></>
                        }} />
                      })}
                    </AccordionDetails>
                  </Accordion>
                })}
              </List>
            </Column>
          </AccordionDetails>
        </Accordion>

      </Column>
      {!completed ? <></> : <SuccessPage size="xs" open={true} info={new SuccessMessage(undefined, undefined, `Congratulations! ¢${currentAmnt} DTV Coins has been added to your wallet`)} />}
    </>
  )
}
export default Wallet;

Wallet.propTypes = {
  stripeForm: PropTypes.node,
  Wallet: PropTypes.object, 
  style: PropTypes.object
}