import { RouteNames, baseUrl } from "../config/api";
import axios from "axios";

export const ReadingService = () => {
    const getReadingList = async ({userId, cursor, limit, offset,order}) => {
        const request = {
            url: `${baseUrl}/${RouteNames.readingList}`,
            method: "get",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
                cursor,
                limit,
                offset,
                order: order === "asc" ? "asc" : "desc"
            },
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: {
                    data: response.data.data?.data,
                    count: response.data.data?.count,
                    total: response.data.data?.total,
                    cursor: response.data.data?.cursor,
                },
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }
    const updateReadingList = async ({ userId, id, content }) => {
        const request = {
            url: `${baseUrl}/${RouteNames.readingList}`,
            method: "post",
            headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            },
            params: {
                userId,
            },
            data: {
                id,
                content
            }
        }
        try {
            const response = await axios(request);
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.error);
            }
            return {
                data: response.data,
                status: 200
            };
        } catch (e) {
            console.log(e);
            throw new Error(e);
        }
    }

    return {
        getReadingList,
        updateReadingList
    }
}